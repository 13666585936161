import api, { loadAuth }      from 'api';
import { omit }               from 'underscore';
import { mapReviewAction }    from 'theme/utils/helpers';

const getFollowed = () => {
  return api({
    method: 'get',
    url: '/followed',
    params: {page: 0, pageSize: 1000}
  });
}

function fetchEducators({page, pageSize = 50, filters = {}, query}) {
  const {sort, ...restFilters} = filters;
  const newFilters = Object.entries(restFilters)
  .filter(([value]) => value !== undefined && value !== null)
  .map(([k, value]) => `${k}:${value}`);

  return api({
    method: 'get',
    url: '/educators',
    params: {
      page: page || 0,
      pageSize,
      filter: newFilters,
      sort: sort || 'programReviewCount:d',
      ...query && {query}
    }
  })
}

function fetchEducator({id}) {
  const user = loadAuth();
  const loggedIn = Object.keys(user).length > 0;
  const followedReq = loggedIn && getFollowed();
  const educatorReq = api({
    method: 'get',
    url: `/educators/${id}`
  });

  if(!loggedIn)
    return educatorReq;

  return Promise.all([educatorReq, followedReq])
  .then(([educator, followed]) => {
    const c = followed.data.data.find(f => f.id == educator.data.id);
    return {
      ...educator.data,
      isFollowed: Boolean(c)
    };
  })
}

function postEducator({values}) {
  return new Promise((resolve, reject) => {
    api({
      method: 'post',
      url: `/educators`,
      headers: {
        'Captcha': values.captcha
      },
      data: omit(values, 'captcha')
    })
    .then(resolve, reject)
  });
}

function fetchInterviewReviews({id, page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: `/educators/${id}/reviews/interviews`,
    params: {page, pageSize}
  });
}

function fetchProgramReviews({id, page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: `/educators/${id}/reviews/programs`,
    params: {page, pageSize}
  });
}

function postInterviewReview({values}) {
  const newEducator = values.educator.new;
  const newEducatorRequest = newEducator ? postEducator({values: {name: newEducator, captcha: values.captcha}}) : Promise.resolve();
  return newEducatorRequest.then(
    (response) => {
      const educatorId = newEducator ? response.data.id : (values.educator.id || values.educator);
      const headers = values.captcha ? {'Captcha': values.captcha} : {};
      return api({
        method: 'post',
        url: `/educators/${educatorId}/reviews/interviews`,
        headers: headers,
        data: omit(values, 'agreement', 'educator', 'captcha')
      })
    }
  )
}

function postProgramReview({values}) {
  const newEducator = values.educator.new;
  const newEducatorRequest = newEducator ? postEducator({values: {name: newEducator, captcha: values.captcha}}) : Promise.resolve();
  return newEducatorRequest.then(
    (response) => {
      const educatorId = newEducator ? response.data.id : (values.educator.id || values.educator);
      const headers = values.captcha ? {'Captcha': values.captcha} : {};
      return api({
        method: 'post',
        url: `/educators/${educatorId}/reviews/programs`,
        headers: headers,
        data: omit(values, 'agreement', 'educator', 'captcha')
      });
    }
  )
}

function reviewAction(type, educatorId, reviewId, action, isVote) {
  return api(
    mapReviewAction({
      method: 'post',
      action: isVote ? (action === 'revokeVote' ? 'revokeVote' : 'vote') : null,
      url: `/educators/${educatorId}/reviews/${type}/${reviewId}`,
      params: isVote
        ? {type: action}
        : {action}
    })
  );
}

function fetchFilters() {
  return api({
    method: 'get',
    url: 'educators/filters'
  });
}

function fetchReview({educatorId, reviewType, reviewId}) {
  return api({
    method: 'get',
    url: `/educators/${educatorId}/reviews/${reviewType}/${reviewId}`
  });
}

export function updateReview(educatorId, reviewType, reviewId, values) {
  return (dispatch) => {
    return api({
      method: 'put',
      url: `/educators/${educatorId}/reviews/${reviewType}/${reviewId}`,
      data: omit(values, 'id', 'educator', 'metadata')
    })
    .then(() => dispatch(getReview(educatorId, reviewType, reviewId, {loader: false})))
  }
}

export const getAutocomplete = ({prefix, sort = 'name:a', field, nameOnly = false}) => () => {
  return api({
    method: 'get',
    url: nameOnly ? 'educators/autocomplete' : 'educators',
    params: {
      prefix,
      sort: nameOnly ? null : sort,
      field: nameOnly ? field = 'city' : field
    }
  })
  .then(({data}) => data)
}

export const getEducators = (opts) => {
  return {type: 'EDUCATORS/FETCH', req: fetchEducators, ...opts}
}

export const getEducator = (id, opts = {loader: true}) => {
  return {type: `EDUCATOR:${id}/FETCH`, id, req: fetchEducator, ...opts}
}

export const getInterviews = (id, opts = {loader: true}) => {
  return {type: `EDUCATOR:${id}:INTERVIEWS/FETCH`, id, req: fetchInterviewReviews, ...opts}
}

export const getProgramReviews = (id, opts = {loader: true}) => {
  return {type: `EDUCATOR:${id}:PROGRAMS/FETCH`, id, req: fetchProgramReviews, ...opts}
}

export const addEducator = ({values, form, resolve, reject}) => {
  return {type: 'EDUCATOR/SUBMIT', values, req: postEducator, form, resolve, reject}
}


//------------------------------ FOLLOWING ---------------------------

export const follow = educatorId => {
  return () => {
    return api({
      method: 'post',
      url: `/educators/${educatorId}/follow`
    })
  }
}

export const unfollow = educatorId => {
  return () => {
    return api({
      method: 'delete',
      url: `/educators/${educatorId}/follow`
    })
  }
}

//------------------------------ ADDING REVIEWS ---------------------------
export const addProgramReview = ({values, form, resolve, reject}) => {
  return {type: 'EDUCATOR_PROGRAM_REVIEW/SUBMIT', values, req: postProgramReview, form, resolve, reject}
}

export const addInterviewReview = ({values, form, resolve, reject}) => {
  return {type: 'EDUCATOR_INTERVIEW_REVIEW/SUBMIT', values, req: postInterviewReview, form, resolve, reject}
}


//------------------------------ VIEWS ---------------------------
export const viewInterview = (educatorId, reviewId) => () => {
  return reviewAction('interviews', educatorId, reviewId, 'View');
}

export const viewProgram = (educatorId, reviewId) => () => {
  return reviewAction('programs', educatorId, reviewId, 'View');
}

//------------------------------ FOLLOW REVIEWS ---------------------------
export const followReview = (educatorId, reviewId, type) => () => {
  const reviewType = type == 'programs' ? 'programs' : 'interviews';
  return reviewAction(reviewType, educatorId, reviewId, 'Follow');
}

export const unfollowReview = (educatorId, reviewId, type) => () => {
  const reviewType = type == 'programs' ? 'programs' : 'interviews';
  return reviewAction(reviewType, educatorId, reviewId, 'Unfollow');
}

//------------------------------ UP- & DOWNVOTING ---------------------------
export const voteProgram = (educatorId, reviewId, action, {page, pageSize, loadReview} = {}) => dispatch => {
  return reviewAction('programs', educatorId, reviewId, action, true)
  .then(() => dispatch(loadReview
    ? getReview({educatorId, reviewId, reviewType: 'programs', loader: false})
    : getProgramReviews(educatorId, {loader: false, page, pageSize})
  ));
}

export const voteInterview = (educatorId, reviewId, action, {page, pageSize, loadReview} = {}) => dispatch => {
  return reviewAction('interviews', educatorId, reviewId, action, true)
  .then(() => dispatch(loadReview
    ? getReview({educatorId, reviewId, reviewType: 'interviews', loader: false})
    : getInterviews(educatorId, {loader: false, page, pageSize})
  ));
}

//---------------------SINGLE REVIEW----------------------
export const getReview = ({educatorId, reviewType, reviewId, loader}) => {
  return {type: `REVIEW:${reviewId}/FETCH`, req: fetchReview, educatorId, reviewType, reviewId, loader}
}

export const getFilters = (opts = {}) => {
  return {type: 'FILTER/FETCH', req: fetchFilters, ...opts}
}
