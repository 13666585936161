import React                 from 'react';
import { Link }              from 'react-router-dom';
import { reduxForm,
         Field }             from 'redux-form';
import { compose,
         setDisplayName,
         withState }         from 'recompose';
import { Switch,
         TextField,
         StaticField }       from 'theme/Form';
import { options }           from 'theme/content';

import './GeneralForm.sass';


export default compose(
  setDisplayName('GeneralForm'),
  withState('isEmailEdited', 'setEmailEdited', false),
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, openModal, isEmailEdited, setEmailEdited, isEmployerAdmin}) => {
    return (
      <div className="GeneralForm">
        <form onSubmit={handleSubmit}>
          <div className="form-container-section">
            <div className="GeneralForm__heading">
              <h5 className="subheader">Change email</h5>
              {isEmailEdited
                ?
                  <button
                    type="submit"
                    className="btn-secondary"
                    disabled={submitting}
                  >
                    Save
                  </button>
                :
                  <span
                    className="edit-btn btn-secondary-outlined"
                    onClick={() => setEmailEdited(true)}
                  >
                    Edit
                  </span>
              }
            </div>
            {isEmailEdited
              ?
                <Field
                  name="email"
                  label="Email"
                  component={TextField}
                />
              :
                <Field
                  name="email"
                  label="Email"
                  component={StaticField}
                />
            }
          </div>
          <div className="form-container-section">
            <div className="GeneralForm__heading">
              <h5 className="subheader">Change password</h5>
            </div>
            <span
              className="GeneralForm__changePassword"
              onClick={() => openModal()}
            >
              Change password
            </span>
          </div>
          <div className="form-container-section GeneralForm__notifications">
            {!isEmployerAdmin &&
              <>
                <h5 className="subheader">Email notifications</h5>
                <div>
                  <span className="form-label">Send me an email when someone:</span>
                  <Field
                    name="emailNotifications.jobApplyInvitation"
                    label="invites me to apply for a job"
                    component={Switch}
                    options={options.switchOptions}
                    alt
                  />
                  {/* <Field
                    name="emailNotifications.directMessage"
                    label="sends me a direct message"
                    component={Switch}
                    options={options.switchOptions}
                    alt
                  /> */}
                </div>
              </>
            }
            <h5 className="subheader">Newsletter</h5>
            <div>
              <span className="form-label">I would like to receive:</span>
              <Field
                name="newsletter.vetitAnnouncements"
                label="announcements from the Vet it team (rarely)"
                component={Switch}
                options={options.switchOptions}
                alt
              />
              {!isEmployerAdmin &&
                <Field
                  name="newsletter.newJobMatches"
                  label="new job matches"
                  component={Switch}
                  options={options.switchOptions}
                  alt
                />
              }
            </div>
          </div>
          <div className="form-container-section">
            <h5 className="subheader">Delete account</h5>
            <div className="GeneralForm__deleteAccount">
              <p>Deleting your account will remove all of your content and data associated with it.</p>
              <Link to="/account-settings/delete-account" className="btn-secondary-outlined">Delete account</Link>
            </div>
          </div>
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </form>
      </div>
    )
  }
))
