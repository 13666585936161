const types = {
  NO_CONNECTION: 'NO_CONNECTION',
  CONNECTED: 'CONNECTED',
  CREATE_REQUEST: 'CREATE_REQUEST',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAILURE: 'REQUEST_FAILURE',
  LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
  LOGGED_FAILED: 'LOGGED_FAILED',
  LOGGED_SUCCESSFULLY: 'LOGGED_SUCCESSFULLY',
  LOGGED_OUT: 'LOGGED_OUT',
  ACCOUNT_CONFIRMED: 'ACCOUNT_CONFIRMED',
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ACTION_SUCCEEDED: 'ACTION_SUCCEEDED'
}

module.exports = types;