import React                 from 'react';
import { connect }           from 'react-redux';
import { reduxForm,
         formValueSelector,
         Field }             from 'redux-form';
import { Switch,
         LabelGroup }        from 'theme/Form';
import { options }           from 'theme/content';

import './PrivacySettingsForm.sass';


const info = {
  matching: 'Vet it analyzes your military experience, skills, education, employment history and translates them to the civilian labor market. That’s how we can present you with job openings best suited for you.',
  contact: 'Vet it keeps your identity discreet from employers during the matching process. Employers will see that they\'ve matched with a potential applicant, but they will not be able to identify you by name or see your profile details until after they send a request to contact you through our system and you approve that request. This is how you permit them to send those requests.',
  reviews: 'Info you want displayed publicly with your posts and reviews. Vet it values discreet posts and reviews, because we want you to engage honestly and candidly. We acknowledge some users want to be more discreet than others, and provide options below for what will be shared with your posts and reviews. '
}


const createSignatureExample = (activeTags, signature) => {
  const result = [];
  Object.entries(signature).map(([key, value]) => {
    if (activeTags?.find(tag => tag === key))
      result.push(value || 'not given');
  })
  return result.join(' / ');
}

export default connect(
  (state, props) => ({activeReviewSignTags: formValueSelector(props.form)(state, 'reviewSignTags')})
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, submitting, pristine, activeReviewSignTags, isSettings, signature}) => {
    return (
      <div className="PrivacySettingsForm">
        <form onSubmit={handleSubmit} className="PrivacySettingsForm__form">
          <div>
            <h5 className="subheader">Job searching</h5>
            <Field
              name="enableJobMatching"
              label="Enable Vet it to match your profile with job openings?"
              component={Switch}
              options={options.switchOptions}
              info={info.matching}
            />
            <Field
              name="allowEmployerContact"
              label="Allow employers to request contact with you?"
              component={Switch}
              options={options.switchOptions}
              info={info.contact}
            />
          </div>
          <div className="PrivacySettingsForm__reviews">
            <h5 className="subheader">Signature</h5>
            <Field
              name="reviewSignTags"
              label="Sign my reviews with"
              component={LabelGroup}
              options={options.reviewSignTags}
              info={info.reviews}
            />
          </div>
          <div className="PrivacySettingsForm__example">
            Example:
            <span> {createSignatureExample(activeReviewSignTags, signature) || 'Anonymous'}</span>
          </div>
          <button
            type="submit"
            className="btn-primary"
            disabled={isSettings ?  (pristine || submitting) : submitting}
          >
            {isSettings ? 'Save' : 'Save & Continue'}
          </button>
        </form>
      </div>
    )
  }
))
