import api from 'api';


function fetchNotifications({page = 0, pageSize = 3}) {
  return api({
    method: 'get',
    url: '/notifications/my',
    params: {page, pageSize}
  })
}

function fetchRecentNotifications({page = 0, pageSize = 1000}) {
  return api({
    method: 'get',
    url: '/notifications/my/new',
    params: {page, pageSize}
  })
}

export const viewNotification = id => () => {
  return api({
    method: 'patch',
    url: `notifications/${id}/viewed`
  })
}

export const viewAll = () => () => {
  return api({
    method: 'patch',
    url: 'notifications/my/viewed'
  })
}

export const getNotifications = ({loader, page, pageSize, resolve, reject} = {}) => {
  return {type: 'NOTIFICATIONS/FETCH', req: fetchNotifications, loader, page, pageSize, resolve, reject};
}

export const getRecentNotifications = ({loader = false, page, pageSize, resolve, reject} = {}) => {
  return {type: 'RECENT_NOTIFICATIONS/FETCH', req: fetchRecentNotifications, loader, page, pageSize, resolve, reject};
}
