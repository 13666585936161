import api                 from 'api';
import { mapReviewAction } from 'theme/utils/helpers';

const fetchComments = ({page = 0, pageSize = 3, topicId, sort = 'createdAt:d', query}) => {
  const params = query
    ? {page, pageSize, topicId, sort, query}
    : {page, pageSize, topicId, sort};

  return api({
    method: 'get',
    url: '/comments',
    params
  })
}

const fetchPostComments = ({postId, page, pageSize = 3, itemType = 'comment', sort = 'createdAt:a'}) => {
  return api({
    method: 'get',
    url: '/comments',
    params: {
      page,
      pageSize,
      sort,
      topicId: `${itemType}/${postId}`
    }
  })
}

const fetchTopics = ({topicType, page, pageSize, sortByPopularity}) => {
  return api({
    method: 'get',
    url: '/topics',
    params: {
      type: topicType,
      page,
      pageSize,
      ...(sortByPopularity && {sort: 'popularity:d'})
    }
  })
}

const fetchTopic = ({topicId}) => {
  return api({
    method: 'get',
    url: `/topic/${topicId}`
  })
}

const fetchPost = ({postId}) => {
  return api({
    method: 'get',
    url: `/comments/${postId}`
  })
}

const createComment = ({values, topicId}) => {
  return api({
    method: 'post',
    url: '/comments',
    params: values.topicId.__isNew__
      ? {topicId: `general/${values.topicId.value.split(' ').join('-')}`, topicName: values.topicId.label}
      : {topicId: values.topicId.value},
    data: values
  })
  .then(response => {
    getComments({topicId});

    return response;
  });
}

export const voteComment = (commentId, action) => () => {
  return api(
    mapReviewAction({
      method: 'post',
      action: action === 'revokeVote' ? 'revokeVote' : 'vote',
      url: `/comments/${commentId}`,
      params: {type: action}
    })
  );
}

export const addPostComment = ({values, postId, itemType = 'comment'}) => dispatch => {
  return api({
    method: 'post',
    url: '/comments',
    params: {
      topicId: `${itemType}/${postId}`
    },
    data: {
      content: values
    }
  })
  .then(({data}) => {
    dispatch(getPostComments({postId, itemType}));
    return data;
  });
}

export const updateComment = ({values, topicId}) => () => {
  return api({
    method: 'patch',
    url: `/comments/${topicId}`,
    data: values.content ? values : {content: values}
  });
}

export const deleteComment = (id, type = 'comment') => () => {
  return api({
    method: 'delete',
    url: `/${type}s/${id}`
  });
}

export const viewComment = postId => () => {
  return api({
    method: 'post',
    url: `/comments/${postId}/view`
  });
}

export const getAutocomplete = ({query}) => () => {
  return api({
    method: 'get',
    url: 'topics',
    params: {
      query
    }
  })
  .then(({data}) => data);
}

/////

export const getComments = ({page, pageSize, topicId, sort, query, resolve, reject} = {}) => {
  return {type: 'COMMENTS/FETCH', req: fetchComments, topicId, sort, page, pageSize, query, resolve, reject}
}

export const addComment = ({values, page, pageSize, topicId, resolve, reject} = {}) => {
  return {type: 'COMMENT/SUBMIT', req: createComment, values, topicId, page, pageSize, resolve, reject}
}

export const getPostComments = ({postId, page, pageSize, itemType, sort, resolve, reject} = {}) => {
  return {type: `POST_COMMENTS/FETCH`, req: fetchPostComments, postId, page, pageSize, itemType, sort, resolve, reject}
}

export const getTopics = ({topicType, page, pageSize, sortByPopularity, resolve, reject}) => {
  return {type: topicType ? `TOPICS_${topicType}/FETCH` : 'TOPICS/FETCH', req: fetchTopics, topicType, page, pageSize, sortByPopularity, resolve, reject}
}

export const getTopic = ({topicId, loader, resolve, reject}) => {
  return {type: 'TOPIC/FETCH', req: fetchTopic, topicId, loader, resolve, reject}
}

export const getPost = ({postId, loader, resolve, reject}) => {
  return {type: 'COMMENT/FETCH', req: fetchPost, postId, loader, resolve, reject}
}

//------------------------------ FOLLOWING ---------------------------

export const follow = commentId => () => {
  return api({
    method: 'post',
    url: `/comments/${commentId}/follow`
  })
}

export const unfollow = commentId => () => {
  return api({
    method: 'delete',
    url: `/comments/${commentId}/follow`
  })
}

export const followTopic = topicId => () => {
  return api({
    method: 'post',
    url: `/topics/follow/${topicId}`
  })
}

export const unfollowTopic = topicId => () => {
  return api({
    method: 'delete',
    url: `/topics/follow/${topicId}`
  })
}

//------------------------------ REPORTING ---------------------------

const reportingUrl = ({organizationId, itemType}) => {
  switch(itemType) {
    case 'EmployerJobReview':
      return `/employers/${organizationId}/reviews/jobs`;
    case 'EmployerInterviewReview':
      return `/employers/${organizationId}/reviews/interviews`;
    case 'EducatorProgramReview':
      return `/educators/${organizationId}/reviews/programs`;
    case 'EducatorInterviewReview':
      return `/educators/${organizationId}/reviews/interviews`;
    case 'PartnerReview':
      return `/partners/${organizationId}/reviews`;
    case 'AdminPost':
      return `/admin-posts`;
    default:
      return `/comments`;
  }
};

export const report = ({organizationId, itemId, url, itemType}) => () =>
  api({
    method: 'post',
    url: `${reportingUrl({organizationId, itemId, itemType})}/${itemId}/report`,
    data: {url}
  })
