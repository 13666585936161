// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("theme/img/flag.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".checkbox .check{width:20px;height:20px;display:inline-flex;justify-content:center;align-items:center;border-radius:4px;border:1px solid #BAC2D2;cursor:pointer;position:relative}.checkbox .check:before{display:none}.checkbox .check.checked{background:#44D19F;border:1px solid #44D19F}.checkbox .check.checked .fa-check{color:#fff;font-size:.8rem}@keyframes tick{0%{transform:scale(0)}90%{transform:scale(1.7)}100%{transform:scale(1)}}.PartnerReview{background-color:#F8F7F6}.PartnerReview h4{font-weight:600;color:#FF4E00}.PartnerReview>.container{display:flex;flex-flow:row wrap}.PartnerReview>.container h6{font-size:1em;line-height:1.3em;color:#8F969A;text-transform:uppercase;letter-spacing:1px;color:#44D19F;margin:0;margin-bottom:2rem}.PartnerReview__error{color:#E02020;font-weight:500;margin-top:2rem;text-align:right}.PartnerReview__sidebar{flex:1 1 33%;padding-left:4rem;margin-bottom:6rem}@media only screen and (max-width: 767px){.PartnerReview__sidebar{display:none}}.PartnerReview .flag{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/contain no-repeat;width:2rem;height:2rem;margin-top:4rem}.PartnerReview .third{margin-top:5rem}\n", ""]);
// Exports
module.exports = exports;
