import React               from 'react';
import { withRouter }      from 'react-router';
import { Helmet }          from 'react-helmet';
import { compose,
         setDisplayName,
         withProps,
         withHandlers }    from 'recompose';
import cx                  from 'classnames';
import Modal               from 'theme/Modal';
import PageHeading         from 'theme/PageHeading';
import PageCover           from 'theme/PageCover';
import MobileActions       from 'theme/MobileActions';
import { withModal }       from 'theme/utils/recompose';
import { images }          from 'theme/img/images';
import AskQuestion         from 'comments/AskQuestion';
import Share               from 'share/Share';

import './Profile.sass';

const ORG_TYPE_MAP = {
  Educator: 'educator',
  Employer: 'employer',
  Partner: 'support service'
};

const MODAL_NAMES = {
  SHARE: 'SHARE',
  ASK_QUESTION: 'ASK_QUESTION'
};

const Profile = compose(
  setDisplayName('Profile'),
  withModal(),
  withProps(({match}) => ({
    organizationUrl: `${location.origin}${match.url}`
  })),
  withHandlers({
    getComponent: ({children}) => key => children.filter(comp => comp.key === key)
  })
)(({manager, loading, prefix, user, premium, follow, unfollow, submit, userSignature, activeSection, sections, getComponent, fullWidth, mobileReviewLinks, modal, closeModal, openModal, organizationUrl, actionsBtnProps}) => {
  const mobileActions = [
    {text: manager.isFollowed ? 'UNFOLLOW' : 'FOLLOW', fn: manager.isFollowed ? unfollow : follow, icon: manager.isFollowed ? images.personXsWhite : images.personXs, bgColor: manager.isFollowed ? '#5C6373' : '#FFF', preventFromClosing: true},
    {text: 'SHARE', icon: images.share, fn: () => openModal(MODAL_NAMES.SHARE), handlesModal: true},
    {text: 'ASK A QUESTION', icon: images.editAlt, fn: () => openModal(MODAL_NAMES.ASK_QUESTION), handlesModal: true},
    ...mobileReviewLinks
  ];

  return (
    <div className="Profile">
      {prefix == 'support-services'
        ?
          <Helmet>
            <title>{`Vet it — ${manager.name}`}</title>
            <meta name="description" content={`${manager.name} Reviews. A free inside look at support services posted discreetly by military veterans and spouses.`}/>
            <meta name="image" content={manager.logoUrl || images.shareDefaultImage }/>
            <meta itemProp="name" content={`Vet it – ${manager.name}`}/>
            <meta itemProp="description" content={`${manager.name} Reviews. A free inside look at support services posted discreetly by military veterans and spouses.`}/>
            <meta itemProp="image" content={manager.logoUrl || images.shareDefaultImage }/>
            <meta name="og:title" content={`Vet it – ${manager.name}`}/>
            <meta name="og:description" content={`${manager.name} Reviews. A free inside look at support services posted discreetly by military veterans and spouses.`}/>
            <meta name="og:image" content={manager.logoUrl || images.shareDefaultImage }/>
            <meta name="og:url" content={location.href}/>
            <meta name="og:site_name" content={`Vet it – ${manager.name}`}/>
            <meta name="og:type" content="website"/>
            <meta name="twitter:title" content={`Vet it – ${manager.name}`} />
            <meta name="twitter:description" content={`${manager.name} Reviews. A free inside look at support services posted discreetly by military veterans and spouses.`} />
            <meta name="twitter:image" content={manager.logoUrl || images.shareDefaultImage } />
          </Helmet>
        :
          <Helmet>
            <title>{`Vet it – ${manager.name}`}</title>
            <meta name="description" content={`${manager.name} Reviews. A free inside look at schools and companies posted discreetly by military veterans and spouses.`}/>
            <meta name="image" content={(manager.premiumFeatures?.imgUrl) || manager.logo || images.shareDefaultImage }/>
            <meta itemProp="name" content={`Vet it – ${manager.name}`}/>
            <meta itemProp="description" content={`${manager.name} Reviews. A free inside look at schools and companies posted discreetly by military veterans and spouses.`}/>
            <meta itemProp="image" content={(manager.premiumFeatures?.imgUrl) || manager.logo || images.shareDefaultImage }/>
            <meta name="og:title" content={`Vet it – ${manager.name}`}/>
            <meta name="og:description" content={`${manager.name} Reviews. A free inside look at schools and companies posted discreetly by military veterans and spouses.`}/>
            <meta name="og:image" content={(manager.premiumFeatures?.imgUrl) || manager.logo || images.shareDefaultImage }/>
            <meta name="og:url" content={location.href}/>
            <meta name="og:site_name" content={`Vet it – ${manager.name}`}/>
            <meta name="og:type" content="website"/>
            <meta name="twitter:title" content={`Vet it – ${manager.name}`} />
            <meta name="twitter:description" content={`${manager.name} Reviews. A free inside look at schools and companies posted discreetly by military veterans and spouses.`} />
            <meta name="twitter:image" content={(manager.premiumFeatures?.imgUrl) || manager.logo || images.shareDefaultImage } />
          </Helmet>
      }
      <PageCover
        premium={premium}
        arePremiumFeaturesEnabled={manager.arePremiumFeaturesEnabled}
      />
      <div className="Profile__container container">
        <PageHeading
          manager={manager}
          activeSection={activeSection}
          sections={sections}
          prefix={prefix}
          loading={loading}
          user={user}
          follow={follow}
          unfollow={unfollow}
          isStarEmployer={premium?.star}
          premiumEnabled={manager.arePremiumFeaturesEnabled}
          openModal={() => openModal(MODAL_NAMES.SHARE)}
          actionsBtnProps={actionsBtnProps}
        />
        <div className={cx("Profile__content", {fullWidth, isDiscussion: activeSection == 'discussion'})}>
          <div className="left">{getComponent('left')}</div>
          <div className={cx("right", {isDiscussion: activeSection == 'discussion'})}>{getComponent('right')}</div>
        </div>
      </div>

      <Modal
        isOpen={modal.visible}
        className="shareModal"
        close={closeModal}
        closeBtn
      >
        {(() => {
          switch(modal.name) {
            case MODAL_NAMES.SHARE:
              return (
                <Share
                  url={organizationUrl}
                  organizationName={manager.name}
                  itemType={ORG_TYPE_MAP[manager.class]}
                  shareType={manager.class}
                  itemId={manager.id}
                  closeModal={closeModal}
                  linkedinText={`Check out ${manager.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better! ${organizationUrl}`}
                />
              )
            case MODAL_NAMES.ASK_QUESTION:
              return (
                <AskQuestion
                  onSubmit={submit}
                  form="QuestionForm"
                  initialValues={{
                    topicId: {
                      value: `organization/${manager.id}`,
                      label: manager.name
                    }
                  }}
                  signature={userSignature}
                  title="Ask a question"
                />
              )
          }
        })()}
      </Modal>

      {user &&
        <MobileActions
          actions={mobileActions}
          floating
          initiallyVisible
        />
      }
    </div>
  )
})

export default withRouter(Profile);
