import free from './img/free.svg';
import paid from './img/paid.svg';

export default [
  {
    name: 'free',
    label: 'Free Products',
    img: free,
    desc: 'Fast, simple, and effective. Sign up and post jobs in minutes.',
    btnText: 'Post Jobs Free',
    branding: [
      'Vet it Job Ads',
      'Vet it Search',
      'Vet it Match'
    ],
    candidateSourcing: [
      'Vet it Job Ads',
      'Vet it Search',
      'Vet it Match'
    ]
  },
  {
    name: 'paid',
    label: 'Paid Products',
    img: paid,
    desc: 'Attract, engage, target, search, match, and hire like a pro!',
    btnText: 'Subscribe Now',
    branding: [
      'Vet it Job Ads',
      'Vet it Search',
      'Vet it Match'
    ],
    candidateSourcing: [
      'Vet it Job Ads',
      'Vet it Search',
      'Vet it Match'
    ]
  }
]
