import React                        from 'react';
import { Link }                     from 'react-router-dom';
import { withRouter }               from 'react-router';
import { connect }                  from 'react-redux';
import { compose,
         withState,
         withProps,
         withHandlers,
         setDisplayName,
         lifecycle }                from 'recompose';
import cx                           from 'classnames';
import { logout,
         setQuery,
         search }                   from 'store/actions';
import { env }                      from 'theme/content';
import VetitLogo                    from 'theme/VetitLogo';
import MobileMenu                   from 'theme/MobileMenu';
import SecondaryNav                 from 'theme/SecondaryNav';
import { constants }                from 'theme/utils/constants';
import { withScreenWidthDetection } from 'theme/utils/recompose';
import Notifications                from 'notifications/Notifications';
import { getUserProfile,
         getOrganizations }         from 'user/actions';

import Dropdown                     from './Dropdown';
import Search                       from './Search';

import './Header.sass';

const getLinks = role => {
  switch(role) {
    case 'user':
      return [
        {name: 'Dashboard', to: env.HOME_ROUTE.common},
        {name: 'Edit Profile', to: '/profile-settings/general'},
        {name: 'My account', to: '/account-settings/general'},
        {name: 'Privacy settings', to: '/account-settings/privacy'}
      ];
    case 'org-admin':
      return [
        {name: 'Dashboard', to: env.HOME_ROUTE.organizationAdmin},
        {name: 'Edit profile', to: '/org-admin/manage-profile/general'},
        {name: 'My account', to: '/account-settings/general'}
      ];
    case 'admin':
      return [
        {name: 'User Dashboard', to: env.HOME_ROUTE.common},
        {name: 'Edit Profile', to: '/profile-settings/general'},
        {name: 'My account', to: '/account-settings/general'},
        {name: 'Privacy settings', to: '/account-settings/privacy'},
        {name: 'Administration', to: '/admin/users'}
      ]
    case 'admin-with-org-access':
      return [
        {name: 'User Dashboard', to: env.HOME_ROUTE.common},
        {name: 'Org Dashboard', to: env.HOME_ROUTE.organizationAdmin},
        {name: 'Edit Profile', to: '/profile-settings/general'},
        {name: 'My account', to: '/account-settings/general'},
        {name: 'Privacy settings', to: '/account-settings/privacy'},
        {name: 'Administration', to: '/admin/users'}
      ]
  }
};

const LINKS = [
  {name: 'Jobs', to: '/jobs'},
  {name: 'Companies', to: '/companies'},
  {name: 'Education', to: '/educators'},
  {name: 'Support', to: '/support-services'},
  {name: 'Community', to: '/community'}
];

const Header = compose(
  setDisplayName('Header'),
  withScreenWidthDetection(),
  withState('isMobileSearchInputVisible', 'setMobileSearchInputVisible', false),
  withState('isMobileMenuVisible', 'setMobileMenuVisible', false),
  withState('isSecondaryNavVisible', 'setSecondaryNavVisible', false),
  withState('headerHidden', 'setHeaderHidden', false),
  withState('prevScrollPos', 'setPrevScrollPos', 0),
  withProps(({screenWidth}) => ({
    isMobile: screenWidth < constants.SCREEN_WIDTH.TABLET
  })),
  withHandlers({
    updateDimensions: ({resizeWindow}) => () => resizeWindow(window.innerWidth),
    toggleSecondaryNav: ({isSecondaryNavVisible, setSecondaryNavVisible, isMobileMenuVisible, setMobileMenuVisible}) => () => {
      setSecondaryNavVisible(!isSecondaryNavVisible);
      isMobileMenuVisible && setMobileMenuVisible(false);
    },
    handleSearch: ({getOrganizations, history, setQuery}) => query => {
      return getOrganizations(query)
      .then(response => {
        if (response.length > 0) {
          switch(response[0].class) {
            case 'Employer':
              history.push('/companies')
              break;
            case 'Educator':
              history.push('/educators')
              break;
            case 'Partner':
              history.push('/support-services')
              break;
            default:
              return;
          }
        } else return;

        setQuery(query);
      })
    },
    handleScroll: ({setHeaderHidden, prevScrollPos, setPrevScrollPos}) => () => {
      const currentScroll = window.pageYOffset;

      setHeaderHidden((currentScroll > 30 && currentScroll > prevScrollPos));
      setPrevScrollPos(currentScroll);

      if (currentScroll < 30)
        setHeaderHidden(false);
    }
  }),
  lifecycle({
    componentDidMount() {
      const { user, getUserProfile, handleScroll } = this.props;

      user && getUserProfile({id: user.id});

      window.addEventListener('scroll', handleScroll);
    },
    componentDidUpdate(prevProps) {
      const { user, getUserProfile } = this.props;

      if ((prevProps.user != user) && user)
        getUserProfile({id: user.id});
    },
    componentWillUnmount() {
      window.removeEventListener('scroll', this.props.handleScroll);
    }
  }),
  withHandlers({
    toggleMobileSearchInput: ({isMobile, setMobileSearchInputVisible, isMobileSearchInputVisible}) => () => {
      if (isMobile)
        setMobileSearchInputVisible(!isMobileSearchInputVisible);
    },
    logout: ({logout, setSecondaryNavVisible, isSecondaryNavVisible}) => () => {
      logout();
      if (isSecondaryNavVisible)
        setSecondaryNavVisible(false);
    },
    closeMobileMenu: ({isMobileMenuVisible, setMobileMenuVisible}) => () => {
      isMobileMenuVisible && setMobileMenuVisible(false);
    },
    closeSecondaryNav: ({isSecondaryNavVisible, setSecondaryNavVisible}) => () => {
      isSecondaryNavVisible && setSecondaryNavVisible(false);
    }
  })
)(({user, userProfile, headerHidden, loadingProfile, closeMobileMenu, setMobileMenuVisible, isMobileMenuVisible, logout, setQuery, query, isMobile, isOnboarding, handleSearch, toggleMobileSearchInput, isMobileSearchInputVisible, isSecondaryNavVisible, toggleSecondaryNav, closeSecondaryNav}) => {
  const link = !isOnboarding && (user ? (user.organizationId ? env.HOME_ROUTE.organizationAdmin : env.HOME_ROUTE.common) : '/home');
  const role = user?.isAdmin
      ? (user?.organizationId ? 'admin-with-org-access' : 'admin')
      : (user?.organizationId ? 'org-admin' : 'user');

  return (
    <nav className={cx("Header", {notVisible: headerHidden})}>
      <div className="Header__container">
        <div className={cx("Header__content", {isOnboarding})}>
          {!isMobileSearchInputVisible &&
            <>
              {isOnboarding
                ? <VetitLogo />
                : <Link
                    to={link}
                    className="Header__logo"
                    onClick={closeMobileMenu}
                  >
                    <VetitLogo />
                  </Link>
              }
            </>
          }
          {!isOnboarding &&
            <>
              <ul className="Header__links">
                {LINKS.map((l, i) => (
                  <li key={i}>
                    <Link to={l.to} className="Header__link">{l.name.toUpperCase()}</Link>
                  </li>
                ))}
              </ul>
              {(!user || (user && !user.organizationId)) &&
                <div className="Header__forEmployers vertical-line">
                  <Link to='/employer' className="Header__link">FOR EMPLOYERS</Link>
                </div>
              }

              <div className={cx("Header__searchAndNotifications", {fullWidth: isMobileSearchInputVisible})}>
                <Search
                  className={cx("Header__search", {fullWidth: isMobileSearchInputVisible})}
                  search={query => handleSearch(query)}
                  setQuery={setQuery}
                  query={query}
                  isMobile={isMobile}
                  isInputVisible={isMobileSearchInputVisible}
                  toggleInput={toggleMobileSearchInput}
                />
                {user && !isMobile &&
                  <Notifications />
                }
                {isMobile && user &&
                  <div className="Header__avatar Header__avatar--mobile" />
                }
              </div>
            </>
          }
        </div>
        {!isOnboarding &&
          <MobileMenu
            links={LINKS}
            mobileDropdownClass="Header__mobileDropdown"
            visible={isMobileMenuVisible}
            toggleMenu={() => setMobileMenuVisible(!isMobileMenuVisible)}
            closeSecondaryNav={closeSecondaryNav}
          />
        }
        {!isOnboarding &&
          <div className="Header__actions">
            {isMobile
              ?
                <SecondaryNav
                  mobileDropdownClass="Header__mobileDropdown"
                  links={getLinks(role)}
                  mobile={isMobile}
                  visible={isSecondaryNavVisible}
                  toggle={toggleSecondaryNav}
                  close={closeSecondaryNav}
                  logout={logout}
                />
              :
                <>
                  {user
                    ? <Dropdown
                        user={user}
                        userProfile={user && userProfile}
                        loadingProfile={loadingProfile}
                        logout={logout}
                        links={getLinks(role)}
                      />
                    :
                      <div className="Header__btns">
                        <Link className="Header__signin btn-secondary-outlined" to="/auth/signup">Join now</Link>
                        <Link className="Header__signin btn-secondary" to="/auth/signin">Sign in</Link>
                      </div>
                    }
                </>
            }
          </div>
        }
      </div>
    </nav>
  )
})

const mapStateToProps = ({auth: {user}, data, loading, header}) => ({
  user,
  userProfile: data?.user_profile || {},
  loadingProfile: loading.user_profile,
  ...header
});

export default withRouter(connect(
  mapStateToProps,
  {
    logout,
    setQuery,
    search,
    getUserProfile,
    getOrganizations
  }
)(Header));
