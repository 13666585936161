import React  from 'react';
import cx     from 'classnames';

import './Rating.sass';


export default function Rating ({rating, className, size = 'sm', onClick, disabled, filter}) {
  return (
    <div className={cx("Rating", className, `Rating__size_${size}`)}>
      {Array(5).fill(0).map((_, i) => (
        <span
          key={i}
          className={cx("fa fa-star", {
            active: rating > i,
            clickable: onClick != null
          })}
          onClick={() => !disabled ? onClick && onClick(i + 1) : null}
        />
      ))}
      {filter &&
        <span className="Rating__label">{rating}</span>
      }
    </div>
  )
}
