import React       from 'react';
import { Link }    from 'react-router-dom';
import cx          from 'classnames';
import Loader      from 'theme/Loader'    ;
import ManagerLogo from 'theme/ManagerLogo';

import './Follows.sass';


export default function Follows({organizationTypes, loading, className}) {
  return (
    <div className={cx("Follows card", className)}>
      <Loader loading={loading} />
      <h4>My follows</h4>

      {organizationTypes.map((type, i) => (
        <div key={i} className="Follows__type">
        <h5 className="subheader">{type.label}</h5>
        <div className="Follows__managers">
          {type && type.data.map((manager, i) => (
            <Link to={`/${type.name}/${manager.id}/${manager.slug}/overview`} key={i}>
              <ManagerLogo
                manager={manager}
                className="Follows__managersLogo"
              />
            </Link>
          ))}
        </div>
      </div>
      ))}
    </div>
  )
}
