import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import { compose,
         withState,
         withProps,
         withHandlers,
         withStateHandlers,
         lifecycle }        from 'recompose';
import { pick }             from 'underscore';
import withDebounce         from 'theme/utils/recompose';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import MessageSection       from 'theme/MessageSection';
import Loader               from 'theme/Loader';
import Modal                from 'theme/Modal';
import { withScroll }       from 'theme/utils/recompose';
import { getProfile }       from 'auth/actions';
import CompanyForm          from 'companies/CompanyForm';
import { getCompanies,
         getAutocomplete,
         addCompanyReview,
         getCompany,
         addCompany,
         getReview,
         updateReview }     from 'companies/actions';

import Form                 from './Form';
import './CompanyReview.sass';


const formName = "CompanyReview";
const CompanyFormName = "CompanyForm";

const CompanyReview = compose(
  withScroll(),
  withState('submitted', 'setSubmitted', false),
  withStateHandlers(
  {
    modalOpened: false,
    orgName: ''
  },
  {
    openModal: () => (orgName) => ({
      modalOpened: true,
      orgName
    }),
    closeModal: () => () => ({modalOpened: false})
  }),
  withHandlers({
    nextCompanyReview: (props) => () => props.setSubmitted(false),
    submit: (props) => (values) => {
      return props.submit(values)
      .then(() =>
        props.setSubmitted(values.employer.id || values.employer)
      )
    },
    addCompany: ({addCompany, closeModal}) => (values) => {
      return addCompany(values)
      .then(closeModal);
    },
    getAutocomplete: ({getAutocomplete, name = 'name'}) => value => {
      if (value === '') return;
      return getAutocomplete({field: name, prefix: value});
    }
  }),
  withDebounce({
    functionName: 'search',
    debouncedName: 'searchCompanies',
    withLifecycle: true
  }),
  lifecycle({
    componentDidMount () {
      const { getReview, match, getCompany, getProfile, user } = this.props;

      if(match.params.reviewId)
        getReview();
      if(match.params.companyId)
        getCompany();
      if(user && Object.keys(user).length)
        getProfile(user);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.submitted != this.props.submitted)
        window.scrollTo(0, 0);
    }
  }),
  withProps(({match: {params}, company}) => {
    return {
      companyId: params.companyId,
      companyName: company.name,
      companySlug: company.slug
    }
  })
)(({review,
    submitted,
    nextCompanyReview,
    submit,
    companyId,
    companyName,
    companySlug,
    profile,
    loadingCompany,
    modalOpened,
    openModal,
    closeModal,
    orgName,
    addCompany,
    match,
    getAutocomplete
  }) => {
    const initialValues = !match.params.reviewId
    ? { employer: {id: companyId, name: companyName},
        reviewerDetails: pick(profile, 'mos', 'etsPaygrade', 'serviceBranch', 'email')
      }
    : {...review, employer: {id: companyId, name: companyName}};

    const message = {
      title: `Thanks for paying it forward! ${match.params.reviewId ? 'Your review has been updated.' : 'Your review has been added to this company\'s profile.'}`
    };

    const redirectLink = match.params.reviewId
      ? history.state.state.prevPath
      : `/companies/${submitted}/${companySlug}/overview`;

    return (
      <div className="CompanyReview page">
        <Helmet>
          <title>Vetit — {companyName || ''} Employee Experience</title>
        </Helmet>
        <Header />
        {submitted
        ?
          <div className="container">
            <MessageSection {...message}>
              <Link
                className="btn-primary"
                onClick={nextCompanyReview}
                to={redirectLink}
              >
                Return
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <Loader loading={loadingCompany} />
            {!loadingCompany &&
              <Form
                onSubmit={submit}
                form={formName}
                initialValues={initialValues}
                profile={profile}
                openModal={openModal}
                editDisabled={Boolean(match.params.reviewId)}
                getAutocomplete={getAutocomplete}
              />
            }
            <div className="CompanyReview__sidebar">
              <div className="flag first"></div>
              <p className="secondary-text">Informational Interview</p>
              <p className="content-text">This review form allows you to share details about your employment experiences. </p>
              <div className="flag second"></div>
              <p className="secondary-text">Be Constructive!</p>
              <p className="content-text">We value candid insights much more than rose-colored ones, but be professional! Profanity will be removed. </p>
              <p className="content-text">Respond as though a close military friend is reaching out to learn more about this specific experience.</p>
        {/*   <p className="secondary-text">Need help? Check here:</p>
              <Link className="content-text link" to="#">Military Occupational Specialty table {'>>'}</Link>
              <Link className="content-text link" to="#">ETS Paygrade tables {'>>'}</Link>
        */}
              <div className="flag third"></div>
              <p className="secondary-text">Answer Military Specific Questions</p>
              <p className="content-text">-As a vet/spouse, how did you get your start in this function and/or industry?</p>
              <p className="content-text">-What is the military affinity group like? Is it sponsored by an executive?</p>
              <p className="content-text">-What is mentorship like?</p>
              <p className="content-text">-What is the military leave policy like for members of the National Guard and Reserves? How well does the company support training weekends and mobilizations?</p>
              <p className="content-text">-What is the military spouse program like? How does it support PCS's?</p>
              <p className="content-text">-Does the company have a reasonable number of executives who are veterans? Is there upward mobility for this position?</p>
              <p className="content-text">-Is there any other insight you'd share with veterans?</p>
              <div className="flag fourth"></div>
              <p className="secondary-text">Answer General Questions</p>
              <p className="content-text">-Why do you like this company, especially when comparing it to others in the industry?</p>
              <p className="content-text">-What are the organization's culture and work/life balance like?</p>
              <p className="content-text">-What does a typical 'day at the office' look like for you?</p>
              <p className="content-text">-What kind of projects do you work on?</p>
              <p className="content-text">-Is there a specific educational degree, certificate, or training program that is preferred by the company for your function?</p>
              <div className="flag fifth"></div>
              <p className="secondary-text">Your Review Will Remain Discreet!</p>
              <p className="content-text">Because our community can learn the most from open, honest insights like yours, we want you to feel safe sharing them.</p>
            </div>
          </div>
        }
        <Footer />

        <Modal
          isOpen={modalOpened}
          close={closeModal}
          className="CompanyReview__modal"
        >
          <h4 className="title">New company</h4>
          {orgName &&
            <CompanyForm initialValues={{name: orgName}} onSubmit={addCompany} form={CompanyFormName} />
          }
        </Modal>
      </div>
    )
  }
)

const mapStateToProps = ({auth, data, loading}, props) => {
  const {companies, profile} = data
  const id = props.match.params.companyId;
  const reviewId = props.match.params.reviewId;
  return {
    user: auth.user,
    companies: (companies && companies.data) || [],
    company: data[`company:${id}`] || {},
    loadingCompanies: loading.companies,
    loadingCompany: loading[`company:${id}`],
    profile: profile || {},
    loadingProfile: loading.profile,
    review: (data && data[`review:${reviewId}`]) || {metadata: {}, reviewerDetails: {}}
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const id = props.match.params.companyId;
  const reviewType = 'jobs';
  const reviewId = props.match.params.reviewId;
  return {
    getCompany: () => new Promise((resolve, reject) => dispatch(getCompany(id, {resolve, reject}))),
    getAutocomplete: (args) => dispatch(getAutocomplete(args)),
    search: (query) => new Promise((resolve, reject) => dispatch(getCompanies({query, resolve, reject}))),
    submit: (values) => reviewId
      ? dispatch(updateReview(id, reviewType, reviewId, values))
      : new Promise((resolve, reject) => dispatch(addCompanyReview({values, resolve, reject, form: formName}))),
    getProfile: (user) => dispatch(getProfile(user.id)),
    addCompany: (values) => new Promise((resolve, reject) => dispatch(addCompany({values, form: CompanyFormName, resolve, reject}))),
    getReview: () => new Promise((resolve, reject) => dispatch(getReview({companyId: id, reviewType, reviewId, resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyReview))
