import api    from 'api';

const ORG_TYPE_MAP = {
  'educators': 'educators',
  'companies': 'employers',
  'support-services': 'partners'
};

function fetchReview({organizationType, organizationId, reviewType, reviewId}) {
  return api({
    method: 'get',
    url: organizationType == 'support-services'
      ? `/${ORG_TYPE_MAP[organizationType]}/${organizationId}/reviews/${reviewId}`
      : `/${ORG_TYPE_MAP[organizationType]}/${organizationId}/reviews/${reviewType}/${reviewId}`
  });
}

function fetchOrganization({id, organizationType}) {
  return api({
    method: 'get',
    url: `/${organizationType}s/${id}`
  });
}

export const getReview = ({organizationType, organizationId, reviewType, reviewId, loader}) => {
  return {type: `REVIEW:${reviewId}/FETCH`, req: fetchReview, organizationType, organizationId, reviewType, reviewId, loader}
}

export const getOrganization = (id, organizationType, opts = {}) => {
  return {type: `ORGANIZATION:${id}/FETCH`, req: fetchOrganization, id, organizationType, ...opts}
}
