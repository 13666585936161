export const constants = {
  COMMENT: {
    PAGE_SIZE: 3,
    IMAGE_SIZE: 2,
    ESTIMATED_TEXT_LINE_LENGTH: 100,
    ESTIMATED_COMMENT_BOX_LINE_LENGTH: 65,
    TIMEOUT: 3000,
    MAX_LEN: 200,
    SCROLLED_COMMENT_PAGE_SIZE: 100
  },
  TOPIC: {
    MAX_LEN: 20,
    MOBILE_PAGE_SIZE: 500
  },
  REVIEW: {
    MAX_LEN: 500
  },
  NOTIFICATION: {
    PAGE_SIZE: 3,
    INTERVAL: 30000
  },
  LANDING: {
    INTERVAL: 8000
  },
  PAGINATION: {
    SIZE: 10
  },
  JOBS: {
    FILTERS_SIZE: 3,
    INDUSTRY_FILTERS_SIZE: 6,
    MATCHED_PAGE_SIZE: 3
  },
  SIGNATURE: {
    CHAR_LIMIT: 55
  },
  TOAST: {
    TIMEOUT: 10000
  },
  ONBOARDING: {
    STEP_COUNT: 5,
    ORG_SIZE: 3
  },
  SCREEN_WIDTH: {
    MOBILE: 769,
    TABLET: 992
  },
  SECRET_SIGN_LENGTH: 15,
  BLUR_LIMIT: 20
};
