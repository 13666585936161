import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { compose,
         withState,
         mapProps,
         withStateHandlers,
         lifecycle }          from 'recompose';
import cx                     from 'classnames';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Loader                 from 'theme/Loader';
import AdminNav               from 'theme/AdminNav';
import { formatDate,
         formatPlan }         from 'theme/utils/helpers';
import { getSubscriptions }   from 'subscriptions/actions';

import './Subscriptions.sass';


const filterFunc = query => s => {
  const q = query.toLowerCase();
  return (
    (s.customer.first_name + '' + s.customer.last_name).toLowerCase().indexOf(q) !== -1
  )
}

const STATUS_MAP = {
  cancelled: 'Cancelled',
  in_trial: 'In trial',
  future: 'Future',
  non_renewing: 'Non renewing',
  active: 'Active'
}

const Subscriptions = compose(
  withState('query', 'setQuery', ''),
  withStateHandlers({
    subscriptions: []
  },
  {
    setSubscriptions: () => subscriptions => ({subscriptions}),
    filter: () => e => ({query: e.target.value})
  }),
  lifecycle({
    componentDidMount() {
      this.props.getSubscriptions()
      .then(res => this.props.setSubscriptions(res.list));
    }
  }),
  mapProps(props => ({
    ...props,
    subscriptions: props.subscriptions.filter(filterFunc(props.query))
  }))
)(({subscriptions, loading, filter}) => {
  return (
    <div className="Subscriptions page">
      <Header />
      <AdminNav />
      <div className="container admin-container">
        <div className="Subscriptions__heading">
          <h4>Subscriptions</h4>
          <div>
            <span>Filter by name: </span>
            <input onChange={filter} placeholder="Enter user name" />
          </div>
        </div>
        <div className="Subscriptions__table">
          <Loader
            className="Subscriptions__loader"
            loading={loading}
          />
          {subscriptions.length > 0 && !loading
            ?
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Plan</th>
                    <th>Created at</th>
                    <th>Status</th>
                    <th>Trial start</th>
                    <th>Trial end</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.map(s => {
                    return (
                      <tr key={s.subscription.id}>
                        <td>{s.customer.first_name + ' ' + s.customer.last_name}</td>
                        <td>{s.customer.email}</td>
                        <td>{formatPlan(s.subscription.plan_id)}</td>
                        <td>{formatDate(s.subscription.created_at)}</td>
                        <td>
                          <span className={cx('status', s.subscription.status)}>{STATUS_MAP[s.subscription.status]}</span>
                        </td>
                        <td>{s.subscription.trial_start && formatDate(s.subscription.trial_start)}</td>
                        <td>{s.subscription.trial_end &&formatDate(s.subscription.trial_end)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            :
              <h5>No subscriptions yet!</h5>
            }
        </div>
      </div>
      <Footer />
    </div>
  )
})

const mapStateToProps = ({data, loading}) => {
  return {
    subscriptions: data.subscriptions && data.subscriptions.list || [],
    loading: loading.subscriptions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSubscriptions: () => new Promise((resolve, reject) => dispatch(getSubscriptions({resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptions))
