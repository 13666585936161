import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import PT                   from 'prop-types'
import { compose,
         withState,
         mapProps }         from 'recompose';
import EducatorForm         from 'educators/EducatorForm'
import { addEducator }      from 'educators/actions';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import MessageSection       from 'theme/MessageSection';
import { createSlug }       from 'theme/utils/helpers';

import './NewEducator.sass';


const NewEducator = compose(
  withState('submitted', 'setSubmitted', false),
  mapProps(({setSubmitted, addEducator, submitted}) => ({
    submitted,
    addEducator: (...args) =>
      addEducator(...args)
      .then(response => {
        setSubmitted(response);
      })
  }))
)(
  ({addEducator, submitted}) => {
    return(
      <div className="NewEducator page">
        <Helmet>
          <title>Vetit – Add New Educator</title>
        </Helmet>
        <Header />
        {submitted
          ?
            <div className="container">
              <MessageSection title="Thank you for adding a new Educator!">
                <Link
                  to={`/educators/${submitted.id}/${createSlug(submitted.name.toLowerCase())}/overview`}
                  className="btn-primary"
                >
                  Show the educator
                </Link>
              </MessageSection>
            </div>
          :
            <div className="container">
              <div className="NewEducator__card">
                <h4>New Educator</h4>
                <EducatorForm onSubmit={addEducator} form="NewEducator" />
              </div>
              <div className="NewEducator__sidebar">
                <p className="secondary-text">Thank you for adding a new Educator!</p>
              </div>
            </div>
        }
        <Footer />
      </div>
    )
  }
  )

NewEducator.propTypes = {
  formName: PT.string,
  addEducator: PT.func
}

const mapDispatchToProps = (dispatch, props) => ({
  addEducator: values => new Promise((resolve, reject) => dispatch(addEducator({values, form: props.formName, resolve, reject})))
})

export default withRouter(connect(
  null,
  mapDispatchToProps
)(NewEducator))
