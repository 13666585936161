import React from 'react';

import './SchoolRow.sass';

export default function SchoolRow({school: {schoolName, location, graduationYear, degree, fieldOfStudy}}) {
  return (
    <div className="SchoolRow job-content-row">
      <h4>{schoolName}</h4>
      <span className="text">
        {location}
        <span className="SchoolRow__graduationYear">{graduationYear}</span>
      </span>
      <div className="data">
        <span className="small-label">Degree </span>
        <span className="text">{degree}</span>
      </div>
      <div className="data">
        <span className="small-label">Field of study </span>
        <span className="text">{fieldOfStudy}</span>
      </div>
    </div>
  )
}
