import React, { useEffect } from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import Review               from 'theme/Review';
import Loader               from 'theme/Loader';
import { getReview }        from 'teaser/actions';

import './Teaser.sass';


function ReviewTeaser({review, user, history, getReview, loading}) {
  useEffect(() => {
    getReview();

    if (user) {
      history.push(location.pathname.replace('/teaser/review', ''));
    }
  }, []);

  const defineReviewClass = () => {
    switch(review?.metadata?.class) {
      case 'EmployerJobReview':
        return 'employee'
      case 'EducatorProgramReview':
        return 'student'
      default:
        return 'candidate'
    }
  };

  return (
    <div className="Teaser page">
      <Helmet>
        <title>{`Vet it – ${review?.organization?.name} review`}</title>
        <meta name="description" content={`Check out this ${defineReviewClass()} review about ${review?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`}/>
        <meta name="image" content={(review?.organization?.premiumFeatures && review?.organization?.premiumFeatures.imgUrl) || review?.organization?.logo }/>
        <meta itemProp="name" content={`Vet it – ${review?.organization?.name} review`}/>
        <meta itemProp="description" content={`Check out this ${defineReviewClass()} review about ${review?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`}/>
        <meta itemProp="image" content={(review?.organization?.premiumFeatures && review?.organization?.premiumFeatures.imgUrl) || review?.organization?.logo }/>
        <meta name="og:title" property="og:title" content={`Vet it – ${review?.organization?.name} review`}/>
        <meta name="og:description" property="og:description" content={`Check out this ${defineReviewClass()} review about ${review?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`}/>
        <meta name="og:image" property="og:image" content={(review?.organization?.premiumFeatures && review?.organization?.premiumFeatures.imgUrl) || review?.organization?.logo }/>
        <meta name="og:url" property="og:url" content={location.href}/>
        <meta name="og:site_name" content={`Vet it – ${review?.organization?.name} review`}/>
        <meta name="og:type" content="website"/>
        <meta name="twitter:title" content={`Vet it – ${review?.organization?.name} review`} />
        <meta name="twitter:description" content={`Check out this ${defineReviewClass()} review about ${review?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
        <meta name="twitter:image" content={(review?.organization?.premiumFeatures && review?.organization?.premiumFeatures.imgUrl) || review?.organization?.logo } />
      </Helmet>

      <div className="Teaser__container container">
        <div className="Teaser__content">
          <Loader loading={loading} />
          <Review
            review={review}
            reviewId={review.id}
            organization={review.organization}
            reviewItem
            card
          />
        </div>
      <div className="Teaser__actions">
          <Link className="btn-primary-transparent" to="/auth/signup">Join the community</Link>
          <Link
            className="btn-primary"
            to={`/auth/signin?ReviewShared=${location.pathname.replace('/teaser/review', '').substring(1)}`}
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({auth, data, loading}, {match: {params: {reviewId}}}) => ({
  user: auth.user,
  review: data[`review:${reviewId}`] || {},
  loading: loading[`review:${reviewId}`]
});

const mapDispatchToProps = (dispatch, {match: {params: {reviewId, organizationType, reviewType, organizationId}}}) => ({
  getReview: () => new Promise((resolve, reject) => dispatch(getReview({organizationType, reviewType, organizationId, reviewId, resolve, reject})))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewTeaser));
