import React         from 'react';
import { Link }      from 'react-router-dom';
import { constants } from 'theme/utils/constants';

import './Community.sass';

const trim = topic => topic.length > constants.TOPIC.MAX_LEN ? topic.substring(0, constants.TOPIC.MAX_LEN) : topic;

export default function TopicsList({topics}) {
  return (
    <div className="Community__topicsList">
      {topics.map((t, i) => (
        <Link
          className="Community__topicsItem taglabel"
          key={i}
          to={`/community/${t.id}`}
        >
          {trim(t.name)}
        </Link>
      ))}
    </div>
  )
}
