import React              from 'react';
import { Link }           from 'react-router-dom';
import cx                 from 'classnames';
import ManagerLogo        from 'theme/ManagerLogo';
import ListItemLabel      from 'theme/ListItemLabel';
import { formatDate }     from 'theme/utils/helpers';
import { images }         from 'theme/img/images';

import './JobRow.sass';

const PROGRAM_MAP = {
  'DOD Skillbridge': 'DOD Skillbridge',
  'Registered Apprenticeship Program (RAP)': 'RAP',
  'Industry-Recognized Apprenticeship Program (IRAP)': 'IRAP'
}

export default function JobRow({job: {id, saved, location, availableTypes, createdAt, metadata, jobTitle, supportedPrograms, viewed}, status, viewJob, user, employer, link, matched, handleSave, jobInvitation}) {
  const date = createdAt || metadata.createdAt;

  return (
    <div className={cx("JobRow card", {matched})}>
      <ManagerLogo
        className="JobRow__managerLogo"
        manager={employer}
        size="l"
      />
      <div className="JobRow__mobileHeading">
        <ManagerLogo
          manager={employer}
          size="xxs"
        />
        {date &&
          <span className="JobRow__date">{formatDate(date, ...Array(1), true)}</span>
        }
      </div>
      <div className="JobRow__wrapper">
        <div className="JobRow__name">
          <div>
            <h4 className="title">{jobTitle}</h4>
            {user && !viewed && !jobInvitation &&
              <ListItemLabel text="New" color="orange" />
            }
            {supportedPrograms?.map((p, i) => (
              <ListItemLabel key={i} text={PROGRAM_MAP[p]} color="green" />
            ))}
            {status == 'Withdrawn' &&
              <ListItemLabel text="Withdrawn" color="dark-gray" />
            }
          </div>
          {date &&
            <span className="JobRow__date JobRow__date--desktop">{formatDate(date, ...Array(1), true)}</span>
          }
        </div>

        <div className="JobRow__content">
          <div className="JobRow__info">
            <p className="JobRow__employer">{employer.name}</p>
            <div>
              <span className="JobRow__location">
                <img src={images.pin} alt="location" />
                {location ? location : 'Unknown'}
              </span>
              <span className="JobRow__type">
                {availableTypes && availableTypes[0]}
                {availableTypes?.length - 1 > 0 &&
                  <ListItemLabel
                    text={`+${availableTypes.length - 1}`}
                    color="dark-gray"
                    className={cx({noMargin: availableTypes.length == 0})}
                  />
                }
              </span>
            </div>
          </div>
          <div className="JobRow__btns">
            {user && !jobInvitation &&
              <button
                className={cx("btn btn-secondary-ghost", {active: saved, withdrawn: status == 'Withdrawn' && !saved})}
                onClick={() => handleSave(id, saved ? 'Unsave': 'Save')}
              >
                {saved ? 'Saved' : 'Save'}
              </button>
            }
            <Link
              to={link}
              className="btn btn-secondary"
              onClick={() => viewJob(id)}
            >
              Details
            </Link>
          </div>
        </div>
      </div>
      {status == 'Withdrawn' &&
        <div className="JobRow__overlay" />
      }
    </div>
  )
}
