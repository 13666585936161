import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import PT                   from 'prop-types'
import { pick, debounce }   from 'underscore';
import { lifecycle,
        withProps,
        withState,
        withHandlers,
        setDisplayName,
        mapProps,
        compose }           from 'recompose';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Pagination           from 'theme/Pagination';
import Loader               from 'theme/Loader';
import Profile              from 'theme/Profile';
import ManagerStats         from 'theme/ManagerStats';
import InterviewsStats      from 'theme/InterviewsStats';
import QuickGlance          from 'theme/QuickGlance';
import ReviewsSection       from 'theme/ReviewsSection';
import SingleReview         from 'theme/SingleReview';
import Videos               from 'theme/Videos';
import StarSection          from 'theme/StarSection';
import DetailsSection       from 'theme/DetailsSection';
import PartnersBox          from 'theme/PartnersBox';
import { withScroll }       from 'theme/utils/recompose';
import { images }           from 'theme/img/images';
import { env }              from 'theme/content';
import { getEducator,
         viewInterview,
         viewProgram,
         getProgramReviews,
         getReview,
         voteProgram,
         voteInterview,
         follow,
         unfollow,
         followReview,
         unfollowReview,
         getInterviews }    from 'educators/actions';
import { labels }           from './Labels';

import './Educator.sass';


const headingWithCount = (name, count) => {
  if (!count || count == 0)
    return name;
  return (
    <span>
      {name}
      <span className="count"> ({count})</span>
    </span>
  )
};

const headingSections = (interviewsCount, reviewsCount) => [
  {key: 'overview', name: <span><span className="hidden-xs"></span>Overview</span>},
  {key: 'programs', name: headingWithCount('Students', reviewsCount)},
  {key: 'interviews', name: headingWithCount('Applicants', interviewsCount)}
];

const arrayToString = text => {
  if (text instanceof Array)
    return (
      <span>{text.map((t, i) => (<span key={i} className="text">{t}</span>))}</span>
    )
  return (<span className="text">{text}</span>)
};

const Educator = compose(
  setDisplayName('Educator'),
  withScroll(),
  withState('page', 'setPage', 0),
  withProps(({match: {params: {section, reviewId, id}}, educator}) => ({
    activeSection: section,
    reviewId,
    educatorId: id,
    premium: educator.premiumFeatures || {},
    bodyRef: React.createRef()
  })),
  mapProps(props => ({
    ...props,
    voteInterview: debounce(props.voteInterview, 1000, true),
    voteProgram: debounce(props.voteProgram, 1000, true)
  })),
  lifecycle({
    componentDidMount() {
      const {getEducator, getInterviews, getProgramReviews, getReview, reviewId} = this.props;

      getEducator()
      // .then(e => history.push(`/educators/${e.id}/${e.slug}/overview`))
      getInterviews();
      getProgramReviews();

      if (reviewId)
        getReview();
    },
    componentDidUpdate(prevProps) {
      const { getInterviews, getProgramReviews, activeSection, setPage, reviewId, getReview } = this.props;
      if (activeSection && activeSection != prevProps.activeSection) {
        if (activeSection == 'programs') {
          getProgramReviews();
          setPage(0);
        }
        if (activeSection == 'interviews') {
          getInterviews();
          setPage(0);
        }
      }

      if (prevProps.reviewId != reviewId && reviewId)
        getReview();
    }
  }),
  withHandlers({
    follow: ({follow, getEducator, getProgramReviews, getInterviews, getReview, reviewId}) => (...args) => {
      return follow(...args)
      .then(() => {
        getEducator({loader: false});
        getProgramReviews(0, false);
        getInterviews(0, false);

        if (reviewId)
          getReview({loader: false});
      })
    },
    unfollow: ({unfollow, getEducator, getProgramReviews, getInterviews, getReview, reviewId}) => (...args) => {
      return unfollow(...args)
      .then(() => {
        getEducator({loader: false});
        getProgramReviews(0, false);
        getInterviews(0, false);

        if (reviewId)
          getReview({loader: false});
      })
    },
    followReview: ({followReview, getProgramReviews, getInterviews, educatorId, page}) => type => reviewId => {
      const req = type === 'programs' ? getProgramReviews : getInterviews;

      return followReview(educatorId, reviewId, type)
      .then(() => req(page, false));
    },
    unfollowReview: ({unfollowReview, getProgramReviews, getInterviews, educatorId, page}) => type => reviewId => {
      const req = type === 'programs' ? getProgramReviews : getInterviews;

      return unfollowReview(educatorId, reviewId, type)
      .then(() => req(page, false));
    }
  }),
  withHandlers({
    scrollToReviewsListTop: ({bodyRef}) => () => window.scrollTo(0, bodyRef.current.offsetTop),
    getProgramReviews: ({getProgramReviews, setPage, scrollToReviewsListTop}) => page => {
      getProgramReviews(page)
      .then(() => {
        setPage(page);
        scrollToReviewsListTop();
      });
    },
    getInterviews: ({getInterviews, setPage, scrollToReviewsListTop}) => page => {
      getInterviews(page)
      .then(() => {
        setPage(page);
        scrollToReviewsListTop();
      });
    },
    voteProgram: ({voteProgram, page}) => (loadReview = false) => (...args) => {
      return voteProgram(...args, {page, loadReview})
    },
    voteInterview: ({voteInterview, page}) => (loadReview = false) => (...args) => {
      return voteInterview(...args, {page, loadReview})
    },
    redirectToReview: ({history, educator}) => section => (reviewId, reported = false, returnUrl = false) => {
      const url = `/educators/${educator.id}/${educator.slug}/${section}/${reviewId}`;

      if (returnUrl)
        return url;
      else history.push(reported ? `${url}${env.QUERY.reviewReported}` : url);
    }
  })
)(
  ({educator,
    activeSection,
    interviews,
    programs,
    viewInterview,
    viewProgram,
    voteInterview,
    voteProgram,
    loading,
    premium,
    user,
    follow,
    unfollow,
    followReview,
    unfollowReview,
    page,
    getInterviews,
    getProgramReviews,
    programsCount,
    interviewsCount,
    bodyRef,
    review,
    reviewId,
    redirectToReview,
    history
  }) => {
    const quickGlanceProps = {
      data: educator,
      loading: loading.educator,
      toString: arrayToString
    };

    const actionsBtnProps = {
      title: 'Add a review',
      links: [
        {name: '+ Applicant', link: `/educators/interview-review/${educator.id}/${educator.slug}`},
        {name: '+ Student', link: `/educators/review/${educator.id}/${educator.slug}`}
      ]
    };

    const mobileReviewLinks = [
      {text: '+ APPLICANT REVIEW', icon: images.comment, fn: () => history.push(`/educators/interview-review/${educator.id}/${educator.slug}`)},
      {text: '+ STUDENT REVIEW', icon: images.comment, fn: () => history.push(`/educators/review/${educator.id}/${educator.slug}`)}
    ];

    return (
      <div className="Educator page">
        <Header />
        <Profile
          manager={educator}
          activeSection={activeSection}
          sections={headingSections(interviewsCount, programsCount)}
          prefix="educators"
          loading={loading.educator}
          user={user}
          follow={follow}
          unfollow={unfollow}
          premium={premium}
          actionsBtnProps={actionsBtnProps}
          mobileReviewLinks={mobileReviewLinks}
        >
          <div key="left" ref={bodyRef}>
            {activeSection == 'overview' &&
              <div>
                {premium.videos && educator.arePremiumFeaturesEnabled &&
                  <Videos videos={premium.videos} />
                }
                <div className="card">
                  <Loader loading={loading.educator} />
                  <QuickGlance
                    {...quickGlanceProps}
                    labels={labels.quickGlance(educator)}
                    hasColumns
                    numberOfRows={3}
                    caution
                  />
                </div>
                <div className="card">
                  <Loader loading={loading.educator} />
                  <QuickGlance
                    {...quickGlanceProps}
                    labels={labels.studentsData(educator)}
                    percentFields={labels.percentFields}
                    studentsData
                    hasColumns
                    numberOfRows={3}
                  />
                </div>
                <div className="Educator__row">
                  <div className="card">
                    <Loader loading={loading.educator} />
                    <QuickGlance
                      {...quickGlanceProps}
                      labels={labels.dollarAndSense(educator)}
                      isInDollars
                    />
                  </div>
                  <div className="card">
                    <Loader loading={loading.educator} />
                    <QuickGlance
                      {...quickGlanceProps}
                      labels={labels.indicators(educator)}
                      booleanFields={labels.booleanFields}
                    />
                  </div>
                </div>
                {programs.length > 0 &&
                  <div className="card">
                    <Loader loading={loading.programs} />
                    <ReviewsSection
                      manager={educator}
                      reviews={programs}
                      view={viewProgram}
                      title="Student Experiences"
                      reviewName="Student Reviews"
                      link={`/educators/${educator.id}/${educator.slug}/programs`}
                      linkAdd="/educators/review"
                      voteReview={voteProgram()}
                      allReviewsCount={programsCount}
                      redirectToReview={redirectToReview('programs')}
                      followReview={followReview('programs')}
                      unfollowReview={unfollowReview('programs')}
                    >
                      {educator?.statistics?.educatorStatistics &&
                        <ManagerStats
                          statistics={educator.statistics.educatorStatistics}
                          reviews={programs}
                          className="Educator__statistics"
                          allReviewsCount={programsCount}
                        />
                      }
                    </ReviewsSection>
                  </div>
                }
                {interviews.length > 0 &&
                  <div className="card">
                    <Loader loading={loading.interviews} />
                    <ReviewsSection
                      manager={educator}
                      reviews={interviews}
                      view={viewInterview}
                      title="Applicant Experiences"
                      reviewName="Applicant Reviews"
                      link={`/educators/${educator.id}/${educator.slug}/interviews`}
                      linkAdd="/educators/interview-review"
                      voteReview={voteInterview()}
                      allReviewsCount={interviewsCount}
                      redirectToReview={redirectToReview('interviews')}
                      followReview={followReview('interviews')}
                      unfollowReview={unfollowReview('interviews')}
                    >
                      {educator?.statistics?.interviewStatistics &&
                        <InterviewsStats
                          statistics={pick(educator.statistics.interviewStatistics, 'interviewDifficulty', 'averageOverallRating', 'admissionPercent')}
                          className="Educator__statistics"
                          organization={educator}
                          reviews={interviews}
                          allReviewsCount={interviewsCount}
                        />
                      }
                    </ReviewsSection>
                  </div>
                }
              </div>
            }
            {activeSection == 'interviews' && !reviewId &&
              <div>
                <div className="position-relative">
                  <Loader loading={loading.interviews} />
                  <ReviewsSection
                    key={page}
                    manager={educator}
                    reviews={interviews}
                    view={viewInterview}
                    title="Applicant Experiences"
                    reviewName="Applicant Reviews"
                    link={`/educators/${educator.id}/${educator.slug}/interviews`}
                    linkAdd="/educators/interview-review"
                    voteReview={voteInterview()}
                    redirectToReview={redirectToReview('interviews')}
                    followReview={followReview('interviews')}
                    unfollowReview={unfollowReview('interviews')}
                    all
                  />
                </div>
                <Pagination
                  itemsCount={interviewsCount}
                  gotoPage={getInterviews}
                  activePage={page}
                  disabled={loading.interviews}
                />
              </div>
            }
            {activeSection == 'interviews' && reviewId &&
              <SingleReview
                review={review}
                id={reviewId}
                user={user}
                voteReview={voteInterview(true)}
                loading={loading.review}
                goBack={() => history.goBack()}
                followReview={followReview('interviews')}
                unfollowReview={unfollowReview('interviews')}
              />
            }
            {activeSection == 'programs' && !reviewId &&
              <div>
                <div className="position-relative">
                  <Loader loading={loading.programs} />
                  <ReviewsSection
                    key={page}
                    manager={educator}
                    reviews={programs}
                    view={viewProgram}
                    title="Student Experiences"
                    reviewName="Student Reviews"
                    link={`/educators/${educator.id}/${educator.slug}/programs`}
                    linkAdd="/educators/review"
                    voteReview={voteProgram()}
                    redirectToReview={redirectToReview('programs')}
                    followReview={followReview('programs')}
                    unfollowReview={unfollowReview('programs')}
                    all
                  />
                </div>
                <Pagination
                  itemsCount={programsCount}
                  gotoPage={getProgramReviews}
                  activePage={page}
                  disabled={loading.programs}
                />
              </div>
            }
            {activeSection == 'programs' && reviewId &&
              <SingleReview
                review={review}
                id={reviewId}
                user={user}
                voteReview={voteProgram(true)}
                loading={loading.review}
                goBack={() => history.goBack()}
                followReview={followReview('programs')}
                unfollowReview={unfollowReview('programs')}
              />
            }
          </div>
          <div key="right">
            {activeSection == 'overview' &&
              <div>
                {educator.arePremiumFeaturesEnabled &&
                  <StarSection
                    orgType={educator.class}
                    loading={loading.educator}
                  />
                }
                {educator?.partners?.length > 0 &&
                  <div className="card">
                    <Loader loading={loading.educator} />
                    <PartnersBox partners={educator?.partners} />
                  </div>
                }
                {premium.details && educator.arePremiumFeaturesEnabled &&
                  <div className="card">
                    <Loader loading={loading.educator} />
                    <DetailsSection details={premium.details} />
                  </div>
                }
              </div>
            }
          </div>
        </Profile>
        <Footer />
      </div>
    )
  }
)

Educator.propTypes = {
  educator: PT.object,
  getEducator: PT.func
};

const mapStateToProps = ({auth, data, loading}, {match: {params: {id, reviewId}}}) => ({
  user: auth.user,
  review: data[`review:${reviewId}`] || {},
  educator: data[`educator:${id}`] && data[`educator:${id}`] || {},
  programs: (data[`educator:${id}:programs`] && data[`educator:${id}:programs`].data) || [],
  programsCount: (data[`educator:${id}:programs`] && data[`educator:${id}:programs`].count) || 0,
  interviews: (data[`educator:${id}:interviews`] && data[`educator:${id}:interviews`].data) || [],
  interviewsCount: (data[`educator:${id}:interviews`] && data[`educator:${id}:interviews`].count) || 0,
  loading: {
    educator: loading[`educator:${id}`],
    programs: loading[`educator:${id}:programs`],
    interviews: loading[`educator:${id}:interviews`],
    review: loading[`review:${reviewId}`]
  }
});

const mapDispatchToProps = (dispatch, {match: {params: {id, reviewId, section}}}) => ({
  getEducator: (args = {}) => new Promise((resolve, reject) => dispatch(getEducator(id, {...args, resolve, reject}))),
  getInterviews: (page, loader) => new Promise((resolve, reject) => dispatch(getInterviews(id, {page, loader, resolve, reject}))),
  getProgramReviews: (page, loader) => new Promise((resolve, reject) => dispatch(getProgramReviews(id, {page, loader, resolve, reject}))),
  getReview: (args = {}) => new Promise((resolve, reject) => dispatch(getReview({...args, educatorId: id, reviewType: section, reviewId, resolve, reject}))),
  viewProgram: (...args) => dispatch(viewProgram(id, ...args)),
  viewInterview: (...args) => dispatch(viewInterview(id, ...args)),
  voteProgram: (...args) => dispatch(voteProgram(id, ...args)),
  voteInterview: (...args) => dispatch(voteInterview(id, ...args)),
  follow: () => dispatch(follow(id)),
  unfollow: () => dispatch(unfollow(id)),
  followReview: (...args) => dispatch(followReview(...args)),
  unfollowReview: (...args) => dispatch(unfollowReview(...args))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Educator));
