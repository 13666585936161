import React from 'react';
import cx    from 'classnames';

import './Steps.sass';

export default function Steps({stepCount, page}) {
  return (
    <div className="Steps">
      <ul>
        {Array(stepCount).fill().map((count, i) => (
          <li key={i} className={cx(`Steps__step-${i + 1}`, {active: i + 1 <= page})}><div /></li>
        ))}
      </ul>
    </div>
  )
}
