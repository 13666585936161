import React from 'react';
import cx    from 'classnames';

import './Pie.sass';

const cleanPercentage = percentage => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct, small }) => {
  const r = small ? 25 : 50;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;

  return (
    <circle
      r={r}
      cx={40}
      cy={60}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={small ? '8px' : '13px'}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    />
  );
};

const textProps = {
  x: '50%',
  dominantBaseline: 'central',
  textAnchor: 'middle'
}

const Pie = ({ value, className, small }) => {
  const pct = cleanPercentage(value);
  return (
    <svg className={cx('Pie', className)} width={120} height={120}>
      <g transform={`rotate(-90 ${"50 50"})`}>
        <Circle colour="#DBE0E8" small={small} />
        <Circle colour="#44D19F" pct={pct} small={small} />
      </g>
      <text
        className={cx("Pie__text", {small})}
        y={small ? "50%" : "47%"}
        {...textProps}
      >
        {value?.toFixed(0)}%
      </text>
      {!small &&
        <text
          className="Pie__text Pie__text--label"
          y="65%"
          {...textProps}
        >
          Matched
        </text>
      }
    </svg>
  );
};

export default Pie;