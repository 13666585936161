import { push }                      from 'connected-react-router';
import { SET_QUERY,
         SHOW_MODAL,
         CLOSE_MODAL,
         SET_ACTIVE_MOBILE_LINK,
         SCROLL_MOBILE_NAV_RIGHT }   from './types';

const SEARCH_ROUTE = /\/(educators|companies)$/


export function search(path, query) {
  return (dispatch) => {
    if(query && !SEARCH_ROUTE.test(path))
      dispatch(push('/companies'));
  }
}

export function setQuery(query) {
  return {type: SET_QUERY, query};
}

export function setActiveMobileLink(link) {
  return {type: SET_ACTIVE_MOBILE_LINK, link};
}

export function showModal(name, payload) {
  return {type: SHOW_MODAL, name, payload};
}

export function closeModal(name) {
  return {type: CLOSE_MODAL, name};
}

export function closeToast(type) {
  return {type: type == 'error' ? 'CLEAR_ERRORS' : 'CLEAR_SUCCESS'};
}

export function scrollMobileNavRight(payload) {
  return {type: SCROLL_MOBILE_NAV_RIGHT, payload};
}
