import React, { useState, useEffect }   from 'react';
import { connect }                      from 'react-redux';
import { Helmet }                       from 'react-helmet';
import { withRouter }                   from 'react-router';
import cx                               from 'classnames';
import Footer                           from 'theme/Footer';
import Header                           from 'theme/Header';
import { getAutocomplete  }             from 'companies/actions';
import { registerEmployer as register } from 'auth/actions';

import availablePlans                   from './AvailablePlans';
import Plan                             from './Plan';
import Form                             from './Form';
import talent                           from './img/talent.svg';

import './CompanyLanding.sass';


const SECTIONS = [
  {title: 'Branding & Reputation', text: 'You\'ve got great programs, entry points, and advice. Help veterans understand how to best assess your culture and job needs.'},
  {title: 'Candidate Sourcing', text: 'Showcase your commitment by displaying your HIRE Vets Medallion, Employer Support of the Guard and Reserve Awards, and support of the Military Spouse Employment Partnership program.'},
];

function CompanyLanding({getAutocomplete, submit}) {
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [businessEmail, setBusinessEmail] = useState('');

  const handleSubmit = values => {
    return submit(values)
    .then(response => {
      setBusinessEmail(response.data.email);
      setSubmitted(true);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getEmployers = value => {
    return getAutocomplete({prefix: value, field: 'name'})
    .then(res => res.map(r => ({
      label: r.name,
      value: r.id
    })))
  };

  return (
    <div className="CompanyLanding page">
      <Helmet>
        <title>Vet it — Employer</title>
      </Helmet>
      <Header />
      <section className="CompanyLanding__main">
        <div className="overlay" />
        <div className="container">
          <div className="CompanyLanding__intro">
            <h1>The platform where employer ready veterans meet veteran ready employers.</h1>
            <p>You want to hire veterans, and ours make <br />it easy for you</p>
          </div>
          <div className="CompanyLanding__form card">
            {submitted
              ?
                <div className="CompanyLanding__emailConfirmation">
                  <h3>Confirm your email address</h3>
                  <span>Check mailbox</span>
                  <p>Thank you! We will review your business email address now.<br />Once it is done, a confirmation email will be sent to <span>{businessEmail}</span>.<br />Click on the confirmation link in the email to activate your account.</p>
                </div>
              :
                <Form
                  onSubmit={handleSubmit}
                  form="CompanyLandingForm"
                  getEmployers={getEmployers}
                />
            }
          </div>
        </div>
      </section>

      <section className="CompanyLanding__plans center">
        <div className="container">
          <div className="CompanyLanding__sectionHeading">
            <span className="CompanyLanding__subtitle">All plans include <br />a 14-day free trial period.</span>
            <h2>Choose the right plan <br />for your team</h2>
          </div>
          <div className="CompanyLanding__plansContainer">
            {availablePlans.map((p, i) => (
              <Plan
                key={i}
                plan={p}
                employerLanding
              />
            ))}
          </div>
        </div>
      </section>

      <section className="CompanyLanding__talent">
        <div className="container">
          <div className="CompanyLanding__talentContent">
            <div className="CompanyLanding__sectionHeading CompanyLanding__talentHeading">
              <span className="CompanyLanding__subtitle">Talented HR Teams Recognize Untapped Talent</span>
              <h2>Everything you need to win the war for talent.</h2>
            </div>
            <div className="CompanyLanding__talentScroll">
              {SECTIONS.map((s, i) => (
                <div
                  className={cx("CompanyLanding__talentSection", {active: i === activeSectionIndex})}
                  key={i}
                  onMouseEnter={() => setActiveSectionIndex(i)}
                >
                  <h4 className="title">{s.title}</h4>
                  <p>{s.text}</p>
                </div>
              ))}
            </div>
          </div>
          <img
            className="CompanyLanding__talentImg"
            src={talent}
            alt="talent"
          />
        </div>
      </section>

      <section className="CompanyLanding__features center">
        <div className="container">
          <div className="CompanyLanding__sectionHeading">
            <span className="CompanyLanding__subtitle">Our matching means you <br />review less resumes</span>
            <h2>Imagine veteran applicants who <br />understand your jobs and are ready <br />to contribute on day one.</h2>
          </div>
          <div className="CompanyLanding__steps">
            <div className="CompanyLanding__step">
              <div className="CompanyLanding__stepNumber">1</div>
              <p className="CompanyLanding__stepDescription secondary-text">
                Grow your brand awareness and reputation with our community
              </p>
            </div>
            <div className="CompanyLanding__step">
              <div className="CompanyLanding__stepNumber">2</div>
              <p className="CompanyLanding__stepDescription secondary-text">
                Help veterans understand your culture, commitment, benefits, and expectations
              </p>
            </div>
            <div className="CompanyLanding__step">
              <div className="CompanyLanding__stepNumber">3</div>
              <p className="CompanyLanding__stepDescription secondary-text">
                Post jobs for free, or better yet, allow us to integrate with your favorite ATS
              </p>
            </div>
            <div className="CompanyLanding__step">
              <div className="CompanyLanding__stepNumber">4</div>
              <p className="CompanyLanding__stepDescription secondary-text">
                Get matched with veterans whose interests, skills, and values align with yours
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  getAutocomplete: args => dispatch(getAutocomplete(args)),
  submit: values => dispatch(register(values))
})

export default withRouter(connect(
  null,
  mapDispatchToProps
)(CompanyLanding))
