import React from 'react';

import './ApplyBox.sass';

export default function ApplyBox({apply}) {
  return (
    <div className="ApplyBox card">
      <div>
        <h4>Looks like <br />a perfect fit for you!</h4>
      </div>
      <button className="btn-primary max-width" onClick={apply}>Apply Now</button>
    </div>
  )
}
