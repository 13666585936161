import React                from 'react';
import { withRouter }       from 'react-router';
import { NavLink }          from 'react-router-dom';

import './AdminNav.sass';


const sections = [
  {name: 'users', label: 'Users'},
  {name: 'employers', label: 'Employers'},
  {name: 'educators', label: 'Educators'},
  {name: 'support-services', label: 'Support Services'},
  {name: 'reviews', label: 'Reviews'},
  {name: 'posts', label: 'Feature Posts'}
];

const AdminNav = () => {
  return (
    <div className="AdminNav">
      <div className="AdminNav__container container">
        {sections.map(s => (
          <NavLink
            to={`/admin/${s.name}`}
            key={s.name}
            className="AdminNav__item"
            activeClassName="active"
          >
            {s.label}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default withRouter(AdminNav);
