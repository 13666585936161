import React, { useEffect } from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import Loader               from 'theme/Loader';
import { images }           from 'theme/img/images';
import Post                 from 'comments/Post';
import { getPost }          from 'comments/actions';
import { getAdminPost }     from 'admin/actions';

import './Teaser.sass';


function PostTeaser({post, match: {params: {postType, postId}}, adminPost, getPost, getAdminPost, loading, history, user}) {
  useEffect(() => {
    postType == 'admin-post' ? getAdminPost() : getPost();

    if (user) {
      history.push(postType == 'admin-post'
        ? `/${user.organizationId ? 'org-admin' : 'dashboard'}/feed/post/${postId}`
        : `/community/post/${postId}`
      )
    }
  }, []);

  return (
    <div className="Teaser page">
      {(() => {
        switch(postType) {
          case 'post':
            return (
              <Helmet>
                <title>{`Vet it – ${post.topic?.name} community discussion`}</title>
                <meta name="description" content={`Check out this post about ${post.topic?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
                <meta name="image" content={images.shareDefaultImage} />
                <meta itemProp="name" content={`Vet it – ${post.topic?.name} community discussion`}/>
                <meta itemProp="description" content={`Check out this post about ${post.topic?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`}/>
                <meta itemProp="image" content={images.shareDefaultImage} />
                <meta name="og:title" content={`Vet it – ${post.topic?.name} community discussion`}/>
                <meta name="og:description" content={`Check out this post about ${post.topic?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
                <meta name="og:image" content={images.shareDefaultImage} />
                <meta name="og:url" content={location.href} />
                <meta name="og:site_name" content={`Vet it – ${post.topic?.name} community discussion`} />
                <meta name="og:type" content="website" />
                <meta name="twitter:title" content={`Vet it – ${post.topic?.name} community discussion`} />
                <meta name="twitter:description" content={`Check out this post about ${post.topic?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
                <meta name="twitter:image" content={images.shareDefaultImage} />
              </Helmet>
            )
          case 'admin-post':
            return (
              <Helmet>
                <title>{`Vet it – ${adminPost?.organization?.name} community discussion`}</title>
                <meta name="description" content={`Check out this post from ${adminPost?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
                <meta name="image" content={adminPost?.organization?.logo || images.shareDefaultImage} />
                <meta itemProp="name" content={`Vet it – ${adminPost?.organization?.name} community discussion`}/>
                <meta itemProp="description" content={`Check out this post from ${adminPost?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`}/>
                <meta itemProp="image" content={adminPost?.organization?.logo || images.shareDefaultImage} />
                <meta name="og:title" property="og:title" content={`Vet it – ${adminPost?.organization?.name} community discussion`}/>
                <meta name="og:description" property="og:description" content={`Check out this post from ${adminPost?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
                <meta name="og:image" property="og:image" content={adminPost?.organization?.logo || images.shareDefaultImage} />
                <meta name="og:url" property="og:url" content={location.href} />
                <meta name="og:site_name" content={`Vet it – ${adminPost?.organization?.name} community discussion`} />
                <meta name="og:type" content="website" />
                <meta name="twitter:title" content={`Vet it – ${adminPost?.organization?.name} community discussion`} />
                <meta name="twitter:description" content={`Check out this post about ${adminPost?.organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`} />
                <meta name="twitter:image" content={adminPost?.organization?.logo || images.shareDefaultImage} />
              </Helmet>
            )
        }
      })()}
      <div className="Teaser__container container">
        <div className="Teaser__content">
          <Loader loading={postType == 'admin-post' ? loading.adminPost : loading.post} />
          <Post
            post={postType == 'admin-post' ? adminPost : post}
            admin={postType == 'admin-post'}
            topLvlComment
            teaser={!user}
          />
        </div>
      <div className="Teaser__actions">
          <Link className="btn-primary-transparent" to="/auth/signup">Join the community</Link>
          <Link
            className="btn-primary"
            to={`/auth/signin?${postType == 'admin-post' ? 'AdminPostShared' : 'CommunityPostShared'}=${postId}`}
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = ({auth, data, loading}) => ({
  user: auth.user,
  post: data.comment || {},
  adminPost: data.admin_post || {},
  loading: {
    post: loading.comment,
    adminPost: loading.admin_post
  }
});

const mapDispatchToProps = (dispatch, {match: {params: {postId}}}) => ({
  getPost: () => new Promise((resolve, reject) => dispatch(getPost({postId, resolve, reject}))),
  getAdminPost: () => new Promise((resolve, reject) => dispatch(getAdminPost({postId, resolve, reject})))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PostTeaser));
