import React        from 'react';
import cx           from 'classnames';
import Interactions from 'comments/Interactions';
import Signature    from 'theme/Signature';
import { images }   from 'theme/img/images';

import './PostFooter.sass';

const PostedBy = ({me, signature, byTopicChampion}) => (
  <div className="PostFooter__postedBy">
    <span>Posted by</span>
    {me
      ? <span className="PostFooter__me">me</span>
      : <Signature
          signature={signature}
          byTopicChampion={byTopicChampion}
        />
    }
  </div>
);

export default function PostFooter({votes, react, report, count, me, signature, isReported, itemType, views, singleItemView, teaser, isAdminPost, hasMedia, byTopicChampion}) {
  return (
    <div className={cx("PostFooter", {hasMedia, isReview: itemType === 'review', admin: isAdminPost})}>
      <div className="PostFooter__container">
        {!isAdminPost &&
          <PostedBy
            me={me}
            signature={signature}
            byTopicChampion={byTopicChampion}
          />
        }
      </div>
      <div className="PostFooter__container">
        <div className="PostFooter__wrapper">
          {votes &&
            <Interactions
              votes={votes}
              react={react}
              report={report}
              isReported={isReported}
              itemType={itemType}
            />
          }
          {teaser &&
            <div className="PostFooter__wrapperTeaserOverlay" />
          }
        </div>
        <div className="PostFooter__wrapper">
          <div className="PostFooter__views">
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <title>Icon/eye</title>
              <desc>Created with Sketch.</desc>
              <defs>
                <path d="M10,11.25 C9.31083333,11.25 8.75,10.6891667 8.75,10 C8.75,9.31083333 9.31083333,8.75 10,8.75 C10.6891667,8.75 11.25,9.31083333 11.25,10 C11.25,10.6891667 10.6891667,11.25 10,11.25 M10,7.08333333 C8.39166667,7.08333333 7.08333333,8.39166667 7.08333333,10 C7.08333333,11.6083333 8.39166667,12.9166667 10,12.9166667 C11.6083333,12.9166667 12.9166667,11.6083333 12.9166667,10 C12.9166667,8.39166667 11.6083333,7.08333333 10,7.08333333 M10.1830833,14.1646667 C6.59475,14.248 4.25391667,11.1788333 3.47725,9.99633333 C4.33225,8.65883333 6.48558333,5.9205 9.81725,5.8355 C13.3914167,5.74466667 15.7455833,8.82133333 16.52225,10.0038333 C15.6680833,11.3413333 13.5139167,14.0796667 10.1830833,14.1646667 M18.2230833,9.5855 C17.6914167,8.65883333 14.75475,4.01383333 9.77475,4.16966667 C5.16808333,4.28633333 2.48891667,8.34466667 1.77725,9.5855 C1.62975,9.84216667 1.62975,10.158 1.77725,10.4146667 C2.30141667,11.3288333 5.13475,15.833 10.0205833,15.833 C10.0889167,15.833 10.15725,15.8321667 10.2255833,15.8305 C14.8314167,15.713 17.5114167,11.6555 18.2230833,10.4146667 C18.36975,10.158 18.36975,9.84216667 18.2230833,9.5855" id="path-1"></path>
              </defs>
              <g id="Icon/eye" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="🎨-Icon-Сolor" fill="#1A1A1A" fillRule="nonzero" xlinkHref="#path-1"></use>
              </g>
            </svg>
            {views}
          </div>
          {(!singleItemView || (singleItemView && count > 0)) &&
            <div className="PostFooter__comments">
              <img src={images.comment} alt="Comments" />
              <span className={cx({firstToComment: !count})}>{count > 0 ? count : 'Be first to comment'}</span>
            </div>
          }
          {teaser &&
            <div className="PostFooter__wrapperTeaserOverlay" />
          }
        </div>
      </div>
    </div>
  )
}
