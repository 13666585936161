import React            from 'react';
import { Link }         from 'react-router-dom';
import { ReactSVG }     from 'react-svg';
import { reduxForm,
         Field,
         FieldArray }   from 'redux-form';
import cx               from 'classnames';
import { TextField,
         VideoField,
         ColorField,
         ArrayField,
         ImageField }   from 'theme/Form';
import { images }       from 'theme/img/images';
import { url }          from 'theme/utils/validate';

import './PremiumProfileForm.sass';


export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit,
    submitting,
    organizationProfile
  }) => {
    return (
      <div className="PremiumProfileForm">
        <form onSubmit={handleSubmit}>
          <section>
            <Field
              name="premiumFeatures.title"
              className="medium-field"
              label="Main headline"
              placeholder="e.g. Our Long Standing Commitment"
              component={TextField}
            />
            <Field
              name="premiumFeatures.imgUrl"
              className="PremiumProfileForm__coverPhoto"
              label="Cover Photo"
              component={ImageField}
              placeholder="http://some-picture.png"
              validate={url}
            />
          </section>
          <section>
            <h5 className="subheader">Box content</h5>
            <Field
              name="premiumFeatures.boxBackgroundColor"
              label="Box background color"
              component={ColorField}
              options={['#44D19F', '#5C6373', '#C2D1D9']}
            />
            <Field
              name="premiumFeatures.info.title"
              className="medium-field"
              label="Title"
              placeholder="Type your title"
              component={TextField}
            />
            <Field
              name="premiumFeatures.info.text"
              label="Text"
              placeholder="Type your text..."
              component={TextField}
              componentType="textarea"
            />
            <Field
              name="premiumFeatures.info.url"
              label="Learn More Link"
              placeholder="ex. http://careers.company.com"
              component={TextField}
              validate={url}
            />
          </section>
          <section>
            <h5 className="subheader">Videos</h5>
            <FieldArray
              name="premiumFeatures.videos"
              component={ArrayField}
              buttonText="Upload video"
            >
              <VideoField />
            </FieldArray>
          </section>

          <section>
            <h5 className="subheader">News</h5>
            <FieldArray
              name="premiumFeatures.news"
              component={ArrayField}
              buttonText="Add more www"
              placeholder="https://"
            >
              <Field component={TextField} name="" validate={url} label="www" />
            </FieldArray>
          </section>

          <div className={cx('actions', {organizationProfile})}>
            {!organizationProfile &&
              <Link
                className="CompanyOnboarding__goBack btn-primary-outlined"
                to='/employer-onboarding/2'
              >
                <ReactSVG
                  src={images.arrowLeft}
                  aria-label="Go back"
                />
                Back
              </Link>
            }
            <button
              type="submit"
              className="btn-primary"
              disabled={submitting}
            >
              {organizationProfile ? 'Save' : 'Finish'}
            </button>
          </div>
        </form>
      </div>
    )
  }
)
