import React        from 'react';
import CustomRadio  from 'theme/CustomRadio';

const options = [
  {value: '1970-01-01', name: 'Any time', label: 'Any time'},
  {value: 'now-1M/d', name: 'Past month', label: 'Past month'},
  {value: 'now-1w/d', name: 'Past week', label: 'Past week'}
]

export default function RadioFilters({radioFilter, choose, selected}) {
  const click = option => {
    radioFilter.onChange(option.value);
    choose(option);
  }

  return (
    <div>
      {options.map((o, i) => (
        <div className="Filters__boxItem" key={i}>
          <CustomRadio onClick={() => click(o)} value={selected?.name === o.name} />
          <span className="Filters__boxItemName">{o.label}</span>
        </div>
      ))}
    </div>
  )
}
