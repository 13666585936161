import React                from 'react';
import { reduxForm,
         Field,
         FieldArray }       from 'redux-form';
import { TextField,
         Select,
         ArrayField,
         ImageField }       from 'theme/Form';
import { options }          from 'theme/content';
import { url }              from 'theme/utils/validate';


export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, user, filterOptions}) => {
    return (
      <div className="NewPartner__form">
        <h4>New Support Service</h4>
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            label="Name"
            placeholder="Type support service's name..."
            component={TextField}
            required
          />
          {user.isAdmin &&
            <Field
              name="logoUrl"
              label="Logo URL"
              placeholder="http://some-picture.png"
              component={ImageField}
              className="Partner__logo"
            />
          }
          <Field
            name="hrefUrl"
            label="Website URL"
            placeholder="http://some-website.com"
            component={TextField}
            validate={url}
            required
          />
          <Field
            name="founded"
            label="Founded"
            placeholder="1990"
            component={TextField}
          />
          <FieldArray
            name="cities"
            label="Cities"
            component={ArrayField}
            placeholder="San Francisco"
          >
            <Field name="" component={TextField} />
          </FieldArray>
          <FieldArray
            name="states"
            label="States"
            component={ArrayField}
          >
            <Field
              name="state"
              component={Select}
              options={options.states}
              filterOptions={filterOptions}
            />
          </FieldArray>
          <Field
            name="type"
            label="Type"
            placeholder="Select a type"
            component={Select}
            options={options.partnerTypes}
          />
          <button
            type="submit"
            className="btn-primary save"
            disabled={pristine || submitting}
          >
            SAVE
          </button>
        </form>
      </div>
    )
  }
)
