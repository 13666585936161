import React, { useState } from 'react';
import { ReactSVG }        from 'react-svg';
import cx                  from 'classnames';

import './MobileActions.sass';


export default function MobileActions({actions, floating, reverse, xl, initiallyVisible}) {
  const [visible, toggle] = useState(initiallyVisible ? true : false);
  const click = e => {
    e.stopPropagation();
    toggle(!visible);
  };

  return (
    <div className={cx("MobileActions", {floating})}>
      <button
        className={cx("MobileActions__button", {visible, floating, xl, reverse})}
        onClick={e => click(e)}
        type="button"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
          <g fill="none" fillRule="evenodd">
            <g fill={reverse ? "#FF4E00" : "#fff"} fillRule="nonzero">
              <g>
                <g>
                  <g transform="translate(-76 -472) translate(67.375 438.934) translate(0 25) translate(9 9)">
                    <circle cx="6" cy="15" r="2.25"/>
                    <circle cx="15" cy="15" r="2.25"/>
                    <circle cx="24" cy="15" r="2.25"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>

      <ul className={cx("MobileActions__actions", {visible, xl, floating})}>
        {actions.map(({fn, icon, text, bgColor, preventFromClosing, handlesModal}, i) => {
          const action = e => {
            e.stopPropagation();
            if (!preventFromClosing)
              toggle(false);

            fn();
          };

          return (
            <li
              key={i}
              onClick={handlesModal ? fn : e => action(e)}
              className="MobileActions__actionsItem"
            >
              <span>{text}</span>
              <div
                className={cx("MobileActions__actionsItemButton", {floating})}
                style={{backgroundColor: bgColor && `${bgColor}`}}
              >
                <ReactSVG
                  src={icon}
                  className="MobileActions__actionsItemIcon"
                />
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  );
}
