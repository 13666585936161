import React              from 'react';
import { connect }        from 'react-redux';
import { withState,
         lifecycle,
         compose }        from 'recompose';
import cx                 from 'classnames';
import { closeToast }     from 'theme/actions';
import { images }         from 'theme/img/images';
import { constants }      from 'theme/utils/constants';

import './Toast.sass';


const Toast = compose(
  withState('timeout', 'setTime', null),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { error, success, closeToast } = this.props;

      if (((prevProps.error.visible !== error.visible))) {
        setTimeout(() => {
          closeToast('error');
        }, constants.TOAST.TIMEOUT);
      }

      if (((prevProps.success.visible !== success.visible))) {
        setTimeout(() => {
          closeToast('success');
        }, constants.TOAST.TIMEOUT);
      }
    },
    componentWillUnMount() {
      clearTimeout(this.props.closeToast);
    }
  })
)(({error, success, closeToast}) => {
  return (
    <div className={cx("Toast", {visible: error.visible || success.visible, error: error.visible, success: success.visible})}>
      <div className={cx("Toast__icon", {success: success.visible, error: error.visible})}>
        <div className="Toast__iconCircle">
          <i
            className={cx(`fa fa-${error.visible ? 'exclamation' : 'check'}`, {success: success.visible, error: error.visible})}
            aria-hidden="true"
          >
          </i>
        </div>
      </div>
      <div className="Toast__content">
        {error.visible &&
          <h5>
            An error occurred while
            {error.type == 'SUBMIT' ? ' saving ' : ' fetching '}
            {error.origin?.toLowerCase()}
            {error.multiple ? 's' : ''}
          </h5>
        }
        {success.visible &&
          <h5>Thank you!</h5>
        }
        <p>{error.visible ? error.message : success.message}</p>
      </div>
      <div className="Toast__close">
        <img
          src={images.closeBlack}
          alt="Close toast"
          onClick={() => closeToast(error.visible ? 'error' : 'success')}
        />
      </div>
    </div>
  )
});

const mapDispatchToProps = dispatch => ({
  closeToast: type => dispatch(closeToast(type))
});

export default connect(
  ({error, success}) => ({error, success}),
  mapDispatchToProps
)(Toast);
