import React                    from 'react';
import { CSSTransition }        from 'react-transition-group';
import cx                       from 'classnames';

import './Tooltip.sass';

export default function Tooltip({className, children, open}) {
  return (
    <CSSTransition
      classNames="fade"
      in={open}
      appear
      unmountOnExit
      timeout={{exit: 500, enter: 0}}
    >
      {() => (
        <div className={cx("Tooltip", className)}>
          <div className="Tooltip__arrow" />
          <div className="Tooltip__container">
            {children}
          </div>
        </div>
      )}
    </CSSTransition>
  )
}
