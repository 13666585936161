import axios              from 'axios';
import { NO_CONNECTION,
         CONNECTED }      from 'store/types';
import qs                 from 'qs';

import * as localStorage  from './localStorage';
export *                  from './localStorage';


const isDev = (location.hostname == "localhost" || location.hostname == 'vetit.local' || (location.host.split(".").length == 4 && location.host.split(".")[2] != "vetitforward"));
const isStaging = (location.host == "vetit-staging.iterato.rs");
const isProduction = !isDev && !isStaging;
const root = ((isDev || isStaging) && "https://vetit-staging.iterato.rs/api") || location.origin + "/api";

export const API_CONF = {
  root: root,
  env: (isDev && "dev") || (isStaging && "staging") || (isProduction && "production") || "unknown",
  isDev: isDev,
  isStaging: isStaging,
  isProduction: isProduction,
  FACEBOOK_REDIRECT_DOMAIN: isProduction ? "https://vetitforward.com/auth/callback/facebook" : "https://vetit-staging.iterato.rs/auth/callback/facebook",
  LINKEDIN_REDIRECT_DOMAIN: isProduction ? "https://vetitforward.com/auth/callback/linkedin" : "https://vetit-staging.iterato.rs/auth/callback/linkedin",
  FACEBOOK_CLIENT_ID: isProduction ? "293834654513191" : "1150304238766558", // stg 2154154334813980
  LINKEDIN_CLIENT_ID: isProduction ? "863jhdn5667sv1" : "863jhdn5667sv1",
  LINKEDIN_AUTH_LINK: isProduction ? "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=863jhdn5667sv1&redirect_uri=https%3A%2F%2Fvetitforward.com%2Fauth%2Fcallback%2Flinkedin&scope=r_liteprofile%20r_emailaddress%20w_member_social" : "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=863jhdn5667sv1&redirect_uri=https%3A%2F%2Fvetit-staging.iterato.rs%2Fauth%2Fcallback%2Flinkedin&scope=r_liteprofile%20r_emailaddress%20w_member_social",
  RECAPTCHA_SITE_KEY: isProduction ? "6LfnO2cUAAAAADbJb7mKUwovGa-Xh5VmAAXA-tbB" : "6LcKDlkUAAAAAGW7wGd8NNDDsyRaM_O9G67Q_FdN",
  STATE_PARAM: "111111",
  CHARGEBEE_SITE: isProduction ? "https://vetitforward.com" : "https://vetit-staging.iterato.rs",
  CHARGEBEE_KEY: 'test_vbP98Tn3WkXcuAU0olVvfXX084cubuwFrQ',
  VA_CLIENT_ID: '0oa382skvnJNc8MSg2p7',
  VA_REDIRECT_URI: isProduction ? 'https://vetitforward.com/auth/callback/va' : 'https://vetit-staging.iterato.rs/auth/callback/va',
  MIL_REDIRECT_URI: isProduction ? 'https://vetitforward.com/auth/callback/mil' : 'https://vetit-staging.iterato.rs/auth/callback/mil'
};

const api = axios.create({
  baseURL: API_CONF.root,
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
});

export const configureInterceptors = (store) => {

  api.interceptors.request.use((config) =>
    new Promise((resolve) => {
      const auth = localStorage.loadAuth();
      if(auth.token) {
        var cfg = {
          ...config
        };
        cfg.headers = {
          ...cfg.headers,
          Authorization: `Bearer ${auth.token}`
        }
        resolve(cfg)
      } else {
        resolve(config);
      }
    })
  );

  api.interceptors.response.use(
    (response) => {
      store.dispatch({type: CONNECTED});
      return Promise.resolve(response);
    }
    , (error) => {
      if(!error.response)
        store.dispatch({type: NO_CONNECTION});
      return Promise.reject(error);
    }
  );
}

export default api;
