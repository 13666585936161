import React                        from 'react';
import { reduxForm,
         Field,
         Fields,
         FieldArray }               from 'redux-form';
import cx                           from 'classnames';
import { compose,
         setDisplayName }           from 'recompose';
import { MultipleField,
         Switch,
         ArrayField }               from 'theme/Form';
import { withStaticFormSections }   from 'theme/utils/recompose';
import { options, env }             from 'theme/content';
import SchoolRow                    from 'jobs/SchoolRow';
import CertificationRow             from 'jobs/CertificationRow';

import './EducationForm.sass';

const SchoolsField = () => (
  <FieldArray
    name="education"
    className=""
    component={ArrayField}
    buttonText="Add school"
  >
    <Fields
      names={['schoolName', 'degree', 'fieldOfStudy', 'graduationYear', 'location']}
      component={MultipleField}
      components={['input', 'select', 'select', 'input', 'input']}
      options={[[], options.formalEducationOptions, options.fieldOfStudyOptions]}
      keyName="education"
      keys={['schoolName', 'degree', 'fieldOfStudy', 'graduationYear', 'location']}
      placeholders={['e.g. City College of San Francisco', 'Select', 'Select', 'e.g. 2014', 'e.g. San Francisco, CA']}
      labels={['Name of school / institution', 'Degree', 'Field of study', 'Graduation year', 'Location']}
      column
    />
  </FieldArray>
)

const CertificationsField = () => (
  <FieldArray
    name="certifications"
    className=""
    component={ArrayField}
    buttonText="Add"
  >
    <Fields
      names={['certificateName', 'issuingOrganization', 'issueDate', 'expirationDate']}
      component={MultipleField}
      components={['input', 'input', 'datepicker', 'datepicker']}
      keyName="certifications"
      keys={['certificateName', 'issuingOrganization', 'issueDate', 'expirationDate']}
      placeholders={['e.g. Microsoft 365 Certified: Security Administrator Associate', 'e.g. Microsoft', env.DATE_FORMAT, env.DATE_FORMAT]}
      labels={['Name', 'Issuing organization', 'Issue date', 'Expiration Date']}
    />
  </FieldArray>
)

export default compose(
  setDisplayName('EducationForm'),
  withStaticFormSections()
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, submitting, pristine, isSettings, className, isOnboarding, isEdited, toggleEditedSections, initialValues}) => {
    return (
      <div className="EducationForm">
        <form onSubmit={handleSubmit} className="EducationForm__form">
          <div className={cx("EducationForm__history form-container-section", className)}>
            {isOnboarding &&
              <>
                <h5 className="subheader">Education history</h5>
                <SchoolsField />
              </>
            }
            {isSettings &&
              <>
                <div className="EducationForm__heading form-container-section-heading">
                  <h5 className="subheader">Education history</h5>
                  {isEdited('education')
                    ?
                      <button
                          type="submit"
                          className="btn-secondary"
                          disabled={isSettings ? submitting : (pristine || submitting)}
                        >
                          Save
                      </button>
                    :
                      <span className="edit-btn btn-secondary-outlined" onClick={() => toggleEditedSections('education')}>Edit</span>
                  }
                </div>
                <div>
                  {isEdited('education')
                    ? <SchoolsField />
                    : <>
                        {initialValues.education && initialValues.education.map((item, i) => (
                          <SchoolRow key={i} school={item} />
                        ))}
                      </>
                  }
                </div>
              </>
            }
          </div>
          <div className="form-container-section">
            <Field
              name="isContinuingEducation"
              label="Are you considering continuing your education?"
              component={Switch}
              options={options.switchOptions}
            />
          </div>
          <div className="form-container-section EducationForm__certifications">
            {isOnboarding &&
              <>
                <h5 className="subheader">Certifications and courses</h5>
                <CertificationsField />
              </>
            }
            {isSettings &&
              <>
                <div className="EducationForm__heading form-container-section-heading">
                  <h5 className="subheader">Certifications and courses</h5>
                  {isEdited('certifications')
                    ?
                      <button
                          type="submit"
                          className="btn-secondary"
                          disabled={isSettings ? submitting : (pristine || submitting)}
                        >
                          Save
                      </button>
                    :
                      <span className="edit-btn btn-secondary-outlined" onClick={() => toggleEditedSections('certifications')}>Edit</span>
                  }
                </div>
                <div>
                  {isEdited('certifications')
                    ? <CertificationsField />
                    : <>
                        {initialValues.certifications && initialValues.certifications.map((c, i) => (
                          <CertificationRow key={i} certification={c} />
                        ))}
                      </>
                  }
                </div>
              </>
            }
          </div>
          <button
            type="submit"
            className="btn-primary EducationForm__save"
            disabled={submitting}
          >
            {isSettings ? 'Save' : 'Save & Continue'}
          </button>
        </form>
      </div>
    )
  }
))
