import React, { useState } from 'react';
import cx                  from 'classnames';
import { images }          from 'theme/img/images';

import './SortDropdown.sass';


export default function SortDropdown({selected, sort, options, direction}) {
  const [dropdownVisible, toggle] = useState(false);

  const handleClick = option => {
    sort(option, direction === 'down' ? 'up' : 'down');
    toggle(false);
  }

  return (
    <div className="SortDropdown">
      <button
        className={cx({dropdownVisible})}
        onClick={() => toggle(!dropdownVisible)}
      >
        <i className={`fa fa-chevron-${direction}`} aria-hidden="true"></i>
        {selected.label}
        <img src={images.down} alt="down" />
      </button>
      {dropdownVisible &&
        <div className="SortDropdown__dropdown">
          <ul>
            {options?.map((o, i) => (
              <li
                key={i}
                className={cx({active: selected.value === o.value})}
                onClick={() => handleClick(o)}
              >
                {o.label}
                {selected.value === o.value &&
                  <i className={`fa fa-chevron-${direction}`} aria-hidden="true"></i>
                }
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  )
}
