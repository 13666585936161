import React                        from 'react';
import { connect }                  from 'react-redux';
import { withRouter }               from 'react-router';
import { Link }                     from 'react-router-dom';
import { compose,
         setDisplayName,
         withState,
         withProps,
         withHandlers }             from 'recompose';
import Header                       from 'theme/Header';
import MessageSection               from 'theme/MessageSection';
import { withPasswordToggle }       from 'theme/utils/recompose';
import { resetPassword,
         requestNewPassword }       from 'auth/actions';
import { closeConfirmationMessage } from 'store/actions';

import EmailForm                    from './EmailForm';
import PasswordForm                 from './PasswordForm';

import './ResetPassword.sass';


const emailFormName = 'EmailForm';
const passwordFormName = 'PasswordForm';

const ResetPassword = compose(
  setDisplayName('ResetPassword'),
  withPasswordToggle(),
  withState('submitted', 'setSubmitted', false),
  withHandlers({
    submit: ({submit, setSubmitted}) => values => {
      return submit(values)
      .then(response => setSubmitted(response));
    }
  }),
  withProps(({match}) => ({
    mailToken: match.params.mailToken
  }))
)(({submit, submitted, setSubmitted, mailToken, closeConfirmationMessage
}) => {
  return (
    <div className="ResetPassword page">
      <Header />
      {submitted
        ?
          <div className="container">
            {mailToken
              ?
                <MessageSection
                  title="Successful password reset!"
                  text="You can now use your new password to log in to your account."
                >
                  <Link
                    className="btn-primary"
                    onClick={closeConfirmationMessage}
                    to="/auth/signin"
                  >
                    Go to signin
                  </Link>
                </MessageSection>
              :
                <MessageSection
                  title="Check your email"
                  text="A password reset link was sent to your email. Follow the instructions in the message."
                >
                  <Link to='/reset-password' className="btn-primary-outlined" onClick={() => setSubmitted(false)}>
                    Send Again
                  </Link>
                </MessageSection>
            }
          </div>
        :
          <div className="container">
            <div className="ResetPassword__card">
              <h3>Reset your password</h3>
              {!mailToken &&
                <p>Don’t worry! Just enter your email below to receive a password reset link.</p>
              }
              <div>
                {mailToken
                  ?
                    <PasswordForm
                      form={passwordFormName}
                      onSubmit={submit}
                      mailToken={mailToken}
                    />
                  :
                    <EmailForm
                      form={emailFormName}
                      onSubmit={submit}
                      notSubmitted={!submitted && !mailToken}
                    />
                }
              </div>
            </div>
          </div>
      }
    </div>
  )
})

const mapDispatchToProps = (dispatch, {match}) => {
  const mailToken = match.params.mailToken;
  return {
    closeConfirmationMessage,
    submit: values => mailToken
      ? dispatch(resetPassword(values, mailToken))
      : dispatch(requestNewPassword(values))
  }
};

export default withRouter(connect(
  null,
  mapDispatchToProps
)(ResetPassword));
