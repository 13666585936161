import React                from 'react';
import { reduxForm,
         Field }            from 'redux-form';
import { Radio }            from 'theme/Form';

export default reduxForm({
  enableReinitialize: true
})(({handleSubmit, fieldList, pristine, submitting}) => {
  return (
    <div className="OrganizationSettings__form">
      <form onSubmit={handleSubmit}>
        {fieldList.map(f => (
          <Field
            key={f.name}
            name={f.name}
            label={f.label}
            component={Radio}
            mode="checkbox"
          />
        ))}
        <button
          type="submit"
          className="btn-primary"
          disabled={pristine || submitting}
        >
          SAVE
        </button>
      </form>
    </div>
  )
})