import api, { loadAuth }      from 'api';
import { omit }               from 'underscore';
import { mapReviewAction }    from 'theme/utils/helpers';

const getFollowed = () => {
  return api({
    method: 'get',
    url: '/followed',
    params: {page: 0, pageSize: 1000}
  });
}

function fetchCompanies({page, pageSize = 50, filters = {}, query}) {
  const {sort, ...restFilters} = filters;
  const newFilters = Object.entries(restFilters)
  .filter(([value]) => value !== undefined && value !== null)
  .map(([k, value]) => `${k}:${value}`);

  return api({
    method: 'get',
    url: '/employers',
    params: {
      page: page || 0,
      pageSize,
      filter: newFilters,
      sort: sort || 'employeeReviewCount:d',
      ...query && {query}
    }
  })
}

function fetchCompany({id}) {
  const user = loadAuth();
  const loggedIn = Object.keys(user).length > 0;
  const followedReq = loggedIn && getFollowed();
  const companyReq = api({
    method: 'get',
    url: `/employers/${id}`
  });

  if(!loggedIn)
    return companyReq;

  return Promise.all([companyReq, followedReq])
  .then(([company, followed]) => {
    const c = followed.data.data.find(f => f.id == company.data.id);
    return {
      ...company.data,
      isFollowed: Boolean(c)
    };
  });
}

function fetchCompanyJobs({page = 0, pageSize = 10, organizationId}) {
  return api({
    method: 'get',
    url: '/job-openings/matched',
    params: {
      page,
      pageSize,
      filter: organizationId
    }
  })
}

function reviewAction(type, companyId, reviewId, action, isVote) {
  return api(
    mapReviewAction({
      method: 'post',
      action: isVote ? (action === 'revokeVote' ? 'revokeVote' : 'vote') : null,
      url: `/employers/${companyId}/reviews/${type}/${reviewId}`,
      params: isVote
        ? {type: action}
        : {action}
    })
  );
}

function fetchJobReviews({id, page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: `/employers/${id}/reviews/jobs`,
    params: {page, pageSize}
  });
}

function fetchInterviews({id, page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: `/employers/${id}/reviews/interviews`,
    params: {page, pageSize}
  });
}

function postCompany({values}) {
  return new Promise((resolve, reject) => {
    api({
      method: 'post',
      url: `/employers`,
      headers: {
        'Captcha': values.captcha
      },
      data: omit(values, 'captcha')
    })
    .then(resolve, reject)
  });
}

function postInterviewReview({values}) {
  const newEmployer = values.employer.new;
  const newEmployerRequest = newEmployer ? postCompany({values: {name: newEmployer, captcha: values.captcha}}) : Promise.resolve();
  return newEmployerRequest.then(
    (response) => {
      const employerId = newEmployer ? response.data.id : (values.employer.id || values.employer);
      const headers = values.captcha ? {'Captcha': values.captcha} : {};
      return api({
        method: 'post',
        url: `/employers/${employerId}/reviews/interviews`,
        headers: headers,
        data: omit(values, 'agreement', 'employer', 'invited', 'captcha')
      });
    }
  );
}

function postReview({values}) {
  const newEmployer = values.employer.new;
  const newEmployerRequest = newEmployer ? postCompany({values: {name: newEmployer, captcha: values.captcha}}) : Promise.resolve();
  return newEmployerRequest.then(
    (response) => {
      const employerId = newEmployer ? response.data.id : (values.employer.id || values.employer);
      const headers = values.captcha ? {'Captcha': values.captcha} : {};
      return api({
        method: 'post',
        url: `/employers/${employerId}/reviews/jobs`,
        headers: headers,
        data: omit(values, 'agreement', 'employer', 'captcha')
      });
    }
  )
}

function fetchFilters() {
  return api({
    method: 'get',
    url: 'employers/filters'
  });
}

function fetchReview({companyId, reviewType, reviewId}) {
  return api({
    method: 'get',
    url: `/employers/${companyId}/reviews/${reviewType}/${reviewId}`
  });
}

export function updateReview(companyId, reviewType, reviewId, values) {
  return (dispatch) => {
    return api({
      method: 'put',
      url: `/employers/${companyId}/reviews/${reviewType}/${reviewId}`,
      data: omit(values, 'id', 'employer', 'metadata')
    })
    .then(() => dispatch(getReview(companyId, reviewType, reviewId, reviewId, {loader: false})))
  }
}

export const getAutocomplete = ({prefix, sort = 'name:a', field, nameOnly = false}) => () => {
  return api({
    method: 'get',
    url: nameOnly ? 'employers/autocomplete' : 'employers',
    params: {
      prefix,
      sort: nameOnly ? null : sort,
      field: nameOnly ? field = 'city' : field
    }
  })
  .then(({data}) => data);
}

export const verifyEmployer = ({userId, isRejected}) => () => {
  return api({
    method: 'post',
    url: '/auth/confirmation/employer',
    data: {
      loginUrl: location.origin.concat('/auth/signin?mailToken={TOKEN}'),
      userId,
      adminAccepted: isRejected ? false : true
    }
  })
}

export const getCompanies = ({page, pageSize, query, filters, loader = true} = {}) => {
  return {type: 'COMPANIES/FETCH', req: fetchCompanies, page, pageSize, query, filters, loader}
}

export const getCompany = (id, opts = {loader: true}) => {
  return {type: `COMPANY:${id}/FETCH`, id, req: fetchCompany, ...opts}
}

export const getCompanyJobs = ({page, pageSize, loader, organizationId} = {}) => {
  return {type: 'COMPANY_JOBS/FETCH', req: fetchCompanyJobs, page, pageSize, loader, organizationId}
}

export const getJobReviews = (id, opts = {loader: true}) => {
  return {type: `COMPANY:${id}:REVIEWS/FETCH`, id, req: fetchJobReviews, ...opts}
}

export const getInterviews = (id, opts = {loader: true}) => {
  return {type: `COMPANY:${id}:INTERVIEWS/FETCH`, id, req: fetchInterviews, ...opts}
}

export const addCompany = ({values, form, resolve, reject}) => {
  return {type: 'COMPANY/SUBMIT', values, req: postCompany, form, resolve, reject}
}

//------------------------------ FOLLOWING ---------------------------

export const follow = companyId => {
  return () => {
    return api({
      method: 'post',
      url: `/employers/${companyId}/follow`
    })
  }
}

export const unfollow = companyId => {
  return () => {
    return api({
      method: 'delete',
      url: `/employers/${companyId}/follow`
    })
  }
}



//------------------------------ ADDING REVIEWS ---------------------------
export const addInterviewReview = ({values, form, resolve, reject}) => {
  return {type: 'COMPANY_INTERVIEW_REVIEW/SUBMIT', values, req: postInterviewReview, form, resolve, reject}
}

export const addCompanyReview = ({values, form, resolve, reject}) => {
  return {type: 'COMPANY_REVIEW/SUBMIT', values, req: postReview, form, resolve, reject}
}


//------------------------------ VIEWS ---------------------------
export const viewJobReview = (companyId, reviewId) => () => {
  return reviewAction('jobs', companyId, reviewId, 'View');
}

export const viewInterview = (companyId, reviewId) => () => {
  return reviewAction('interviews', companyId, reviewId, 'View');
}


//------------------------------ FOLLOW REVIEWS ---------------------------
export const followReview = (companyId, reviewId, type) => () => {
  const reviewType = type == 'reviews' ? 'jobs' : 'interviews';
  return reviewAction(reviewType, companyId, reviewId, 'Follow');
}

export const unfollowReview = (companyId, reviewId, type) => () => {
  const reviewType = type == 'reviews' ? 'jobs' : 'interviews';
  return reviewAction(reviewType, companyId, reviewId, 'Unfollow');
}

//------------------------------ UP- & DOWNVOTING ---------------------------
export const voteJobReview = (companyId, reviewId, action, {page, pageSize, loadReview} = {}) => {
  return (dispatch) => {
    return reviewAction('jobs', companyId, reviewId, action, true)
    .then(() => dispatch(loadReview
      ? getReview({companyId, reviewId, reviewType: 'jobs', loader: false})
      : getJobReviews(companyId, {loader: false, page, pageSize})
    ));
  }
}

export const voteInterview = (companyId, reviewId, action, {page, pageSize, loadReview} = {}) => {
  return (dispatch) => {
    return reviewAction('interviews', companyId, reviewId, action, true)
    .then(() => dispatch(loadReview
      ? getReview({companyId, reviewId, reviewType: 'interviews', loader: false})
      : getInterviews(companyId, {loader: false, page, pageSize})
    ));
  }
}

//---------------------SINGLE REVIEW---------------------------
export const getReview = ({companyId, reviewType, reviewId, loader}) => {
  return {type: `REVIEW:${reviewId}/FETCH`, req: fetchReview, companyId, reviewType, reviewId, loader}
}

export const getFilters = (opts = {}) => {
  return {type: 'FILTER/FETCH', req: fetchFilters, ...opts}
}
