import React    from 'react';
import cx       from 'classnames';
import Rating   from 'theme/Rating';

import './InterviewsStats.sass'

const sourceOptions = {
  online: 'Online Ad',
  inPerson: '3rd Party',
  referral: 'Insider'
}
const typeOptions = {
  fullTime: 'full time',
  partTime: 'part time',
  onCampus: 'on campus',
  onLine: 'on-line'
}
const overallLabel = (n) => {
  if(n < 2) return 'Very Low';
  if(n < 3) return 'Low';
  if(n < 4) return 'Average';
  if(n < 5) return 'High';
  else return 'Very High';
}

export default function InterviewsStats ({statistics, className, organization, allReviewsCount}) {
  const interviewDifficulty = statistics.interviewDifficulty % 1 != 0 ? statistics.interviewDifficulty.toFixed(2) : statistics.interviewDifficulty;
  return(
    <div className={cx("InterviewsStats", className)}>
      {statistics.averageOverallRating &&
        <div className="InterviewsStats__group InterviewsStats__group--rating">
          <div className="InterviewsStats__rating">
            <h2>{Math.ceil(statistics.averageOverallRating)}</h2>
            <Rating rating={statistics.averageOverallRating} size="semi-md" />
            <h4>Overall Rating</h4>
            <p>Based on {allReviewsCount} review{allReviewsCount > 1 ? 's' : ''}</p>
          </div>
          <p className="InterviewsStats__subtitle">Experience</p>
        </div>
      }
      {statistics.interviewDifficulty &&
        <div className="InterviewsStats__group">
          <div className="InterviewsStats__difficulty">
            <h2>{interviewDifficulty}</h2>
            <h4>{overallLabel(statistics.interviewDifficulty)}</h4>
          </div>
          <p className="InterviewsStats__subtitle">Difficulty</p>
        </div>
      }
      {statistics.interviewSourceDistribution &&
        <div className="InterviewsStats__group">
          {Object.keys(sourceOptions).map((key => {
            const percent = `${Math.round(statistics.interviewSourceDistribution[key])}%`;
            return (
              <div className="InterviewsStats__stat" key={key}>
                <h4>{percent}</h4>
                <span>{sourceOptions[key]}</span>
                <div className="InterviewsStats__statBar">
                  <div
                    className="InterviewsStats__statBar_filled"
                    style={{width: percent}}
                  />
                </div>
              </div>
            )
          }))}
          <p className="InterviewsStats__subtitle">Source</p>
        </div>
      }
      {statistics.typeStats &&
        <div className="InterviewsStats__group">
          {Object.keys(typeOptions).map((key => {
            const percent = `${Math.round(statistics.typeStats[key])}%`;
            return (
              <div className="InterviewsStats__stat" key={key}>
                <h4>{percent}</h4>
                <span>{typeOptions[key]}</span>
                <div className="InterviewsStats__statBar">
                  <div
                    className="InterviewsStats__statBar_filled"
                    style={{width: percent}}
                  />
                </div>
              </div>
            )
          }))}
          <p className="InterviewsStats__subtitle">Source</p>
        </div>
      }
      {statistics.admissionPercent >= 0 &&
        <div className="InterviewsStats__group">
          <div className="InterviewsStats__difficulty">
            <h2>{Math.round(statistics.admissionPercent)}%</h2>
            <h4>{organization.class == "Educator" ? "Received" : "Received"}</h4>
          </div>
          <p className="InterviewsStats__subtitle">Offers</p>
        </div>
      }
    </div>
  )
}
