import React                from 'react';
import Loader               from 'theme/Loader';

import './StarSection.sass';

export default function StarSection({orgType, industry, loading}) {
  return(
    <div className="StarSection">
      <Loader loading={loading} />
      <span className="fa fa-star" />
      <div>
        <h4>Platinum {orgType}</h4>
        <p>{industry}</p>
      </div>
    </div>
  )
}
