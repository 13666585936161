import React    from 'react';
import cx       from 'classnames';
import Rating   from 'theme/Rating';

import './ManagerStats.sass';


export default function ManagerStats ({statistics, className, allReviewsCount}) {
  return(
    <div className={cx("ManagerStats", className)}>
      <div className="ManagerStats__rating">
        <h1>{Math.ceil(statistics.averageOverallRating)}</h1>
        <Rating rating={statistics.averageOverallRating} size="md" />
        <h5>Overall Veteran Rating</h5>
        <p>Based on {allReviewsCount} review{allReviewsCount === 1 ? '' : 's'}</p>
      </div>
      {statistics.recommendationPercent &&
        <div className="ManagerStats__recommend">
          <h1>{Math.round(statistics.recommendationPercent)}%</h1>
          <h5>Recommend to Friends</h5>
        </div>
      }
    </div>
  )
}
