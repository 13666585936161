import React            from 'react';
import { REVIEW_TYPES } from 'theme/content';
import { createSlug }   from 'theme/utils/helpers';

const REMOVED_ITEM_MSG = type => <>Your {type} has been removed from the platform due to a violation of the platform rules. In order to get more information, please contact us on <strong>admin@vetitforward.com</strong></>;

const NOTIFICATION_TYPES = {
  QuestionLike: 'QuestionLike',
  QuestionDislike: 'QuestionDislike',
  QuestionLove: 'QuestionLove',
  QuestionSupport: 'QuestionSupport',
  QuestionCelebrate: 'QuestionCelebrate',
  QuestionCurious: 'QuestionCurious',
  QuestionSad: 'QuestionSad',
  QuestionAngry: 'QuestionAngry',
  QuestionReplied: 'QuestionReplied',
  QuestionDeleted: 'QuestionDeleted',
  QuestionAdded: 'QuestionAdded',
  FollowQuestionReplied: 'FollowQuestionReplied',
  ReviewLike: 'ReviewLike',
  ReviewDislike: 'ReviewDislike',
  ReviewLove: 'ReviewLove',
  ReviewSupport: 'ReviewSupport',
  ReviewCelebrate: 'ReviewCelebrate',
  ReviewCurious: 'ReviewCurious',
  ReviewSad: 'ReviewSad',
  ReviewAngry: 'ReviewAngry',
  ReviewCommented: 'ReviewCommented',
  ReviewDeleted: 'ReviewDeleted',
  CommentReplied: 'CommentReplied',
  CommentLike: 'CommentLike',
  CommentDislike: 'CommentDislike',
  CommentLove: 'CommentLove',
  CommentSupport: 'CommentSupport',
  CommentCelebrate: 'CommentCelebrate',
  CommentCurious: 'CommentCurious',
  CommentSad: 'CommentSad',
  CommentAngry: 'CommentAngry',
  CommentDeleted: 'CommentDeleted',
  JobMatched: 'JobMatched', // TODO
  InvitationApplied: 'InvitationApplied',
  ReviewAdded: 'ReviewAdded',
  JobApplied: 'JobApplied',
  OrgPostLike: 'OrgPostLike',
  OrgPostDislike: 'OrgPostDislike',
  OrgPostLove: 'OrgPostLove',
  OrgPostSupport: 'OrgPostSupport',
  OrgPostCelebrate: 'OrgPostCelebrate',
  OrgPostCurious: 'OrgPostCurious',
  OrgPostSad: 'OrgPostSad',
  OrgPostAngry: 'OrgPostAngry',
  OrgPostCommented: 'OrgPostCommented',
  OrgPostDeleted: 'OrgPostDeleted'
};

const CREATE_NOTIFICATION_TEXTS = (organizationName, jobTitle, topicName) => ({
  [NOTIFICATION_TYPES.QuestionLike]: 'Someone likes your question',
  [NOTIFICATION_TYPES.QuestionDislike]: 'Someone dislikes your question',
  [NOTIFICATION_TYPES.QuestionLove]: 'Someone loves your question',
  [NOTIFICATION_TYPES.QuestionSupport]: 'Someone supports your question',
  [NOTIFICATION_TYPES.QuestionCelebrate]: 'Someone celebrates your question',
  [NOTIFICATION_TYPES.QuestionCurious]: 'Someone finds your question curious',
  [NOTIFICATION_TYPES.QuestionSad]: 'Someone finds your question sad',
  [NOTIFICATION_TYPES.QuestionAngry]: 'Someone is angry about your question',
  [NOTIFICATION_TYPES.QuestionReplied]: 'Someone replied to your question',
  [NOTIFICATION_TYPES.QuestionDeleted]: REMOVED_ITEM_MSG('question'),
  [NOTIFICATION_TYPES.QuestionAdded]: <>Someone asked a question in the topic of <strong>{topicName}</strong></>,
  [NOTIFICATION_TYPES.FollowQuestionReplied]: 'Someone replied to a question you follow',
  [NOTIFICATION_TYPES.ReviewLike]: 'Someone likes your review',
  [NOTIFICATION_TYPES.ReviewDislike]: 'Someone dislikes your review',
  [NOTIFICATION_TYPES.ReviewLove]: 'Someone loves your review',
  [NOTIFICATION_TYPES.ReviewSupport]: 'Someone supports your review',
  [NOTIFICATION_TYPES.ReviewCelebrate]: 'Someone celebrates your review',
  [NOTIFICATION_TYPES.ReviewCurious]: 'Someone finds your review curious',
  [NOTIFICATION_TYPES.ReviewSad]: 'Someone finds your review sad',
  [NOTIFICATION_TYPES.ReviewAngry]: 'Someone is angry about your review',
  [NOTIFICATION_TYPES.ReviewCommented]: 'Someone commented on your review',
  [NOTIFICATION_TYPES.ReviewDeleted]: REMOVED_ITEM_MSG('review'),
  [NOTIFICATION_TYPES.CommentReplied]: 'Someone replied to your comment',
  [NOTIFICATION_TYPES.CommentLike]: 'Someone likes your comment',
  [NOTIFICATION_TYPES.CommentDislike]: 'Someone dislikes your comment',
  [NOTIFICATION_TYPES.CommentLove]: 'Someone loves your comment',
  [NOTIFICATION_TYPES.CommentSupport]: 'Someone supports your comment',
  [NOTIFICATION_TYPES.CommentCelebrate]: 'Someone celebrates your comment',
  [NOTIFICATION_TYPES.CommentCurious]: 'Someone finds your comment curious',
  [NOTIFICATION_TYPES.CommentSad]: 'Someone finds your comment sad',
  [NOTIFICATION_TYPES.CommentAngry]: 'Someone is angry about your comment',
  [NOTIFICATION_TYPES.CommentDeleted]: REMOVED_ITEM_MSG('comment'),
  [NOTIFICATION_TYPES.JobMatched]: 'We matched new jobs for you',
  [NOTIFICATION_TYPES.InvitationApplied]: <><strong>{organizationName}</strong> invited you to apply to its open <strong>{jobTitle}</strong> job</>,
  [NOTIFICATION_TYPES.ReviewAdded]: <>Someone added a review of <strong>{organizationName}</strong></>,
  [NOTIFICATION_TYPES.JobApplied]: <>Someone applied to your <strong>{jobTitle}</strong> job</>,
  [NOTIFICATION_TYPES.OrgPostLike]: 'Someone likes your post',
  [NOTIFICATION_TYPES.OrgPostDislike]: 'Someone dislikes your post',
  [NOTIFICATION_TYPES.OrgPostLove]: 'Someone loves your post',
  [NOTIFICATION_TYPES.OrgPostSupport]: 'Someone supports your post',
  [NOTIFICATION_TYPES.OrgPostCelebrate]: 'Someone celebrates your post',
  [NOTIFICATION_TYPES.OrgPostCurious]: 'Someone finds your post curious',
  [NOTIFICATION_TYPES.OrgPostSad]: 'Someone finds your post sad',
  [NOTIFICATION_TYPES.OrgPostAngry]: 'Someone is angry about your post',
  [NOTIFICATION_TYPES.OrgPostCommented]: 'Someone commented on your post',
  [NOTIFICATION_TYPES.OrgPostDeleted]: REMOVED_ITEM_MSG('post')
});

const addParams = (id, parentId, questionId) => `notification=1&commentId=${id}&parentId=${parentId}&threadId=${questionId}`;

const createCommentLink = ({type, commentId, parentId, questionId, adminPostId, organizationName, organizationId, reviewType, reviewId}, user) => {
  let url;

  switch(type) {
    case 'post': // admin post
      url = user.organizationId
        ? `/org-admin/feed/post/${adminPostId}`
        : `/dashboard/feed/post/${adminPostId}`;
      break;
    case 'review':
      url = REVIEW_TYPES[reviewType]?.organizationsAlt == 'companies'
        ? `/${REVIEW_TYPES[reviewType]?.organizationsAlt}/${organizationId}/${createSlug(organizationName)}/${REVIEW_TYPES[reviewType]?.section}/${REVIEW_TYPES[reviewType]?.type}/${reviewId}`
        : `/${REVIEW_TYPES[reviewType]?.organizationsAlt}/${organizationId}/${createSlug(organizationName)}/${REVIEW_TYPES[reviewType]?.section}/${reviewId}`;
      break;
    default: // community post
      url = `/community/post/${questionId}`;
  }

  return `${url}?${addParams(commentId, parentId, questionId)}`;
};

export const createNotificationTextAndLink = ({content, type}, user) => {
  const {questionId, adminPostId, organizationName, organizationId, jobTitle, topicName, jobApplicationId, jobOpeningId, reviewId, reviewType} = content;

  const links = {
    review: REVIEW_TYPES[reviewType]?.organizationsAlt == 'companies'
      ? `/${REVIEW_TYPES[reviewType]?.organizationsAlt}/${organizationId}/${createSlug(organizationName)}/${REVIEW_TYPES[reviewType]?.section}/${REVIEW_TYPES[reviewType]?.type}/${reviewId}`
      : `/${REVIEW_TYPES[reviewType]?.organizationsAlt}/${organizationId}/${createSlug(organizationName)}/${REVIEW_TYPES[reviewType]?.section}/${reviewId}`,
    communityPost: `/community/post/${questionId}`,
    jobOpening: `/companies/${organizationId}/${createSlug(organizationName)}/job-offers/${jobOpeningId}`,
    jobApplication: `/org-admin/candidate/${jobApplicationId}`,
    matchedJobs: '/dashboard/jobs/matched',
    adminPost: `/org-admin/feed/post/${adminPostId}`,
    comment: createCommentLink(content, user)
  };

  const findLink = () => {
    if (type.includes('Question'))
      return links.communityPost;
    else if (type.includes('Review'))
      return links.review;
    else if (type.includes('Comment'))
      return links.comment;
    else if (type.includes('OrgPost'))
      return links.adminPost;
    else if (type.includes('JobMatched'))
      return links.matchedJobs;
    else if (type.includes('JobApplied'))
      return links.jobApplication;
    else // InvitationApplied
      return links.jobOpening;
  }

  return {
    text: CREATE_NOTIFICATION_TEXTS(organizationName, jobTitle, topicName)[type],
    link: findLink()
  }
}
