import React                from 'react';
import { connect }          from 'react-redux';
import { reduxForm,
         formValueSelector,
         Field }            from 'redux-form';
import { TextField,
         StaticField,
         Datepicker,
         Select,
         RadioLabels }      from 'theme/Form';
import { options, env }     from 'theme/content';
import { getMosOptions }    from 'theme/content/mosOptions';

export default connect(
  (state, props) => ({
    serviceBranchValue: formValueSelector(props.form)(state, 'serviceBranch')
  })
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, serviceBranchValue, getOrganizations, getTopics, initialValues, showModal}) => {
    return (
      <div className="User__form">
        <h4>User Profile</h4>
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            label="Email"
            component={StaticField}
          />
          <Field
            name="firstName"
            label="First Name"
            placeholder="Type first name..."
            component={StaticField}
          />
          <Field
            name="lastName"
            label="Last Name"
            placeholder="Type last name..."
            component={StaticField}
          />
          <Field
            name="championTopics"
            label="Assign topics"
            component={Select}
            getAutocomplete={getTopics}
            placeholder="Assign topics to user"
            async
            isMulti
          />
          <Field
            name="organizationId"
            label="Organization"
            placeholder="Select organization..."
            component={Select}
            getAutocomplete={getOrganizations}
            async
          />
          {initialValues?.organizationId &&
            <div className="User__formRemoveOrg">
              <span
                className="remove"
                onClick={showModal}
              >
                Remove Organization
              </span>
            </div>
          }
          <h6>Reviewer Information</h6>
          <Field
            name="serviceBranch"
            label="Service Branch"
            className="block"
            component={RadioLabels}
            options={options.branchRange}
          />
          <Field
            name="mos"
            label="Military Occupation"
            component={Select}
            options={getMosOptions(serviceBranchValue)}
          />
          <Field
            name="etsPaygrade"
            label="Highest Paygrade Attained"
            placeholder="e.g. E5 or Spouse"
            component={TextField}
          />
          <Field
            name="etsSeparationDate"
            label="ETS/Separation Date"
            placeholder={env.DATE_FORMAT}
            component={Datepicker}
          />
          <button
            type="submit"
            className="btn-primary save"
            disabled={pristine || submitting}
          >
            SAVE
          </button>
        </form>
      </div>
    )
  }
))
