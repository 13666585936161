import React, { useState }  from 'react';
import { connect }          from 'react-redux';
import { ReactSVG }         from 'react-svg';
import { Link }             from 'react-router-dom';
import cx                   from 'classnames';
import Loader               from 'theme/Loader';
import ManagerLogo          from 'theme/ManagerLogo';
import HeadingNav           from 'theme/HeadingNav';
import Rating               from 'theme/Rating';
import { images }           from 'theme/img/images';

import './PageHeading.sass';


const ActionsBtn = ({title, links}) => {
  const [expanded, toggle] = useState(false);
  const click = () => toggle(!expanded);

  return (
    <div className={cx("PageHeading__actionsBtn", {expanded})}>
      <button
        className="btn btn-primary"
        onClick={click}
      >
        {title}
        <ReactSVG
          src={images.expandBig}
          aria-label="Add a review"
        />
      </button>
      {expanded &&
        <div className="PageHeading__actionsBtnDropdown">
          {links.map(({link, name}, i) => (
            <Link
              key={i}
              className="PageHeading__actionsBtnLink"
              to={link}
            >
              {name}
            </Link>
          ))}
        </div>
      }
    </div>
  )
};

function PageHeading({manager, activeSection, sections, prefix, loading, user, follow, unfollow, isOrgAdminPage, isStarEmployer, premiumEnabled, openModal, actionsBtnProps, isMobileNavScrolledRight}) {
  const { url, hrefUrl, name, statistics, id, isFollowed, slug } = manager;
  const managerUrl = url || hrefUrl;

  return (
    <div className="PageHeading">
      <Loader loading={loading} />
      <div className="PageHeading__content">
        <ManagerLogo
          className={cx("PageHeading__logo", {orgProfile: !isOrgAdminPage})}
          manager={manager}
          size="xl"
        />
        <div className={cx("PageHeading__title", {orgProfile: !isOrgAdminPage})}>
          {isOrgAdminPage
            ?
              <h3>Hello<br />{name}</h3>
            :
              <h3>
                {name}
                {isStarEmployer && premiumEnabled &&
                  <img
                    src={images.shield}
                    alt="badge"
                    className="PageHeading__badge"
                  />
                }
              </h3>
          }
          {!isOrgAdminPage &&
            <a href={managerUrl} target="_blank">
              {managerUrl}
              <img src={images.externalLink} alt="Go to website" />
            </a>
          }
        </div>

        {isOrgAdminPage &&
          <div className="PageHeading__rating">
            <span className="PageHeading__ratingLabel">Your rating</span>
            <Rating
              rating={statistics?.employerStatistics.averageOverallRating}
              size="md"
            />
            <span className="rating-value">{statistics?.employerStatistics.averageOverallRating && Math.ceil((statistics?.employerStatistics.averageOverallRating) * 10) / 10}</span>
          </div>
        }

        <div className={cx("PageHeading__btns", {orgAdmin: isOrgAdminPage})}>
          {isOrgAdminPage
            ? <ActionsBtn {...actionsBtnProps} />
            : <>
                {user && follow &&
                  <button
                    className={cx("PageHeading__btn--desktop btn-primary-ghost", {active: isFollowed})}
                    onClick={isFollowed ? unfollow : follow}
                  >
                    {isFollowed ? 'FOLLOWING' : 'FOLLOW'}
                  </button>
                }
                {user &&
                  <button
                    className="PageHeading__btn--desktop PageHeading__btn--icon btn-icon-xxl"
                    onClick={openModal}
                  >
                    <ReactSVG src={images.share} />
                  </button>
                }
                {actionsBtnProps
                  ? <ActionsBtn {...actionsBtnProps} />
                  : <Link className="btn-primary" to={`/support-services/review/${manager.id}/${manager.slug}`}>+ Add a review</Link>
                }
              </>
          }
        </div>
      </div>
      <HeadingNav
        sections={sections}
        link={({key}) => isOrgAdminPage ? `/org-admin/${key}` : `/${prefix}/${id}/${slug}/${key}`}
        activeSection={activeSection}
      />
      <div className={cx("nav-overlay", {visible: !isMobileNavScrolledRight})}></div>
    </div>
  )
}

export default connect(
  ({isMobileNavScrolledRight}) => ({isMobileNavScrolledRight}),
  null
)(PageHeading);
