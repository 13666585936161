import React from 'react';
import cx    from 'classnames';

import './FunctionBtn.sass';

export default function FunctionBtn({className, text, img, action, disabled, type}) {
  return (
    <div
      className={cx('FunctionBtn', className, {disabled})}
      onClick={disabled ? null : action}
      type={type}
    >
      <div className="img">
        <img src={img.src} alt={img.alt} />
      </div>
      <div className="text">{text}</div>
    </div>
  )
}
