import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import { lifecycle,
         compose,
         withState,
         withStateHandlers,
         withHandlers }     from 'recompose';
import cx                   from 'classnames';
import { REVIEW_TYPES }     from 'theme/content';
import Loader               from 'theme/Loader';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import AdminNav             from 'theme/AdminNav';
import Pagination           from 'theme/Pagination';
import withDebounce         from 'theme/utils/recompose';
import { formatDate }       from 'theme/utils/helpers';
import { getAdminReviews,
         deleteReview,
         indexReviews,
         getOrganizations } from 'admin/actions';

import './AdminReviews.sass';

const AdminReviews = compose(
  withState('activePage', 'setPage', 0),
  withState('query', 'setQuery', null),
  withState('indexMsgIsVisible', 'showIndexMsg', false),
  withStateHandlers({
    sortColumn: null,
    sortDirection: null
  },
  {
    sortBy: () => (column, direction) => {
      return {
        sortColumn: column,
        sortDirection: direction
      }
    }
  }),
  withHandlers({
    getSortedReviews: ({sortBy, getAdminReviews, query, sortDirection, sortColumn, setPage}) => (column = 'reviewCreatedAt', page, direction) => {
      const dir = direction ||
        (column == sortColumn
        ? sortDirection == 'a' ? 'd' : 'a'
        : 'd');

      sortBy(column || sortColumn, dir);
      setPage(page);
      getAdminReviews({page, query, sort: `${column}:${dir}`});
    }
  }),
  withHandlers({
    searchAdminReviews: ({getSortedReviews, sortColumn, sortDirection}) => page => {
      getSortedReviews(sortColumn, page, sortDirection)
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getSortedReviews('reviewCreatedAt', 0);
      this.props.getOrganizations();
    }
  }),
  withDebounce(),
  withHandlers({
    change: ({searchDebounced, setQuery}) => e => {
      const query = e.target.value == '' ? null : e.target.value;
      setQuery(query);
      searchDebounced(query);
    },
    hideReview: ({deleteReview, activePage}) => review => {
      return deleteReview(REVIEW_TYPES[review.metadata.class], review, {page: activePage});
    },
    indexReviews: ({indexReviews, showIndexMsg}) => () => {
      return indexReviews()
      .then(showIndexMsg(true));
    }
  })
)(({
  loading,
  reviews,
  activePage,
  searchAdminReviews,
  reviewsCount,
  hideReview,
  indexReviews,
  indexMsgIsVisible,
  getSortedReviews,
  sortColumn,
  sortDirection
}) => {
  const checkOrgClass = review =>
    review.organization.class === 'Educator' ? 'educators' : review.organization.class === 'Employer' ? 'companies' : 'support-services';
  const checkReviewType = review => {
    switch(review.metadata.class) {
      case 'EmployerJobReview':
        return 'review';
      case 'EducatorProgramReview':
        return 'review';
      case 'EmployerInterviewReview':
        return 'interview-review';
      case 'EducatorInterviewReview':
        return 'interview-review';
      case 'PartnerReview':
        return 'review';
    }
  }

  return (
    <div className="AdminReviews page">
      <Helmet>
        <title>Vetit Admin – Admin Reviews</title>
      </Helmet>
      <Header />
      <AdminNav />
      <div className="AdminReviews__topBar">
        {indexMsgIsVisible &&
          <p className="indexMessage">Please wait a few seconds and refresh the page.</p>
        }
        <button
          className="AdminReviews__topBarBtn btn-secondary"
          onClick={indexReviews}
          disabled={indexMsgIsVisible}
        >
          Index
        </button>
      </div>

      <div className="container admin-container">
        <Loader className="AdminReviews__loader" loading={loading} />
        <table>
          <thead>
            <tr>
              <th>Organization</th>
              <th>Title</th>
              <th>Comments</th>
              <th onClick={() => getSortedReviews('reviewDownvotesCount', activePage)} className={cx(sortColumn == 'reviewDownvotesCount' && sortDirection)}>Downvotes</th>
              <th onClick={() => getSortedReviews('reviewCreatedAt', activePage)} className={cx(sortColumn == 'reviewCreatedAt' && sortDirection)}>Date</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reviews.map(r => (
              <tr className="AdminReviews__tableRow" key={r.id}>
                <td className="AdminReviews__email" data-th="Organization">{r.organization.name.toUpperCase()}</td>
                <td data-th="Title">{r.review.reviewTitle}</td>
                <td data-th="Comments">{r.review.reviewComments}</td>
                <td data-th="Downvotes">{r.metadata.votes.dislike.length}</td>
                <td data-th="Date">{formatDate(r.metadata.createdAt)}</td>
                <td data-th="Email">{r.reviewerDetails.email}</td>
                <td data-th="Actions">
                  <Link to={`/${checkOrgClass(r)}/${checkReviewType(r)}/${r.organization.id}/edit/${r.id}`} className="btn-secondary">EDIT</Link>
                  {r.metadata && !r.metadata.archivedAt && (
                    <button className="btn-secondary" onClick={() => hideReview(r)}>HIDE</button>
                  ) || (
                    <span>Hidden</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsCount={reviewsCount}
        gotoPage={searchAdminReviews}
        disabled={loading}
        activePage={activePage}
      />
      <Footer />
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}) => ({
  user: auth.user,
  reviews: (data.admin_reviews && data.admin_reviews.data) || [],
  reviewsCount: (data.admin_reviews && data.admin_reviews.count) || 0,
  organizations: data.admin_organizations || [],
  loading: loading.admin_reviews
})

const mapDispatchToProps = dispatch => ({
  search: query => new Promise((resolve, reject) => dispatch(getAdminReviews({query, page: 0, resolve, reject}))),
  getAdminReviews: ({query, page, sort} = {}) => dispatch(getAdminReviews({query, page, sort})),
  getOrganizations: () => dispatch(getOrganizations()),
  deleteReview: (...args) => dispatch(deleteReview(...args)),
  indexReviews: () => dispatch(indexReviews())
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReviews))