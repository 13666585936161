import React    from 'react';
import cx       from 'classnames';

import './ManagerLogo.sass';

export default function ManagerLogo({manager, size = 'sm', className}) {
  const disabled = manager.class !== 'Partner' && Object.prototype.hasOwnProperty.call(manager, 'isLogoEnabled') && !manager.isLogoEnabled;
  return (
    <div className={cx("ManagerLogo", size, className, {noLogo: !manager.logo && !manager.logoUrl})}>
      {(disabled || (!manager.logo && !manager.logoUrl)) && manager.name && manager.name[0]}
      {!disabled &&
        <div className="ManagerLogo__img" style={{backgroundImage: `url(${manager.logo || manager.logoUrl})`}} />
      }
    </div>
  )
}
