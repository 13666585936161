import React                        from 'react';
import { connect }                  from 'react-redux';
import { withRouter }               from 'react-router';
import { Helmet }                   from 'react-helmet';
import { compose,
         lifecycle,
         setDisplayName,
         withProps,
         withHandlers,
         withState,
         withStateHandlers }        from 'recompose';
import qs                           from 'qs';
import cx                           from 'classnames';
import Footer                       from 'theme/Footer';
import Header                       from 'theme/Header';
import Return                       from 'theme/Return';
import Loader                       from 'theme/Loader';
import Pagination                   from 'theme/Pagination';
import SortDropdown                 from 'theme/SortDropdown';
import MobileActions                from 'theme/MobileActions';
import { withScroll,
         withScreenWidthDetection } from 'theme/utils/recompose';
import { constants }                from 'theme/utils/constants';
import { images }                   from 'theme/img/images';
import JobRow                       from 'jobs/JobRow';
import JobSearch                    from 'jobs/JobSearch';
import JobContainer                 from 'jobs/JobContainer';
import { getJob,
         getJobs,
         getAutocomplete,
         subscribe,
         saveJob,
         unsaveJob,
         withdraw,
         viewJob }                  from 'jobs/actions';
import { actionSucceeded }          from 'store/actions';

import Filters                      from './Filters';
import { filters }                  from './filterValues';

import './JobBrowsing.sass';


const FILTER_TYPES = ['availableTypes', 'supportedPrograms', 'createdAt', 'benefits', 'industry', 'companyCultures', 'location'];
const SORT_OPTIONS = [
  {direction: 'd', value: 'createdAt', label: 'Newest'},
  {direction: 'd', value: 'score', label: 'Best match'},
  {direction: 'd', value: 'applicationCount', label: 'Most popular'},
  {direction: 'd', value: 'expirationDate', label: 'Expires soon'},
  {direction: 'a', value: 'jobTitle', label: 'Job title'},
  {direction: 'a', value: 'referenceNumber', label: 'Reference number'}
];

const SortContainer = ({count, selected, sort, mobile}) => (
  <div className={cx("JobBrowsing__sort", {mobile, desktop: !mobile, hidden: count < 2})}>
    <SortDropdown
      selected={selected}
      sort={sort}
      options={SORT_OPTIONS}
    />
  </div>
);

const replaceFilterValues = (filters, name, value) => {
  if (name === 'createdAt' || name === 'location')
    return [...filters.filter(f => f.filterName !== name), {filterName: name, value}]
  else
    return [...filters, {filterName: name, value}];
};

const JobBrowsing = compose(
  setDisplayName('JobBrowsing'),
  withScroll(),
  withScreenWidthDetection(),
  withState('activePage', 'setPage', 0),
  withState('query', 'setQuery', ''),
  withState('hasFocus', 'setFocus', false),
  withState('locationHasFocus', 'setLocationFocus', false),
  withState('expandedFilterBoxes', 'setExpandedFilterBoxes', FILTER_TYPES),
  withState('sortOption', 'setSortOption', SORT_OPTIONS[0]),
  withState('submitted', 'setSubmitted', false),
  withHandlers({
    filterJobs: ({getJobs, sortOption, query}) => (args = {}) => {
      return getJobs({sort: `${sortOption.value}:${sortOption.direction}`, query, ...args});
    }
  }),
  withStateHandlers({
    selectedFilters: [],
    selectedDateOption: null,
    selectedLocation: null,
    action: null
  },
  {
    setFilters: ({selectedFilters}, {filterJobs}) => (filterName, value) => {
      const f = selectedFilters.find(f => f.value === value)
        ? selectedFilters.filter(f => f.value !== value)
        : replaceFilterValues(selectedFilters, filterName, value);

      if (filterName != 'location')
        filterJobs({page: 0, filters: f});

      return {selectedFilters: f};
    },
    clearFilters: ({selectedFilters}, {filterJobs}) => filters => {
      const f = filters ? selectedFilters.filter(f => !filters.find(ff => ff.value == f.value)) : [];

      filterJobs({page: 0, filters: f});

      return {
        selectedFilters: f,
        selectedDateOption: null
      };
    },
    removeLocationFilter: ({selectedFilters}) => () => ({
      selectedLocation: null,
      selectedFilters: selectedFilters.filter(f => f.filterName !== 'location')
    }),
    setDateOption: () => option => ({selectedDateOption: option}),
    setLocation: () => location => ({selectedLocation: location}),
    setAction: () => action => ({action})
  }),
  withProps(({match: {params: {jobId, subscription}}}) => {
    const queryString = qs.parse(subscription, {ignoreQueryPrefix: true});
    const searchString = qs.parse(location.search, {ignoreQueryPrefix: true});

    const a = Object.entries(queryString)
      .filter(([key, value]) => key !== 'query') // eslint-disable-line
      .map((([key, value]) => ({filterName: key, value: value instanceof Array ? value.map(v => ({filterName: key, value: v})) : value})));

    const b = a
      .filter(item => item.value instanceof Array)
      .reduce((a, b) => [...a, ...b.value], []);

    return {
      jobId,
      urlQuery: searchString?.query,
      urlLocation: searchString?.location,
      subscribedQuery: queryString?.query,
      subscribedFilters: a
        .filter(item => !(item.value instanceof Array))
        .concat(b)
    }
  }),
  withProps(({screenWidth}) => ({
    refEl: React.createRef(),
    filtersEl: React.createRef(),
    isMobile: screenWidth < constants.SCREEN_WIDTH.TABLET
  })),
  withHandlers({
    setFilters: ({setFilters}) => filterName => value => setFilters(filterName, value)
  }),
  lifecycle({
    componentDidMount() {
      const { getJobs, isSubscription, query, subscribedFilters, setFilters, subscribedQuery, filter, urlLocation, urlQuery, selectedFilters } = this.props;

      if(urlLocation)
        setFilters('location')(urlLocation);

      if (isSubscription)
        getJobs({filters: subscribedFilters, query: subscribedQuery})
      else if (filter)
        getJobs({query: query || urlQuery, filters: [...selectedFilters, {filterName: 'location', value: urlLocation}]});
      else getJobs();
    }
  }),
  withProps(({query, selectedLocation, urlLocation, urlQuery}) => {
    const loc = selectedLocation || urlLocation;
    const q = query || urlQuery;
    const filterString = {...(q ? {query: q} : {}), ...(loc ? {location: loc} : {})};

    return {
      filterParam: qs.stringify(filterString, {arrayFormat: 'repeat'})
    }
  }),
  withHandlers({
    scrollToRef: ({refEl}) => () => window.scrollTo(0, refEl.current.offsetTop)
  }),
  withHandlers({
    searchJobs: ({getJobs, setPage, scrollToRef, query, urlQuery, selectedFilters}) => page => {
      return getJobs({page, query: query || urlQuery, filters: selectedFilters})
      .then(() => {
        setPage(page);
        scrollToRef();
      })
    },
    changeQuery: ({getJobs, setPage, query, urlQuery, selectedFilters, hasFocus, scrollToRef, history, filterParam}) => () => {
      history.push(`/jobs/search/filter?${filterParam}`);

      return getJobs({page: 0, query: hasFocus ? query : query || urlQuery, filters: selectedFilters})
      .then(() => {
        setPage(0);
        scrollToRef();
      })
    },
    queryJobs: ({getJobs, setPage, scrollToRef, jobId, query, history, selectedFilters}) => () => {
      if (jobId) {
        history.push('/jobs');
      } else {
        return getJobs({page: 0, query, filters: selectedFilters})
        .then(() => {
          setPage(0);
          scrollToRef();
        })
      }
    },
    chooseLocation: ({setFilters, setLocation}) => value => {
      setLocation(value);
      setFilters('location')(value);
    },
    filterJobs: ({setPage, setFilters}) => page => filter => {
      setPage(page);
      setFilters(filter.type)(filter.value);
    },
    withdraw: ({withdraw, displaySuccessMessage, jobId, getJob}) => applicationId => {
      return withdraw(applicationId)
      .then(() => {
        getJob({jobId});
        displaySuccessMessage('Your job application has been withdrawn.');
      })
    },
    subscribe: ({subscribe, selectedFilters, setSubmitted, displaySuccessMessage, query}) => () => {
      return subscribe(selectedFilters, query)
      .then(() => {
        setSubmitted(true);
        displaySuccessMessage('Your search results have been saved. You can now review them in \'My jobs — Searches\' tab.');
      })
    },
    handleSave: ({saveJob, unsaveJob, selectedFilters, activePage, getJobs, query}) => (id, action) => {
      const req = action == 'Save' ? saveJob(id) : unsaveJob(id);

      return req
      .then(() => {
        getJobs({page: activePage, loader: false, query, filters: selectedFilters})
      }
      )
    }
  }),
  withHandlers({
    closeBox: ({expandedFilterBoxes, setExpandedFilterBoxes, clearFilters}) => (name, filters) => {
      setExpandedFilterBoxes(expandedFilterBoxes.filter(box => box !== name));
      clearFilters(filters);
    },
    getAutocomplete: ({getAutocomplete}) => value => {
      return getAutocomplete({prefix: value})
      .then(res => res.map(r => ({
        value: r,
        label: r
      })))
    },
    sortJobs: ({getJobs, setSortOption, activePage, selectedFilters, setPage, scrollToRef, query, urlQuery}) => option => {
      setSortOption(option);
      return getJobs({page: activePage, filters: selectedFilters, sort: `${option.value}:${option.direction}`, query: query || urlQuery})
      .then(() => {
        setPage(0);
        scrollToRef();
      });
    },
    onLocationFocus: ({removeLocationFilter, setLocationFocus}) => () => {
      removeLocationFilter();
      setLocationFocus(true);
    }
  }),
  withProps(({jobs, jobsCount, topHit, matchedJobs, setFilters}) => ({
    jobs: Object.keys(topHit).length > 0 ? [topHit, ...jobs] : jobs,
    count: jobsCount + matchedJobs.length,
    filters: filters.map(f => ({...f, onChange: setFilters(f.type)}))
  }))
)(({
  jobs,
  jobsCount,
  count,
  matchedJobs,
  loading,
  user,
  filters,
  selectedFilters,
  clearFilters,
  jobId,
  closeBox,
  expandedFilterBoxes,
  selectedDateOption,
  setDateOption,
  searchJobs,
  activePage,
  withdraw,
  getAutocomplete,
  chooseLocation,
  selectedLocation,
  removeLocationFilter,
  subscribe,
  isSubscription,
  handleSave,
  sortOption,
  sortJobs,
  filterJobs,
  viewJob,
  refEl,
  query,
  setQuery,
  queryJobs,
  urlQuery,
  urlLocation,
  match,
  submitted,
  changeQuery,
  setFocus,
  hasFocus,
  locationHasFocus,
  onLocationFocus,
  isMobile
}) => {
  const jobOpeningProps = j => ({
    job: j.jobOpening,
    user,
    employer: j.organization,
    status: j.jobApplication?.status,
    link: j.jobOpening.externalInfo
      ? `/jobs/${j.jobOpening.id}/jazzhr`
      : `/jobs/${j.jobOpening.id}/organizations/${j.organization.internalId}`,
    handleSave,
    viewJob
  });

  const mobileActions = [
    {text: 'CLEAR ALL FILTERS', icon: images.close, fn: () => clearFilters()}
  ];

  return (
    <div className="JobBrowsing page">
      <Helmet>
        <title>Vetit — Browse Jobs</title>
      </Helmet>
      <Header />
      <div className="JobBrowsing__container container">
        {match.path.includes('/jobs') &&
          <JobSearch
            loadOptions={getAutocomplete}
            onSelectOption={chooseLocation}
            clearLocation={removeLocationFilter}
            query={hasFocus ? query : query || urlQuery}
            setQuery={setQuery}
            focus={setFocus}
            locationValue={locationHasFocus ? selectedLocation : selectedLocation || urlLocation}
            onLocationFocus={onLocationFocus}
            search={match.params.filter ? changeQuery : queryJobs}
          />
        }
        <div>
          {!jobId &&
            <>
              <div className="JobBrowsing__heading">
                <h3>
                  Search
                  <span> {count > 10000 ? '10000+' : count} {count === 1 ? 'job' : 'jobs'}</span>
                </h3>
                {user &&
                  <button
                    className={cx("btn-secondary-ghost-transparent", {active: submitted || isSubscription})}
                    onClick={() => subscribe()}
                  >
                    {isSubscription || submitted ? 'Subscribed' : 'Save search'}
                  </button>
                }
              </div>
              <SortContainer
                count={jobsCount}
                sort={sortJobs}
                selected={sortOption}
              />
              <div
                className="JobBrowsing__content"
                ref={refEl}
              >
                <Filters
                  filter={filterJobs(activePage)}
                  filters={filters}
                  clearFilters={clearFilters}
                  selectedFilters={selectedFilters}
                  closeBox={closeBox}
                  expanded={expandedFilterBoxes}
                  setDateOption={setDateOption}
                  selectedDateOption={selectedDateOption}
                  mobile={isMobile}
                />
                <SortContainer
                  count={jobsCount}
                  sort={sortJobs}
                  selected={sortOption}
                  mobile
                />
                <div className={cx("JobBrowsing__jobs", {padding: matchedJobs.length === 0})}>
                  <div className="JobBrowsing__list">
                    <Loader className="JobBrowsing__loader" loading={loading} />
                    {user && matchedJobs.length > 0 &&
                      <>
                        <h5 className="JobBrowsing__toConsider">Jobs to consider</h5>
                        {matchedJobs.map(j => {
                          return (
                            <JobRow
                              {...jobOpeningProps(j)}
                              key={j.jobOpening.id}
                              matched
                            />
                          )
                        })}
                      </>
                    }
                  </div>

                  <div className="JobBrowsing__list">
                    {user &&
                      <Loader className="JobBrowsing__loader" loading={loading} />
                    }
                    {user && matchedJobs.length > 0 &&
                      <h5 className="other">Other open positions</h5>
                    }
                    {jobs.map(j => (
                      <JobRow
                        {...jobOpeningProps(j)}
                        key={j.jobOpening.id}
                      />
                    ))}
                  </div>
                  <div className="JobBrowsing__pagination">
                    {jobsCount > constants.PAGINATION.SIZE &&
                      <span>Page {activePage + 1} of {Math.ceil(jobsCount / constants.PAGINATION.SIZE)}</span>
                    }
                    <Pagination
                      itemsCount={jobsCount}
                      gotoPage={searchJobs}
                      disabled={loading}
                      activePage={activePage}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          {jobId &&
            <div className="JobBrowsing__jobDetails">
              <Return
                text="Back to search results"
                link='/jobs'
              />
              <JobContainer
                withdraw={withdraw}
                isJobBrowsing
              />
            </div>
          }
        </div>
      </div>
      <Footer />

      {(isMobile && selectedFilters.length > 0) &&
        <MobileActions actions={mobileActions} floating />
      }
    </div>
  )
})

const mapStateToProps = ({auth, data: {jobs}, loading}) => ({
  user: auth.user,
  jobs: auth.user
    ? jobs?.list?.data || []
    : jobs?.data || [],
  jobsCount: auth.user
    ? jobs?.list?.count || 0
    : jobs?.count || 0,
  matchedJobs: jobs?.matched || [],
  topHit: auth.user
    ? jobs?.list?.topHit || {}
    : jobs?.topHit || {},
  loading: loading.jobs
});

const mapDispatchToProps = dispatch => ({
  getJob: (args = {}) => new Promise((resolve, reject) => dispatch(getJob({...args, resolve, reject}))),
  getJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getJobs({...args, resolve, reject}))),
  getAutocomplete: (prefix) => dispatch(getAutocomplete(prefix)),
  withdraw: (applicationId) => dispatch(withdraw(applicationId)),
  subscribe: (filters, query) => dispatch(subscribe({filters, query})),
  saveJob: (id) => dispatch(saveJob(id)),
  unsaveJob: (id) => dispatch(unsaveJob(id)),
  viewJob: (id) => dispatch(viewJob(id)),
  displaySuccessMessage: (message) => dispatch(actionSucceeded(message))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JobBrowsing));
