import React                from 'react';
import cx                   from 'classnames';

import './Videos.sass';

export default function Videos({videos, mobile}) {
  return(
    <div className={cx("Videos", {mobile, desktop: !mobile})}>
      {videos.map((video, i) => (
        <a
          key={i}
          className="Videos__box"
          style={{backgroundImage: `url(${video?.thumbnail})`}}
          href={video?.url}
          target="_blank"
        >
          <div className="Videos__overlay" />
          <h6>VIDEO<span className="fa fa-caret-right" /></h6>
          <h5>{video?.title}</h5>
        </a>
      ))}
    </div>
  )
}
