import { SET_QUERY, SET_ACTIVE_MOBILE_LINK, SHOW_MODAL, CLOSE_MODAL, SCROLL_MOBILE_NAV_RIGHT } from './types';

const SEARCH_ROUTE = /\/(educators|companies)$/
const headerState = {
  query: '',
  activeMobileLink: null
};

export const header = (state = headerState, action) => {
  switch  (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (action.payload && !SEARCH_ROUTE.test(action.payload.pathname)) {
        return {
          ...state,
          query: ''
        }
      }
      return state;
    case SET_QUERY:
      return {
        ...state,
        query: action.query
      }
    case SET_ACTIVE_MOBILE_LINK:
      return {
        ...state,
        activeMobileLink: action.link
      }
    default:
      return state;
  }
}

export const modal = (state = {}, action) => {
  switch  (action.type) {
    case SHOW_MODAL:
      if (action.name)
        return {
          ...state,
          [action.name]: {
            ...state[action.name],
            visible: true,
            payload: action.payload
          }
        }
      else return state;
    case CLOSE_MODAL:
      if (action.name)
        return {
          ...state,
          [action.name]: {
            ...state[action.name],
            visible: false
          }
        }
      else return state;
    default:
      return state;
  }
}

export const isMobileNavScrolledRight = (state = false, {type, payload}) => {
  switch (type) {
    case SCROLL_MOBILE_NAV_RIGHT:
      return payload;
    default:
      return state;
  }
}
