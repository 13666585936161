import React                 from 'react';
import { connect }           from 'react-redux';
import { reduxForm,
         Field,
         formValueSelector } from 'redux-form';
import { TextField,
         Select,
         RadioLabels,
         Captcha,
         Rating }            from 'theme/Form';
import { required }          from 'theme/utils/validate';
import { options,
         agreements }        from 'theme/content';
import { getMosOptions }     from 'theme/content/mosOptions';

import './Form.sass';


export default connect(
  (state, props) => ({serviceBranchValue: formValueSelector(props.form)(state, 'reviewerDetails.serviceBranch')})
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, submitFailed, invalid, openModal, profile, editDisabled, getAutocomplete, serviceBranchValue}) => {
    const isSpouse = serviceBranchValue === 'Spouse';
    return (
      <div className="CompanyReview__form">
        <h4>Employee Experience</h4>
        <form onSubmit={handleSubmit}>
          <h6>Employer Details</h6>
          <Field
            name="employer"
            label="Company Name"
            required
            placeholder="Start typing..."
            component={Select}
            valueKey='id'
            labelKey='name'
            validate={required}
            creatable
            onNewOptionClick={openModal}
            disabled={editDisabled}
            getAutocomplete={getAutocomplete}
            async
          />
          <Field
            name="jobDetails.position"
            label="Position Title"
            required
            placeholder="e.g. Consultant"
            component={TextField}
            validate={required}
          />
          <Field
            name="jobDetails.jobFunction"
            label="Functional Area"
            placeholder="e.g. Management Analysts"
            component={Select}
            options={options.functions}
            disabled={editDisabled}
          />
          <h6>Experience Overview</h6>
          <Field
            name="review.reviewTitle"
            label="Review Title"
            placeholder="e.g. An incredibly fulfilling company to work for!"
            className="block"
            required
            component={TextField}
            validate={required}
          />
          <Field
            name="review.reviewComments"
            label="Review Comments"
            className="block"
            placeholder="For review prompts, refer to 'Military specific questions' and 'General questions'. Please be as generous as you can with details. --------->"
            required
            componentType="textarea"
            component={TextField}
            validate={required}
          />
          <Field
            name="rating"
            label="Rate your overall experience"
            required
            validate={required}
            component={Rating}
            disabled={editDisabled}
          />
          <h6>Pay & Culture Details</h6>
          <Field
            name="offerDetails.salaryType"
            className="InterviewReview__salary"
            label="Pay Type"
            required
            validate={required}
            component={RadioLabels}
            options={options.salaryTypes}
            disabled={editDisabled}
          />
          <Field
            name="offerDetails.averageSalary"
            label="Average Monthly Pay"
            className="InterviewReview__amount block"
            placeholder="$"
            component={RadioLabels}
            options={options.avgMonthlyPay}
            disabled={editDisabled}
          />
          <Field
            name="CEOCommitmentRating"
            label="Rate the CEO's commitment to veteran employees"
            required
            validate={required}
            component={Rating}
            disabled={editDisabled}
          />
          <Field
            name="cultureOpinion"
            label="Overall, the team culture is: "
            className="block"
            required
            validate={required}
            component={RadioLabels}
            options={options.experienceOptions}
            disabled={editDisabled}
          />
          <h6>Recommendation</h6>
          <Field
            name="recommendation"
            label="Would you recommend this company to other veterans and military spouses?"
            className="block"
            required
            validate={required}
            component={RadioLabels}
            options={options.complete}
            disabled={editDisabled}
          />
          <h6>Information</h6>
          <Field
            name="reviewerDetails.serviceBranch"
            label="Service Branch"
            required
            className="block"
            component={RadioLabels}
            options={options.branchRange}
            validate={required}
            disabled={editDisabled}
          />
          {!isSpouse &&
            <Field
              label="Military Occupation"
              name="reviewerDetails.mos"
              placeholder="Select"
              component={Select}
              options={getMosOptions(serviceBranchValue)}
              disabled={editDisabled}
              {...(!isSpouse && {validate: required, required: true})}
            />
          }
          {!isSpouse &&
            <Field
              name="reviewerDetails.etsPaygrade"
              label="Highest Paygrade Attained"
              placeholder="Select"
              component={Select}
              options={options.paygradeOptions}
              disabled={editDisabled}
              {...(!isSpouse && {validate: required, required: true})}
            />
          }
          <Field
            name="reviewerDetails.email"
            label="Your E-mail"
            required
            placeholder="e.g. sgt.hooah@gmail.com or iam.t.standard4.mil@mail.mil"
            component={TextField}
            validate={required}
            disabled={editDisabled}
          />
          <h5 className="CompanyReview__agreement">{agreements.certifiedVet}</h5>
          {Object.keys(profile).length == 0 &&
            <Field
              name="captcha"
              component={Captcha}
              validate={required}
            />
          }
          {submitFailed && invalid &&
            <div className="CompanyReview__error">Please check if you filled all required fields.</div>
          }
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting}
          >
            VET IT FORWARD!
          </button>
        </form>
      </div>
    )
  }
))
