import React,
       { useState, useEffect }   from 'react';
import RSelect                   from 'react-select';
import CreatableSelect           from 'react-select/creatable';
import AsyncSelect               from 'react-select/async';
import AsyncCreatableSelect      from 'react-select/async-creatable';
import ReCAPTCHA                 from 'react-google-recaptcha';
import DatePicker                from 'react-datepicker';
import { useDropzone }           from 'react-dropzone';
import { Field }                 from 'redux-form';
import { compose,
         withState,
         lifecycle,
         setDisplayName,
         withStateHandlers,
         defaultProps,
         withHandlers,
         withProps }             from 'recompose';
import PT                        from 'prop-types';
import cx                        from 'classnames';
import { API_CONF }              from 'api';
import Toggle                    from 'theme/Toggle';
import FunctionBtn               from 'theme/FunctionBtn';
import MoreInfo                  from 'theme/MoreInfo';
import { copy }                  from 'theme/content';
import { url }                   from 'theme/utils/validate';
import { convertToMB,
         capitalizeFirstLetter } from 'theme/utils/helpers';
import { images }                from 'theme/img/images';

import { DropdownIndicator,
         Menu,
         MultiValueContainer }   from './SelectComponents';
import RatingComponent           from '../Rating';
import MarkdownEditor            from '../MarkdownEditor';

import 'react-datepicker/dist/react-datepicker.css';
import './Form.sass';
import '../theme-datepicker.sass';

const siteKey = API_CONF.RECAPTCHA_SITE_KEY;

const selectProps = {
  classNamePrefix: "RSelect",
  components: {
    DropdownIndicator,
    IndicatorSeparator: () => null
  }
};

const customStyles = {
  singleValue: (provided, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'block'
  })
};

export const Select = compose(
  defaultProps({
    valueKey: 'value',
    labelKey: 'label'
  }),
  withStateHandlers(
    ({input: {value}, valueKey}) => {
      return {selectedOption: value && value[valueKey] ? value : null}
    },
    {
      setOption: () => (option) => ({selectedOption: option})
    }
  ),
  withProps(({input: {value}, options, selectedOption, valueKey}) => {
    const foundOption = options && options.find(o => o[valueKey] == value || o == value);
    return {selectedOption: foundOption || selectedOption || ''}
  })
)(
  ({input,
    label,
    required,
    placeholder,
    options,
    meta: {touched, error},
    search,
    creatable,
    async,
    getAutocomplete,
    valueKey,
    labelKey,
    className,
    disabled,
    loading,
    setOption,
    selectedOption,
    onNewOptionClick,
    isClearable,
    filterOptions,
    isMulti,
    searchIcon
  }) => {
  const Component = creatable ? CreatableSelect : RSelect;
  const getNewOptionData = (valueKey, labelKey) =>
    (inputValue, optionLabel) => {
      return {[valueKey]: inputValue, [labelKey]: optionLabel, _new: true};
    }

  const onChange = (option) => {
    const newOption = option && option._new;

    setOption(option);
    input.onChange(newOption ? {new: option && option[valueKey]} : (isMulti ? option : option && option[valueKey]))
  }

  const onInputChange = (value) => {
    search && search(value);
    return value;
  }

  const filteredOptions = filterOptions ? options.filter(filterOptions) : options;

  return (
    <div className={cx("Form__field", className, {disabled})}>
      <label className={cx({required})} htmlFor={input.name}>
        {label}
      </label>
      <div className="Form__input">
        {touched && error &&
          <span className="Form__fieldError">{error}</span>
        }
        {async ?
          <AsyncSelect
            {...selectProps}
            loadOptions={getAutocomplete}
            onChange={onChange}
            classNamePrefix="RSelect"
            className={cx({searchIcon})}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null
            }}
            onInputChange={onInputChange}
            placeholder={placeholder}
            value={isMulti ? input.value : selectedOption}
            valueKey={valueKey}
            labelKey={labelKey}
            getOptionLabel = {(data) => data[labelKey]}
            getOptionValue = {(data) => data[valueKey]}
            isClearable={isClearable}
            isDisabled={disabled}
            isMulti={isMulti}
            searchIcon={searchIcon}
            styles={{
              ...customStyles,
              dropdownIndicator: base => searchIcon
              ? ({
                  ...base,
                  padding: '15px'
                })
              : base
            }}
          />
        :
          <Component
            {...selectProps}
            className={cx("Form__select", {err: touched && error})}
            options={filteredOptions}
            onChange={onChange}
            onInputChange={onInputChange}
            isLoading={loading}
            value={selectedOption}
            placeholder={placeholder}
            valueKey={valueKey}
            labelKey={labelKey}
            isDisabled={disabled}
            isClearable={isClearable}
            onNewOptionClick={(aa) => onNewOptionClick && onNewOptionClick(aa.name)}
            autoBlur
            getOptionLabel = {(data) => data[labelKey]}
            getOptionValue = {(data) => data[valueKey]}
            getNewOptionData = {getNewOptionData(labelKey, valueKey)}
          />
        }
      </div>
    </div>
  )
})

Select.propTypes = {
  label: PT.oneOfType([PT.string, PT.node]),
  required: PT.bool,
  placeholder: PT.string,
  options: PT.arrayOf(PT.shape({
    label: PT.string
  })),
  search: PT.func,
  creatable: PT.bool,
  valueKey: PT.string,
  labelKey: PT.string,
  loading: PT.bool,
  className: PT.string,
  disabled: PT.bool
}

export const CodeField = ({input, labels, required, getCodeNames, className}) => {
  const [selectedOption, setOption] = useState(null);

  const handleChange = (option, key, otherKey) => {
    setOption(option);
    input.onChange({
      [key]: option,
      [otherKey]: {label: option.otherFieldValue, value: option.otherFieldValue}
    })
  }

  const setValue = field =>
    input.value[field] || selectedOption && {label: selectedOption.otherFieldValue, value: selectedOption.otherFieldValue}

  return (
    <div className={cx("Form__field", className)}>
      {/* <div>
        <label className={cx({required})} htmlFor={input.name}>{labels[0]}</label>
        <AsyncSelect
          {...selectProps}
          label="Code"
          placeholder="__-____"
          value={setValue('code')}
          onChange={option => handleChange(option, 'code', 'associatedName')}
          loadOptions={getCodes}
        />
      </div> */}
      <div>
        <label className={cx({required})} htmlFor={input.name}>{labels[1]}</label>
        <AsyncSelect
          {...selectProps}
          label="Associated O*Net Name"
          placeholder="Administrative Services Managers"
          value={setValue('associatedName')}
          onChange={option => handleChange(option, 'associatedName', 'code')}
          loadOptions={getCodeNames}
        />
      </div>
    </div>
  )
}

export const StaticField = ({input, label, className}) => (
  <div className={cx("Form__field", className)}>
    <label htmlFor={input.name}>
      {label}
    </label>
    <div className="Form__input">
      <span>{input.value}</span>
    </div>
  </div>
)

export const ImageField = ({input, label, required, placeholder, className, disabled, meta: {touched, error}}) => (
  <div className={cx("Form__field", className, {disabled})}>
    <label className={cx({required})} htmlFor={input.name}>
      {label}
    </label>
    <div className="Form__input Form__inputImg">
      <div className="Form__inputImgBox">
        {touched && error &&
          <span className="Form__fieldError">{error}</span>
        }
        <input
          type="text"
          placeholder={placeholder}
          className={cx({
            'has-value': input.value && input.value != '',
            err: touched && error
          })}
          disabled={disabled}
          {...input}
        />
      </div>
      <div className="Form__fieldImg" style={{backgroundImage: `url(${input.value})`}}>
        {!input.value &&
          <span className="fa fa-picture-o" />
        }
      </div>
    </div>
  </div>
)

export const ArrayField = ({
  fields,
  label,
  required,
  placeholder,
  className,
  disabled,
  meta: {touched, error},
  children,
  info,
  centerVertically,
  maxCount = 10,
  buttonText = "Add",
  removeBtn = true,
  hideLabel,
  firstFieldVisibleByDefault
}) => {

  useEffect(() => {
    if (firstFieldVisibleByDefault) fields.push();
  }, [firstFieldVisibleByDefault]);

  return (
    <div className={cx("Form__field Form__fieldArray", className, {disabled})}>
      {hideLabel
        ?
          <>
            {fields.length === 0 && <label className={cx({required}, {info})}>{label}</label>}
          </>
        :
          <label className={cx({required}, {info})}>{label}</label>
      }
      <div className={cx("Form__fieldArrayInfo", {info})}>{info}</div>
      <div className={cx("Form__input Form__inputArray", {info: info && fields.length > 0})}>
        {touched && error &&
          <span className="Form__fieldError">{error}</span>
        }
        {fields.map((f, i) => {
          return (
            <div className={cx("Form__item", {center: centerVertically})} key={i}>
              {React.Children.map(children, (child) => (
                React.cloneElement(child, {
                  name: f,
                  names: child.props.names && child.props.names.map(n => f.concat(`.${n}`)),
                  className: "Form__itemField",
                  index: i,
                  placeholder,
                  disabled
                })
              ))}
              {removeBtn &&
                <button
                  type="button"
                  onClick={() => fields.remove(i)}
                  className="Form__btnRemove"
                  disabled={disabled}
                >
                  <img src={images.close} alt="remove" />
                </button>
              }
            </div>
          )}
        )}
          {maxCount
            ?
              <>
                {maxCount && fields.length < maxCount &&
                  <FunctionBtn
                    type="button"
                    img={{src: images.plus, alt: 'add'}}
                    text={buttonText}
                    action={() => fields.push()}
                    disabled={disabled}
                  />
                }
              </>
            :
              <FunctionBtn
                type="button"
                img={{src: images.plus, alt: 'add'}}
                text={buttonText}
                action={() => fields.push()}
                disabled={disabled}
              />
          }
      </div>
    </div>
  )
}

export const ColorField = ({options, input, error, disabled, label, required}) => {
  return (
    <div className={cx("Form__colorField Form__field", {disabled})}>
      <label className={cx({required})} htmlFor={input.name}>
        {label}
      </label>

      <div>
        {options.map((option, i) => {
          return (
            <div
              key={i}
              className={cx("option", {disabled})}
              style={{background: `${option}`}}
              onClick={() => input.onChange(option)}
            >
              {input.value === option &&
                <i className={cx("fa fa-check", {white: option === '#FFFFFF'})}></i>
              }
            </div>
          )
        })}
      </div>

      {error &&
        <span className="Form__fieldError">{error}</span>
      }
    </div>
  )
}

export const TextField = ({
  input,
  label,
  required,
  placeholder,
  className,
  componentType = 'input',
  disabled,
  meta: {touched, error},
  type,
  maxlength,
  isPasswordVisible,
  togglePassword,
  size
}) => (
  <div className={cx("Form__field Form__textField", className, size, {textarea: componentType === 'textarea'}, {disabled})}>
    {label &&
      <label className={cx({required})} htmlFor={input.name}>
        {label}
      </label>
    }
    <div className="Form__input">
      {(() => {
        switch(componentType) {
          case 'textarea':
            return (
              <div className="Form__textareaWrapper">
                <textarea
                  placeholder={placeholder}
                  className={cx({
                    'has-value': input.value && input.value != '',
                    err: touched && error
                  })}
                  disabled={disabled}
                  maxLength={maxlength}
                  {...input}
                />
                {touched && error &&
                  <span className="Form__inputAlert Form__inputAlert--textarea">
                    <img src={images.alert} alt="Error" />
                  </span>
                }
              </div>
            )
          case 'input':
            return (
              <div className="Form__inputWrapper">
                <input
                  type={type || "text"}
                  placeholder={placeholder}
                  className={cx({
                    'has-value': input.value && input.value != '',
                    err: touched && error
                  })}
                  maxLength={maxlength}
                  disabled={disabled}
                  {...input}
                />
                {(input.name === 'password' || input.name === 'confirmPassword') &&
                  <span onClick={() => togglePassword(input.name)} className="Form__togglePassword">
                    <img src={isPasswordVisible ? images.eyeOff : images.eye} alt="Show password" />
                  </span>
                }
                {touched && error && (input.name !== 'password' && input.name !== 'confirmPassword') &&
                  <span className="Form__inputAlert">
                    <img src={images.alert} alt="Error" />
                  </span>
                }
              </div>
            )
          case 'markdown':
            return (
              <MarkdownEditor
                {...input}
                className={cx('Form__markdownEditor', {
                  'has-value': input.value && input.value != '',
                  err: touched && error
                })}
                placeholder={placeholder}
              />
            )
        }
      })()}
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
    </div>
  </div>
)

TextField.propTypes = {
  label: PT.oneOfType([PT.string, PT.node]),
  required: PT.bool,
  placeholder: PT.string,
  className: PT.string,
  textarea: PT.bool,
  disabled: PT.bool,
  type: PT.string,
  maxlength: PT.string,
  markdown: PT.bool,
  componentType: PT.string,
  isPasswordVisible: PT.bool,
  togglePassword: PT.func,
  size: PT.string,
  hasMultipleValues: PT.bool
}

export const Creatable = ({input, label, placeholder, required, meta: {touched, error}, className, disabled, options, defaultOptions, getAutocomplete, isMulti, async, createLabel, isEmployerSignup}) => {
  return (
    <div className={cx("Form__field Form__creatable", className)}>
      {label &&
        <label className={cx({required})} htmlFor={input.name}>
          {label}
        </label>
      }
      <div>
        {touched && error &&
          <span className="Form__fieldError">{error}</span>
        }
        {async
          ?
            <AsyncCreatableSelect
              classNamePrefix='RSelect'
              components={{
                MultiValueContainer,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ...isEmployerSignup && ({Menu: (props => <Menu text='Suggested:' labelKey='name' {...props} /> )}) //eslint-disable-line
              }}
              placeholder={placeholder}
              onChange={value => isMulti ? input.onChange(value && value.reduce((a, b) => [...a, b.value], [])) : input.onChange(value)}
              loadOptions={getAutocomplete}
              defaultOptions={defaultOptions}
              value={isMulti ? input.value && input.value.map(item => ({value: item, label: item})) : input.value}
              isMulti={isMulti}
              isDisabled={disabled}
              isClearable={false}
              styles={customStyles}
              formatCreateLabel={value => <div style={{color: '#FF7300'}}>{createLabel ? `${createLabel} '${value}'` : `Create '${value}'`}</div>}
            />
          :
            <CreatableSelect
              components={{
                MultiValueContainer,
                DropdownIndicator: () => null,
                Menu: () => null
              }}
              classNamePrefix='Creatable'
              placeholder={placeholder}
              defaultValue={options}
              onChange={value => input.onChange(value)}
              options={options}
              isDisabled={disabled}
              isClearable={false}
              isMulti
            />
        }
      </div>
    </div>
  )
}

export const Datepicker = ({input, label, required, placeholder, className, disabled, isClearable, meta: {touched, error}, isStatic, minDate}) => {
  const date = new Date(input.value);
  const isoDate = input.value && new Date(date.getTime() + (date.getTimezoneOffset() * 60000));

  const [startDate, setStartDate] = useState(isoDate);
  const handleDate = value => {
    input.onChange(value);
    setStartDate(value);
  };

  return (
    <div className={cx("Form__field Form__datepicker", className, {disabled})}>
      {label &&
        <label
          className={cx({required})}
          htmlFor={input.name}
        >
          {label}
        </label>
      }
      <div className="Form__input">
        {touched && error &&
          <span className="Form__fieldError">{error}</span>
        }
        <div className="Form__datepickerContainer">
          <DatePicker
            selected={startDate}
            onChange={value => handleDate(value)}
            placeholderText={placeholder || "Select Date"}
            showMonthDropdown
            showYearDropdown
            minDate={minDate}
            isClearable={isClearable}
            dropdownMode="select"
            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
            className={cx({
              'has-value': input.value && input.value != '',
              err: touched && error,
              static: isStatic
            })}
          />
          {(!isStatic && !isClearable) &&
            <img src={images.calendar} alt="date" />
          }
        </div>
      </div>
    </div>
  )
}

export const MultipleField = compose(
  setDisplayName('MultipleField'),
  withState('selectedServiceBranch', 'setServiceBranch', null),
  withHandlers({
    targetKey: fields => i => {
      const { keyName, index, keys } = fields;
      return fields[keyName][index][keys[i]];
    },
    generatePlaceholderText: ({keyName, index, placeholders}) => i => keyName === 'skills' ? `Skill ${copy.skillInputNumbers[index]}` : placeholders[i]
  }),
  lifecycle({
    componentDidMount() {
      const { defaultValueIndex, targetKey } = this.props;

      defaultValueIndex && targetKey(defaultValueIndex).input.onChange(true);
    }
  })
)(({
  placeholders,
  labels,
  required,
  components,
  options,
  labelOptions,
  disabled,
  column,
  targetKey,
  generatePlaceholderText,
  selectedServiceBranch,
  setServiceBranch,
  getMosOptions,
  mosOptionsField,
  getAutocomplete,
  noLabel
}) => {
  const clickLabel = (i, value) => {
    targetKey(i).input.onChange(value);
    setServiceBranch(value);
  }

  return (
    <div className="Form__multipleField">
      <div className={cx("Form__multipleFieldContainer", {column})}>
        {components.map((c, cIndex) => {
          const targetValue = targetKey(cIndex).input.value;

          return (
            <div className="Form__multipleFieldItem" key={cIndex}>
              {labels &&
                <label className={cx('Form__multipleFieldLabel', {required, noLabel})}>{labels[cIndex]}</label>
              }
              {(() => {
                switch(c) {
                  case 'input':
                    return (
                      <input
                        {...targetKey(cIndex).input}
                        type="text"
                        className="Form__multipleFieldInput"
                        placeholder={generatePlaceholderText(cIndex)}
                      />
                    )
                  case 'select':
                    return (
                      <RSelect
                        {...targetKey(cIndex).input}
                        {...selectProps}
                        className="Form__multipleFieldSelect"
                        onBlur={event => event.preventDefault()}
                        onChange={option => targetKey(cIndex).input.onChange(option && option.value)}
                        value={targetValue ? {value: targetValue, label: options.find(o => o.value === targetValue) ? options.find(o => o && o.value === targetValue).label : targetValue} : ''}
                        placeholder={placeholders[cIndex]}
                        options={targetKey(cIndex).input.name.includes(mosOptionsField)
                          ? getMosOptions(selectedServiceBranch)
                          : options.some(o => o instanceof Array) ? options[cIndex] : options
                        }
                      />
                    )
                  case 'async':
                    return (
                      <AsyncSelect
                        {...targetKey(cIndex).input}
                        {...selectProps}
                        loadOptions={getAutocomplete}
                        className="Form__multipleFieldSelect"
                        onBlur={event => event.preventDefault()}
                        onChange={option => targetKey(cIndex).input.onChange(option && option.value)}
                        value={targetValue ? {value: targetValue, label: targetValue || options.find(o => o.value === targetValue).label} : ''}
                        placeholder={placeholders[cIndex]}
                      />
                    )
                  case 'radioLabels':
                    return (
                      <div className="Form__multipleFieldLabels">
                        {labelOptions.map(({value, label}, i) => {
                          return (
                            <button
                              key={i}
                              className={cx("Form__radioLabel", {
                                active: targetValue != null && targetValue === value,
                                err: targetKey(cIndex).meta.touched && targetKey(cIndex).meta.error
                              })}
                              onClick={() => clickLabel(cIndex, value)}
                              disabled={disabled}
                              type="button"
                            >
                              {label}
                            </button>
                          )
                        })}
                      </div>
                    )
                  case 'datepicker':
                    return (
                      <div className="Form__datepickerContainer">
                        <DatePicker
                          selected={targetValue && new Date(targetValue)}
                          onChange={targetKey(cIndex).input.onChange}
                          placeholderText={placeholders[cIndex] || "Select Date"}
                          dropdownMode="select"
                          showMonthDropdown
                          showYearDropdown
                        />
                        <img src={images.calendar} alt="date" />
                      </div>
                    )
                }
              })()}
            </div>
          )
        }
        )}
      </div>
    </div>
  )
})

export const RadioLabels = ({input, required, options, label, className, disabled, meta: {touched, error}, isStatic}) => {
  const newOptions = isStatic ? options.filter(o => o.value === input.value) : options;
  return (
    <div className={cx("Form__field", className, {disabled})}>
      {label &&
        <label className={cx({required: !isStatic && required})} htmlFor={input.name}>
          {label}
        </label>
      }
      <div className="Form__labels">
        {touched && error &&
          <span className="Form__fieldError">{error}</span>
        }
        {newOptions.map(({value, label}, i) => (
          <button
            key={i}
            className={cx("Form__radioLabel", {
              active: input.value != null && input.value === value,
              err: touched && error,
              static: isStatic
            })}
            onClick={() => input.onChange(value)}
            disabled={disabled}
            type="button"
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  )
}

RadioLabels.propTypes = {
  label: PT.oneOfType([PT.string, PT.node]),
  required: PT.bool,
  options: PT.arrayOf(PT.shape({
    label: PT.string
  })),
  className: PT.string,
  disabled: PT.bool,
  isStatic: PT.bool
}

export const Radio = ({input, label, className, required, optionValue, mode, meta: {touched, error}, disabled, triple}) => {
  const val = mode == 'checkbox' ? true : optionValue;
  const click = () => {
    if (disabled) return;
    if (triple) {
      if(mode == 'checkbox' && input.value === true)
        input.onChange(false);
      else if(mode == 'checkbox' && input.value === false)
        input.onChange(null);
      else
        input.onChange(val);
    } else {
      if(mode == 'checkbox' && input.value === true)
        input.onChange(false);
      else
        input.onChange(val);
    }
  }

  const classes = triple
    ? {checked: input.value == val, checkedFalse: input.value === false, err: touched && error}
    : {checked: input.value == val, err: touched && error};

  return (
    <div className={cx("Form__field Form__radio", className, {disabled}, {checkbox: mode === 'checkbox'})}>
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
      <div
        className={cx("check", classes)}
        onClick={click}
      >
        {mode === 'checkbox' && input.value &&
          <i className="fa fa-check" aria-hidden="true" />
        }
      </div>
      <label
        className={cx('alt-label', {required})}
        htmlFor={input.name}
        onClick={click}
      >
        {label}
      </label>
    </div>
  )
}

Radio.propTypes = {
  label: PT.oneOfType([PT.string, PT.node]),
  required: PT.bool,
  options: PT.arrayOf(PT.shape({
    label: PT.string
  })),
  className: PT.string,
  optionValue: PT.oneOfType([PT.bool, PT.string]),
  deselectable: PT.bool,
  mode: PT.oneOf(['checkbox', 'radio']),
  disabled: PT.bool,
  triple: PT.bool,
  hasValue: PT.bool
}


export const CheckboxGroup = ({label, required, options, input, meta: {touched, error}, className, classes, disabled, moreInfo, columnCount}) => {
  const isOneOptionChoice = options.some(o => o.value === 'Yes');
  const click = event => {
    if (isOneOptionChoice) {
      return input.onChange(event.value);
    } else {
      const newValue = [...input.value];

      if (input.value.indexOf(event.value) === -1)
        newValue.push(event.value);
      else
        newValue.splice(newValue.indexOf(event.value), 1);

      return input.onChange(newValue);
    }
  }

  return (
    <div className={cx("Form__field Form__radio Form__checkboxGroup checkbox", className, {disabled, grid: columnCount})}>
      <label className={cx({required})} htmlFor={input.name}>
        {label}
      </label>
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
      <div>
        {options.map((option, i) => {
          const checked = isOneOptionChoice ? input.value === option.value : input.value.indexOf(option.value) > -1;

          return (
            <div key={i} style={{flex: `0 1 calc(100% / ${columnCount})`}}>
              <div
                className={cx("check", classes, {checked})}
                onClick={() => click(option)}
              >
                {input.value.indexOf(option.value) !== -1 &&
                  <i className="fa fa-check" aria-hidden="true" />
                }
              </div>
              <span>{option.label}</span>
              {moreInfo && <MoreInfo text={moreInfo[i]} />}
            </div>
          )
        })}
      </div>
    </div>
  );
}

export const LabelGroup = ({ label, required, options, input, meta: {touched, error}, className, disabled, info, taglabel, loadOptions }) => {
  const click = event => {
    const newValue = [...input.value];

    if (input.value.indexOf(event.value) === -1)
      newValue.push(event.value);
    else
      newValue.splice(newValue.indexOf(event.value), 1);

    return input.onChange(newValue);
  }

  const handleSearch = e => input.onChange([...input.value, e.value]);
  const removeItem = i => input.onChange(input.value.filter((item, index) => index !== i));

  const items = loadOptions ? input.value.map(option => ({value: option, label: option})) : options;

  return (
    <div className={cx("Form__field Form__labelGroup", className, {disabled})}>
      <div className="form-info">{info}</div>
      <label className={cx({required})} htmlFor={input.name}>
        {label}
      </label>
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
      <div className="Form__labelGroupItems">
        {items.map((item, i) => (
          <button
            key={i}
            type="button"
            className={cx("Form__radioLabel", {
              active: loadOptions || input.value.indexOf(item.value) > -1,
              err: touched && error,
              taglabel
            })}
            onClick={() => !loadOptions && click(item)}
            disabled={disabled}
          >
            {item.label}
            {(loadOptions || (taglabel && input.value.indexOf(item.value) > -1)) &&
              <img
                src={images.closeWhite}
                alt="Remove skill"
                onClick={() => removeItem(i)}
              />
            }
          </button>
        ))}
      </div>
      {loadOptions &&
        <div className="Form__labelGroupSearch">
          <span>Add more</span>
          <div>
            <AsyncCreatableSelect
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null
              }}
              placeholder="Select or type..."
              loadOptions={loadOptions}
              onChange={e => handleSearch(e)}
              styles={customStyles}
            />
            <img src={images.search} alt="Search" />
          </div>
        </div>
      }
    </div>
  );
}

export const Switch = ({input, required, label, options, className, disabled, info, meta: {touched, error}, alt}) => {
  const click = () => {
    if (disabled) return;
    else {
      if (input.value === true)
        input.onChange(false);
      else input.onChange(true);
    }
  }

  return (
    <div className={cx("Form__field Form__switch", className)}>
      {!alt &&
        <label className={cx({required})} htmlFor={input.name}>
          {label}
        </label>
      }
      <div className={cx("form-info", {'Form__switchInfo': info})}>{info}</div>
      <div className="Form__switchContainer">
        {!alt &&
          <span>{options[0].label}</span>
        }
        <Toggle
          checked={Boolean(input.value)}
          onClick={click}
          disabled={disabled}
        />
        {alt &&
          <span className="Form__switchSpanLabel">{label}</span>
        }
        {!alt &&
          <span>{options[1].label}</span>
        }
      </div>
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
    </div>
  )
}


export const Rating = ({input, required, label, className, disabled, meta: {touched, error}}) => {
  return (
    <div className={cx("Form__field", className, {disabled})}>
      <label className={cx({required})} htmlFor={input.name}>
        {label}
      </label>
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
      <RatingComponent
        className={cx("Form__rating", {err: touched && error})}
        onClick={input.onChange}
        rating={input.value}
        size="lg"
        disabled={disabled}
      />
    </div>
  )
}

Rating.propTypes = {
  label: PT.oneOfType([PT.string, PT.node]),
  required: PT.bool,
  className: PT.string,
  disabled: PT.bool
}

export const Captcha = ({input, className, meta: {touched, error}}) => {
  return (
    <div className={cx("Form__field", className, "Form__captcha")}>
      {touched && error &&
        <span className="Form__fieldError">{error}</span>
      }
      <ReCAPTCHA
        sitekey={siteKey}
        onChange={input.onChange}
      />
    </div>
  )
}

Captcha.propTypes = {
  className: PT.string
}

export const VideoField = ({name, className}) => {
  return (
    <div className={cx("Form__video", className)}>
      <Field
        name={`${name}.thumbnail`}
        placeholder="https://"
        label="Video miniature"
        component={ImageField}
        validate={url}
      />
      <Field
        name={`${name}.title`}
        className="Form__videoTitle"
        placeholder="Text in field"
        label="Video title"
        component={TextField}
      />
      <Field
        name={`${name}.url`}
        placeholder="https://"
        label="Video URL"
        component={TextField}
        validate={url}
      />
    </div>
  )
}

const Tile = ({organization, add, remove, isSelected}) => {
  return (
    <div
      className={cx("Tile", {organization, isSelected})}
      onClick={add}
    >
      <div
        className="Tile__logo"
        style={{backgroundImage: organization && `url(${organization && (organization.logo || organization.logoUrl)})`}}
      >
        {isSelected &&
          <div className="Tile__remove" onClick={remove}>
            <img src={images.closeWhite} alt="remove" />
          </div>
        }
        {organization && (!organization.logo && !organization.logoUrl) &&
          <span className="Tile__firstLetter">{capitalizeFirstLetter(organization.name.charAt(0))}</span>
        }
      </div>
    </div>
  )
}

export const OrganizationLogoField = compose(
  lifecycle({
    componentDidMount() {
      this.props.input.onChange(this.props.organizations.reduce((a, b) => [...a, b.id], []));
    }
  })
)(({input, organizations, required, label, tileCount = 3}) => {
  const isSelected = item => input.value?.includes(item.id);

  return (
    <div className="Form__organizationLogoField">
      <label className={cx({required})}>
        {label}
      </label>
      <div>
        {organizations.map(o => (
          <Tile
            key={o.id}
            organization={o}
            isSelected={input.value && input.value.find(organizationId => organizationId === o.id)}
            add={() => !isSelected(o) && input.onChange(input.value.concat(o.id))}
            remove={() => input.onChange(input.value.filter(id => id !== o.id))}
          />
        ))}
        {Array(tileCount - organizations.length).fill().map((item, i) => (
          <Tile key={i} />
        ))}
      </div>
    </div>
  )
})

export const UploadField = ({input, label, required}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files => input.onChange(input.value.concat(files)),
    noDrag: true,
    accept: '.doc, .docx, .pdf',
    multiple: true
  });

  return (
    <div className="Form__upload Form__field">
      <label className={cx({required})}>
        {label}
      </label>
      <ul>
        {input.value?.map((file, index) => (
          <li key={file.key || file.lastModified} className="Form__uploadFile">
            <div>
              <img className="Form__uploadFileIcon" src={images.doc} alt="File" />
              <p>{file.headers ? file.headers[0].value : file.name}</p>
              <span>{convertToMB(file.headers ? file.headers[1].value : file.size)} mb</span>
            </div>
            <button
              className="Form__btnRemove"
              type="button"
              onClick={() => input.onChange(input.value.filter((item, i) => index !== i))}
            >
              <img src={images.close} alt="remove" />
            </button>
          </li>
        ))}
      </ul>
      <section className="Form__uploadContainer">
        <div {...getRootProps({className: 'dropzone'})}>
          <div className="Form__uploadContainerImg">
            <img src={images.plus} alt="Add a resume" />
          </div>
          <div className="Form__uploadContainerInput">
            <input {...getInputProps()} />
            <p>Upload file</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export const UploadBase64 = ({input, label, required, moreInfo, infoText, fileLimit = 1}) => {
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    const fileData = {
      base64,
      name: file.name,
      size: file.size,
      type: file.type
    }

    input.onChange(input.value.concat(fileData));
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    })
  }

  return (
    <div className="Form__uploadBase64 Form__field">
      <div>
        {label &&
          <label className={cx({required})}>
            {label}
          </label>
        }
        {moreInfo &&
          <MoreInfo text={infoText} />
        }
      </div>
      <ul>
        {input.value.length > 0 && input.value?.map((file, index) => (
          <li key={index} className="Form__uploadFile">
            <div>
              <img className="Form__uploadFileIcon" src={images.doc} alt="File" />
              <p>{file.name}</p>
              <span>{convertToMB(file.size)} mb</span>
            </div>
            <button
              className="Form__btnRemove"
              type="button"
              onClick={() => input.onChange(input.value.filter((item, i) => index !== i))}
            >
              <img src={images.close} alt="remove" />
            </button>
          </li>
        ))}
      </ul>
      {(!fileLimit || (fileLimit && input.value?.length < fileLimit)) &&
        <div>
          <label htmlFor="myfileid">
            <div className="fakebtn">
              <div className="icon">
                <img src={images.plus} alt="Add a resume" />
              </div>
              <p className="text">Upload a file</p>
            </div>
          </label>
          <input
            type="file"
            id="myfileid"
            onChange={e => uploadImage(e)}
            style={{visibility: 'hidden'}}
          />
        </div>
      }
    </div>
  )
}
