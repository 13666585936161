import React                    from 'react';
import { lifecycle,
         compose,
         withState,
         withProps,
         withHandlers }         from 'recompose';
import { connect }              from 'react-redux';
import { formValueSelector }    from 'redux-form';
import { withRouter }           from 'react-router';
import { Helmet }               from 'react-helmet';
import Loader                   from 'theme/Loader';
import Footer                   from 'theme/Footer';
import Header                   from 'theme/Header';
import AdminNav                 from 'theme/AdminNav';
import Modal                    from 'theme/Modal';
import PageCover                from 'theme/PageCover';
import withDebounce             from 'theme/utils/recompose';
import { valueToPercent,
         flattenObj,
         upperCaseFirstLetter } from 'theme/utils/helpers';
import { getOrganization,
         updateOrganization,
         getFields }            from 'organizations/actions';
import { getPartners }          from 'partners/actions';
import { actionSucceeded }      from 'store/actions';
import Form                     from './Form';

import './Organization.sass';

const formName = 'Organization';
const orgTypes = ['educator', 'employer', 'partner'];

const Organization = compose(
  withState('previewVisible', 'togglePreview', false),
  withState('isVisible', 'toggleVis', false),
  withState('logoUrlIsVisible', 'toggleLogo', false),
  withState('query', 'setQuery', null),
  withDebounce(),
  withHandlers({
    toggleVisibility: ({ toggleVis, isVisible }) => () => toggleVis(!isVisible),
    toggleLogoUrlVisibility: ({ toggleLogo, logoUrlIsVisible }) => () => toggleLogo(!logoUrlIsVisible)
  }),
  lifecycle({
    componentDidMount() {
      const { isOrgAdminPage, getOrganization, toggleLogo, toggleVis, getPartners, getFields } = this.props;

      if (isOrgAdminPage) {
        orgTypes.map(type => {
          getOrganization(type)
          .then(() => this.props.organizationType != undefined && getFields(this.props.organizationType))
        });
      }
      else {
        getOrganization()
        .then(org => {
          toggleLogo(org.isLogoEnabled);
          toggleVis(org.arePremiumFeaturesEnabled);
        })
        getFields();
      }
      getPartners();
    }
  }),
  withHandlers({
    submit: ({submit, displaySuccessMessage}) => values => {
      return submit(values)
      .then(() => {
        displaySuccessMessage('The data of your organization have been saved successfully.');
      })
    }
  }),
  withProps(({organizationType}) => ({
    organizationType: organizationType && upperCaseFirstLetter(organizationType)
  }))
)(({
  loading,
  organization,
  submit,
  premiumEnabled,
  premiumFeatures,
  previewVisible,
  togglePreview,
  toggleVisibility,
  toggleLogoUrlVisibility,
  isVisible,
  logoUrlIsVisible,
  partners,
  selectedPartners,
  selectedStates,
  isOrgAdminPage,
  user,
  match,
  fields
}) => {
  const partnerOptions = partners.map(p => ({label: p.name, value: p.id}));
  const filterOptions = {
    partners: option => selectedPartners.findIndex(id => id === option.value) === -1,
    states: option => selectedStates.findIndex(state => state === option.value) === -1
  };

  const graduationPercent = organization && organization.quickGlance && organization.quickGlance.graduationPercent;
  const repaymentPercent = organization && organization.quickGlance && organization.quickGlance.repaymentPercent;
  const retentionPercent = organization && organization.quickGlance && organization.quickGlance.retentionPercent;

  const initialValues = organization.class === 'Partner'
    ? organization
    : {
      ...organization,
      partners: organization?.partners?.map(p => p.id),
      quickGlance: {
        ...organization.quickGlance,
        graduationPercent: valueToPercent(graduationPercent),
        repaymentPercent: valueToPercent(repaymentPercent),
        retentionPercent: valueToPercent(retentionPercent)
      }
    };

  const preview = {
    imgUrl: premiumFeatures.imgUrl,
    overlayColor: premiumFeatures.overlayColor,
    overlayOnCover: true,
    title: premiumFeatures.title || 'Cover Title',
    info: premiumFeatures.info && {
      title: premiumFeatures.info.title || 'Info Title',
      text: premiumFeatures.info.text ||  'Enter more information about organization'
    }
  }

  return (
    <div className="Organization page">
      <Helmet>
        <title>Vetit Admin – Organization</title>
      </Helmet>
      <Header />
      {user &&
        <AdminNav />
      }
      <div className="container">
        <Loader className="Organization__loader" loading={loading} />
        {Object.keys(organization).length > 0 &&
          <Form
            initialValues={initialValues}
            onSubmit={submit}
            form={formName}
            type={organization.class}
            premiumEnabled={premiumEnabled}
            premiumToggleDisabled={isOrgAdminPage}
            preview={() => togglePreview(true)}
            isVisible={isVisible || organization.arePremiumFeaturesEnabled}
            logoUrlIsVisible={logoUrlIsVisible}
            toggleVisibility={toggleVisibility}
            toggleLogoUrlVisibility={toggleLogoUrlVisibility}
            partnerOptions={partnerOptions}
            filterOptions={filterOptions}
            user={user}
            partnerId={match.params.id}
            fields={flattenObj(fields)}
            isOrgAdminPage={isOrgAdminPage}
          />
        }
      </div>
      <Footer />
      <Modal
        isOpen={previewVisible}
        close={() => togglePreview(false)}
      >
        <PageCover premium={preview} arePremiumFeaturesEnabled={organization.arePremiumFeaturesEnabled} />
        <div className="Organization__modalBtn">
          <button onClick={() => togglePreview(false)} className="btn-secondary">Close Preview</button>
        </div>
      </Modal>
    </div>
  )
})

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;
  const type = match.params.type;
  const selector = formValueSelector(formName);
  const orgKey = Object.keys(state.data).find(o => o.indexOf(id) !== -1 && state.data[o].id);
  const orgMatch = orgKey && orgKey.match(/.*:(.*):.*/);
  const organizationType = orgMatch && orgMatch[1];

  return {
    user: state.auth.user,
    organization: state.data[orgKey] || {},
    organizationType,
    loading: state.loading[`organization:${organizationType}:${id}` || `organization:${id}`],
    type,
    premiumEnabled: selector(state, 'arePremiumFeaturesEnabled'),
    premiumFeatures: selector(state, 'premiumFeatures') || {},
    partners: (state.data.partners && state.data.partners.data) || [],
    selectedPartners: selector(state, 'partners') || [],
    selectedStates: selector(state, 'states') || [],
    fields: state.data && state.data.fields || {}
  }
}

const mapDispatchToProps = (dispatch, {match, isOrgAdminPage}) => {
  const id = match.params.id;
  const organizationType = match.params.type === 'support-service' ? 'partner' : match.params.type;

  return {
    getOrganization: isOrgAdminPage
      ? (organizationType) => new Promise((resolve, reject) => dispatch(getOrganization(id, organizationType, {resolve, reject})))
      : () => new Promise((resolve, reject) => dispatch(getOrganization(id, organizationType, {resolve, reject}))),
    getPartners: ({query, page} = {}) => dispatch(getPartners({query, page, pageSize: 200})),
    getFields: isOrgAdminPage
      ? (organizationType) => new Promise((resolve, reject) => dispatch(getFields({organizationType, resolve, reject})))
      : () => new Promise((resolve, reject) => dispatch(getFields({organizationType, resolve, reject}))),
    submit: (values) => dispatch(updateOrganization({organizationType, isOrgAdminPage, id, values, form: formName})),
    displaySuccessMessage: (message) => dispatch(actionSucceeded(message))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Organization))
