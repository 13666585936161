import React                from 'react';
import {reduxForm, Field}   from 'redux-form';
import {TextField, Captcha} from 'theme/Form';
import {required}           from 'theme/utils/validate';

import './CompanyForm.sass'


export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting}) => {
    return (
      <div className="CompanyForm">
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            label="Company name"
            required
            placeholder="Enter company name..."
            component={TextField}
          />
          <Field
            name="captcha"
            component={Captcha}
            validate={required}
          />
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting}
          >
          + Add Company
          </button>
        </form>
      </div>
    )
  }
)
