import React                   from 'react';
import { connect }             from 'react-redux';
import { withRouter }          from 'react-router';
import { Helmet }              from 'react-helmet';
import { compose,
         setDisplayName,
         withState,
         lifecycle,
         withHandlers,
         withProps }           from 'recompose';
import { flatten }             from 'underscore';
import cx                      from 'classnames';
import Modal                   from 'theme/Modal';
import Loader                  from 'theme/Loader';
import { images }              from 'theme/img/images';
import { withModal,
         withScroll }          from 'theme/utils/recompose';
import Share                   from 'share/Share';
import JobRow                  from 'jobs/JobRow';
import { getRelatedJobs,
         getJob,
         getJobQuestions,
         getJobStatus,
         apply,
         applyExternally,
         saveJob,
         unsaveJob,
         viewJob }             from 'jobs/actions';
import { getUserProfile,
         getSavedJobs }        from 'user/actions';
import { actionSucceeded }     from 'store/actions';

import Job                     from '../Job';
import ApplyBox                from '../ApplyBox';
import OrganizationBox         from '../OrganizationBox';
import InternalApplicationForm from '../InternalApplicationForm';
import ExternalApplicationForm from '../ExternalApplicationForm';

import './JobContainer.sass';


const JobContainer = compose(
  setDisplayName('JobContainer'),
  withScroll(),
  withState('linkCopied',  'setCopied', false),
  withState('errorOccurred', 'setErrorOccurred', false),
  withModal(),
  withHandlers({
    getData: ({getJob, match, getJobStatus, getJobQuestions, setErrorOccurred, getRelatedJobs, user, isOrgAdminPage}) => () => {
      return getJob({jobId: match.params.jobId})
      .then(response => {
        if (response.jobOpening.type == 'External') {
          getJobStatus()
          .catch(() => setErrorOccurred(true))
          if (response.jobOpening.status == 'Open') {
            getJobQuestions();
          }
        }
        if (user && !isOrgAdminPage)
          getRelatedJobs();
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      const { getData } = this.props;

      document.querySelector('html').style.scrollBehavior = 'unset';
      getData();
    },
    componentDidUpdate(prevProps) {
      const { getData } = this.props;

      if (prevProps.match.params.jobId != this.props.match.params.jobId) {
        getData();
        window.scrollTo(0, 0);
      }
    },
    componentWillUnmount() {
      document.querySelector('html').style.scrollBehavior = 'smooth';
    }
  }),
  withProps(({match}) => ({
    jobUrl: `${location.origin}${match.url}`
  })),
  withHandlers({
    copy: ({setCopied, jobUrl}) => () => {
      navigator.clipboard.writeText(jobUrl);
      setCopied(true);
    },
    closeModal: ({closeModal, setCopied}) => () => {
      closeModal();
      setCopied(false);
    },
    markAsMatching: ({matchingInfo: {matchingSkills, matchingEducationRequirements, matchingIndustryRequirements, user}}) => (item, industryName) => {
      const skillsReqs = matchingSkills?.map(s => ({name: s, type: 'skill'}));
      const educationReqs = matchingEducationRequirements?.map(r => ({degree: r.degree, fieldOfStudy: r.fieldOfStudy, period: r.period, type: 'education'}));
      const industryReqs = matchingIndustryRequirements?.map(r => ({name: r.industry, period: r.period, type: 'industry'}));

      const reqs = flatten([skillsReqs, educationReqs, industryReqs]);

      return user && reqs.find(r => r.type === 'industry'
        ? r.name === industryName && r.period === item.period
        : (r.type === 'education'
          ? r.degree === item.degree && r.fieldOfStudy === item.fieldOfStudy
          : r.name === item.name)
      );
    },
  }),
  withHandlers({
    apply: ({apply, displaySuccessMessage, getJob, closeModal}) => values => {
      return apply(values)
      .then(() => {
        getJob();
        displaySuccessMessage('Your job application has been sent.');
        closeModal();
      })
    },
    applyExternally: ({applyExternally, displaySuccessMessage, getJob, closeModal}) => values => {
      return applyExternally(values)
      .then(() => {
        getJob();
        displaySuccessMessage('Your job application has been sent.');
        closeModal();
      })
    },
    handleSave: ({saveJob, unsaveJob, getJob, getRelatedJobs, getSavedJobs}) => (reloadRelated = false) => (id, action) => {
      const req = action == 'Save' ? saveJob(id) : unsaveJob(id);

      return req
      .then(() => {
        getSavedJobs();

        reloadRelated
        ? getRelatedJobs({loader: false})
        : getJob({loader: false})
      })
    }
  }),
  withProps(({job, job: {skills, leadershipExperience, industryExperience, education}}) => {
    const leadershipExp = leadershipExperience && {...leadershipExperience, label: 'leadership'};
    const industryExp = industryExperience && {...industryExperience, label: 'industry'};

    const populate = array => {
      const result = [];
      array && array.map(s => {
        result.push(s);
      })

      return result;
    };

    const all = job && [leadershipExp, industryExp, ...populate(skills), ...populate(education)]

    return {
      requiredCriteria: all.filter(c => c && c.required),
      niceToHaveCriteria: all.filter(c => c && !c.required)
    }
  })
)(({
  job,
  jobApplication,
  user,
  className,
  organization,
  relatedJobs,
  requiredCriteria,
  niceToHaveCriteria,
  loadingJob,
  loadingRelatedJobs,
  isJobBrowsing,
  isCompanyPage,
  isUserDashboard,
  apply,
  applyExternally,
  userProfile,
  withdraw,
  modal,
  closeModal,
  openModal,
  jobUrl,
  copy,
  linkCopied,
  handleSave,
  markAsMatching,
  viewJob,
  jobQuestions,
  errorOccurred
}) => {
  const external = job?.type == 'External';
  const link = j => j.jobOpening.externalInfo
      ? `/jobs/${j.jobOpening.id}/jazzhr`
      : `/jobs/${j.jobOpening.id}/organizations/${j.organization.internalId}`;

  const jobOpeningProps = j => ({
    job: j.jobOpening,
    employer: j.organization,
    link: link(j),
    handleSave: handleSave(true),
    isRelatedJob: true,
    viewJob,
    user
  });

  const reduce = () => {
    return jobQuestions
    .filter(jq => jq.format === 'File')
    .reduce((a, b) => ({
      ...a,
      [b.id === 'resume' ? b.id : `field-${b.id}`]: []
    }), {})
  };

  const initialValues = external
  ? {
      ...reduce(),
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: user?.email,
      resume: []
    }
  : {
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: user?.email,
      resumes: []
    };

  return (
    <div className={cx("JobContainer", className)}>
      <Helmet>
        <title>{`Vet it – ${organization.name} — ${job.jobTitle}`}</title>
        <meta name="description" content="Check it out!" />
        <meta name="image" content={ organization.logo || images.shareDefaultImage } />
        <meta itemProp="name" content={`Vet it – ${organization.name} — ${job.jobTitle}`} />
        <meta itemProp="description" content="Check it out!" />
        <meta itemProp="image" content={ organization.logo || images.shareDefaultImage } />
        <meta name="og:title" content={`Vet it – ${organization.name} — ${job.jobTitle}`} />
        <meta name="og:description" content="Check it out!" />
        <meta name="og:image" content={ organization.logo || images.shareDefaultImage } />
        <meta name="og:url" content={location.href} />
        <meta name="og:site_name" content={`Vet it – ${organization.name} — ${job.jobTitle}`} />
        <meta name="og:type" content="website" />
        <meta name="twitter:title" content={`Vet it – ${organization.name} — ${job.jobTitle}`} />
        <meta name="twitter:description" content="Check it out!" />
        <meta name="twitter:image" content={organization.logo || images.shareDefaultImage} />
      </Helmet>
      <div className="JobContainer__container">
        <Job
          className="JobContainer__job"
          job={job}
          jobApplication={jobApplication}
          requiredCriteria={requiredCriteria}
          niceToHaveCriteria={niceToHaveCriteria}
          loading={loadingJob}
          withdraw={withdraw}
          user={user}
          openModal={openModal}
          handleSave={handleSave()}
          markAsMatching={markAsMatching}
          isJobAvailable={!errorOccurred}
          showActionButtons={isJobBrowsing || isCompanyPage || isUserDashboard}
        />
        <div className="JobContainer__boxes">
          {user && ((isCompanyPage || isUserDashboard || isJobBrowsing) && Object.keys(jobApplication).length === 0) && !errorOccurred &&
            <ApplyBox apply={() => openModal(external ? 'APPLY_EXTERNAL' : 'APPLY')} />
          }
          {job.internalInfo &&
            <OrganizationBox
              organization={organization}
              loading={loadingJob}
            />
          }
        </div>
      </div>
      <div className="JobContainer__relatedJobs">
        <Loader loading={loadingRelatedJobs} />
        {relatedJobs.length > 0 &&
          <span>You may also like</span>
        }
        {relatedJobs.map(j => (
          <JobRow
            {...jobOpeningProps(j)}
            key={j.jobOpening.id}
          />
        ))}
      </div>

      <Modal
        isOpen={modal.visible}
        className={cx(modal.name == 'SHARE' ? 'shareModal' : 'JobContainer__modal')}
        close={closeModal}
        closeBtn
      >
        {(() => {
          switch(modal.name) {
            case 'SHARE':
              return (
                <Share
                  url={jobUrl}
                  copyUrl={copy}
                  linkCopied={linkCopied}
                  closeModal={closeModal}
                  itemType="job posting"
                  shareType="JobPosting"
                  itemId={job.id}
                  organizationName={job.externalInfo?.organizationName || organization.name}
                  jobShareDescription={`Check out this open ${job.jobTitle} position at ${job.externalInfo?.organizationName || organization.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better!`}
                  linkedinText={`Check out this open ${job.jobTitle} position at ${job.externalInfo?.organizationName || organization.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better! ${jobUrl}`}
                />
              )
            case 'APPLY':
              return (
                <InternalApplicationForm
                  onSubmit={apply}
                  initialValues={initialValues}
                  form="InternalApplicationForm"
                  close={() => closeModal()}
                />
              )
            case 'APPLY_EXTERNAL':
              return (
                <ExternalApplicationForm
                  onSubmit={applyExternally}
                  initialValues={initialValues}
                  form="ExternalApplicationForm"
                  close={() => closeModal()}
                  questions={jobQuestions}
                />
              )
          }
        })()}
      </Modal>
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}, {match: {params: {jobId}}}) => ({
  user: auth.user,
  userProfile: data.user_profile || {},
  job: data[`job:${jobId}`]?.jobOpening || {},
  jobStatus: data[`job_status:${jobId}`] || '',
  jobApplication: data[`job:${jobId}`]?.jobApplication || {},
  matchingInfo: data[`job:${jobId}`]?.matchingInfo || {},
  organization: data[`job:${jobId}`]?.organization || {},
  relatedJobs: data.related_jobs?.data || [],
  loadingJob: loading[`job:${jobId}`],
  loadingRelatedJobs: loading.related_jobs,
  jobQuestions: data.job_questions || []
})

const mapDispatchToProps = (dispatch, {match: {params: {jobId}}}) => ({
  getJob: (args = {}) => new Promise((resolve, reject) => dispatch(getJob({...args, jobId, resolve, reject}))),
  getSavedJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getSavedJobs({...args, resolve, reject}))),
  getRelatedJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getRelatedJobs({...args, jobId, resolve, reject}))),
  getJobQuestions: () => new Promise((resolve, reject) => dispatch(getJobQuestions({jobId, resolve, reject}))),
  apply: values => dispatch(apply(jobId, values)),
  applyExternally: values => dispatch(applyExternally(jobId, values)),
  displaySuccessMessage: message => dispatch(actionSucceeded(message)),
  getUserProfile: (args = {}) => new Promise((resolve, reject) => dispatch(getUserProfile({...args, resolve, reject}))),
  getJobStatus: () => new Promise((resolve, reject) => dispatch(getJobStatus({jobId, resolve, reject}))),
  viewJob: id => dispatch(viewJob(id)),
  saveJob: id => dispatch(saveJob(id)),
  unsaveJob: id => dispatch(unsaveJob(id))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JobContainer))
