import React, { useEffect } from 'react';
import { Helmet }           from 'react-helmet';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import copy                 from 'security/copy';

import security1            from './img/security-1.svg';
import security2            from './img/security-2.svg';
import security3            from './img/security-3.svg';
import security4            from './img/security-4.svg';
import security5            from './img/security-5.svg';

import './Security.sass';


export default function Security() {
  const { dataSecurity, phishing, scams } = copy;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Security page">
      <Helmet>
        <title>Vetit — Security</title>
      </Helmet>
      <Header />
      <div className="Security__jumbotron">
        <h1>Safeguarding your experience</h1>
      </div>
      <section className="Security__dataSecurity">
        <div className="container">
          <div className="Security__dataSecurityHeading">
            <h2>{dataSecurity.title}</h2>
            <p className="secondary-text">{dataSecurity.subtitle}</p>
          </div>
          <article>
            <img src={security1} alt="Connect securely" />
            <div className="right">
              <h3>{dataSecurity.block1.title}</h3>
              <ul className="grid">
                {dataSecurity.block1.list.map((item, i) => (
                  <li key={i} className="secondary-text">{item}</li>
                ))}
              </ul>
            </div>
          </article>
          <article>
            <div className="left">
              <h3>{dataSecurity.block2.title}</h3>
              <ul className="grid">
                {dataSecurity.block2.list.map((item, i) => (
                  <li key={i} className="secondary-text">{item}</li>
                ))}
              </ul>
            </div>
            <img src={security2} alt="Maintain Data Confidence" />
          </article>
          <article>
            <img src={security3} alt="Account Safety Tips" />
            <div className="right">
              <h3>{dataSecurity.block3.title}</h3>
              <ul>
                {dataSecurity.block3.list.map((item, i) => (
                  <li key={i} className="Security__row">
                    <h4  className="Security__subTitle">{item.title}</h4>
                    <p className="secondary-text">{item.text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </article>
        </div>
      </section>
      <section className="Security__phishing">
        <div className="container">
          <h2>{phishing.title}</h2>
          <article>
            <div className="left">
              <h4 className="Security__title align-right">{phishing.signs.title}</h4>
              <ul>
                {phishing.signs.list.map((item, i) => (
                  <li key={i} className="Security__row align-right">
                    <h4 className="Security__subTitle">{item.title}</h4>
                    <p className="secondary-text">{item.text}</p>
                  </li>
                ))}
              </ul>
            </div>
            <img src={security4} alt="Phishing" />
            <div className="right">
              <h4 className="Security__title">{phishing.whatToDo.title}</h4>
              <ul>
                {phishing.whatToDo.list.map((item, i) => (
                  <li key={i} className="Security__row">
                    <h4 className="Security__subTitle">{item.title}</h4>
                    <p className="secondary-text">{item.text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </article>
        </div>
      </section>
      <section className="Security__scams">
        <div className="container">
          <h3>{scams.title}</h3>
          <article>
            <div className="left">
              <div>
                <h4 className="Security__title">{scams.signs.title}</h4>
                <ul>
                  {scams.signs.list.map((item, i) => (
                    <li key={i} className="Security__row">
                      <h4 className="Security__subTitle">{item.title}</h4>
                      <p className="secondary-text">{item.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="Security__title">{scams.whatToDo.title}</h4>
                <ul>
                  {scams.whatToDo.list.map((item, i) => (
                    <li key={i} className="Security__row">
                      <h4 className="Security__subTitle">{item.title}</h4>
                      <p className="secondary-text">{item.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <img src={security5} alt="Scams" />
          </article>
        </div>
      </section>
      <Footer />
    </div>
  )
}
