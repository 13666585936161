import React                   from 'react';
import RSelect, { components } from 'react-select';
import AsyncSelect             from 'react-select/async';
import { compose,
         mapProps,
         setDisplayName,
         withStateHandlers,
         withHandlers }        from 'recompose';
import cx                      from 'classnames';
import Rating                  from 'theme/Rating';
import CustomRadio             from 'theme/CustomRadio';
import { DropdownIndicator }   from 'theme/Form/SelectComponents';

import './OrganizationFilters.sass';

const { Option, ValueContainer } = components;

const focusedOptionStyles = {
  option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    backgroundColor: isFocused ? '#FF4E00' : isSelected ? '#505c6e' : null
  })
}

const selectProps = {
  classNamePrefix: "RSelect",
  components: {
    DropdownIndicator,
    IndicatorSeparator: () => null
  }
};

export default compose(
  setDisplayName('OrganizationFiltersItem'),
  withStateHandlers(() => ({
    inputValue: ''
  }),
  {
    handleInputChange: () => newValue => {
      const inputValue = newValue.replace(/\W/g, '');
      return inputValue;
    }
  }),
  mapProps(props => ({
    ...props,
    isState: props.name === 'state',
    isCity: props.name === 'city' || props.name === 'cities',
    isOrganizationName: props.name === 'organizationName'
  })),
  withHandlers({
    getAutocomplete: ({getAutocomplete, getPartnerAutocomplete, isCity, partnerSelect, name}) => value => {
      const req = partnerSelect ? getPartnerAutocomplete : getAutocomplete;
      return req({prefix: value, field: name, nameOnly: isCity})
      .then(res => res.map(r => ({
        label: isCity ? r : r.name,
        value: isCity ? r : (partnerSelect ? r.id : r.name)
      })))
    }
  })
)(
  ({component,
    options,
    onChange,
    onClick,
    value,
    label,
    flex,
    placeholder,
    sortSelect,
    stateSelectDisabled,
    isState,
    isOrganizationName,
    setRef,
    handleInputChange,
    getAutocomplete,
    isMulti,
    checkbox,
    section,
    partnerSelect,
    mobile
  }) => (
  <div className={cx("OrganizationFilters__item", {checkbox})} style={{flex: mobile ? '1 1 100%' : flex}}>
    <span className="form-label">{label}</span>
    {(() => {
      switch(component) {
        case 'search':
          return (
            <AsyncSelect
              {...selectProps}
              loadOptions={getAutocomplete}
              onChange={e => onChange(e && e.value)}
              onInputChange={handleInputChange}
              placeholder={placeholder}
              value={value
              ?
                (partnerSelect
                ?
                  (options
                    ? options.find(o => {
                      if (o.value == value)
                        return {label: value, value}
                    })
                    : null
                  )
                :
                {label: value, value})
              : null
              }
              isClearable
              isDisabled={isOrganizationName}
              backspaceRemovesValue
            />
          )
        case 'select':
          return (
            <div>
              {isMulti && value &&
                <ul className="multiselectValueList">
                  {value.map(v =>
                    <li
                      key={v}
                      onClick={() => onChange(value.filter(vv => vv !== v))}
                    >
                      {v}
                    </li>
                  )}
                </ul>
              }
              <RSelect
                classNamePrefix="RSelect"
                options={options || []}
                onChange={option =>
                  isMulti
                  ? onChange(option.map(o => o.value))
                  : !sortSelect && onChange(option && option.value)}
                value={(options && value) ? options.find(o => o.value == value) : null}
                valueKey="key"
                placeholder={placeholder || ''}
                isMulti={isMulti}
                isDisabled={section != '/partners' && isState && stateSelectDisabled}
                ref={setRef}
                backspaceRemovesValue
                controlShouldRenderValue={isMulti && false}
                components={
                  {
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option: function iconOption(props) {
                      if(sortSelect)
                        return (
                          <Option {...props} className="OrganizationFilters__sortOption">
                            <div className={cx("sortOptionWrapper", {isFocused: props.isFocused}, {sortSelect})}>
                              {props.data.label}
                            </div>
                          </Option>
                        )
                      return (
                        <Option {...props}>
                          <div className={cx({isFocused: props.isFocused})}>
                            {props.data.label}
                          </div>
                        </Option>
                      )
                    },
                    ValueContainer: function customValueContainer({children, ...props}) {
                      return (
                        <ValueContainer {...props}>
                          <div className="customValueContainer">{children}</div>
                        </ValueContainer>
                      )
                    }
                  }
                }
                styles={focusedOptionStyles}
              />
            </div>
          )
        case 'checkbox':
          return <CustomRadio onClick={onClick} value={value} />
        case 'rating':
          return (
            <Rating
              className={cx("OrganizationFilters__rating", {active: value})}
              rating={value}
              onClick={onClick}
              size='semi-md'
            />
          )
      }
    })()}
  </div>
))
