import React         from 'react';
import { ReactSVG }  from 'react-svg';
import { images }    from 'theme/img/images';

import './PageCover.sass';


export default function PageCover({premium, arePremiumFeaturesEnabled}) {
  return(
    <div className="PageCover">
      {premium?.imgUrl && arePremiumFeaturesEnabled &&
        <div className="PageCover__bgr">
          {premium.overlayOnCover &&
            <div className="PageCover__bgrOverlay" style={{background: premium.overlayColor}} />
          }
          <div className="PageCover__bgrImg" style={{backgroundImage: `url(${premium.imgUrl})`}} />
        </div>
      }
      {premium?.imgUrl && arePremiumFeaturesEnabled &&
        <div className="container">
          <h1>{premium.title}</h1>
          {premium.info && (premium.info.title || premium.info.text || premium.info.url) &&
            <div className="PageCover__info" style={{backgroundColor: premium.boxBackgroundColor}}>
              <h5>{premium.info.title}</h5>
              <p>{premium.info.text}</p>
              {premium.info.url &&
                <div className="right">
                  <a href={premium.info.url} target="_blank">
                    Learn more
                    <ReactSVG
                      src={images.externalLink}
                      aria-label="Learn more"
                    />
                  </a>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  )
}
