import api                  from 'api';
import { formatDate,
         checkIfTimestamp } from 'theme/utils/helpers';


const newFilters = filters => filters
  .filter(({value}) => value !== undefined && value !== null)
  .map(({filterName, value}) => `${filterName}:${value}`);

const fetchJobs = ({page = 0, pageSize = 10, filters = [], sort, query}) => {
  return api({
    method: 'get',
    url: '/job-openings',
    params: {
      page,
      pageSize,
      sort: sort || 'createdAt:d',
      filter: newFilters(filters),
      query
    }
  })
}

const fetchRelatedJobs = ({jobId, page = 0, pageSize = 3}) => {
  return api({
    method: 'get',
    url: `/job-openings/${jobId}/related`,
    params: {
      page,
      pageSize
    }
  })
}

const fetchJobQuestions = ({jobId}) => {
  return api({
    method: 'get',
    url: `/job-openings/${jobId}/questions`
  })
}

export const subscribe = ({filters, query}) => () => {
  return api({
    method: 'post',
    url: '/job-openings/subscriptions',
    params: {
      filter: newFilters(filters),
      query
    }
  })
}

export const getLocations = () => () => {
  return api({
    method: 'get',
    url: '/job-openings/filters'
  })
  .then(({data}) => data)
}

export const getAutocomplete = ({prefix}) => () => {
  return api({
    method: 'get',
    url: '/job-openings/autocomplete',
    params: {
      field: 'location',
      prefix
    }
  })
  .then(({data}) => data)
}

const fetchJob = ({jobId}) => {
  return api({
    method: 'get',
    url: `/job-openings/${jobId}`,
  })
}

const fetchJobStatus = ({jobId}) => {
  return api({
    method: 'get',
    url: `/job-openings/${jobId}/status`
  })
}

export const apply = (jobOpeningId, values) => () => {
  const createFileObject = item => ({
    fileName: item?.name,
    mimeType: item?.type,
    contentBase64: item?.base64.split('base64,').pop()
  });

  return api({
    method: 'post',
    url: '/job-applications',
    data: {
      jobOpeningId,
      internalInfo: {
        ...values,
        resumes: values.resumes?.map(resume => createFileObject(resume))
      }
    }
  })
}

export const applyExternally = (jobOpeningId, values) => () => {
  const parsedValues = Object.entries(values)
  .filter(([key, value]) => key !== 'resume') //eslint-disable-line
  .map(([key, value]) => {
    const newKey = key.includes('field-') ? key.replace('field-', '') : key;

    return {
      questionId: newKey,
      value: checkIfTimestamp(value) ? formatDate(value, 'yyyy-MM-dd') : value
    }
  });

  const fileToken = item => values[item] && values[item][0] && values[item][0].base64.split('base64,').pop() || null;

  return api({
    method: 'post',
    url: '/job-applications',
    data: {
      jobOpeningId,
      externalInfo: values.file
      ? [
          ...parsedValues,
          {questionId: 'resume', value: fileToken('resume')},
          {questionId: 'file', value: fileToken('file')}
        ]
      : [
          ...parsedValues,
          {questionId: 'resume', value: fileToken('resume')}
        ]
    }
  })
}

export const withdraw = applicationId => () => {
  return api({
    method: 'delete',
    url: `/job-applications/${applicationId}`
  })
}

export const saveJob = jobOpeningId => () => {
  return api({
    method: 'post',
    url: `/job-openings/saved/${jobOpeningId}`
  })
}

export const unsaveJob = jobOpeningId => () => {
  return api({
    method: 'delete',
    url: `/job-openings/saved/${jobOpeningId}`
  })
}

export const viewJob = jobOpeningId => () => {
  return api({
    method: 'post',
    url: `/job-openings/viewed/${jobOpeningId}`
  })
}

export const getJobs = ({page, pageSize, filters, sort, query, loader, resolve, reject} = {}) => {
  return {type: 'JOBS/FETCH', req: fetchJobs, page, pageSize, filters, sort, query, loader, resolve, reject}
}

export const getRelatedJobs = ({jobId, page, pageSize, loader, resolve, reject} = {}) => {
  return {type: 'RELATED_JOBS/FETCH', req: fetchRelatedJobs, jobId, page, pageSize, loader, resolve, reject}
}

export const getJobQuestions = ({jobId, resolve, reject} = {}) => {
  return {type: 'JOB_QUESTIONS/FETCH', req: fetchJobQuestions, jobId, resolve, reject}
}

export const getJob = ({jobId, loader, resolve, reject}) => {
  return {type: `JOB:${jobId}/FETCH`, req: fetchJob, jobId, loader, resolve, reject}
}

export const getJobStatus = ({jobId, loader, resolve, reject}) => {
  return {type: `JOB_STATUS:${jobId}/FETCH`, req: fetchJobStatus, jobId, loader, resolve, reject}
}
