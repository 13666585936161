import React from 'react';
import cx    from 'classnames';

import './ListItemLabel.sass';

export default function ListItemLabel({text, color, className}) {
  return (
    <span className={cx('ListItemLabel', color, className)}>
      {text}
    </span>
  )
}
