import React                from 'react';
import cx                   from 'classnames';
import { reduxForm,
         Field,
         FieldArray }       from 'redux-form';
import { TextField,
         Select,
         ArrayField,
         ColorField,
         Radio,
         Rating,
         ImageField,
         VideoField }       from 'theme/Form';
import { options }          from 'theme/content';
import { url,
         normalizeNumber }  from 'theme/utils/validate';

const COLOR_OPTIONS = ['#44D19F', '#5C6373', '#C2D1D9', '#e8ffe7', '#FF4E00', '#FF7300', '#1794DD', '#3b5998', '#E02020', '#DAA520'];

const DetailsField = ({name, className}) => {
  return (
    <div className={cx("Organization__details", className)}>
      <Field
        name={`${name}.title`}
        placeholder="Details item title"
        label="Title"
        component={TextField}
      />
      <FieldArray
        name={`${name}.text`}
        placeholder="Details item paragraph"
        component={ArrayField}
        label="Paragraphs"
      >
        <Field component={TextField} name=""/>
      </FieldArray>
    </div>
  )
}

export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit,
    pristine,
    submitting,
    type,
    user,
    preview,
    isVisible,
    logoUrlIsVisible,
    toggleVisibility,
    toggleLogoUrlVisibility,
    partnerOptions,
    filterOptions,
    premiumToggleDisabled,
    fields,
    isOrgAdminPage
  }) => {
    const disableForOrgAdmin = name => isOrgAdminPage && fields[name];
    return (
      <div className="Organization__form">
        <h4>{type == 'Partner' ? 'Support Service' : type}</h4>
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            label="Name"
            component={TextField}
            placeholder="Type organization name..."
            disabled={disableForOrgAdmin('name')}
          />
          {type != 'Partner' &&
            <Field
              name="isLogoEnabled"
              label="Enable Logo Url"
              component={Radio}
              mode="checkbox"
              onChange={toggleLogoUrlVisibility}
              disabled={premiumToggleDisabled || disableForOrgAdmin('isLogoEnabled')}
            />
          }
          {logoUrlIsVisible &&
            <Field
              name="logo"
              label="Logo URL"
              component={ImageField}
              className="Organization__logo"
              placeholder="http://some-picture.png"
              validate={url}
              disabled={disableForOrgAdmin('logo')}
            />
          }
          {type == 'Partner' && user.isAdmin &&
            <Field
              name="logoUrl"
              label="Logo URL"
              placeholder="http://some-picture.png"
              component={ImageField}
              className="Organization__logo"
              disabled={disableForOrgAdmin('logoUrl')}
            />
          }
          {type == 'Partner' &&
            <div className="Organization__formWrapper">
              <Field
                name="hrefUrl"
                label="Website URL"
                placeholder="http://some-website.com"
                component={TextField}
                validate={url}
                required
                disabled={disableForOrgAdmin('hrefUrl')}
              />
              <Field
                name="founded"
                label="Founded"
                placeholder="1990"
                component={TextField}
                disabled={disableForOrgAdmin('founded')}
              />
              <FieldArray
                name="cities"
                label="Cities"
                component={ArrayField}
                placeholder="San Francisco"
                disabled={disableForOrgAdmin('cities')}
              >
                <Field name="" component={TextField} />
              </FieldArray>
              <FieldArray
                name="states"
                label="States"
                component={ArrayField}
                disabled={disableForOrgAdmin('states')}
              >
                <Field
                  name="state"
                  component={Select}
                  options={options.states}
                  filterOptions={filterOptions.states}
                />
              </FieldArray>
              <Field
                name="type"
                label="Type"
                placeholder="Select a type"
                component={Select}
                options={options.partnerTypes}
                disabled={disableForOrgAdmin('type')}
              />
              <Field
                name="geographicScope"
                label="Geo Scope"
                placeholder="Select a geographic scope"
                component={Select}
                options={options.geographicScopeOptions}
                disabled={disableForOrgAdmin('geographicScope')}
              />
            </div>
          }
          {type != 'Partner' &&
            <FieldArray
              name="partners"
              label="Support Services"
              component={ArrayField}
              disabled={disableForOrgAdmin('partners')}
            >
              <Field
                component={Select}
                name=""
                placeholder="Select a support service"
                options={partnerOptions}
                filterOptions={filterOptions.partners}
              />
            </FieldArray>
          }
          {type != 'Partner' &&
            <h6>Quick Glance</h6>
          }
          {type == 'Educator' &&
            <div className="Organization__formWrapper">
              <Field
                name="quickGlance.isCaution"
                label="Caution"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('quickGlance.isCaution')}
              />
              <Field
                name="quickGlance.accreditation"
                label="Accreditation"
                placeholder="Regional"
                component={Select}
                options={options.accreditationTypes}
                disabled={disableForOrgAdmin('quickGlance.accreditation')}
              />
              <Field
                name="selectivity"
                label="Selectivity"
                placeholder="Choose a selectivity"
                component={Select}
                options={options.selectivityCategories}
                disabled={disableForOrgAdmin('selectivity')}
              />
            </div>
          }
          {type != 'Partner' &&
            <div className="Organization__formWrapper">
              <Field
                name="address.city"
                label="City"
                placeholder="Irving"
                component={TextField}
                disabled={disableForOrgAdmin('address.city')}
              />
              <Field
                name="address.state"
                label="State"
                placeholder="Choose a state"
                component={Select}
                options={options.states}
                disabled={disableForOrgAdmin('address.state')}
              />
              <Field
                name="address.country"
                label="Country"
                placeholder="Choose a country"
                component={Select}
                options={options.countries}
                disabled={disableForOrgAdmin('address.country')}
              />
              <Field
                name="url"
                label="Website URL"
                placeholder="walmart.com"
                component={TextField}
                validate={url}
                disabled={disableForOrgAdmin('url')}
              />
              <Field
                name="type"
                label="Type"
                placeholder={type == 'Employer' ? 'Choose a type' : 'ex. Subsidiary, Private'}
                component={type == 'Employer' ? Select : TextField}
                options={type == 'Employer' && options.companyTypes}
                disabled={disableForOrgAdmin('type')}
              />
            </div>
          }
          {type == 'Employer' &&
            <div className="Organization__formWrapper">
              <Field
                name="founded"
                label="Founded"
                placeholder="1993"
                component={TextField}
                disabled={disableForOrgAdmin('founded')}
              />
              <Field
                name="industry"
                label="Industry"
                component={Select}
                options={options.industries}
                disabled={disableForOrgAdmin('industry')}
              />
              <Field
                name="organizationSize"
                label="Organization Size"
                component={Select}
                options={options.orgSizeOptions}
                disabled={disableForOrgAdmin('organizationSize')}
              />
              <Field
                name="affinityGroupSize"
                label="Affinity Group Size"
                component={Select}
                options={options.affinityGroupSizeOptions}
                disabled={disableForOrgAdmin('affinityGroupSize')}
              />
            </div>
          }
          {type == 'Educator' &&
            <div className="Organization__formWrapper">
              <Field
                name="quickGlance.studentsGiBill"
                label="GI Bill Students"
                placeholder="400"
                component={TextField}
                parse={value => Number(value)}
                disabled={disableForOrgAdmin('quickGlance.studentsGiBill')}
              />
              <Field
                name="quickGlance.studentsUndergraduates"
                label="Undegraduate Students"
                placeholder="1200"
                component={TextField}
                parse={value => Number(value)}
                disabled={disableForOrgAdmin('quickGlance.studentsUndergraduates')}
              />
              <Field
                name="quickGlance.studentsYellowRibbon"
                label="Yellow Ribbon Students"
                placeholder="500"
                component={TextField}
                parse={value => Number(value)}
                disabled={disableForOrgAdmin('quickGlance.studentsYellowRibbon')}
              />
              <Field
                name="quickGlance.retentionPercent"
                label="Retention Percent (%)"
                placeholder="15"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('quickGlance.retentionPercent')}
              />
              <Field
                name="quickGlance.graduationPercent"
                label="Graduation Percent (%)"
                placeholder="35"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('quickGlance.graduationPercent')}
              />
              <Field
                name="quickGlance.repaymentPercent"
                label="Repayment Percent (%)"
                placeholder="20"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('quickGlance.repaymentPercent')}
              />
              <h6>Dollars & Sense</h6>
              <Field
                name="dollarsSense.tuitionInState"
                label="In State Tuition / YR"
                placeholder="300"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('dollarsSense.tuitionInState')}
              />
              <Field
                name="dollarsSense.tuitionOutOfState"
                label="Out Of State Tuition / YR"
                placeholder="200"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('dollarsSense.tuitionOutOfState')}
              />
              <Field
                name="dollarsSense.maxHousingAllowance"
                label="Max Housing Allowance / MO"
                placeholder="1000"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('dollarsSense.maxHousingAllowance')}
              />
              <Field
                name="dollarsSense.medianEarnings"
                label="Median Earnings / YR"
                placeholder="25000"
                component={TextField}
                parse={value => normalizeNumber(value)}
                disabled={disableForOrgAdmin('dollarsSense.medianEarnings')}
              />
            </div>
          }
          {type != 'Partner' &&
            <h6>Indicators</h6>
          }
          {type == 'Educator' &&
            <div className="Organization__formWrapper">
              <Field
                name="quickGlance.isMostlyOnline"
                label="Mostly Online"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('quickGlance.isMostlyOnline')}
              />
              <Field
                name="indicators.is8kc"
                label="8 Key Commitment"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.is8kc')}
              />
              <Field
                name="indicators.isPoe"
                label="Principles of Excellence"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.isPoe')}
              />
              <Field
                name="indicators.isDodmou"
                label="DOD MOU / TA Agreement"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.isDodmou')}
              />
              <Field
                name="indicators.isSvg"
                label="Student Vet Group"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.isSvg')}
              />
            </div>
          }
          {type == 'Employer' &&
            <div className="Organization__formWrapper">
              <Field
                name="indicators.dodSkillbridgeReady"
                label="DOD Skillbridge Ready"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.dodSkillbridgeReady')}
              />
              <Field
                name="indicators.executiveSponsorship"
                label="Executive Sponsorship"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.executiveSponsorship')}
              />
              <Field
                name="indicators.militaryOnboardingProcess"
                label="DOL Apprenticeships"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.militaryOnboardingProcess')}
              />
              <Field
                name="indicators.mentorshipProgram"
                label="Mentorship Program"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.mentorshipProgram')}
              />
              <Field
                name="indicators.militaryLeavePolicy"
                label="ESGR Support Signatory"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.militaryLeavePolicy')}
              />
              <Field
                name="indicators.militarySpousePCSPolicy"
                label="MSEP Support Signatory"
                mode="checkbox"
                component={Radio}
                disabled={disableForOrgAdmin('indicators.militarySpousePCSPolicy')}
              />
            </div>
          }
          {type != 'Partner' &&
            <h6>Premium Features</h6>
          }
          {type != 'Partner' &&
            <div>
              <Field
                name="arePremiumFeaturesEnabled"
                label="Premium Features Enabled"
                component={Radio}
                mode="checkbox"
                onChange={toggleVisibility}
                disabled={premiumToggleDisabled || disableForOrgAdmin('arePremiumFeaturesEnabled')}
              />
              {isVisible &&
                <div className="Organization__formPremium">
                  <h6>Premium Features — Cover Image</h6>
                  <Field
                    name="premiumFeatures.imgUrl"
                    label="Cover Photo URL"
                    component={ImageField}
                    className="Organization__img"
                    placeholder="http://some-picture.png"
                    disabled={disableForOrgAdmin('premiumFeatures.imgUrl')}
                  />
                  <Field
                    name="premiumFeatures.overlayColor"
                    label="Cover Overlay color"
                    component={ColorField}
                    options={COLOR_OPTIONS}
                    disabled={disableForOrgAdmin('premiumFeatures.overlayColor')}
                  />
                  <div className="Organization__enable">
                    <div>
                      <Field
                        name="premiumFeatures.overlayOnCover"
                        label="Enable Overlay on Organization Page"
                        className="Organization__radio"
                        component={Radio}
                        mode="checkbox"
                        disabled={disableForOrgAdmin('premiumFeatures.overlayOnCover')}
                      />
                      <Field
                        name="premiumFeatures.overlayOnLanding"
                        label="Enable Overlay on Landing Page"
                        className="Organization__radio"
                        component={Radio}
                        mode="checkbox"
                        disabled={disableForOrgAdmin('premiumFeatures.overlayOnLanding')}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={preview}
                      className="btn-secondary Organization__preview"
                    >
                      PREVIEW
                    </button>
                  </div>
                  <h6>Premium Features — Cover Content</h6>
                  <Field
                    name="premiumFeatures.title"
                    label="Cover Title"
                    placeholder="ex. We value the potential in you."
                    component={TextField}
                    disabled={disableForOrgAdmin('premiumFeatures.title')}
                  />
                  <Field
                    name="premiumFeatures.info.title"
                    label="Blue Box Title"
                    placeholder="ex. Get Best Advertiser ..."
                    component={TextField}
                    disabled={disableForOrgAdmin('premiumFeatures.info.title')}
                  />
                  <Field
                    name="premiumFeatures.info.text"
                    label="Blue Box Text"
                    placeholder="ex. See how your career..."
                    component={TextField}
                    componentType="textarea"
                    disabled={disableForOrgAdmin('premiumFeatures.info.text')}
                  />
                  <Field
                    name="premiumFeatures.info.url"
                    label="Blue Box 'Learn More' Link"
                    placeholder="ex. http://careers.company.com"
                    component={TextField}
                    disabled={disableForOrgAdmin('premiumFeatures.info.url')}
                  />
                  <h6>Premium Features — Star</h6>
                  <Field
                    name="premiumFeatures.star.title"
                    label="Star Title"
                    placeholder="ex. Vet It Company Rating"
                    component={TextField}
                    disabled={disableForOrgAdmin('premiumFeatures.star.title')}
                  />
                  <FieldArray
                    name="premiumFeatures.star.content"
                    label="Star Paragraphs"
                    placeholder="ex. Veteran Friendly"
                    component={ArrayField}
                    disabled={disableForOrgAdmin('premiumFeatures.star.content')}
                  >
                    <Field component={TextField} name="" />
                  </FieldArray>
                  <h6>Premium Features — Videos</h6>
                  <FieldArray
                    name="premiumFeatures.videos"
                    component={ArrayField}
                    disabled={disableForOrgAdmin('premiumFeatures.videos')}
                  >
                    <VideoField />
                  </FieldArray>
                  <h6>Premium Features — Details</h6>
                  <FieldArray
                    name="premiumFeatures.details"
                    component={ArrayField}
                    disabled={disableForOrgAdmin('premiumFeatures.details')}
                  >
                    <DetailsField />
                  </FieldArray>
                  <h6>Premium Features — Landing Review</h6>
                  <Field
                    name="premiumFeatures.review.title"
                    label="Review Title"
                    placeholder="ex. Tons of post-military employment options"
                    component={TextField}
                    maxlength="120"
                    disabled={disableForOrgAdmin('premiumFeatures.review.title')}
                  />
                  <Field
                    name="premiumFeatures.review.reviewer"
                    label="Reviewer Details"
                    placeholder="ex. Army, 11B, E4"
                    component={TextField}
                    disabled={disableForOrgAdmin('premiumFeatures.review.reviewer')}
                  />
                  <Field
                    name="premiumFeatures.review.rating"
                    label="Review Rating"
                    component={Rating}
                    disabled={disableForOrgAdmin('premiumFeatures.review.rating')}
                  />
                </div>
              }
            </div>
          }
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting}
          >
            SAVE
          </button>
        </form>
      </div>
    )
  }
)
