import React       from 'react';
import { Link }    from 'react-router-dom';
import cx          from 'classnames';

import './MobileMenu.sass';


export default function MobileMenu({links, mobileDropdownClass, visible, toggleMenu, closeSecondaryNav}) {
  const click = () => {
    toggleMenu();
    closeSecondaryNav();
  }

  return (
    <div className={cx("MobileMenu", {visible})}>
      <div
        className={cx("MobileMenu__btn", {visible})}
        onClick={click}
      >
        {visible
          ?
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31">
              <g fill="none" fillRule="evenodd">
                  <g fill="#FF4E00" fillRule="nonzero">
                      <g>
                          <g>
                              <path d="M22.714 19.5c-.29 0-.583-.098-.823-.298l-6.906-5.755-6.894 5.548c-.551.446-1.361.358-1.807-.196-.445-.552-.358-1.361.197-1.807l7.714-6.207c.475-.385 1.158-.38 1.629.014l7.714 6.428c.545.454.618 1.265.164 1.81-.254.305-.62.463-.988.463" transform="translate(-16 -59) translate(-.005) translate(16.005 59.25)"/>
                          </g>
                      </g>
                  </g>
              </g>
            </svg>
          :
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="31" viewBox="0 0 30 31">
              <defs>
                  <path id="0dboqkzaca" d="M16.444 13.688H4.894c-.797 0-1.444.586-1.444 1.312 0 .726.647 1.313 1.444 1.313H25.106c.797 0 1.444-.587 1.444-1.313s-.647-1.313-1.444-1.313h-8.662z"/>
                  <path id="58iq414hfb" d="M16.444 6.05H4.894c-.797 0-1.444.586-1.444 1.312 0 .726.647 1.312 1.444 1.312H25.106c.797 0 1.444-.586 1.444-1.312 0-.726-.647-1.313-1.444-1.313h-8.662z"/>
                  <path id="kpwsns27qc" d="M16.444 21.326H4.894c-.797 0-1.444.586-1.444 1.312 0 .726.647 1.313 1.444 1.313H25.106c.797 0 1.444-.587 1.444-1.313s-.647-1.312-1.444-1.312h-8.662z"/>
              </defs>
              <g fill="none" fillRule="evenodd">
                  <g>
                      <g>
                          <g>
                              <g transform="translate(-16 -60) translate(0 .977) translate(16.005 59.25)">
                                  <use fill="#1A1A1A" fillRule="nonzero" xlinkHref="#0dboqkzaca"/>
                                  <use fill="#1A1A1A" fillRule="nonzero" xlinkHref="#58iq414hfb"/>
                                  <use fill="#1A1A1A" fillRule="nonzero" xlinkHref="#kpwsns27qc"/>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
        }
      </div>
      {visible &&
        <ul className={cx(mobileDropdownClass)}>
          {links.map((l, i) => (
            <li key={i}>
              <Link
                to={l.to}
                onClick={click}
              >
                {l.name.toUpperCase()}
              </Link>
            </li>
          ))}
          <li>
            <Link
              to='/employer'
              onClick={click}
            >
              FOR EMPLOYERS
            </Link>
          </li>
        </ul>
      }
    </div>
  )
}

MobileMenu.displayName = "MobileMenu";
