import React                from 'react';
import { connect }          from 'react-redux';
import { Helmet }           from 'react-helmet';
import { Link }             from 'react-router-dom';
import { compose,
         withState,
         mapProps }         from 'recompose';
import PT                   from 'prop-types';
import CompanyForm          from 'companies/CompanyForm';
import { addCompany }       from 'companies/actions';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import MessageSection       from 'theme/MessageSection';
import { createSlug }       from 'theme/utils/helpers';

import './NewCompany.sass';


const NewCompany = compose(
  withState('submitted', 'setSubmitted', false),
  mapProps(({setSubmitted, addCompany, submitted}) => ({
    submitted,
    addCompany: (...args) =>
      addCompany(...args)
      .then(response => {
        setSubmitted(response)
      })
  }))
)(({addCompany, submitted}) => {
  return(
    <div className="NewCompany page">
      <Helmet>
        <title>{`Vetit – Add New Company`}</title>
      </Helmet>
      <Header />
      {submitted
        ?
          <div className="container">
            <MessageSection title="Thank you for adding a new Company!">
              <Link
                to={`/companies/${submitted.id}/${createSlug(submitted.name.toLowerCase())}/overview`}
                className="btn-primary"
              >
                Show the company
              </Link>
            </MessageSection>
          </div>
        :
        <div className="container">
          <div className="NewCompany__card">
            <h4>New Company</h4>
            <CompanyForm onSubmit={addCompany} form="NewCompany" />
          </div>
          <div className="NewCompany__sidebar">
            <p className="secondary-text">Thank you for adding a new Company!</p>
          </div>
        </div>
      }
      <Footer />
    </div>
  )
})

NewCompany.propTypes = {
  formName: PT.string,
  addCompany: PT.func
}

const mapDispatchToProps = (dispatch, props) => ({
  addCompany: values => new Promise((resolve, reject) => dispatch(addCompany({values, form: props.formName, resolve, reject})))
})

export default connect(
  null,
  mapDispatchToProps
)(NewCompany)
