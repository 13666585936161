const enlist = data => data?.join(', ');

export const labels = {
  quickGlance: item => ([
    {label: 'cities', value: item && enlist(item.cities) || []},
    {label: 'states', value: item && enlist(item.states) || []},
    {label: 'founded', value: item && item.founded},
    {label: 'type', value: item.type},
    {label: 'geo scope', value: item.geographicScope}
  ])
}
