import React                      from 'react';
import { Link }                   from 'react-router-dom';
import { lifecycle,
         withState,
         withHandlers,
         compose }                from 'recompose';
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router';
import { Helmet }                 from 'react-helmet';
import Loader                     from 'theme/Loader';
import Footer                     from 'theme/Footer';
import Header                     from 'theme/Header';
import Modal                      from 'theme/Modal';
import Confirm                    from 'theme/Confirm';
import AdminNav                   from 'theme/AdminNav';
import MessageSection             from 'theme/MessageSection';
import VerificationLabel          from 'theme/VerificationLabel';
import { withModal }              from 'theme/utils/recompose';
import { downloadFile }           from 'user/actions';
import { getUser,
         updateUser,
         verifyUser,
         deleteUserOrganization } from 'users/actions';
import { getOrganizations }       from 'user/actions';
import { getAutocomplete as
         getTopics }              from 'comments/actions';

import Form                       from './Form';

import './User.sass';

const formName = 'User';

const User = compose(
  withState('submitted', 'setSubmitted', false),
  withModal(),
  lifecycle({
    componentDidMount() {
      this.props.getUser();
    },
  }),
  withHandlers({
    verifyUser: ({verifyUser, setSubmitted}) => id => {
      return verifyUser(id)
      .then(() => setSubmitted(true));
    },
    getOrganizations: ({getOrganizations}) => value => {
      if (value === '') return;
      return getOrganizations(value)
      .then(res => res.map(r => ({
        label: r.name,
        value: r.id
      })));
    },
    getTopics: ({getTopics}) => value => {
      if (value === '') return;
      return getTopics({query: value})
      .then(res => res.data.map(r => ({
        label: r.name,
        value: r.id
      })));
    },
    deleteUserOrganization: ({deleteUserOrganization, closeModal}) => id => {
      return deleteUserOrganization(id)
      .then(() => closeModal());
    }
  })
)(({loading, loadingProfile, user, userProfile, submit, getOrganizations, getTopics, deleteUserOrganization, modal, openModal, closeModal, downloadFile, verifyUser, submitted, setSubmitted}) => {
  const mappedChampionTopics = user.championTopics?.map(t => ({value: t.id, label: t.name}));
  const initialValues = user.organization ? {
    ...user,
    championTopics: mappedChampionTopics,
    organizationId: {
      value: user.organization?.internalId,
      label: user.organization?.name
    }
  } : {
    ...user,
    championTopics: mappedChampionTopics
  };

  return (
    <div className="User page">
      <Helmet>
        <title>Vetit Admin – User</title>
      </Helmet>
      <Header />
      <AdminNav />
      {submitted
        ?
          <div className="container">
            <MessageSection
              title="Great!"
              text="The user has been verified."
            >
              <Link
                className="btn-primary"
                onClick={() => setSubmitted(false)}
                to={`/admin/users/${user.id}`}
              >
                Go back to user page
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <Loader className="User__loader" loading={loading} />
            <div className="User__content">
              {!loading &&
                <Form
                  initialValues={initialValues}
                  onSubmit={submit}
                  form={formName}
                  getOrganizations={getOrganizations}
                  getTopics={getTopics}
                  showModal={() => openModal(null, user)}
                />
              }
              {userProfile.proofFile &&
                <div className="User__verification">
                  <Loader loading={loadingProfile} className="User__loader" />
                  <h6>Proof file verification</h6>
                  {userProfile.verified
                    ? <VerificationLabel />
                    : <>
                        <button
                          className="btn-secondary-ghost"
                          onClick={() => downloadFile(user.id)}
                        >
                          Download file
                        </button>
                        <div>
                          <button
                            className="btn-secondary"
                            onClick={() => verifyUser(user.id)}
                          >
                            Verify
                          </button>
                          If the file is OK, click this button to verify the user.
                        </div>
                      </>
                  }
                </div>
              }
            </div>
          </div>
      }
      <Footer />

      <Modal
        close={() => closeModal()}
        isOpen={modal.visible}
        className="Organizations__modal"
      >
        <Confirm
          question="Are you sure you that this user should not administrate the profile of this organization?"
          close={() => closeModal()}
          action={() => deleteUserOrganization(modal.payload.id)}
        />
      </Modal>
    </div>
  )
})

const mapStateToProps = ({data, loading}, {match}) => {
  const id = match.params.id;
  return {
    user: data[`user:${id}`] || {},
    userProfile: (data.user_profile) || {},
    loading: loading[`user:${id}`],
    loadingProfile: loading.user_profile
  }
}

const mapDispatchToProps = (dispatch, {match}) => {
  const id = match.params.id;

  return {
    getUser: () => dispatch(getUser(id)),
    getOrganizations: args => dispatch(getOrganizations(args)),
    getTopics: args => dispatch(getTopics(args)),
    deleteUserOrganization: id => dispatch(deleteUserOrganization(id)),
    downloadFile: userId => dispatch(downloadFile(userId)),
    verifyUser: userId => dispatch(verifyUser(userId)),
    submit: values => dispatch(updateUser({id, values, form: formName}))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(User))
