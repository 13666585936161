import React                from 'react';
import { Link }             from 'react-router-dom';
import { compose,
         setDisplayName,
         withProps }        from 'recompose';
import cx                   from 'classnames';
import Review               from 'theme/Review';
import EmptyReviews         from 'theme/EmptyReviews';

import './ReviewsSection.sass';


const AllReviews = ({manager, className, reviews, linkAdd, reviewName, voteReview, followReview, unfollowReview, view, redirect, isOrgAdminPage}) => {
  return (
    <div className={cx("ReviewsSection__all", className)}>
      {reviews.length == 0
      ?
        <EmptyReviews
          link={`${linkAdd}/${manager.id}/${manager.name}`}
          reviewName={reviewName}
          manager={manager}
        />
      :
        <div>
          {reviews.map((r, i) => (
            <Review
              review={r}
              key={i}
              view={view}
              voteReview={voteReview}
              organization={manager}
              isOrgAdminPage={isOrgAdminPage}
              redirect={redirect}
              follow={followReview}
              unfollow={unfollowReview}
              reviewItem
              card
            />
          ))}
        </div>
      }
    </div>
  )
}


export default compose(
  setDisplayName('ReviewsSection'),
  withProps(({reviews}) => {
    const [firstReview, ...restReviews] = reviews || [];
    return {
      firstReview,
      restReviews
    }
  })
)(({manager, className, title, reviews, children, redirectToReview, reviewName, followReview, unfollowReview, firstReview, link, all, linkAdd, view, voteReview, allReviewsCount, isOrgAdminPage}) => {
  return (
    <div className={cx("ReviewsSection", className)}>
      {reviews.length > 0 &&
        <h3 className="ReviewsSection__title">{title}</h3>
      }
      {children}
      {!all && firstReview &&
        <Review
          review={firstReview}
          view={view}
          voteReview={voteReview}
          organization={manager}
          isOrgAdminPage={isOrgAdminPage}
          redirect={redirectToReview}
          follow={followReview}
          unfollow={unfollowReview}
        />
      }
      {all &&
        <AllReviews
          reviews={reviews}
          manager={manager}
          view={view}
          linkAdd={linkAdd}
          reviewName={reviewName}
          voteReview={voteReview}
          isOrgAdminPage={isOrgAdminPage}
          redirect={redirectToReview}
          followReview={followReview}
          unfollowReview={unfollowReview}
          card
        />
      }
      {!all && reviews.length > 0 &&
        <div className="ReviewsSection__showAll">
          <Link to={link}>See All {allReviewsCount} {reviewName}</Link>
        </div>
      }
    </div>
  )
})