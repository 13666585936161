export default {
  share: {
    share: 'Invite your friends to check this specific content out!',
    invite: 'Vetters transition better! "Vet it Forward" by inviting the military peers, veterans, and spouses you know to share their insights with our community!'
  },
  jobOpening: {
    soc: 'The US system of classifying occupations.',
    skills: 'Add skills that apply to this function and industry.',
    rap: 'Federally approved apprenticeships that are mostly in well established, traditionally blue collar building and industrial trades.',
    irap: 'Federally approved yet decentralized apprenticeships in fast-growing sectors like technology, healthcare, and professional services.',
    dodSkillbridge: 'A program that allows separating service members up to 180 days of permissive TDY (PTO) to participate in a valuable civilian training, apprenticeship, or internship with an approved industry partner.'
  },
  fileProof: 'Acceptable proof includes any of the following\: \n -Gov issued ID Card indicating veteran status (examples include VA, State, Driver License, Uniformed Service, Retiree, Dependent, etc) \n - eBenefits Proof of Service letter \n - VSO ID (eg VFW or American Legion) \n- Military Records (DD214, Award, Evaluation, etc)',
  candidate: {
    militaryBackgroundTip: 'These are generalized assumptions based on the veteran\'s rank and time in military service.',
    etsPaygrade: {
      'E-1': 'As an employee, this individual will be open to learn, will ask questions and wants to understand how his/her job fits within the company\'s processes and goals. Wants to be part of a team, but will hold team members to high standards and share success/blame. Punctual, positive, and goal oriented, looking to move to the next position.',

      'E-2': 'As an employee, this individual will be open to learn, will ask questions and wants to understand how his/her job fits within the company\'s processes and goals. Wants to be part of a team, but will hold team members to high standards and share success/blame. Punctual, positive, and goal oriented, looking to move to the next position. Some fill in supervisory or additional responsibilities are possible.',

      'E-3': 'Accountability for equipment and troubleshooting/problem solving skills are present. You may delegate some low level responsibilities with success. As a rule, veteran will ask for additional information in order to get very clear expectations. Veteran will strive to move into a team leader or first line supervisor. Will hold team members to a high standard.',

      'E-4': 'Veteran will have some leadership capabilities (formal training) and will be interested in making decisions/suggestions that will have a positive impact on the process. Expect this veteran to ask questions and offer suggestions freely. May need clear goals and/or benchmarks in order to measure self and team success. Problem solver, able to find ways to accomplish goals through being creative and thinking outside the box. May very well become your "go to" person and has a high desire to succeed. May need fairly frequent feedback regarding performance at initial stages of employment.',

      'E-5': 'This veteran has had formal leadership training and has been in charge of both projects and people. Would excel as a team leader or a first line supervisor. Will strive to understand clear responsibilities and expectations, will be creative in finding solutions. Communication skills may need to evolve, effective coaching and feedback will help with the transition. Will hold team members to a high standard and integrity, honesty and accountability will be second nature. Will seek additional information and will have a need to understand other functions in the organization and how they fit together.',

      'E-6': 'As an employee, would have more experience as a leader, technical skills, and is used to dealing with day to day issues and personnel. Process oriented, this employee will need to have clear expectations and benchmarks to measure success. Will coach and mentor team members, communicate very clearly and hold them to a high standard. Will willingly follow formal processes and procedures, and offer improvements and suggestions to become better. Wants to be aware of hierarchy and chain of command. Committed and loyal, this employee will strive to learn more and move up in the organization.',

      'E-7': 'This veteran has many years of leadership, and can be a department manager with a lot of activity and demands. Will be organized, can prioritize well and will strive to understand overall company missions. Will measure success on company goals and measures. Communication will need to evolve, may not give a lot of information to those he/she leads. Will expect team members to be a close unit, support each other and work for the common good. Will have much company loyalty and strive to be a respected and valued member of the team. Is used to an environment where taking risks are acceptable and may do so. Employee will plan, execute and evaluate change initiatives. May execute change easily and quickly not understanding how different change is in a civilian environment. Since this veteran served for many years, there may be some transitional issues and will need coaching and mentoring from other veterans.',

      'E-8': 'Senior leader used to operating at a higher level in the organization, excels in strategy development, planning and overseeing execution. Evaluates data and relies on team mates to make decisions. Will hold peers and subordinates at a high level of integrity, energy, and honesty. Communication skills may need to develop, as well as budgetary concepts, profit margins, and other accounting responsibilities may need to be developed. Will gauge success on clear company goals, will hold team members accountable, but will also accept accountability for team. Safety and risk oriented.',

      'E-9': 'Senior leader used to operating at a higher level in the organization, excels in strategy development, planning and overseeing execution. Evaluates data and relies on team mates to make decisions. Will hold peers and subordinates at a high level of integrity, energy, and honesty. Communication skills may need to develop, as well as budgetary concepts, profit margins, and other accounting responsibilities may need to be developed. Will gauge success on clear company goals, will hold team members accountable, but will also accept accountability for team. Will coach and mentor teams for improvement and give clear expectations. Will have a desire for continued improvement and leader development activities. Safety oriented and focused on employee/leader development.',

      'E-10': 'Senior Management and Project Management Skills, often extensive training in this area as well as Leadership. Assumptions can be made that match the skill set of a CEO or owner of a large company. Senior strategist and organizer. Very often has Bachelors/Masters Degree in addition to other applicable training. Management skills that include: Project Management, Speech, Written/Oral Communication, Critical Thinking, Race/Ethnic Diversity, Safety, Self Management. Assumptions can be made pertaining to Law Enforcement.',

      'O-1': 'This individual would have the minimum of a Bachelors Degree. Will expect to have responsibilities, limited leadership experience except in classroom. Will have the equivalent of a new college graduate in area of study.',

      'O-2': 'This individual would have the minimum of a Bachelors Degree. Will expect to have responsibilities, limited leadership experience except in classroom. Will have the equivalent of a new college graduate in area of study.',

      'O-3': 'This individual would have the minimum of a Bachelors Degree, may have Masters Degree. Will have experience in speech, Written Communication, Oral/Interpersonal Communication, Ethics, Leadership and Training, Critical Thinking, entry level management.',

      'O-4': 'This individual would have the minimum of a Bachelors Degree, more than likely has a Masters degree. Veteran received advanced leadership training. Assume veteran has skills similar to a Department or senior Manager in a medium to large company. Assume Skills in: Speech, Written Communication, Oral/Interpersonal Comm, Ethics, Leadership and Training, Critical Thinking, management, planning, logistics, senior project manager.',

      'O-5': 'This individual would have the minimum of a Bachelors Degree, more than likely has a Masters Degree. Veteran received advanced leadership training. Assume student has skills similar to a Department Manager in a medium to large company managing multi levels. Assume Skills in: Speech, Written Communication, Oral/Interpersonal Communications, Ethics, Leadership and Training, Critical Thinking, management. Would have strategic planning, execution, and follow up experience.',

      'O-6': 'This individual would have the minimum of a Bachelors Degree, more than likely has a Masters Degree. Veteran received intense and extensive leadership training. Assume skills similar to a CEO in a medium to large company .Senior level manager, strategist. Assume Skills in: Speech, Written Communication, Oral/Interpersonal Communication, Ethics, Leadership and Training, Critical Thinking, management.',

      'O-7': 'Assume veteran has a Masters Degree, possibly PhD. Most Senior Level of Management. Assume veteran has skills of a CEO of a very large international company. Master strategist.',

      'O-8': 'Assume veteran has a Masters Degree, possibly PhD. Most Senior Level of Management. Assume veteran has skills of a CEO of a very large international company. Master strategist of multiple companies with multiple products. Capable of leading major change initiatives, new product conception and roll out, establishing essential relationships and making decisions capable of major impact on the organization.',

      'O-9': 'Assume veteran has a Masters Degree, possibly PhD. Most Senior Level of Management. Assume veteran has skills of a CEO of a very large international company. Master strategist of multiple companies with multiple products. Capable of leading major change initiatives, new product conception and roll out, establishing essential relationships and making decisions capable of major impact on the organization.',

      'O-10': 'Assume veteran has a Masters Degree, possibly PhD. Most Senior Level of Management. Assume veteran has skills of a CEO of a very large international company. Master strategist of multiple companies with multiple products. Capable of leading major change initiatives, new product conception and roll out, establishing essential relationships and making decisions capable of major impact on the organization.',

      'W-1': 'Highly technical individual, very well trained in a specialty. Can assume veteran has at least an associates degree, possible Bachelors Degree. Assumptions can be made regarding: Witten/oral communication, leadership, Law enforcement skills, Project Management, Speech, Ethics, Critical Thinking.',

      'W-2': 'Can assume veteran has at least an associates degree, possible Bachelors Degree. Warrant officers are technicians, if the veteran performed the same or similar job in the military as the position being hired for, expect high level of proficiency. Assumptions can be made regarding: Witten/oral communication, leadership, Law enforcement skills, Project Management, Speech, Ethics, Critical Thinking.',

      'W-3': 'Can assume veteran has at least a Bachelors Degree. Warrant officers are technicians, if the student performed the same or similar job in the military as job being hired for, expect a high level of expertise. Assumptions can be made regarding: Witten/oral communication, leadership, Law enforcement skills, Project Management, Speech, Ethics, Critical Thinking. Usually manages a team or department, coaching and teaching, planning and managing, setting and reaching goals.',

      'W-4': 'Can assume veteran has at least a Bachelors Degree. Warrant officers are technicians, if the student performed the same or similar job in the military as being hired for, can assume a high level of expertise. Can assume that skills are related to a Senior Technical Manager in a mid to large size company. Assumptions can be made regarding: Witten/oral communication, leadership, Law enforcement skills, Project Management, Speech, Ethics, Critical Thinking. Generally leads a department or section, coaching, mentoring and leading as well as understanding and leading team to accomplish specific goals. Operationally minded with logistics and ROI understanding.',

      'W-5': 'Can assume veterans has at least a Bachelors Degree. Warrant officers are technicians, if the veteran performed the same or similar job in the military as being hired for, assume master level performance. Can assume that skills are related to a CEO in a mid to large size company. Assumptions can be made regarding: Witten/oral communication, leadership, and Law enforcement skills, Project Management, Speech, Ethics, Critical Thinking, decision making, leadership.'
    }
  },
  usaLabel: 'United States',
  applicationCoverLetter: 'Unlike the generic resume you should have uploaded to your profile, this resume should be tailored specifically to this job.',
  employmentDocuments: 'At a minimum, upload your current generic resume. You may also want to upload a generic cover letter. The employers of jobs you apply to on Vet it may review these key employment documents in your profile.',
  employmentFormInfo: {
    example: 'Here you\'ll find some exemplary skills that you can add to your profile:',
    search: 'Below you\'ll see the list of the skills added with the search. You can either choose them from the list or add new ones:'
  },
  skillInputNumbers: ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']
};
