import React        from 'react';
import { Collapse } from 'react-collapse';

import './CollapseComponent.sass';

export default function CollapseComponent({isOpened, children, className}) {
  return (
    <Collapse isOpened={isOpened} className={className}>
      {children}
    </Collapse>
  )
}
