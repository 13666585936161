import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import { compose,
        withState,
        withProps,
        withHandlers,
        withStateHandlers,
        lifecycle }         from 'recompose';
import { pick }             from 'underscore';
import withDebounce         from 'theme/utils/recompose';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Loader               from 'theme/Loader';
import MessageSection       from 'theme/MessageSection';
import Modal                from 'theme/Modal';
import { withScroll }       from 'theme/utils/recompose';
import { getProfile }       from 'auth/actions';
import CompanyForm          from 'companies/CompanyForm';

import Form                 from './Form';
import { getCompanies,
         getAutocomplete,
         addInterviewReview,
         getCompany,
         addCompany,
         getReview,
         updateReview }     from '../actions';

import './InterviewReview.sass';


const formName = "CompanyInterviewReview";
const CompanyFormName = "CompanyForm";

const InterviewReview = compose(
  withScroll(),
  withState('submitted', 'setSubmitted', false),
  withStateHandlers({
    modalOpened: false,
    orgName: ''
  },
  {
    openModal: () => (orgName) => ({
      modalOpened: true,
      orgName
    }),
    closeModal: () => () => ({modalOpened: false})
  }),
  withHandlers({
    nextInterviewReview: (props) => () => props.setSubmitted(false),
    submit: (props) => (values) => {
      return props.submit(values)
      .then(() => props.setSubmitted(values.employer.id || values.employer))
    },
    addCompany: ({addCompany, closeModal}) => (values) => {
      return addCompany(values)
      .then(closeModal);
    },
    getAutocomplete: ({getAutocomplete, name = 'name'}) => value => {
      if (value === '') return;
      return getAutocomplete({field: name, prefix: value});
    }
  }),
  withDebounce({
    functionName: 'search',
    debouncedName: 'searchCompanies',
    withLifecycle: true
  }),
  lifecycle({
    componentDidMount() {
      const { getReview, match, getCompany, getProfile, user } = this.props;

      if(match.params.reviewId)
        getReview();
      if(match.params.companyId)
        getCompany();
      if (user && Object.keys(user).length)
        getProfile(user);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.submitted != this.props.submitted)
        window.scrollTo(0, 0);
    }
  }),
  withProps(({match: {params}, company}) => {
    return {
      companyId: params.companyId,
      companyName: company.name,
      companySlug: company.slug
    }
  })
)(({review,
    submitted,
    nextInterviewReview,
    submit,
    companyId,
    companyName,
    companySlug,
    profile,
    loadingCompany,
    modalOpened,
    openModal,
    closeModal,
    orgName,
    addCompany,
    match,
    getAutocomplete
  }) => {
    const initialValues = !match.params.reviewId
    ? { employer: {id: companyId, name: companyName},
        reviewerDetails: pick(profile, 'mos', 'etsPaygrade', 'serviceBranch', 'email')
      }
    : {...review, employer: {id: companyId, name: companyName}};

    const message = {
      title: `Thanks for paying it forward! ${match.params.reviewId ? 'Your review has been updated.' : 'Your review has been added to this company\'s profile.'}`
    };

    const redirectLink = match.params.reviewId
      ? history.state.state.prevPath
      : `/companies/${submitted}/${companySlug}/overview`;

    return (
      <div className="InterviewReview page">
        <Helmet>
          <title>Vetit — {companyName || ''} Job Applicant Review</title>
        </Helmet>
        <Header />
        {submitted
        ?
          <div className="container">
            <MessageSection {...message}>
              <Link
                className="btn-primary"
                onClick={nextInterviewReview}
                to={redirectLink}
              >
                Return
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <Loader loading={loadingCompany} />
            {!loadingCompany &&
              <Form
                onSubmit={submit}
                form={formName}
                initialValues={initialValues}
                profile={profile}
                openModal={openModal}
                editDisabled={Boolean(match.params.reviewId)}
                getAutocomplete={getAutocomplete}
              />
            }
            <div className="InterviewReview__sidebar">
              <div className="flag first"></div>
              <p className="secondary-text">Informational Interview</p>
              <p className="content-text">This review form allows you to share details about your job application, interview, & onboarding experiences. </p>
              <div className="flag second"></div>
              <p className="secondary-text">Be Constructive!</p>
              <p className="content-text">We value candid insights much more than rose-colored ones, but be professional! Profanity will be removed. </p>
              <p className="content-text">Respond as though a close military friend is reaching out to learn more about this specific experience.</p>
              <div className="flag third"></div>
              <p className="secondary-text">Answer Military Specific Questions</p>
              <p className="content-text">-Did a military connected partner or service aid you in the application process? What was that like?</p>
              <p className="content-text">-Did you consider the company's industry, size, and military affinity group?</p>
              <p className="content-text">-How important were the company's onboarding, mentorship, and military-specific policies to you? </p>
              <p className="content-text">-If you applied through the DoD Skillbridge program, what was that like?</p>
              <p className="content-text">-Were other veterans already at the company helpful?</p>
              <p className="content-text">-Is there anything you wish you had known or insight you'd share with veterans interested in applying?</p>
              <div className="flag fourth"></div>
              <p className="secondary-text">Answer General Questions</p>
              <p className="content-text">-What attracted you to this position/company/industry?</p>
              <p className="content-text">-Did you conduct any focused informational interviews? What did you learn from them?</p>
              <p className="content-text">-Did the position require a particular qualification?</p>
              <p className="content-text">-What aspect(s) of your background were most appealing to the hiring manager?</p>
              <p className="content-text">-Did you consider/apply to similar positions at other companies? Which ones?</p>
              <p className="content-text">-How did you prepare for your hiring interviews and what were they like?</p>
              <p className="content-text">-Were there any unique questions/aspects during the hiring interview process?</p>
              <p className="content-text">-Did you negotiate any employment terms? Which ones mattered most to you?</p>
              <p className="content-text">-How was your onboarding process?</p>
              <div className="flag fifth"></div>
              <p className="secondary-text">How Did You Get The Interview?</p>
              <p className="content-text">Online: Online Ad or Job Posting</p>
              <p className="content-text">3rd Party: Career Event, Fair, Workshop, or External Recruiter</p>
              <p className="content-text">Insider: Referral from a current Employee</p>
              <div className="flag sixth"></div>
              <p className="secondary-text">Your Review Will Remain Anonymous! </p>
              <p className="content-text">Because our community can learn the most from open, honest insights like yours, we want you to feel safe sharing them.</p>
            </div>
          </div>
        }
        <Footer />

        <Modal
          isOpen={modalOpened}
          close={closeModal}
          className="InterviewReview__modal"
        >
          <h4 className="title">New company</h4>
          {orgName &&
            <CompanyForm initialValues={{name: orgName}} onSubmit={addCompany} form={CompanyFormName} />
          }
        </Modal>
      </div>
    )
  }
)

const mapStateToProps = ({auth, data, loading}, props) => {
  const {companies, profile} = data;
  const id = props.match.params.companyId;
  const reviewId = props.match.params.reviewId;
  return {
    user: auth.user,
    companies: (companies && companies.data) || [],
    company: data[`company:${id}`] || {},
    loadingCompanies: loading.companies,
    loadingCompany: loading[`company:${id}`],
    profile: profile || {},
    loadingProfile: loading.profile || false,
    review: (data && data[`review:${reviewId}`]) || {metadata: {}, reviewerDetails: {}}
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const id = props.match.params.companyId;
  const reviewType = 'interviews';
  const reviewId = props.match.params.reviewId;
  return {
    getCompany: () => new Promise((resolve, reject) => dispatch(getCompany(id, {resolve, reject}))),
    getAutocomplete: (args) => dispatch(getAutocomplete(args)),
    search: (query) => new Promise((resolve, reject) => dispatch(getCompanies({query, resolve, reject}))),
    submit: (values) => reviewId
      ? dispatch(updateReview(id, reviewType, reviewId, values))
      : new Promise((resolve, reject) => dispatch(addInterviewReview({values, resolve, reject, form: formName}))),
    getProfile: (user) => dispatch(getProfile(user.id)),
    addCompany: (values) => new Promise((resolve, reject) => dispatch(addCompany({values, form: CompanyFormName, resolve, reject}))),
    getReview: () => new Promise((resolve, reject) => dispatch(getReview({companyId: id, reviewType, reviewId, resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewReview))
