import React                from 'react';
import { connect }          from 'react-redux';
import { Waypoint }         from 'react-waypoint';
import { compose,
         lifecycle,
         setDisplayName,
         withProps,
         withState,
         withStateHandlers,
         withHandlers }     from 'recompose';
import cx                   from 'classnames';
import { pluck }            from 'underscore';
import Gallery              from 'theme/Gallery';
import Loader               from 'theme/Loader';
import Modal                from 'theme/Modal';
import SortDropdown         from 'theme/SortDropdown';
import { withModal,
         withSignature,
         withGallery }      from 'theme/utils/recompose';
import { constants }        from 'theme/utils/constants';
import EditAdminPostForm    from 'admin/AdminPosts/Form';
import AskQuestion          from 'comments/AskQuestion';
import Post                 from 'comments/Post';
import { updateComment,
         voteComment as votePost,
         viewComment,
         getAutocomplete }  from 'comments/actions';
import { viewReview,
         voteReview }       from 'user/actions';
import { viewAdminPost,
         voteAdminPost,
         updateAdminPost }  from 'admin/actions';

import Review               from 'theme/Review';

import './Feed.sass';

const Feed = compose(
  setDisplayName('Feed'),
  withModal(),
  withGallery(),
  withSignature(),
  withState('page', 'setPage', 0),
  withState('loading', 'setLoading', false),
  withState('selectedItem', 'setSelectedItem', null),
  withStateHandlers(({data, sortOptions, reviewsFeed, communityFeed}) => ({
    data,
    sortOption: sortOptions && sortOptions[0],
    reviewsFeed,
    communityFeed
  }),
  {
    addData: ({data, reviewsFeed, communityFeed}) => (i, onMount = false) => {
      const newData = (reviewsFeed || communityFeed)
        ? data
        : data.map(i => ({
          ...i,
          id: reviewsFeed ? i.id : (i.adminPostFeedContent ? i.adminPostFeedContent.id : (i.commentFeedContent ? i.commentFeedContent.id : i.reviewFeedContent.id))
        }));

      const ids = pluck(newData, 'id');
      const uniq = i.filter(ii => ids.indexOf(ii.id) == -1);

      return {data: onMount ? i : [].concat(newData, uniq)};
    },
    setData: () => data => ({data}),
    setSortOption: () => option => ({sortOption: option})
  }),
  lifecycle({
    componentDidMount() {
      const { setLoading, getData, query, addData, optionalArgs } = this.props;
      setLoading(true);

      getData({page: 0, pageSize: constants.COMMENT.PAGE_SIZE, query, ...optionalArgs})
      .then(response => {
        addData(response.data, true);
        setLoading(false);
      });
    },
    componentDidUpdate(prevProps) {
      if (prevProps.topicId !== this.props.topicId)
        this.props.setPage(0);
    }
  }),
  withProps(({data, sortOption, query}) => ({
    actionGetParams: {
      page: 0,
      pageSize: data.length,
      ...sortOption && {sort: `${sortOption.value}:${sortOption.direction}`},
      ...query && {query}
    }
  })),
  withHandlers({
    follow: ({follow, getData, data, setData, query, topicId, optionalArgs}) => (...args) => {
      return follow(...args)
      .then(() => getData({page: 0, pageSize: data.length, query, topicId, sort: 'createdAt:d', loader: false, ...optionalArgs})
        .then(response => setData(response.data)
      ));
    },
    unfollow: ({unfollow, getData, data, setData, query, topicId, optionalArgs}) => (...args) => {
      return unfollow(...args)
      .then(() => getData({page: 0, pageSize: data.length, query, topicId, sort: 'createdAt:d', loader: false, ...optionalArgs})
        .then(response => setData(response.data)
      ));
    },
    vote: ({voteReview, voteAdminPost, votePost, setData, getData, actionGetParams, optionalArgs}) => (itemType, review = null) => (id, action, loader = true) => {
      const req = itemType === 'review'
        ? voteReview(review, id, action)
        : (itemType === 'admin-post'
            ? voteAdminPost(id, action)
            : votePost(id, action)
          );

      return req
      .then(() => {
        getData({...actionGetParams, ...optionalArgs, loader})
        .then(({data}) => setData(data));
      })
    },
    sortData: ({getData, setSortOption, setData, setPage, query}) => option => {
      setSortOption(option);
      setPage(0);

      return getData({page: 0, query, sort: `${option.value}:${option.direction}`})
      .then(({data}) => setData(data));
    },
    showMore: ({page, setPage, getData, loading, setLoading, loadingFeed, sortOption, addData, data, dataCount, query, optionalArgs = {}}) => () => {
      if (loadingFeed || loading || (data.length >= dataCount)) return;
      const pagesCount = Math.ceil(dataCount / constants.COMMENT.PAGE_SIZE);
      const newPage = page + 1 > pagesCount ? pagesCount : page + 1;

      const params = {
        page: newPage,
        pageSize: constants.COMMENT.PAGE_SIZE,
        sort: sortOption && `${sortOption.value}:${sortOption.direction}`,
        query,
        ...optionalArgs
      };

      return getData(params)
      .then(response => {
        addData(response.data);
        setLoading(false);
        setPage(newPage);
      });
    },
    viewReview: ({viewReview}) => review => id => {
      return viewReview(review, id);
    },
    getAutocomplete: ({getAutocomplete}) => value => {
      if (value === '') return;
      return getAutocomplete({query: value})
      .then(res => res.data.map(r => ({
        label: r.name,
        value: r.id
      })));
    },
    updatePost: ({updateQuestion, updateAdminPost, selectedItem, closeModal, getData, data, query, setData, optionalArgs}) => values => {
      const req = selectedItem.adminPostFeedContent
        ? updateAdminPost(values)
        : updateQuestion(values, selectedItem.commentFeedContent ? selectedItem.commentFeedContent.id : selectedItem.id);

      return req
      .then(() => {
        closeModal();
        getData({page: 0, pageSize: data.length, query, ...optionalArgs})
        .then(r =>
          setData(r.data)
        )
      });
    },
    editPost: ({setSelectedItem, openModal}) => item => {
      setSelectedItem(item);
      openModal(item.adminPostFeedContent ? 'EDIT_ADMIN_POST' : 'EDIT_QUESTION', item);
    },
    onReviewClick: ({redirectToReview, selectReview}) => item => {
      selectReview(item);
      redirectToReview(item.id);
    }
  })
)(({data,
   dataCount,
   loading,
   loadingFeed,
   className,
   openGallery,
   modal,
   closeModal,
   imageIndex,
   prev,
   next,
   showMore,
   user,
   editPost,
   updatePost,
   userSignature,
   topics,
   selectedItem,
   sortOption,
   sortOptions,
   sortData,
   follow,
   unfollow,
   emptyText,
   userDashboard,
   reviewsFeed,
   vote,
   viewReview,
   viewAdminPost,
   viewComment,
   history,
   redirectToReview,
   selectReview,
   isMyReviews
  }) => {
  const selectedItemValues = selectedItem?.commentFeedContent || selectedItem;
  const topicOptions = topics.map(t => ({value: t.id, label: t.name}));
  const topicData = {topicId: {value: selectedItemValues?.topic?.id, label: selectedItemValues?.topic?.name}};

  const initialValues = {...topicData, ...selectedItemValues};

  const formProps = {
    signature: userSignature,
    defaultOptions: topicOptions,
    getAutocomplete,
    title: 'Edit your question',
    editBtnText: 'Save my edit',
    topicPlaceholder: "Select or enter new topic"
  };

  return (
    <div className={cx("Feed", className)}>
      <Loader loading={loading} />
      {sortOptions &&
        <div>
          <SortDropdown
            selected={sortOption}
            sort={sortData}
            options={sortOptions}
          />
        </div>
      }
      <div>
        {data.map(item => {
          const { adminPostFeedContent, commentFeedContent, reviewFeedContent, createdAt } = item;
          const content = adminPostFeedContent || commentFeedContent || reviewFeedContent
            ? (adminPostFeedContent ? {...adminPostFeedContent, createdAt, addedByAdmin: true} : (item.commentFeedContent || item.reviewFeedContent))
            : item;

          const reviewData = reviewsFeed ? item : content;

          if(reviewFeedContent || reviewsFeed)
            return (
              <Review
                key={content.id}
                className="Feed__item"
                review={reviewData}
                selectReview={selectReview}
                view={viewReview(reviewData)}
                voteReview={vote('review', reviewData)}
                redirect={redirectToReview(reviewData)}
                isMyReviews={isMyReviews}
                reviewItem
                isFeed
                card
              />
            )
          else return (
            <Post
              key={content.id}
              className="Feed__item"
              post={content}
              authUser={user}
              history={history}
              vote={vote(item.adminPostFeedContent ? 'admin-post' : 'post')}
              view={item.adminPostFeedContent ? viewAdminPost : viewComment}
              follow={follow}
              unfollow={unfollow}
              userDashboard={userDashboard}
              admin={item.adminPostFeedContent !== undefined}
              openGallery={openGallery(item)}
              editPost={() => editPost(item)}
              link={`/${userDashboard ? 'dashboard' : 'org-admin'}/feed/post/${content.id}`}
              topLvlComment
            />
          )
        })}
      </div>
      {(data.length >= dataCount) && !loadingFeed && !loading &&
        <div className="Feed__more">{emptyText}</div>
      }
      {data.length < dataCount && !loadingFeed && !loading &&
        <Waypoint onEnter={showMore} />
      }
      {data.length < dataCount && loadingFeed &&
        <div className="Feed__more">
          <Loader loading={loadingFeed || loading} />
        </div>
      }

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        isGallery={modal.name === 'GALLERY'}
      >
        {(() => {
          switch(modal.name) {
            case 'GALLERY':
              return (
                <Gallery
                  images={modal.payload?.adminPostFeedContent.images}
                  currentIndex={imageIndex}
                  prev={() => prev(modal.payload?.adminPostFeedContent.images, imageIndex)}
                  next={() => next(modal.payload?.adminPostFeedContent.images, imageIndex)}
                />
              )
            case 'EDIT_QUESTION':
              return (
                <AskQuestion
                  onSubmit={updatePost}
                  form="EditQuestionForm"
                  initialValues={initialValues}
                  isQuestionEdited
                  {...formProps}
                />
              )
            case 'EDIT_ADMIN_POST':
              return (
                <EditAdminPostForm
                  onSubmit={updatePost}
                  form="EditAdminPostForm"
                  initialValues={modal.payload?.adminPostFeedContent}
                  postType={modal.payload?.type}
                  title="Edit your post"
                  btnText="Save your edit"
                  isEdited
                />
              )
          }
        })()}
      </Modal>
    </div>
  )
})

const mapStateToProps = ({data: {topics, user_profile}}) => ({
  userProfile: user_profile || {},
  topics: topics?.data || []
});

const mapDispatchToProps = dispatch => ({
  updateQuestion: (values, postId) => dispatch(updateComment({values, topicId: postId})),
  getAutocomplete: (args) => dispatch(getAutocomplete(args)),
  viewAdminPost: id => dispatch(viewAdminPost(id)),
  viewComment: id => dispatch(viewComment(id)),
  viewReview: (review, id) => dispatch(viewReview(review, id)),
  voteReview: (review, id, action) => dispatch(voteReview(review, id, action)),
  voteAdminPost: (id, action) => dispatch(voteAdminPost(id, action)),
  votePost: (id, action) => dispatch(votePost(id, action)),
  updateAdminPost: (values) => dispatch(updateAdminPost({values, organizationId: values.organizationId}))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feed);
