export default {
  dataSecurity: {
    title: 'Securing shared data at the highest standards possible',
    subtitle: 'We are serious about security.',
    block1: {
      title: 'Connect Securely',
      list: [
        'HTTPS encryption',
        'Security protocols',
        'Verification protocols',
        'SSL API (AES-256 bit encryption)',
        '.Mil domains',
        'TLS 1.2 for HTTPS encryption',
        'VA Lighthouse API',
        'OWASP compliant',
        'Manual checks',
        'bcrypt encryption',
        'Community guidelines'
      ]
    },
    block2: {
      title: 'Maintain Data Confidence',
      list: [
        'Database replication',
        'OFCCP Compliance',
        'Periodic snapshots',
        'Access Control and Provisioning',
        'Digital Ocean',
        'Incident Response',
        'SOC2',
        'GDPR',
        'Pen testing',
        'EEO',
        'Automated assessments'
      ]
    },
    block3: {
      title: 'Account Safety Tips',
      list: [
        {title: 'Create a tougher password', text: 'Use a strong, unique password for your vetitforward.com account.'},
        {title: 'Reset your password', text: 'if you think someone may have unauthorized access to your account, change your password'},
        {title: 'Scan for problems and update software frequently', text: 'Run antivirus software and keep your device’s operating system and all software up to date'},
        {title: 'Double up', text: 'Use 2 Factor Authentication on sensitive accounts if available (such as bank accounts or email accounts).'},
        {title: 'Contact us', text: 'If you have security concerns.'}
      ]
    }
  },
  phishing: {
    title: 'What to Know About Phishing',
    signs: {
      title: 'Common signs of phishing',
      list: [
        {title: 'From an unknown sender', text: 'Indeed-related emails will only come from @vetitforward.com - be wary of emails from a company or candidate that you haven’t contacted'},
        {title: 'Threatening or urgent', text: 'A claim your account will be locked if you don’t respond.'},
        {title: 'Too good to be true', text: 'Request you to claim lottery winnings or a large inheritance.'},
        {title: 'Includes an unexpected attachment', text: 'Invoices, obscure file types, or documents prompting you to “enable macros”.'},
        {title: 'Links to a suspicious website', text: 'The website doesn’t look quite right or uses a “look-a-like domain”'}
      ]
    },
    whatToDo: {
      title: 'What you can do',
      list: [
        {title: 'Contact the company', text: 'If you suspect that someone is fraudulently claiming to be a legitimate company, use a trusted communication such as a phone number listed on their website to reach out to the company directly.'},
        {title: 'Be wary of attachments', text: 'Avoid opening unexpected attachments, especially those with unusual file extensions (.zip, .jar, .js).'},
        {title: 'Use security tools', text: 'Enable and regularly update anti-virus software, firewalls, and security patches.'},
        {title: 'Keep personal information personal', text: 'Don’t send sensitive information such as bank account or credit card data over email or instant messenger.'}
      ]
    }
  },
  scams: {
    title: 'Scams Targeting Job Seekers',
    signs: {
      title: 'Common scams',
      list: [
        {title: 'Remote work', text: 'Scammers may request money for a work-from-home “starter kit” or send you a check with a request that you wire a portion of the money back.'},
        {title: 'Tax scams', text: 'Fraudulent employers may request past W2s or other tax information. Never provide personal or financial information via email.'}
      ]
    },
    whatToDo: {
      title: 'What you can do',
      list: [
        {title: 'Interview process', text: 'Be wary of employers who offer you a job without an interview or formal application process.'},
        {title: 'Don\'t send money', text: 'Never pay upfront for a job or “starter kit.”Don’t transfer  “extra” or accidental pay back to employer. Contact your bank for assistance.'}
      ]
    }
  }
}