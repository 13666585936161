import { fork, takeEvery, put, call, all, throttle }        from 'redux-saga/effects';
import { startSubmit, stopSubmit, reset, SubmissionError }  from 'redux-form';
import { createSlug }                                       from 'theme/utils/helpers';


const forkSagas = (...sagas) => {
  return sagas.map(s => fork(s));
}

export default function* rootSaga() {
  yield all(forkSagas(
    watchFetch,
    watchSubmit,
    watchFeed
  ))
}

const FETCH_PATTERN = /^((?!(FEED|USER__REVIEWS)).*)\/(FETCH|DELETE)$/;
const FEED_PATTERN = /(FEED|USER__REVIEWS)\/FETCH$/;
const SUBMIT_PATTERN = /(.*)\/SUBMIT$/;

function* watchFetch() {
  yield takeEvery(
    action => FETCH_PATTERN.test(action.type),
    fetchSaga
  )
}

function* watchSubmit() {
  yield takeEvery(
    action => SUBMIT_PATTERN.test(action.type),
    formSaga
  )
}

function* watchFeed() {
  yield throttle(
    1000,
    action => FEED_PATTERN.test(action.type),
    fetchSaga
  )
}

function* formSaga(action) {
  const match = SUBMIT_PATTERN.exec(action.type);
  yield put({ type: `${match[1]}/LOADING`});
  yield put(startSubmit(action.form));

  try {
    const a = {...action, values: action.values};
    const response = yield call(action.req, a);
    const data = response.data || response;

    yield put(reset(action.form));
    yield put(stopSubmit(action.form));

    yield put({ type: `${match[1]}/SUBMIT_SUCCESS`, data });
    yield put({ type: `${match[1]}/FETCH_SUCCESS`, data });
    yield put({ type: `${match[1]}/LOADED`});
    yield put({ type: 'CLEAR_ERRORS'});

    action.resolve && action.resolve(data);

  } catch (error) {
    if (error instanceof SubmissionError) {
      yield put(stopSubmit(action.form, error.errors))
    } else {
      console.error(error) // eslint-disable-line no-console
      yield put(stopSubmit(action.form, { _error: error && error.message }))
    }
    yield put({ type: `${match[1]}/SUBMIT_ERROR`, error: error.response || error});
    yield put({ type: `${match[1]}/LOADED`});
    action.reject && action.reject(error.response || error);
  }
}

function* fetchSaga(action) {
  const match = FETCH_PATTERN.exec(action.type) || FEED_PATTERN.exec(action.type);
  const loader = action.loader !== false;

  if(loader) yield put({ type: `${match[1]}/LOADING`});

  try {
    const response = yield call(action.req, action);
    const data = response.data || response;

    // dispatch a success action to the store
    const isObject = !(data instanceof Array) && !data.data != null;
    const isPaginatedArray = data.data != null && data.count != null;

    const dataWithSlug = isPaginatedArray ? {
      ...data,
      data: data.data.map(d => ({...d, slug: createSlug(d.name)}))
    } : isObject ? {
      ...data,
      slug: createSlug(data.name)
    } : data.map(d => ({...d, slug: createSlug(d.name)}));

    yield put({ type: `${match[1]}/FETCH_SUCCESS`, data: dataWithSlug });

    if(loader) yield put({ type: `${match[1]}/LOADED`});
    yield put({ type: 'CLEAR_ERRORS'});

    action.resolve && action.resolve(dataWithSlug);

  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: `${match[1]}/FETCH_ERROR`, error: error.response || error });
    yield put({ type: `${match[1]}/LOADED`});
    action.reject && action.reject(error.response || error);
  }
}
