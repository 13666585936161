import { push }                      from 'connected-react-router';
import jwtDecode                     from 'jwt-decode';
import api,
       { API_CONF,
         saveAuth,
         deleteAuth }                from 'api';
import { omit }                      from 'underscore';
import { env }                       from 'theme/content';
import { LOGGED_SUCCESSFULLY,
         LOGGED_OUT }                from 'store/types';
import { requestSuccess,
         createRequest,
         handleError }               from 'store/actions';


export function loginSuccess(response) {
  return { auth: response, type: LOGGED_SUCCESSFULLY };
}

export function accountConfirmed() {
  return {type: 'AUTH/ACCOUNT_CONFIRMED'}
}

export function closeConfirmationMessage() {
  return {type: 'AUTH/CLOSE_CONFIRMATION_MESSAGE'}
}

// could put params to logout()
export function logout() {
  return (dispatch) => {
    dispatch({type: LOGGED_OUT});
    deleteAuth();
    dispatch(push('/'));
  }
}

export function saveToken(auth) {
  return (dispatch) => {
    const authData = saveAuth(auth);
    dispatch(loginSuccess(authData));
  }
}

export function confirmAccount(mailToken) {
  return (dispatch) => {
    dispatch(createRequest({mailToken}));
    return api({
      method: 'post',
      url: '/auth/confirmation',
      data: {mailToken}
    })
    .then((response) => {
      dispatch(requestSuccess(response));
      dispatch(accountConfirmed());
    })
    .catch(handleError(dispatch))
  }
}

export const verify = (userId, method) => () => {
  const splitString = method == 'mil' ? 'secret=' : 'code=';
  const code = method == 'mil'
    ? location.href.split(splitString).pop()
    : location.href.split(splitString).pop().split('&')[0]
  const state = location.href.split('state=').pop();

  const url = method == 'mil'
    ? `/users/${userId}/verify?method=EmailCode`
    : `/users/${userId}/verify?method=Va`;

  const data = method == 'mil'
    ? { code }
    : { code, state, redirectUri: API_CONF.VA_REDIRECT_URI };

  return api({
    method: 'post',
    url,
    data
  })
}

export function login({password, email, redirectUri, code, provider}) {
  return (dispatch) => {
    dispatch(createRequest({password, email, code}));
    const request = () => {
      if (email && password) {
        return api({
          method: 'post',
          url: '/auth/session?method=EmailPass',
          data: {
            email,
            password,
            loginUrl: location.origin.concat('/auth/signin')
          }
        })
      }
      else if (code && provider) {
        return api({
          method: 'post',
          url: `/auth/session?method=${provider}`,
          data: {code, redirectUri}
        });
      }
    }
    return request()
    .then(response => {
      const data = jwtDecode(response.data);

      dispatch(saveToken(response.data));
      dispatch(push(data.isOnboarded
        ? (data.organizationId ? env.HOME_ROUTE.organizationAdmin : env.HOME_ROUTE.common)
        : (data.organizationId ? `${env.HOME_ROUTE.organizationAdmin}?first-time` : '/onboarding')
      ));

      return response;
    })
    .catch(handleError(dispatch))
    .catch((error) => {
      return Promise.reject({
        status: error.response.status,
        message: error.response.data.message
      })
    });
  }
}

export function requestNewPassword({email}) {
  return () => {
    return api({
      method: 'post',
      url: 'auth/password',
      data: {
        email,
        resetUrl: location.origin.concat('/reset-password')
      }
    })
  }
}

export function resetPassword({password}, mailToken) {
  return () => {
    return api({
      method: 'patch',
      url: 'auth/password',
      data: {
        password,
        mailToken
      }
    })
  }
}

export function register({password, email, redirectUri, code, provider}) {
  return (dispatch) => {
    dispatch(createRequest({password, email, code}));
    const request = () => {
      if (email && password) {
        return api({
          method: 'post',
          url: '/auth/register?method=EmailPass',
          data: {
            email,
            password,
            loginUrl: location.origin.concat('/auth/signin?mailToken={TOKEN}')
          }
        });
      }
      else if (code && provider) {
        return api({
          method: 'post',
          url: `/auth/register?method=${provider}`,
          data: {code, redirectUri}
        });
      }
    }
    return request()
    .then(response => {

      dispatch(saveToken(response.data));
      if(code && provider)
        dispatch(push('/auth/signup?registered=true&social=true'));
      else
        dispatch(push('/auth/signup?registered=true'));

      return response;
    })
    .catch(handleError(dispatch))
    .catch((error) => {
      if(code && provider)
        dispatch(push(`/auth/signup?error=true&status=${error.response.status}`));
      return Promise.reject({
        status: error.response.status,
        message: error.response.data.message
      })
    });
  }
}

export function registerEmployer(values) {
  const isNew = values.employerAccount.employer.value === values.employerAccount.employer.label;
  const employerInfo = isNew
    ? {employerName: values.employerAccount.employer.value}
    : {employerId: values.employerAccount.employer.value, employerName: values.employerAccount.employer.label};

  return (dispatch) => {
    // dispatch(createRequest({password, email}));
    const request = () => {
      return api({
        method: 'post',
        url: '/auth/register/employer?method=EmailPass',
        data: {
          ...values,
          employerAccount: {
            ...omit(values.employerAccount, 'employer'),
            ...employerInfo
          },
          loginUrl: location.origin.concat('/auth/signin?mailToken={TOKEN}')
        }
      });
    }
    return request()
    .then(response => {
      dispatch(saveToken(response.data));
      return response;
    })
    .catch(handleError(dispatch))
    .catch(error => {
      return Promise.reject({
        status: error.response.status,
        message: error.response.data.message
      })
    });
  }
}

export function resendEmail({email, loginUrl}) {
  return api({
    method: 'post',
    url: '/auth/confirmation/resend',
    data: { email, loginUrl }
  })
}

function fetchProfile({id}) {
  return api({
    method: "get",
    url: `/users/${id}`
  });
}

export const getProfile = (id, {resolve, reject, loader} = {}) => {
  return {type: 'PROFILE/FETCH', req: fetchProfile, id, resolve, reject, loader}
}

export function checkCaptcha (responseToken) {
  return api({
    method: 'post',
    url: '/auth/captcha',
    params: {responseToken}
  })
  .then(response => {
    return response.data;
  })
}
