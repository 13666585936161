import alert                from './images/alert.svg';
import arrowDown            from './images/arrow-down.svg';
import arrowLeft            from './images/arrow-left.svg';
import arrowLeftDark        from './images/arrow-left-dark.svg';
import arrowRight           from './images/arrow-right.svg';
import authBackground       from './images/auth-background.jpg';
import binoculars           from './images/binoculars.svg';
import briefcase            from './images/briefcase.svg';
import bulbGray             from './images/bulb-gray.png';
import bulbGreen            from './images/bulb-green.png';
import calendar             from './images/calendar.svg';
import checkmark            from './images/checkmark.svg';
import checkmarkSmall       from './images/checkmark small.svg';
import chevronLeft          from './images/chevron-left.svg';
import chevronLeftGray      from './images/chevron-left-gray.svg';
import chevronRight         from './images/chevron-right.svg';
import chevronRightGray     from './images/chevron-right-gray.svg';
import closeBlack           from './images/close-black.svg';
import closeWhite           from './images/close-white.svg';
import close                from './images/close.svg';
import comment              from './images/comment.svg';
import companies            from './images/companies.svg';
import compass              from './images/compass.svg';
import doc                  from './images/doc.svg';
import dots                 from './images/dots.svg';
import down                 from './images/down.svg';
import download             from './images/download.svg';
import dropdownIcon         from './images/dropdown-icon.png';
import edit                 from './images/edit.svg';
import editAlt              from './images/edit-alt.svg';
import expand               from './images/expand.svg';
import expandBig            from './images/expand-big.svg';
import externalLink         from './images/external-link.svg';
import eye                  from './images/eye.svg';
import eyeOff               from './images/eye-off.svg';
import fb                   from './images/fb.svg';
import feature1             from './images/feature1.svg';
import feature2             from './images/feature2.svg';
import feature3             from './images/feature3.svg';
import feature4             from './images/feature4.svg';
import feature5             from './images/feature5.svg';
import feature6             from './images/feature6.svg';
import film                 from './images/film.svg';
import hide                 from './images/hide.svg';
import hideBlack            from './images/hide-black.svg';
import home                 from './images/home.svg';
import iconArrowHide        from './images/icon-arrow-hide.svg';
import iconFilters          from './images/icon-filters.svg';
import iconMenu             from './images/icon-menu.svg';
import image                from './images/image.svg';
import inbox                from './images/inbox.svg';
import isolinesBig          from './images/isolines-big.png';
import isolinesWBg          from './images/isolines-w-bg.png';
import linkedin             from './images/in.svg';
import logo                 from './images/logo.svg';
import logoWhite            from './images/logo-white.svg';
import message              from './images/message.svg';
import messageOrange        from './images/message-orange.svg';
import messageWhite         from './images/message-white.svg';
import msg                  from './images/msg.svg';
import people               from './images/people.svg';
import personAdd            from './images/person-add.svg';
import personBlack          from './images/person-black.svg';
import personGray           from './images/person-gray.svg';
import personXs             from './images/person-xs.svg';
import personXsWhite        from './images/person-xs-white.svg';
import pin                  from './images/pin.svg';
import plus                 from './images/plus.svg';
import plusOrange           from './images/plus-orange.svg';
import questionMarkCircle   from './images/question-mark-circle.svg';
import roadsign             from './images/roadsign.svg';
import search               from './images/search.svg';
import searchMobile         from './images/search-mobile.svg';
import searchMobileOrange   from './images/search-mobile-orange.svg';
import share                from './images/share 2.svg';
import shield               from './images/shield.svg';
import tw                   from './images/tw.svg';
import userAvatar           from './images/user-avatar.png';

export const images = {
  alert,
  arrowDown,
  arrowLeft,
  arrowLeftDark,
  arrowRight,
  authBackground,
  binoculars,
  briefcase,
  bulbGray,
  bulbGreen,
  calendar,
  checkmark,
  checkmarkSmall,
  chevronLeft,
  chevronLeftGray,
  chevronRight,
  chevronRightGray,
  closeBlack,
  closeWhite,
  close,
  comment,
  companies,
  compass,
  doc,
  dots,
  down,
  download,
  dropdownIcon,
  edit,
  editAlt,
  expand,
  expandBig,
  externalLink,
  eye,
  eyeOff,
  fb,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  film,
  hide,
  hideBlack,
  home,
  iconArrowHide,
  iconFilters,
  iconMenu,
  image,
  inbox,
  isolinesBig,
  isolinesWBg,
  linkedin,
  logo,
  logoWhite,
  message,
  messageOrange,
  messageWhite,
  msg,
  people,
  personAdd,
  personBlack,
  personGray,
  personXs,
  personXsWhite,
  pin,
  plus,
  plusOrange,
  questionMarkCircle,
  roadsign,
  search,
  searchMobile,
  searchMobileOrange,
  share,
  shareDefaultImage: 'https://images.unsplash.com/photo-1556761175-b413da4baf72?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80',
  shield,
  tw,
  userAvatar
};
