import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { Link }               from 'react-router-dom';
import { Helmet }             from 'react-helmet';
import { lifecycle,
         compose,
         withState,
         withHandlers }       from 'recompose';
import Loader                 from 'theme/Loader';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import AdminNav               from 'theme/AdminNav';
import ManagerLogo            from 'theme/ManagerLogo';
import Pagination             from 'theme/Pagination';
import Modal                  from 'theme/Modal';
import Confirm                from 'theme/Confirm';
import withDebounce,
      { withModal,
        withScroll }          from 'theme/utils/recompose';
import { getEducators,
         getEmployers,
         getPartners,
         indexData,
         deleteOrganization } from 'organizations/actions';

import './Organizations.sass';


const Organizations = compose(
  withState('activePage', 'setPage', 0),
  withState('query', 'setQuery', ''),
  withState('indexMsgIsVisible', 'showIndexMsg', false),
  withModal(),
  withScroll(),
  lifecycle({
    componentDidMount() {
      this.props.getOrganizations(this.props.activePage)();
    },
    componentDidUpdate({section}) {
      if(section !== this.props.section) {
        this.props.getOrganizations(0)();
        this.props.setPage(0);
      }
    }
  }),
  withHandlers({
    deleteOrganization: ({deleteOrganization, closeModal, activePage, query}) => organization => {
      deleteOrganization(organization, {page: activePage, query})
      .then(() => closeModal())
    },
    search: ({getOrganizations, page = 0}) => getOrganizations(page),
    getOrganizations: ({getOrganizations, setPage, activePage, query}) => page => {
      setPage(page);
      getOrganizations(page)(query)
      .catch(() => setPage(activePage));
    }
  }),
  withDebounce(),
  withHandlers({
    onChange: ({setQuery, searchDebounced}) => e => {
      const value = e.target.value;
      setQuery(value);
      searchDebounced(value);
    },
    indexData: ({indexData, section, showIndexMsg}) => () => {
      return indexData(section)
      .then(showIndexMsg(true));
    }
  })
)(({
  loading,
  organizations,
  deleteOrganization,
  modal,
  closeModal,
  openModal,
  organizationsCount,
  getOrganizations,
  activePage,
  query,
  onChange,
  section,
  indexData,
  indexMsgIsVisible
}) => {
  const organizationLink = o => `/admin/organizations/${o.class && o.class === 'Partner' ? 'support-service' : o.class.toLowerCase()}/${o.id}`;
  const organizationSettingsLink = `/admin/organizations/${section.substring(0, section.length - 1)}/settings`;

  return (
    <div className="Organizations page">
      <Helmet>
        <title>{`Vetit Admin — ${section == 'support-services' ? 'Support Services' : 'Organizations'}`}</title>
      </Helmet>
      <Header />
      <AdminNav />
      <div className="Organizations__heading">
        <input onChange={onChange} value={query} placeholder="Search organizations" />
        <div>
          {indexMsgIsVisible &&
            <p className="indexMessage">Please wait a few seconds and refresh the page.</p>
          }
          <button
            className="Organizations__headingBtn btn-secondary"
            onClick={indexData}
            disabled={indexMsgIsVisible}
          >
            Index
          </button>
          <Link
            className="Organizations__headingBtn btn-secondary"
            to={organizationSettingsLink}
          >
            Form Settings
          </Link>
          {section == 'support-services' &&
            <Link className="Organizations__headingBtn btn-primary" to='/admin/support-services/new'>
              + <span className="Organizations__headingPartner">Support Service</span>
            </Link>
          }
        </div>
      </div>
      <div className="container admin-container">
        <Loader className="Organizations__loader" loading={loading} />
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              {section != 'partners' &&
                <th>Premium</th>
              }
              {section == 'partners' &&
                <th>Website</th>
              }
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {organizations
            .map(o => (
                <tr className="Organizations__tableRow" key={o.id}>
                  <td className="Organizations__logo">
                    <Link to={organizationLink(o)}>
                      <ManagerLogo manager={o} size='sm' />
                    </Link>
                  </td>
                  <td className="Organizations__name" data-th="Name">
                    <Link to={organizationLink(o)}>
                      {o.name}
                    </Link>
                  </td>
                  {section != 'support-services' &&
                    <td data-th="Premium">
                      {o && o.arePremiumFeaturesEnabled &&
                        <span className="fa fa-check" />
                      }
                    </td>
                  }
                  {section == 'support-services' &&
                    <td data-th="Website">
                      <a href={o.hrefUrl} target="_blank">{o.hrefUrl}</a>
                    </td>
                  }
                  <td>
                    <Link
                      to={organizationLink(o)}
                      className="btn-edit"
                    >
                      EDIT
                    </Link>
                    <button
                      className="btn-delete"
                      onClick={() => openModal(null, o)}
                    >
                      DELETE
                    </button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <Pagination
        itemsCount={organizationsCount}
        gotoPage={getOrganizations}
        disabled={loading}
        activePage={activePage}
      />
      <Footer />

      <Modal
        close={() => closeModal()}
        isOpen={modal.visible != false}
        className="Organizations__modal"
      >
        <Confirm
          question={`Are you sure you want to delete ${modal && modal.payload && modal.payload.name}?`}
          close={() => closeModal()}
          action={() => deleteOrganization(modal)}
        />
      </Modal>
    </div>
  )
})

const mapStateToProps = ({auth, data: {admin_employers = {}, admin_educators = {}, admin_partners = {}}, loading}, {section}) => {
  const setOrganizations = items => (items.topHit ? [items.topHit, ...items.data] : items.data) || [];
  const partnersWithClass = setOrganizations(admin_partners).map(i => ({...i, class: 'Partner'}));

  switch(section) {
    case 'educators':
      return {
        user: auth.user,
        organizations: setOrganizations(admin_educators),
        organizationsCount: admin_educators.count || 0,
        loading: loading.admin_educators
      }
    case 'employers':
      return {
        user: auth.user,
        organizations: setOrganizations(admin_employers),
        organizationsCount: admin_employers.count || 0,
        loading: loading.admin_employers
      }
    default:
      return {
        user: auth.user,
        organizations: partnersWithClass,
        organizationsCount: admin_partners.count || 0,
        loading: loading.admin_partners
      }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getOrganizations: (page) => (query) => new Promise((resolve, reject) => {
      switch (props.section) {
        case 'educators':
          return dispatch(getEducators({query, page, resolve, reject}));
        case 'employers':
          return dispatch(getEmployers({query, page, resolve, reject}));
        default:
          return dispatch(getPartners({query, page, resolve, reject}));
      }
    }),
    indexData: (args) => dispatch(indexData(args)),
    deleteOrganization: (organization, {page, query} = {}) => dispatch(deleteOrganization({organization, page, query}))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations))
