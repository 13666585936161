import React              from 'react';
import { Link }           from 'react-router-dom';
import { compose,
         withProps,
         withHandlers,
         setDisplayName } from 'recompose';
import qs                 from 'qs';
import Modal              from 'theme/Modal';
import Confirm            from 'theme/Confirm';
import { withModal }      from 'theme/utils/recompose';
import JobRow             from 'jobs/JobRow';

import './SubscribedSearch.sass';


const SubscribedSearch = compose(
  setDisplayName('SubscribedSearch'),
  withModal(),
  withHandlers({
    deleteSearch: ({closeModal, deleteSearch}) => id => {
      return deleteSearch(id)
      .then(() => closeModal());
    }
  }),
  withProps(({search: {subscription, searchResults: {topHit, data}}}) => {
    const a = subscription.filterTerms
      .map((f => ({
        columnName: f.columnName,
        filterValue: f.filterValues
          ? f.filterValues.map(v => ({columnName: f.columnName, filterValue: v}))
          : f.filterValue
      })))

    const b = a
      .filter(item => item.filterValue instanceof Array)
      .reduce((a, b) => [...a, ...b.filterValue], [])

    return {
      results: topHit && Object.keys(topHit).length > 0 ? [topHit, ...data] : data,
      filterTerms: a
        .filter(item => !(item.filterValue instanceof Array))
        .concat(b)
        .map(t => {
          switch(t.filterValue) {
            case 'now-1M/d':
              return t.filterValue.replace(t.filterValue, 'Past month');
            case 'now-1w/d':
              return t.filterValue.replace(t.filterValue, 'Past week');
            case '1970-01-01':
              return t.filterValue.replace(t.filterValue, 'Any time');
            default:
              return t.filterValue;
          }
        })
        .concat(subscription.textQuery)
        .join('; ')
    }
  }),
  withProps(({search: {subscription}}) => {
    const queryString = subscription.filterTerms.reduce((obj, item) =>
      Object.assign(obj, {[item.columnName]: item.filterValue || item.filterValues}), {}
    );
    const q = qs.stringify(queryString, {arrayFormat: 'repeat'});

    return {
      subscriptionParam: subscription.textQuery ? q.concat(`&query=${subscription.textQuery}`) : q
    }
  })
)(({search, results, filterTerms, deleteSearch, openModal, modal, closeModal, subscriptionParam, viewJob, user, handleSave}) => {
  return (
    <div className="SubscribedSearch">
      <div className="SubscribedSearch__heading">
        <h5>Saved search: {filterTerms}</h5>
        <div>
          <span>Results: {results.length} of {search.searchResults.count}</span>
          <Link
            className="btn-secondary-transparent"
            to={`/jobs/${subscriptionParam}`}
          >
            View all
          </Link>
          <button
            className="btn-secondary-transparent icon"
            onClick={() => openModal()}
          >
            <svg width="12px" height="12px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <title>Icon / close</title>
              <desc>Created with Sketch.</desc>
              <defs>
                <path d="M11.41425,10.00025 L15.70725,5.70725 C16.09825,5.31625 16.09825,4.68425 15.70725,4.29325 C15.31625,3.90225 14.68425,3.90225 14.29325,4.29325 L10.00025,8.58625 L5.70725,4.29325 C5.31625,3.90225 4.68425,3.90225 4.29325,4.29325 C3.90225,4.68425 3.90225,5.31625 4.29325,5.70725 L8.58625,10.00025 L4.29325,14.29325 C3.90225,14.68425 3.90225,15.31625 4.29325,15.70725 C4.48825,15.90225 4.74425,16.00025 5.00025,16.00025 C5.25625,16.00025 5.51225,15.90225 5.70725,15.70725 L10.00025,11.41425 L14.29325,15.70725 C14.48825,15.90225 14.74425,16.00025 15.00025,16.00025 C15.25625,16.00025 15.51225,15.90225 15.70725,15.70725 C16.09825,15.31625 16.09825,14.68425 15.70725,14.29325 L11.41425,10.00025 Z" id="path-1"></path>
              </defs>
              <g id="Icon-/-close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="🎨-Icon-Сolor" fill="#1A1A1A" fillRule="nonzero" xlinkHref="#path-1"></use>
              </g>
            </svg>
          </button>
        </div>
      </div>
      {results.map(r => {
        return (
          <JobRow
            key={r.jobOpening.id}
            job={r.jobOpening}
            employer={r.organization}
            status={r.jobApplication?.status}
            link={`/dashboard/jobs/searches/${r.jobOpening.id}`}
            viewJob={viewJob}
            user={user}
            handleSave={handleSave}
          />
        )
      })}
      <Modal isOpen={modal.visible}>
        <Confirm
          question="Are you sure that you want to delete this search?"
          close={closeModal}
          action={() => deleteSearch(search.subscription.id)}
        />
      </Modal>
    </div>
  )
})

export default SubscribedSearch;
