import React                 from 'react';
import { connect }           from 'react-redux';
import { withRouter }        from 'react-router';
import { lifecycle,
         compose,
         withState,
         mapProps,
         setDisplayName,
         withProps,
         withHandlers }      from 'recompose';
import { debounce }          from 'underscore';
import { env }               from 'theme/content';
import Footer                from 'theme/Footer';
import Header                from 'theme/Header';
import Loader                from 'theme/Loader';
import Profile               from 'theme/Profile';
import SingleReview          from 'theme/SingleReview';
import QuickGlance           from 'theme/QuickGlance';
import ReviewsSection        from 'theme/ReviewsSection';
import ManagerStats          from 'theme/ManagerStats';
import Pagination            from 'theme/Pagination';
import { withScroll }        from 'theme/utils/recompose';
import { images }            from 'theme/img/images';
import { getPartner,
         getPartnerReviews,
         getPartnerReview,
         follow,
         unfollow,
         followReview,
         unfollowReview,
         viewReview,
         voteReview }        from 'partners/actions';

import { labels }            from './Labels';
import './Partner.sass';


const headingWithCount = (name, count) => {
  if (!count || count == 0)
    return name;
  return (
    <span>
      {name}
      <span className="count"> ({count})</span>
    </span>
  )
};

const headingSections = reviewsCount => [
  {key: 'overview', name: <span><span className="hidden-xs"></span>Overview</span>},
  {key: 'reviews', name: headingWithCount('Reviews', reviewsCount)}
];

const Partner = compose(
  setDisplayName('Partner'),
  withScroll(),
  withState('page', 'setPage', 0),
  withProps(({match}) => ({
    activeSection: match.params.section,
    reviewId: match.params.reviewId,
    partnerId: match.params.id,
    bodyRef: React.createRef()
  })),
  mapProps(props => ({
    ...props,
    voteReview: debounce(props.voteReview, 1000, true)
  })),
  withHandlers({
    scrollToReviewsListTop: ({bodyRef}) => () => window.scrollTo(0, bodyRef.current.offsetTop),
    follow: ({follow, getPartner, getPartnerReviews, getReview, reviewId}) => (...args) => {
      return follow(...args)
      .then(() => {
        getPartner({loader: false});
        getPartnerReviews(0, false);

        if (reviewId)
          getReview({loader: false});
      })
    },
    unfollow: ({unfollow, getPartner, getPartnerReviews, getReview, reviewId}) => (...args) => {
      return unfollow(...args)
      .then(() => {
        getPartner({loader: false});
        getPartnerReviews(0, false);

        if (reviewId)
          getReview({loader: false});
      })
    },
    followReview: ({followReview, getPartnerReviews, partnerId, page}) => reviewId => {

      return followReview(partnerId, reviewId)
      .then(() => getPartnerReviews(page, false));
    },
    unfollowReview: ({unfollowReview, getPartnerReviews, partnerId, page}) => reviewId => {

      return unfollowReview(partnerId, reviewId)
      .then(() => getPartnerReviews(page, false));
    }
  }),
  withHandlers({
    redirect: ({history, match}) => () => {
      const link = match.path.includes('admin') ? '/admin/support-services' : '/support-services';
      history.push(link);
    },
    redirectToReview: ({history, partner}) => (reviewId, reported = false, returnUrl = false) => {
      const url = `/support-services/${partner.id}/${partner.slug}/reviews/${reviewId}`;

      if (returnUrl)
        return url;
      else history.push(reported ? `${url}${env.QUERY.reviewReported}` : url);
    },
    getPartnerReviews: ({getPartnerReviews, setPage, scrollToReviewsListTop}) => (page, onMount = false) => {
      return getPartnerReviews(page)
      .then(() => {
        setPage(page);
        if (!onMount)
          scrollToReviewsListTop();
      });
    },
    voteReview: ({voteReview, page}) => (loadReview = false) => (id, action) => {
      return voteReview(id, action, {page, loadReview});
    }
  }),
  lifecycle({
    componentDidMount() {
      const { partnerId, getPartner, getPartnerReviews, reviewId, getReview } = this.props;

      getPartnerReviews(0, true);

      if(partnerId)
        getPartner();

      if (reviewId)
        getReview();
    },
    componentDidUpdate(prevProps) {
      const { reviewId, getReview, getPartnerReviews } = this.props;

      if (prevProps.reviewId != reviewId) {
        if (reviewId)
          getReview();
        else getPartnerReviews(this.props.page);
      }
    }
  })
)(({
  partner,
  loading,
  page,
  user,
  partnerReviews,
  partnerReviewsCount,
  getPartnerReviews,
  activeSection,
  voteReview,
  viewReview,
  follow,
  unfollow,
  followReview,
  unfollowReview,
  bodyRef,
  review,
  reviewId,
  redirectToReview,
  history
}) => {

  const mobileReviewLinks = [
    {text: '+ REVIEW', icon: images.comment, fn: () => history.push(`/support-services/review/${partner.id}/${partner.slug}`)}
  ];

  return (
    <div className="Partner page">
      <Header />
      <Profile
        manager={partner}
        activeSection={activeSection}
        sections={headingSections(partnerReviewsCount)}
        prefix="support-services"
        loading={loading.partner}
        user={user}
        follow={follow}
        unfollow={unfollow}
        mobileReviewLinks={mobileReviewLinks}
      >
        <div key="left" ref={bodyRef}>
          {activeSection == 'overview' &&
            <div>
              <div className="card">
                <Loader loading={loading.partner} />
                <QuickGlance
                  data={partner}
                  labels={labels.quickGlance(partner)}
                  loading={loading.partner}
                  hasColumns
                  numberOfRows={3}
                />
              </div>
              {partnerReviews.length > 0 &&
                <div className="card">
                  <div className="position-relative">
                    <Loader loading={loading.reviews} />
                    <ReviewsSection
                      key={page}
                      manager={partner}
                      reviews={partnerReviews}
                      view={viewReview}
                      title="Support Service Experiences"
                      reviewName="Support Service Reviews"
                      link={`/support-services/${partner.id}/${partner.slug}/reviews`}
                      linkAdd="/support-services/review"
                      allReviewsCount={partnerReviewsCount}
                      redirectToReview={redirectToReview}
                      voteReview={voteReview()}
                      followReview={followReview}
                      unfollowReview={unfollowReview}
                    >
                      {partner.statistics &&
                        <ManagerStats
                          statistics={partner.statistics}
                          reviews={partnerReviews}
                          className="Partner__statistics"
                          allReviewsCount={partnerReviewsCount}
                        />
                      }
                    </ReviewsSection>
                  </div>
                </div>
              }
            </div>
          }
          {activeSection == 'reviews' && !reviewId &&
            <div>
              <div className="position-relative">
                <Loader loading={loading.reviews} />
                <ReviewsSection
                  manager={partner}
                  reviews={partnerReviews}
                  view={viewReview}
                  title="Support Service Experiences"
                  reviewName="Support Service Reviews"
                  link={`/support-services/${partner.id}/${partner.slug}/reviews`}
                  linkAdd="/support-services/review"
                  allReviewsCount={partnerReviewsCount}
                  redirectToReview={redirectToReview}
                  voteReview={voteReview()}
                  followReview={followReview}
                  unfollowReview={unfollowReview}
                  all
                />
              </div>
              <Pagination
                className="Partner__pagination"
                itemsCount={partnerReviewsCount}
                gotoPage={getPartnerReviews}
                activePage={page}
                disabled={loading.reviews}
              />
            </div>
          }
          {activeSection == 'reviews' && reviewId &&
            <SingleReview
              review={review}
              id={reviewId}
              user={user}
              voteReview={voteReview(true)}
              loading={loading.review}
              goBack={() => history.goBack()}
              followReview={followReview}
              unfollowReview={unfollowReview}
            />
          }
        </div>
        <div key="right"></div>
      </Profile>
      <Footer />
  </div>
  )
});

const mapStateToProps = ({auth, data, loading}, {match: {params: {id, reviewId}}}) => ({
  user: auth.user,
  review: data[`partner_review:${reviewId}`] || {},
  partner: (data && data[`partner:${id}`]) || {},
  partnerReviews: (data[`partner:${id}:reviews`] && data[`partner:${id}:reviews`].data) || [],
  partnerReviewsCount: (data[`partner:${id}:reviews`] && data[`partner:${id}:reviews`].count) || 0,
  loading: {
    partner: loading[`partner:${id}`],
    review: loading[`partner_review:${reviewId}`],
    reviews: loading[`partner:${id}:reviews`]
  }
});

const mapDispatchToProps = (dispatch, {match: {params: {id, reviewId}}}) => ({
  getPartner: (args = {}) => new Promise((resolve, reject) => dispatch(getPartner(id, {...args, resolve, reject}))),
  getReview: (args = {}) => new Promise((resolve, reject) => dispatch(getPartnerReview(id, reviewId, {...args, resolve, reject}))),
  getPartnerReviews: (page, loader) => new Promise((resolve, reject) => dispatch(getPartnerReviews(id, {page, loader, resolve, reject}))),
  viewReview: (...args) => dispatch(viewReview(id, ...args)),
  voteReview: (...args) => dispatch(voteReview(id, ...args)),
  follow: () => dispatch(follow(id)),
  unfollow: () => dispatch(unfollow(id)),
  followReview: (...args) => dispatch(followReview(...args)),
  unfollowReview: (...args) => dispatch(unfollowReview(...args))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Partner));
