import React                  from 'react';
import { reduxForm, Field }   from 'redux-form';
import { compose,
         setDisplayName }     from 'recompose';
import Agreement              from 'theme/Agreement';
import { TextField,
         Radio,
         Creatable }          from 'theme/Form';
import { required }           from 'theme/utils/validate';
import { withPasswordToggle } from 'theme/utils/recompose';

import './Form.sass';


const validate = values => {
  const errors = {};

  if(!values.employerAccount?.employer) {
    errors.companyName = 'Required'
  }
  if(!values.employerAccount?.jobTitle) {
    errors.jobTitle = 'Required'
  }
  if(!values.employerAccount?.firstName) {
    errors.firstName = 'Required'
  }
  if(!values.employerAccount?.lastName) {
    errors.lastName = 'Required'
  }
  if(!values.password) {
    errors.password = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.agree) {
    errors.agree = 'Required'
  }
  return errors;
}

export default compose(
  setDisplayName('CompanyLandingForm'),
  withPasswordToggle(),
)(reduxForm({
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, pristine, submitting, getEmployers, invalid, checkPasswordVisible, togglePassword}) => {
    return (
      <div className="CompanyLandingForm">
        <h3>Create employer account</h3>
        <form onSubmit={handleSubmit}>
          <Field
            name="employerAccount.employer"
            label="Your Company"
            placeholder="Start typing or enter new company name"
            component={Creatable}
            validate={required}
            getAutocomplete={getEmployers}
            createLabel="+ Add a new company"
            required
            isEmployerSignup
            async
          />
          <Field
            name="employerAccount.jobTitle"
            label="Your Job Title"
            required
            component={TextField}
            validate={required}
          />
          <div className="CompanyLandingForm__name">
            <Field
              name="employerAccount.firstName"
              label="First Name"
              required
              component={TextField}
              validate={required}
            />
            <Field
              name="employerAccount.lastName"
              label="Last Name"
              required
              component={TextField}
              validate={required}
            />
          </div>
          <Field
            name="email"
            label="Business Email Address"
            required
            component={TextField}
            validate={required}
          />
          <Field
            name="password"
            label="Password"
            type={checkPasswordVisible('password') ? 'text' : 'password'}
            required
            component={TextField}
            isPasswordVisible={checkPasswordVisible('confirmPassword')}
            togglePassword={togglePassword}
            validate={required}
          />
          <Field
            name="agree"
            component={Radio}
            label={<Agreement />}
            className="CompanyLandingForm__agreement"
            placeholder="Set your password"
            mode="checkbox"
          />
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting || invalid}
          >
            Join
          </button>
        </form>
      </div>
    )
  }
))
