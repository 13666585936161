import React from 'react';

import './Gallery.sass';

export default function Gallery({images, currentIndex, prev, next}) {
  return (
    <div
      className="Gallery"
      style={{backgroundImage: `url(${images[currentIndex]})`}}
    >
      {images.length > 1 &&
        <div className="Gallery__wrapper">
          <div className="Gallery__arrow prev">
            <span
              onClick={prev}
              className="fa fa-chevron-left"
            />
          </div>
          <div className="Gallery__counter">
            <span>{`${currentIndex + 1} / ${images.length}`}</span>
          </div>
          <div className="Gallery__arrow next">
            <span
              onClick={next}
              className="fa fa-chevron-right"
            />
          </div>
        </div>
      }
    </div>
  )
}
