import React from 'react';

import './Signature.sass';

export default function Signature({signature, byTopicChampion}) {
  const elements = Object.values(signature);
  const text = elements.every(el => !el)
    ? 'Anonymous'
    : elements.filter(Boolean).join(' / ');

  return (
    <span className="Signature">
      {byTopicChampion ? 'Topic Champion' : text}
    </span>
  )
}
