import jwtDecode from 'jwt-decode';

export const loadAuth = () => {
  try {
    const serializedState = localStorage.getItem(`Vetit__auth_${location.hostname}`);
    if (serializedState == null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
}

export const saveAuth = (auth) => {
  try {
    const user = jwtDecode(auth.token || auth);
    const serializedState = {
      user,
      token: auth.token || auth
    }

    localStorage.setItem(`Vetit__auth_${location.hostname}`, JSON.stringify(serializedState));
    return serializedState;
  } catch (err) {
    // console.log('error', err);
  }
}

export const deleteAuth = () => {
  try {
    localStorage.removeItem(`Vetit__auth_${location.hostname}`);
  } catch (err) {
    // console.log(err);
  }
}
