import React                       from 'react';
import { reduxForm,
         Field,
         Fields,
         FieldArray }              from 'redux-form';
import { compose,
         setDisplayName }          from 'recompose';
import cx                          from 'classnames';
import { Switch,
         Datepicker,
         MultipleField,
         ArrayField,
         Creatable,
         LabelGroup }              from 'theme/Form';
import MoreInfo                    from 'theme/MoreInfo';
import FileUpload                  from 'theme/FileUpload';
import { withStaticFormSections }  from 'theme/utils/recompose';
import { env,
         copy,
         options,
         skillExamples }           from 'theme/content';
import WorkExperienceRow           from 'jobs/WorkExperienceRow';

import './EmploymentForm.sass';

const WorkExperienceField = () => (
  <FieldArray
    name="employments"
    className=""
    component={ArrayField}
    buttonText="Add job position"
    removeBtn={false}
  >
    <Fields
      names={['position', 'company', 'industry', 'location', 'start', 'end']}
      component={MultipleField}
      components={['input', 'input', 'select', 'input', 'datepicker', 'datepicker']}
      options={options.industries}
      keyName="employments"
      keys={['position', 'company', 'industry', 'location', 'start', 'end']}
      placeholders={['e.g. Sales Associate', 'e.g. Amazon', 'Select', 'e.g. Austin, TX', env.DATE_FORMAT, env.DATE_FORMAT]}
      labels={['Title/Position', 'Company', 'Industry', 'Location', 'Start date', 'End date']}
    />
  </FieldArray>
)

export default compose(
  setDisplayName('EmploymentForm'),
  withStaticFormSections()
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, submitting, pristine, initialValues, className, isSettings, isEdited, toggleEditedSections, isOnboarding, uploadProps, getSkills}) => {
    return (
      <div className="EmploymentForm">
        <form onSubmit={handleSubmit} className="EmploymentForm__form">
          <div className={cx('EmploymentForm__jobSeekingPreferences form-container-section', className)}>
            <h5 className="subheader">Job seeking preferences</h5>
            {initialValues.seekingEmployment &&
              <Field
                name="seekingEmployment"
                label="Currently seeking employment?"
                component={Switch}
                options={options.switchOptions}
              />
            }
            <Field
              name="earliestAvailability"
              label="Availability Date"
              placeholder={env.DATE_FORMAT}
              component={Datepicker}
            />
          </div>
          <div className="form-container-section EmploymentForm__resume">
            <h5 className="subheader">
              Employment documents
              <MoreInfo text={copy.employmentDocuments} />
            </h5>
            {uploadProps.files &&
              <FileUpload {...uploadProps} />
            }
          </div>
          <div className="form-container-section EmploymentForm__history">
            {isOnboarding &&
              <>
                <h5 className="subheader">Employment history</h5>
                <WorkExperienceField />
              </>
            }
            {isSettings &&
              <>
                <div className="EmploymentForm__heading form-container-section-heading">
                  <h5 className="subheader">Employment history</h5>
                  {isEdited('employments')
                    ?
                      <button
                        type="submit"
                        className="btn-secondary"
                        disabled={isSettings ? submitting : (pristine || submitting)}
                      >
                        Save
                      </button>
                    :
                      <span
                        className="edit-btn btn-secondary-outlined"
                        onClick={() => toggleEditedSections('employments')}
                      >
                        Edit
                      </span>
                  }
                </div>
                <div>
                  {isEdited('employments')
                    ? <WorkExperienceField />
                    : <>
                        {initialValues.employments?.map((w, i) => (
                          <WorkExperienceRow
                            key={i}
                            work={w}
                          />
                        ))}
                      </>
                  }
                </div>
              </>
            }
          </div>
          <div className="form-container-section EmploymentForm__skills">
            <h5 className="subheader">Skills</h5>
            {isOnboarding
              ?
                <div className="EmploymentForm__skillsContainer">
                  <Field
                    name="suggestedSkills"
                    component={LabelGroup}
                    options={skillExamples.map(o => ({value: o, label: o}))}
                    info={copy.employmentFormInfo.example}
                    taglabel
                  />
                  <div className="EmploymentForm__skillsSearch">
                    <p className="form-info">{copy.employmentFormInfo.search}</p>
                    <Field
                      name="skills"
                      label="Add more"
                      className="EmploymentForm__skillsSearchInput"
                      placeholder="Start typing..."
                      component={Creatable}
                      getAutocomplete={getSkills}
                      isMulti
                      async
                    />
                  </div>
                </div>
              :
                <div className="EmploymentForm__skillsContainer">
                  {initialValues.skills &&
                    <Field
                      name="skills"
                      component={LabelGroup}
                      options={initialValues.skills}
                      loadOptions={getSkills}
                      taglabel
                    />
                  }
                </div>
            }
          </div>
          <button
            type="submit"
            className="EmploymentForm__save btn-primary"
            disabled={submitting}
          >
            {isSettings ? 'Save' : 'Save & Continue'}
          </button>
        </form>
      </div>
    )
  }
))
