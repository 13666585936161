import React, { useState, useEffect }     from 'react';
import { connect }                        from 'react-redux';
import { Link }                           from 'react-router-dom';
import { ReactSVG }                       from 'react-svg';
import { Waypoint }                       from 'react-waypoint';
import { pluck }                          from 'underscore';
import cx                                 from 'classnames';
import { isAfter, subDays }               from 'date-fns';
import Loader                             from 'theme/Loader';
import { env }                            from 'theme/content';
import { images }                         from 'theme/img/images';
import { formatDate }                     from 'theme/utils/helpers';
import { constants }                      from 'theme/utils/constants';
import { getNotifications,
         getRecentNotifications,
         viewNotification,
         viewAll }                        from 'notifications/actions';
import useInterval                        from 'notifications/hooks';
import { createNotificationTextAndLink }  from 'notifications/createNotificationTextAndLink';

import './Notifications.sass';

const Notification = ({item: {link, type, text, ago}, className, close, children}) => (
  <div className="Notifications__item">
    <Link
      className={cx("Notifications__itemLink", className, {deleted: type.includes('Deleted')})}
      to={link}
      onClick={close}
    >
      <div className={cx("Notifications__itemIcon", type)} />
      <div className="Notifications__itemContent">
        <p>{text}</p>
        <span>{ago}</span>
      </div>
    </Link>
    {children}
  </div>
);

const NotificationsHeader = ({text, borderTop, viewAllNotifications, isViewAllBtnVisible}) => (
  <div className={cx("Notifications__header", {borderTop})}>
    <span>{text}</span>
    {viewAllNotifications && isViewAllBtnVisible &&
      <span
        className="Notifications__viewAll"
        onClick={() => viewAllNotifications()}
      >
        Mark all as read
      </span>
    }
  </div>
)

function Notifications({user, className, getNotifications, getRecentNotifications, notificationsCount, viewNotification, viewAll, loadingNotifications, newNotificationsCount, newNotifications, mobile, mobileClick}) {
  const [visible, toggleVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const newNotificationsDeleted = newNotifications.filter(n => n.type.includes('Deleted'));

  useEffect(() => {
    setLoading(true);
    if (user) {
      getNotifications({page})
      .then(response => {
        setData(response.data);
        setLoading(false);
      });
      getRecentNotifications();
    }
    return () => setLoading(false);
  }, []);

  useEffect(() => {
    if (visible && newNotificationsDeleted.length) {
      newNotificationsDeleted.map(n => {
        viewNotification(n.id)
        .then(() => {
          getNotifications({page: 0, pageSize: data.length})
          .then(response => {
            setData(response.data);
          });
          getRecentNotifications();
        })
      })
    }
  }, [visible]);

  useInterval(() => {
    if (user) {
      getNotifications({page: 0, pageSize: data.length, loader: false})
      .then(response => {
        setData(response.data);
      });
      getRecentNotifications();
    }
  }, constants.NOTIFICATION.INTERVAL);

  const addData = newData => {
    const ids = pluck(data, 'id');
    const uniq = newData.filter(item => ids.indexOf(item.id) == -1);
    setData([].concat(data, uniq));
  };

  const showMore = () => {
    const pagesCount = Math.ceil(notificationsCount / constants.NOTIFICATION.PAGE_SIZE);
    const newPage = page + 1 > pagesCount ? pagesCount : page + 1;

    return getNotifications({page: newPage})
    .then(({data}) => {
      addData(data);
      setLoading(false);
      setPage(newPage);
    });
  };

  const finalNotifications = data.map(n => ({
    ...n,
    ...createNotificationTextAndLink(n, user),
    ago: formatDate(n.createdAt, env.DATE_FORMAT, true)
  }));

  const redirect = notification => {
    if (!notification.viewed) {
      viewNotification(notification.id)
      .then(() => {
        getNotifications({page: 0, pageSize: data.length})
        .then(response => {
          setData(response.data);
        });
        getRecentNotifications();
      })
    }

    toggleVisible(false);
  };

  const viewAllNotifications = () => {
    return viewAll()
    .then(() => {
      getNotifications({page: 0, pageSize: data.length})
      .then(response => {
        setData(response.data);
      });
      getRecentNotifications();
    })
  };

  const open = () => {
    toggleVisible(!visible);
    mobileClick && mobileClick();
  };

  const minus24h = subDays(new Date(), 1);
  const isRecent = notification => isAfter(Date.parse(notification.createdAt), minus24h);
  const recentNotifications = finalNotifications.filter(n => isRecent(n));
  const oldestRecentNotification = recentNotifications[recentNotifications.length - 1];

  return (
    <div className={cx("Notifications vertical-line", className)}>
      <button
        className={cx("Notifications__open", {active: recentNotifications.length > 0, open: visible})}
        onClick={open}
      >
        <ReactSVG
          src={images.inbox}
          aria-label="Notifications"
        />
        {newNotificationsCount > 0 &&
          <div className={cx("Notifications__openCount", {long: newNotificationsCount.toString().length > 2})}>
            {newNotificationsCount}
          </div>
        }
      </button>
      {visible &&
        <div
          className="Notifications__overlay"
          onClick={() => toggleVisible(false)}
        />
      }
      <div className={cx("Notifications__dropdown", {visible, mobile})}>
        {mobile &&
          <div className="Notifications__mobileHeader">
            <h4>Notification center</h4>
            <button
              className="btn-close"
              onClick={() => toggleVisible(false)}
            >
              <img src={images.closeBlack} alt="close" />
            </button>
          </div>
        }
        <div className={cx("Notifications__list", {mobile})}>
          {recentNotifications.length > 0 &&
            <NotificationsHeader
              text="Recent notifications"
              viewAllNotifications={viewAllNotifications}
              isViewAllBtnVisible={newNotificationsCount > 0}
            />
          }
          {finalNotifications.every(n => !isRecent(n)) &&
            <NotificationsHeader
              text="Earlier"
              viewAllNotifications={viewAllNotifications}
              isViewAllBtnVisible={newNotificationsCount > 0}
            />
          }
          <div className="Notifications__listBody">
            {finalNotifications.map(n => {
              return (
                <Notification
                  key={n.id}
                  item={n}
                  close={() => redirect(n)}
                  className={cx({"Notifications__item--new": !n.viewed})}
                >
                  {finalNotifications.length > recentNotifications.length && oldestRecentNotification?.id === n.id &&
                    <NotificationsHeader
                      text="Earlier"
                      borderTop
                    />
                  }
                </Notification>
              )
            })}
          </div>
          {(finalNotifications.length >= notificationsCount) && !loadingNotifications && !loading &&
            <div className="Notifications__more">That's all!</div>
          }
          {finalNotifications.length < notificationsCount && !loadingNotifications && !loading && visible &&
            <Waypoint onEnter={showMore}>
              <span className="Notifications__waypointFallback">...</span>
            </Waypoint>
          }
          {finalNotifications.length < notificationsCount && loadingNotifications &&
            <div className="Notifications__more">
              <Loader loading={loadingNotifications || loading} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({auth, data: {notifications, recent_notifications}, loading}) => ({
  user: auth.user,
  notifications: notifications?.data || [],
  notificationsCount: notifications?.count || 0,
  newNotifications: recent_notifications?.data || [],
  newNotificationsCount: recent_notifications?.count || 0,
  loadingNotifications: loading.notifications
});

const mapDispatchToProps = dispatch => ({
  getNotifications: (args = {}) => new Promise((resolve, reject) => dispatch(getNotifications({...args, resolve, reject}))),
  getRecentNotifications: (args = {}) => new Promise((resolve, reject) => dispatch(getRecentNotifications({...args, resolve, reject}))),
  viewNotification: id => dispatch(viewNotification(id)),
  viewAll: () => dispatch(viewAll())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
