import React, { useEffect } from 'react';
import cx                   from 'classnames'
import RModal               from 'react-modal';
import { images }           from 'theme/img/images';

import './Modal.sass';

export default function Modal({close, isOpen, children, className, isGallery, closeBtn, timeout}) {
  useEffect(() => {
    setTimeout(() => timeout?.action(false), 3000);
  }, [timeout?.dependency]);

  return (
    <RModal
      className={cx("Modal", className, {isGallery})}
      overlayClassName="Modal__overlay"
      bodyOpenClassName="modal-open"
      isOpen={isOpen}
      onRequestClose={close}
      closeTimeoutMS={500}
      appElement={document.body}
    >
      {closeBtn &&
        <button
          className="btn-close"
          onClick={close}
        >
          <img src={images.closeBlack} alt="close" />
        </button>
      }
      {children}
    </RModal>
  )
}
