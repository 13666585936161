import api       from 'api';


const setOrgParams = (query, page, pageSize) => ({
  filter: `name:${query}` || 'name',
  page,
  pageSize,
  sort: 'name:a'
})

function fetchEducators({query = '', page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/educators',
    params: setOrgParams(query, page, pageSize)
  });
}

function fetchEmployers({query = '', page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/employers',
    params: setOrgParams(query, page, pageSize)
  });
}

function fetchPartners({query = '', page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/partners',
    params: setOrgParams(query, page, pageSize)
  });
}

function fetchOrganization({id, organizationType}) {
  return api({
    method: 'get',
    url: `/${organizationType}s/${id}`
  })
  .catch(() => Promise.resolve({}))
}

function fetchFields({organizationType}) {
  return api({
    method: 'get',
    url: `/${organizationType}s/fields`
  })
}

export const updateFields = ({values, organizationType}) => dispatch => {
  return api({
    method: 'put',
    url: `/${organizationType}s/fields`,
    data: values
  })
  .then(() => dispatch(getFields({organizationType})));
}

export const indexData = orgType => () => {
  const type = orgType === 'support-services' ? 'partners' : orgType;

  return api({
    method: 'get',
    url: `/elasticsearch/index/${type}`
  })
}

export const getFields = ({organizationType, resolve, reject, loader}) => {
  return {type: 'FIELDS/FETCH', req: fetchFields, organizationType, resolve, reject, loader}
}

export const getEducators = ({query, resolve, reject, page, pageSize, loader}) => {
  return {type: 'ADMIN_EDUCATORS/FETCH', query, req: fetchEducators, resolve, reject, page, pageSize, loader}
}

export const getEmployers = ({query, resolve, reject, page, pageSize, loader}) => {
  return {type: 'ADMIN_EMPLOYERS/FETCH', query, req: fetchEmployers, resolve, reject, page, pageSize, loader}
}

export const getPartners = ({query, resolve, reject, page, pageSize, loader}) => {
  return {type: 'ADMIN_PARTNERS/FETCH', query, req: fetchPartners, resolve, reject, page, pageSize, loader}
}

export const getOrganization = (id, organizationType, opts = {}) => {
  return {type: `ORGANIZATION:${organizationType}:${id}/FETCH`, req: fetchOrganization, id, organizationType, ...opts}
}

//---------------------UPDATING---------------------------
export function updateOrganization({organizationType, isOrgAdminPage, id, values, isOnboarding = false}) {
  return (dispatch) => {
    const type = isOrgAdminPage
      ? values.class.toLowerCase()
      : (organizationType || (values.class.charAt(0).toLowerCase() + values.class.slice(1)));

    const dto = type == 'partner'
      ? values
      : {
        ...values,
        logo: values.logo || null,
        quickGlance: {
          ...values.quickGlance,
          graduationPercent: (values && values.quickGlance && values.quickGlance.graduationPercent) / 100,
          repaymentPercent: (values && values.quickGlance && values.quickGlance.repaymentPercent) / 100,
          retentionPercent: (values && values.quickGlance && values.quickGlance.retentionPercent) / 100
        },
        ...isOnboarding && {onboarded: true}
      };

    return api({
      method: type == 'partner' ? 'put' : 'patch',
      url: `/${type}s/${id}`,
      data: dto
    })
    .then(() => {
      dispatch(getOrganization(id, organizationType || type));
    })
  }
}

//---------------------DELETING---------------------------
export const deleteOrganization = ({organization, page, query}) => dispatch => {
  const orgClass = organization.class || organization.payload.class;
  const orgId = organization.id || organization.payload.id;

  return api({
    method: 'delete',
    url: `/${orgClass.toLowerCase()}s/${orgId}`
  })
  .then(() => {
    switch(orgClass) {
      case 'Employer':
        return dispatch(getEmployers({page, query}));
      case 'Educator':
        return dispatch(getEducators({page, query}));
      default:
        return dispatch(getPartners({page, query}));
    }
  })
}
