import React, { useRef, useState } from 'react';
import { reduxForm, Field }        from 'redux-form';
import { TextField,
         UploadBase64,
         Datepicker,
         CheckboxGroup,
         Radio,
         Select }                  from 'theme/Form';
import { copy }                    from 'theme/content';
import { required, phoneNumber }   from 'theme/utils/validate';

import './ExternalApplicationForm.sass';


const COMPONENT_MAP = {
  Text: TextField,
  Email: TextField,
  Date: Datepicker,
  Checkbox: Radio,
  Phone: TextField,
  Select: Select,
  File: UploadBase64,
  Textarea: TextField,
  Number: TextField
};

const inputType = question => {
  switch(question.format) {
    case 'Textarea':
      return 'textarea';
    case 'Text':
      return 'input';
    case 'Email':
      return 'input';
    case 'Phone':
      return 'input';
    case 'Number':
      return 'input';
    default:
      return null;
  }
};

const parseFieldName = name => isNaN(parseFloat(name)) ? name : `field-${name}`;

export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, questions, invalid, close}) => {
    const [scrolledDown, setScrollDown] = useState(false);
    const innerRef = useRef();

    const onScroll = () => {
      if (innerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setScrollDown(true);
        } else setScrollDown(false)
      }
    };

    return (
      <div className="ExternalApplicationForm">
        <h3>Application details required<br />by this employer</h3>
        <form
          ref={innerRef}
          onSubmit={handleSubmit}
          onScroll={() => onScroll()}
        >
          {questions.map(q => {
            const r = q.isRequired ? required : null;
            return (
              <div key={q.id} className="ExternalApplicationForm__field">
                {q.id == 'firstName' &&
                  <h5 className="subheader">Contact details</h5>
                }
                <Field
                  name={parseFieldName(q.id)}
                  label={q.label === 'Resume' ? 'Tailored Resume' : q.label}
                  required={q.isRequired}
                  validate={q.format === 'Phone' ? [r, phoneNumber] : q.isRequired ? required : null}
                  type={q.format == 'Number' ? 'number' : null}
                  component={q.format == 'Checkbox' && q.options ? CheckboxGroup : COMPONENT_MAP[q.format]}
                  mode={q.format === 'Checkbox' && "checkbox"}
                  options={q.options?.map(o => ({label: o, value: o}))}
                  componentType={inputType(q)}
                  maxlength={q.maxLength?.toString()}
                  columnCount={q.format == 'Checkbox' && q.options ? 3 : null}
                  moreInfo={q.format === 'File'}
                  infoText={copy.applicationCoverLetter}
                />
              </div>
            )
          })}
          <div className="ExternalApplicationForm__actions">
            <button
              type="button"
              className="btn-primary-outlined"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary"
              disabled={pristine || invalid || submitting}
            >
              Apply
            </button>
          </div>
        </form>
        {!scrolledDown &&
          <div className="overlay" />
        }
      </div>
    )
  }
)
