import React      from 'react';
import { Link }   from 'react-router-dom';
import cx         from 'classnames';
import { images } from 'theme/img/images';

import './Footer.sass';


const LINKS = [
  {label: 'Jobs', to: '/jobs'},
  {label: 'Companies', to: '/companies'},
  {label: 'Education', to: '/educators'},
  {label: 'Support', to: '/support-services'},
  {label: 'For employers', to: '/employer'}
]

const Social = ({desktop}) => (
  <div className={cx("Footer__social", {desktop, mobile: !desktop})}>
    <a href="https://www.facebook.com/vetitforward/" target="_blank">
      <span className="social-icon fa fa-facebook" alt="facebook" />
    </a>
    <a href="https://www.linkedin.com/company/vetit/" target="_blank">
      <span className="social-icon fa fa-linkedin" alt="linkedin" />
    </a>
    <a href="https://twitter.com/@vetitforward/" target="_blank">
      <span className="social-icon fa fa-twitter" alt="twitter" />
    </a>
  </div>
)

const Links = ({desktop}) => (
  <ul className={cx("Footer__links", {desktop})}>
    <li><Link to="/about">About</Link></li>
    <li><Link to="/terms-of-use">Terms of Use</Link></li>
    <li><Link to="/privacy-and-cookie-policy">Privacy</Link></li>
    {/* <li><Link to="/security">Security</Link></li> */}
  </ul>
)

export default function Footer () {
  return (
    <div className="Footer">
      <div className="container">
        <div className="Footer__wrapper">
          <div className="Footer__nav">
            <ul>
              {LINKS.map((l, i) => (
                <li key={i} className="nav-item">
                  <Link className="nav-link" to={l.to}>
                    {l.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="Footer__content">
            <div className="Footer__copyright">
              <div className="Footer__copyrightText">Copyright Vet it, LLC 2021. <br/>"Vet it", the Vet it logo, and "Vet it Forward"<br/>are proprietary trademarks of Vet it, LLC.</div>
              <div>
                <img
                  src={images.logoWhite}
                  className="Footer__logo"
                  alt="vet it logo"
                />
                <Links desktop />
              </div>
            </div>
            <Social desktop />
          </div>
        </div>
        <div className="Footer__mobile">
          <Links />
          <Social />
        </div>
      </div>
    </div>
  )
}
