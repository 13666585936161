import { mapObject }           from 'underscore';
import { format as fnsFormat,
         parseISO,
         formatDistanceToNow } from 'date-fns';
import { env }                 from 'theme/content';

export const trimValues = values => mapObject(values, (val) => {
  if (val instanceof Array)
    return val.map(v => v.trim());
  else
    return val.trim();
});

export const valueToPercent = value => value && (Math.round(value * 100) / 100) * 100;
export const toCommas = value => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const flattenObj = (obj, parent, res = {}) => {
  for(let key in obj){
      let propName = parent ? parent + '.' + key : key;
      if(typeof obj[key] == 'object'){
          flattenObj(obj[key], propName, res);
      } else {
          res[propName] = obj[key];
      }
  }
  return res;
}

export const upperCaseFirstLetter = item => item && item.charAt(0).toUpperCase() + item.slice(1);
export const lowerCaseFirstLetter = item => item && item.charAt(0).toLowerCase() + item.slice(1);

export const formatPrice = price => (price / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
export const formatPlan = plan => plan.replace(/(^|_)./g,  s => ' ' + s.slice(-1).toUpperCase());

export const formatDate = (date, format = env.DATE_FORMAT, toNow) => {
  const d = toNow
    ? `${formatDistanceToNow(parseISO(date))} ago`
    : fnsFormat(date instanceof Date ? date : parseISO(date), format);

  return d;
}

export const checkIfTimestamp = string => new Date(string).getTime() > 0;

export const createSlug = name => name && name.toLowerCase().replace(/[^a-z0-9]/gmi, '-');
export const convertToMB = size => (size / Math.pow(1024, 2)).toFixed(2);

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const mapReviewAction = ({method, url, action, params}) => {
  const isRevoke = action === 'revokeVote';

  return {
    method: action === 'revokeVote' ? 'delete' : method,
    url: action ? `${url}/${action}` : url,
    ...(!isRevoke && {params})
  }
}