import React                         from 'react';
import ReactMde,
       { getDefaultToolbarCommands } from 'react-mde';
import cx                            from 'classnames';
import * as Showdown                 from 'showdown';

import 'react-mde/lib/styles/css/react-mde-all.css';
import './MarkdownEditor.sass';


const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  openLinksInNewWindow: true
});

const toolbarCommands = getDefaultToolbarCommands()
  .map((commandGroup, i) => {
    if (i === 1) return commandGroup.slice(0, -1);
    else return commandGroup;
  });

export default function MarkdownEditor({value, onChange, readOnly, className, placeholder}) {
  const [selectedTab, setSelectedTab] = React.useState('write');

  return (
    <div className={cx('MarkdownEditor', {readOnly})}>
      <ReactMde
        value={value}
        className={className}
        onChange={onChange}
        textAreaProps={{placeholder: placeholder || 'Type here...'}}
        readOnly={readOnly}
        selectedTab={readOnly ? 'preview' : selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands={toolbarCommands}
        generateMarkdownPreview={markdown =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
    </div>
  );
}
