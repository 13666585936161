import React, { useState, useEffect } from 'react';
import cx                             from 'classnames';
import { images }                     from 'theme/img/images';

import './Carousel.sass';

export default function Carousel({items, slideClass, screenWidth, className}) {
  const visibleSlidesCount = () => {
    if (screenWidth < 600)
      return 2;
    else if (screenWidth >= 600 && screenWidth < 1200)
      return 3;
    else
      return 4;
  };

  const count = visibleSlidesCount();

  const [firstVisibleItemIndex, setFirstVisibleItemIndex] = useState(0);
  const [baseTranslation, setBaseTranslation] = useState(0);

  const itemWidth = 100 / count;

  useEffect(() => {
    setBaseTranslation(firstVisibleItemIndex * 100);
  }, [firstVisibleItemIndex]);

  useEffect(() => {
    setFirstVisibleItemIndex(0);
  }, [screenWidth]);

  const prev = () => setFirstVisibleItemIndex(firstVisibleItemIndex - 1);
  const next = () => setFirstVisibleItemIndex(firstVisibleItemIndex + 1);

  return (
    <div className={cx("Carousel", className)}>
      {firstVisibleItemIndex > 0 &&
        <div className="Carousel__btn Carousel__left" onClick={prev}>
          <img src={images.chevronLeft} alt="left" />
        </div>
      }
      {items.map((item, i) => (
        <div
          key={i}
          className={cx("Carousel__slide", slideClass)}
          style={{
            flex: `0 0 ${itemWidth}%`,
            transform: `translateX(-${baseTranslation}%)`
          }}
        >
          <div className="Carousel__slideImg">
            <img src={item.image} alt="slide" />
          </div>
          <span>{item.title}</span>
        </div>
      ))}
      {firstVisibleItemIndex < (items.length - count) &&
        <div className="Carousel__btn Carousel__right" onClick={next}>
          <img src={images.chevronRight} alt="right" />
        </div>
      }
    </div>
  )
}
