import React                 from 'react';
import { connect }           from 'react-redux';
import { reduxForm,
         Field,
         formValueSelector } from 'redux-form';
import { TextField,
         Select,
         RadioLabels,
         Captcha,
         Rating }            from 'theme/Form';
import { required }          from 'theme/utils/validate';
import { options,
         agreements }        from 'theme/content';
import { getMosOptions }     from 'theme/content/mosOptions';

import './Form.sass';


export default connect(
  (state, props) => ({serviceBranchValue: formValueSelector(props.form)(state, 'reviewerDetails.serviceBranch')})
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, submitFailed, invalid, profile, partnerOptions, editDisabled, serviceBranchValue}) => {
    const isSpouse = serviceBranchValue === 'Spouse';
    return (
      <div className="PartnerReview__form">
        <h4>Support Service Review</h4>
        <form onSubmit={handleSubmit}>
          <h6>Details</h6>
          <Field
            name="partner"
            label="Organization Name"
            required
            placeholder="e.g. Student Veterans of America"
            component={Select}
            valueKey='id'
            labelKey='name'
            validate={required}
            disabled={editDisabled}
            options={partnerOptions}
          />
          <Field
            name="programName"
            label="Program Name"
            required
            placeholder="e.g. Affiliated Chapter"
            component={TextField}
            validate={required}
          />

          <h6>Experience Overview</h6>
          <Field
            name="review.reviewTitle"
            label="Review Title"
            placeholder="e.g. Great experience!"
            className="block"
            required
            component={TextField}
            validate={required}
          />
          <Field
            name="review.reviewComments"
            label="Review Comments"
            className="block"
            placeholder="Please be as generous as you can with details!"
            required
            componentType="textarea"
            component={TextField}
            validate={required}
          />
          <Field
            name="rating"
            label="Rate your overall experience"
            required
            validate={required}
            component={Rating}
            disabled={editDisabled}
          />
          <h6>Private feedback</h6>
          <Field
            name="feedback"
            label="Is there anything you'd like to communicate directly to the program to thank them or help them improve the quality of their service?"
            className="block"
            placeholder="Share your thoughts here..."
            componentType="textarea"
            component={TextField}
          />
          <h6>Recommendation</h6>
          <Field
            name="recommendation"
            label="Would you recommend this support service to other veterans and military spouses?"
            className="block"
            required
            validate={required}
            component={RadioLabels}
            options={options.complete}
            disabled={editDisabled}
          />
          <h6>Reviewer Information</h6>
          <Field
            name="reviewerDetails.serviceBranch"
            label="Service Branch"
            required
            className="block"
            component={RadioLabels}
            options={options.branchRange}
            validate={required}
            disabled={editDisabled}
          />
          {!isSpouse &&
            <Field
              label="Military Occupation"
              name="reviewerDetails.mos"
              placeholder="Select"
              component={Select}
              options={getMosOptions(serviceBranchValue)}
              disabled={editDisabled}
              {...(!isSpouse && {validate: required, required: true})}
            />
          }
          {!isSpouse &&
            <Field
              name="reviewerDetails.etsPaygrade"
              label="Highest Paygrade Attained"
              placeholder="Select"
              component={Select}
              options={options.paygradeOptions}
              disabled={editDisabled}
              {...(!isSpouse && {validate: required, required: true})}
            />
          }
          <Field
            name="reviewerDetails.email"
            label="Your E-mail"
            required
            placeholder="e.g. sgt.hooah@gmail.com or iam.t.standard4.mil@mail.mil"
            component={TextField}
            validate={required}
            disabled={editDisabled}
          />
          <h5 className="PartnerReview__agreement">{agreements.certifiedVet}</h5>
          {Object.keys(profile).length == 0 &&
            <Field
              name="captcha"
              component={Captcha}
              validate={required}
            />
          }
          {submitFailed && invalid &&
            <div className="PartnerReview__error">Please check if you filled all required fields.</div>
          }
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting}
          >
            VET IT FORWARD!
          </button>
        </form>
      </div>
    )
  }
))
