export default {
  DATE_FORMAT: 'MM/dd/yyyy',
  QUERY: {
    commentReported: '?commentReported=1',
    postReported: '?postReported=1',
    reviewReported: '?reviewReported=1'
  },
  HOME_ROUTE: {
    common: '/dashboard/feed',
    commonOnboarded: '/dashboard/feed?onboarded',
    organizationAdmin: '/org-admin/feed'
  }
}