import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import { compose,
         withState,
         withProps,
         withStateHandlers,
         withHandlers,
         lifecycle }        from 'recompose';
import { pick }             from 'underscore';
import withDebounce,
       { withScroll }       from 'theme/utils/recompose';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Loader               from 'theme/Loader';
import MessageSection       from 'theme/MessageSection';
import Modal                from 'theme/Modal';
import { getProfile }       from 'auth/actions';
import EducatorForm         from 'educators/EducatorForm';

import Form                 from './Form';
import { getEducators,
         getAutocomplete,
         addInterviewReview,
         getEducator,
         addEducator,
         getReview,
         updateReview }     from '../actions';

import './InterviewReview.sass';


const formName = "EducatorInterviewReviewForm";
const EducatorFormName = "EducatorForm";

const InterviewReview = compose(
  withScroll(),
  withState('submitted', 'setSubmitted', false),
  withStateHandlers({
    modalOpened: false,
    orgName: ''
  },
  {
    openModal: () => (orgName) => ({
      modalOpened: true,
      orgName
    }),
    closeModal: () => () => ({modalOpened: false})
  }),
  withHandlers({
    nextInterviewReview: (props) => () => props.setSubmitted(false),
    submit: ({submit, setSubmitted}) => values => {
      return submit(values)
      .then(() => {
        setSubmitted(values.educator.id || values.educator);
      })
    },
    addEducator: ({addEducator, closeModal}) => values => {
      return addEducator(values)
      .then(closeModal);
    },
    getAutocomplete: ({getAutocomplete}) => value => {
      if (value === '') return;
      return getAutocomplete({prefix: value});
    }
  }),
  withDebounce({
    functionName: 'search',
    debouncedName: 'searchEducators',
    withLifecycle: true
  }),
  lifecycle({
    componentDidMount () {
      const { match, getEducator, getReview, getProfile, user } = this.props;

      if(match.params.reviewId)
        getReview();
      if(match.params.educatorId)
        getEducator();
      if (user && Object.keys(user).length)
        getProfile(user);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.submitted != this.props.submitted)
        window.scrollTo(0, 0);
    }
  }),
  withProps(({match: {params}, educator}) => ({
    educatorId: params.educatorId,
    educatorName: educator.name,
    educatorSlug: educator.slug
  }))
)(({review,
    submitted,
    nextInterviewReview,
    submit,
    searchEducators,
    educators,
    loadingEducators,
    educatorId,
    educatorName,
    educatorSlug,
    profile,
    loadingEducator,
    modalOpened,
    openModal,
    closeModal,
    orgName,
    addEducator,
    match,
    getAutocomplete
  }) => {
		const initialValues = !match.params.reviewId
    ? { educator: {id: educatorId, name: educatorName},
      reviewerDetails: pick(profile, 'mos', 'etsPaygrade', 'serviceBranch', 'email')}
    :  {...review, educator: {id: educatorId, name: educatorName}};

    const message = {
      title: `Thanks for paying it forward! ${match.params.reviewId ? 'Your review has been updated.' : 'Thanks for reviewing this education application and interview process with our community!'}`
    };

    const redirectLink = match.params.reviewId
      ? history.state.state.prevPath
      : `/educators/${submitted}/${educatorSlug}/overview`;

    return (
      <div className="InterviewReview page">
        <Helmet>
          <title>Vetit — {educatorName || ''} School Applicant Review</title>
        </Helmet>
        <Header />
        {submitted
        ?
          <div className="container">
            <MessageSection {...message}>
              <Link
                className="btn-primary"
                onClick={nextInterviewReview}
                to={redirectLink}
                >
                Return
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <Loader loading={loadingEducator} />
            {!loadingEducator &&
              <Form
                onSubmit={submit}
                searchEducators={searchEducators}
                educators={educators}
                loadingEducators={loadingEducators}
                form={formName}
                initialValues={initialValues}
                profile={profile}
                openModal={openModal}
                editDisabled={Boolean(match.params.reviewId)}
                getAutocomplete={getAutocomplete}
              />
            }
            <div className="InterviewReview__sidebar">
              <div className="flag first"></div>
              <p className="secondary-text">Informational Interview</p>
              <p className="content-text">This review form allows you to share details about your education and training application experiences. </p>
              <div className="flag second"></div>
              <p className="secondary-text">Be Constructive!</p>
              <p className="content-text">We value candid insights much more than rose-colored ones, but be professional! Profanity will be removed. </p>
              <p className="content-text">Respond as though a close military friend is reaching out to learn more about this specific experience.</p>
              <div className="flag third"></div>
              <p className="secondary-text">Answer Military Specific Questions</p>
              <p className="content-text">-Did a military connected partner or service aid you in the application process? What was that like?</p> 
              <p className="content-text">-Did you consider the school's type, accreditation, and selectivity while applying?</p>
              <p className="content-text">-How important were the school's retention, graduation, and repayment outcomes to you?</p>
              <p className="content-text">-Was the yellow ribbon program important to you, and did you consider veteran-specific support standards such as the 8 Key Commitment and Principles of Excellence?</p>
              <p className="content-text">-Did you conduct focused informational interviews with current student vets or alumni? What did you learn from them?</p>
              <p className="secondary-text">Answer General Questions</p>
              <p className="content-text">-What were the main reasons you applied to this program at this school?</p>
              <p className="content-text">-What specific career goals and outcomes were you expecting to achieve?</p>
              <p className="content-text">-Did you consider and/or apply to similar programs at other schools? Which ones?</p>
              <p className="content-text">-Did you do anything special to prep your resume?</p>
              <p className="content-text">-If your program was more selective, how did you prepare for your interviews and what were they like?</p>
              <p className="content-text">-Were there any special requirements, such as previous course work or standardized tests?</p>
              <p className="content-text">-If you were admitted into more than one school, why did you ultimately decide the way you did?</p>
              <p className="content-text">-Is there any other insight you'd share with veterans who are interested in applying to this same program?</p>
              <div className="flag fourth"></div>
              <p className="secondary-text">Your Review Will Remain Discreet!</p>
              <p className="content-text">Because our community can learn the most from open, honest insights like yours, we want you to feel safe sharing them.</p>
            </div>
          </div>
        }
        <Footer />

        <Modal
          isOpen={modalOpened}
          close={closeModal}
          className="InterviewReview__modal"
        >
          <h4 className="title">New educator</h4>
          {orgName &&
            <EducatorForm initialValues={{name: orgName}} onSubmit={addEducator} form={EducatorFormName} />
          }
        </Modal>
      </div>
    )
  }
)

const mapStateToProps = ({auth, data, loading}, {match}) => {
  const { educators, profile } = data;
  const id = match.params.educatorId;
  const reviewId = match.params.reviewId;

  return {
    user: auth.user,
    educators: educators?.data || [],
    educator: data[`educator:${id}`] || {},
    loadingEducators: loading.educators,
    profile: profile || {},
    loadingEducator: loading[`educator:${id}`],
    loadingProfile: loading.profile || false,
    review: (data && data[`review:${reviewId}`]) || {metadata: {}, reviewerDetails: {}}
  }
}

const mapDispatchToProps = (dispatch, {match}) => {
  const id = match.params.educatorId;
  const reviewType = 'interviews';
  const reviewId = match.params.reviewId;

  return {
    getEducator: () => new Promise((resolve, reject) => dispatch(getEducator(id, {resolve, reject}))),
    getAutocomplete: (args) => dispatch(getAutocomplete(args)),
    search: (query) => new Promise((resolve, reject) => dispatch(getEducators({query, resolve, reject}))),
    submit: (values) => reviewId ?
      dispatch(updateReview(id, reviewType, reviewId, values))
      : new Promise((resolve, reject) => dispatch(addInterviewReview({values, resolve, reject, form: formName}))),
    getProfile: (user) => dispatch(getProfile(user.id)),
    addEducator: (values) => new Promise((resolve, reject) => dispatch(addEducator({values, form: EducatorFormName, resolve, reject}))),
    getReview: () => new Promise((resolve, reject) => dispatch(getReview({educatorId: id, reviewType, reviewId, resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewReview))
