import React                   from 'react';
import { compose,
         lifecycle,
         setDisplayName,
         withState,
         withProps,
         withHandlers }        from 'recompose';
import FunctionBtn             from 'theme/FunctionBtn';
import { images }              from 'theme/img/images';

import List                    from './List';
import './Candidates.sass';


const Candidates = compose(
  setDisplayName('Candidates'),
  withState('expandedLists', 'setExpandedLists', [0, 1, 2]),
  withProps(({applications, matched, matchedCount}) => {
    const shortlisted = applications?.filter(a => a.jobApplication.shortlisted);
    const applied = applications?.filter(a => a.jobApplication.status === 'Submitted' && !a.jobApplication.shortlisted);

    return {
      shortlisted: {candidates: shortlisted, name: 'Shortlisted', label: 'SHORTLISTED', count: shortlisted?.length},
      applied: {candidates: applied, name: 'Applied', label: 'APPLIED', count: applied?.length},
      matched: {candidates: matched, name: 'Matched', label: 'MATCHED', count: matchedCount}
    }
  }),
  withProps(({shortlisted, applied, matched}) => ({
    all: [shortlisted, applied, matched]
  })),
  lifecycle({
    componentDidMount() {
      const textElements = document.getElementsByClassName('CircularProgressbar-text');
      Array.from(textElements).forEach(el => {
        el.setAttribute('text-anchor', 'middle');
        el.setAttribute('dominant-baseline', 'middle');
        el.style.fontWeight = '600';
      })
    }
  }),
  withHandlers({
    toggleList: ({expandedLists, setExpandedLists}) => index => {
      const isListExpanded = expandedLists.includes(index);
      const newExpanded = isListExpanded
        ? expandedLists.filter(i => i !== index)
        : expandedLists.concat(index);

      setExpandedLists(newExpanded);
    }
  })
)(({all, toggleList, expandedLists, showMore, shortlist, jobOpeningClosed, downloadResume, openModal, viewApplication, loading}) => {

  return (
    <div className="Candidates" onClick={(e) => e.stopPropagation()}>
      {all.map((list, i) => {
        const isMatched = i == 2;
        return (
          <List
            key={i}
            index={i}
            data={list}
            openModal={openModal}
            toggleList={toggleList}
            isListExpanded={expandedLists.includes(i)}
            shortlist={shortlist}
            loading={isMatched ? loading.matched_profiles : loading.job_applications}
            downloadResume={downloadResume}
            viewApplication={viewApplication}
            jobOpeningClosed={jobOpeningClosed}
            isMatched={isMatched}
          >
            {(list.name === 'Matched' && list.candidates.length < list.count) &&
              <div className="Candidates__loadMore">
                <FunctionBtn
                  img={{src: images.plus, alt: 'load'}}
                  text="Load more"
                  action={() => showMore()}
                />
              </div>
            }
          </List>
        )
      })}
    </div>
  )
})

export default Candidates;