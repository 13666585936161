import React          from 'react';
import { Link }       from 'react-router-dom';
import Loader         from 'theme/Loader';
import Rating         from 'theme/Rating';
import ManagerLogo    from 'theme/ManagerLogo';
import { images }     from 'theme/img/images';
import { createSlug } from 'theme/utils/helpers';

import './OrganizationBox.sass';


export default function OrganizationBox({organization, loading}) {
  return (
    <div className="OrganizationBox card">
      <Loader loading={loading} />
      <ManagerLogo manager={organization} size="xl" />
      <h4>{organization.name}</h4>
      <a
        className="OrganizationBox__link"
        href={organization.url}
        target="_blank"
      >
        {organization.url}
        <img src={images.externalLink} alt="Go to website" />
      </a>
      <div className="OrganizationBox__rating">
        <div>
          <Rating
            rating={organization.averageOverallRating}
            size="md"
          />
          <span className="rating-value">{organization.averageOverallRating && Math.ceil((organization.averageOverallRating) * 10) / 10}</span>
        </div>
        <span>Overall Veteran Rating</span>
      </div>
      <Link
        to={`/companies/${organization.internalId}/${createSlug(organization.name.toLowerCase())}/overview`}
        className="btn-primary-outlined"
      >
        Company Profile
      </Link>
    </div>
  )
}
