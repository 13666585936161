import React            from 'react';
import { formatDate }   from 'theme/utils/helpers';

import './CertificationRow.sass';

export default function CertificationRow({certification: {certificateName, issuingOrganization, issueDate, expirationDate}}) {
  return (
    <div className="CertificationRow job-content-row">
      <div className="name">
        <span className="label">{certificateName}</span>
        <span className="text">{issuingOrganization}</span>
      </div>
      <div className="date">
        <span className="label">Issued</span>
        <span className="text">{issueDate && formatDate(issueDate, 'MM/yyyy')}</span>
      </div>
      <div className="date">
        <span className="label">Expires</span>
        <span className="text">{expirationDate && formatDate(expirationDate, 'MM/yyyy')}</span>
      </div>
    </div>
  )
}
