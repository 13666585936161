import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Helmet }           from 'react-helmet';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import { login, register }  from 'store/actions';

import * as sso             from '../sso'

import './Callback.sass';

const provider = {
  facebook: 'Facebook',
  linkedin: 'LinkedIn'
}

const paramsNames = ['state', 'code', 'access_token'];

class CallbackComponent extends Component {
  constructor() {
    super();
    this.formatUrl = this.formatUrl.bind(this);
    this.getUrlParameter = this.getUrlParameter.bind(this);
  }

  componentDidMount() {
    const {match: {params: urlParams}, history, login, register} = this.props;

    if(!urlParams.type || !urlParams.social) {
      history.push('/auth/signin');
      return;
    }
    const params = this.formatUrl();

    //state returned after login dont match sent state
    if(!params || params.state !== sso[urlParams.social].state) {
      history.push('/auth/signin');
      return;
    }

    if(urlParams.type == 'signin')
      login({
        code: params.access_token || params.code,
        redirectUri: sso[urlParams.social].base.login,
        provider: provider[urlParams.social]
      })
      .catch((err) => {
        history.push(`/auth/signin?error=true&status=${err.status}`);
      })
    else
      register({
        code: params.access_token || params.code,
        redirectUri: sso[urlParams.social].base.register,
        provider: provider[urlParams.social]
      })
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?#&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    var resultsHash = regex.exec(location.hash);
    return results === null
      ?
        resultsHash === null ? null : decodeURIComponent(resultsHash[1].replace(/\+/g, ' '))
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  formatUrl() {
    if(this.getUrlParameter('error')) return false;
    return paramsNames.reduce((obj, name) => ({
      ...obj,
      [name]: this.getUrlParameter(name)
    }), {});
  }

  render() {
    return (
      <div className="Callback page">
        <Helmet>
          <title>Vetit — Authorizing</title>
        </Helmet>
        <Header />
        <div className="container">
        <h3>Authorizing...</h3>
        </div>
        <Footer />
      </div>
    )
  }
}

const Callback = withRouter(connect(
  null,
  {login, register}
)(CallbackComponent));

export default Callback;
