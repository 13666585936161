import React                          from 'react';
import { connect }                    from 'react-redux';
import { withRouter }                 from 'react-router';
import { Link }                       from 'react-router-dom';
import { ReactSVG }                   from 'react-svg';
import { compose,
         setDisplayName,
         mapProps,
         withStateHandlers,
         withHandlers,
         withProps,
         lifecycle }                  from 'recompose';
import cx                             from 'classnames';
import { parse }                      from 'qs';
import Modal                          from 'theme/Modal';
import Confirm                        from 'theme/Confirm';
import Rating                         from 'theme/Rating';
import CollapseComponent              from 'theme/CollapseComponent';
import { env,
         options,
         REVIEW_TYPES,
         findLabelByValue }           from 'theme/content';
import { formatDate, createSlug }     from 'theme/utils/helpers';
import { constants }                  from 'theme/utils/constants';
import { withModal }                  from 'theme/utils/recompose';
import { images }                     from 'theme/img/images';
import Share                          from 'share/Share';
import PostFooter                     from 'comments/PostFooter';
import CommentsSection                from 'comments/CommentsSection';
import { withComment }                from 'comments/recompose';
import { getPostComments,
         voteComment,
         report,
         addPostComment }             from 'comments/actions';
import { deleteReview }               from 'admin/actions';
import { actionSucceeded }            from 'store/actions';

import './Review.sass';

const MODAL_NAMES = {
  SHARE: 'SHARE',
  DELETE_REVIEW: 'DELETE_REVIEW',
  NUDGE_TO_REGISTER: 'NUDGE_TO_REGISTER',
};

const splitAt = index => x => [x.slice(0, index), x.slice(index)];

const Review = compose(
  setDisplayName('Review'),
  withModal(),
  withComment(),
  withProps(({match, review}) => {
    const beginning = `${location.origin}/${REVIEW_TYPES[review.metadata && review.metadata.class]?.organizationsAlt}/${review.organization?.id}/${createSlug(review.organization?.name)}/${REVIEW_TYPES[review.metadata && review.metadata.class]?.section}`;
    const teaserUrlBeginning = `${location.origin}/teaser/review/${REVIEW_TYPES[review.metadata && review.metadata.class]?.organization}/${review.organization?.id}/${createSlug(review.organization?.name)}/${REVIEW_TYPES[review.metadata && review.metadata.class]?.section}`;

    const getPath = () => {
      switch(review.metadata?.class) {
        case 'EmployerInterviewReview':
          return {
            loggedIn: `${beginning}/interviews/${review.id}`,
            reporting: `${beginning}/interviews/${review.id}${env.QUERY.reviewReported}`,
            reportingComment: `${beginning}/interviews/${review.id}${env.QUERY.commentReported}`,
            teaser: `${teaserUrlBeginning}/interviews/${review.id}`
          }
        case 'EmployerJobReview':
          return {
            loggedIn: `${beginning}/jobs/${review.id}`,
            reporting: `${beginning}/jobs/${review.id}${env.QUERY.reviewReported}`,
            reportingComment: `${beginning}/jobs/${review.id}${env.QUERY.commentReported}`,
            teaser: `${teaserUrlBeginning}/jobs/${review.id}`
          };
        default:
          return {
            loggedIn: `${beginning}/${review.id}`,
            reporting: `${beginning}/${review.id}${env.QUERY.reviewReported}`,
            reportingComment: `${beginning}/${review.id}${env.QUERY.commentReported}`,
            teaser: `${teaserUrlBeginning}/${review.id}`
          };
      }
    };

    return {
      reviewId: match.params.reviewId,
      shareReviewUrl: getPath().loggedIn,
      teaserUrl: getPath().teaser,
      reportingUrl: getPath().reporting,
      reportingCommentUrl: getPath().reportingComment
    }
  }),
  mapProps(props => {
    const {review} = props;
    if (review?.review?.reviewComments?.length > constants.REVIEW.MAX_LEN) {
      const trimmedText = review.review.reviewComments.substring(0, constants.REVIEW.MAX_LEN);
      const shortComment = trimmedText.substring(0, trimmedText.lastIndexOf(' '));
      return Object.assign({}, props, {review: {
        ...review,
        shortComment
      }});
    }
    return props;
  }),
  withProps(({review, user, urlParams}) => ({
    isAdmin: user && (user.isAdmin || user.isOrgAdmin),
    me: user?.id === review?.metadata?.reviewerId,
    isReported: user?.isAdmin && urlParams?.reviewReported,
    reviewAuthorSignature: {
      'Service branch': review?.reviewerDetails?.serviceBranch,
      'Military occupation': review?.reviewerDetails?.mos,
      'EAS paygrade': review?.reviewerDetails?.etsPaygrade,
      'User category': review?.reviewerDetails?.category
    }
  })),
  withProps(({review: {metadata}}) => {
    const defineSubject = () => {
      switch(metadata?.class) {
        case 'EmployerInterviewReview':
          return 'Position';
        case 'EmployerJobReview':
          return 'Position';
        default:
          return 'Program';
      }
    }

    return {
      reviewSubject: defineSubject()
    }
  }),
  withHandlers({
    openModal: ({openModal}) => (e, name) => {
      e.stopPropagation();
      openModal(name);
    },
    closeModal: ({closeModal}) => e => {
      e.stopPropagation();
      closeModal();
    },
    goToForm: ({review: {id, metadata}}) => () => {
      const getUrlData = () => {
        switch(metadata?.class) {
          case 'EmployerInterviewReview':
            return {
              reviewType: 'interview-review',
              organizationType: 'companies'
            };
          case 'EmployerJobReview':
            return {
              reviewType: 'review',
              organizationType: 'companies'
            };
          case 'EducatorInterviewReview':
            return {
              reviewType: 'interview-review',
              organizationType: 'educators'
            };
          case 'EducatorProgramReview':
            return {
              reviewType: 'review',
              organizationType: 'educators'
            };
          default:
            return {
              reviewType: 'review',
              organizationType: 'support-services'
            }
        }
      };

      return `/${getUrlData().organizationType}/${getUrlData().reviewType}/${metadata?.organizationId}/edit/${id}`;
    },
    createCommentReportingUrl: ({reportingCommentUrl}) => params => `${reportingCommentUrl}&${params}`,
    react: ({voteReview}) => id => (e, action) => {
      e.stopPropagation();
      return voteReview(id, action);
    },
    reactToComment: ({review, reactToComment, getPostComments, comments, setComments}) => id => (e, action) => {
      return reactToComment(id, action)
      .then(() => {
        getPostComments({
          postId: review.id,
          page: 0,
          pageSize: comments.length,
          sort: 'createdAt:d',
          itemType: 'review'
        })
        .then(response => setComments(response.data));
      });
    },
    deleteReview: ({deleteReview, closeModal, history, review, displaySuccessMessage}) => () => {
      return deleteReview(REVIEW_TYPES[review.metadata.class], review, {page: 0}, false)
      .then(() => {
        closeModal();
        displaySuccessMessage('You have successfully deleted the review.');
        history.push('/home');
      });
    },
    follow: ({follow}) => e => id => {
      e.stopPropagation();
      return follow(id);
    },
    unfollow: ({unfollow}) => e => id => {
      e.stopPropagation();
      return unfollow(id);
    }
  }),
  withHandlers({
    report: ({report, reportingUrl, review: {id, metadata}}) => e => {
      e.stopPropagation();
      return report({
        itemId: id,
        itemType: metadata?.class,
        organizationId: metadata?.organizationId,
        url: reportingUrl
      });
    },
    // eslint-disable-next-line react/display-name
    blurText: ({user}) => text => {
      const isTextLongerThanBlurLimit = text?.length && text.length > constants.BLUR_LIMIT;
      const sliced = isTextLongerThanBlurLimit ? splitAt(constants.BLUR_LIMIT)(text) : text;

      return user
        ? text
        : (isTextLongerThanBlurLimit
            ? <span>{sliced[0]}<span className="blurred">{sliced[1]}</span></span>
            : text
          );
    },
    handleClick: ({user, openModal, review, redirect}) => (e) => {
      e.stopPropagation();

      if (!user)
        openModal(e, MODAL_NAMES.NUDGE_TO_REGISTER)
      else redirect(review.id)
    }
  }),
  withProps(({review, blurText, shortComment}) => ({
    review: {
      ...review,
      review: {
        ...review.review,
        reviewComments: blurText(review?.review?.reviewComments)
      }
    },
    shortComment: blurText(shortComment),
  })),
  withStateHandlers(
    ({review: shortComment}) => ({
      expanded: !shortComment
    }),
    {
      expand: () => () => ({expanded: true})
    }
  ),
  lifecycle({
    componentDidMount() {
      const { view, review } = this.props;
      view && view(review.id);
    }
  })
)(props => {
    const {user, card, isFeed, isReported, report, createCommentReportingUrl, reviewSubject, react, reviewId, reviewAuthorSignature, comments, commentsCount, follow, unfollow, loadingComments, showMore, expanded, expand, reactToComment, deleteReview, className, me, goToForm, commentsVisible, reply, replied, setReplied, modal, shareReviewUrl, teaserUrl, closeModal, openModal, isMyReviews, handleClick, redirect} = props;
    const {id, review, jobDetails, details, isFollowed, programName, organization, rating, shortComment, interviewDetails, metadata, popularity} = props.review;

    return (
      <div
        className={cx("Review", className, {clickable: !reviewId})}
        onClick={e => handleClick(e)}
      >
        <div className={cx('Review__container', {card})}>
          <div className="Review__heading">
            <div>
              {((isFeed || !user) || isMyReviews) &&
                <div className="Review__employerName topicLabel">{organization?.name}</div>
              }
              <Rating
                rating={rating}
                className="Review__rating"
              />
            </div>

            <div className="feed-item-heading-btns">
              {metadata?.createdAt &&
                <span className="date">{formatDate(metadata.createdAt, ...Array(1), true)}</span>
              }
              {user && !isFeed && !isReported &&
                <button
                  className={cx("btn-secondary-ghost", {active: isFollowed})}
                  onClick={e => isFollowed ? unfollow(e)(id) : follow(e)(id)}
                >
                  {isFollowed ? 'Unfollow' : 'Follow'}
                </button>
              }
              {user && !isReported &&
                <button
                  className="Review__share btn-icon"
                  onClick={e => openModal(e, MODAL_NAMES.SHARE)}
                >
                  <ReactSVG src={images.share} />
                </button>
              }
              {me && !isReported &&
                <Link
                  className="Review__edit btn-icon"
                  to={{pathname: goToForm(), state: {prevPath: location.pathname}}}
                  onClick={e => e.stopPropagation()}
                >
                  <ReactSVG
                    src={images.editAlt}
                    aria-label="Edit review"
                  />
                </Link>
              }
              {isReported &&
                <button
                  className="btn-icon-trash"
                  onClick={e => openModal(e, MODAL_NAMES.DELETE_REVIEW)}
                >
                  <span className="fa fa-trash" />
                </button>
              }
            </div>
          </div>

          <div className="Review__body feed-item-content">
            <div className="feed-item-title-container">
              <h4 className="feed-item-title">{review?.reviewTitle}</h4>
              {metadata?.createdAt &&
                <span className="date">{formatDate(metadata.createdAt, ...Array(1), true)}</span>
              }
            </div>
            <div className="Review__metadata">
              <div className="Review__metadataItem">
                <span>{reviewSubject}</span>
                <span>{jobDetails?.position || details || programName}</span>
              </div>
              {interviewDetails?.interviewDifficulty &&
                <div className="Review__metadataItem">
                  <span>Difficulty</span>
                  <span>{findLabelByValue(interviewDetails.interviewDifficulty, options.interviewDifficulty)}</span>
                </div>
              }
              {interviewDetails?.interviewSource &&
                <div className="Review__metadataItem">
                  <span>Source</span>
                  <span>{findLabelByValue(interviewDetails.interviewSource, options.interviewSources)}</span>
                </div>
              }
              <div className="Review__metadataItem">
                <span>Experience</span>
                <span>{findLabelByValue(rating, options.experienceRatingOptions)}</span>
              </div>
            </div>

            {review &&
              <div className="Review__comment">
                {!user
                  ? <p className="secondary-text">{review.reviewComments}</p>
                  : <CollapseComponent isOpened>
                      {expanded || !shortComment
                      ? <p className="secondary-text">{review.reviewComments}</p>
                      :
                        <p className="secondary-text">
                          {shortComment}
                          <span className="more" onClick={expand}>Read more...</span>
                        </p>
                      }
                    </CollapseComponent>
                }
              </div>
            }
          </div>

          <PostFooter
            votes={metadata?.votes}
            react={react(id)}
            me={me}
            signature={reviewAuthorSignature}
            views={metadata?.views}
            count={popularity}
            report={report}
            isReported={isReported}
            itemType="review"
            singleItemView={Boolean(reviewId)}
          />
        </div>

        {commentsVisible &&
          <CommentsSection
            comments={comments}
            user={user}
            reply={reply}
            replied={replied}
            setReplied={setReplied}
            reactToComment={reactToComment}
            commentId={id}
            loading={loadingComments}
            count={commentsCount}
            showMore={showMore}
            createCommentReportingUrl={createCommentReportingUrl}
            rootTopicType="review"
          />
        }

         <Modal
          isOpen={modal.visible}
          className="shareModal"
          close={closeModal}
          closeBtn
        >
          {modal.name === MODAL_NAMES.SHARE &&
            <Share
              url={teaserUrl}
              organizationName={organization?.name}
              itemType='review'
              shareType={metadata && metadata.class}
              itemId={organization?.id}
              redirectUrl={shareReviewUrl}
              closeModal={closeModal}
              reviewId={id}
              linkedinText={`Check out this review of ${organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better! ${teaserUrl}`}
            />
          }
          {modal.name === MODAL_NAMES.DELETE_REVIEW &&
            <Confirm
              question="Are you sure that this review violates the community rules and, in consequence, you want to remove it from the platform?"
              close={closeModal}
              action={deleteReview}
            />
          }
          {modal.name === MODAL_NAMES.NUDGE_TO_REGISTER &&
            <div className="Review__nudge">
              <h3>What's your take? Sign in to continue.</h3>
              <Link to={{pathname: "/auth/signin", state: redirect(id, false, true)}} className="btn-primary">Sign in</Link>
            </div>
          }
        </Modal>
      </div>
    )
  }
)

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  urlParams: parse(location.search.substr(1))
});

const mapDispatchToProps = dispatch => ({
  getPostComments: (args = {}) => new Promise((resolve, reject) => dispatch(getPostComments({...args, resolve, reject}))),
  reply: (values, postId, itemType) => dispatch(addPostComment({values, postId, itemType})),
  report: (args = {}) => dispatch(report(args)),
  reactToComment: (id, action) => dispatch(voteComment(id, action)),
  deleteReview: (...args) => dispatch(deleteReview(...args)),
  displaySuccessMessage: (message) => dispatch(actionSucceeded(message))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Review));
