import React                        from 'react';
import { connect }                  from 'react-redux';
import { Link }                     from 'react-router-dom';
import { reduxForm,
         Field,
         Fields,
         FieldArray,
         formValueSelector }        from 'redux-form';
import { compose }                  from 'recompose';
import { env }                      from 'theme/content';
import { TextField,
         Select,
         CodeField,
         ArrayField,
         Radio,
         Datepicker,
         Switch,
         CheckboxGroup,
         MultipleField }            from 'theme/Form';
import MoreInfo                     from 'theme/MoreInfo';
import MobileActions                from 'theme/MobileActions';
import { images }                   from 'theme/img/images';
import { options, copy }            from 'theme/content';
import { required }                 from 'theme/utils/validate';
import { withScreenWidthDetection } from 'theme/utils/recompose';

import './Form.sass';


export default compose(
  withScreenWidthDetection()
)(connect(
  (state, props) => ({
    hasExpirationDate: formValueSelector(props.form)(state, 'hasExpirationDate')
  })
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, invalid, pristine, initialValues, submitting, hasExpirationDate, history, jobId, actionType, getSocCodes, getFieldsOfStudy, openModal, cloned, screenWidth, deleteJob}) => {
    const mobileColumnsCount = {
      positionTypes: () => {
        if (screenWidth >= 992) return 4;
        else if (screenWidth < 992 && screenWidth > 400) return 3;
        else return 1;
      },
      benefits: () => {
        if (screenWidth >= 768) return 2;
        else return 1;
      },
      companyCultures: () => {
        if (screenWidth >= 768) return 4;
        else return 2;
      }
    };

    const generateMobileActions = () => {
      if (!cloned) {
        if (initialValues.status !== 'Closed') {
          return [
            {text: 'Clone', fn: () => history.push(`/org-admin/jobs/${jobId}/clone`), icon: images.plusOrange},
            {text: 'Close recruitment', fn: openModal, icon: images.personXs}
          ];
        } else return [{text: 'Clone', fn: () => history.push(`/org-admin/jobs/${jobId}/clone`), icon: images.plusOrange}];
      } else return [{text: 'Close recruitment', fn: openModal, icon: images.personXs}];
    };

    return (
      <div className="JobOpeningForm">
        <form onSubmit={handleSubmit}>
          <div className="JobOpeningForm__heading">
            <h1>{actionType} job opening</h1>
            <div className="JobOpeningForm__headingBtns">
              {jobId &&
                <>
                  {!cloned &&
                    <Link to={`/org-admin/jobs/${jobId}/clone`} className="JobOpeningForm__clone btn-primary-outlined">Clone</Link>
                  }
                  {initialValues.status !== 'Closed' &&
                    <span className="JobOpeningForm__closeRecruitment btn-primary-outlined" onClick={openModal}>Close recruitment</span>
                  }
                  <MobileActions
                    actions={generateMobileActions()}
                    reverse
                    xl
                  />
                </>
              }
              <button className="JobOpeningForm__save btn-primary">Save</button>
            </div>
          </div>

          <div className="JobOpeningForm__section">
            <div className="form-narrow">
              <Field
                name="jobTitle"
                label="Job title"
                required
                validate={required}
                placeholder="Level 1 Mechatronics Systems Assistant"
                component={TextField}
              />
              <div className="JobOpeningForm__soc">
                <h5 className="subheader">
                  O*Net Standard Occupation Classification (SOC)
                  <MoreInfo text={copy.jobOpening.soc} />
                </h5>
                <div className="JobOpeningForm__socWrapper">
                  <Field
                    name="soc"
                    component={CodeField}
                    labels={['Code', 'Associated O*Net Name']}
                    className="JobOpeningForm__socField"
                    getCodes={query => getSocCodes(query, 'code')}
                    getCodeNames={query => getSocCodes(query, 'description')}
                  />
                </div>
                <div className="JobOpeningForm__soc industry">
                  <Field
                    name="industry"
                    label="Industry"
                    required
                    validate={required}
                    placeholder="Select"
                    component={Select}
                    className="JobOpeningForm__select"
                    options={options.industries}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="JobOpeningForm__section">
            <h5 className="subheader">Candidate criteria</h5>
            <div>
              <FieldArray
                name="industryExperience"
                component={ArrayField}
                label="Experience in the industry"
                maxCount={1}
                centerVertically
              >
                <Fields
                  names={['period', 'required']}
                  component={MultipleField}
                  components={['select', 'radioLabels']}
                  options={options.experiencePeriods}
                  labelOptions={options.isRequiredOptions}
                  keyName="industryExperience"
                  keys={['period', 'required']}
                  placeholders={['Select min. time period']}
                  defaultValueIndex={1}
                />
              </FieldArray>

              <FieldArray
                name="leadershipExperience"
                component={ArrayField}
                label="Leadership or management experience"
                maxCount={1}
                centerVertically
              >
                <Fields
                  names={['period', 'required']}
                  component={MultipleField}
                  components={['select', 'radioLabels']}
                  options={options.experiencePeriods}
                  labelOptions={options.isRequiredOptions}
                  keyName="leadershipExperience"
                  keys={['period', 'required']}
                  placeholders={['Select min. time period']}
                  defaultValueIndex={1}
                />
              </FieldArray>

              <FieldArray
                name="education"
                label="Minimum formal education"
                component={ArrayField}
                className="JobOpeningForm__minFormalEducation"
                hideLabel
              >
                <Fields
                  names={['degree', 'fieldOfStudy', 'required']}
                  component={MultipleField}
                  components={['select', 'async', 'radioLabels']}
                  options={[options.formalEducationOptions, options.fieldOfStudyOptions]}
                  labelOptions={options.isRequiredOptions}
                  labels={['Minimum formal education', 'Field of study']}
                  keyName="education"
                  keys={['degree', 'fieldOfStudy', 'required']}
                  placeholders={['Select degree', 'Start typing...']}
                  getAutocomplete={getFieldsOfStudy}
                  defaultValueIndex={2}
                  noLabel
                />
              </FieldArray>

              <FieldArray
                name="skills"
                className="JobOpeningForm__skills"
                component={ArrayField}
                label="Skills"
                info={copy.jobOpening.skills}
                centerVertically
              >
                <Fields
                  names={['name', 'required']}
                  component={MultipleField}
                  components={['input', 'radioLabels']}
                  labelOptions={options.isRequiredOptions}
                  keyName="skills"
                  keys={['name', 'required']}
                  defaultValueIndex={1}
                />
              </FieldArray>

              <div className="JobOpeningForm__accessibility">
                <span className="form-label pseudo-label">Accessibility info for individuals with disabilities</span>
                <Field
                  name="isDisabilityFriendly"
                  component={Radio}
                  label="Disability-friendly job"
                  mode="checkbox"
                />
                <Field
                  name="disabilityDescription"
                  placeholder="Describe the nature of the physical tasks…"
                  component={TextField}
                  componentType="textarea"
                  className="form-narrow"
                />
              </div>
            </div>
          </div>

          <div className="JobOpeningForm__section">
            <h5 className="subheader">Job details</h5>
            <Field
              name="availableTypes"
              label="Position Type"
              required
              validate={required}
              component={CheckboxGroup}
              options={options.positions}
              columnCount={mobileColumnsCount.positionTypes()}
            />
            <Field
              name="supportedPrograms"
              label="Supported Programs"
              component={CheckboxGroup}
              options={options.supportedPrograms}
              moreInfo={[
                copy.jobOpening.dodSkillbridge,
                copy.jobOpening.rap,
                copy.jobOpening.irap
              ]}
            />
            <Field
              name="fillBy"
              label="Need to have this job filled by"
              placeholder={env.DATE_FORMAT}
              component={Datepicker}
              minDate={new Date()}
              isClearable
            />
            <div className="JobOpeningForm__location">
              <Field
                name="location"
                label="Location"
                placeholder="e.g. Austin, TX"
                component={TextField}
              />
              <Field
                name="remoteAvailable"
                label="Remote possible"
                component={Switch}
                options={options.switchOptions}
              />
            </div>
            <div className="JobOpeningForm__jobDescription">
              <div className="JobOpeningForm__jobDescriptionContainer">
                <Field
                  name="jobDescription"
                  label="Job description"
                  placeholder="Type here..."
                  component={TextField}
                  componentType="markdown"
                />
                <Field
                  name="benefits"
                  label="Benefits"
                  component={CheckboxGroup}
                  options={options.benefitOptions}
                  columnCount={mobileColumnsCount.benefits()}
                />
                {/* <Field
                  name="companyCultures"
                  label="Team Culture"
                  component={CheckboxGroup}
                  options={options.companyCultureOptions}
                  columnCount={mobileColumnsCount.companyCultures()}
                /> */}
              </div>
              <div className="JobOpeningForm__jobDescriptionTip">
                <p>How to write an effective job description?</p>
                <ol>
                  <li>Keep it as simple and clear as possible.</li>
                  <li>
                    Veterans are very mission oriented. Highlight your:
                    <ol type="a">
                      <li>Company's mission: Capture what the company does and why in one line.</li>
                      <li>Team's mission: Capture what this job's team does and why in one line.</li>
                      <li>Job's mission: Capture what this job does and why in one line.</li>
                    </ol>
                  </li>
                  <li>List the 2 or 3 most important skills the job requires and how many years of experience for each skill.</li>
                  <li>Veterans are very values oriented. Highlight how this team's values and culture nest into the company's and why it matters to success.</li>
                  <li>Veterans are highly attracted to authenticity. Be authentic - one of the best ways to set realistic expectations is to acknowledge how the group implements and practices stated values.</li>
                  <li>Short, concise descriptions are the most effective.</li>
                </ol>
              </div>
            </div>
            <div className="form-narrow" />
          </div>

          <div className="JobOpeningForm__section">
            <div className="form-narrow">
              <Field
                name="referenceNumber"
                label="Reference number"
                placeholder=""
                component={TextField}
                className="JobOpeningForm__refNumber"
              />
            </div>
            <div className="JobOpeningForm__expiration">
              <span className="form-label pseudo-form-label">Opening settings</span>
              <div>
                <Field
                  name="hasExpirationDate"
                  component={Radio}
                  label="Set expiration date"
                  mode="checkbox"
                />
                {hasExpirationDate &&
                  <>
                    <Field
                      name="expirationDate"
                      placeholder={env.DATE_FORMAT}
                      component={Datepicker}
                      minDate={new Date()}
                      isClearable
                    />
                    <p>Recruitment will be closed after this date.</p>
                  </>
                }
              </div>
            </div>
          </div>

          <div className="JobOpeningForm__actions">
            <button
              type="submit"
              className="btn-primary"
              disabled={pristine || submitting || invalid}
            >
              Save
            </button>
            {jobId &&
              <span
                className="btn-primary-outlined"
                onClick={deleteJob}
              >Delete</span>
            }
          </div>
        </form>
      </div>
    )
  }
)))
