import { createStore,
         applyMiddleware,
         combineReducers,
         compose }                  from 'redux';
import thunkMiddleware              from 'redux-thunk';
import createSagaMiddleware         from 'redux-saga';
import { createLogger }             from 'redux-logger';
import { reducer as form }          from 'redux-form';
import { routerMiddleware,
         connectRouter }            from 'connected-react-router';
import { loadAuth,
        configureInterceptors }     from 'api';

import history                      from './history';
import reducers                     from './reducers';
import sagas                        from './sagas';

export { default as history }       from './history';

const sagaMiddleware = createSagaMiddleware();

const initialState = {
  auth: loadAuth()
}

const logReduxActions = false;

const middleware = [
  logReduxActions && createLogger({collapsed: true}),  //logging redux actions
  thunkMiddleware,                                                    //allows to return functions instead of actions for dispatch, awesome for async requests
  routerMiddleware(history),
  sagaMiddleware
].filter(Boolean);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

const configureStore = () => {

  const store = createStore(
    combineReducers({
      ...reducers,
      form,
      router: connectRouter(history)
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // We need to require for hot reloading to work properly.
      const nextReducer = require('./reducers');  // eslint-disable-line global-require

      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const store = configureStore();

sagaMiddleware.run(sagas);

configureInterceptors && configureInterceptors(store);

export default store;