import React                   from 'react';
import AsyncCreatableSelect    from 'react-select/async-creatable';
import cx                      from 'classnames';
import { images }              from 'theme/img/images';

import './TrendingTopics.sass';


const customStyles = {
  singleValue: (provided, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'block'
  })
};

export default function TrendingTopics({ topics, removeTopic, addTopicWithSearch, message, clearMessage, className, disabled, taglabel, loadOptions }) {
  return (
    <div className={cx("TrendingTopics", className, {disabled})}>
      <h5 className="subheader">Most trending topics</h5>
      <div className="TrendingTopics__container">
        {topics.map((t, i) => (
          <button
            key={i}
            type="button"
            className={cx("Form__radioLabel", {
              active: loadOptions || topics.indexOf(t.id) > -1,
              taglabel
            })}
            onClick={() => removeTopic(t)}
            disabled={disabled}
          >
            {t.name}
            {(loadOptions || topics.indexOf(t.id) > -1) &&
              <img src={images.closeWhite} alt="Remove topic" />
            }
          </button>
        ))}
      </div>
      {loadOptions &&
        <div className="TrendingTopics__search Form__labelGroupSearch">
          <div>
            <AsyncCreatableSelect
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null
              }}
              placeholder="Search for more"
              loadOptions={loadOptions}
              classNamePrefix="RSelect"
              onBlur={() => clearMessage()}
              onInputChange={() => clearMessage()}
              onChange={e => addTopicWithSearch(e)}
              styles={customStyles}
            />
            <img src={images.search} alt="Search" />
          </div>
          <p className="TrendingTopics__message">{message}</p>
        </div>
      }
    </div>
  );
}

TrendingTopics.displayName = 'TrendingTopics';
