import React                from 'react';
import {lifecycle,
        withState,
        compose,
        withHandlers}       from 'recompose';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import Loader               from 'theme/Loader';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Confirm              from 'theme/Confirm';
import Modal                from 'theme/Modal';
import AdminNav             from 'theme/AdminNav';
import Pagination           from 'theme/Pagination';
import withDebounce,
      { withModal }        from 'theme/utils/recompose';
import { getUsers,
         deleteUser }       from 'users/actions';

import './Users.sass';


const Users = compose(
  withModal(),
  withState('activePage', 'setPage', 0),
  withState('query', 'setQuery', null),
  lifecycle({
    componentDidMount() {
      this.props.getUsers();
    }
  }),
  withHandlers({
    searchUsers: ({getUsers, setPage, query}) => (page) => {
      setPage(page);
      getUsers({page, query});
    },
    deleteUser: ({query, activePage, getUsers, closeModal}) => id => {
      return deleteUser(id)
      .then(() => {
        getUsers({query, page: activePage});
        closeModal();
      })
    }
  }),
  withDebounce(),
  withHandlers({
    change: ({searchDebounced, setQuery}) => (e) => {
      const query = e.target.value == '' ? null : e.target.value;
      setQuery(query);
      searchDebounced(query);
    }
  })
)(({loading, users, activePage, searchUsers, change, usersCount, deleteUser, modal, openModal, closeModal}) => {
  return (
    <div className="Users page">
      <Helmet>
        <title>Vetit Admin – Users</title>
      </Helmet>
      <Header />
      <AdminNav />
      <div className="admin-container Users__filter">
        <span>Filter by email: </span>
        <input onChange={change} placeholder="Enter user email" />
      </div>
      <div className="container admin-container">
        <Loader className="Users__loader" loading={loading} />
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Details</th>
              <th>Topics</th>
              <th>Admin</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              return (
                <tr className="Users__tableRow" key={user.id}>
                  <td className="Users__email" data-th="Email">
                    <Link to={`/admin/users/${user.id}`}>{user.email}</Link>
                  </td>
                  <td data-th="Name">{user.firstName} {user.lastName}</td>
                  <td data-th="Details" className="Users__details">
                    {user.serviceBranch && <span>{user.serviceBranch}</span>}
                    {user.mos && <span>{user.mos}</span>}
                    {user.etsPaygrade && <span>{user.etsPaygrade}</span>}
                  </td>
                  <td data-th="Topics">{user.championTopicIds?.length}</td>
                  <td data-th="Admin">{user.isAdmin && <span className="fa fa-check" />}</td>
                  <td data-th="">
                    <Link to={`/admin/users/${user.id}`} className="btn-edit">EDIT</Link>
                    <span
                      className="Users__delete"
                      onClick={() => openModal('deleteUser', user)}
                    >
                      DELETE
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination
          itemsCount={usersCount}
          gotoPage={searchUsers}
          disabled={loading}
          activePage={activePage}
        />
      </div>
      <Footer />

      <Modal
        isOpen={modal.visible}
        close={closeModal}
      >
        <Confirm
          question="Are you sure you want to delete this user?"
          close={closeModal}
          action={() => deleteUser(modal.payload.id)}
        />
      </Modal>
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}) => {
  return {
    user: auth.user,
    users: (data.users && data.users.data) || [],
    usersCount: (data.users && data.users.count) || 0,
    loading: loading.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (query) => new Promise((resolve, reject) => dispatch(getUsers({query, page: 0, resolve, reject}))),
    getUsers: ({query, page} = {}) => dispatch(getUsers({query, page})),
    deleteUser: (id) => dispatch(deleteUser(id))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Users))