import React, { useEffect }    from 'react';
import { connect }             from 'react-redux';
import { withRouter }          from 'react-router';
import { formValueSelector }   from 'redux-form';
import fromUnixTime            from 'date-fns/fromUnixTime';
import { API_CONF }            from 'api';
import Tabs                    from 'theme/Tabs';
import Footer                  from 'theme/Footer';
import Header                  from 'theme/Header';
import { formatDate }          from 'theme/utils/helpers';
import PersonalInformationForm from 'user/Forms/PersonalInformationForm';
import Plan                    from 'companyLanding/Plan';
import availablePlans          from 'companyLanding/AvailablePlans';
import { getOrganization,
         updateOrganization }  from 'organizations/actions';
import { getSubscription,
         editSubscription }    from 'organizationAdmin/actions';
import { getPartners }         from 'partners/actions';
import { updateUserProfile }   from 'user/actions';

import premiumImg              from './premium.svg';
import BasicProfileForm        from '../Forms/BasicProfileForm';
import PremiumProfileForm      from '../Forms/PremiumProfileForm';

import './ManageProfile.sass';


const links = [
  {name: 'general', label: 'General'},
  {name: 'personal', label: 'Personal information'},
  {name: 'subscription', label: 'Subscription'},
  {name: 'premium', label: 'Premium features'}
];

window.Chargebee.init({
  site: API_CONF.CHARGEBEE_SITE,
  publishableKey: API_CONF.CHARGEBEE_KEY
})

const basicProfileFormName = 'BasicProfileForm';
const premiumEmployerFormName = 'PremiumEmployerForm';
const PersonalInformationFormName = "PersonalInfoForm";

function ManageProfile({match, updateUserProfile, organization, partners, selectedPartners, loading, loadingProfile, submit, getOrganization, userProfile, getSubscription, editSubscription, getPartners, user, subscription}) {
  const activeTab = match.params.activeTab;
  const initialValues = {
    ...organization,
    partners: organization?.partners?.map(p => p.id)
  };

  const partnerOptions = partners?.map(p => ({label: p.name, value: p.id}));
  const filterOptions = {
    partners: option => selectedPartners.findIndex(id => id === option.value) === -1
  };

  const submitEmployer = values => {
    return submit(values, organization.id);
  };

  const hasPremium = !subscription.error;

  const changePlan = () => {
    const planId = hasPremium ? 'basic' : 'premium';

    return editSubscription(planId)
    .then(res => window.open(hasPremium ? res.data.portal_session.access_url : res.data.hosted_page.url, '_self'));
  };

  const editUserProfile = values => {
    return updateUserProfile(user, values);
  }

  const currentTermEnd = subscription?.subscription?.current_term_end && formatDate(fromUnixTime(subscription?.subscription?.current_term_end));

  useEffect(() => {
    getOrganization(user.organizationId);
    getPartners();
    getSubscription();
  }, []);

  return (
    <div className="ManageProfile page">
      <Header />
      <div className="container">
        <div className="ManageProfile__content">
          <h2 className="ManageProfile__title">Employer</h2>
          <Tabs
            links={hasPremium ? links : links.slice(0, -1)}
            section={activeTab}
            loading={loading || loadingProfile}
            mobileTitle="Employer"
          >
            {activeTab == 'general' &&
              <BasicProfileForm
                onSubmit={submitEmployer}
                form={basicProfileFormName}
                initialValues={initialValues}
                partnerOptions={partnerOptions}
                filterOptions={filterOptions}
                organizationProfile
              />
            }
            {activeTab =='personal' &&
              <PersonalInformationForm
                onSubmit={editUserProfile}
                form={PersonalInformationFormName}
                initialValues={userProfile}
                user={user}
                isSettings
                isEmployer
              />
            }
            {activeTab == 'subscription' &&
              <div className="ManageProfile__subscription">
                <div className="ManageProfile__subscriptionInfo">
                  <div>
                    <h5 className="subheader">Your subscription</h5>
                    <span>{subscription.error ? 'Basic' : 'Premium'}</span>
                  </div>
                  <div>
                    <h5 className="subheader">Expiration</h5>
                    <span>{currentTermEnd}</span>
                  </div>
                </div>

                {hasPremium
                  ? <button
                      className="btn-primary"
                      onClick={() => changePlan()}
                    >
                      Manage Subscription
                    </button>
                  : <div className="ManageProfile__subscriptionContainer">
                      <span>Upgrade your account</span>
                      <div className="ManageProfile__subscriptionCard">
                        <Plan
                          plan={availablePlans[hasPremium ? 1 : 0]}
                          premiumImg={premiumImg}
                          changePlan={changePlan}
                          altText={<><span>Fast, simple, and effective.</span> <span>Upgrade and post jobs in minutes.</span></>}
                        />
                      </div>
                    </div>
                }
              </div>
            }
            {activeTab == 'premium' &&
              <PremiumProfileForm
                onSubmit={submitEmployer}
                form={premiumEmployerFormName}
                initialValues={initialValues}
                organizationProfile
              />
            }
          </Tabs>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = state => {
  const { auth, loading, data } = state;
  const id = auth.user?.organizationId;
  const selector = formValueSelector(basicProfileFormName);

  return {
    user: auth.user,
    userProfile: data?.user_profile || {},
    loading: loading[`organization:employer:${id}`],
    loadingProfile: loading.user_profile,
    organization: (data && data[`organization:employer:${id}`]) || {},
    partners: data.partners?.data || [],
    selectedPartners: selector(state, 'partners') || [],
    subscription: state.data?.subscription || {}
  };
};

const mapDispatchToProps = dispatch => ({
  getOrganization: organizationId => new Promise((resolve, reject) => dispatch(getOrganization(organizationId, 'employer', {resolve, reject}))),
  getSubscription: () => dispatch(getSubscription()),
  editSubscription: planId => dispatch(editSubscription(planId)),
  submit: (values, id) => dispatch(updateOrganization({organizationType: 'employer', isOrgAdminPage: true, id, values, form: basicProfileFormName})),
  getPartners: () => dispatch(getPartners({page: 0, pageSize: 200})),
  updateUserProfile: (user, values) => dispatch(updateUserProfile(user.id, values, false, true))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProfile));
