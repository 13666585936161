import api      from 'api';
import { omit } from 'underscore';

function fetchJob({id}) {
  return api({
    method: 'get',
    url: `/job-openings/${id}`
  });
}

function fetchJobs({page = 0, pageSize = 10, sort, filter, query}) {
  return api({
    method: 'get',
    url: '/job-openings/offered',
    params: {
      page,
      pageSize,
      sort: sort || 'createdAt:d',
      ...filter && {filter: `status:${filter}`},
      ...query && {query}
    }
  });
}

function fetchJobApplications({jobOpeningId}) {
  return api({
    method: 'get',
    url: '/job-applications',
    params: { jobOpeningId }
  })
}

function fetchJobApplication({jobApplicationId}) {
  return api({
    method: 'get',
    url: `/job-applications/${jobApplicationId}`
  })
}

function fetchMatchedProfiles({jobOpeningId, page = 0, pageSize = 3}) {
  return api({
    method: 'get',
    url: `/job-openings/${jobOpeningId}/matched-profiles`,
    params: { page, pageSize }
  })
}

function fetchOrganizationFeed({page = 0, pageSize = 3}) {
  return api({
    method: 'get',
    url: `/feed/organization`,
    params: { page, pageSize }
  })
}

export const inviteToApply = (userId, jobOpeningId) => () => {
  return api({
    method: 'post',
    url: '/job-invitations',
    data: { userId, jobOpeningId }
  });
}

export const editNotes = (id, values) => {
  return api({
    method: 'patch',
    url: `/job-applications/${id}`,
    data: { employerNotes: values }
  });
}

export const getSocCodes = query => () => {
  return api({
    method: 'get',
    url: '/dictionaries/soc-codes',
    params: { query }
  })
}

export const getFieldsOfStudy = ({name}) => () => {
  return api({
    method: 'get',
    url: '/dictionaries/fields-of-study',
    params: { name }
  })
  .then(({data}) => data.map(r => ({
    label: r.fieldOfStudy.slice(0, -1),
    value: r.fieldOfStudy.slice(0, -1)
  })));
}

const parseData = values => {
  return omit({
    ...values,
    education: values.education?.map(item => ({
      ...item,
      degree: item.degree,
      fieldOfStudy: item.fieldOfStudy
    })) || [],
    associatedName: values.soc?.associatedName?.value,
    socCode: values.soc?.code?.value,
    skills: values?.skills || [],
    supportedPrograms: values?.supportedPrograms || [],
    benefits: values?.benefits || [],
    companyCultures: values?.companyCultures || [],
    leadershipExperience: values.leadershipExperience && {
      ...(values.leadershipExperience && values.leadershipExperience[0]),
      period: values?.leadershipExperience && values.leadershipExperience[0].period
    },
    industryExperience: values.industryExperience && {
      ...(values.industryExperience && values.industryExperience[0]),
      period: values?.industryExperience && values.industryExperience[0].period
    }
  }, 'soc', 'status');
}

function postJob({values}) {
  return api({
    method: 'post',
    url: '/job-openings',
    data: parseData(values)
  });
}

function patchJob({id, values}) {
  return api({
    method: 'patch',
    url: `/job-openings/${id}`,
    data: parseData(values)
  });
}

export const closeJob = ({id, values}) => () => {
  return api({
    method: 'patch',
    url: `/job-openings/${id}`,
    data: {
      ...parseData(values),
      status: 'Closed'
    }
  });
}

export const shortlist = (id, value) => () => {
  return api({
    method: 'patch',
    url: `/job-applications/${id}`,
    data: {
      shortlisted: value
    }
  });
}

export const deleteJob = ({id}) => () => {
  return api({
    method: 'delete',
    url: `/job-openings/${id}`
  });
}

export const viewApplication = id => () => {
  return api({
    method: 'patch',
    url: `/job-applications/${id}`,
    data: {
      viewed: true
    }
  })
}

export const getJob = (id, opts = {loader: true}) => {
  return {type: `JOB:${id}/FETCH`, req: fetchJob, id, ...opts}
}

export const getJobs = ({page, pageSize, filter, sort, query, loader}) => {
  return {type: `JOBS/FETCH`, req: fetchJobs, page, pageSize, filter, sort, query, loader}
}

export const getJobApplication = (jobApplicationId, opts = {loader: true}) => {
  return {type: `JOB_APPLICATION/FETCH`, req: fetchJobApplication, jobApplicationId, ...opts}
}

export const getJobApplications = (jobOpeningId, opts = {loader: true}) => {
  return {type: `JOB_APPLICATIONS/FETCH`, req: fetchJobApplications, jobOpeningId, ...opts}
}

export const getMatchedProfiles = (jobOpeningId, opts = {loader: true}) => {
  return {type: `MATCHED_PROFILES/FETCH`, req: fetchMatchedProfiles, jobOpeningId, ...opts}
}

export const addJob = ({values, resolve, reject}) => {
  return {type: 'JOB/SUBMIT', values, req: postJob, resolve, reject}
}

export const updateJob = ({id, values, resolve, reject}) => {
  return {type: `JOB:${id}/SUBMIT`, id, values, req: patchJob, resolve, reject}
}

export const getOrganizationFeed = ({page, pageSize, resolve, reject, loader = true}) => {
  return {type: 'ORGANIZATION_FEED/FETCH', req: fetchOrganizationFeed, page, pageSize, loader, resolve, reject}
}

// SUBSCRIPTIONS

function fetchSubscription() {
  return api({
    method: 'get',
    url: '/subscriptions/my'
  })
}

function fetchSubscriptions() {
  return api({
    method: 'get',
    url: '/subscriptions'
  })
}

export const generateCheckout = planId => () => {
  return api({
    method: 'post',
    url: 'subscriptions/my',
    params: {plan: planId}
  })
  .then(({data}) => data)
}

export const editSubscription = planId => () => {
  return api({
    method: 'patch',
    url: 'subscriptions/my',
    params: {plan: planId}
  })
}

export const getSubscription = (opts = {}) => {
  return {type: `SUBSCRIPTION/FETCH`, req: fetchSubscription, ...opts}
}

export const getSubscriptions = (opts = {}) => {
  return {type: `SUBSCRIPTIONS/FETCH`, req: fetchSubscriptions, ...opts}
}
