import React from 'react';

import './Styleguide.sass'

const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam condimentum cursus congue. Sed facilisis ante nec sem tempor posuere. Maecenas risus nisl, vulputate vitae iaculis sit amet, semper quis erat. Quisque nec est justo."


export default function Styleguide () {
  return (
    <div className="Styleguide">
      <div className="Styleguide__section Styleguide__title">
        <h1>AaBcDd</h1>
        <span>Poppins Google Font</span>
      </div>
      <div className="Styleguide__heading">
        Typography
      </div>
      <div className="Styleguide__section Styleguide__typography">
        <div className="Styleguide__column">
          <h1>h1. Heading 1</h1>
          <h2>h2. Heading 2</h2>
          <h3>h3. Heading 3</h3>
          <h4>h4. Heading 4</h4>
          <h5>h5. Heading 5</h5>
        </div>
        <div className="Styleguide__column">
          <h1 className="Styleguide__hSecondary">h1. Heading 1</h1>
          <h2 className="Styleguide__hSecondary">h2. Heading 2</h2>
          <h3 className="Styleguide__hSecondary">h3. Heading 3</h3>
          <h4 className="Styleguide__hSecondary">h4. Heading 4</h4>
          <h5 className="Styleguide__hSecondary">h5. Heading 5</h5>
        </div>
        <div className="Styleguide__column">
          <h6>Sub header</h6>
          <p className="Styleguide__secondary">Secondary text</p>
          <p className="Styleguide__paragraph">{loremIpsum}</p>
          <p className="Styleguide__paragraph Styleguide__paragraphSecondary">{loremIpsum}</p>
        </div>
      </div>
      <div className="Styleguide__heading">
        Buttons
      </div>
      <div className="Styleguide__section Styleguide__buttons Styleguide__row">
        <div className="Styleguide__column">
          <div />
          <div>Default</div>
          <div>Hover</div>
          <div>Active</div>
          <div>Disabled</div>
          <div>Outline</div>
        </div>
        <div className="Styleguide__column">
          <div>Primary</div>
          <div>
            <button className="btn-primary">Button</button>
          </div>
          <div>
            <button className="btn-primary hover">Button</button>
          </div>
          <div>
            <button className="btn-primary active">Button</button>
          </div>
          <div>
            <button className="btn-primary" disabled>Button</button>
          </div>
          <div>
            <button className="btn-primary_outlined">Button</button>
          </div>
        </div>
        <div className="Styleguide__column">
          <div>Secondary</div>
          <div>
            <button className="btn-secondary">Button</button>
          </div>
          <div>
            <button className="btn-secondary hover">Button</button>
          </div>
          <div>
            <button className="btn-secondary active">Button</button>
          </div>
          <div>
            <button className="btn-secondary" disabled>Button</button>
          </div>
          <div>
            <button className="btn-secondary_outlined">Button</button>
          </div>
        </div>
        <div className="Styleguide__column Styleguide__ghost">
          <div>Ghost</div>
          <div>
            <button className="btn_ghost">Button</button>
          </div>
          <div>
            <button className="btn_ghost hover">Button</button>
          </div>
          <div>
            <button className="btn_ghost active">Button</button>
          </div>
          <div>
            <button className="btn_ghost" disabled>Button</button>
          </div>
          <div>
            <button className="btn_ghost_outlined">Button</button>
          </div>
        </div>
      </div>
      <div className="Styleguide__heading">
        Colors
      </div>
      <div className="Styleguide__section Styleguide__colors">
        <div className="Styleguide__row">
          <div className="color1" />
          <div className="color2" />
          <div className="color3" />
          <div className="color4" />
          <div className="color5" />
        </div>
        <div className="Styleguide__row">
          <div className="color6" />
          <div className="color7" />
          <div className="color8" />
          <div className="color9" />
          <div className="color5" />
        </div>
      </div>
    </div>
  )
}