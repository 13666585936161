const armyMosValues = [
  '11A — INFANTRY',
  '11B — INFANTRYMAN',
  '11C — INDIRECT FIRE INFANTRYMAN',
  '11Z — INFANTRY SENIOR',
  '120A — CONSTRUCTION ENGINEERING',
  '125D — GEOSPATIAL ENGINEERING',
  '12A — ENGINEER',
  '12B — COMBAT ENGINEER',
  '12B — COMBAT ENGINEER',
  '12C — BRIDGE CREWMEMBER',
  '12D — DIVER',
  '12D — CONSTRUCTION ENGINEER',
  '12G — QUARRYING (RC)',
  '12H — CONSTRUCTION ENGINEERING SUPERVISOR',
  '12K — PLUMBER',
  '12M — FIREFIGHTER',
  '12N — HORIZONTAL CONSTRUCTION ENGINEER',
  '12P — PRIME POWER PRODUCTION',
  '12Q — POWER LINE DISTRIBUTION (RC)',
  '12R — INTERIOR ELECTRICIAN',
  '12T — TECHNICAL ENGINEER',
  '12V — CONCRETE AND ASPHALT EQUIPMENT OPERATOR',
  '12W — CARPENTRY AND MASONRY',
  '12X — GENERAL ENGINEERING SUPERVISOR',
  '12Y — GEOSPATIAL ENGINEER',
  '12Z — COMBAT ENGINEERING SENIOR',
  '131A — FIELD ARTILLERY TARGETING',
  '13A — FIELD ARTILLERY',
  '13B — CANNON CREWMEMBER',
  '13C — TACTICAL AUTOMATED FIRE CONTROL SYSTEMS',
  '13D — FIELD ARTILLERY AUTOMATED TACTICAL DATA SYSTEM',
  '13E — CANNON FIRE DIRECTION',
  '13F — FIRE SUPPORT',
  '13M — MLRS HIMARS',
  '13P — MLRS OPERATIONAL FIRE DIRECTION',
  '13R — FIELD ARTILLERY FIREFINDER RADAR OPERATOR',
  '13S — FIELD ARTILLERY SURVEYOR',
  '13T — FIELD ARTILLERY SURVEYOR',
  '13W — FIELD ARTILLERY METEOROLOGICAL',
  '13Z — FIELD ARTILLERY SENIOR',
  '140A — COMMAND & CONTROL',
  '140E — AIR AND MISSILE DEFENSE',
  '140X — AIR DEFENSE ARTILLERY',
  '14A — AIR DEFENSE',
  '14E — PATRIOT FIRES',
  '14J — AIR DEFENSE',
  '14M — MAN PORTABLE AIR DEFENSE SYSTEM CREWMEMBER (RC)',
  '14S — AIR AND MISSILE DEFENSE (AMD) CREWMEMBER',
  '14T — PATRIOT LAUNCHING STATION ENHANCED OPERATOR/MAINTAINER',
  '14Z — AIR DEFENSE ARTILLERY SENIOR',
  '150A — AIR TRAFFIC',
  '150U — UNMANNED AIRCRAFT SYSTEMS',
  '151A — AVIATION MAINTENANCE',
  '152B — OH-58A/C SCOUT PILOT (RC)',
  '152C — OH-6 PILOT',
  '152D — OH-58D PILOT',
  '152E — ARH-XX PILOT',
  '152F — AH-64A ATTACK PILOT',
  '152H — AH-64D ATTACK PILOT',
  '153A — ROTARY WING AVIATOR',
  '153B — UH-1 PILOT(RC)',
  '153D — UH-60 PILOT',
  '153E — MH-60 PILOT',
  '153L — UH-72A PILOT',
  '153M — UH-60M PILOT',
  '154C — CH-47D PILOT',
  '154E — MH-47 PILOT',
  '154F — CH-47F PILOT',
  '155A — FIXED WING AVIATOR',
  '155E — C-12 PILOT',
  '155F — JET AIRCRAFT PILOT',
  '155G — 0-5A/EO-5B/RC-7 PILOT',
  '15A — AVIATION',
  '15B — AIRCRAFT POWERPLANT REPAIRER',
  '15B — AIR CAV',
  '15C — AVIATION INTEL',
  '15D — AIRCRAFT POWERTRAIN REPAIRER',
  '15F — AIRCRAFT ELECTRICIAN',
  '15G — AIRCRAFT STRUCTURAL REPAIRER',
  '15H — AIRCRAFT PNEUDRAULICS REPAIRER',
  '15J — OH-58D/ARH ARMAMENT/ELECTRICAL/AVIONICS SYSTEMS REPAIRER',
  '15K — AIRCRAFT COMPONENTS REPAIR SUPERVISOR',
  '15M — UH-1 HELICOPTER REPAIRER (RC)',
  '15N — AVIONICS MECHANIC',
  '15P — AVIATION OPS',
  '15Q — AIR TRAFFIC CONTROL OPERATOR',
  '15R — AH-64 ATTACK HELICOPTER REPAIRER',
  '15S — OH-58D/ARH HELICOPTER REPAIRER',
  '15T — UH-60 HELICOPTER REPAIRER',
  '15U — CH-47 HELICOPTER REPAIRER',
  '15V — OBSERVATION/SCOUT HELICOPTER REPAIRER (RC)',
  '15W — UNMANNED AERIAL VEHICLE OPERATOR',
  '15X — AH-64A ARMAMENT/ELECTRICAL/AVIONICS SYSTEMS REPAIRER',
  '15Y — AH-64D ARMAMENT/ELECTRICAL/AVIONICS SYSTEMS REPAIRER',
  '15Z — AIRCRAFT MAINTENANCE SENIOR',
  '180A — SPECIAL FORCES',
  '18A — SPECIAL FORCES',
  '18B — SPECIAL FORCES WEAPONS',
  '18C — SPECIAL FORCES ENGINEER',
  '18D — SPECIAL FORCES MEDICAL',
  '18E — SPECIAL FORCES COMMUNICATIONS',
  '18F — SPECIAL FORCES ASSISTANT OPS & INTEL',
  '18Z — SPECIAL FORCES SENIOR',
  '19A — ARMOR',
  '19C — CAVALRY',
  '19D — CAVALRY SCOUT',
  '19K — M1 ARMOR CREWMAN',
  '19Z — ARMOR SENIOR',
  '210A — CONSTRUCTION ENGINEERING',
  '215D — GEOSPATIAL ENGINEERING',
  '21A — ENGINEER',
  '21B — COMBAT ENGINEER',
  '21B — COMBAT ENGINEER',
  '21C — BRIDGE CREWMEMBER',
  '21D — DIVER',
  '21D — CONSTRUCTION ENGINEER',
  '21E — CONSTRUCTION EQUIPMENT',
  '21G — QUARRYING (RC)',
  '21H — CONSTRUCTION ENGINEERING',
  '21K — PLUMBER',
  '21M — FIREFIGHTER',
  '21N — HORIZONTAL CONSTRUCTION ENGINEER',
  '21P — PRIME POWER PRODUCTION',
  '21Q — POWER LINE DISTRIBUTION (RC)',
  '21R — INTERIOR ELECTRICIAN',
  '21T — TECHNICAL ENGINEER',
  '21V — CONCRETE & ASPHALT EQUIPMENT OPERATOR',
  '21W — CARPENTRY & MASONRY',
  '21X — GENERAL ENGINEERING SUPERVISOR',
  '21Y — GEOSPATIAL ENGINEER',
  '21Z — COMBAT ENGINEERING SENIOR',
  '24A — TELECOMS ENGINEER',
  '24B — DATA SYSTEMS ENGINEER',
  '24Z — INFO SYSTEMS ENGINEER',
  '250N — NETWORK MGMT',
  '251A — INFO SYSTEMS',
  '254A — SIGNAL SYSTEMS SUPPORT',
  '255A — INFO SERVICES',
  '255N — NETWORK MGMT',
  '255S — INFO PROTECTION',
  '255Z — SENIOR NETWORK OPS',
  '25A — SIGNAL',
  '25B — INFO TECHNOLOGY',
  '25C — RADIO OPERATOR-MAINTAINER',
  '25E — ELECTROMAGNETIC SPECTRUM MANAGER',
  '25F — NETWORK SWITCHING SYSTEMS OPERATOR-MAINTAINER',
  '25L — CABLE SYSTEMS INSTALLER-MAINTAINER',
  '25M — MULTIMEDIA ILLUSTRATOR',
  '25N — NODAL NETWORK SYSTEMS OPERATOR-MAINTAINER',
  '25P — MICROWAVE SYSTEMS OPERATOR-MAINTAINER',
  '25Q — MULTICHANNEL TRANSMISSION SYSTEMS OPERATOR-MAINTAINER',
  '25R — VISUAL INFO EQUIPMENT OPERATOR-MAINTAINER',
  '25S — SATELLITE COMMUNICATION SYSTEMS OPERATOR-MAINTAINER',
  '25T — SATELLITE/MICROWAVE SYSTEMS CHIEF',
  '25U — SIGNAL SUPPORT SYSTEMS',
  '25V — COMBAT DOCUMENTATION/PRODUCTION',
  '25W — TELECOMMUNICATIONS OPS CHIEF',
  '25X — SENIOR SIGNAL',
  '25Z — VISUAL INFO OPS CHIEF',
  '270A — LEGAL ADMINISTRATOR',
  '27A — JUDGE ADVOCATE',
  '27B — MILITARY JUDGE',
  '27D — PARALEGAL',
  '290A — ELECTRONIC WARFARE',
  '29A — ELECTRONIC WARFARE',
  '29E — ELECTRONIC WARFARE',
  '30A — INFO OPS',
  '311A — CID SPECIAL AGENT',
  '31A — MILITARY POLICE',
  '31B — MILITARY POLICE',
  '31D — CID SPECIAL AGENT',
  '31E — INTERNMENT/RESETTLEMENT',
  '34A — STRATEGIC INTEL',
  '350F — ALL SOURCE INTEL',
  '350G — IMAGERY INTEL TECHNICAN',
  '350Z — ATTACHE',
  '351L — COUNTERINTEL',
  '351M — HUMAN INTEL COLLECTION',
  '351Y — AREA INTEL',
  '352N — SIGNALS INTEL ANALYSIS',
  '352P — VOICE INTERCEPT',
  '352S — SIGNALS COLLECTION',
  '353T — IEW SYSTEMS MAINTENANCE',
  '35C — IMINT',
  '35D — ALL SOURCE INTEL',
  '35E — COUNTER INTEL',
  '35F — INTEL ANALYST',
  '35F — HUMINT',
  '35G — IMAGERY ANALYST',
  '35G — SIGINT/EW',
  '35H — COMMON GROUND STATION (CGS) ANALYST',
  '35L — COUNTER INTEL AGENT',
  '35M — HUMAN INTEL COLLECTOR',
  '35N — SIGNALS INTEL ANALYST',
  '35P — CRYPTOLOGIC LINGUIST',
  '35S — SIGNALS COLLECTOR/ANALYST',
  '35T — MILITARY INTEL SYSTEMS MAINTAINER/INTEGRATOR',
  '35X — INTEL SENIOR /CHIEF INTEL',
  '35Y — CHIEF COUNTER INTEL/HUMAN INTEL',
  '35Z — SIGNALS INTEL',
  '36A — FINANCIAL MANAGER',
  '36B — FINANCIAL MGMT',
  '37A — PSYCHOLOGICAL OPS',
  '37F — PSYCHOLOGICAL OPS',
  '38A — CIVIL AFFAIRS',
  '38B — CIVIL AFFAIRS',
  '40A — SPACE OPS',
  '40C — ARMY ASTRONAUT',
  '420A — HUMAN RESOURCES',
  '420C — BANDMASTER',
  '42A — HUMAN RESOURCES',
  '42B — HUMAN RESOURCES',
  '42C — ARMY BANDS',
  '42F — HUMAN RESOURCES INFO SYSTEMS MGMT',
  '42H — SENIOR HUMAN RESOURCES',
  '42R — ARMY BANDPERSON',
  '42S — SPECIAL BAND MEMBER',
  '43F — HUMAN RESOURCES INFO SYSTEMS MGMTSPEC',
  '46A — PUBLIC AFFAIRS',
  '46Q — PUBLIC AFFAIRS',
  '46R — PUBLIC AFFAIRS BROADCAST',
  '46Z — CHIEF PUBLIC AFFAIRS NCO',
  '47A — USMA, PROFESSOR',
  '48X — FOREIGN AREA',
  '49A — ORSA',
  '51A — SYSTEMS DEVELOPMENT',
  '51C — AQUISITION, LOGISTICS & TECHNOLOGY (AL&t) CONTRACTING NCO',
  '51C — INDUSTRIAL MANAGEMENT',
  '51R — SYSTEMS AUTOMATION',
  '51S — RESEARCH AND ENGINEERING',
  '51T — TEST AND EVALUATION',
  '51Z — ACQUISITION',
  '52B — NUCLEAR',
  '53A — INFO SYSTEMS MANAGEMENT',
  '53X — DESIGNATED SYSTEMS AUTOMATION',
  '56A — CHAPLAIN',
  '56D — CLINICAL PASTORAL EDUCATOR',
  '56M — CHAPLAIN ASSISTANT',
  '57A — SIMULATIONS OPS',
  '59A — STRATEGIC PLANS AND POLICY',
  '60A — OPERATIONAL MED',
  '60B — NUCLEAR MED',
  '60C — PREVENTIVE MED',
  '60D — OCCUPATIONAL MED',
  '60F — CRITICAL CARE',
  '60G — GASTROENTEROLOGIST',
  '60H — CARDIOLOGIST',
  '60J — OBGYN',
  '60K — UROLOGIST',
  '60L — DERMATOLOGIST',
  '60M — ALLERGIST',
  '60N — ANESTHESIOLOGIST',
  '60P — PEDIATRICIAN',
  '60Q — PEDIATRIC SUB-',
  '60R — CHILD NEUROLOGIST',
  '60S — OPHTHALMOLOGIST',
  '60T — OTOLARYNGOLOGIST',
  '60U — CHILD PSYCHIATRIST',
  '60V — NEUROLOGIST',
  '60W — PSYCHIATRIST',
  '61A — NEPHROLOGIST',
  '61B — MEDICAL ONCOLOGIST/HEMATOLOGIST',
  '61C — ENDOCRINOLOGIST',
  '61D — RHEUMATOLOGIST',
  '61E — CLINICAL PHARMACOLOGIST',
  '61F — INTERNIST',
  '61G — INFECTIOUS DISEASE',
  '61H — FAMILY MED',
  '61J — GENERAL SURGEON',
  '61K — THORACIC SURGEON',
  '61L — PLASTIC SURGEON',
  '61M — ORTHOPEDIC SURGEON',
  '61N — FLIGHT SURGEON',
  '61P — PHYSIATRIST',
  '61Q — RADIATION ONCOLOGIST',
  '61R — DIAGNOSTIC RADIOLOGIST',
  '61U — PATHOLOGIST',
  '61W — VASCULAR SURGEON',
  '61Z — NEUROSURGEON',
  '62A — EMERGENCY PHYSICIAN',
  '62B — FIELD SURGEON',
  '63A — GENERAL DENTIST',
  '63B — COMPREHENSIVE DENTIST',
  '63D — PERIODONTIST',
  '63E — ENDODONTIST',
  '63F — PROSTHODONIST',
  '63H — PUBLIC HEALTH DENTIST',
  '63K — PEDIATRIC DENTIST',
  '63M — ORTHODONTIST',
  '63N — ORAL AND MAXILLOFACIAL SURGEON',
  '63P — ORAL PATHOLOGIST',
  '63R — EXECUTIVE DENTIST',
  '640A — FOOD SAFETY',
  '64A — FIELD VETERINARY SERVICE',
  '64B — VETERINARY PREVENTIVE MED',
  '64C — VETERINARY LABORATORY ANIMAL MED',
  '64D — VETERINARY PATHOLOGY',
  '64E — VETERINARY COMPARATIVE MED',
  '64F — VETERINARY CLINICAL MED',
  '65A — OCCUPATIONAL THERAPY',
  '65B — PHYSICAL THERAPY',
  '65C — DIETITIAN',
  '65D — PHYSICIAN ASSISTANT',
  '65X —  ALLIED OPS',
  '66B — ARMY PUBLIC HEALTH NURSE',
  '66C — PSYCHIATRIC/MENTAL HEALTH NURSE',
  '66E — PERIOPERATIVE NURSE',
  '66F — NURSE ANESTHETIST',
  '66G — OBSTETRICS AND GYNECO',
  '66H — MEDICAL-SURGICAL NURSE',
  '66N — GENERALIST NURSE',
  '66P — FAMILY NURSE PRACTITIONER',
  '670A — HEALTH SERVICES MAINTENANCE',
  '67A — HEALTH SERVICES',
  '67B — LABORATORY SCIENCES',
  '67C — PREVENTIVE MED SCIENCES',
  '67D — BEHAVIORAL SCIENCES',
  '67E — PHARMACY',
  '67F — OPTOMETRY',
  '67G — PODIATRY',
  '67J — AEROMEDICAL EVACUATION',
  '68A — BIOMEDICAL EQUIPMENT',
  '68D — OPERATING ROOM',
  '68E — DENTAL',
  '68G — PATIENT ADMINISTRATION',
  '68H — OPTICAL LABORATORY',
  '68J — MEDICAL LOGISTICS',
  '68K — MEDICAL LABORATORY',
  '68M — NUTRITION CARE',
  '68P — RADIOLOGY',
  '68Q — PHARMACY',
  '68R — VETERINARY FOOD INSPECTION',
  '68S — PREVENTIVE MED',
  '68T — ANIMAL CARE',
  '68V — RESPIRATORY',
  '68W — HEALTH CARE',
  '68X — BEHAVIORAL HEALTH',
  '68Z — CHIEF MEDICAL NCO',
  '70A — HEALTH CARE ADMINISTRATION',
  '70B — HEALTH SERVICES ADMINISTRATION',
  '70C — HEALTH SERVICES COMPTROLLER',
  '70D — HEALTH SERVICES SYSTEMS MANAGEMENT',
  '70E — PATIENT ADMINISTRATION',
  '70F — HEALTH SERVICES HUMAN RESOURCES',
  '70H — HEALTH SERVICES PLANS & OPS',
  '70K — HEALTH SERVICES MATERIEL',
  '71A — MICROBIOLOGY',
  '71B — BIOCHEMISTRY',
  '71E — CLINICAL LABORATORY',
  '71F — RESEARCH PSYCHOLOGY',
  '72A — NUCLEAR MEDICAL SCIENCE',
  '72B — ENTOMOLOGY',
  '72C — AUDIOLOGY',
  '72D — ENVIRONMENTAL SCIENCE',
  '72E — ENVIRONMENTAL ENGINEER',
  '73A — SOCIAL WORK',
  '73B — CLINICAL PSYCHOLOGY',
  '740A — CBRN',
  '74A — CBRN',
  '74B — CBRN',
  '74C — CBRN',
  '74D — CBRN',
  '79R — RECRUITER NCO',
  '79S — CAREER COUNSELOR',
  '79T — RECRUITING & RETENTION',
  '79V — RETENTION & TRANSITION',
  '880A — MARINE DECK',
  '881A — MARINE ENGINEERING',
  '882A — MOBILITY',
  '88A — TRANSPORTATION',
  '88B — TRAFFIC MANAGEMENT',
  '88C — MARINE AND TERMINAL OPS',
  '88D — MOTOR/RAIL TRANSPORTATION',
  '88H — CARGO',
  '88K — WATERCRAFT OPERATOR',
  '88L — WATERCRAFT ENGINEER',
  '88M — MOTOR TRANSPORT OPERATOR',
  '88N — TRANSPORTATION MGMTCOORDINATOR',
  '88P — RAILWAY EQUIPMENT REPAIRER(RC)',
  '88T — RAILWAY SECTION REPAIRER(RC)',
  '88U — RAILWAY OPS CREWMEMBER(RC)',
  '88Z — TRANSPORTATION SENIOR',
  '890A — AMMUNITION',
  '89A — AMMUNITION STOCK CONTROL & ACCOUNTING',
  '89B — AMMUNITION',
  '89D — EXPLOSIVE ORDNANCE DISPOSAL',
  '89E — EXPLOSIVE ORDNANCE DISPOSAL',
  '90A — LOGISTICS',
  '913A — ARMAMENT SYSTEMS MAINTENANCE',
  '914A — ALLIED TRADES',
  '915A — AUTOMOTIVE MAINTENANCE',
  '915E — SENIOR ORDNANCE LOGISTICS',
  '919A — ENGINEER EQUIPMENT MAINTENANCE',
  '91A — M1 ABRAHMS TANK SYSTEM MAINTAINER',
  '91A — MAINTENANCE & MUNITIONS',
  '91B — WHEELED VEHICLE MECHANIC',
  '91C — UTILITIES EQUIPMENT REPAIRER',
  '91D — POWER-GENERATION EQUIPMENT REPAIRER',
  '91E — ALLIED TRADES',
  '91F — SMALL ARMS/ARTILLERY REPAIRER',
  '91G — FIRE CONTROL REPAIRER',
  '91H — TRACK VEHICLE REPAIRER',
  '91J — QUARTERMASTER & CHEMICAL EQUIPMENT REPAIRER',
  '91K — ARMAMENT REPAIRER',
  '91L — CONSTRUCTION EQUIPMENT REPAIRER',
  '91M — BRADLEY FIGHTING VEHICLE SYSTEM MAINTAINER',
  '91P — ARTILLERY MECHANIC',
  '91W — METAL WORKER',
  '91X — MAINTENANCE SUPERVISOR',
  '91Z — MECHANICAL MAINTENANCE SUPERVISOR',
  '920A — PROPERTY ACCOUNTING',
  '920B — SUPPLY SYSTEMS',
  '921A — AIRDROP SYSTEMS',
  '922A — FOOD SERVICE',
  '923A — PETROLEUM',
  '92A — AUTOMATED LOGISTICAL',
  '92A — QUARTERMASTER',
  '92D — AERIAL DELIVERY',
  '92F — PETROLEUM SUPPLY',
  '92F — PETROLEUM AND WATER',
  '92G — FOOD SERVICE',
  '92L — PETROLEUM LABORATORY',
  '92M — MORTUARY AFFAIRS',
  '92R — PARACHUTE RIGGER',
  '92S — SHOWER/LAUNDRY & CLOTHING REPAIR',
  '92W — WATER TREATMENT',
  '92Y — UNIT SUPPLY',
  '92Z — SENIOR NONCOMISSIONED LOGISTICIAN',
  '948B — ELECTRONIC SYSTEMS MAINTENANCE',
  '948D — ELECTRONIC MISSILE SYSTEMS MAINTENANCE',
  '948E — SENIOR ELECTRONICS MAINTENANCE',
  '94A — LAND COMBAT ELECTRONIC MISSILE SYSTEM REPAIRER',
  '94D — AIR TRAFFIC CONTROL EQUIPMENT REPAIRER',
  '94E — COMSEC REPAIRER',
  '94F — COMPUTER DETECTION SYSTEMS REPAIRER',
  '94H — TMDE MAINTENANCE SUPPORT',
  '94K — APACHE ATTACK HELICOPTER SYSTEMS REPAIRER',
  '94L — AVIONICS EQUIPMENT REPAIRER',
  '94M — RADAR REPAIRER',
  '94P — MULTIPLE LAUNCH ROCKET SYSTEM REPAIRER',
  '94R — AVIONICS & SURVIVABILITY EQUIPMENT REPAIRER',
  '94S — PATRIOT SYSTEM REPAIRER',
  '94T — AVENGER SYSTEM REPAIRER',
  '94W — ELECTRONIC MAINTENANCE CHIEF',
  '94X — SENIOR MISSLE SYSTEMS MAINTAINER',
  '94Y — IFTE OPERATOR & MAINTAINER',
  '94Z — SENIOR ELECTRONIC MAINTENANCE CHIEF'
];

const navyMosValues = [
  'AB — AVIATION BOATSWAINS MATE',
  'ABE — AVIATION BOATSWAINS MATE (LAUNCHING AND RECOVERY EQUIPMENT)',
  'ABF — AVIATION BOATSWAINS MATE (FUELS)',
  'ABH — AVIATION BOATSWAINS MATE (AIRCRAFT HANDLING)',
  'AC — AIR TRAFFIC CONTROLLER',
  'AD — AVIATION MACHINISTS MATE',
  'AE — AVIATION ELECTRICIANS MATE',
  'AG — AEREOGRAPHERS MATE',
  'AM — AVIATION STRUCTURAL MECHANIC',
  'AME — AVIATION STRUCTURAL MECHANIC (SAFETY EQUIPMENT)',
  'AO — AVIATION ORDINANCEMEN',
  'AS — AVIATION SUPPORT EQUIPMENT',
  'AT — AVIATION ELECTRONICS',
  'AW — AVIATION AIRCREWMAN',
  'AWF — AVIATION AIRCREWMAN (MECHANICAL)',
  'AWO — AVIATION AIRCREWMAN (OPERATOR)',
  'AWR — AVIATION AIRCREWMAN (TACTICAL HELICOPTER)',
  'AWS — AVIATION AIRCREWMAN (HELICOPTER)',
  'AWV — AVIATION AIRCREWMAN (AVIONICS)',
  'AZ — AVIATION MAINTENANCE ADMINISTRATIONMAN',
  'BM — BOATSWAINS MATE',
  'BU — BUILDER',
  'CE — CONSTRUCTION ELECTRICIAN',
  'CM — CONSTRUCTION MECHANIC',
  'CMC — COMMAND MASTER CHIEF',
  'CMD — COMMAND MASTER CHIEF PETTY',
  'CS — CULINARY',
  'CTI — CRYPTOLOGIC (INTERPRETIVE)',
  'CTI — CRYPTOLOGIC (MAINTENANCE)',
  'CTI — CRYPTOLOGIC (COLLECTION)',
  'CTI — CRYPTOLOGIC (TECHNICAL)',
  'CTI — CRYPTOLOGIC (NETWORK)',
  'DC — DAMAGE CONTROLMAN',
  'EA — ENGINEERING AIDS',
  'EM — ELECTRICIANS MATE',
  'EN — ENGINEMAN',
  'EO — EQUIPMENT OPERATOR',
  'EOD — EXPLOSIVE ORDNANCE DISPOSAL/DIVER',
  'ET — ELECTRONICS',
  'FC — FIRE CONTROLMAN',
  'FLT — FLEET MASTER CHIEF PETTY',
  'FOR — FORCE MEASTER CHIEF PETTY OFFIECER',
  'FT — FIRE CONTROL',
  'GM — GUNNERS MATE',
  'GS — GAS TURBINE SYSTEM',
  'GSE — GAS TURBINE SYSTEM (ELECTRICAL)',
  'GSM — GAS TURBINE SYSTEM (MECHANICAL)',
  'HM — HOSPITAL CORPSMAN',
  'HT — HULL MAINTENANCE',
  'IC — INTERIOR COMMUNICATIONS ELECTRICIAN',
  'IS — INTEL',
  'IT — INFOS SYSTEMS',
  'LN — LEGALMAN',
  'LS — LOGISTICS',
  'MA — MASTER-AT-ARMS',
  'MC — MASS COMMUNICATIONS',
  'MCN — MASTER CHIEF PETTY  OF THE NAVY',
  'MN — MINEMAN',
  'MN — MACHIISTS MATE',
  'MR — MACHINERY REPAIRMAN',
  'MT — MISSLE',
  'MU — MUSICIAN',
  'NC — NAVY COUNSELOR',
  'ND — NAVY DIVER',
  'OS — OPS',
  'PR — AIRCRAFT SURVIVAL EQUIPMENTMAN',
  'PS — PERSONNEL',
  'QM — QUARTERMASTER',
  'RP — RELIGIOUS PROGRAMS',
  'SB — SPECIAL OPS BOATS',
  'SH — SHIPS SERVICEMEMBER',
  'SO — SPECIAL OPS',
  'STG — SONAR (SURFACE)',
  'STS — SONAR (SUBMARINE)',
  'SW — STEELWORKER',
  'UT — UTILITIESMAN',
  'YN — YEOMAN'
];

const marineCorpsMosValues = [
  '01XX — PERSONNEL AND ADMINISTRATION',
  '02XX — INTEL',
  '03XX — INFANTRY',
  '04XX — LOGISTICS',
  '05XX — MARINE AIR GROUND TASK FORCE (MAGTF) PLANS',
  '06XX — COMMUNICATIONS',
  '08XX — FIELD ARTILLERY',
  '09XX — TRAINING',
  '11XX — UTILITIES',
  '13XX — ENGINEER, CONSTRUCTION, FACILITIES, AND EQUIPMENT',
  '18XX — TANK AND ASSAULT AMPHIBIOUS VEHICLE',
  '21XX — GROUND ORDNANCE MAINTENANCE',
  '2300 — EOD',
  '2305 — EOD',
  '2311 — EOD',
  '26XX — SIGNALS INTEL/GROUND ELECTRONIC WARFARE',
  '27XX — LINGUIST',
  '28XX — GROUND ELECTRONICS MAINTENANCE',
  '30XX — SUPPLY ADMINISTRATION AND OPS',
  '31XX — DISTRIBUTION MANAGEMENT',
  '33XX — FOOD SERVICE',
  '34XX — FINANCIAL MANAGEMENT',
  '35XX — MOTOR TRANSPORT',
  '41XX — MARINE CORPS COMMUNITY SERVICES',
  '43XX — PUBLIC AFFAIRS',
  '44XX — LEGAL SERVICES',
  '46XX — COMBAT CAMERA (COMCAM)',
  '48XX — RECRUITING AND RETENTION',
  '55XX — MUSIC',
  '57XX — CBRN',
  '58XX — MILITARY POLICE',
  '59XX — ELECTRONICS MAINTENANCE',
  '60XX — AIRCRAFT MAINTENANCE',
  '61XX — AIRCRAFT MAINTENANCE (ROTARY-WING)',
  '62XX — AIRCRAFT MAINTENANCE (FIXED-WING)',
  '63XX — ORGANIZATIONAL AVIONICS MAINTENANCE',
  '64XX — INTERMEDIATE AVIONICS MAINTENANCE',
  '65XX — AVIATION ORDNANCE',
  '66XX — AVIATION LOGISTICS',
  '68XX — METEOROLOGY AND OCEANOGRAPHY (METOC)',
  '70XX — AIRFIELD SERVICES',
  '72XX — AIR CONTROL/AIR SUPPORT/ANTIAIR WARFARE/AIR',
  '73XX — NAVIGATION /ENLISTED FLIGHT CREWS',
  '75XX — PILOTS/NAVAL FLIGHT S'
];

const airForceMosValues = [
  '10C0 — OPS Commander',
  '11AX — Airlift Pilot',
  '11BX — Bomber Pilot',
  '11EX — Test Pilot',
  '11FX — Fighter Pilot',
  '11GX — Generalist Pilot',
  '11HX — Helicopter Pilot',
  '11KX — Trainer Pilot',
  '11RX — Recce/Surv/Elect Warfare Pilot',
  '11SX — Special OPS Pilot',
  '11TX — Tanker Pilot, C-12 CTP',
  '12AX — Airlift Navigator',
  '12BX — Bomber Navigator',
  '12EX — Test Navigator',
  '12FX — Fighter Navigator',
  '12GX — Generalist Navigator',
  '12KX — Trainer Navigator',
  '12RX — Recce/Surv/Elect Warfare Navigator',
  '12SX — Special OPS Navigator',
  '12TX — Tanker Navigator',
  '13AX — Astronaut',
  '13BX — Air Battle Manager',
  '13DX — Combat Control',
  '13MX — Airfield Operation',
  '13SX — Space & Missile',
  '14NX — INTEL',
  '15WX — Weather',
  '16FX — Foreign Area',
  '16GX — Air Force OPS Staff',
  '16PX — Intl Politico-Military Affairs',
  '16RX — Planning & Programming',
  '20C0 — Logistics Commander',
  '21LX — Logistician',
  'H21BX — Maintenance',
  '21MX — Munitions and Missile Maintenance',
  'H21RX — Logistics Readiness',
  '30C0 — Support Commander',
  '31PX — Security Forces',
  '32EX — Civil Engineer',
  '33C0 — Communications Commander',
  '33SX — Communications and INFO',
  '34MX — Services',
  '35BX — Band',
  '35PX — Public Affairs',
  '36MX — Mission Support',
  '36PX — Personnel',
  '38MX — Manpower',
  '40C0 — Medical Commander',
  '41AX — Health Services Administrator',
  '42BX — Physical Therapist',
  '42EX — Optometrist',
  '42FX — Podiatrist',
  '42GX — Physician Assistant',
  '42NX — Audiology/Speech Pathologist',
  '42PX — Clinical Psychologist',
  '42SX — Clinical Social Worker',
  '42TX — Occupational Therapist',
  '43AX — Aerospace Physiologist',
  '43BX — Biomedical Scientist',
  '43DX — Dietitian',
  '43EX — Bioenvironmental Engineer',
  '43HX — Public Health',
  '43MX — Medical Entomologist',
  '43PX — Pharmacist',
  '43TX — Biomedical Laboratory',
  '43VX — Veterinary Clinician',
  '43YX — Health Physicist',
  '44AX — Chief, Hospital/Clinic Services',
  '44BX — Preventive MED',
  '44DX — Pathologist',
  '44EX — Emergency Services Physician',
  '44FX — Family Physician',
  '44GX — General Practice Physician',
  '44HX — Nuclear MED Physician',
  '44JX — Clinical Geneticist',
  '44KX — Pediatrician',
  '44MX — Internist',
  '44NX — Neurologist',
  '44PX — Psychiatrist',
  '44RX — Diagnostic Radiologist',
  '44SX — Dermatologist',
  '44TX — Radiotherapist',
  '44UX — Occupational MED',
  '44YX — Critical Care MED',
  '44ZX — Allergist',
  '45AX — Anesthesiologist',
  '45BX — Orthopedic Surgeon',
  '45EX — Ophthalmologist',
  '45GX — OB/GYN',
  '45NX — Otorhinolaryngologist',
  '45PX — Physical MED Physician',
  '45SX — Surgeon',
  '45UX — Urologist',
  '46AX — Nurse Administrator',
  '46FX — Flight Nurse',
  '46GX — Nurse-Midwife',
  '46MX — Nurse Anesthetist',
  '46NX — Clinical Nurse',
  '46PX — Mental Health Nurse',
  '46SX — Operating Room Nurse',
  '47BX — Orthodontist',
  '47DX — Oral and Maxillofacial Pathologist',
  '47EX — Endodontist',
  '47GX — Dentist',
  '47HX — Periodontist',
  '47KX — Pediatric Dentist',
  '47PX — Prosthodontist',
  '47SX — Oral Surgeon',
  '48AX — Aerospace MED',
  '48GX — General Medical (GMO)',
  '48RX — Residency Trained Flight Surgeon',
  '48VX — Pilot-Physician',
  '51JX — Judge Advocate',
  '52RX — Chaplain',
  '60C0 — Program Director',
  '61SX — Scientist',
  '62EX — Developmental Engineer',
  '63AX — Acquisition Manager',
  '64PX — Contracting',
  '65AX — Auditor',
  '65FX — Financial Management',
  '65WX — Cost Analysis',
  '71SX — Special Investigator',
  '80C0 — Commander, Cadet Squadron, USAFA',
  '81C0 — Training Commander, OTS',
  '81T0 — Instructor',
  '82A0 — Academic Program Manager',
  '83R0 — Recruiting Service',
  '84H0 — Historian',
  '85G0 — USAF Honor Guard',
  '86M0 — OPS Management',
  '86P0 — Command and Control',
  '87G0 — Inspector General',
  '88A0 — Aide-de-Camp',
  '88P0 — Protocol',
  '90G0 — General',
  '91C0 — Commander',
  '91W0 — Wing Commander',
  '92J0 — Nondesignated Lawyer',
  '92J1 — AFROTC Educational Delay-Law Student',
  '92J2 — Funded Legal Ed Program Law Student',
  '92J3 — Excess Leave Law Student',
  '92M0 — Heath Professions Scholarship Program (HPSP) Medical Student',
  '92M1 — Uniformed Services University of Health Sciences Student',
  '92M2 — HPSP Biomedical Science Student',
  '92R0 — Chaplain Candidate',
  '92S0 — Student  Authorization',
  '92T0 — Pilot Trainee',
  '92T1 — Navigator Trainee',
  '94N0 — Nuclear Weapons Custodian'
],

coastGuardMosValues = [
  'AMT — AVIATION MAINTENANCE',
  'AET — AVIATION ELECTRICAL',
  'AST — AVIATION SURVIVAL',
  'HS — HEALTH SERVICES',
  'MST — MARINE SCIENCES',
  'MU — MUSICIAN',
  'PA — PUBLIC AFFAIRS',
  'SK — STORE KEEPER',
  'FS — FOOD SERVICE',
  'YN — YEOMAN',
  'BM — BOATSWAINES MATE',
  'GM — GUNNERS MATE',
  'OS — OPS',
  'IS — INTELLIGENCE',
  'DC — DAMAGE CONTROLMAN',
  'EM — ELECTRICIANS MATE',
  'ET — ELECTRONICS',
  'MK — MACHINERY',
  'IT — INFO SYSTEMS',
  'PS — PORT SECURITYMAN',
  'IV — INVESTIGATOR'
],

spaceForceMosValues = [
  '13S - Space operations officer',
  '13SA - Orbital warfare officer',
  '13SB - Space electronic warfare officer',
  '13SD - Space battle management officer',
  '13SE - Space access and sustainment officer',
  '14N - Intelligence officer',
  '17 - Cyberspace warfare operations officer',
  '17C - Cyberspace warfare operations commander',
  '17D - Warfighter communications operations officer',
  '17S - Cyberspace effects operations officer',
  '1C6 - Space systems operations specialist',
  '1N - Intelligence specialist',
  '1N0 - All source intelligence analyst',
  '1N1 - Geospatial intelligence analyst',
  '1N2 - Signals intelligence analyst',
  '1N4 - Fusion analyst',
  '1N8 - Targeting analyst',
  '3D - Cyberspace support specialist',
  '3D0 - Cyberspace operations specialist',
  '3D1 - Cyberspace support specialist',
  '62 - Developmental engineer field',
  '62E - Developmental engineer',
  '62S - Materiel leader',
  '63 - Acquisition field',
  '63A - Acquisitions manager',
  '63G - Senior materiel leader-lower echelon',
  '63S - Materiel leader'
];

const navyMosOptions = navyMosValues.map(value => ({value, label: value})),
      armyMosOptions = armyMosValues.map(value => ({value, label: value})),
      marineCorpsMosOptions = marineCorpsMosValues.map(value => ({value, label: value})),
      airForceMosOptions = airForceMosValues.map(value => ({value, label: value})),
      coastGuardMosOptions = coastGuardMosValues.map(value => ({value, label: value})),
      spaceForceMosOptions = spaceForceMosValues.map(value => ({value, label: value}));

export const getMosOptions = branchValue => {
  switch(branchValue) {
    case 'Air Force':
      return airForceMosOptions;
    case 'Army':
      return armyMosOptions;
    case 'Coast Guard':
      return coastGuardMosOptions;
    case 'Marines':
      return marineCorpsMosOptions;
    case 'Navy':
      return navyMosOptions;
    case 'Space Force':
      return spaceForceMosOptions;
  }
}
