import React, { useEffect } from 'react';
import { hot,
         setConfig }        from 'react-hot-loader';
import { Provider }         from 'react-redux';
import { Helmet }           from 'react-helmet';
import { compose }          from 'recompose';
import { ConnectedRouter }  from 'connected-react-router';
import store, { history }   from 'store';
import { withModal }        from 'theme/utils/recompose';
import Toast                from 'theme/Toast';
import Modal                from 'theme/Modal';
import VetitLogo            from 'theme/VetitLogo';
import useIsIOS             from 'hooks/useIsIOS';

import share                from './apple-share.png';

import './Main.sass';

setConfig({ pureSFC: true });

const App = compose(
  withModal(),
)(({ routes, modal, closeModal, openModal }) => {
  const { prompt } = useIsIOS();

  useEffect(() => {
    if (prompt) openModal();
  }, [prompt]);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div className="Main">
          <div className="Main__content">
            <Helmet>
              <title>Vetit</title>
            </Helmet>
            {routes}
            <Toast />
            <Modal
              isOpen={modal.visible}
              className="Main__modal"
              close={closeModal}
              closeBtn
            >
              <div className="Main__logoContainer">
                <VetitLogo size="M" />
              </div>
              <h4 className="Main__addToHomeScreen">Add to Home Screen</h4>
              <p className="secondary-text">Add this app to your home<br />screen for quick and easy access</p>
              <span className="secondary-text">
                Tap
                <img
                  src={share}
                  alt="Add to homescreen"
                  height="20"
                  width="20"
                />
                then &quot;Add to Home Screen&quot;
              </span>
            </Modal>
          </div>
        </div>
      </ConnectedRouter>
    </Provider>
  );
})

export default hot(module)(App);
