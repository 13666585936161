import React                        from 'react';
import { connect }                  from 'react-redux';
import { reduxForm,
         Field,
         FieldArray,
         Fields,
         formValueSelector }        from 'redux-form';
import cx                           from 'classnames';
import { compose,
         withHandlers,
         setDisplayName }           from 'recompose';
import { TextField,
         Radio,
         RadioLabels,
         Datepicker,
         StaticField,
         MultipleField,
         Creatable,
         ArrayField }               from 'theme/Form';
import Modal                        from 'theme/Modal';
import VerificationLabel            from 'theme/VerificationLabel';
import { required }                 from 'theme/utils/validate';
import { withModal,
         withStaticFormSections }   from 'theme/utils/recompose';
import { constants }                from 'theme/utils/constants';
import { options, env }             from 'theme/content';
import { getMosOptions }            from 'theme/content/mosOptions';
import { sendVerificationEmail }    from 'user/actions';

import VerifyForm                   from './VerifyForm';

import './PersonalInformationForm.sass';

const BasicDataFields = ({isEmployer, children}) => (
  <>
    <div className="withEdit">
      <Field
        name="firstName"
        label="First name"
        required
        validate={required}
        placeholder=""
        component={TextField}
        size="medium"
      />
      <div className="withEdit--desktop">{children}</div>
    </div>
    <Field
      name="lastName"
      label="Last name"
      required
      validate={required}
      placeholder=""
      component={TextField}
      size="medium"
    />
    {!isEmployer &&
      <>
        <Field
          name="userCategory"
          label="Affiliation —"
          required
          validate={required}
          component={RadioLabels}
          options={options.identificationOptions}
        />
        <Field
          name="earliestAvailability"
          label="Availability Date"
          required
          validate={required}
          placeholder={env.DATE_FORMAT}
          component={Datepicker}
        />
        <Field
          name="eas"
          label="End of Active Service Date (EAS)"
          placeholder={env.DATE_FORMAT}
          component={Datepicker}
        />
      </>
    }
    <div className="withEdit--mobile">{children}</div>
  </>
)

const ServiceDataField = ({isOnboarding}) => (
  <FieldArray
    name="militaryServices"
    className={cx("PersonalInformationForm__serviceDataField", {isOnboarding})}
    component={ArrayField}
    buttonText="Add military experience"
    firstFieldVisibleByDefault={isOnboarding}
  >
    <Fields
      names={['serviceBranch', 'mos', 'etsPaygrade']}
      component={MultipleField}
      components={['radioLabels', 'select', 'select']}
      options={options.paygradeOptions}
      labelOptions={options.branchRange.slice(0, -1)}
      keyName="militaryServices"
      keys={['serviceBranch', 'mos', 'etsPaygrade']}
      labels={['Service branch', 'Military occupation', 'Highest paygrade attained']}
      placeholders={['', 'Select', 'Select']}
      getMosOptions={getMosOptions}
      mosOptionsField="mos"
      column
    />
  </FieldArray>
)

const PersonalInformationForm = connect(
  (state, {form}) => ({
    remoteOnlyValue: formValueSelector(form)(state, 'remoteOnly')
  })
)(compose(
  setDisplayName('PersonalInformationForm'),
  withModal(),
  withStaticFormSections(),
  withHandlers({
    generateSecret: () => () => {
      return new Array(constants.SECRET_SIGN_LENGTH)
        .join()
        .replace(/(.|$)/g, () => ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? 'toString' : 'toUpperCase']());
    },
    sendVerificationEmail: ({sendVerificationEmail, setSubmitted, setMessage, user, closeModal}) => values => {
      return sendVerificationEmail(user, values)
      .then(() => {
        setSubmitted(true);
        closeModal();
        setMessage('Please check the provided .mil address inbox for further instructions.');
      })
    }
  })
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, invalid, submitting, remoteOnlyValue, isEmployer, className, getLocations, toggleEditedSections, isEdited, initialValues, isSettings, isOnboarding, modal, openModal, closeModal, generateSecret, sendVerificationEmail, uploadProps}) => {
    return (
      <div className="PersonalInformationForm">
        <form onSubmit={handleSubmit} className="PersonalInformationForm__form">
          <div className={cx("PersonalInformationForm__basicData form-container-section", className)}>
            {isOnboarding &&
              <BasicDataFields isEmployer={isEmployer} />
            }
            {isSettings &&
              <div className="PersonalInformationForm__basicData--static">
                {isEdited('basicData')
                  ?
                    <BasicDataFields isEmployer={isEmployer}>
                      <button
                        type="submit"
                        className="btn-secondary"
                        disabled={isSettings ? submitting : (pristine || submitting)}
                      >
                        Save
                      </button>
                    </BasicDataFields>
                  :
                    <>
                      <div className="PersonalInformationForm__basicData--staticWrapper">
                        <div className="withEdit">
                          <Field
                            name="firstName"
                            label="First name"
                            required
                            placeholder=""
                            component={StaticField}
                            size="medium"
                          />
                          <span className="edit-btn btn-secondary-outlined" onClick={() => toggleEditedSections('basicData')}>Edit</span>
                        </div>
                        <Field
                          name="lastName"
                          label="Last name"
                          required
                          placeholder=""
                          component={StaticField}
                          size="medium"
                        />
                      </div>
                      {!isEmployer &&
                        <>
                          <Field
                            name="userCategory"
                            label="Affiliation —"
                            required
                            validate={required}
                            component={RadioLabels}
                            options={options.identificationOptions}
                            isStatic
                          />
                          <Field
                            name="eas"
                            label="End of Active Service Date (EAS)"
                            placeholder={env.DATE_FORMAT}
                            component={Datepicker}
                            isStatic
                          />
                        </>
                      }
                    </>
                }
              </div>
            }
          </div>
          {!isEmployer &&
            <>
              <div className="form-container-section">
                <h5 className="subheader">Identity Verification</h5>
                {initialValues.verified &&
                  <VerificationLabel />
                }
                {Boolean(initialValues.proofFile && !initialValues.verified) &&
                  <VerificationLabel inProgress />
                }
                {Boolean(!initialValues.proofFile && !initialValues.verified) &&
                  <>
                    <p className="form-info">Verify your military affiliation to gain full access to the platform.</p>
                    <span className="btn-secondary" onClick={() => openModal('verify')}>Verify</span>
                  </>
                }
              </div>
              <div className="PersonalInformationForm__serviceData form-container-section">
                <div className="PersonalInformationForm__serviceDataHeading form-container-section-heading">
                  <h5 className="subheader">Service Data</h5>
                  {isSettings && isEdited('serviceData') &&
                    <button
                      type="submit"
                      className="btn-secondary"
                      disabled={isSettings ? submitting : (pristine || submitting)}
                    >
                      Save
                    </button>
                  }
                  {isSettings && !isEdited('serviceData') &&
                    <span className="edit-btn btn-secondary-outlined" onClick={() => toggleEditedSections('serviceData')}>Edit</span>
                  }
                </div>
                {isOnboarding &&
                  <ServiceDataField isOnboarding={isOnboarding} />
                }
                {isSettings &&
                  <div>
                    {isEdited('serviceData')
                      ? <ServiceDataField isOnboarding={isOnboarding} />
                      : <>
                          {initialValues.militaryServices && initialValues.militaryServices.map((s, i) => (
                            <div key={i} className="PersonalInformationForm__serviceData--staticItem">
                              <div>
                                <span className="form-label">Service branch</span>
                                <span>{s.serviceBranch}</span>
                              </div>
                              <div>
                                <span className="form-label">Military occupation</span>
                                <span>{s.mos}</span>
                              </div>
                              <div>
                                <span className="form-label">Highest paygrade attained</span>
                                <span>{s.etsPaygrade}</span>
                              </div>
                              </div>
                            ))}
                        </>
                    }
                  </div>
                }
              </div>
              {isSettings &&
                <>
                  <div className="form-container-section">
                    <h5 className="subheader">Security Clearance</h5>
                    <p className="form-info">The background checks you’ve been through and the security clearances you’ve gained are valuable to you as a civilian. Many goverment agencies and contractors require them, and they can be the difference in getting hired. Even if you aren’t interested in federal or contracting work currently, you may be in the future.</p>
                    <div className="PersonalInformationForm__clearance">
                      <Field
                        name="securityClearance.clearance"
                        label="My clearance"
                        component={RadioLabels}
                        options={options.clearanceOptions}
                      />
                      <Field
                        name="securityClearance.clearanceExpiration"
                        label="Expiration Date"
                        placeholder={env.DATE_FORMAT}
                        component={Datepicker}
                      />
                    </div>
                  </div>
                  <div className="PersonalInformationForm__geoPreferences form-container-section">
                    <h5 className="subheader">Geographical Preferences</h5>
                    <p className="form-info">Places you’d like to live, work and/or go to school.</p>
                    <Field
                      name="remoteOnly"
                      label="Remote only"
                      mode="checkbox"
                      component={Radio}
                    />
                    {!remoteOnlyValue &&
                      <Field
                        name="locations"
                        label="Location"
                        placeholder="Start typing a location name..."
                        component={Creatable}
                        getAutocomplete={getLocations}
                        isClearable={false}
                        isMulti
                        async
                      />
                    }
                  </div>
                </>
              }
              <button
                type="submit"
                className="PersonalInformationForm__save btn-primary"
                disabled={pristine || submitting || invalid}
              >
                {isSettings ? 'Save' : 'Save & Continue'}
              </button>
            </>
          }
        </form>

        <Modal
          isOpen={modal.visible}
          className="PersonalInformationForm__modal"
          close={closeModal}
        >
          <VerifyForm
            form="VerifyForm"
            generateSecret={generateSecret}
            onSubmit={sendVerificationEmail}
            uploadProps={uploadProps}
          />
        </Modal>
      </div>
    )
  }
)));

export default connect(
  null,
  dispatch => ({sendVerificationEmail: (user, values) => dispatch(sendVerificationEmail(user.id, values))})
)(PersonalInformationForm);
