import React, { useRef, useState } from 'react';
import { reduxForm, Field }        from 'redux-form';
import { TextField,
         UploadBase64 }            from 'theme/Form';
import MoreInfo                    from 'theme/MoreInfo';
import { copy }                    from 'theme/content';
import { required }                from 'theme/utils/validate';

import './InternalApplicationForm.sass';


const validate = values => {
  const errors = {};

  if(!values.firstName) {
      errors.firstName = 'Required'
  }
  if(!values.lastName) {
      errors.lastName = 'Required'
  }
  if(!values.email) {
      errors.email = 'Required'
  }
  return errors;
};

export default reduxForm({
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, submitting, invalid, uploadProps, close}) => {
    const [scrolledDown, setScrollDown] = useState(false);
    const innerRef = useRef();

    const onScroll = () => {
      if (innerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setScrollDown(true);
        } else setScrollDown(false)
      }
    };

    return (
      <div className="InternalApplicationForm">
        <h3>Send my application</h3>
        <form
          ref={innerRef}
          onSubmit={handleSubmit}
          onScroll={() => onScroll()}
        >
          <h5 className="subheader">Contact details</h5>
          <Field
            name="firstName"
            label="First name"
            placeholder="Enter company name..."
            component={TextField}
            validate={required}
            required
          />
          <Field
            name="lastName"
            label="Last name"
            component={TextField}
            validate={required}
            required
          />
          <Field
            name="email"
            label="Email"
            component={TextField}
            validate={required}
            required
          />
          <Field
            name="phone"
            label="Phone number"
            placeholder="Type your contact number"
            component={TextField}
          />
          <div className="InternalApplicationForm__documents">
            <h5 className="subheader">
              Employment documents
              <MoreInfo text={copy.applicationCoverLetter} />
            </h5>
            <Field
              name="resumes"
              component={UploadBase64}
              {...uploadProps}
            />
          </div>
          <div className="InternalApplicationForm__actions">
            <button
              type="button"
              className="btn-primary-outlined"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-primary"
              disabled={submitting || invalid}
            >
              Apply
            </button>
          </div>
        </form>
        {!scrolledDown &&
          <div className="overlay" />
        }
      </div>
    )
  }
)
