import React                 from 'react';
import { connect }           from 'react-redux';
import { withRouter }        from 'react-router';
import { Link }              from 'react-router-dom';
import { Helmet }            from 'react-helmet';
import { lifecycle,
         compose,
         withState,
         withProps,
         withHandlers,
         setDisplayName }    from 'recompose';
import cx                    from 'classnames';
import Footer                from 'theme/Footer';
import Header                from 'theme/Header';
import Modal                 from 'theme/Modal';
import Loader                from 'theme/Loader';
import MessageSection        from 'theme/MessageSection';
import Tabs                  from 'theme/Tabs';
import { withScroll,
         withSignature,
         withModal }         from 'theme/utils/recompose';
import { getProfile,
         updateProfile,
         updateUserProfile,
         deleteAccount }     from 'user/actions';
import { logout,
         resetPassword }     from 'auth/actions';

import PrivacySettingsForm   from '../Forms/PrivacySettingsForm';
import GeneralForm           from '../Forms/GeneralForm';
import ChangePasswordForm    from '../Forms/ChangePasswordForm';

import './AccountSettings.sass';


const links = [
  {name: 'general', label: 'General'},
  {name: 'privacy', label: 'Privacy'},
  {name: 'delete-account', label: ''}
];

const employerLinks = [
  {name: 'general', label: 'General'},
  {name: 'delete-account', label: ''}
];

const GeneralFormName = "GeneralForm";
const PrivacySettingsFormName = "PrivacySettingsForm";
const ChangePasswordFormName = "ChangePasswordForm";

const AccountSettings = compose(
  setDisplayName('AccountSettings'),
  withState('submitted', 'setSubmitted', false),
  withScroll(),
  withModal(),
  withSignature(),
  withHandlers({
    submit: ({submit, user}) => values => {
      return submit(user, values);
    },
    resetPassword: ({resetPassword, closeModal}) => values => {
      return resetPassword(values)
      .then(() => closeModal());
    },
    deleteAccount: ({deleteAccount, user, setSubmitted}) => () => {
      return deleteAccount(user)
      .then(() => setSubmitted(true));
    }
  }),
  withProps(({match}) => ({
    section: match.params.section
  })),
  lifecycle({
    componentDidMount() {
      this.props.getProfile(this.props.user);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.section !== this.props.section)
        window.scrollTo(0, 0);
    }
  })
)(({
  submit,
  section,
  modal,
  openModal,
  closeModal,
  loadingProfile,
  loadingUserProfile,
  profile,
  user,
  userProfile,
  userSignature,
  submitted,
  logout,
  deleteAccount,
  resetPassword
}) => {
  const title = section === 'general'
    ? 'Account settings'
    : (section === 'privacy' ? 'Privacy settings' : 'You are about to delete your account');

  return (
    <div className="AccountSettings page">
      <Helmet>
        <title>Vetit – Account Settings</title>
      </Helmet>
      <Header />
      {submitted
        ?
          <MessageSection
            title="Thanks for staying with us!"
            text="Your account has been deleted."
          >
            <button
              className="btn-primary"
              onClick={() => logout()}
            >
              Return to homepage
            </button>
          </MessageSection>
        :
        <>
          <div className={cx("container card", {delete: section === 'delete-account'})}>
            <h1 className={cx("AccountSettings__title")}>{title}</h1>
            <Tabs
              links={(user.organizationId && !user.isAdmin) ? employerLinks : links}
              section={section}
              loading={loadingProfile}
              mobileTitle={title}
            >
              {section === 'general' &&
                <div>
                  <Loader loading={loadingProfile} />
                  <GeneralForm
                    initialValues={profile}
                    onSubmit={submit}
                    form={GeneralFormName}
                    openModal={openModal}
                    isEmployerAdmin={Boolean(user.organizationId)}
                  />
                </div>
              }
              {section === 'privacy' &&
                <div>
                  <Loader loading={loadingUserProfile} />
                  <PrivacySettingsForm
                    initialValues={userProfile}
                    onSubmit={submit}
                    form={PrivacySettingsFormName}
                    signature={userSignature}
                    isSettings
                  />
                </div>
              }
              {section === 'delete-account' &&
                <div className="AccountSettings__delete">
                  <h4 className="AccountSettings__deleteMobileTitle">You are about to delete your account</h4>
                  <p>Deleting your account will remove all of your profile information and data associated with it. This cannot be undone.</p>
                  <p>Maybe we can help you in some other way? Contact us at <a href="mailto:admin@vetitforward.com">admin@vetitforward.com</a></p>
                  <div>
                    <span className="form-label">Are you sure you want to delete your account?</span>
                    <Link className="btn-primary" to="/account-settings/general">Cancel</Link>
                    <button className="btn-primary-outlined" onClick={() => deleteAccount()}>Yes, delete my account</button>
                  </div>
                </div>
              }
            </Tabs>
          </div>
          <Footer />
        </>
      }
      <Modal
        isOpen={modal.visible}
        close={closeModal}
      >
        <ChangePasswordForm
          onSubmit={resetPassword}
          form={ChangePasswordFormName}
        />
      </Modal>
    </div>
  )
});

const mapStateToProps = ({auth, data, loading}) => ({
  user: auth.user,
  profile: data?.profile || {},
  userProfile: data?.user_profile || {},
  loadingProfile: loading.profile,
  loadingUserProfile: loading.user_profile
});

const mapDispatchToProps = (dispatch, {match: params}) => ({
  logout: () => dispatch(logout()),
  getProfile: (user) => new Promise((resolve, reject) => dispatch(getProfile(user.id, {resolve, reject}))),
  deleteAccount: (user) => dispatch(deleteAccount(user.id)),
  resetPassword: (values) => dispatch(resetPassword(values)),
  submit: (user, values) => params.params.section === 'general'
    ? dispatch(updateProfile(user.id, values))
    : dispatch(updateUserProfile(user.id, values))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings));
