import React from 'react';
import cx    from 'classnames';

import './Thumbnail.sass';

const setThumbnail = post => post.video ? post.video.thumbnail : (post.images ? post.images[0] : null);
const setIcon = post => post.video ? 'file-video-o' : (post.images ? 'picture-o' : null);

export default function Thumbnail({item, openGallery, className}) {
  const { images, video } = item;
  const moreCount = images && images.length - 1;
  return (
    <div
      className={cx("Thumbnail", className, {hasThumbnail: video || images })}
      style={{backgroundImage: `url(${setThumbnail(item)})` }}
    >
      {images &&
        <div
          className="image"
          onClick={images && (() => openGallery(0))}
        />
      }
      {video &&
        <a
          className="video"
          href={video.url}
          target="_blank"
        />
      }
      <div className="overlay">
        <div className="overlay__content">
          <span className={`fa fa-${setIcon(item)}`} />
          {images && images.length > 1 &&
            <span className="more">+{moreCount}</span>
          }
        </div>
      </div>
    </div>
  )
}
