import React                     from 'react';
import { connect }               from 'react-redux';
import { Helmet }                from 'react-helmet';
import { withRouter }            from 'react-router';
import { Link }                  from 'react-router-dom';
import { compose,
         lifecycle,
         setDisplayName,
         withState,
         withProps,
         withHandlers }          from 'recompose';
import cx                        from 'classnames';
import { parse }                 from 'qs';
import Footer                    from 'theme/Footer';
import Header                    from 'theme/Header';
import Modal                     from 'theme/Modal';
import Loader                    from 'theme/Loader';
import Return                    from 'theme/Return';
import ScrollingBar              from 'theme/ScrollingBar';
import { withModal,
         withScroll,
         withScreenWidthDetection,
         withSignature }         from 'theme/utils/recompose';
import { constants }             from 'theme/utils/constants';
import { images }                from 'theme/img/images';
import Feed                      from 'feed/Feed';
import Post                      from 'comments/Post';
import AskQuestion               from 'comments/AskQuestion';
import { getComments,
         getTopics,
         getTopic,
         getPost,
         voteComment,
         getAutocomplete,
         follow,
         unfollow,
         followTopic,
         unfollowTopic,
         addComment,
         updateComment }         from 'comments/actions';

import TopicsList                from './TopicsList';

import './Community.sass';

const SORT_OPTIONS = [
  {direction: 'd', value: 'createdAt', label: 'Newest'},
  {direction: 'd', value: 'popularity', label: 'Most popular'},
  {direction: 'd', value: 'recentlyCommented', label: 'Recently commented'}
];

const modalNames = {
  NEW_QUESTION: 'NEW_QUESTION',
  EDIT_QUESTION: 'EDIT_QUESTION'
};

const modalTitles = {
  NEW_QUESTION: 'Ask a question',
  EDIT_QUESTION: 'Edit your question'
};

const AskQuestionBtn = ({user, openModal, history}) => (
  <button
    className="btn-primary"
    onClick={() => user
      ? openModal(modalNames.NEW_QUESTION)
      : history.push('/auth/signup')
    }
  >
    Ask a question
  </button>
);

const Community = compose(
  setDisplayName('Community'),
  withModal(),
  withScroll(),
  withSignature(),
  withScreenWidthDetection(),
  withState('sortOption', 'setSortOption', SORT_OPTIONS[0]),
  withState('query', 'setQuery', ''),
  withProps(({match}) => ({
    topicId: match.params.topicId,
    postId: match.params.postId
  })),
  withHandlers({
    getData: ({getComments, loadingPosts, topicId, query}) => () => {
      const params = topicId
      ? {page: 0, pageSize: constants.COMMENT.PAGE_SIZE, topicId, loader: loadingPosts, query}
      : {page: 0, pageSize: constants.COMMENT.PAGE_SIZE, loader: loadingPosts, query};

      return getComments(params);
    },
    getPost: ({getPost, getTopic, getComments}) => (postId, loader = true) => {
      return getPost({postId, loader})
      .then(({topic}) => {
        getComments({topicId: topic.id});
        if (loader)
          getTopic({topicId: topic.id});
      });
    }
  }),
  withProps(({screenWidth}) => ({
    isMobile: screenWidth < 769
  })),
  lifecycle({
    componentDidMount() {
      const { getTopics, getTopic, topicId, getPost, postId, isMobile } = this.props;

      if (isMobile)
        getTopics({topicType: 'General', pageSize: constants.TOPIC.MOBILE_PAGE_SIZE});
      else getTopics();

      if (topicId)
        getTopic({topicId});

      if (postId)
        getPost(postId);
    },
    componentDidUpdate(prevProps) {
      const { topicId, getData, getPost, getTopic, postId, setSortOption, setQuery } = this.props;

      if (prevProps.topicId !== topicId) {
        window.scrollTo(0, 0);
        getData();
        setQuery('');
        setSortOption(SORT_OPTIONS[0]);

        if (topicId)
          getTopic({topicId});
      }

      if ((prevProps.postId !== postId) && postId) {
        window.scrollTo(0, 0);
        getPost(postId);
      }

      if ((prevProps.postId !== postId) && !postId) {
        window.scrollTo(0, 0);
        getData();
      }
    }
  }),
  withProps(({posts, history}) => ({
    posts: posts?.filter(p => p.id === p.threadId),
    previousRoute: history.location.state?.from
  })),
  withHandlers({
    submit: ({submit, closeModal, getComments, topicId, postId, history}) => values => {
      return submit(values, topicId)
      .then(response => {
        if (postId) {
          history.push('/community');
        } else {
          getComments({topicId});
        }
        closeModal();

        return response;
      });
    },
    updateQuestion: ({updateQuestion, postId, closeModal, getPost}) => values => {
      return updateQuestion(values, postId)
      .then(() => {
        closeModal();
        getPost(postId);
      });
    },
    getAutocomplete: ({getAutocomplete}) => value => {
      if (value === '') return;
      return getAutocomplete({query: value})
      .then(res => res.data.map(r => ({
        label: r.name,
        value: r.id
      })));
    },
    followTopic: ({followTopic, getTopic, post, topicId}) => () => {
      return followTopic(post.topic?.id || topicId)
      .then(() => getTopic({topicId: post.topic?.id || topicId, loader: false}));
    },
    unfollowTopic: ({unfollowTopic, getTopic, post, topicId}) => () => {
      return unfollowTopic(post.topic?.id || topicId)
      .then(() => getTopic({topicId: post.topic?.id || topicId, loader: false}));
    },
    votePost: ({voteComment, getPost, postId}) => (id, action) => {
      return voteComment(id, action)
      .then(() => {
        getPost(postId, false);
      })
    },
    followPost: ({follow, getPost, postId}) => id => {
      return follow(id)
      .then(() => {
        getPost(postId, false);
      })
    },
    unfollowPost: ({unfollow, getPost, postId}) => id => {
      return unfollow(id)
      .then(() => {
        getPost(postId, false);
      })
    }
  })
)(({
  post,
  posts,
  postsCount,
  postId,
  topic,
  topics,
  topicId,
  loading,
  submit,
  modal,
  openModal,
  closeModal,
  votePost,
  user,
  userSignature,
  getAutocomplete,
  getComments,
  followPost,
  unfollowPost,
  followTopic,
  unfollowTopic,
  history,
  previousRoute,
  updateQuestion,
  location,
  query,
  setQuery,
  getData,
  urlParams
}) => {
  const postProps = {
    vote: votePost,
    authUser: user,
    follow: followPost,
    unfollow: unfollowPost,
    history,
    editPost: () => openModal(modalNames.EDIT_QUESTION, post)
  };

  const topicOptions = topics.map(t => ({value: t.id, label: t.name}));
  const topicData = {topicId: {value: post.topic?.id, label: post.topic?.name}};

  const formProps = {
    signature: userSignature,
    defaultOptions: topicOptions,
    getAutocomplete,
    title: modalTitles[modal.name],
    topicPlaceholder: "Select or enter new topic"
  };

  const initialValues = topicId
    ? {topicId: {value: topic.id, label: topic.name}}
    : (postId
      ? modal.name === modalNames.EDIT_QUESTION
        ? {...topicData, ...post}
        : topicData
      : {});

  return (
    <div className="Community page">
      <Helmet>
        <title>Vetit — Community</title>
      </Helmet>
      <Header />
      <div className="container">
        <div className="Community__search card">
          <img src={images.search} alt="Search posts" />
          <input
            placeholder="Search community..."
            value={query}
            onKeyPress={e => e.key == 'Enter' && getData()}
            onChange={e => setQuery(e.target.value)}
          />
          <button
            className="btn-primary"
            onClick={() => getData()}
          >
            Search
          </button>
        </div>
        {postId && !Object.keys(urlParams).length &&
          <Return
            text={previousRoute ? 'Back to all' : location.state ? 'Back to previous page' : 'Back to all' }
            goTo={() => previousRoute ? history.push('/community') : location.state ? history.goBack() : history.push('/community')}
          />
        }
        <div className={cx("Community__container", {postPage: postId, reportingPage: Object.keys(urlParams).length})}>
          {postId
            ?
              <div className="Community__postContainer">
                <Loader loading={loading.post} />
                {Object.keys(post).length > 0 &&
                  <Post
                    {...postProps}
                    post={post}
                    topLvlComment
                  />
                }
              </div>
            :
              <div className="Community__postContainer">
                <Feed
                  data={posts}
                  key={postsCount}
                  dataCount={postsCount}
                  getData={getComments}
                  loadingFeed={loading.posts}
                  user={user}
                  query={query}
                  history={history}
                  follow={followPost}
                  unfollow={unfollowPost}
                  sortOptions={SORT_OPTIONS}
                  optionalArgs={{topicId}}
                  emptyText="No more posts!"
                  communityFeed
                />
              </div>
          }
          <div className="Community__actions">
            {topicId || postId
              ?
                <>
                  <div className="Community__topic Community__card card">
                    <Loader loading={loading.topic} />
                    <h4>About the topic</h4>
                    <div className="Community__topicInfo">
                      <div className="Community__topicCount">
                        <span>{postsCount}</span>
                        <span>{postsCount ===  1 ? 'question' : 'questions'}</span>
                      </div>
                      <div className="Community__topicText">
                        <h4 className="Community__topicTitle">{topic.name}</h4>
                        <p>the place to ask and answer thought-provoking career questions</p>
                      </div>
                      <button
                        className={cx("Post__follow btn-secondary-ghost", {active: topic.isFollowed})}
                        onClick={() => topic.isFollowed ? unfollowTopic(topic.id) : followTopic(topic.id)}
                      >
                        {topic.isFollowed ? 'Unfollow' : 'Follow'}
                      </button>
                    </div>
                    <AskQuestionBtn
                      user={user}
                      history={history}
                      openModal={openModal}
                    />
                  </div>
                </>
              :
              <>
                <div className="Community__askQuestion card">
                  <AskQuestionBtn
                    user={user}
                    history={history}
                    openModal={openModal}
                  />
                </div>
                <div className="Community__topics Community__card card">
                  <Loader loading={loading.topics} />
                  <h4>Topics</h4>
                  <TopicsList topics={topics} />
                  <Link
                    className="btn-primary-outlined"
                    to="/topics"
                  >
                    Browse all
                  </Link>
                </div>
                <ScrollingBar
                  className="Community__mobileTopics"
                  loading={loading.followed}
                  title="Topics"
                >
                  <TopicsList topics={topics} />
                </ScrollingBar>
              </>
            }
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        className="Community__modal"
        closeBtn
      >
        {(() => {
          switch(modal.name) {
            case 'NEW_QUESTION':
              return (
                <AskQuestion
                  onSubmit={submit}
                  form="QuestionForm"
                  initialValues={initialValues}
                  editBtnText='Add your question'
                  {...formProps}
                />
              )
            case 'EDIT_QUESTION':
              return (
                <AskQuestion
                  onSubmit={updateQuestion}
                  form="EditQuestionForm"
                  initialValues={initialValues}
                  editBtnText='Save my edit'
                  isQuestionEdited
                  {...formProps}
                />
              )
          }
        })()}
      </Modal>
    </div>
  )
})

const mapStateToProps = ({auth, data: {comment, comments, topic, topics, topics_general, user_profile}, loading}) => ({
  user: auth.user,
  userProfile: user_profile || {},
  post: comment || {},
  posts: comments?.data || [],
  postsCount: comments?.count || 0,
  loading: {
    post: loading.comment,
    posts: loading.comments,
    topic: loading.topic,
    topics: loading.topics
  },
  topics: topics?.data || [],
  topicsGeneral: topics_general?.data || [],
  topic: topic || {},
  urlParams: parse(location.search.substring(1))
});

const mapDispatchToProps = dispatch => ({
  getComments: (args = {}) => new Promise((resolve, reject) => dispatch(getComments({...args, resolve, reject}))),
  getTopics: (args = {}) => new Promise((resolve, reject) => dispatch(getTopics({...args, resolve, reject}))),
  getTopic: (args = {}) => new Promise((resolve, reject) => dispatch(getTopic({...args, resolve, reject}))),
  getPost: (args = {}) => new Promise((resolve, reject) => dispatch(getPost({...args, resolve, reject}))),
  submit: (values, topicId) => new Promise((resolve, reject) => dispatch(addComment({values, topicId, resolve, reject}))),
  updateQuestion: (values, postId) => dispatch(updateComment({values, topicId: postId})),
  voteComment: (id, action) => dispatch(voteComment(id, action)),
  getAutocomplete: (args) => dispatch(getAutocomplete(args)),
  follow: (...args) => dispatch(follow(...args)),
  unfollow: (...args) => dispatch(unfollow(...args)),
  followTopic: (...args) => dispatch(followTopic(...args)),
  unfollowTopic: (...args) => dispatch(unfollowTopic(...args))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Community));
