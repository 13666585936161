import React, { useEffect } from 'react';
import { Helmet }           from 'react-helmet';
import { withRouter }       from 'react-router';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';

import TopicsCard           from './TopicsCard';

import './Topics.sass';


function Topics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Topics page">
      <Helmet>
        <title>Vetit — Topics</title>
      </Helmet>
      <Header />
      <div className="container">
        <h3>All topics</h3>
        <TopicsCard
          title="General discussion"
          type="General"
        />
        <TopicsCard
          title="Employers"
          type="Employer"
        />
        <TopicsCard
          title="Educators"
          type='Educator'
        />
        <TopicsCard
          title="Support services"
          type='Partner'
        />
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(Topics);
