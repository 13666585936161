import React, { useEffect } from 'react';
import { Helmet }           from 'react-helmet';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';

import './Terms.sass';

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Terms page">
      <Helmet>
        <title>Vet It — Privacy & Cookie Terms</title>
      </Helmet>
      <Header />
      <div className="container">
        <div className="vertical-card">
          <h1>Terms of Use - ToU Agreement</h1>

          <p>
            These Terms of Use (the "Terms") regulate your access to and use of our websites, emails, and mobile apps ("Vet It"), and include our Privacy and Cookie Policy.
            By accessing and using Vet It, you agree to comply with these Terms. If you are using Vet It on behalf of an educational organization, employer, or other legal entity, you agree to be bound by these Terms even if we have a separate agreement.
            You may not use Vet It if you do not agree to the version of the Terms posted on Vet It at the time you access Vet It. (The terms "we" and "us" refer to Vet It, LLC, a Texas Limited Liability Company.)
          </p>

          <p>
            <strong>Arbitration: </strong>
            Where disputes arise, Vet It's Terms require arbitration on an individual basis as opposed to jury trials or class actions, and also limit the remedies available to you in the event of a dispute.
          </p>

          <h4>1. Eligibility</h4>
          <p>
            To access or use Vet It, you must be 18 years of age or older, be under contract to serve in the Armed Forces of the United States (active, guard, or reserve), be an official veteran of the same, or be a military or veteran spouse, and have the power and authority to enter into these Terms.
            Except as approved by us, Vet It is for your personal, non-commercial use. If you wish to make commercial use of Vet It, you must enter into an agreement with us to do so. You may not use Vet It if we have terminated your account or banned you.
          </p>

          <h4>2. Account</h4>
          <p>
            <strong>1. Vet It Account. </strong>
            To become a member of Vet It (a "Member"), you are required to set up a user name and password to create an account.
            Additional account requirements (e.g. requiring review contributions, culture and pay details) may also apply.
            The burden of maintaining the confidentiality of your password falls on you as the user, and you are strictly forbidden from using the account or password of another Member. You agree to notify us immediately unauthorized use of your account or access to your password is suspected.
            Responsibility for any and all use of your account falls on you as the user. Your passwords may be cancelled or suspended by Vet It at any time.
          </p>
          <p>
            <strong>2. Social Sign In. </strong>
            If you access Vet It through a social networking site, such as Facebook or LinkedIn ("Social Networking Site"), you agree that we may access, make available, and store (if applicable) any information, data, text, messages, tags, and/or other materials accessible through Vet It that you have provided to and stored in your Social Networking Site account so that it is available on and through Vet It via your account and your dashboard profile.
            Subject to the privacy settings that you have set with the Social Networking Site account you use to access Vet It, personally identifiable information that you post to that Social Networking Site may be displayed on Vet It.
            Please note: your relationship with your Social Networking Sites is governed solely by your agreement with those Social Networking Sites and we disclaim any liability for personally identifiable information that may be provided to us by a Social Networking Site in violation of the privacy settings that you have set with that Social Networking Site account.
          </p>

          <h4>3. Community Guidelines</h4>
          <p>
            <strong>1. Rules. </strong>
            You will use Vet It solely for lawful purposes in a manner consistent with these Terms and any and all applicable laws, regulations, or other binding obligations (including contractual obligations) you may have towards third parties. You will not:
          </p>
          <ul>
            <li>Use any information obtained from Vet It in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to any visitor without their prior explicit consent;</li>
            <li>Introduce software or automated agents to Vet It, or access the Service so as to produce multiple accounts, generate automated messages, or to strip or mine data;</li>
            <li>Interfere with, disrupt, or create an undue burden on Vet It or the networks or services connected to it;</li>
            <li>Interfere with, disrupt, modify, reverse engineer, or decompile any data or functionality of Vet It.</li>
          </ul>
          <p>
            <strong>2. Links. </strong>
            Vet It may contain links to third-party websites placed by us as a service to those interested in this information, or posted by other Members.
            Your use of all such links to third-party websites is at your own risk. We do not monitor or have any control over, and make no claim or representation regarding third-party websites.
            To the extent such links are provided by us, they are provided only as a convenience, and a link to a third-party website does not imply our endorsement, adoption or sponsorship of, or affiliation with, such third-party website.
            When you leave Vet It, our terms and policies no longer govern.
          </p>
          <p>
            <strong>3. Third-Party Content. </strong>
            Content from other Members, visitors, advertisers, and other third parties is made available to you through Vet It.
            ("Content") means any work of authorship or information, including salaries, reviews, photos, responses, job ads, profile information, advertisements, comments, opinions, postings, resumes, messages, text, files, images, works of authorship, e-mail, or other materials you find on Vet It.
            Because we do not control such Content, you understand and agree that:
          </p>
          <ul>
            <li>We are not responsible for, and do not endorse, any such Content, including advertising and information about third-party products and services, job ads, or the employer, interview and salary-related information provided discreetly by other Members;</li>
            <li>We make no guarantees about the accuracy, currency, suitability, or quality of the information in such Content;</li>
            <li>We assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful Content made available by other visitors, employers, advertisers, and third parties.</li>
          </ul>
          <p>
            In accordance with Section 230 of the U.S. Communications Decency Act, we generally cannot be held liable for claims arising from the Content provided by third parties on Vet It.
          </p>

          <h4>4. Sharing Content</h4>
          <p>
            <strong>1. Your Responsibility for Your Content. </strong>
            You are solely responsible for any and all Content that is posted through your account on Vet It ("Your Content"), as well as the representations you make to us about Your Content.
            You agree that by submitting Your Content to Vet It, you have reviewed and agree to abide by our Community Guidelines.
          </p>
          <p>
            <strong>2. Representations Regarding Your Content. </strong>
            You represent and warrant that:
          </p>
          <ul>
            <li>You own Your Content or otherwise have the right to grant the license set forth in these Terms;</li>
            <li>Your Content does not violate the privacy rights, publicity rights, copyright rights, or other rights of any person;</li>
            <li>By providing or posting Your Content, you do not violate any binding confidentiality, non-disclosure, or contractual obligations you might have towards a third party, including your current or former employer or any potential employer;</li>
            <li>Any information you provide in a salary, company review, interview review, company photo, employer response, job ad, or employer profile is correct;</li>
            <li>Any information you provide about your current, past or potential status as an employee of a certain employer is correct and complete;</li>
            <li>Any resume you upload is accurate and submitted on your own behalf.Please do not provide any information that you are not allowed to share with others, including by binding contractual obligation or by law, because any information you provide will be accessible by every visitor of Vet It.</li>
          </ul>
          <p>
            <strong>3. Prohibited Content. </strong>
            You agree that you will not post any Content that:
          </p>
          <ul>
            <li>Is offensive or promotes racism, bigotry, hatred or physical harm of any kind against any group or individual, or is pornographic or sexually explicit in nature; bullies, harasses or advocates stalking, bullying, or harassment of another person;</li>
            <li>Involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing, or "spamming";</li>
            <li>Is false or misleading or promotes, endorses or furthers illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;</li>
            <li>Promotes, copies, performs or distributes an illegal or unauthorized copy of another person's work that is protected by copyright or trade secret law, such as providing pirated computer programs or links to them, providing information to circumvent manufacturer-installed copy-protection devices, or providing pirated music, videos, or movies, or links to such pirated music, videos, or movies;</li>
            <li>Is involved in the exploitation of persons under the age of eighteen (18) in a sexual or violent manner, or solicits personal information from anyone under eighteen (18);</li>
            <li>Provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses and other harmful code;</li>
            <li>Contains identification information such as social security number, passport number, national identification number, insurance number, driver's license number, immigration number, or any other similar number, code, or identifier;</li>
            <li>Solicits passwords or personally identifying information for commercial or unlawful purposes from other visitors;</li>
            <li>Except as expressly approved by us, involves commercial activities and/or promotions such as contests, sweepstakes, barter, pyramid schemes, advertising, affiliate links, and other forms of solicitation;</li>
            <li>Contains viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or similar software;</li>
            <li>Posts or distributes information which would violate any binding confidentiality, non-disclosure or other contractual restrictions or rights of any third party, including any current or former employers or potential employers;</li>
            <li>Implies a Vet It endorsement or partnership of any kind;</li>
            <li>Otherwise violates these Terms, the terms of your agreements with us, or creates liability for us.</li>
          </ul>

          <h4>5. Special Provisions Applicable To Employers/Educators</h4>
          <p>You may not post any job/program ad that:</p>
          <ul>
            <li>Does not comply with Texas laws or the applicable laws or regulations of the state and country where the job is to be performed, including laws relating to labor and employment, equal employment opportunity and employment eligibility requirements, data privacy, data access and use and intellectual property;</li>
            <li>Contains false information or solicits employees by intentional misrepresentation, such as, misrepresentation of the terms of employment, the hiring entity, or the identity of the poster;</li>
            <li>Requires an application fee or up-front or periodic payments; requires recruitments of others; resembles a multi-level marketing scheme, franchise, pyramid scheme, "club membership", distributorship or sales representative agency arrangement; or only pays commissions (except where the listing makes clear that the available job pays commission only and clearly describes the product or service that the job seeker would be selling);</li>
            <li>Involves any screening requirement where such screening requirement is not an actual and legal requirement of the advertised position;</li>
            <li>Contains any logo or brands, or link to website, other than your own or those of any entity for which you are authorized to submit job ads;</li>
            <li>Contains multiple job openings in a single job ad (unless you've purchased a service that permits this);</li>
            <li>Discriminates against applicants on the basis of gender, race, age or disability, as determined in Vet It's reasonable discretion.You may not offer incentives in exchange for company or interview reviews. We will remove reviews where we have evidence that employees were compensated to leave reviews.</li>
          </ul>

          <h4>6. Special Provisions Applicable to Advertisers</h4>
          <p>
            This provision applies to all advertisers, including Employers who purchase Job Ads or display ads.
            Unless we agree otherwise, you may not use data collected or derived from ads ("Ad Data") for any purpose (including retargeting, building or augmenting visitor profiles, allowing piggybacking or redirecting with tags, or combining with data across multiple advertisers' campaigns) other than to assess the performance and effectiveness of your campaigns on an aggregate and anonymous basis.
            You may not, and you may not permit a third-party to, transfer or sell any Ad Data to, or use Ad Data in connection with, any ad network, ad exchange, data broker, or other party not acting on behalf of you and your campaigns.
            You may use information provided directly to you from visitors if you provide clear notice to and obtain consent from those visitors and comply with all applicable laws and industry guidelines.
          </p>

          <h4>7. Enforcement by Vet It</h4>
          <p>
            <strong>0. Removal of Content. </strong>
              While Vet It has no obligation to do so, Vet It reserves the right to review and delete (or modify) any Content that we believe, in our sole discretion, violates these Terms or other applicable policies posted on Vet It (including our Community Guidelines), or that we deem, in our sole discretion, inappropriate.
              If you see any Content on Vet It that you believe violates our policies, you may report that Content by clicking on an applicable link adjacent to that Content (e.g. links titled: "Inappropriate" or "Flag Review") or by contacting us here.
              Once notified, we will review the Content and consider whether to remove or modify it.
              Please note: Our interpretation of our policies and the decision whether or not to edit or remove Content is within our sole discretion.
              You understand and agree that if we choose not to remove or edit Content that you find objectionable, that decision will not constitute a violation of these Terms or any agreement we have with you.
              For more information please send a request here.
          </p>
          <p>
            <strong>1. Copyright Policy. </strong>
              Vet It has adopted the following policy toward copyright infringement on Vet It in accordance with the Digital Millennium Copyright Act (the "DMCA").
              It is our policy to terminate membership privileges of any Member who repeatedly infringes copyright upon prompt notification to us by the copyright owner or the copyright owner's legal agent.
              Without limiting the foregoing, if you believe that your work has been copied in violation of DMCA and used on Vet It in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: · An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; · An identification of the copyrighted work that you claim has been infringed; · A description of where the material that you claim is infringing is located on Vet It; · Your address, telephone number, and e-mail address; · A written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and · A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
          </p>
          <p>If you feel that content has been taken down inappropriately please contact our Copyright Agent who will provide information on the requirements and procedure for filing a counter-notification with us.</p>
          <p>
            Contact information for our Copyright Agent for notice of claims of copyright infringement is as follows:<br />
            Vet It, LLC<br />
            Attn: Copyright Agent<br />
            1401 Lavaca St, #605<br />
            Austin, TX 78701<br />
            e-mail: <a href="mailto:admin@vetitforward.com">admin@vetitforward.com</a>
          </p>
          <p>
            <strong>2. Other Enforcement Actions. </strong>
            While we have no obligation to do so, we reserve the right to investigate and take appropriate action in our sole discretion against you if you violate these Terms, including without limitation: removing Content from Vet It (or modifying it); suspending your rights to use Vet It; terminating your membership and account; reporting you to law enforcement, regulatory authorities, or administrative bodies; and taking legal action against you.
          </p>
          <p>
            <strong>3. Defending Our Members. </strong>
            While we have no obligation to do so, we reserve the right to take appropriate action to protect the anonymity of our Members against the enforcement of subpoenas or other information requests that seek a Member's electronic address or identifying information.
          </p>

          <h4>8. Rights to Your Content</h4>
          <p>
            We do not claim ownership in any Content that you submit to Vet It, but to be able to legally provide Vet It to our visitors, we have to have certain rights to use such Content in connection with Vet It, as set forth below.
            By submitting any Content to Vet It, you hereby grant to us an unrestricted, irrevocable, perpetual, non-exclusive, fully-paid and royalty-free, license (with the right to sublicense through unlimited levels of sublicensees) to use, copy, perform, display, create derivative works of, and distribute such Content in any and all media (now known or later developed) throughout the world.
            No compensation will be paid with respect to the Content that you post through Vet It.
            You should only submit Content to Vet It that you are comfortable sharing with others under the terms and conditions of these Terms.
          </p>

          <h4>9. Rights to Vet It Content</h4>
          <p>
            Vet It contains Content provided by us and our licensors.
            We and our licensors (including other visitors) own and retain all proprietary (and intellectual property) rights in the Content we each provide and Vet It owns and retains all property rights in Vet It.
            If you are a visitor, we hereby grant you a limited, revocable, non-sublicensable license under the intellectual property rights licensable by us to download, view, copy and print Content from Vet It solely for your personal use in connection with using Vet It.
            Except as provided in the foregoing, you agree not to: (1) reproduce, modify, publish, transmit, distribute, publicly perform or display, sell, or create derivative works based on Vet It or the Content (excluding Your Content); or (2) rent, lease, loan, or sell access to Vet It.
            "Vet It", the Vet It logo, and "Vet it Forward" are trademarks of Vet It, LLC.
            The trademarks, logos and service marks ("Marks") displayed on Vet It are our property or the property of other third parties.
            You are not permitted to use these Marks without our prior written consent or the consent of the third party that owns the Mark.
          </p>

          <h4>10. Indemnity</h4>
          <p>You agree to defend, indemnify, and hold us, our subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney's fees, made by any third party due to or arising from your use of Vet It and due to or arising from your breach of any provision of these Terms.</p>

          <h4>11. Disclaimers and Limitation on Liability</h4>
          <p>The disclaimers and limitations on liability in this section apply to the maximum extent allowable under applicable law.</p>
          <p>
            Nothing in this section is intended to limit any rights you have which may not be lawfully limited.
            You are solely responsible for your interactions with advertisers and other visitors and we are not responsible for the activities, omissions, or other conduct, whether online or offline, of any advertiser or visitor of Vet It.
            We are not responsible for any incorrect or inaccurate Content (including any information in profiles) posted on Vet It, whether caused by visitors or by any of the equipment or programming associated with or utilized in Vet It.
            We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any communication with other visitors.
            We are not responsible for any problems or technical malfunction of any hardware and software due to technical problems on the Internet or on Vet It or combination thereof, including any injury or damage to visitors or to any person's computer related to or resulting from participation or downloading materials in connection with Vet It.
            Under no circumstances shall we be responsible for any loss or damage resulting from use of Vet It or from any Content posted on Vet It or transmitted to visitors, or any interactions between visitors of Vet It, whether online or offline.
          </p>
          <p>
            Vet It is provided "as-is" and as available.
            We expressly disclaim any warranties and conditions of any kind, whether express or implied, including the warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.
            We make no warranty that: (1) Vet It will meet your requirements; (2) Vet It will be available on an uninterrupted, timely, secure, or error-free basis; or (3) the results that may be obtained from the use of Vet It will be accurate or reliable.
          </p>
          <p>
            You hereby release us, our officers, employees, agents and successors from any and all claims, demands, and losses, damages, rights, claims, and actions of any kind that are either directly or indirectly related to or arises from: (1) any interactions with other visitors to or users of Vet It, or (2) your participation in any of our offline events.
          </p>
          <p>
            IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM YOUR USE OF VET IT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, WHERE PERMITTED BY APPLICABLE LAW, YOU AGREE THAT OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO YOUR USE OF VET IT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO ONE HUNDRED U.S. DOLLARS ($100).
          </p>

          <h4>12. Termination</h4>
          <p>
            These Terms remain in effect while you use Vet It and, for Members, as long as your account remains open.
            You may delete your account at any time.
            We may suspend or terminate your account or your access to parts of Vet It, without notice to you, if we believe that you have violated these Terms.
            All provisions of these Terms shall survive termination or expiration of these Terms except those granting access to or use of Vet It.
            We will have no liability whatsoever to you for any termination of your account or related deletion of your information.
          </p>

          <h4>13. Changes to Terms</h4>
          <p>
            We may revise these Terms from time by posting an updated version on Vet It and you agree that the revised Terms will be effective thirty (30) days after the change is posted.
            Your continued use of Vet It is subject to the most current effective version of these Terms.
          </p>

          <h4>14. Dispute Resolution</h4>
          <p>
            <strong>0. Governing Law.</strong>
              This Agreement and any and all claims or disputes by or between you or us, including but not limited to any such claims or disputes that are in any way related to or arising under this Agreement or your access to or use of Vet It, shall be governed by the laws of the State of Texas without giving effect to any conflict-of-laws principles that may otherwise provide for the application of the law of another jurisdiction.
              For any claim or dispute not subject to the Arbitration provision below, the claim or dispute shall be brought and litigated exclusively in the state courts located within Travis County, Texas or the federal courts in the Texas, as appropriate, and you agree to submit to the personal jurisdiction of each of these courts for the purpose of litigating such claims or disputes.
          </p>
          <p>
            <strong>PLEASE READ THIS CAREFULLY. IT AFFECTS YOUR RIGHTS.</strong>
            YOU AGREE THAT BY ENTERING INTO THIS AGREEMENT, YOU AND VET IT ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
            YOU AND VET IT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
            ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.
          </p>
          <p>
            <strong>1. Arbitration. </strong>
            Except for a claim that we may have against you, any and all disputes between you and Vet It arising under or related in any way to this Agreement must be resolved through binding arbitration as described in this section.
            This agreement to arbitrate is intended to be interpreted broadly.
            It includes, but is not limited to, all claims and disputes relating to your use of Vet It.
            The arbitration will be governed by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes of the American Arbitration Association ("AAA"), as modified by this section.
            For any claim where the total amount of the award sought is $10,000 or less, the AAA, you and Vet It must abide by the following rules: (a) the arbitration shall be conducted solely based on telephone or online appearances and/or written submissions; and (b) the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties.
            If the claim exceeds $10,000, the right to a hearing will be determined by the AAA rules, and the hearing (if any) must take place in Austin, Texas.
            The arbitrator's ruling is binding and may be entered as a judgment in any court of competent jurisdiction.
            In the event this agreement to arbitrate is held unenforceable by a court, then the claims or disputes that would otherwise have been arbitrated shall be exclusively brought in the state courts located in Travis County, Texas or federal courts located in Texas, as appropriate, and you agree to submit to the personal jurisdiction of each of these courts for the purpose of litigating such claims or disputes.
            Claims of infringement or misappropriation of Vet It's patent, copyright, trademark, or trade secret shall be brought and litigated exclusively in the state courts located in Travis County, Texas or federal courts located in the Texas, as appropriate, and you agree to submit to the personal jurisdiction of each of these courts for the purpose of litigating such claims.
          </p>

          <h4>14. Dispute Resolution</h4>
          <p>
            Except as specifically stated in another agreement we have with you, these Terms constitute the entire agreement between you and us regarding the use of Vet It and these Terms supersede all prior proposals, negotiations, agreements, and understandings concerning the subject matter of these Terms.
            You represent and warrant that no person has made any promise, representation, or warranty, whether express or implied, not contained herein to induce you to enter into this agreement.
            Our failure to exercise or enforce any right or provision of the Terms shall not operate as a waiver of such right or provision.
            If any provision of the Terms is found to be unenforceable or invalid, then only that provision shall be modified to reflect the parties' intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.
            The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with Vet It's prior written consent, but may be assigned or transferred by us without restriction.
            Any attempted assignment by you shall violate these Terms and be void.
            The section titles in the Terms are for convenience only and have no legal or contractual effect; as used in the Terms, the word "including" means "including but not limited to."
            Please contact us with any questions regarding these Terms by contacting us <a href="mailto:admin@vetitforward.com">here</a>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
