import api from 'api';

export const shareViaLinkedin = ({code, content, redirectUri}) => () => {
    return api({
      method: 'POST',
      url: '/linkedin/share',
      data: {
        code,
        redirectUri,
        body: {
          lifecycleState: 'PUBLISHED',
          visibility: {
            'com.linkedin.ugc.MemberNetworkVisibility': 'PUBLIC'
          },
          specificContent: {
            'com.linkedin.ugc.ShareContent': {
              shareCommentary: {
                text: content
              },
              shareMediaCategory: 'NONE'
            }
          }
        }
      }
    })
  }

const defineUrl = (shareType, itemId, reviewId) => {
  switch(shareType) {
    case 'AdminPost':
      return `/admin-posts/${itemId}/share`;
    case 'Post':
      return `/comments/${itemId}/share`;
    case 'JobPosting':
      return `/job-openings/${itemId}/share`;
    case 'Educator':
      return `/educators/${itemId}/share`;
    case 'Employer':
      return `/employers/${itemId}/share`;
    case 'Partner':
      return `/partners/${itemId}/share`;
    case 'EducatorProgramReview':
      return `/educators/${itemId}/reviews/interviews/${reviewId}/share`;
    case 'EducatorInterviewReview':
      return `/educators/${itemId}/reviews/programs/${reviewId}/share`;
    case 'EmployerInterviewReview':
      return `/employers/${itemId}/reviews/interviews/${reviewId}/share`;
    case 'EmployerJobReview':
      return `/employers/${itemId}/reviews/jobs/${reviewId}/share`;
    case 'PartnerReview':
      return `/partners/${itemId}/reviews/${reviewId}/share`;
  }
};

export const shareContent = ({redirectUrl, recipients, shareType, itemId, reviewId, personalizedMessage, isAnonymous}) => () => {
  return api({
    method: 'POST',
    url: defineUrl(shareType, itemId, reviewId),
    data: {
      redirectUrl,
      emails: recipients,
      isAnonymous,
      ...(personalizedMessage && {personalizedMessage}),
    }
  })
};
