import React from 'react';
import ReactDOM from 'react-dom';
import App from 'main'
import store from 'store';

import createRoutes from './routes';


const routes = createRoutes(store);
const rootElem = document.getElementById('app');


ReactDOM.render(<App routes={routes} />, rootElem);
