import  { useState, useEffect } from 'react';
import toDate from 'date-fns/toDate';
import differenceInDays from 'date-fns/differenceInDays';

const localStorageName = 'Vetit__install_pwa_prompt';

function checkForIOS() {
  if (navigator.standalone)
    return false;

  const today = toDate(new Date());
  const lastPrompt = localStorage.getItem(localStorageName);
  const days = differenceInDays(new Date(), new Date(lastPrompt));

  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone;
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

  const prompt = (isNaN(days) || days > 30) && isIOS && isSafari;

  if (prompt && "localStorage" in window)
    localStorage.setItem(localStorageName, today);

  return { isIOS, isSafari, prompt };
}

export default function useIsIOS() {
  const [isIOS, setIsIOS] = useState({});

  useEffect(() => {
    setIsIOS(checkForIOS());
    return () => console.log('CLEANUP INSTALL PROMPT', isIOS);  //eslint-disable-line
  }, []);

  return isIOS;
}