import React from 'react'
import cx    from 'classnames';

import './Sorting.sass';


export default function Sorting({columns, sortColumn, sortDirection, activePage, sort}) {
  return (
    <div className="Sorting">
      {columns.map((c, i) => (
        <div
          key={i}
          onClick={() => sort(activePage, c.name)}
          className={cx("Sorting__item", sortColumn == c.name && sortDirection)}
        >
          <span>{c.label}</span>
          {sortColumn != c.name &&
            <i className="fa fa-sort" aria-hidden="true"></i>
          }
        </div>
      ))}
    </div>
  )
}
