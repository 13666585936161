import React        from 'react';
import { Link }     from 'react-router-dom';
import cx           from 'classnames';
import Search       from 'theme/Search';
import { images }   from 'theme/img/images';

import './JobSearch.sass';


export default function JobSearch({loadOptions, search, onSelectOption, clearLocation, focus, query, setQuery, onLocationFocus, locationValue, className, redirectLink}) {
  const onKeyPress = e => {
    if (e.key === 'Enter')
      return search();
    else if(e.keyCode === 8) {
      setQuery('');
    }
  };

  const onLocationKeyPress = e => {
    if (e.key === 'Enter')
      return search();
    else if(e.keyCode === 8) {
      clearLocation();
    }
  };

  const onFocus = () => {
    focus && focus(true);
    setQuery('');
  }

  return (
    <div className={cx("JobSearch card", className)}>
      <input
        className="JobSearch__title"
        placeholder="Search by skill and read reviews by veterans"
        onChange={e => setQuery(e.target.value)}
        onKeyPress={onKeyPress}
        value={query}
        onFocus={() => onFocus()}
      />
      <Search
        placeholder="Anywhere"
        icon={images.pin}
        loadOptions={loadOptions}
        value={locationValue}
        onSelectOption={onSelectOption}
        onKeyPress={onLocationKeyPress}
        onFocus={() => onLocationFocus && onLocationFocus()}
        async
      />
      {redirectLink
        ?
          <Link
            className="btn-primary"
            to={redirectLink}
          >
            Find jobs
          </Link>
        :
          <button
            className="btn-primary"
            onClick={() => search()}
          >
            Find jobs
          </button>
      }
    </div>
  )
}
