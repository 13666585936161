import React                    from 'react';
import cx                       from 'classnames';
import { upperCaseFirstLetter } from 'theme/utils/helpers';

import './QuickGlance.sass';


export default function QuickGlanceItem({label, value, checkIfBoolean, checkIfPercent, getValue, studentsDataItem}) {
  return (
    <div className={cx("QuickGlance__item", {studentsDataItem, bool: checkIfBoolean(label)})}>
      <span className={cx("label", {studentsDataItem})}>{upperCaseFirstLetter(label)}</span>
      <span className={cx("content", checkIfBoolean(label) ? cx(value ? 'check' : 'times') : null)}>
        {(() => {
          switch(label) {
            case checkIfBoolean(label):
              return value
                ? <span className="QuickGlance__icon QuickGlance__icon--check"><i className="fa fa-check" aria-hidden="true"></i></span>
                : <span className="QuickGlance__icon QuickGlance__icon--times"><i className="fa fa-times" aria-hidden="true"></i></span>;
            case checkIfPercent(label):
              return value && `${getValue(value)}%`;
            default:
              return value ? getValue(value) : '';
          }
        })()}
      </span>
    </div>
  )
}
