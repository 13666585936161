import React        from 'react';
import AsyncSelect  from 'react-select/async';
import { images }   from 'theme/img/images';

import './Search.sass';

const customStyles = {
  singleValue: (provided, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'block'
  })
}

export default function Search({onChange, label, icon, placeholder = 'Search...', async, loadOptions, onSelectOption, onKeyPress, onFocus, value}) {
  return (
    <div className="Search">
      <span className="Search__label form-label">{label}</span>
      <div>
        {async
          ?
            <AsyncSelect
              classNamePrefix="RSelect"
              styles={customStyles}
              placeholder={placeholder}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              value={value && {value, label: value}}
              isClearable
              loadOptions={loadOptions}
              onChange={e => e && onSelectOption(e.value)}
              onKeyDown={onKeyPress}
              onFocus={onFocus}
            />
          :
            <input
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
        }
        <img src={icon || images.search} alt="search" />
      </div>
    </div>
  )
}
