import React, { useState }        from 'react';
import { connect }                from 'react-redux';
import { FacebookShareButton,
  FacebookMessengerShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  RedditIcon,
  TwitterIcon }                   from 'react-share';
import { API_CONF }               from 'api';
import CreatableInput             from 'theme/CreatableInput';
import FunctionBtn                from 'theme/FunctionBtn';
import CustomCheckbox             from 'theme/CustomCheckbox';
import { copy }                   from 'theme/content';
import { images }                 from 'theme/img/images';
import { email as validateEmail } from 'theme/utils/validate';
import { shareContent }           from 'share/actions';
import { actionSucceeded }        from 'store/actions';

import './Share.sass';

const SIZE = 32;

function Share({url, itemType, organizationName, shareContent, shareType, itemId, redirectUrl, reviewId, jobShareDescription, linkedinText, closeModal, displaySuccessMessage}) {
  const [linkCopied, setCopied] = useState(false);
  const [invitesSent, setInvitesSent] = useState(false);
  const [areAllEmailsValid, setEmailsValid] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [linkedinTextValue, setLinkedinTextValue] = useState(linkedinText);
  const [section, setSection] = useState('default');

  const [isPersonalizedMessageTextareaVisible, setIsPersonalizedMessageTextAreaVisible] = useState(false);
  const [personalizedMessage, setPersonalizedMessage] = useState('');

  const isOrganizationShare = itemType == 'employer' || itemType == 'educator' || itemType == 'support service';

  const copyUrl = e => {
    e.stopPropagation();
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const reducedEmails = emailAddresses?.reduce((a, b) => [...a, b.value], []);

  const shareItem = () => {
    return shareContent({
      redirectUrl: redirectUrl || url,
      recipients: reducedEmails,
      shareType,
      itemId,
      reviewId,
      personalizedMessage,
      isAnonymous
    })
  };

  const sendInvites = e => {
    e.stopPropagation();
    setInvitesSent(true);

    shareItem();

    closeModal(e);
    displaySuccessMessage('The invites have been successfully sent to your friends.');
  };

  const validateEmails = emails => {
    setEmailAddresses(emails);
    emails?.every(email => !validateEmail(email.value)) ? setEmailsValid(true) : setEmailsValid(false);
  };

  const saveLinkedinShareInLocalStorage = () => {
    localStorage.setItem('linkedinShare', JSON.stringify({
      text: linkedinTextValue,
      url: location.pathname
    }))
  };

  const renderTitle = () => {
    switch(itemType) {
      case 'job posting':
        return jobShareDescription;
      case 'educator':
      case 'employer':
      case 'support service':
        return `Check out ${organizationName} on Vet it!`
      case 'post':
        return 'Check out this post on Vet it!'
      case 'review':
        return 'Check out this review on Vet it!'
      default:
        return 'Join the community!';
    }
  };

  return (
    <div
      className="Share"
      onClick={e => e.stopPropagation()}
    >
      <div className="Share__container Share__media Share__or">
        <h3>
          {section == 'linkedin' ? `Share this ${itemType} on Linkedin` : `Share this ${itemType}`}
        </h3>
        {section == 'default' &&
          <>
            <div className="Share__btns">
              <FacebookShareButton
                url={url}
                quote={renderTitle()}
              >
                <FacebookIcon {...SIZE} />
              </FacebookShareButton>
              <FacebookMessengerShareButton
                url={url}
                appId={API_CONF.FACEBOOK_CLIENT_ID}
              >
                <FacebookMessengerIcon {...SIZE} />
              </FacebookMessengerShareButton>
              <TwitterShareButton
                url={url}
                title={renderTitle()}
              >
                <TwitterIcon {...SIZE} />
              </TwitterShareButton>
              <RedditShareButton
                url={url}
                title={renderTitle()}
              >
                <RedditIcon {...SIZE} />
              </RedditShareButton>
              <button
                className="Share__linkedin"
                onClick={() => setSection('linkedin')}
              >
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </button>
            </div>
            <div className="Share__inputContainer Share__copy">
              <div className="Share__input">
                <span className="form-label">Copy link</span>
                <input
                  className="input"
                  value={url}
                  readOnly
                />
              </div>
              <button
                className="btn-primary"
                onClick={e => copyUrl(e)}
              >
                {linkCopied ? 'Copied!' : 'Copy'}
              </button>
            </div>
          </>
        }
        {section == 'linkedin' &&
          <div className="Share__linkedinForm">
            <button
              className="Share__linkedinFormBack"
              onClick={() => setSection('default')}
            >
              <img src={images.arrowLeftDark} alt="Go back" />
              <span>BACK</span>
            </button>
            <textarea
              className="Share__textarea input"
              value={linkedinTextValue}
              onChange={e => setLinkedinTextValue(e.target.value)}
            />
            <a
              href={API_CONF.LINKEDIN_AUTH_LINK}
              className="btn-primary"
              onClick={() => saveLinkedinShareInLocalStorage()}
            >
              Share on Linkedin
            </a>
          </div>
        }
      </div>
      <div className="Share__container">
        <h3>Vet it Forward!<br />Invite friends!</h3>
        <p>
          {isOrganizationShare
            ? `Invite friends to review their experience at ${organizationName}!`
            : copy.share.share
          }
        </p>
        <div className="Share__inputContainer Share__invite">
          <div>
            <CreatableInput
              className="Share__multipleEmails"
              validate={validateEmails}
              placeholder="Hit &#9166; after each email entered OR paste an email list and hit &#9166;"
            />
            <div className="Share__addPersonalizedMessage">
              <FunctionBtn
                img={{src: images.plus, alt: 'Add personalized message'}}
                text="Add personalized message"
                action={() => setIsPersonalizedMessageTextAreaVisible(!isPersonalizedMessageTextareaVisible)}
              />
              {isPersonalizedMessageTextareaVisible &&
                <textarea
                  className="Share__textarea Share__textarea--personalized-message input"
                  value={personalizedMessage}
                  placeholder="Add your personalized message..."
                  onChange={e => setPersonalizedMessage(e.target.value)}
                />
              }
            </div>
            <div className="Share__actions">
              <CustomCheckbox
                // className="Filters__filterItem"
                checked={isAnonymous}
                onClick={() => setIsAnonymous(!isAnonymous)}
                label="Send anonymously"
              />
              <button
                className="Share__submit btn-primary"
                disabled={emailAddresses?.length == 0 || !areAllEmailsValid || invitesSent}
                onClick={e => sendInvites(e)}
              >
                Send invites
              </button>
            </div>
          </div>
          {emailAddresses?.length > 0 && !areAllEmailsValid &&
            <span className="Share__error">All the emails need to have a proper format.</span>
          }
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  dispatch => ({
    shareContent: (args = {}) => dispatch(shareContent({...args})),
    displaySuccessMessage: message => dispatch(actionSucceeded(message)),
  })
)(Share);
