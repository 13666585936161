import React                 from 'react';
import { withRouter }        from 'react-router';
import { compose,
         setDisplayName,
         withHandlers,
         withProps,
         withStateHandlers } from 'recompose';
import cx                    from 'classnames';
import withDebounce          from 'theme/utils/recompose';
import { images }            from 'theme/img/images';


export default withRouter(compose(
  setDisplayName('HeaderSearch'),
  withDebounce(),
  withProps(() => ({
    inputEl: React.createRef()
  })),
  withStateHandlers(
    ({query}) => ({
      inputValue: query || ''
    }),
    {
      setInputValue: () => value => ({
        inputValue: value
      })
    }
  ),
  withHandlers({
    onChange: ({setInputValue}) => e => {
      const value = e.target.value;
      setInputValue(value);
    },
    onKeyPress: ({setQuery, searchDebounced}) => e => {
      const value = e.target.value;

      if(e.charCode == 13) {
        setQuery(value);
        searchDebounced(value);
      }
    }
  })
)(({className, onChange, onKeyPress, inputValue, isMobile, isInputVisible, toggleInput, inputEl}) => (
  <div className={cx(className)}>
    <input
      ref={inputEl}
      onChange={onChange}
      placeholder={isMobile ? 'Start typing...' : ''}
      value={inputValue}
      onKeyPress={onKeyPress}
      onFocus={e => e.target.placeholder = ""}
      className={cx({mobile: isMobile, fullWidth: isInputVisible})}
    />
    <img
      className={cx({mobile: isMobile})}
      src={isMobile ? (isInputVisible ? images.searchMobileOrange : images.searchMobile) : images.search}
      alt="search"
      onClick={toggleInput}
    />
  </div>
)))
