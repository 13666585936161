import React        from 'react';
import CustomRadio  from 'theme/CustomRadio';
import FunctionBtn  from 'theme/FunctionBtn';
import { images }   from 'theme/img/images';

import './SubscriptionInfo.sass';

export default function SubscriptionInfo({type, selectedType, select, profile, altText}) {
  return (
    <div className="SubscriptionInfo">
      <div className="SubscriptionInfo__checkbox">
        <CustomRadio
          value={selectedType === type.name}
          action={() => select(type.name)}
        />
      </div>
      <div className="SubscriptionInfo__container">
        <h4 className="SubscriptionInfo__name">{type.name}</h4>
        <div className="SubscriptionInfo__description">
          {profile ? altText : type.description}
        </div>
        <div className="SubscriptionInfo__price">{type.priceInfo}</div>
        <div className="SubscriptionInfo__add">
          {selectedType === type.name
            ? <img src={images.checkmark} alt="Selected account type" />
            : <FunctionBtn
                img={{src: images.plus, alt: 'Select account type'}}
                text="Add"
                action={() => select(type.name)}
              />
          }
        </div>
      </div>
    </div>
  )
}
