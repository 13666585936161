
import React from 'react';
import cx    from 'classnames';

import './CustomCheckbox.sass';

export default function CustomCheckbox({ checked, onClick, label, className }) {
  return (
    <div className={cx("Form__radio CustomCheckbox checkbox", className)}>
      <div
        className={cx("check", {checked})}
        onClick={onClick}
      >
          {checked &&
            <i className="fa fa-check" aria-hidden="true" />
          }
      </div>
      <span className="Filters__boxItemName">{label}</span>
    </div>
  )
}
