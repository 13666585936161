import React, { useEffect }    from 'react';
import { ReactSVG }            from 'react-svg';
import { connect }             from 'react-redux';
import { Link }                from 'react-router-dom';
import cx                      from 'classnames';
import { setActiveMobileLink } from 'store/actions';
import { env }                 from 'theme/content';
import { images }              from 'theme/img/images';
import Notifications           from 'notifications/Notifications';

import './SecondaryNav.sass';


const ITEMS = [
  {key: 'home', element: 'dashboard-link', adminLink: env.HOME_ROUTE.organizationAdmin, link: env.HOME_ROUTE.common, img: images.home, imgAlt: 'home', svgColoredEl: 'g'},
  {key: 'jobs', element: 'link', link: '/jobs', img: images.briefcase, imgAlt: 'home', svgColoredEl: 'g'},
  {key: 'companies', element: 'link', link: '/companies', img: images.companies, imgAlt: 'home', svgColoredEl: 'path'},
  {key: 'community', element: 'link', link: '/community', img: images.people, imgAlt: 'home', svgColoredEl: 'g'},
  {key: 'notifications', element: 'notifications', link: null, img: null, imgAlt: 'home'},
  {key: 'dropdown', element: 'dropdown', link: null, img: images.expandBig, imgVisible: images.iconArrowHide, imgAlt: 'home'}
];

function SecondaryNav({links, mobileDropdownClass, user, mobile, visible, toggle, close, logout, activeMobileLink, setActiveMobileLink}) {
  useEffect(() => {
    setActiveMobileLink(location.pathname);
  }, []);

  const handleToggle = () => {
    toggle();
    setActiveMobileLink(null);
  };

  const getComponent = item => {
    const { link, element, adminLink, key, img, imgVisible, imgAlt, svgColoredEl } = item;

    switch(element) {
      case 'dashboard-link':
        return (
          <Link
            key={key}
            className={cx("SecondaryNav__btn", {active: activeMobileLink?.includes(user?.organizationId ? adminLink : link), g: svgColoredEl == 'g'})}
            to={user?.organizationId ? adminLink : link}
          >
            <ReactSVG src={img} aria-label={imgAlt} />
          </Link>
        );
      case 'link':
        return (
          <Link
            key={key}
            className={cx("SecondaryNav__btn", {active: activeMobileLink?.includes(link), path: svgColoredEl == 'path', g: svgColoredEl == 'g'})}
            to={link}
          >
            <ReactSVG src={img} aria-label={imgAlt} />
          </Link>);
      case 'notifications':
        return (
          <Notifications
            key={key}
            className="SecondaryNav__notifications SecondaryNav__btn"
            mobile={mobile}
            mobileClick={() => setActiveMobileLink(null)}
          />);
      case 'dropdown':
        return (
          <button
            key={key}
            className="SecondaryNav__btn"
            onClick={handleToggle}
          >
            <img src={visible ? imgVisible : img} alt={imgAlt} />
          </button>
        );
    }
  };

  return (
    <div className="SecondaryNav">
      <div className={cx("SecondaryNav__container", {auth: user})}>
        {user
          ?
            <div className="SecondaryNav__links">
              {ITEMS.map(item => getComponent(item))}
            </div>
          :
            <div className={cx("SecondaryNav__authBtns", {auth: user})}>
              <Link className="SecondaryNav__signin btn-secondary-outlined" to="/auth/signup" onClick={close}>Join now</Link>
              <Link className="SecondaryNav__signin btn-secondary" to="/auth/signin" onClick={close}>Sign in</Link>
            </div>
        }
      </div>
      {visible &&
        <div>
          <ul className={cx(mobileDropdownClass)}>
            {links.map((l, i) => (
              <li key={i}>
                <Link to={l.to}>{l.name.toUpperCase()}</Link>
              </li>
            ))}
          </ul>
          <div className="SecondaryNav__logout">
            <button
              className="btn-secondary-outlined"
              onClick={logout}
            >
              Log out
            </button>
          </div>
        </div>
      }
    </div>
  )
}

SecondaryNav.displayName = 'SecondaryNav';

export default connect(
  ({auth, header}) => ({user: auth.user, ...header}),
  { setActiveMobileLink }
)(SecondaryNav);
