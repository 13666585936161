import React                          from 'react';
import { connect }                    from 'react-redux';
import { withRouter }                 from 'react-router';
import { Link }                       from 'react-router-dom';
import { Helmet }                     from 'react-helmet';
import { ReactSVG }                   from 'react-svg';
import { compose,
         withState,
         lifecycle,
         setDisplayName,
         withProps,
         withHandlers }               from 'recompose';
import jwtDecode                      from 'jwt-decode';
import { parse }                      from 'qs';
import cx                             from 'classnames';
import Header                         from 'theme/Header';
import MessageSection                 from 'theme/MessageSection';
import { images }                     from 'theme/img/images';
import { login,
         register,
         resendEmail,
         closeConfirmationMessage,
         confirmAccount }             from 'store/actions';

import LoginForm                      from './LoginForm';
import SignupForm                     from './SignupForm';
// import { facebook, linkedin }         from '../sso';

import './Authentication.sass';

const containerBackground = {backgroundImage: `url(${images.authBackground})`};

const Logo = () => (
  <svg className="Authentication__logo" xmlns="http://www.w3.org/2000/svg" width="67" height="24" viewBox="0 0 67 24"><g fill="none"><path d="M15.6 0.4L20.3 0.4 19.9 2.4 9.2 24 2.7 24 0 5.6 5.4 5.6 6.7 19 15.6 0.4ZM33.4 16.6L21.6 16.6 21.3 18.4C21.2 18.9 21.3 19.4 21.6 19.7 21.9 20.1 22.3 20.2 23 20.2L32 20.2 31.4 24 21.1 24C20.3 24 19.6 23.9 19 23.6 18.4 23.4 17.9 23 17.5 22.6 17.1 22.1 16.8 21.6 16.7 21 16.5 20.4 16.5 19.8 16.6 19.1L18.2 10.5C18.3 9.8 18.5 9.2 18.9 8.6 19.2 8 19.7 7.5 20.2 7.1 21.4 6.1 22.8 5.6 24.3 5.6L30.1 5.6C30.8 5.6 31.5 5.8 32.1 6 32.7 6.2 33.2 6.6 33.6 7.1 34 7.5 34.3 8 34.4 8.6 34.6 9.2 34.6 9.9 34.4 10.5L33.4 16.6 33.4 16.6ZM22.3 12.8L29.5 12.8 29.8 11.2C29.9 10.8 29.8 10.3 29.5 9.9 29.2 9.6 28.8 9.4 28.1 9.4L24.9 9.4C24.3 9.4 23.8 9.6 23.3 9.9 22.9 10.2 22.6 10.7 22.6 11.2L22.3 12.8 22.3 12.8ZM41.2 0.4L43.9 0.4 42.9 5.6 46.3 5.6 45.7 9.4 42.3 9.4 40.7 18.4C40.6 18.9 40.7 19.4 41 19.7 41.3 20.1 41.7 20.2 42.4 20.2L46 20.2 45.3 24 40.4 24C39.7 24 39 23.9 38.4 23.6 37.8 23.4 37.3 23 36.9 22.6 36.5 22.1 36.2 21.6 36.1 21 35.9 20.4 35.9 19.8 36 19.1L37.8 9.4 36.2 9.4 41.2 0.4 41.2 0.4Z" fill="#1A1A1A"/><path d="M52.5 24L48 24 50.6 9.4 55.1 9.4 52.5 24 52.5 24ZM55.7 5.6L51.2 5.6 52.1 0.4 56.6 0.4 55.7 5.6 55.7 5.6ZM61.9 0.4L64.6 0.4 63.6 5.6 67 5.6 66.4 9.4 63 9.4 61.4 18.4C61.3 18.9 61.4 19.4 61.7 19.7 62 20.1 62.4 20.2 63.1 20.2L66.7 20.2 66 24 61.2 24C60.5 24 59.8 23.9 59.1 23.6 58.5 23.4 58 23 57.6 22.6 57.3 22.1 57 21.6 56.8 21 56.7 20.4 56.7 19.8 56.8 19.1L58.5 9.4 56.9 9.4 61.9 0.4 61.9 0.4Z" fill="#FF4E00"/></g></svg>
);

const Authentication = compose(
  setDisplayName('Authentication'),
  withState('submitted', 'setSubmitted', false),
  withState('emailResent', 'setEmailResent', false),
  withState('error', 'setError', null),
  withProps(({match}) => {
    const search = location.search;
    const href = location.href;

    return {
      activeSection: match.params.section,
      adminAccepted: search.includes('AdminAccepted'),
      adminRejected: search.includes('AdminRejected'),
      milVerification: href.includes('milVerification'),
      postShared: href.includes('CommunityPostShared'),
      adminPostShared: href.includes('AdminPostShared'),
      reviewShared: href.includes('ReviewShared'),
      reviewUrl: search.includes('ReviewShared') && search.split('ReviewShared=').pop(),
      postId: search.includes('CommunityPostShared') && search.split('CommunityPostShared=').pop(),
      adminPostId: search.includes('AdminPostShared') && search.split('AdminPostShared=').pop(),
      proofFile: search.includes('ProofFile'),
      trendingSearch: search.includes('trendingSearch'),
      organizationId: search.split('organizationId=').pop(),
      verificationUrl: search,
      trendingSearchTopic: search.includes('trendingSearch') && search.split('trendingSearch=').pop(),
      secretKey: search.split('secret=').pop(),
      milVerificationUserId: search.match('id=(.*)&secret=') && search.match('id=(.*)&secret=')[1],
      jobOpeningId: search.match('jobOpeningId=(.*)&organizationId=') && search.match('jobOpeningId=(.*)&organizationId=')[1],
      externalJobOpeningId: search.split('externalJobOpeningId=').pop()
    }
  }),
  lifecycle({
    componentDidMount() {
      const {urlParams, confirmAccount} = this.props;

      window.scrollTo(0, 0);

      if(urlParams.mailToken)
        confirmAccount(urlParams.mailToken);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.activeSection !== this.props.activeSection)
        this.props.setError(null);
    }
  }),
  withHandlers({
    togglePassword: ({setPasswordVisible, isPasswordVisible}) => () => setPasswordVisible(!isPasswordVisible),
    submit: ({submit, setSubmitted, adminPostShared, reviewShared, reviewUrl, postShared, postId, adminPostId, milVerification, trendingSearch, trendingSearchTopic, secretKey, milVerificationUserId, location, setError, adminAccepted, adminRejected, history, verificationUrl, organizationId, proofFile, jobOpeningId, externalJobOpeningId}) => values => {
      setSubmitted(values);
      return submit(values)
      .then(({data}) => {
        const userData = jwtDecode(data);

        if (location.state) history.push({pathname: location.state, state: { from: location.pathname }});
        else {
          if (adminAccepted || adminRejected) {
            if(proofFile)
              history.push(`/handle-user-verification${location.search}`)
            else history.push(`/companies/${organizationId}/undefined/overview${verificationUrl}`);
          }
          else if (milVerification)
            history.push(`/auth/callback/mil?id=${milVerificationUserId}&secret=${secretKey}`)
          else if (trendingSearch)
            history.push(`/community/${trendingSearchTopic}`)
          else if (postShared)
            history.push(`/community/post/${postId}`)
          else if (adminPostShared)
            history.push(`/${userData.organizationId ? 'org-admin' : 'dashboard'}/feed/post/${adminPostId}`)
          else if (reviewShared)
            history.push(`/${reviewUrl}`)
          else if (jobOpeningId)
            history.push(`/jobs/${jobOpeningId}/organizations/${organizationId}`)
          else if (externalJobOpeningId)
            history.push(`/jobs/${externalJobOpeningId}/jazzhr`)
        }
      })
      .catch(err => setError(err));
    },
    resendEmail: ({resendEmail, submitted, setEmailResent}) => () => {
      setEmailResent(true);
      return resendEmail({
        email: submitted?.email,
        loginUrl: location.origin.concat('/auth/signin?mailToken={TOKEN}')
      })
    }
  })
)(({urlParams, submit, closeConfirmationMessage, activeSection, resendEmail, emailResent, submitted, error}) => {
  const message = {
    title: "Confirm Your Email Address",
    socialText: "You can now sign in with your social account.",
    text: <p>A confirmation email has been sent to <b>{submitted.email}</b>. Click on the confirmation link in the email to activate your account.</p>
  };

  return (
    <div className="Authentication page">
      <Helmet>
        <title>Vetit — {activeSection === 'Signin' ? 'Signin' : 'Signup'}</title>
      </Helmet>
      <Header isAuth />
      {!urlParams.registered && !urlParams.mailToken &&
        <div className="Authentication__header">
          <h3>Find your path. Make your mark.</h3>
          <div className="Authentication__nav">
            <Link to="/auth/signin" className={cx({active: activeSection === 'signin'})}>Sign in</Link>
            <Link to="/auth/signup" className={cx({active: activeSection === 'signup'})}>Sign up</Link>
          </div>
        </div>
      }
      {activeSection === 'signin' &&
        <div>
          {urlParams.mailToken
            ?
              <div className="container">
                <MessageSection
                  title="Congratulations!"
                  text="Your email has been confirmed."
                >
                  <Link
                    className="btn-primary"
                    onClick={closeConfirmationMessage}
                    to="/auth/signin"
                  >
                    Log in now
                  </Link>
                </MessageSection>
              </div>
            :
              <div className="container" style={containerBackground}>
                <div className="Authentication__card">
                  {/* <div className="Authentication__btns">
                    <a
                      className="btn btn-li btn-linkedin"
                      href={linkedin.loginURI}
                    >
                      <span className="fa fa-linkedin" />
                      <span>Log in with LinkedIn</span>
                    </a>
                    <a
                      className="btn btn-fb"
                      href={facebook.loginURI}
                    >
                      <span className="fa fa-facebook" />
                      <span>Log in with Facebook</span>
                    </a>
                    {urlParams?.error && (
                      urlParams.status == 401 &&
                        <p className="error social-error">Invalid credentials. Note: if you don't have an account — <Link to="/signup">Sign Up.</Link></p>
                      ||
                        <p className="error social-error">Something went wrong. Please try again later</p>
                      )
                    }
                  </div>
                  <span className="Authentication__or">OR</span> */}
                  <Logo />
                  <LoginForm onSubmit={submit} />
                  {error &&
                    <p className="error social-error">{error.message}</p>
                  }
                  <div className="Authentication__verify">
                    <span>Are you an employer?</span>
                    <Link to="/employer">Claim your company's profile</Link>
                  </div>
                </div>
              </div>
            }
        </div>
      }
      {activeSection === 'signup' &&
        <div>
          {urlParams.registered
            ?
              <div className="container">
                <MessageSection
                  title={message.title}
                  text={urlParams.social ? message.socialText : message.text}
                >
                  <button
                    className="btn-primary-outlined Authentication__resend"
                    onClick={() => resendEmail()}
                    disabled={emailResent}
                  >
                    <ReactSVG
                      src={images.messageOrange}
                      aria-label="Resend confirmation email"
                    />
                    Send Again
                  </button>
                  {emailResent &&
                    <span>The confirmation email has been resent</span>
                  }
                </MessageSection>
              </div>
            :
              <div className="container" style={containerBackground}>
                <div className="Authentication__card">
                  {/* <div className="Authentication__btns">
                    <a
                      className="btn btn-li btn-linkedin"
                      href={linkedin.registerURI}
                    >
                      <span className="fa fa-linkedin" />
                      <span>Continue with LinkedIn</span>
                    </a>
                    <a
                      className="btn btn-fb"
                      href={facebook.registerURI}
                    >
                      <span className="fa fa-facebook" />
                      <span>Continue with Facebook</span>
                    </a>
                  </div>
                  <span className="Authentication__or">OR</span> */}
                  <Logo />
                  <SignupForm onSubmit={submit} />
                  {error &&
                    <p className="error social-error">{error.message}</p>
                  }
                  <div className="Authentication__verify">
                    <span>Are you an employer?</span>
                    <Link to="/employer">Claim your company's profile</Link>
                  </div>
                </div>
              </div>
            }
        </div>
      }
    </div>
  )
})

const mapStateToProps = () => ({data: {error}}) => ({
  err: error,
  urlParams: parse(location.search.substr(1))
})

const mapDispatchToProps = (dispatch, {match: params}) => {
  const action = params.params.section === 'signin'
    ? (values) => dispatch(login(values))
    : (values) => dispatch(register(values));

  return {
    submit: action,
    closeConfirmationMessage,
    confirmAccount: mailToken => dispatch(confirmAccount(mailToken)),
    resendEmail
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication));
