import React                from 'react';
import cx                   from 'classnames';
import { images }           from 'theme/img/images';

import './Pagination.sass';


const pagesSubstr = [-3, -2, -1, 0, 1];
const firstPagesSubstr = [0, 1, 2, 3, 4];
const lastPagesSubstr = [-4, -3, -2, -1, 0];

const Pagination = ({itemsCount, className, gotoPage, activePage, disabled, pageSize = 10}) => {
  const pages = Math.ceil(itemsCount / pageSize);
  const backVisible = activePage > 0;
  const nextVisible = activePage < pages - 1;
  const visiblePages = Array(5).fill(0).map((_, i) => {
    if (activePage + 3 > pages) return activePage + lastPagesSubstr[i];
    if (activePage > 3) return activePage + pagesSubstr[i];
    return firstPagesSubstr[i];

  }).filter(p => p >= 0 && p < pages);
  const lastPageVisible = visiblePages.some(p => p == pages - 1);
  const morePages = visiblePages[visiblePages.length - 1] + 3;
  if(pages < 2) return <div />

  return (
    <div className={cx("Pagination", className)}>
      <button
        className="Pagination__arrow Pagination__arrow--left"
        onClick={() => gotoPage(activePage - 1)}
        disabled={!backVisible || disabled}
      >
        <img src={images.arrowLeft} alt="left" />
      </button>
      {visiblePages.map(page => (
        <button
          key={page}
          className={cx("Pagination__page", {active: page == activePage})}
          onClick={() => gotoPage(page)}
          disabled={disabled}
        >
          {page + 1}
        </button>
      ))}
      {!lastPageVisible && activePage < pages - 3 &&
        <button
          className="Pagination__page"
          onClick={() => gotoPage(morePages < pages - 1 ? morePages : pages - 1)}
          disabled={disabled}
        >
          ...
        </button>
      }
      {!lastPageVisible &&
        <button
          className="Pagination__page"
          onClick={() => gotoPage(pages - 1)}
          disabled={disabled}
        >
          {pages}
        </button>
      }
      <button
        className="Pagination__arrow Pagination__arrow--right"
        onClick={() => gotoPage(activePage + 1)}
        disabled={!nextVisible || disabled}
      >
        <img src={images.arrowRight} alt="right" />
      </button>
    </div>
  )
}

export default Pagination;