import React          from 'react';
import { Link }       from 'react-router-dom';
import cx             from 'classnames';
import { createSlug } from 'theme/utils/helpers';

import './LogoLink.sass';


const VetitLogo = () => (
  <svg className="LogoLink__vetit" xmlns="http://www.w3.org/2000/svg" width="67" height="24" viewBox="0 0 67 24"><g fill="none"><path d="M15.6 0.4L20.3 0.4 19.9 2.4 9.2 24 2.7 24 0 5.6 5.4 5.6 6.7 19 15.6 0.4ZM33.4 16.6L21.6 16.6 21.3 18.4C21.2 18.9 21.3 19.4 21.6 19.7 21.9 20.1 22.3 20.2 23 20.2L32 20.2 31.4 24 21.1 24C20.3 24 19.6 23.9 19 23.6 18.4 23.4 17.9 23 17.5 22.6 17.1 22.1 16.8 21.6 16.7 21 16.5 20.4 16.5 19.8 16.6 19.1L18.2 10.5C18.3 9.8 18.5 9.2 18.9 8.6 19.2 8 19.7 7.5 20.2 7.1 21.4 6.1 22.8 5.6 24.3 5.6L30.1 5.6C30.8 5.6 31.5 5.8 32.1 6 32.7 6.2 33.2 6.6 33.6 7.1 34 7.5 34.3 8 34.4 8.6 34.6 9.2 34.6 9.9 34.4 10.5L33.4 16.6 33.4 16.6ZM22.3 12.8L29.5 12.8 29.8 11.2C29.9 10.8 29.8 10.3 29.5 9.9 29.2 9.6 28.8 9.4 28.1 9.4L24.9 9.4C24.3 9.4 23.8 9.6 23.3 9.9 22.9 10.2 22.6 10.7 22.6 11.2L22.3 12.8 22.3 12.8ZM41.2 0.4L43.9 0.4 42.9 5.6 46.3 5.6 45.7 9.4 42.3 9.4 40.7 18.4C40.6 18.9 40.7 19.4 41 19.7 41.3 20.1 41.7 20.2 42.4 20.2L46 20.2 45.3 24 40.4 24C39.7 24 39 23.9 38.4 23.6 37.8 23.4 37.3 23 36.9 22.6 36.5 22.1 36.2 21.6 36.1 21 35.9 20.4 35.9 19.8 36 19.1L37.8 9.4 36.2 9.4 41.2 0.4 41.2 0.4Z" fill="#1A1A1A"/><path d="M52.5 24L48 24 50.6 9.4 55.1 9.4 52.5 24 52.5 24ZM55.7 5.6L51.2 5.6 52.1 0.4 56.6 0.4 55.7 5.6 55.7 5.6ZM61.9 0.4L64.6 0.4 63.6 5.6 67 5.6 66.4 9.4 63 9.4 61.4 18.4C61.3 18.9 61.4 19.4 61.7 19.7 62 20.1 62.4 20.2 63.1 20.2L66.7 20.2 66 24 61.2 24C60.5 24 59.8 23.9 59.1 23.6 58.5 23.4 58 23 57.6 22.6 57.3 22.1 57 21.6 56.8 21 56.7 20.4 56.7 19.8 56.8 19.1L58.5 9.4 56.9 9.4 61.9 0.4 61.9 0.4Z" fill="#FF4E00"/></g></svg>
);

export default function LogoLink({logo, internalId, name, isVetitAdmin}) {
  return (
    <Link
      className={cx("LogoLink", {'LogoLink__placeholder': !logo, isVetitAdmin})}
      to={isVetitAdmin ? '#' : `/companies/${internalId}/${createSlug(name)}/overview`}
      onClick={e => e.stopPropagation()}
    >
      {isVetitAdmin
        ? <VetitLogo />
        : <>
            {logo
              ? <div style={{backgroundImage: `url(${logo})`}} />
              : name && name[0]
            }
          </>
      }
    </Link>
  )
}
