import React                        from 'react';
import { withRouter }               from 'react-router';
import { ReactSVG }                 from 'react-svg';
import { compose,
         setDisplayName,
         withProps,
         withStateHandlers,
         withState,
         withHandlers }             from 'recompose';
import cx                           from 'classnames';
import Loader                       from 'theme/Loader';
import Pagination                   from 'theme/Pagination';
import FunctionBtn                  from 'theme/FunctionBtn';
import SortDropdown                 from 'theme/SortDropdown';
import { options }                  from 'theme/content';
import { withScreenWidthDetection } from 'theme/utils/recompose';
import { images }                   from 'theme/img/images';
import OrganizationFilters          from 'organizationFilters/OrganizationFilters';

import Row                          from './Row';

import './SearchResults.sass';

const SearchResults = withRouter(compose(
  setDisplayName('SearchResults'),
  withScreenWidthDetection(),
  withState('activePage', 'setPage', 0),
  withState('filtersVisible', 'setFiltersVisible', false),
  withState('query', 'setQuery', ''),
  withProps(({match: {path, params}, screenWidth}) => ({
    section: params.filter ? path.substring(0, path.indexOf('/:filter')) : path,
    refEl: React.createRef(),
    isMobile: screenWidth < 992
  })),
  withHandlers({
    scrollToRef: ({refEl}) => () => window.scrollTo(0, refEl.current.offsetTop),
    toggleFilters: ({filtersVisible, setFiltersVisible}) => () => setFiltersVisible(!filtersVisible),
    handleKeyPress: ({search, query}) => e => {
      if (e.key === 'Enter') {
        return search(query);
      }
    }
  }),
  withHandlers({
    filterBy: ({filter, selectedFilters, setPage, sort, query, headerQuery, scrollToRef}) => (page = 0) => {
      setPage(page);
      scrollToRef();

      filter && filter({page, query: query || headerQuery, filters: {
        ...selectedFilters,
        sort
      }})
    },
    setFilters: ({setFilters}) => filterName => value => {
      setFilters(filterName, value);
    }
  }),
  withProps(({partners, filterValues, selectedFilters, section}) => ({
    partnerOptions: partners && partners
      .map(p => ({label: p.name, value: p.id}))
      .sort((a, b) => a.label.localeCompare(b.label)),
    typeFilterOptions: section == '/educators' && filterValues.type?.map(value => ({label: value, value})),
    stateSelectDisabled: selectedFilters.country !== 'USA'
  })),
  withProps(({partnerOptions, typeFilterOptions, setFilters, selectedFilters}) => ({
    companyFilters: [
      {component: 'select', name: 'country', label: 'Country', flex: '1 1 calc(100% / 3)', options: options.countries, value: selectedFilters.country, placeholder: 'Select', onChange: setFilters('country')},
      {component: 'select', name: 'state', label: 'State', flex: '1 1 calc(100% / 3)', options: options.states, value: selectedFilters.state, placeholder: 'Select', onChange: setFilters('state')},
      {component: 'search', name: 'city', value: selectedFilters.city, label: 'City', flex: '1 1 calc(100% / 3)', placeholder: 'Start typing...', onChange: setFilters('city')},
      {component: 'select', name: 'industry', label: 'Industry', flex: '1 1 80%', options: options.industries, value: selectedFilters.industry, placeholder: 'Select', onChange: setFilters('industry')},
      {component: 'select', name: 'type', label: 'Type', flex: '1 1 20%', options: options.companyTypes, value: selectedFilters.type, placeholder: 'Select', onChange: setFilters('type')},
      {component: 'select', name: 'organizationSize', label: 'Organization size', flex: '1 1 calc(100% / 3)', options: options.orgSizeOptions, value: selectedFilters.organizationSize, placeholder: 'Select', onChange: setFilters('organizationSize')},
      {component: 'select', name: 'affinityGroupSize', label: 'Affinity group size', flex: '1 1 calc(100% / 3)', options: options.affinityGroupSizeOptions, value: selectedFilters.affinityGroupSize, placeholder: 'Select', onChange: setFilters('affinityGroupSize')},
      {component: 'search', name: 'partnerId', label: 'Connected partner', flex: '1 1 calc(100% / 3)', options: partnerOptions, value: selectedFilters.partnerId, placeholder: 'Start typing...', onChange: setFilters('partnerId')},
      {component: 'rating', name: 'rating', label: 'Company rating', value: selectedFilters.rating, onChange: setFilters('rating')}
    ],
    educatorFilters: [
      {component: 'select', name: 'country', label: 'Country', flex: '1 1 calc(100% / 3)', options: options.countries, value: selectedFilters.country, placeholder: 'Select', onChange: setFilters('country')},
      {component: 'select', name: 'state', label: 'State', flex: '1 1 calc(100% / 3)', options: options.states, value: selectedFilters.state, placeholder: 'Select', onChange: setFilters('state')},
      {component: 'search', name: 'city', label: 'City', flex: '1 1 calc(100% / 3)', value: selectedFilters.city, placeholder: 'Start typing...', onChange: setFilters('city')},
      {component: 'select', name: 'type', label: 'Type', flex: '1 1 calc(100% / 3)', options: typeFilterOptions, value: selectedFilters.type, placeholder: 'Select', onChange: setFilters('type')},
      {component: 'select', name: 'accreditation', label: 'Accreditation', flex: '1 1 calc(100% / 3)', options: options.accreditationTypes, value: selectedFilters.accreditation, placeholder: 'Select', onChange: setFilters('accreditation')},
      {component: 'select', name: 'selectivity', label: 'Selectiveness', flex: '1 1 calc(100% / 3)', options: options.selectivityCategories, value: selectedFilters.selectivity, placeholder: 'Select', onChange: setFilters('selectivity')},
      {component: 'search', name: 'partnerId', label: 'Connected partner', flex: '0 1 calc(100% / (3 / 2)', options: partnerOptions, value: selectedFilters.partnerId, placeholder: 'Select', onChange: setFilters('partnerId')},
      {component: 'rating', name: 'rating', label: 'School rating', flex: '0 1 calc(100% / (3 / 2)', value: selectedFilters.rating, onChange: setFilters('rating')}
    ],
    partnerFilters: [
      {component: 'search', name: 'cities', label: 'City', flex: '1 1 50%', value: selectedFilters.cities, placeholder: 'Start typing...', onChange: setFilters('cities')},
      {component: 'select', name: 'states', label: 'State', flex: '1 1 50%', options: options.states, value: selectedFilters.states, placeholder: 'Select', onChange: setFilters('states')},
      {component: 'select', name: 'type', label: 'Type', flex: '1 1 50%', options: options.partnerTypes, value: selectedFilters.type, placeholder: 'Select', onChange: setFilters('type')},
      {component: 'select', name: 'geographicScope', label: 'Geo scope', flex: '1 1 50%', options: options.geographicScopeOptions, value: selectedFilters.geographicScope, placeholder: 'Select', onChange: setFilters('geographicScope')}
    ]
  })),
  withProps(({section, companyFilters, educatorFilters, partnerFilters}) => {
    switch(section) {
      case '/companies':
        return { filters: companyFilters, sortOptions: options.companySortOptions }
      case '/educators':
        return { filters: educatorFilters, sortOptions: options.educatorSortOptions }
      default:
        return { filters: partnerFilters, sortOptions: options.partnerSortOptions }
    }
  }),
  withStateHandlers(({sortOptions}) => ({
    activeSortOption: sortOptions[0]
  }),
  {
    sortBy: (_, {setFilters, filter, page, query, headerQuery, selectedFilters}) => (sortOption, direction = null) => {
      setFilters('sort')({dir: direction, value: sortOption.value})
      filter && filter({page, query: query || headerQuery, filters: {
        ...selectedFilters,
        sort: `${sortOption.value}:${direction == 'down' ? 'd' : 'a'}`
      }});

      return {
        activeSortOption: sortOption
      }
    }
  })
)(
  ({results,
    section,
    loading,
    name,
    follow,
    unfollow,
    user,
    filterBy,
    filters,
    selectedFilters,
    stateSelectDisabled,
    activePage,
    itemsCount,
    getAutocomplete,
    getPartnerAutocomplete,
    history,
    query,
    setQuery,
    handleKeyPress,
    search,
    refEl,
    screenWidth,
    isMobile,
    filtersVisible,
    toggleFilters,
    activeSortOption,
    sortOptions,
    sortBy
  }) => (
    <div className="SearchResults">
      <div className="SearchResults__filters card">
        <Loader loading={loading} />
        <div className={cx("SearchResults__filtersHeading", {filtersVisible})}>
          <div className="SearchResults__search">
            <input
              placeholder={isMobile ? 'Search' : `Search for a ${name}`}
              value={query}
              onChange={e => setQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="btn-primary"
              disabled={loading}
              onClick={() => search(query)}
            >
              {isMobile ? <i className="fa fa-search" aria-hidden="true"></i> : 'Search'}
            </button>
          </div>
          <button
            className={cx("SearchResults__filtersOpen btn-primary-outlined", {active: filtersVisible})}
            onClick={toggleFilters}
          >
            <ReactSVG src={filtersVisible ? images.closeWhite : images.iconFilters} alt="toggle filters" />
            <span>Filters</span>
          </button>
        </div>
        {filtersVisible &&
          <OrganizationFilters
            section={section}
            options={options}
            filter={() => filterBy()}
            filterItems={filters}
            stateSelectDisabled={stateSelectDisabled}
            selectedFilters={selectedFilters}
            getAutocomplete={getAutocomplete}
            getPartnerAutocomplete={getPartnerAutocomplete}
            mobile={screenWidth < 769}
          />
        }
      </div>
      <div className="SearchResults__heading">
        <h3>
          <span> {itemsCount} {section.substring(1).split('-').join(' ')} </span>
          found
        </h3>
        <FunctionBtn
          img={{src: images.plus, alt: `Add ${name}`}}
          text={isMobile ? 'Add' : `Add ${name}`}
          action={() => history.push(user ? `${section}/new` : '/auth/signup')}
        />
      </div>
      <div className="SearchResults__sorting">
        <SortDropdown
          selected={activeSortOption}
          sort={sortBy}
          options={sortOptions}
          direction={selectedFilters.sort.dir}
        />
      </div>
      <div
        className="SearchResults__container"
        ref={refEl}
      >
        <Loader loading={loading} />
        {results.length > 0
          ? <div className="SearchResults__table">
              {results.map(r => (
                <Row
                  data={r}
                  key={r.id}
                  prefix={section}
                  follow={follow(activePage, query)}
                  unfollow={unfollow(activePage, query)}
                  user={user}
                  history={history}
                  section={section}
                  isMobile={isMobile}
                />
              ))}
            </div>
          :
            <h5>There are no results matching your query</h5>
        }
      </div>
      <Pagination
        itemsCount={itemsCount}
        gotoPage={filterBy}
        disabled={loading}
        activePage={activePage}
        pageSize={section == '/support-services' ? 10 : 50}
      />
    </div>
  )
))

export default SearchResults;
