import { REQUEST_FAILURE, REQUEST_SUCCESS, CREATE_REQUEST, PAGE_CLEANUP, ACTION_SUCCEEDED } from 'store/types';


export function requestError(error) {
  return { error, type: REQUEST_FAILURE };
}

export function requestSuccess(response, name) {
  return { data: (response && response.data) || response, name, type: REQUEST_SUCCESS };
}

export function createRequest(...args) {
  return { ...args, type: CREATE_REQUEST };
}

export function pageCleanup() {
  return {type:  PAGE_CLEANUP}
}

export function handleError(dispatch) {
  return (error) => {
    dispatch(requestError(error));
    return Promise.reject(error);
  }
}

export function actionSucceeded(message) {
  return {type: ACTION_SUCCEEDED, message}
}
