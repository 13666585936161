import React          from 'react';
import { Link }       from 'react-router-dom';
import cx             from 'classnames';
import Rating         from 'theme/Rating';
import ManagerLogo    from 'theme/ManagerLogo';
import { images }     from 'theme/img/images';

import './SearchResults.sass';


const Buttons = ({followed, handleFollow, profileLink, mobile, user}) => (
  <div className={cx("SearchResults__tableRowButtons", {mobile})}>
    {user &&
      <button
        className={cx("btn-secondary-ghost", {active: followed})}
        onClick={e => handleFollow(e)}
      >
        {followed ? 'Following' : 'Follow'}
      </button>
    }
    <Link
      to={profileLink}
      className="btn-secondary"
    >
      Details
    </Link>
  </div>
);

const RatingContainer = ({rating, reviewCount, mobile}) => (
  <div className={cx("SearchResults__tableRowRating", {mobile})}>
    <Rating
      rating={rating}
      size={mobile ? 'sm' : 'md'}
    />
    <h3>{rating && Math.round(10 * rating) / 10}</h3>
    {reviewCount > 0 &&
      <span> {`(${reviewCount})`}</span>
    }
  </div>
);

const Row = ({data, prefix, section, follow, unfollow, user, isMobile}) => {
  const rating = data?.rating;
  const reviewCount = section == '/companies' ? data.employeeReviewCount : data.programReviewCount;
  const profileLink = `${prefix}/${data.id}/${data.slug}/overview`;
  const location = `${data?.city || ''}${data?.city && data?.state ? ', ' : ''}${data?.state || ''}`;

  const handleFollow = e => {
    e.stopPropagation();
    data.isFollowed ? unfollow(data.id, true) : follow(data.id, true);
  };

  return (
    <div className="SearchResults__tableRow">
      <div className="SearchResults__tableRowLogo">
        <Link to={profileLink}>
          <ManagerLogo
            manager={data}
            size={isMobile ? 'xxs' : 'xl'}
          />
        </Link>
        <RatingContainer
          reviewCount={reviewCount}
          rating={rating}
          mobile
        />
      </div>
      <div className="SearchResults__tableRowContainer">
        <div className="SearchResults__tableRowTop">
          <div className="SearchResults__tableRowInfo">
            <h4>
              {data.name}
              {data.arePremiumFeaturesEnabled &&
                <img
                  src={images.shield}
                  alt="badge"
                  className="PageHeading__badge"
                />
              }
            </h4>
            {rating &&
              <RatingContainer
                className="SearchResults__tableRowRating--desktop"
                reviewCount={reviewCount}
                rating={rating}
              />
            }
          </div>
          <Buttons
            followed={data.isFollowed}
            handleFollow={handleFollow}
            profileLink={profileLink}
            user={user}
          />
        </div>
        <div className="SearchResults__tableRowBottom">
          {location &&
            <span>
              <img src={images.pin} alt="location" />
              {location}
            </span>
          }
          {data.industry &&
            <span className="SearchResults__tableRowIndustry">
              <b>Industry</b> {data.industry}
            </span>
          }
        </div>
        <Buttons
          followed={data.isFollowed}
          handleFollow={handleFollow}
          profileLink={profileLink}
          user={user}
          mobile
        />
      </div>
    </div>
  )
};

export default Row;
