import React                from 'react';
import { connect }          from 'react-redux';
import { Helmet }           from 'react-helmet';
import { withRouter }       from 'react-router';
import { compose,
         withState,
         withProps,
         withStateHandlers,
         withHandlers }     from 'recompose';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import withDebounce,
       { withScroll }       from 'theme/utils/recompose';
import Search               from 'searchResults/SearchResults';
import { getPartners,
         getAutocomplete,
         follow,
         unfollow,
         deletePartner }    from 'partners/actions';

import './Partners.sass';


const Partners = compose(
  withState('activePage', 'setPage', 0),
  withState('modal', 'showModal', false),
  withScroll(),
  withStateHandlers({
    selectedFilters: {
      sort: {
        value: 'name',
        dir: 'up'
      }
    }
  },
  {
    setFilters: ({selectedFilters}) => (filterName, value) => {
      const f = value === null
      ? Object.keys(selectedFilters).reduce((obj, key) => {
        if (key != filterName) {
          obj[key] = selectedFilters[key];
        }
        return obj;
      }, {})
      : {...selectedFilters, [filterName]: value};

      return {selectedFilters: f};
    }
  }),
  withHandlers({
    searchPartners: ({getPartners, page = 0}) => queryValue => {
      return getPartners({page})(queryValue)
    },
    getPartners: ({getPartners, activePage, setPage}) => page => queryValue => {
      setPage(page);
      getPartners({page})(queryValue)
      .catch(() => setPage(activePage));
    },
    filterPartners: ({getPartners, page = 0, selectedFilters}) => (args = {}) => {
      return getPartners({page, filters: selectedFilters, ...args})(args.query);
    },
    change: ({searchDebounced, setQuery}) => e => {
      const query = e.target.value == '' ? null : e.target.value;
      setQuery(query);
      searchDebounced(query);
    },
    deletePartner: ({deletePartner, showModal, activePage, query}) => partner => {
      deletePartner(partner, {page: activePage, query})
      .then(() => showModal(false))
    },
    follow: ({follow, getPartners, selectedFilters, sort, query, urlQuery}) => (activePage, queryValue) => (...args) => {
      return follow(...args)
      .then(() => getPartners({page: activePage, loader: false, filters: {...selectedFilters, sort}})(query || queryValue || urlQuery))
    },
    unfollow: ({unfollow, getPartners, selectedFilters, sort, query, urlQuery}) => (activePage, queryValue) => (...args) => {
      return unfollow(...args)
      .then(() => getPartners({page: activePage, loader: false, filters: {...selectedFilters, sort}})(query || queryValue || urlQuery))
    }
  }),
  withProps(({partners, selectedFilters, topHit, match}) => ({
    partners: topHit ? [topHit, ...partners] : partners,
    sort: selectedFilters.sort && `${selectedFilters.sort.value}:${selectedFilters.sort.dir == 'down' ? 'd' : 'a'}`,
    urlQuery: match.params.filter?.split('=').pop()
  })),
  withDebounce({withLifecycle: true})
)(
  ({partners,
    partnersCount,
    query,
    user,
    filterPartners,
    selectedFilters,
    setFilters,
    loading,
    filterValues,
    sort,
    searchPartners,
    getAutocomplete,
    follow,
    unfollow
  }) => (
  <div className="Partners page">
    <Helmet>
      <title>Vetit — Support Services</title>
    </Helmet>
    <Header />
    <div className="container">
      <Search
        results={partners}
        loading={loading}
        name="service"
        filter={filterPartners}
        filterValues={filterValues}
        user={user}
        headerQuery={query}
        sort={sort}
        follow={follow}
        unfollow={unfollow}
        itemsCount={partnersCount}
        setFilters={setFilters}
        selectedFilters={selectedFilters}
        search={searchPartners}
        getAutocomplete={getAutocomplete}
      />
    </div>
    <Footer />
  </div>
))

const mapStateToProps = ({auth, data: {partners, filter}, header, loading}) => ({
  user: auth.user,
  partners: partners?.data || [],
  partnersCount: partners?.count || 0,
  employersCount: partners?.companiesCount || 0,
  educatorsCount: partners?.educatorsCount || 0,
  topHit: partners?.topHit,
  loading: loading.partners,
  filterValues: filter || [],
  query: header.query
});

const mapDispatchToProps = dispatch => ({
  search: (query) => new Promise((resolve, reject) => dispatch(getPartners({query, page: 0, resolve, reject}))),
  getPartners: (args = {}) => (query) => new Promise((resolve, reject) => dispatch(getPartners({...args, query, resolve, reject, dispatch}))),
  getAutocomplete: (args) => dispatch(getAutocomplete(args)),
  deletePartner: (partner, {page, query} = {}) => dispatch(deletePartner({partner, page, query})),
  follow: (...args) => dispatch(follow(...args)),
  unfollow: (...args) => dispatch(unfollow(...args))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners));
