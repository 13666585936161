import React                      from 'react';
import { reduxForm, Field }       from 'redux-form';
import { TextField, Creatable }   from 'theme/Form';
import Signature                  from 'theme/Signature';
import { required }               from 'theme/utils/validate';

import './AskQuestion.sass';

export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, submitting, pristine, isQuestionEdited, signature, organizationPage, editBtnText, title, initialValues, defaultOptions, getAutocomplete, topicPlaceholder}) => {
    return (
      <div className="AskQuestion">
        <h3>{organizationPage ? 'Write a new post' : title}</h3>
        <form onSubmit={handleSubmit}>
          {!organizationPage &&
            <Field
              name="topicId"
              label="Choose topic"
              component={Creatable}
              required
              validate={required}
              getAutocomplete={getAutocomplete}
              disabled={initialValues?.topicId}
              defaultOptions={defaultOptions}
              placeholder={topicPlaceholder}
              async
            />
          }
          <Field
            name="title"
            label={organizationPage ? 'Post title' : 'Question title'}
            required
            validate={required}
            placeholder={organizationPage ? 'Your post' : 'Your question'}
            component={TextField}
          />
          <Field
            name="content"
            label="Text"
            component={TextField}
            placeholder="Write more details..."
            componentType="markdown"
          />
          {signature &&
            <div className="AskQuestion__signed">
              <span>Your posts are signed with:</span>
              <Signature signature={signature} />
            </div>
          }
          <div className="AskQuestion__save">
            <button
              type="submit"
              className="btn-primary"
              disabled={!isQuestionEdited && (pristine || submitting)}
            >
              {organizationPage ? 'Add your post' : (editBtnText || 'Post your question')}
            </button>
          </div>
        </form>
      </div>
    )
  }
)
