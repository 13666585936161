import React                     from 'react';
import { compose,
         withStateHandlers,
         withState,
         withHandlers,
         setDisplayName }        from 'recompose';
import { reduxForm, Field }      from 'redux-form';
import { Select,
         OrganizationLogoField } from 'theme/Form';
import { createSlug }            from 'theme/utils/helpers';
import TrendingTopics            from '../TrendingTopics';

import './MyFeedForm.sass';


export default compose(
  setDisplayName('MyFeedForm'),
  withState('message', 'setMessage', ''),
  withStateHandlers(({topics}) => ({
    selectedTopics: topics
  }),
  {
    removeTopic: ({selectedTopics}) => topic => ({
      selectedTopics: selectedTopics.filter(t => topic.id !== t.id)
    }),
    addTopicWithSearch: ({selectedTopics}, {setMessage}) => topic => {
      if (selectedTopics.find(t => t.id === topic.value)) {
        setMessage('This topic has already been selected! Choose a different one.');
        return;
      } else
        return {selectedTopics: [...selectedTopics, {name: topic.label, id: topic.value, slug: createSlug(topic.label)}]};
    }
  }),
  withHandlers({
    clearMessage: ({setMessage}) => () => setMessage(''),
    followTopics: ({selectedTopics, followTopic}) => () => {
      return selectedTopics.map(topic => followTopic(topic.id));
    }
  })
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, submitting, initialValues, getOrganizationsAutocomplete, removeTopic, addTopicWithSearch, selectedTopics, message, clearMessage, followTopics, getTopicsAutocomplete}) => {
    return (
      <div className="MyFeedForm">
        <form
          onSubmit={handleSubmit}
          className="MyFeedForm__form"
        >
          <Field
            name="topEmployers"
            component={OrganizationLogoField}
            organizations={initialValues?.companies}
          />
          <Field
            name="topEducators"
            component={OrganizationLogoField}
            organizations={initialValues?.educators}
          />
          <Field
            name="topSupportOrganizations"
            component={OrganizationLogoField}
            organizations={initialValues?.supportServices}
          />
          <div className="MyFeedForm__search">
            <Field
              name="searchedOrganizations"
              placeholder="Search for more"
              component={Select}
              getAutocomplete={getOrganizationsAutocomplete}
              isClearable={false}
              isMulti
              searchIcon
              async
            />
          </div>
          <TrendingTopics
            topics={selectedTopics}
            loadOptions={getTopicsAutocomplete}
            removeTopic={removeTopic}
            addTopicWithSearch={addTopicWithSearch}
            message={message}
            clearMessage={clearMessage}
            taglabel
          />
          <button
            className="MyFeedForm__save btn-primary"
            type="submit"
            disabled={submitting}
            onClick={() => followTopics()}
          >
            Save & Continue
          </button>
        </form>
      </div>
    )
  }
))
