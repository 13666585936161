import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { Link }                       from 'react-router-dom';
import { API_CONF }                   from 'api';
import MessageSection                 from 'theme/MessageSection';
import { verify }                     from 'auth/actions';
import { shareViaLinkedin }           from 'share/actions';
import { actionSucceeded }            from 'store/actions';

import './Verification.sass';

const message = {
  title: 'Thanks for getting verified! You now have full access to the platform!',
  text: 'Please continue completing your information.'
};

function Verification(props) {
  const { user, verify, match: {params: {verificationMethod}}, history, shareViaLinkedin, displaySuccessMessage } = props;
  const [submitted, setSubmitted] = useState(false);

  const userId = location.href.match('id=(.*)&secret=') && location.href.match('id=(.*)&secret=')[1];
  const linkedinShare = JSON.parse(localStorage.getItem('linkedinShare'));

  useEffect(() => {
    if (!user)
      history.push(`/auth/signin?milVerification${location.search}`)
    else {
      verify(userId, verificationMethod)
      .then(() => setSubmitted(true));
    }

    if (verificationMethod == 'linkedin')
      shareViaLinkedin({
        code: location.search.split('code=').pop(),
        redirectUri: API_CONF.LINKEDIN_REDIRECT_DOMAIN,
        content: linkedinShare.text
      })
      .then(() => {
        history.push(linkedinShare.url);
        displaySuccessMessage('You have successfully posted on Linkedin!');
        localStorage.removeItem('linkedinShare');
      })
  }, []);

  return (
    <div className="Verification">
      {submitted
        ?
          <div className="container">
            <MessageSection
              title={message.title}
              text={message.text}
            >
              <Link
                className="btn-primary"
                to={user.isOnboarded ? "/profile-settings/general" : "/onboarding/1"}
              >
                Return
              </Link>
            </MessageSection>
          </div>
        :
          <span className="secondary-text">Verifying...</span>
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  verify: (id, method) => dispatch(verify(id, method)),
  shareViaLinkedin: (args = {}) => dispatch(shareViaLinkedin({...args})),
  displaySuccessMessage: message => dispatch(actionSucceeded(message))
});

export default connect(
  ({auth}) => ({ user: auth.user }),
  mapDispatchToProps
)(Verification);
