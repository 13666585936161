import React      from 'react';
import Loader     from 'theme/Loader';
import Pagination from 'theme/Pagination';
import JobRow     from 'jobs/JobRow';

import './JobList.sass';


export default function JobList({jobs, loading, page, count, searchJobs, handleSave, empty, user, viewJob, activeTab, jobInvitation, pageSize}) {
  return (
    <div className="JobList">
      <Loader loading={loading} />
      {jobs.length > 0
        ?
          <>
            {jobs.map(j => {
              const id = j.id || j.jobOpening?.id || j.jobApplication?.id || j.jobOpeningId;
              return (
                <JobRow
                  key={id}
                  job={j.jobOpening || j.data}
                  status={j.jobApplication?.status}
                  employer={j.organization}
                  link={`/dashboard/jobs/${activeTab}/${j.jobOpening?.id || j.jobOpeningId}`}
                  handleSave={handleSave}
                  viewJob={viewJob}
                  user={user}
                  jobInvitation={jobInvitation}
                />
              )
            })}
            <Pagination
              itemsCount={count}
              gotoPage={searchJobs}
              disabled={loading}
              activePage={page}
              pageSize={pageSize}
            />
          </>
        :
          <div className="JobList__empty">
            <h5>{empty}</h5>
          </div>
      }
    </div>
  )
}
