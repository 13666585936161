import React, { useState } from 'react';
import cx                  from 'classnames';
import CustomCheckbox      from 'theme/CustomCheckbox';
import { images }          from 'theme/img/images';
import { constants }       from 'theme/utils/constants';

import RadioFilters        from './RadioFilters';

import './Filters.sass';

const FilterBox = ({filter, filters, title, closeBox, name, setDateOption, selectedDateOption, selected}) => {
  const [visible, setVisible] = useState(true);
  const toggle = () => setVisible(!visible);

  const size = title == 'Industry' ? constants.JOBS.INDUSTRY_FILTERS_SIZE : constants.JOBS.FILTERS_SIZE;
  const [filterCount, setFilterCount] = useState(size);
  const loadMore = () => setFilterCount(filterCount + size);

  return (
    <div className={cx("Filters__box", {visible})}>
      <div className="Filters__boxHeading">
        <span>{title}</span>
        <span
          className="Filters__clear Filters__clear--box"
          onClick={() => closeBox(name, filters)}
        >
          <img src={images.closeBlack} alt="close" />
        </span>

        <span
          className={cx("Filters__boxHide", {visible})}
          onClick={toggle}
        >
          <img src={images.expand} alt="toggle filter box" />
        </span>
      </div>
      {visible &&
        <div>
          {title === 'Date posted'
            ?
              <RadioFilters
                radioFilter={filters[0]}
                choose={setDateOption}
                selected={selectedDateOption}
              />
            :
              <>
                {filters
                  .slice(0, filterCount)
                  .map((f, i) => (
                    <CustomCheckbox
                      key={i}
                      className="Filters__filterItem"
                      checked={selected(f)}
                      onClick={() => filter(f)}
                      label={f.value}
                    />
                  )
                )}
              </>
          }
          {filterCount && filters.length > filterCount &&
            <button className="Filters__loadMore" onClick={() => loadMore()}>
              <img src={images.plusOrange} alt="Load more" />
              show more
            </button>
          }
        </div>
      }
    </div>
  )
}

const displayFilterValueOnMobile = value => {
  switch(value) {
    case '1970-01-01': return 'Any time';
    case 'now-1M/d': return 'Past month';
    case 'now-1w/d': return 'Past week';
    default: return value;
  }
};

export default function Filters({filter, filters, clearFilters, closeBox, expanded, selectedFilters, setDateOption, selectedDateOption, mobile}) {
  const [visible, setVisible] = useState(mobile ? false : true);
  const toggle = () => setVisible(!visible);

  const selected = f => selectedFilters.find(filter => filter.value === f.value);

  return (
    <div className="Filters card">
      <div className="Filters__heading">
        <div>
          <h5>
            Filters
            {selectedFilters.length > 0 &&
              <span> {`(${selectedFilters.length})`}</span>
            }
          </h5>
          {selectedFilters.filter(f => f.filterName !== 'location').length > 0 &&
            <span
              className="Filters__clear"
              onClick={() => clearFilters()}
            >
              <img src={images.closeBlack} alt="clear all filters" />
              Clear all filters
            </span>
          }
        </div>
        {mobile &&
          <span
            className={cx("Filters__toggle", {visible})}
            onClick={toggle}
          >
            <img src={images.expandBig} alt="toggle filters" />
          </span>
        }
      </div>
      <div className={cx("Filters__selected", {selected: selectedFilters.length > 0, visible: visible && selectedFilters.length > 0})}>
        {selectedFilters.map((f, i) => (
          <CustomCheckbox
            key={i}
            className="Filters__filterItem"
            checked={selected(f)}
            onClick={() => filter(f)}
            label={displayFilterValueOnMobile(f.value)}
          />
        ))}
      </div>
      {visible &&
        <div className="Filters__content">
          {expanded.includes('availableTypes') &&
            <FilterBox
              name="availableTypes"
              filter={filter}
              filters={filters.filter(f => f.type === 'availableTypes')}
              selected={selected}
              selectedFilters={selectedFilters}
              closeBox={closeBox}
              title="Job type"
            />
          }
          {expanded.includes('supportedPrograms') &&
            <FilterBox
              name="supportedPrograms"
              filter={filter}
              filters={filters.filter(f => f.type === 'supportedPrograms')}
              selected={selected}
              selectedFilters={selectedFilters}
              closeBox={closeBox}
              title="Supported programs"
            />
          }
          {expanded.includes('createdAt') &&
            <FilterBox
              name="createdAt"
              filter={filter}
              filters={filters.filter(f => f.type === 'createdAt')}
              selected={selected}
              selectedFilters={selectedFilters}
              closeBox={closeBox}
              title="Date posted"
              setDateOption={setDateOption}
              selectedDateOption={selectedDateOption}
            />
          }
          {expanded.includes('benefits') &&
            <FilterBox
              name="benefits"
              filter={filter}
              filters={filters.filter(f => f.type === 'benefits')}
              selected={selected}
              selectedFilters={selectedFilters}
              closeBox={closeBox}
              title="Benefits"
            />
          }
          {expanded.includes('industry') &&
            <FilterBox
              name="industry"
              filter={filter}
              filters={filters.filter(f => f.type === 'industry')}
              selected={selected}
              selectedFilters={selectedFilters}
              closeBox={closeBox}
              title="Industry"
            />
          }
        </div>
      }
    </div>
  )
}
