import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { compose,
         lifecycle,
         withState,
         withProps,
         withHandlers }       from 'recompose';
import cx                     from 'classnames';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Loader                 from 'theme/Loader';
import AdminNav               from 'theme/AdminNav';
import Modal                  from 'theme/Modal';
import Confirm                from 'theme/Confirm';
import Pagination             from 'theme/Pagination';
import Gallery                from 'theme/Gallery';
import Thumbnail              from 'theme/Thumbnail';
import MarkdownEditor         from 'theme/MarkdownEditor';
import { withModal,
         withGallery }        from 'theme/utils/recompose';
import { formatDate }         from 'theme/utils/helpers';
import { addAdminPost,
         getAdminPosts,
         updateAdminPost,
         deleteAdminPost }    from 'admin/actions';
import { getAutocomplete as
         getEdAutocomplete }  from 'educators/actions';
import { getAutocomplete as
         getCoAutocomplete }  from 'companies/actions';
import { getAutocomplete as
         getPaAutocomplete }  from 'partners/actions';

import Form                   from './Form';

import './AdminPosts.sass';

const modalNames = {
  NEW_POST: 'NEW_POST',
  EDIT_POST: 'EDIT_POST',
  DELETE_POST: 'DELETE_POST',
  GALLERY: 'GALLERY'
}

const modalTitles = {
  NEW_POST: 'New Post',
  EDIT_POST: 'Edit Post',
  DELETE_POST: 'Delete Post'
}

const postTypes = ['Text', 'Image', 'Video'];
const formName = 'AdminPostForm';

const AdminPosts = compose(
  withState('activePage', 'setPage', 0),
  withState('orgType', 'setOrgType', 'Educators'),
  withState('postType', 'setPostType', 'Text'),
  withModal(),
  withGallery(),
  withProps(({match}) => ({
    organizationId: match.params.id
  })),
  withHandlers({
    setOrgType: ({orgType, setOrgType}) => () => {
      switch(orgType) {
        case 'Educators':
          return setOrgType('Employers');
        case 'Employers':
          return setOrgType('Support Services');
        case 'Support Services':
          return setOrgType('Educators');
      }
    },
    getAutocomplete: ({orgType, getEdAutocomplete, getCoAutocomplete, getPaAutocomplete, name = 'name'}) => value => {
      const req = orgType === 'Educators' ? getEdAutocomplete : orgType === 'Employers' ? getCoAutocomplete : getPaAutocomplete;
      if (value === '') return;
      return req({field: name, prefix: value})
      .then(res => res.map(r => ({
        label: r.name,
        value: r.id
      })));
    },
    searchAdminPosts: ({getAdminPosts, setPage}) => page => {
      setPage(page);
      getAdminPosts({page});
    },
    addAdminPost: ({addAdminPost, closeModal, activePage}) => values => {
      return addAdminPost(values, {page: activePage})
      .then(() => closeModal());
    },
    updateAdminPost: ({updateAdminPost, closeModal, activePage}) => values => {
      return updateAdminPost(values, {page: activePage})
      .then(() => closeModal());
    },
    deleteAdminPost: ({deleteAdminPost, closeModal, activePage}) => post => {
      return deleteAdminPost(post, {page: activePage})
      .then(() => closeModal());
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAdminPosts();
    }
  })
)(({
  posts,
  postsCount,
  loading,
  modal,
  openModal,
  closeModal,
  orgType,
  setOrgType,
  getAutocomplete,
  setPostType,
  postType,
  addAdminPost,
  updateAdminPost,
  deleteAdminPost,
  searchAdminPosts,
  activePage,
  organizationId,
  prev,
  next,
  imageIndex,
  openGallery
}) => {
  const formProps = {
    form: formName,
    title: modalTitles[modal.name],
    orgType,
    setOrgType,
    getAutocomplete,
    organizationId,
    postTypes,
    setPostType
  };

  const initialValues = {
    ...modal.payload,
    ...(modal.payload?.organization && {organizationId: {
      value: modal?.payload?.organization.internalId || modal?.payload?.organization.id,
      label: modal?.payload?.organization.name
    }})
  };

  return (
    <div className="AdminPosts page">
      <Header />
      <AdminNav />
      <div className="container admin-container">
        <div className="AdminPosts__heading">
          <h3>Feature Posts</h3>
          <button className="btn-primary" onClick={() => openModal(modalNames.NEW_POST)}>+ POST</button>
        </div>
        <div className="AdminPosts__table">
          <Loader
            className="AdminPosts__loader"
            loading={loading}
          />
          {posts.length > 0 && !loading
            ?
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Text</th>
                    {!organizationId &&
                      <th>Organization</th>
                    }
                    <th>Type</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {posts.map(p => {
                    return (
                      <tr key={p.id}>
                        <td>
                          <Thumbnail
                            item={p}
                            openGallery={openGallery(p)}
                            className={cx({noMedia: !p.images && !p.video})}
                          />
                        </td>
                        <td data-th="Text">
                          <MarkdownEditor
                            value={p.text}
                            readOnly
                          />
                        </td>
                        {!organizationId &&
                          <td data-th="Organization">{p.organization && p.organization.name}</td>
                        }
                        <td data-th="Type">{p.type}</td>
                        <td data-th="Date">{formatDate(p.createdAt)}</td>
                        <td>
                          <span className="fa fa-pencil" onClick={() => openModal(modalNames.EDIT_POST, p)}></span>
                          <span className="fa fa-trash" onClick={() => openModal(modalNames.DELETE_POST, p)}></span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            :
              <h5>You haven't added any posts yet!</h5>
            }
        </div>
      </div>
      <Pagination
        itemsCount={postsCount}
        gotoPage={searchAdminPosts}
        disabled={loading}
        activePage={activePage}
      />
      <Footer />

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        className="AdminPosts__modal"
        isGallery={modal.name === 'GALLERY'}
      >
        {(() => {
          switch(modal.name) {
            case 'NEW_POST':
              return (
                <Form
                  onSubmit={addAdminPost}
                  postType={postType}
                  {...formProps}
                />
              )
            case 'EDIT_POST':
              return (
                <Form
                  initialValues={initialValues}
                  onSubmit={updateAdminPost}
                  postType={modal.payload && modal.payload.type}
                  isEdited
                  {...formProps}
                />
              )
            case 'DELETE_POST':
              return (
                <Confirm
                  question="Are you sure you want to delete this post?"
                  close={closeModal}
                  action={() => deleteAdminPost(modal)}
                />
              )
            case 'GALLERY':
              return (
                <Gallery
                  images={modal.payload.images}
                  currentIndex={imageIndex}
                  prev={() => prev(modal.payload.images, imageIndex)}
                  next={() => next(modal.payload.images, imageIndex)}
                />
              )
          }
        })()}
      </Modal>
    </div>
  )
})

const mapStateToProps = ({data, loading}) => ({
  posts: data?.admin_posts?.data || [],
  postsCount: data?.admin_posts?.count || 0,
  loading: loading.admin_posts
});

const mapDispatchToProps = (dispatch, {match}) => {
  const organizationId = match.params.id;

  return {
    getAdminPosts: (args = {}) => new Promise((resolve, reject) => dispatch(getAdminPosts({...args, organizationId, resolve, reject}))),
    addAdminPost: (values, {page = {}}) => dispatch(addAdminPost({values, page, organizationId, form: formName})),
    updateAdminPost: (values, {page} = {}) => dispatch(updateAdminPost({values, page, organizationId, form: formName})),
    deleteAdminPost: (post, {page} = {}) => dispatch(deleteAdminPost({post, page, organizationId})),
    getEdAutocomplete: (args) => dispatch(getEdAutocomplete(args)),
    getCoAutocomplete: (args) => dispatch(getCoAutocomplete(args)),
    getPaAutocomplete: (args) => dispatch(getPaAutocomplete(args))
  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPosts));
