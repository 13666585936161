import React                 from 'react';
import { connect }           from 'react-redux';
import { Link }              from 'react-router-dom';
import { compose,
         setDisplayName,
         lifecycle,
         withState,
         withHandlers,
         withStateHandlers } from 'recompose';
import { pluck }             from 'underscore';
import Loader                from 'theme/Loader';
import { getTopics }         from 'comments/actions';

import './Topics.sass';


const TOPIC_SIZE = 48;

const TopicsCard = compose(
  setDisplayName('TopicsCard'),
  withState('page', 'setPage', 0),
  withState('loading', 'setLoading', false),
  withStateHandlers({
    topics: []
  },
  {
    addTopics: ({topics}) => i => {
      const ids = pluck(topics, 'id');
      const uniq = i.filter(ii => ids.indexOf(ii.id) == -1);
      return {topics: [].concat(topics, uniq)};
    },
    setTopics: () => topics => ({topics})
  }),
  withHandlers({
    getData: ({getTopics, type, setTopics, addTopics, setLoading}) => () => {
      return getTopics(type)
      .then(res => {
        setTopics(res.data);
        addTopics(res.data);
        setLoading(false);
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.setLoading(true);
      this.props.getData();
    }
  }),
  withHandlers({
    showMore: ({page, setPage, getTopics, loading, addTopics, count, topics, type}) => () => {
      if (loading || (topics.length >= count)) return;
      const pagesCount = Math.ceil(count / TOPIC_SIZE);
      const newPage = page + 1 > pagesCount ? pagesCount : page + 1;

      return getTopics(type, newPage)
      .then(response => {
        addTopics(response.data);
        setPage(newPage);
      });
    }
  })
)(({topics, count, title, loading, showMore}) => {
  return (
    <div className="Topics__card">
      <h4>{title}</h4>
      <div className="card">
        <Loader loading={loading} />
        <div className="Topics__list">
          {topics && topics.map(t => (
            <Link
              key={t.id}
              className="Topics__link"
              to={`/community/${t.id}`}
            >
              {t.name}
            </Link>
          ))}
        </div>
        {count >= TOPIC_SIZE && count !== topics.length &&
          <div
            className="Topics__loadMore"
            onClick={() => showMore()}
          >
            Load more
          </div>
        }
      </div>
    </div>
  )
})

const mapStateToProps = ({data}, props) => {
  const topics = data[`topics_${props.type.toLowerCase()}`] || {};

  return {
    topics: topics?.data || [],
    count: topics?.count || 0
  }
};

const mapDispatchToProps = dispatch => ({
  getTopics: (topicType, page) => new Promise((resolve, reject) => dispatch(getTopics({topicType, page, pageSize: TOPIC_SIZE, resolve, reject})))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicsCard);
