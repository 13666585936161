
import { API_CONF } from 'api';


const redirectFacebook = {
  base: API_CONF.FACEBOOK_REDIRECT_DOMAIN,
  login: API_CONF.FACEBOOK_REDIRECT_DOMAIN.concat('/signin'),
  register: API_CONF.FACEBOOK_REDIRECT_DOMAIN.concat('/signup'),
  loginUri: encodeURIComponent(API_CONF.FACEBOOK_REDIRECT_DOMAIN.concat('/signin')),
  registerUri: encodeURIComponent(API_CONF.FACEBOOK_REDIRECT_DOMAIN.concat('/signup')),
  scope: encodeURIComponent('public_profile,email')
}

const redirectLinkedin = {
  base: API_CONF.LINKEDIN_REDIRECT_DOMAIN,
  login: API_CONF.LINKEDIN_REDIRECT_DOMAIN.concat('/signin'),
  register: API_CONF.LINKEDIN_REDIRECT_DOMAIN.concat('/signup'),
  loginUri: encodeURIComponent(API_CONF.LINKEDIN_REDIRECT_DOMAIN.concat('/signin')),
  registerUri: encodeURIComponent(API_CONF.LINKEDIN_REDIRECT_DOMAIN.concat('/signup')),
  scope: encodeURIComponent('r_liteprofile r_emailaddress')
}

export const facebook = {
  base: redirectFacebook,
  loginURI: `https://www.facebook.com/v2.12/dialog/oauth?response_type=token&scope=${redirectFacebook.scope}&client_id=${API_CONF.FACEBOOK_CLIENT_ID}&state=${API_CONF.STATE_PARAM}&redirect_uri=${redirectFacebook.loginUri}`,
  registerURI: `https://www.facebook.com/v2.12/dialog/oauth?response_type=token&scope=${redirectFacebook.scope}&client_id=${API_CONF.FACEBOOK_CLIENT_ID}&state=${API_CONF.STATE_PARAM}&redirect_uri=${redirectFacebook.registerUri}`,
  state: API_CONF.STATE_PARAM,
}

export const linkedin = {
  base: redirectLinkedin,
  loginURI: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=${redirectLinkedin.scope}&client_id=${API_CONF.LINKEDIN_CLIENT_ID}&state=${API_CONF.STATE_PARAM}&redirect_uri=${redirectLinkedin.loginUri}`,
  registerURI: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=${redirectLinkedin.scope}&client_id=${API_CONF.LINKEDIN_CLIENT_ID}&state=${API_CONF.STATE_PARAM}&redirect_uri=${redirectLinkedin.registerUri}`,
  state: API_CONF.STATE_PARAM,
}
