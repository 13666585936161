import React                      from 'react';
import { connect }                from 'react-redux';
import { Helmet }                 from 'react-helmet';
import { compose,
         withProps,
         withStateHandlers,
         withHandlers,
         withState }              from 'recompose';
import Footer                     from 'theme/Footer';
import Header                     from 'theme/Header';
import withDebounce               from 'theme/utils/recompose';
import { getCompanies,
         getAutocomplete,
         follow,
         unfollow }               from 'companies/actions';
import { getAutocomplete as
         getPartnerAutocomplete } from 'partners/actions';
import Search                     from 'searchResults/SearchResults';

import './Companies.sass';


const Companies = compose(
  withState('activePage', 'setPage', 0),
  withState('industry', 'setIndustry', null),
  withStateHandlers({
    selectedFilters: {
      sort: {
        value: 'employeeReviewCount',
        dir: 'down'
      }
    }
  },
  {
    setFilters: ({selectedFilters}) => (filterName, value) => {
      const f = value === null
      ? Object.keys(selectedFilters).reduce((obj, key) => {
        if (key != filterName) {
          obj[key] = selectedFilters[key];
        }
        return obj;
      }, {})
      : {...selectedFilters, [filterName]: value};

      return {selectedFilters: f};
    }
  }),
  withProps(({selectedFilters, match}) => ({
    sort: selectedFilters.sort && `${selectedFilters.sort.value}:${selectedFilters.sort.dir == 'down' ? 'd' : 'a'}`,
    urlQuery: match.params.filter?.split('=').pop()
  })),
  withHandlers({
    search: ({getCompanies, page = 0, query, urlQuery}) => () => getCompanies(page)(query || urlQuery),
    searchCompanies: ({getCompanies, page = 0}) => queryValue => {
      return getCompanies({page})(queryValue)
    },
    getCompanies: ({getCompanies, activePage, query, setPage}) => page => {
      setPage(page);
      getCompanies({page})(query)
      .catch(() => setPage(activePage));
    },
    filterCompanies: ({getCompanies, page = 0}) => (args = {}) => {
      return getCompanies({page, ...args})(args.query);
    },
    follow: ({follow, getCompanies, selectedFilters, sort, query, urlQuery}) => (activePage, queryValue) => (...args) => {
      return follow(...args)
      .then(() => getCompanies({page: activePage, loader: false, filters: {...selectedFilters, sort}})(queryValue || query || urlQuery))
    },
    unfollow: ({unfollow, getCompanies, selectedFilters, sort, query, urlQuery}) => (activePage, queryValue) => (...args) => {
      return unfollow(...args)
      .then(() => getCompanies({page: activePage, loader: false, filters: {...selectedFilters, sort}})(queryValue || query || urlQuery))
    }
  }),
  withDebounce({withLifecycle: true})
)(({employers,
    query,
    loading,
    employersCount,
    user,
    follow,
    unfollow,
    partners,
    setFilters,
    selectedFilters,
    filterCompanies,
    sort,
    searchCompanies,
    getAutocomplete,
    getPartnerAutocomplete,
    topHit
  }) => (
  <div className="Companies page">
    <Helmet>
      <title>Vetit – Employers</title>
    </Helmet>
    <Header />
    <div className="container">
      <Search
        results={topHit ? [topHit, ...employers] : employers}
        name="company"
        loading={loading}
        user={user}
        itemsCount={employersCount}
        follow={follow}
        unfollow={unfollow}
        headerQuery={query}
        partners={partners}
        filter={filterCompanies}
        setFilters={setFilters}
        selectedFilters={selectedFilters}
        sort={sort}
        search={searchCompanies}
        getAutocomplete={getAutocomplete}
        getPartnerAutocomplete={getPartnerAutocomplete}
      />
    </div>
    <Footer />
  </div>
))

const mapStateToProps = ({auth, header, data: {companies}, loading}) => ({
  user: auth.user,
  employers: companies?.data || [],
  employersCount: companies?.count || 0,
  topHit: companies?.topHit,
  educatorsCount: companies?.educatorsCount || 0,
  partners: companies?.partners || [],
  partnersCount: companies?.partnersCount || 0,
  loading: loading.companies,
  query: header.query
})

const mapDispatchToProps = (dispatch) => ({
  getCompanies: (args = {}) => query => new Promise((resolve, reject) => dispatch(getCompanies({...args, query, resolve, reject, dispatch}))),
  getAutocomplete: args => dispatch(getAutocomplete(args)),
  getPartnerAutocomplete: args => dispatch(getPartnerAutocomplete(args)),
  follow: (...args) => dispatch(follow(...args)),
  unfollow: (...args) => dispatch(unfollow(...args))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies)
