import React, { useEffect } from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import Loader               from 'theme/Loader';
import ManagerStats         from 'theme/ManagerStats';
import { images }           from 'theme/img/images';
import ManagerLogo          from 'theme/ManagerLogo';
import { getOrganization }  from 'organizations/actions';
import { getJobReviews }    from 'companies/actions';

import './FirstTime.sass';


function FirstTime({user, organization, loading, getOrganization, getJobReviews, companyReviews, reviewsCount, history}) {
  useEffect(() => {
    getOrganization(user.organizationId)
    .then(response => {
      if(!response.onboarded)
        history.push('/employer-onboarding/1');
    })
    getJobReviews(user.organizationId);
  }, []);

  return (
    <div className="FirstTime">
      <Loader loading={loading} />
      <h3 className="FirstTime__title">Existing profile</h3>
      <div className="FirstTime__info">
        <div className="FirstTime__infoContainer">
          <ManagerLogo
            className="FirstTime__logo"
            manager={organization}
            size="xl"
          />
          <div>
            <h3>{organization.name}</h3>
            <a
              href={organization.url}
              target="_blank"
            >
              {organization.url}
              <img src={images.externalLink} alt="Go to website" />
            </a>
            <div className="FirstTime__hq">
              <span>Location</span>
              <span>{organization.hq}</span>
            </div>
          </div>
        </div>
      </div>
      {organization.statistics?.employerStatistics &&
        <div>
          <h4>Employee Experiences</h4>
          <ManagerStats
            className="FirstTime__statistics"
            statistics={organization.statistics.employerStatistics}
            reviews={companyReviews}
            allReviewsCount={reviewsCount}
          />
        </div>
      }
      <div className="FirstTime__experiences">
      </div>
      <div className="FirstTime__actions">
        <h3>What would you like to do next?</h3>
        <div>
          <Link to="/employer-onboarding/1" className="btn-primary">Edit profile</Link>
          <Link to="/org-admin/jobs/new" className="btn-primary">Post jobs</Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({auth, data, loading}) => {
  const id = auth.user.organizationId;

  return {
    user: auth.user,
    loading: loading[`organization:employer:${id}`] && loading[`company:${id}:reviews`],
    companyReviews: (data[`company:${id}:reviews`] && data[`company:${id}:reviews`].data) || [],
    reviewsCount: (data[`company:${id}:reviews`] && data[`company:${id}:reviews`].count) || 0,
    organization: (data && data[`organization:employer:${id}`]) || {}
  };
};

const mapDispatchToProps = dispatch => ({
  getOrganization: (organizationId) => new Promise((resolve, reject) => dispatch(getOrganization(organizationId, 'employer', {resolve, reject}))),
  getJobReviews: (organizationId) => new Promise((resolve, reject) => dispatch(getJobReviews(organizationId, {resolve, reject})))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstTime));
