import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import { compose,
        withState,
        withProps,
        withStateHandlers,
        withHandlers,
        lifecycle }         from 'recompose';
import { pick }             from 'underscore';
import withDebounce         from 'theme/utils/recompose';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Loader               from 'theme/Loader';
import Modal                from 'theme/Modal';
import MessageSection       from 'theme/MessageSection';
import { withScroll }       from 'theme/utils/recompose';
import { getProfile }       from 'auth/actions';
import EducatorForm         from 'educators/EducatorForm';

import Form                 from './Form';
import { getEducators,
         getAutocomplete,
         addProgramReview,
         getEducator,
         addEducator,
         getReview,
         updateReview }     from '../actions';

import './ProgramReview.sass'


const formName = "CompanyProgramReview";
const EducatorFormName = "EducatorForm";

const ProgramReview = compose(
  withScroll(),
  withState('submitted', 'setSubmitted', false),
  withStateHandlers({
    modalOpened: false,
    orgName: ''
  },
  {
    openModal: () => (orgName) => ({
      modalOpened: true,
      orgName
    }),
    closeModal: () => () => ({modalOpened: false})
  }),
  withHandlers({
    nextProgramReview: (props) => () => props.setSubmitted(false),
    submit: ({submit, setSubmitted}) => (values) => {
      return submit(values)
      .then(() =>
        setSubmitted(values.educator.id || values.educator)
      )
    },
    addEducator: ({addEducator, closeModal}) => (values) => {
      return addEducator(values)
      .then(closeModal);
    },
    getAutocomplete: ({getAutocomplete, name = 'name'}) => value => {
      if (value === '') return;
      return getAutocomplete({field: name, prefix: value});
    }
  }),
  withDebounce({
    functionName: 'search',
    debouncedName: 'searchEducators',
    withLifecycle: true
  }),
  lifecycle({
    componentDidMount () {
      const { getReview, match, getEducator, user, getProfile } = this.props;

      if(match.params.reviewId)
        getReview();
      if(match.params.educatorId)
        getEducator();
      if (user && Object.keys(user).length)
        getProfile(user);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.submitted != this.props.submitted)
        window.scrollTo(0, 0);
    }
  }),
  withProps(({match: {params}, educator}) => {
    return {
      educatorId: params.educatorId,
      educatorName: educator.name,
      educatorSlug: educator.slug
    }
  }),
)(({review,
    submitted,
    nextProgramReview,
    submit,
    searchEducators,
    educators,
    loadingEducators,
    educatorName,
    educatorId,
    educatorSlug,
    profile,
    loadingEducator,
    modalOpened,
    openModal,
    closeModal,
    orgName,
    addEducator,
    match,
    getAutocomplete
  }) => {
    const initialValues = !match.params.reviewId
      ? { educator: {id: educatorId, name: educatorName},
      reviewerDetails: pick(profile, 'mos', 'etsPaygrade', 'serviceBranch', 'email')}
      :  {...review, educator: {id: educatorId, name: educatorName}};

    const message = {
      title: `Thanks for paying it forward! ${match.params.reviewId ? 'Your review has been updated.' : 'Thanks for reviewing your student experience with our community!'}`
    };

    const redirectLink = match.params.reviewId
      ? history.state.state.prevPath
      : `/educators/${submitted}/${educatorSlug}/overview`;

    return (
      <div className="ProgramReview page">
        <Helmet>
          <title>Vetit — {educatorName || ''} Student Review</title>
        </Helmet>
        <Header />
        {submitted
        ?
          <div className="container">
            <MessageSection {...message}>
              <Link
                className="btn-primary"
                onClick={nextProgramReview}
                to={redirectLink}
              >
                Return
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <Loader loading={loadingEducator} />
            {!loadingEducator &&
              <Form
                onSubmit={submit}
                searchEducators={searchEducators}
                educators={educators}
                loadingEducators={loadingEducators}
                form={formName}
                initialValues={initialValues}
                profile={profile}
                openModal={openModal}
                editDisabled={Boolean(match.params.reviewId)}
                getAutocomplete={getAutocomplete}
              />
            }
            <div className="ProgramReview__sidebar">
              <div className="flag first"></div>
              <p className="secondary-text">Informational Interview</p>
              <p className="content-text">This review form allows you to share details about your education and training experiences. </p>
              <div className="flag second"></div>
              <p className="secondary-text">Be Constructive!</p>
              <p className="content-text">We value candid insights much more than rose-colored ones, but be professional! Profanity will be removed. </p>
              <p className="content-text">Respond as though a close military friend is reaching out to learn more about this specific experience.</p>
        {/*   <p className="secondary-text">Need help? Check here:</p>
              <Link className="content-text link" to="#">Military Occupational Specialty table {'>>'}</Link>
              <Link className="content-text link" to="#">ETS Paygrade tables {'>>'}</Link>
        */}
              <div className="flag third"></div>
              <p className="secondary-text">Answer Military Specific Questions</p>
              <p className="content-text">-What should students expect in terms of the GI Bill? Housing allowance? Tuition Assistance?</p>
              <p className="content-text">-What were your interactions with other veterans like? Veteran Groups/Centers?</p>
              <p className="content-text">-Did you have any issues transferring/articulating credits to/from another school?</p>
              <p className="content-text">-How are veterans treated on campus?</p>
              <p className="secondary-text">Answer General Questions</p>
              <p className="content-text">-What other schools and programs did you consider?</p>
              <p className="content-text">-Why did you ultimately choose this program?</p>
              <p className="content-text">-What are/were your expected goals and outcomes? How have they been met?</p>
              <p className="content-text">-What is a typical class/day like?</p>
              <p className="content-text">-What was your most rewarding class? Most challenging?</p>
              <p className="content-text">-What impressed and/or suprised you the most about the program?</p>
              <p className="content-text">-Is there any other insight you'd share with veterans who are comparing this program to others that are similar?</p>
              <div className="flag fourth"></div>
              <p className="secondary-text">Your Review Will Remain Discreet! </p>
              <p className="content-text">Because our community can learn the most from open, honest insights like yours, we want you to feel safe sharing them.</p>
            </div>
          </div>
        }
        <Footer />

        <Modal
          isOpen={modalOpened}
          close={closeModal}
          className="ProgramReview__modal"
        >
          <h4 className="title">New educator</h4>
          {orgName &&
            <EducatorForm
              initialValues={{name: orgName}}
              onSubmit={addEducator}
              form={EducatorFormName}
            />
          }
        </Modal>
      </div>
    )
  }
)

const mapStateToProps = ({auth, data, loading}, {match}) => {
  const { educators, profile } = data;
  const id = match.params.educatorId;
  const reviewId = match.params.reviewId;

  return {
    user: auth.user,
    educators: educators?.data || [],
    educator: data[`educator:${id}`] || {},
    loadingEducators: loading.educators,
    profile: profile || {},
    loadingEducator: loading[`educator:${id}`],
    loadingProfile: loading.profile || false,
    review: (data && data[`review:${reviewId}`]) || {metadata: {}, reviewerDetails: {}}
  }
}

const mapDispatchToProps = (dispatch, {match}) => {
  const id = match.params.educatorId;
  const reviewType = 'programs';
  const reviewId = match.params.reviewId;

  return {
    getEducator: () => new Promise((resolve, reject) => dispatch(getEducator(id, {resolve, reject}))),
    getAutocomplete: (args) => dispatch(getAutocomplete(args)),
    search: (query) => new Promise((resolve, reject) => dispatch(getEducators({query, resolve, reject}))),
    submit: (values) => reviewId ?
      dispatch(updateReview(id, reviewType, reviewId, values))
      : new Promise((resolve, reject) => dispatch(addProgramReview({values, resolve, reject, form: formName}))),
    getProfile: (user) => dispatch(getProfile(user.id)),
    addEducator: (values) => new Promise((resolve, reject) => dispatch(addEducator({values, form: EducatorFormName, resolve, reject}))),
    getReview: () => new Promise((resolve, reject) => dispatch(getReview({educatorId: id, reviewType, reviewId, resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramReview))
