import React                  from 'react';
import { Link }               from 'react-router-dom';
import { reduxForm,
         Field }              from 'redux-form';
import { compose,
         setDisplayName }     from 'recompose';
import { TextField, Radio }   from 'theme/Form';
import { withPasswordToggle } from 'theme/utils/recompose';


const validate = values => {
  const errors = {};

  if(!values.password) {
    errors.password = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }

  return errors;
}

export default compose(
  setDisplayName('LoginForm'),
  withPasswordToggle(),
)(reduxForm({
  form: 'LoginForm',
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, pristine, submitting, err, urlParams, checkPasswordVisible, togglePassword}) => {
    return (
      <div className="Authentication__form">
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="email"
              component={TextField}
              label="Email"
              placeholder="Type your e-mail"
            />
            <Field
              name="password"
              type={checkPasswordVisible('password') ? 'text' : 'password'}
              component={TextField}
              isPasswordVisible={checkPasswordVisible('password')}
              togglePassword={togglePassword}
              label="Password"
              placeholder="Type your password"
            />
            <Link to="/reset-password" className="Authentication__forgotPassword">Forgot password?</Link>
          </div>
          <div className="Authentication__remember">
            {err && !urlParams.error && (
              err.status == 401 &&
                <p className="error">E-Mail address or password is invalid</p>
              ||
                <p className="error">Something went wrong. Please try again later</p>
            )}
            <Field
              name="remember"
              component={Radio}
              label="Remember me"
              className="Login__radio remember"
              mode="checkbox"
            />
          </div>
          <div className="Authentication__authBtn">
            <button className="btn-primary" type="submit" disabled={submitting || pristine}>
              {submitting && <i className="fa fa-spin fa-spinner" />}
              Log in
            </button>
          </div>
        </form>
      </div>
    )
  }
))
