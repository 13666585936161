import api                 from 'api';
import { mapReviewAction } from 'theme/utils/helpers';

function fetchReviews({query, page = 0, pageSize = 10, sort}) {
  return api({
    method: 'get',
    url: '/admin-feed',
    params: {
      query,
      page,
      pageSize,
      sort: sort || 'reviewCreatedAt:d'}
  });
}

export const deleteReview = (type, review, {page = 0} = {}, withDispatch = true) => {
  const url = type.organization === 'partner'
  ? `/${type.organization}s/${review.organization.id}/reviews/${review.id}`
  : `/${type.organization}s/${review.organization.id}/reviews/${type.review}s/${review.id}`;

  if (withDispatch) {
    return (dispatch) => {
      return api({ method: 'delete', url })
      .then(() => dispatch(getAdminReviews({page})));
    }
  } else {
    return () => api({ method: 'delete', url });
  }
};

function fetchOrgs({page = 0, pageSize = 10}) {
  const companiesReq = api({
    method: 'get',
    url: '/employers',
    params: {page, pageSize}
  });

  const educatorsReq = api({
    method: 'get',
    url: '/educators',
    params: {page, pageSize}
  });

  return Promise.all([companiesReq, educatorsReq])
  .then(([companies, educators]) => {
    const data = [].concat(companies.data.data, educators.data.data)
    return {data};
  });
}

export const indexReviews = () => () => {
  return api({
    method: 'get',
    url: `/elasticsearch/index/reviews`
  })
}

export const getAdminReviews = ({query, page, pageSize, sort} = {}) => {
  return {type: 'ADMIN_REVIEWS/FETCH', req: fetchReviews, query, page, pageSize, sort}
}

export const getOrganizations = ({pageSize = 1000} = {}) => {
  return {type: 'ADMIN_ORGANIZATIONS/FETCH', req: fetchOrgs, pageSize}
}

// --------------------------ADMIN POSTS----------------------------

const fetchAdminPosts = ({page = 0, pageSize = 10, organizationId}) => {
  const params = organizationId ? {page, pageSize, organizationId} : {page, pageSize};

  return api({
    method: 'get',
    url: '/admin-posts',
    params
  })
}

const fetchAdminPost = ({postId}) => {
  return api({
    method: 'get',
    url: `/admin-posts/${postId}`
  })
}

const fetchFilters = () => {
  return api({
    method: 'get',
    url: 'feed/filters'
  });
}

export const addAdminPost = ({values, organizationId}) => () => {
  const data = organizationId ? {...values, organizationId} : values;

  return api({
    method: 'post',
    url: '/admin-posts',
    data
  });
}

export const updateAdminPost = ({values, page}) => dispatch => {
  return api({
    method: 'put',
    url: `/admin-posts/${values.id}`,
    data: {...values, organizationId: values.organization?.internalId || values.organizationId}
  })
  .then(() => dispatch(getAdminPosts({page})));
}

export const deleteAdminPost = ({post, page, organizationId}) => dispatch => {
  const getParams = organizationId ? {page, organizationId: post.payload.organization.id} : {page};

  return api({
    method: 'delete',
    url: `/admin-posts/${post.payload.id}`,
    params: page
  })
  .then(() => dispatch(getAdminPosts(getParams)));
}

export const voteAdminPost = (postId, action) => () => {
  return api(
    mapReviewAction({
      method: 'post',
      action: action === 'revokeVote' ? 'revokeVote' : 'vote',
      url: `/admin-posts/${postId}`,
      params: {type: action}
    })
  );
}

export const viewAdminPost = postId => () => {
  return api({
    method: 'post',
    url: `/admin-posts/${postId}/view`
  });
}

export const getAdminPosts = ({page, pageSize, organizationId}) => {
  return {type: 'ADMIN_POSTS/FETCH', req: fetchAdminPosts, page, pageSize, organizationId}
}

export const getAdminPost = ({postId, loader}) => {
  return {type: 'ADMIN_POST/FETCH', req: fetchAdminPost, postId, loader}
}

export const getFilters = (opts = {}) => {
  return {type: 'FILTER/FETCH', req: fetchFilters, ...opts}
}
