import React                        from 'react';
import { reduxForm, Field }         from 'redux-form';
import { compose,
         withProps,
         setDisplayName }           from 'recompose';
import Agreement                    from 'theme/Agreement';
import { TextField, Radio }         from 'theme/Form';
import { withPasswordToggle,
         withScreenWidthDetection } from 'theme/utils/recompose';


const validate = values => {
  const errors = {};

  if(!values.password) {
    errors.password = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.agree) {
    errors.agree = 'Required';
  }
  return errors;
}

export default compose(
  setDisplayName('SignupForm'),
  withPasswordToggle(),
  withScreenWidthDetection(),
  withProps(({screenWidth}) => ({
    isMobile: screenWidth < 769
  }))
)(reduxForm({
  form: 'SignupForm',
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, pristine, submitting, err, urlParams, checkPasswordVisible, togglePassword, isMobile}) => {
    return (
      <div className="Authentication__form">
       <form onSubmit={handleSubmit}>
          <Field
            name="email"
            component={TextField}
            className="block"
            label="Email"
            placeholder="e.g. sgt.hooah@gmail.com"
          />
          <Field
            name="password"
            type={checkPasswordVisible('password') ? 'text' : 'password'}
            component={TextField}
            isPasswordVisible={checkPasswordVisible('password')}
            togglePassword={togglePassword}
            label="Password"
            placeholder="Type your password"
          />
          <Field
            name="agree"
            component={Radio}
            label={<Agreement />}
            className="Authentication__agreement top"
            placeholder="Set your password"
            mode="checkbox"
          />
          {err && !urlParams.error && (
            err.status == 409 &&
              <p className="Authentication__error">A user with this email already exists. Please go to the sign in page.</p>
            ||
              <p className="Authentication__error">Something went wrong. Please try again later.</p>
          )}
          <div className="Authentication__authBtn">
            <button className="btn-primary" type="submit" disabled={submitting || pristine}>
              {submitting && <i className="fa fa-spin fa-spinner" />}
              {isMobile ? 'Join' : 'Join the community'}
            </button>
          </div>
        </form>
      </div>
    )
  }
))
