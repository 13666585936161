import React               from 'react';
import { connect }         from 'react-redux';
import { compose,
         setDisplayName,
         withState,
         withHandlers,
         withProps }       from 'recompose';
import cx                  from 'classnames';
import Modal               from 'theme/Modal';
import Confirm             from 'theme/Confirm';
import { withModal }       from 'theme/utils/recompose';
import { actionSucceeded } from 'store/actions';

import './Interactions.sass';

const INTERACTIONS = [
  {name: 'angry', label: 'Angry'},
  {name: 'celebrate', label: 'Celebrate'},
  {name: 'curious', label: 'Curious'},
  {name: 'dislike', label: 'Dislike'},
  {name: 'like', label: 'Like'},
  {name: 'love', label: 'Love'},
  {name: 'sad', label: 'Sad'},
  {name: 'support', label: 'Support'},
  {name: 'report', label: 'Report'}
];

const InteractionPanel = ({ interact, visible, openModal, isComment, sortedInteractions }) => {
  const mappedInteractions = INTERACTIONS.map(i => ({...i, count: sortedInteractions.find(s => s.name === i.name)?.count}))
  return (
    <div
      className={cx("Interactions__panel", {visible, isComment})}
      onClick={e => e.stopPropagation()}
    >
      {mappedInteractions.map(i => (
        <div
          key={i.name}
          className={cx("Interactions__icon Interactions__panelIcon Interactions__withLabelTop", i.name)}
          onClick={e => i.name === 'report' ? openModal() : interact(e, i)}
        >
          <span className="Interactions__labelTop">{i.label}</span>
          {i.name !== 'report' &&
            <span className="Interactions__panelMobileLabel">{i.count}</span>
          }
        </div>
      ))}
    </div>
  )
}

const Interactions = compose(
  setDisplayName('Interactions'),
  withModal(),
  withState('panelVisible', 'setPanelVisible', false),
  withHandlers({
    displaySelectedInteraction: ({user, votes}) => () => {
      const reactionArrayWithMyId = Object.entries(votes).find(([key, value]) => value.find(id => id === user?.id)); // eslint-disable-line
      return reactionArrayWithMyId
        ? INTERACTIONS.find(i => i.name === reactionArrayWithMyId[0])
        : null;
    },
    togglePanel: ({setPanelVisible, panelVisible, isReported}) => e => {
      if (isReported) return;

      e.stopPropagation();
      setPanelVisible(!panelVisible);
    },
    interact: ({react, setPanelVisible}) => (e, reaction) => {
      e.stopPropagation();

      setPanelVisible(false);
      react(e, reaction.name);
    },
    report: ({report, closeModal, setPanelVisible, displaySuccessMessage}) => e => {
      e.stopPropagation();
      setPanelVisible(false);

      report(e)
      .then(() => {
        closeModal();
        displaySuccessMessage('Your report has been successfully sent.');
      });
    },
    closeModal: ({closeModal}) => e => {
      e.stopPropagation();
      closeModal();
    }
  }),
  withProps(({displaySelectedInteraction, votes}) => ({
    selectedInteraction: displaySelectedInteraction(),
    interactionsCount: votes && Object.values(votes).reduce((a, b) => a.concat(b), []).length,
    sortedInteractions: INTERACTIONS
      .slice(0, -1)
      .map((i => ({...i, count: votes[i.name].length})))
      .sort((a, b) => b.count - a.count)
  }))
)(({togglePanel, interact, panelVisible, openModal, modal, closeModal, sortedInteractions, interactionsCount, selectedInteraction, report, itemType, user, isReported}) => {
  return (
    <div className="Interactions">
      <div className="Interactions__container">
        <div
          className={cx("Interactions__list", {disabled: isReported || !user})}
          onClick={togglePanel}
        >
          <InteractionPanel
            interact={interact}
            visible={panelVisible}
            openModal={openModal}
            isComment={itemType === 'comment'}
            sortedInteractions={sortedInteractions}
          />
          {sortedInteractions.map((i, index) => (
            <div
              key={i.name}
              className={cx("Interactions__icon Interactions__withLabelTop overlapping", i.name)}
              style={{zIndex: sortedInteractions.length - index}}
            >
              <span className="Interactions__labelTop">{i.count}</span>
            </div>
          ))}
        </div>
        <span className="Interactions__count">{interactionsCount}</span>
        {selectedInteraction &&
          <div
            className="Interactions__selectedInteraction Interactions__withLabelTop"
            onClick={e => interact(e, {name: 'revokeVote'})}
          >
            <div className={cx("Interactions__icon Interactions__selectedInteractionIcon", selectedInteraction.name)} />
            <span className={cx("Interactions__selectedInteractionLabel", {isComment: itemType === 'comment'})}>{selectedInteraction.label}</span>
            <span className="Interactions__labelTop">Click to revoke</span>
          </div>
        }
      </div>

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        className="AdminPosts__modal"
        isGallery={modal.name === 'GALLERY'}
      >
        <Confirm
          question={`Are you sure you want to report this ${itemType} because it's violating the community rules?`}
          close={closeModal}
          action={report}
        />
      </Modal>
    </div>
  )
})

export default connect(
  ({ auth }) => ({ user: auth.user }),
  dispatch => ({
    displaySuccessMessage: message => dispatch(actionSucceeded(message))
  })
)(Interactions);
