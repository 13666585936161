import types          from 'store/types';
import auth           from 'auth/reducers';
import * as theme     from 'theme/reducers';
import { LOGGED_OUT } from 'store/types';

const PATTERN = /(.*)\/(FETCH.*$|LOADING$|LOADED$)/;


export const data = (state = {}, action) => {
  const match = PATTERN.exec(action.type);
  if (action.type == LOGGED_OUT) {
    return {
      ...state,
      profile: null
    }
  }
  switch(match && match[2]) {
    case types.FETCH_SUCCESS:
      if(action.data)
        return {
          ...state,
          [match[1].toLowerCase()]: action.data
        }
      else
        return state;
    case types.FETCH_ERROR:
      return {
        ...state,
        [match[1].toLowerCase()]: {
          ...state[match[1].toLowerCase()],
          error: action.error
        }
      }
    case types.CREATE_REQUEST:
      return state;
    case types.REQUEST_FAILURE:
      if(action.error) {
        return {
          ...state,
          error: action.error.response,
          message: true
        }
      }
      else {
        return state;
      }
    case types.CLOSE_MESSAGE:
      return {
        ...state,
        error: false,
        message: false
      }
    case types.PAGE_CLEANUP:
      return {};
    default:
      return state;
  }
}

const loading = (state = {}, action) => {
  const match = PATTERN.exec(action.type);
  switch(match && match[2]) {
    case types.LOADING:
      return {
        ...state,
        [match[1].toLowerCase()]: true
      }
    case types.LOADED:
      return {
        ...state,
        [match[1].toLowerCase()]: false
      }
    default:
      return state;
  }
}

export const noNetwork = (state = false, action) => {
  switch (action.type) {
    case types.NO_CONNECTION:
      return true;
    case types.CONNECTED:
      return false;
    default:
      return state;
  }
}

const error = (state = {}, action) => {
  const match = /(.*)?:(.*)\/(.*)_(ERROR)/.exec(action.type);
  if(action.type == 'CLEAR_ERRORS')
    return {
      ...state,
      visible: false
    }
  if(match && match[3] && (match[3] == 'SUBMIT' || match[3] == 'FETCH')) {
    return {
      ...state,
      origin: match[1],
      multiple: !match[2],
      type: match[3],
      error: match[4] == 'ERROR',
      message: action.error ? action.error.data && action.error.data.message : action.error,
      visible: true
    }
  }
  return state;
}

const success = (state = {visible: false}, action) => {
  if(action.type == 'CLEAR_SUCCESS')
    return {
      ...state,
      visible: false
    }
  if (action.type == types.ACTION_SUCCEEDED) {
    return {
      ...state,
      message: action.message,
      visible: true
    }
  }
  return state;
}

// Combine reducers with routerReducer which keeps track of
// router state
export default {
  auth,
  data,
  loading,
  noNetwork,
  error,
  success,
  ...theme
}
