import { compose,
         lifecycle,
         mapProps,
         withProps,
         withState,
         withHandlers,
         withStateHandlers }    from 'recompose';
import { parse }                from 'qs';
import { pluck }                from 'underscore';
import { constants }            from 'theme/utils/constants';

export function withComment() {
  return compose(
    withState('commentsVisible', 'setCommentsVisible', false),
    withState('subcommentsVisible', 'setSubcommentsVisible', false),
    withState('replied', 'setReplied', false),
    withState('loadingComments', 'setLoadingComments', false),
    withState('commentsPage', 'setCommentsPage', 0),
    withState('commentsCount', 'setCount', 0),
    withStateHandlers({
      comments: []
    },
    {
      addComments: ({comments}) => i => {
        const ids = pluck(comments, 'id');
        const uniq = i.filter(ii => ids.indexOf(ii.id) == -1);
        return {comments: [].concat(comments, uniq)};
      },
      setComments: () => comments => ({comments})
    }),
    withProps(({reviewItem, admin}) => ({
      itemType: reviewItem ? 'review' : (admin ? 'post' : 'comment'),
      urlParams: parse(location.search.substring(1))
    })),
    lifecycle({
      componentDidMount() {
        const { topLvlComment, mediumLvlComment, getPostComments, setComments, setCount, urlParams, review, post, postId, reviewId, itemType, commentsPage, setCommentsVisible, setSubcommentsVisible } = this.props;

        if (((topLvlComment || review) && (postId || reviewId)) || mediumLvlComment) {
          getPostComments({
            postId: reviewId || review?.id || postId || post?.id,
            page: commentsPage,
            pageSize: (urlParams?.commentReported || urlParams?.notification) && constants.COMMENT.SCROLLED_COMMENT_PAGE_SIZE,
            itemType,
            sort: 'createdAt:d'
          })
          .then(({data, count}) => {
            setComments(data);
            setCount(count);
          });
        }

        if (postId || reviewId)
          setCommentsVisible(true);

        if (post && urlParams?.parentId === post.id)
          setSubcommentsVisible(true);
      },
      componentWillUnmount() {
        this.props.setComments([]);
      }
    }),
    withProps(({comments, mediumLvlComment}) => ({
      comments: mediumLvlComment ? comments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) : comments
    })),
    withHandlers({
      toggleSubcomments: (({subcommentsVisible, setSubcommentsVisible}) => () => setSubcommentsVisible(!subcommentsVisible)),
      reply: ({reply, comments, setComments, setReplied, setCount, commentsCount, mediumLvlComment, itemType}) => (...args) => {
        return reply(...args, itemType)
        .then(response => {
          setComments(mediumLvlComment ? comments.concat(response) : [response, ...comments]);
          setCount(commentsCount + 1);
          setReplied(true);
        })
      },
      showMore: ({commentsPage, setCommentsPage, getPostComments, postId, reviewId, loadingComments, addComments, commentsCount, comments, itemType, setLoadingComments, mediumLvlComment, post}) => () => {
        if (loadingComments || (comments.length >= commentsCount)) return;
        const pagesCount = Math.ceil(commentsCount / constants.COMMENT.PAGE_SIZE);
        const newPage = commentsPage + 1 > pagesCount ? pagesCount : commentsPage + 1;

        const params = mediumLvlComment
          ? {postId: post.id, page: newPage, itemType, sort: 'createdAt:d'}
          : {postId: reviewId || postId || post?.id, page: newPage, itemType, sort: 'createdAt:d'};

        setLoadingComments(true);

        return getPostComments(params)
        .then(response => {
          addComments(response.data);
          setCommentsPage(newPage);
          setLoadingComments(false);
        });
      }
    }),
    mapProps(props => {
      const { post } = props;
      const text = post && (post.text);

      if (text?.length > constants.COMMENT.MAX_LEN) {
        const trimmedText = text.substring(0, constants.COMMENT.MAX_LEN);
        const shortComment = trimmedText.substring(0, trimmedText.lastIndexOf(' '));
        return Object.assign({}, props, {post: {
          ...post,
          shortComment
        }});
      }
      return props;
    })
  )
}
