import React, { useState }    from 'react';
import { Link }               from 'react-router-dom';
import { ReactSVG }           from 'react-svg';
import cx                     from 'classnames';
import Loader                 from 'theme/Loader';
import ListItemLabel          from 'theme/ListItemLabel';
import MarkdownEditor         from 'theme/MarkdownEditor';
import CollapseComponent      from 'theme/CollapseComponent';
import { formatDate }         from 'theme/utils/helpers';
import { images }             from 'theme/img/images';

import './Job.sass';


const PERIOD_MAP = {
  1: '1 month',
  2: '2 months',
  3: '3 months',
  6: '6 months',
  12: '1 year',
  24: '2 years',
  36: '3 years',
  48: '4 years',
  60: '5 years'
};

const Buttons = ({saved, handleSave, openModal, mobile}) => (
  <div className={cx("Job__buttons", {mobile, desktop: !mobile})}>
    <button
      className={cx("btn btn-secondary-ghost", {active: saved})}
      onClick={handleSave}
    >
      {saved ? 'Saved' : 'Save'}
    </button>
    <button
      className="btn-icon"
      onClick={openModal}
    >
      <ReactSVG src={images.share} />
    </button>
  </div>
)

const CriteriaList = ({title, data, industry, markAsMatching, showActionButtons}) => (
  <div className="Job__descriptionList">
    <h5 className="subheader">{title}:</h5>
      <ul>
        {data.map((item, i) => (
          <li key={i} className={cx({matching: showActionButtons && markAsMatching(item, industry)})}>
            <div>
              {
                item.name
                || item.period &&
                  (
                    item.label === 'industry'
                      ? `${PERIOD_MAP[item.period]} experience in ${industry} ${item.label}`
                      : `${PERIOD_MAP[item.period]} of ${item.label} experience`
                  )
                || `${item.degree} in ${item.fieldOfStudy}`
              }
              {showActionButtons && markAsMatching(item, industry) &&
                <img src={images.checkmark} alt="Matching skill" />
              }
            </div>
          </li>
        ))}
      </ul>
  </div>
)

const AccessibilityRow = ({text, isProgram, programs}) => (
  <div className="Job__accessibility-and-programsRow">
    <div className="checkmark">
      <img src={images.checkmarkSmall} alt="check" />
    </div>
    <span>{isProgram ? `Available through ${programs.map(p => p).join(', ')}` : text}</span>
  </div>
)

export default function Job({
  job: {
    id,
    saved,
    location,
    availableTypes,
    createdAt,
    benefits,
    companyCultures,
    externalInfo,
    internalInfo,
    expirationDate,
    referenceNumber,
    industry,
    jobTitle,
    supportedPrograms,
    jobDescription,
    isDisabilityFriendly,
    viewed,
    type
  },
  className,
  jobApplication,
  niceToHaveCriteria,
  requiredCriteria,
  loading,
  withdraw,
  openModal,
  user,
  handleSave,
  markAsMatching,
  showActionButtons,
  isJobAvailable
}) {
  const [isExpanded, setExpanded] = useState(true);
  const jobData = [
    {title: 'Location', content: location ? location : ''},
    {title: 'Job type', content: availableTypes?.join(', '), wrap: true},
    {title: 'Posted', content: createdAt && formatDate(createdAt)},
    {title: 'Expires', content: expirationDate && formatDate(expirationDate)},
    {title: 'Ref. number', content: referenceNumber ? `#${referenceNumber}` : '', alignRight: true}
  ];

  const applicationLinkForNonAuth = externalInfo
    ? `/auth/signin?externalJobOpeningId=${id}`
    : `/auth/signin?jobOpeningId=${id}&organizationId=${internalInfo?.organizationId}`;

  return (
    <div className={cx("Job", className)}>
      <div className="Job__container">
        <div className="Job__content">
          <div className="Job__card Job__intro">
            <Loader loading={loading} />
            {showActionButtons && jobApplication && (jobApplication.status == 'Submitted' || jobApplication.status == 'Withdrawn') &&
              <div className={cx("Job__topBar", {withdrawn: jobApplication.status == 'Withdrawn'})}>
                <span className="Job__topBarText">
                  You {jobApplication.status === 'Withdrawn' ? 'withdrew your application' : 'applied for this job'} on {formatDate(jobApplication && (jobApplication.status === 'Withdrawn' ? jobApplication.withdrawnAt : jobApplication.createdAt))}
                </span>
                {type === 'Internal' && jobApplication.status == 'Submitted' &&
                  <span
                    className="Job__withdraw"
                    onClick={() => withdraw(jobApplication?.id)}
                  >
                    {jobApplication.status == 'Submitted' && 'WITHDRAW'}
                  </span>
                }
              </div>
            }
            <div className="Job__heading">
              <div>
                <h2>{jobTitle}</h2>
                {!viewed &&
                  <ListItemLabel text="New" color="orange" />
                }
              </div>
              {user && showActionButtons &&
                <Buttons
                  saved={saved}
                  openModal={() => openModal('SHARE')}
                  handleSave={() => handleSave(id, saved ? 'Unsave': 'Save')}
                />
              }
            </div>
            {externalInfo &&
              <h5 className="Job__externalCompanyName subheader">{externalInfo.organizationName}</h5>
            }
            <div className="Job__data">
              {jobData?.map(({title, content, wrap, alignRight}, i) => (
                <div
                  className={cx("Job__dataItem", {wrap, alignRight})}
                  key={i}
                >
                  <span className="Job__dataItemTitle">{title}</span>
                  <span className="Job__dataItemContent">{content}</span>
                </div>
              ))}
            </div>
            <div className="Job__accessibility-and-programs">
              {isDisabilityFriendly &&
                <AccessibilityRow text='Disability-friendly' />
              }
              {supportedPrograms?.length > 0 &&
                <AccessibilityRow isProgram programs={supportedPrograms} />
              }
            </div>
            {user && showActionButtons &&
              <Buttons
                saved={saved}
                openModal={() => openModal('SHARE')}
                handleSave={() => handleSave(id, saved ? 'Unsave': 'Save')}
                mobile
              />
            }
          </div>

          <div className="Job__card Job__description">
            <Loader loading={loading} />
            <CollapseComponent
              className="Job__descriptionCollapse"
              isOpened={isExpanded}
            >
              <div className="Job__descriptionText">
                {jobDescription &&
                  <MarkdownEditor
                    value={jobDescription}
                    readOnly
                  />
                }
              </div>
              {requiredCriteria && requiredCriteria.length > 0 &&
                <CriteriaList
                  title="Requirements"
                  data={requiredCriteria}
                  industry={industry}
                  markAsMatching={markAsMatching}
                  showActionButtons={showActionButtons}
                />
              }
              {niceToHaveCriteria?.length > 0 &&
                <CriteriaList
                  title="Nice to have"
                  data={niceToHaveCriteria}
                  industry={industry}
                  markAsMatching={markAsMatching}
                  showActionButtons={showActionButtons}
                />
              }
              {benefits?.length > 0 &&
                <div className="Job__descriptionList">
                  <h5 className="subheader">Benefits:</h5>
                  <ul>
                    {benefits.map((b, i) => (
                      <li key={i}>{b}</li>
                    ))}
                  </ul>
                </div>
              }
              {companyCultures?.length > 0 &&
                <div className="Job__descriptionList">
                  <h5 className="subheader">Team Culture:</h5>
                  <ul>
                    {companyCultures.map((c, i) => (
                      <li key={i}>{c}</li>
                    ))}
                  </ul>
                </div>
              }
              {(showActionButtons && jobApplication && Object.keys(jobApplication).length === 0 && isJobAvailable) &&
                <div className="Job__descriptionApply">
                  {user
                    ? <button
                        className="btn-primary"
                        onClick={() => openModal(type == 'External' ? 'APPLY_EXTERNAL' : 'APPLY')}
                      >
                        Apply Now
                      </button>
                    : <Link
                        className="btn-primary"
                        to={applicationLinkForNonAuth}
                      >
                        Sign in to apply
                      </Link>
                  }
                </div>
              }
              {!isExpanded &&
                <div className="CollapseOverlay" />
              }
            </CollapseComponent>
            {!isExpanded &&
              <div
                className="Job__showMore"
                onClick={() => setExpanded(true)}
              >
                SHOW MORE
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
