import React                      from 'react';
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router';
import { Link }                   from 'react-router-dom';
import ReactPlayer                from 'react-player';
import { ReactSVG }               from 'react-svg';
import { compose,
         setDisplayName,
         lifecycle,
         withHandlers,
         mapProps,
         withProps }              from 'recompose';
import cx                         from 'classnames';
import { parse }                  from 'qs';
import { debounce }               from 'underscore';
import { env }                    from 'theme/content';
import Modal                      from 'theme/Modal';
import ImageBrowser               from 'theme/ImageBrowser';
import MarkdownEditor             from 'theme/MarkdownEditor';
import MobileActions              from 'theme/MobileActions';
import { images as imgs }         from 'theme/img/images';
import { formatDate }             from 'theme/utils/helpers';
import { constants }              from 'theme/utils/constants';
import { withModal }              from 'theme/utils/recompose';
import Share                      from 'share/Share';
import LogoLink                   from 'comments/LogoLink';
import PostFooter                 from 'comments/PostFooter';
import CommentsSection            from 'comments/CommentsSection';
import { withComment }            from 'comments/recompose';
import { getPostComments,
         addPostComment,
         deleteComment,
         report,
         voteComment }            from 'comments/actions';
import { actionSucceeded }        from 'store/actions';

import './Post.sass';

const MODAL_NAMES = {
  SHARE: 'SHARE',
  DELETE_POST: 'DELETE_POST',
  NUDGE_TO_REGISTER: 'NUDGE_TO_REGISTER',
};

const splitAt = index => x => [x.slice(0, index), x.slice(index)];

const Post = compose(
  setDisplayName('Post'),
  withModal(),
  withProps(({match, post, admin}) => ({
    postId: match.params.postId,
    teaserUrl: `${location.origin}/teaser/${admin ? 'admin-post' : 'post'}/${post.id}`,
    reportingUrl: `${location.origin}/${admin ? 'dashboard/feed' : 'community'}/post/${post.id}`
  })),
  withComment(),
  withProps(({post: {id, user: author, organizationId, addedByAdmin}, urlParams, user}) => {
    return {
      me: organizationId
        ? organizationId === user.organizationId
        : user?.id === author?.id,
      postAuthorSignature: {
        'Service branch': author?.serviceBranch,
        'Military occupation': author?.mos,
        'EAS paygrade': author?.etsPaygrade,
        'User category': author?.userCategory
      },
      sharePostUrl: `${location.origin}/teaser/post/${id}`,
      shareAdminPostUrl: `${location.origin}/teaser/admin-post/${id}`,
      isVetitAdmin: addedByAdmin && !organizationId,
      isReported: user?.isAdmin && urlParams?.postReported
    }
  }),
  lifecycle({
    componentDidMount() {
      const { view, post } = this.props;

      if (view)
        view(post.id);
    }
  }),
  withHandlers({
    createReportingUrl: ({reportingUrl}) => reportType => `${reportingUrl}${env.QUERY[reportType]}`
  }),
  withHandlers({
    openModal: ({openModal}) => (e, name) => {
      e.stopPropagation();
      openModal(name);
    },
    openMobileModal: ({openModal}) => () => openModal(),
    handleFollow: ({post: {isFollowed, id}, follow, unfollow}) => e => {
      e.stopPropagation();
      return isFollowed ? unfollow(id) : follow(id);
    },
    handleMobileFollow: ({post: {isFollowed, id}, follow, unfollow}) => () => {
      return isFollowed ? unfollow(id) : follow(id);
    },
    editPost: ({editPost}) => e => {
      e.stopPropagation();
      editPost();
    },
    editMobilePost: ({editPost}) => () => editPost(),
    report: ({post, report, createReportingUrl, admin}) => () => {
      return report({
        itemId: post.id,
        itemType: admin ? 'AdminPost' : 'post',
        url: createReportingUrl('postReported')
      });
    },
    deletePost: ({admin, deletePost, closeModal, post, history, displaySuccessMessage}) => () => {
      return deletePost(post.id, admin ?  'admin-post' : 'comment')
      .then(() => {
        closeModal();
        displaySuccessMessage('You have successfully deleted the post.');
        history.push('/home');
      });
    },
    react: ({post, vote}) => (e, action) => {
      e.stopPropagation();
      vote(post.id, action, false);
    },
    reactToComment: ({post, reactToComment, getPostComments, comments, setComments}) => id => (e, action) => {
      return reactToComment(id, action)
      .then(() => {
        getPostComments({
          postId: post.id,
          page: 0,
          pageSize: comments.length,
          sort: 'createdAt:d'
        })
        .then(response => setComments(response.data));
      });
    },
    createCommentReportingUrl: ({createReportingUrl}) => params => `${createReportingUrl('commentReported')}&${params}`,
    // eslint-disable-next-line react/display-name
    blurText: ({user}) => text => {
      const isTextLongerThanBlurLimit = text?.length && text.length > constants.BLUR_LIMIT;
      const sliced = isTextLongerThanBlurLimit ? splitAt(constants.BLUR_LIMIT)(text) : text;

      return user
        ? text
        : (isTextLongerThanBlurLimit
            ? <span>{sliced[0]}<span className="blurred">{sliced[1]}</span></span>
            : text
          );
    }
  }),
  mapProps(props => ({
    ...props,
    voteComment: debounce(props.voteComment, 1000, true)
  })),
  withProps(({text, post, blurText}) => ({
    post: {...post, content: blurText(post.content)},
    text: blurText(text)
  }))
)(({
  post: {
    id,
    topic,
    title,
    createdAt,
    content,
    votes,
    isFollowed,
    images,
    video,
    organization,
    organizationId,
    organizationName,
    organizationLogo,
    text,
    metadata,
    popularity,
    views,
    byTopicChampion
  },
  postId,
  className,
  me,
  reply,
  comments,
  commentsVisible,
  replied,
  setReplied,
  organizationTopic,
  user,
  commentsCount,
  history,
  reactToComment,
  react,
  report,
  handleFollow,
  handleMobileFollow,
  postAuthorSignature,
  userDashboard,
  showMore,
  loadingComments,
  admin,
  topLvlComment,
  openGallery,
  editPost,
  editMobilePost,
  link,
  isReported,
  modal,
  teaserUrl,
  closeModal,
  openModal,
  openMobileModal,
  teaser,
  sharePostUrl,
  shareAdminPostUrl,
  isVetitAdmin,
  createCommentReportingUrl
}) => {
  const path = history?.location.pathname;
  const shortText = !postId && (!content || content?.length < constants.COMMENT.ESTIMATED_TEXT_LINE_LENGTH);
  const redirectLink = admin ? link : (!postId ? `/community/post/${id}` : '#');

  const defaultMobileActions = userDashboard
    ? [{text: 'Share', fn: openMobileModal, icon: imgs.share}]
    : [
        {text: isFollowed ? 'Unfollow' : 'Follow', fn: handleMobileFollow, icon: isFollowed ? imgs.personXsWhite : imgs.personXs, bgColor: isFollowed ? '#5C6373' : '#FFF'},
        {text: 'Share', fn: openMobileModal, icon: imgs.share}
      ];

  const mobileActions = me
    ? [...defaultMobileActions, {text: 'Edit', fn: editMobilePost, icon: imgs.editAlt}]
    : defaultMobileActions;

  return (
    <div className={cx("Post", className, {clickable: !postId})}>
      <div
        className={cx("Post__card card", {topLvlComment, noContent: !content})}
        onClick={(e) => !isReported && (!user ? openModal(e, MODAL_NAMES.NUDGE_TO_REGISTER) : history.push(redirectLink, {from: path === '/community' ? 'community' : ''}))}
      >
        <div className={cx("Post__heading", {organizationTopic})}>
          {!organizationTopic &&
            <>
              {admin
                ?
                  <div className="Post__organization">
                    <LogoLink
                      internalId={organizationId || organization?.internalId}
                      logo={organizationLogo || organization?.logo}
                      name={organizationName || organization?.name}
                      isVetitAdmin={isVetitAdmin}
                    />
                    <span className="Post__organizationName">{organizationName || organization?.name || 'VETIT'}</span>
                  </div>
                :
                  <div className="topicLabel">{topic?.name}</div>
              }
              <div className="Post__btns">
                <MobileActions actions={mobileActions} />
                <div className="Post__headingBtns feed-item-heading-btns">
                  {createdAt &&
                    <span className="date">{formatDate(createdAt, ...Array(1), true)}</span>
                  }
                  {(!userDashboard && !admin && !teaser && !isReported && user) &&
                    <button
                      className={cx("btn-secondary-ghost", {active: isFollowed})}
                      onClick={handleFollow}
                    >
                      {isFollowed ? 'Unfollow' : 'Follow'}
                    </button>
                  }
                  {user && !isReported &&
                    <button
                      className="btn-icon"
                      onClick={e => openModal(e, MODAL_NAMES.SHARE)}
                    >
                      <ReactSVG src={imgs.share} />
                    </button>
                  }
                  {me && !teaser && !isReported &&
                    <button
                      className="btn-icon"
                      onClick={editPost}
                    >
                      <ReactSVG
                        src={imgs.editAlt}
                        aria-label="Edit post"
                      />
                    </button>
                  }
                </div>
                {isReported &&
                  <button
                    className="btn-icon-trash"
                    onClick={e => openModal(e, MODAL_NAMES.DELETE_POST)}
                  >
                    <span className="fa fa-trash" />
                  </button>
                }
              </div>
            </>
          }
        </div>

        <div className={cx("Post__body", {content, teaser})}>
          <div className="Post__bodyContent feed-item-content">
            <div className={cx("Post__feedItemTitleContainer feed-item-title-container", {admin})}>
              {!admin &&
                <h4 className="feed-item-title">{title || 'Untitled'}</h4>
              }
              {createdAt &&
                <span className="date">{formatDate(createdAt, ...Array(1), true)}</span>
              }
            </div>
            <div className={cx("Post__content", {admin, feed: !postId, shortText})}>
              {user
                ?
                  <MarkdownEditor
                    value={admin ? text : content}
                    readOnly
                  />
                :
                  <p>{admin ? text : content}</p>
              }
              {!postId &&
                <div className={cx("Post__more", {notVisible: shortText})} />
              }
            </div>
          </div>
          {admin && (images || video) &&
            <div
              className="Post__bodyMedia"
              onClick={e => e.stopPropagation()}
            >
              {images &&
                <ImageBrowser
                  images={images}
                  openGallery={openGallery}
                  defaultSize={constants.COMMENT.IMAGES_SIZE}
                />
              }
              {video &&
                <ReactPlayer
                  url={video.url}
                  width="100%"
                  height="100%"
                  controls
                />
              }
            </div>
          }
          {teaser &&
            <div className={cx("Post__bodyTeaserOverlay", {admin})}>
              <h5>Log in to read more</h5>
            </div>
          }
        </div>

        <PostFooter
          me={me}
          signature={postAuthorSignature}
          count={popularity}
          teaser={teaser}
          isAdminPost={admin}
          hasMedia={Boolean(admin && (images || video))}
          views={admin ? metadata?.views : views}
          votes={admin ? metadata?.votes : votes}
          isReported={isReported}
          itemType="post"
          react={react}
          report={report}
          singleItemView={Boolean(postId)}
          byTopicChampion={byTopicChampion}
        />
      </div>

      {!teaser && commentsVisible &&
        <CommentsSection
          comments={comments}
          user={user}
          reply={reply}
          replied={replied}
          setReplied={setReplied}
          reactToComment={reactToComment}
          commentId={id}
          loading={loadingComments}
          count={commentsCount}
          showMore={showMore}
          createCommentReportingUrl={createCommentReportingUrl}
          rootTopicType="review"
        />
      }

      <Modal
        isOpen={modal.visible}
        className={modal.name === MODAL_NAMES.SHARE && "shareModal"}
        close={closeModal}
        closeBtn
      >
        {modal.name === MODAL_NAMES.SHARE &&
          <Share
            url={teaserUrl}
            itemId={id}
            shareType={admin ? 'AdminPost' : 'Post'}
            redirectUrl={admin ? shareAdminPostUrl : sharePostUrl}
            itemType='post'
            closeModal={closeModal}
            linkedinText={admin
              ? `Check out this post by ${organizationName || organization?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better! ${teaserUrl}`
              : `Check out this post about ${topic?.name} on Vet it — a discreet career community for military veterans and spouses. Vetters transition better! ${teaserUrl}`
            }
          />
        }
        {modal.name === MODAL_NAMES.NUDGE_TO_REGISTER &&
            <div className="Post__nudge">
              <h3>What's your take? Sign in to continue.</h3>
              <Link to={{pathname: "/auth/signin", state: `/community/post/${id}`}} className="btn-primary">Sign in</Link>
            </div>
          }
      </Modal>
    </div>
  )
})

const mapStateToProps = ({auth}) => ({
  user: auth.user,
  urlParams: parse(location.search.substring(1))
});

const mapDispatchToProps = dispatch => ({
  getPostComments: (args = {}) => new Promise((resolve, reject) => dispatch(getPostComments({...args, resolve, reject}))),
  reply: (values, postId, itemType) => dispatch(addPostComment({values, postId, itemType})),
  report: (args = {}) => dispatch(report(args)),
  deletePost: (id, type) => dispatch(deleteComment(id, type)),
  reactToComment: (id, action) => dispatch(voteComment(id, action)),
  displaySuccessMessage: (message) => dispatch(actionSucceeded(message))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Post));
