import React, { useState, useEffect } from 'react';
import cx                             from 'classnames';
import { constants }                  from 'theme/utils/constants';

import './CommentBox.sass';


const getEditedCommentRowsCount = value => ~~(value.length / constants.COMMENT.ESTIMATED_COMMENT_BOX_LINE_LENGTH) + 1;

export default function CommentBox({text, commentId, className, reply, isReply, isCommentEdited, commentValue, placeholder="Write a comment...", setReplied, hasReplied}) {
  const [value, setValue] = useState('');
  const [rows, setRows] = useState(1);

  const minRows = 1;
  const maxRows = 10;

  const onChange = e => {
    const textareaLineHeight = 24;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}

    setValue(e.target.value);
    setRows(currentRows < maxRows ? currentRows : maxRows);

    if (hasReplied)
      setReplied(false);
  }

  useEffect(() => {
    if (hasReplied) {
      setValue('');
      setRows(1);
    }
  }, [hasReplied]);

  useEffect(() => {
    if (isCommentEdited) {
      setValue(commentValue);
      setRows(getEditedCommentRowsCount(commentValue));
    }
  }, [isCommentEdited]);

  return (
    <div className={cx("CommentBox card", className, {reply: isReply})}>
      <textarea
        placeholder={placeholder}
        rows={rows}
        onChange={onChange}
        value={value}
      />
      <button
        className="btn-primary"
        onClick={() => reply(value, commentId)}
        disabled={!value}
      >
        {text}
      </button>
    </div>
  )
}
