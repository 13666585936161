import React                from 'react';
import { Link }             from 'react-router-dom';
import { reduxForm,
         Field }            from 'redux-form';
import cx                   from 'classnames';
import { TextField }        from 'theme/Form';
import { images }               from 'theme/img/images';

export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, notSubmitted}) => {
    return (
      <div className="ResetPassword__form">
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            placeholder="Type your email"
            component={TextField}
            required
          />
          <div className="ResetPassword__send">
            <Link to='/auth/signin' className="btn-primary-outlined">Cancel</Link>
            <button
              type="submit"
              className={cx("btn-primary save", {notSubmitted})}
              disabled={pristine || submitting}
            >
              <img src={images.messageWhite} alt="Send reset link" />
              Send reset link
              {notSubmitted &&
                <span />
              }
            </button>
          </div>
        </form>
      </div>
    )
  }
)
