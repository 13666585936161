import React      from 'react';
import cx         from 'classnames';
import { images } from 'theme/img/images';

import './VerificationLabel.sass';

export default function VerificationLabel({inProgress}) {
  return (
    <div className={cx("VerificationLabel", {inProgress})}>
      <div>
        {inProgress
          ? <i className="fa fa-exclamation" aria-hidden="true"></i>
          : <img src={images.checkmark} alt="verified" />
        }
      </div>
      {inProgress ? 'Verification in progress' : 'Verified'}
    </div>
  )
}
