import React              from 'react';
import { useDropzone }    from 'react-dropzone';
import ProgressBar        from 'theme/ProgressBar';
import { images }         from 'theme/img/images';

import './FileUpload.sass';

const convertToMB = size => (size / Math.pow(1024, 2)).toFixed(2);
const extractFileId = key => key.split('Resume/').pop();

export default function FileUpload({files, uploadFile, label, progress, deleteFile, cancelUpload, uploadCancelled}) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop: files => uploadFile(files),
    noDrag: true,
    accept: '.doc, .docx, .pdf',
    multiple: false
  });

  return (
    <div className="FileUpload">
      <ul className="FileUpload__list">
        {files && files.map(f => (
          <li key={f.key}>
            <div>
              <img src={images.doc} alt="File" />
              <span>{decodeURI(f.headers[0].value)}</span>
              <span className="size">{convertToMB(f.headers[1].value)} mb</span>
            </div>
            <div
              className="btn-remove flex"
              onClick={() => deleteFile(extractFileId(f.key))}
            >
              <img src={images.close} alt="remove" />
            </div>
          </li>
        ))}
      </ul>
      <label>{label}</label>
      {progress != 100 && !uploadCancelled &&
        <ul className="FileUpload__accepted">
          {acceptedFiles.map(file => (
            <li key={file.name} className="FileUpload__acceptedFile">
              <img src={images.doc} alt="File" />
              <div className="FileUpload__acceptedProgress">
                <span className="FileUpload__acceptedName">
                  {file.name}
                  <span className="size">{convertToMB(file.size)} mb</span>
                </span>
                <ProgressBar value={progress} />
                <span className="FileUpload__acceptedProgressInfo">{progress}% uploaded</span>
              </div>
              <div
                className="btn-remove flex"
                onClick={() => cancelUpload()}
              >
                <img src={images.close} alt="remove" />
              </div>
            </li>
          ))}
        </ul>
      }
      <section className="FileUpload__container">
        <div {...getRootProps({className: 'dropzone'})}>
          <div className="icon">
            <img src={images.plus} alt="Add a resume" />
          </div>
          <div className="text">
            <input {...getInputProps()} />
            <p>Upload file</p>
          </div>
        </div>
      </section>
    </div>
  )
}
