import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { compose,
         withHandlers,
         lifecycle }          from 'recompose';
import cx                     from 'classnames';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Loader                 from 'theme/Loader';
import AdminNav               from 'theme/AdminNav';
import { formatDate,
         formatPrice,
         formatPlan }         from 'theme/utils/helpers';
import { getSubscription,
         generateCheckout,
         editSubscription }   from 'subscriptions/actions';

import plans                  from './Plans';
import './Subscription.sass';


const STATUS_MAP = {
  cancelled: 'Cancelled',
  in_trial: 'In trial',
  future: 'Future',
  non_renewing: 'Non renewing',
  active: 'Active'
}

const Subscription = compose(
  lifecycle({
    componentDidMount() {
      this.props.getSubscription();
    }
  }),
  withHandlers({
    generateCheckout: ({generateCheckout}) => planId => {
      return generateCheckout(planId)
      .then(res => window.open(res.hosted_page.url));
    },
    editSubscription: ({editSubscription}) => () => {
      return editSubscription()
      .then(res => window.open(res.data.portal_session.access_url));
    }
  })
)(({generateCheckout, editSubscription, sub, loading}) => {
  const { customer, subscription } = sub;

  return (
    <div className="Subscription page">
      <Header />
      <AdminNav />
      <div className="container">
        <div className="Subscription__heading">
          <h4>{sub.subscription ? 'Subscription details' : 'Claim Your Free Pilot Profile!'}</h4>
          {sub.subscription &&
            <button
              className="btn-secondary"
              onClick={editSubscription}
            >
              Edit your subscription
            </button>
          }
        </div>

        <div className="Subscription__wrapper">
          <Loader
            className="Subscription__loader"
            loading={loading}
          />
          {subscription ?
            <table className="Subscription__table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{customer.first_name + ' ' + customer.last_name}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{customer.email}</td>
                </tr>
                <tr>
                  <td>Plan</td>
                  <td>{formatPlan(subscription.plan_id)}</td>
                </tr>
                <tr>
                  <td>Created at</td>
                  <td>{formatDate(subscription.created_at)}</td>
                </tr>
                <tr>
                  <td>Plan</td>
                  <td>{`${formatPrice(subscription.plan_amount)} USD`}</td>
                </tr>
                <tr>
                  <td>Billing period</td>
                  <td>{`${subscription.billing_period} ${subscription.billing_period_unit}${subscription.billing_period === 1 ? '' : 's'}`}</td>
                </tr>
                <tr>
                  <td>Next billing at</td>
                  <td>{formatDate(subscription.next_billing_at)}</td>
                </tr>
                <tr>
                  <td>Started at</td>
                  <td>{subscription.startedAt && formatDate(subscription.startedAt)}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <span className={cx('status', subscription.status)}>{STATUS_MAP[subscription.status]}</span>
                  </td>
                </tr>
                <tr>
                  <td>Trial start</td>
                  <td>{subscription.trial_start && formatDate(subscription.trial_start)}</td>
                </tr>
                <tr>
                  <td>Trial end</td>
                  <td>{subscription.trial_end && formatDate(subscription.trial_end)}</td>
                </tr>
              </tbody>
            </table>
          :
          <div className="Subscription__plans">
            {plans.map(p => (
              <div
                key={p.id}
                className="Subscription__plan card"
              >
                <div className="Subscription__planHeading">
                  <h5>{p.name}</h5>
                </div>
                <div className="Subscription__planInfo">
                  <span>{p.billingPeriod}</span>
                  <span>{p.price} USD</span>
                </div>
                <button className="btn-primary" onClick={() => generateCheckout(p.id)}>SELECT</button>
              </div>
            ))}
          </div>
          }
        </div>
      </div>
      <Footer />
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}) => {
  return {
    user: auth.user,
    sub: data.subscription || {},
    loading: loading.subscription
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSubscription: () => new Promise((resolve, reject) => dispatch(getSubscription({resolve, reject}))),
    generateCheckout: (planId) => dispatch(generateCheckout(planId)),
    editSubscription: () => dispatch(editSubscription())
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription))
