import React                from 'react';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';
import { compose,
         lifecycle,
         setDisplayName,
         withProps,
         withHandlers,
         withStateHandlers,
         withState }        from 'recompose';
import { pluck }            from 'underscore';
import cx                   from 'classnames';
import Modal                from 'theme/Modal';
import Confirm              from 'theme/Confirm';
import CollapseComponent    from 'theme/CollapseComponent';
import ListItemLabel        from 'theme/ListItemLabel';
import { formatDate }       from 'theme/utils/helpers';
import { constants }        from 'theme/utils/constants';
import { withModal }        from 'theme/utils/recompose';
import { getMatchedProfiles,
         getJobApplications,
         inviteToApply,
         shortlist }        from 'organizationAdmin/actions';

import Candidates           from '../Candidates';

import './Jobs.sass';


const question = jobTitle => <span>Are you sure you want to invite this candidate to apply for <b>{jobTitle}</b>?</span>;

const Row = compose(
  setDisplayName('MyCompanyRow'),
  withModal(),
  withState('applications', 'setApplications', []),
  withState('page', 'setPage', 0),
  withState('loading', 'setLoading', false),
  withStateHandlers({
    matched: []
  },
  {
    addMatched: ({matched}) => i => {
      const ids = pluck(matched, 'id');
      const uniq = i.filter(ii => ids.indexOf(ii.id) == -1);

      return {matched: [].concat(matched, uniq)};
    },
    setMatched: () => matched => ({matched})
  }),
  withHandlers({
    getData: ({getMatchedProfiles, setMatched, addMatched, setLoading, job}) => () => {
      return getMatchedProfiles({jobOpeningId: job.id})
      .then(({data}) => {
        setMatched(data);
        addMatched(data);
        setLoading(false);
      });
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { isExpanded, getJobApplications, job, setApplications, getData} = this.props;

      if(prevProps.isExpanded != isExpanded && isExpanded) {
        getJobApplications(job.id)
        .then(({data}) => setApplications(data));

        getData();
      }
    }
  }),
  withProps(({job}) => ({
    expandable: job.jobApplicationCount > 0
  })),
  withHandlers({
    shortlist: ({shortlist, getJobApplications, setApplications}) => jobOpeningId => (id, value) => {
      return shortlist(id, value)
      .then(() => {
        getJobApplications(jobOpeningId)
        .then(res => setApplications(res.data))
      })
    },
    inviteToApply: ({inviteToApply, getMatchedProfiles, setMatched, matched, closeModal}) => (userId, jobOpeningId) => {
      return inviteToApply(userId, jobOpeningId)
      .then(() => {
        closeModal();
        getMatchedProfiles({jobOpeningId, page: 0, pageSize: matched.length})
        .then(({data}) => setMatched(data))
      })
    },
    showMore: ({page, setPage, getMatchedProfiles, loading, addMatched, count, matched, job}) => () => {
      if (loading || (matched.length >= count)) return;
      const pagesCount = Math.ceil(count / constants.JOBS.MATCHED_PAGE_SIZE);
      const newPage = page + 1 > pagesCount ? pagesCount : page + 1;

      return getMatchedProfiles({jobOpeningId: job.id, page: newPage})
      .then(({data}) => {
        addMatched(data);
        setPage(newPage);
      });
    }
  })
)(({job: {id, jobTitle, status, referenceNumber, createdAt, expirationDate, jobApplicationCount}, showMore, loading, modal, closeModal, openModal, matched, count, viewApplication, inviteToApply,shortlist, downloadResume, applications, getJobApplications, toggleCandidatesList, isExpanded, expandable}) => {
  return (
    <div
      className={cx("MyCompanyJobs__row", {expandable})}
      key={id}
      onClick={() => !modal.visible && toggleCandidatesList(id)}
    >
      <div className="MyCompanyJobs__rowContent">
        <div className={cx("refNumber", {hasRefNumber: referenceNumber})}>{referenceNumber ? `#${referenceNumber}` : ''}</div>
        <ListItemLabel
          className="MyCompanyJobs__label--mobile"
          text={status === 'Open' ? 'open position' : 'closed'}
          color={status === 'Closed' ? 'gray' : 'green'}
        />
        <div className="title">
          <p>{jobTitle}</p>
          <ListItemLabel
            text={status === 'Open' ? 'open position' : 'closed'}
            color={status === 'Closed' ? 'gray' : 'green'}
          />
        </div>
        <div className="date">
          {createdAt &&
            <p>Posted: <span>{formatDate(createdAt)}</span></p>
          }
          {expirationDate &&
            <p>Expires: <span>{formatDate(expirationDate)}</span></p>
          }
        </div>
        <div className="applicationCount">
          <span>{jobApplicationCount}</span>
          <svg width="40px" height="40px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Icon / person</title>
            <desc>Created with Sketch.</desc>
            <g id="Icon-/-person" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M15.1428571,17 C14.6688571,17 14.2857143,16.6130766 14.2857143,16.1363318 C14.2857143,13.7551984 12.3631429,12.3443063 10,12.3443063 C7.63685714,12.3443063 5.71428571,13.7551984 5.71428571,16.1363318 C5.71428571,16.6130766 5.33114286,17 4.85714286,17 C4.38314286,17 4,16.6130766 4,16.1363318 C4,12.8025723 6.69228571,10.6169698 10,10.6169698 C13.3077143,10.6169698 16,12.8025723 16,16.1363318 C16,16.6130766 15.6168571,17 15.1428571,17 Z M10,4.72733649 C10.9454286,4.72733649 11.7142857,5.50204691 11.7142857,6.45467299 C11.7142857,7.40729906 10.9454286,8.18200948 10,8.18200948 C9.05457143,8.18200948 8.28571429,7.40729906 8.28571429,6.45467299 C8.28571429,5.50204691 9.05457143,4.72733649 10,4.72733649 M10,9.90934598 C11.8908571,9.90934598 13.4285714,8.35992514 13.4285714,6.45467299 C13.4285714,4.54942083 11.8908571,3 10,3 C8.10914286,3 6.57142857,4.54942083 6.57142857,6.45467299 C6.57142857,8.35992514 8.10914286,9.90934598 10,9.90934598" id="🎨-Icon-Сolor" fill="#DBE0E8" fillRule="nonzero"></path>
            </g>
          </svg>
        </div>
        <div className="btns">
          <Link to={`/org-admin/jobs/${id}`} className="btn-secondary-outlined">Preview</Link>
          <Link to={`/org-admin/jobs/${id}/edit`} className="btn-secondary">Edit</Link>
        </div>
      </div>
      <CollapseComponent isOpened={isExpanded}>
        <Candidates
          applications={applications}
          matched={matched}
          matchedCount={count}
          jobOpeningId={id}
          loading={loading}
          getJobApplications={() => getJobApplications(id)}
          shortlist={shortlist(id)}
          openModal={openModal}
          showMore={showMore}
          jobOpeningClosed={status === 'Closed'}
          downloadResume={downloadResume}
          viewApplication={viewApplication}
        />
      </CollapseComponent>
      <div className="view">
        <span>{isExpanded ? 'Hide Applicants' : 'View Applicants'}</span>
      </div>

      <Modal
        isOpen={modal.visible}
        close={closeModal}
      >
        <Confirm
          question={question(jobTitle)}
          close={closeModal}
          action={() => inviteToApply(modal.payload.id, id)}
        />
      </Modal>
    </div>
  )
})

const mapStateToProps = ({data: {matched_profiles}, loading}) => ({
  matchedProfiles: matched_profiles?.data || [],
  count: matched_profiles?.count || 0,
  loading
});

const mapDispatchToProps = dispatch => ({
  getMatchedProfiles: ({jobOpeningId, page, pageSize}) => new Promise((resolve, reject) => dispatch(getMatchedProfiles(jobOpeningId, {page, pageSize, resolve, reject}))),
  getJobApplications: (jobOpeningId) => new Promise((resolve, reject) => dispatch(getJobApplications(jobOpeningId, {resolve, reject}))),
  shortlist: (id, value) => dispatch(shortlist(id, value)),
  inviteToApply: (userId, jobOpeningId) => dispatch(inviteToApply(userId, jobOpeningId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Row);
