import React, { useState } from 'react';
import cx                  from 'classnames';
import Loader              from 'theme/Loader';
import { images }          from 'theme/img/images';

import './ScrollingBar.sass';


export default function ScrollingBar({title, children, className, loading}) {
  const [visible, toggle] = useState(true);
  const click = () => toggle(!visible);

  return (
    <div className={cx("ScrollingBar", className)}>
      <Loader loading={loading} />
      <div className={cx('ScrollingBar__heading', {visible})}>
        <h4>{title}</h4>
        <span
          className="ScrollingBar__toggle"
          onClick={click}
        >
          <img
            src={images.expandBig}
            className={cx({visible})}
            alt="toggle"
          />
        </span>
      </div>
      {visible &&
        <div className="ScrollingBar__content">{children}</div>
      }
    </div>
  )
}
