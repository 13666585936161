import React                from 'react';
import {reduxForm, Field}   from 'redux-form';
import {TextField, Captcha} from 'theme/Form';
import {required}           from 'theme/utils/validate';

import './EducatorForm.sass'


export default reduxForm({
  enableReinitialize: true
})(
({handleSubmit, pristine, submitting}) => {
  return (
    <div className="EducatorForm">
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Educator name"
          required
          placeholder="Enter educator name..."
          component={TextField}
        />
        <Field
          name="captcha"
          component={Captcha}
          validate={required}
        />
        <button
          type="submit"
          className="btn-primary"
          disabled={pristine || submitting}
        >
        Add School
        </button>
      </form>
    </div>
  )
})
