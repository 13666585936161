import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { Helmet }             from 'react-helmet';
import { compose,
         setDisplayName,
         lifecycle }          from 'recompose';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Return                 from 'theme/Return';
import { withScroll }         from 'theme/utils/recompose';
import JobContainer           from 'jobs/JobContainer';
import { getJob }             from 'jobs/actions';
import { getOrganization }    from 'organizations/actions';

import './JobPreview.sass';


const JobPreview = compose(
  setDisplayName('JobPreview'),
  withScroll(),
  lifecycle({
    componentDidMount() {
      this.props.getJob(this.props.match.params.jobId);
      this.props.getOrganization();
    }
  })
)(() => {
    return (
      <div className="JobPreview page">
        <Helmet>
          <title>Vetit — Job Preview</title>
        </Helmet>
        <Header />
        <div className="JobPreview__return">
          <div className="container">
            <Return
              text="Back to job listing"
              link='/org-admin/jobs'
              brightArrowIcon
            />
          </div>
        </div>
        <div className="container">
          <JobContainer isOrgAdminPage />
        </div>
        <Footer />
      </div>
    )
  }
)

const mapStateToProps = ({data, loading}, {match}) => {
  const organizationId = match.params.id;
  return {
    job: data[`job:${match.params.jobId}`]?.jobOpening || {},
    organization: data && data[`organization:employer:${organizationId}`] || {},
    loadingOrganization: loading[`organization:employer:${organizationId}`],
    loadingJob: loading[`job:${match.params.jobId}`]
  }
}

const mapDispatchToProps = (dispatch, {match: params}) => {
  const organizationId = params.params.id;
  const organizationType = 'employer';

  return {
    getJob: (jobId) => new Promise((resolve, reject) => dispatch(getJob({jobId, resolve, reject}))),
    getOrganization: () => new Promise((resolve, reject) => dispatch(getOrganization(organizationId, organizationType, {resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JobPreview))
