import { getYear, differenceInYears } from 'date-fns';

const startDate = new Date(1950, 1, 1);
const scholarshipValues = ['None', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];
const instructionValues = ['On-Base','On-Campus', 'On-Line', 'Hybrid'];
const fundingValues = ['MyCAA','DoD TA', 'Post-9/11 GI Bill', 'MGIB-AD', 'MGIB-SR', 'REAP', 'Other', 'None'];
const branchValues = [ 'Air Force', 'Army', 'Coast Guard', 'Marines', 'Navy', 'Space Force', 'Spouse'];
const paygradeValues = ['Spouse','E1','E2','E3','E4','E5','E6','E7','E8','E9','W1','W2','W3','W4','W5','O1','O2','O3','O4','O5','O6','O7','O8','O9','O10'];
const outofpocketValues = ['$0', '<$250', '<$1,000', '<$2,500', '<$5,000', '<$10,000', '<$15,000', '<$20,000', '>$20,000'];
const avgmonthlypayValues = ['<$1,750', '<$2,500', '<$3,000', '<$4,000', '<$5,000', '<$6,000', '<$7,000', '<$8,000', '<$9,000', '<$10,000', '<$11,000', '<$12,000', '<$13,000', '>$13,000'];
const degreeValue = [
  'Associate',
  'Bachelors',
  'Masters',
  'Doctoral',
  'Certificate - Undergrad',
  'Certificate - Grad',
  'Credential / Other Certification'
]

const industriesValues = [
  'Admin of Environmental Quality Programs',
  'Administration of Air & Water Resource & Solid Waste Management Programs',
  'Administration of Human Resource Programs',
  'Administration of Veterans\' Affairs',
  'Agents & Managers for Public Figures',
  'Aircraft',
  'All Other Ambulatory Health Care',
  'All Other Crop Farming',
  'All Other General Merch&ise Stores',
  'All Other Information',
  'All Other Miscellaneous Crop Farming',
  'All Other Miscellaneous Food',
  'All Other Miscellaneous Schools & Instruction',
  'All Other Miscellaneous Waste Management',
  'All Other Outpatient Care Centers',
  'All Other Pipeline Transpo',
  'All Other Specialty Food Stores',
  'All Other Support',
  'All Other Transit & Ground Passenger Transpo',
  'Alumina & Aluminum Production & Processing',
  'Ambulance',
  'Amusement, Gambling, & Recreation Industries',
  'Apple Orchards',
  'Armored Car',
  'Automatic Environmental Control  for Residential, Commercial, & Appliance Use',
  'Automobile & Other Motor Vehicle  Wholesalers',
  'Automotive Mechanical & Electrical Repair & Maintenance',
  'Automotive Transmission Repair',
  'Basic Chemical',
  'Beer & Ale  Wholesalers',
  'Beer, Wine, & Liquor Stores',
  'Berry (except Strawberry) Farming',
  'Biomass Electric Power Generation',
  'Book, Periodical, & Newspaper  Wholesalers',
  'Breakfast Cereal',
  'Building Finishing Contractors',
  'Business & Secretarial Schools',
  'Business Schools & Computer & Mngmt Tng',
  'Capacitor, Resistor, Coil, Transformer, & Other Inductor',
  'Casinos (except Casino Hotels)',
  'Cement & Concrete Product',
  'Chemical & Allied Products  Wholesalers',
  'Child Day Care',
  'Children\'s & Infants\' Clothing Stores',
  'Clay & Ceramic & Refractory Minerals Mining',
  'Coating, Engraving, Heat Treating, & Allied Activities',
  'Commercial & Industrial Machinery & Equipment (except Automotive & Electronic) Repair & Maintenance',
  'Commercial & Service Industry Machinery',
  'Commercial Banking',
  'Commodity Contracts Dealing',
  'Community Food',
  'Computer & Computer Peripheral Equipment & Software  Wholesalers',
  'Computer Networking',
  'Computer Systems Design',
  'Computer Training',
  'Construction & Mining (except Oil Well) Machinery & Equipment  Wholesalers',
  'Construction of Buildings',
  'Construction, Transpo, Mining, & Forestry Machinery & Equipment Rental & Leasing',
  'Consumer Electronics Repair & Maintenance',
  'Convenience Stores',
  'Convention & Visitors Bureaus',
  'Copper, Nickel, Lead, & Zinc Mining',
  'Cotton Farming',
  'Courts',
  'Crushed & Broken Granite Mining & Quarrying',
  'Custom Roll Forming',
  'Dairy Cattle & Milk Production',
  'Deep Sea Freight Transpo',
  'Department Stores',
  'Direct Health & Medical Insurance Carriers',
  'Direct Property & Casualty Insurance Carriers',
  'Distilleries',
  'Drafting',
  'Drugs & Druggists\' Sundries Wholesalers',
  'Electric Lamp Bulb & Part',
  'Electrical Equipment',
  'Electronic & Precision Equipment',
  'Electronic Shopping & Mail-Order Houses',
  'Emergency & Other Relief',
  'Engine & Transmission Equipment',
  'Exam Preparation & Tutoring',
  'Executive Offices',
  'Exterminating & Pest Control',
  'Fabricated Pipe & Pipe Fitting',
  'Family Planning Centers',
  'Farm Supplies  Wholesalers',
  'Fiber, Yarn, & Thread Mills',
  'Financial Transactions Processing, Reserve, & Clearinghouse Activities',
  'Fire Protection',
  'Fitness & Recreational Sports Centers',
  'Flavoring Syrup & Concentrate',
  'Floor Covering Stores',
  'Florists',
  'Fluid Milk',
  'Footwear',
  'Forestry & Logging',
  'Framing Contractors',
  'Freight Transpo Arrangement',
  'Frozen Fruit, Juice, & Vegetable',
  'Fruit & Vegetable Markets',
  'Funeral Homes & Funeral',
  'General Automotive Repair',
  'General Freight Trucking, Long-Distance, Less Than Truckload',
  'General Merch&ise Stores',
  'General Rental Centers',
  'Gift, Novelty, & Souvenir Stores',
  'Golf Courses & Country Clubs',
  'Grain & Oilseed Milling',
  'Greenhouse & Nursery Production',
  'Guided Missile & Space Vehicle',
  'Hardware',
  'Health & Welfare Funds',
  'Heavy Duty Truck',
  'HMO Medical Centers',
  'Horses & Other Equine Production',
  'Household Appliance',
  'Household Appliances, Electric Housewares, & Consumer Electronics  Wholesalers',
  'Human Rights Organizations',
  'Ice Cream & Frozen Dessert',
  'Industrial & Personal Service Paper  Wholesalers',
  'Industrial Gas',
  'Industrial Machinery Maintenance',
  'Industrial Truck, Tractor, Trailer, & Stacker Machinery',
  'Information Technology',
  'Internet Publishing & Broadcasting & Web Search Portals',
  'Investment Banking & Securities Dealing',
  'Iron & Steel Mills & Ferroalloy',
  'Jewelry & Silverware',
  'Knit Fabric Mills',
  'L&scape Architectural',
  'Language Schools',
  'Legal',
  'Lessors of Other Real Estate Property',
  'Limited-Service Restaurants',
  'Livestock  Wholesalers',
  'Local Messengers & Local Delivery',
  'Lumber, Plywood, Millwork, & Wood Panel  Wholesalers',
  'Manufactured Home (Mobile Home)',
  'Manufacturing & Reproducing Magnetic & Optical Media',
  'Marketing Research & Public Opinion Polling',
  'Materials Recovery Facilities',
  'Measuring, Dispensing, & Other Pumping Equipment',
  'Meat Processed from Carcasses',
  'Men\'s & Boys\' Clothing & Furnishings  Wholesalers',
  'Metal Can',
  'Metal Household Furniture',
  'Metal Tank (Heavy Gauge)',
  'Millwork',
  'Mining & Oil & Gas Field Machinery',
  'Miscellaneous Intermediation',
  'Mobile Food',
  'Motion Picture & Video Distribution',
  'Motor & Generator',
  'Motor Vehicle Body',
  'Motor Vehicle Parts',
  'Motor Vehicle Seating & Interior Trim',
  'Motorcycle, Boat, & Other Motor Vehicle Dealers',
  'Music Publishers',
  'Natural Gas Distribution',
  'Natural Gas Extraction',
  'New Car Dealers',
  'Newspaper Publishers',
  'Nonchocolate Confectionery',
  'Nondepository Credit Intermediation',
  'Nonferrous Metal (except Copper & Aluminum) Rolling, Drawing, Extruding, & Alloying',
  'Nonferrous Metal Production & Processing',
  'Nonscheduled Air Transpo',
  'Nonscheduled Chartered Passenger Air Transpo',
  'Nursing Care Facilities',
  'Office Administrative',
  'Office Equipment  Wholesalers',
  'Office Supplies (except Paper)',
  'Office Supply, Stationery, & Gift Stores',
  'Offices of Chiropractors',
  'Offices of Lawyers',
  'Offices of Optometrists',
  'Offices of Physicians',
  'Offices of Real Estate Agents & Brokers',
  'Oil & Gas Pipeline & Related Structures Construction',
  'Open-End Investment Funds',
  'Ophthalmic Goods  Wholesalers',
  'Orange Groves',
  'Ornamental & Architectural Metal Work',
  'Other  Related to Advertising',
  'Other Activities Related to Credit Intermediation',
  'Other Aircraft Parts & Auxiliary Equipment',
  'Other Ambulatory Health Care',
  'Other Aquaculture',
  'Other Basic Inorganic Chemical',
  'Other Building Equipment Contractors',
  'Other Building Material Dealers',
  'Other Chemical & Fertilizer Mineral Mining',
  'Other Clothing Stores',
  'Other Community Housing',
  'Other Construction Material  Wholesalers',
  'Other Crop Farming',
  'Other Depository Credit Intermediation',
  'Other Electric Power Generation',
  'Other Electronic Parts & Equipment  Wholesalers',
  'Other Fabricated Wire Product',
  'Other Gambling Industries',
  'Other Health & Personal Care Stores',
  'Other Individual & Family',
  'Other Leather & Allied Product',
  'Other Miscellaneous',
  'Other Motion Picture & Video Industries',
  'Other Noncitrus Fruit Farming',
  'Other Nonmetallic Mineral Mining & Quarrying',
  'Other Outpatient Care Centers',
  'Other Personal & Household Goods Repair & Maintenance',
  'Other Petroleum & Coal Products',
  'Other Residential Care Facilities',
  'Other Scientific & Technical Consulting',
  'Other Specialized Design',
  'Other Support Activities for Water Transpo',
  'Other Travel Arrangement & Reservation',
  'Outdoor Advertising',
  'Outpatient Care Centers',
  'Paint & Coating',
  'Paper & Paper Product  Wholesalers',
  'Parking Lots & Garages',
  'Peanut Farming',
  'Personal & Laundry',
  'Petrochemical',
  'Petroleum & Coal Products',
  'Pharmaceutical & Medicine',
  'Phosphatic Fertilizer',
  'Piece Goods, Notions, & Other Dry Goods  Wholesalers',
  'Pipeline Transpo of Crude Oil',
  'Plastics Bag & Pouch',
  'Plastics Materials & Basic Forms & Shapes  Wholesalers',
  'Plate Work & Fabricated Structural Product',
  'Police Protection',
  'Potash, Soda, & Borate Mineral Mining',
  'Poultry Processing',
  'Power Boiler & Heat Exchanger',
  'Printing & Related Support Activities',
  'Private Households',
  'Promoters of Performing Arts, Sports, & Similar Events without Facilities',
  'Pump & Compressor',
  'Radio Stations',
  'Rail Transpo',
  'Railroad Rolling Stock',
  'Reconstituted Wood Product',
  'Regulation of Agricultural Marketing & Commodities',
  'Religious Organizations',
  'Repair & Maintenance',
  'Residential Health Facilities',
  'Resin, Rubber, & Artificial Fibers',
  'Retirement & Assisted Living',
  'Road Transpo Support',
  'Rooming & Boarding Houses, Dormitories, & Workers\' Camps',
  'Rubber & Plastics Hoses & Belting',
  'RV (Recreational Vehicle) Parks & Campgrounds',
  'S&, Gravel, Clay, & Ceramic & Refractory Minerals Mining & Quarrying',
  'Savings Institutions',
  'Sawmills & Wood Preservation',
  'Scenic Water & Sightseeing Transpo',
  'School & Employee Bus Transpo',
  'Seafood Product Preparation & Packaging',
  'Search, Detection, Navigation, Guidance, Aeronautical, & Nautical System & Instrument',
  'Security Guards & Patrol',
  'Sewage Treatment Facilities',
  'Sheep & Goat Farming',
  'Sheet Metal Work',
  'Shoe Stores',
  'Showcase, Partition, Shelving, & Locker',
  'Sign',
  'Site Preparation Contractors',
  'Small Arms Ammunition',
  'Snack Food',
  'Social Advocacy Organizations',
  'Soft Drink & Ice',
  'Solar Electric Power Generation',
  'Soybean Farming',
  'Space Research & Technology',
  'Special Needs Transpo',
  'Specialty Canning',
  'Spectator Sports',
  'Sporting & Recreational Goods & Supplies  Wholesalers',
  'Sporting Goods Stores',
  'Sports Teams & Clubs',
  'Steam & Air-Conditioning Supply',
  'Steel Wire Drawing',
  'Structural Steel & Precast Concrete Contractors',
  'Support Activities for Forestry',
  'Support Activities for Oil & Gas Operations',
  'Support Activities for Rail Transpo',
  'Surgical Appliance & Supplies',
  'Tax Preparation',
  'Taxi Service',
  'Telephone Apparatus',
  'Textile Bag & Canvas Mills',
  'Theater Companies & Dinner Theaters',
  'Tile & Terrazzo Contractors',
  'Title Abstract & Settlement Offices',
  'Tobacco Stores',
  'Totalizing Fluid Meter & Counting Device',
  'Translation & Interpretation',
  'Tree Nut Farming',
  'Truck, Utility Trailer, & RV (Recreational Vehicle) Rental & Leasing',
  'Trusts, Estates, & Agency Accounts',
  'Unlaminated Plastics Film & Sheet (except Packaging)',
  'Uranium-Radium-Vanadium Ore Mining',
  'Utilities',
  'Vending Machine Operators',
  'Warehouse Clubs & Supercenters',
  'Water, Sewage & Other Systems',
  'Wind Electric Power Generation',
  'Wired & Wireless Telco Carriers',
  'Women\'s Clothing Stores',
  'Wood Product'
];

const functionsValues = [
  'Accountants and Auditors',
  'Actors Producers and Directors',
  'Actuaries',
  'Administrative Services and Facilities Managers',
  'Adult Basic Education Adult Secondary Education and English as a Second Language Instructors',
  'Advertising and Promotions Managers',
  'Advertising Sales Agents',
  'Aerospace Engineers',
  'Agents and Business Managers of Artists Performers and Athletes',
  'Agricultural and Food Science Technicians',
  'Agricultural and Food Scientists',
  'Agricultural Engineers',
  'Agricultural Inspectors',
  'Air Traffic Controllers and Airfield Operations Specialists',
  'Aircraft Mechanics and Service Technicians',
  'Aircraft Pilots and Flight Engineers',
  'Aircraft Structure Surfaces Rigging and Systems Assemblers',
  'Ambulance Drivers and Attendants Except Emergency Medical Technicians',
  'Animal Breeders',
  'Animal Caretakers',
  'Animal Control Workers',
  'Animal Trainers',
  'Architects Except Naval',
  'Architectural and Engineering Managers',
  'Archivists Curators and Museum Technicians',
  'Artists and Related Workers',
  'Arts Communications History and Humanities Teachers Postsecondary',
  'Astronomers and Physicists',
  'Athletes Coaches Umpires and Related Workers',
  'Atmospheric and Space Scientists',
  'Audiologists',
  'Automotive Technicians and Repairers',
  'Baggage Porters Bellhops and Concierges',
  'Bailiffs Correctional Officers and Jailers',
  'Bakers',
  'Barbers Hairdressers Hairstylists and Cosmetologists',
  'Bartenders',
  'Bill and Account Collectors',
  'Billing and Posting Clerks',
  'Bioengineers and Biomedical Engineers',
  'Biological Scientists',
  'Biological Technicians',
  'Boilermakers',
  'Bookkeeping Accounting and Auditing Clerks',
  'Brickmasons Blockmasons and Stonemasons',
  'Bridge and Lock Tenders',
  'Broadcast Announcers and Radio Disc Jockeys',
  'Broadcast Sound and Lighting Technicians',
  'Brokerage Clerks',
  'Budget Analysts',
  'Building Cleaning Workers',
  'Bus and Truck Mechanics and Diesel Engine Specialists',
  'Business Teachers Postsecondary',
  'Butchers and Other Meat Poultry and Fish Processing Workers',
  'Buyers and Purchasing Agents',
  'Cabinetmakers and Bench Carpenters',
  'Cargo and Freight Agents',
  'Carpenters',
  'Carpet Floor and Tile Installers and Finishers',
  'Cashiers',
  'Cement Masons Concrete Finishers and Terrazzo Workers',
  'Chemical Engineers',
  'Chemical Processing Machine Setters Operators and Tenders',
  'Chemical Technicians',
  'Chemists and Materials Scientists',
  'Chief Executives',
  'Childcare Workers',
  'Chiropractors',
  'Civil Engineers',
  'Claims Adjusters Appraisers Examiners and Investigators',
  'Clergy',
  'Clinical Laboratory Technologists and Technicians',
  'Compensation and Benefits Managers',
  'Compensation Benefits and Job Analysis Specialists',
  'Compliance Officers',
  'Computer and Information Analysts',
  'Computer and Information Research Scientists',
  'Computer and Information Systems Managers',
  'Computer Hardware Engineers',
  'Computer Numerically Controlled Tool Operators and Programmers',
  'Computer Support Specialists',
  'Computer Automated Teller and Office Machine Repairers',
  'Conservation Scientists and Foresters',
  'Construction and Building Inspectors',
  'Construction Equipment Operators',
  'Construction Laborers',
  'Construction Managers',
  'Control and Valve Installers and Repairers',
  'Conveyor Operators and Tenders',
  'Cooks',
  'Correspondence Clerks',
  'Cost Estimators',
  'Counselors',
  'Counter and Rental Clerks and Parts Salespersons',
  'Couriers and Messengers',
  'Court Municipal and License Clerks',
  'Crane and Tower Operators',
  'Credit Analysts',
  'Credit Authorizers Checkers and Clerks',
  'Credit Counselors and Loan Officers',
  'Crushing Grinding Polishing Mixing and Blending Workers',
  'Customer Service Representatives',
  'Cutting Workers',
  'Dancers and Choreographers',
  'Data Entry and Information Processing Workers',
  'Data Scientists',
  'Database and Network Administrators and Architects',
  'Dental and Ophthalmic Laboratory Technicians and Medical Appliance Technicians',
  'Dentists',
  'Derrick Rotary Drill and Service Unit Operators Oil and Gas',
  'Designers',
  'Desktop Publishers',
  'Detectives and Criminal Investigators',
  'Diagnostic Related Technologists and Technicians',
  'Dietitians and Nutritionists',
  'Dining Room and Cafeteria Attendants and Bartender Helpers',
  'Directors Religious Activities and Education',
  'Dishwashers',
  'Dispatchers',
  'Drafters',
  'Dredge Operators',
  'Driver/Sales Workers and Truck Drivers',
  'Drywall Installers Ceiling Tile Installers and Tapers',
  'Economists',
  'Education and Childcare Administrators',
  'Education and Library Science Teachers Postsecondary',
  'Electrical and Electronics Engineers',
  'Electrical Electronics and Electromechanical Assemblers',
  'Electricians',
  'Elementary and Middle School Teachers',
  'Elevator and Escalator Installers and Repairers',
  'Eligibility Interviewers Government Programs',
  'Embalmers and Crematory Operators',
  'Emergency Management Directors',
  'Emergency Medical Technicians and Paramedics',
  'Engine and Other Machine Assemblers',
  'Engineering and Architecture Teachers Postsecondary',
  'Engineering Technologists and Technicians Except Drafters',
  'Entertainment and Recreation Managers',
  'Environmental Engineers',
  'Environmental Science and Geoscience Technicians',
  'Environmental Scientists and Geoscientists',
  'Explosives Workers Ordnance Handling Experts and Blasters',
  'Extruding Forming Pressing and Compacting Machine Setters Operators and Tenders',
  'Farm and Home Management Educators',
  'Farmers Ranchers and Other Agricultural Managers',
  'Fast Food and Counter Workers',
  'Fence Erectors',
  'Fiberglass Laminators and Fabricators',
  'File Clerks',
  'Financial Analysts and Advisors',
  'Financial Examiners',
  'Financial Managers',
  'Fire Inspectors',
  'Firefighters',
  'First-Line Enlisted Military Supervisors',
  'First-Line Supervisors of Building and Grounds Cleaning and Maintenance Workers',
  'First-Line Supervisors of Construction Trades and Extraction Workers',
  'First-Line Supervisors of Entertainment and Recreation Workers',
  'First-Line Supervisors of Farming Fishing and Forestry Workers',
  'First-Line Supervisors of Firefighting and Prevention Workers',
  'First-Line Supervisors of Law Enforcement Workers',
  'First-Line Supervisors of Mechanics Installers and Repairers',
  'First-Line Supervisors of Office and Administrative Support Workers',
  'First-Line Supervisors of Personal Service Workers',
  'First-Line Supervisors of Production and Operating Workers',
  'First-Line Supervisors of Sales Workers',
  'First-Line Supervisors of Transportation and Material Moving Workers',
  'Fish and Game Wardens',
  'Fishing and Hunting Workers',
  'Flight Attendants',
  'Food Preparation Workers',
  'Food Servers Nonrestaurant',
  'Food Service Managers',
  'Forest and Conservation Technicians',
  'Forest and Conservation Workers',
  'Forming Machine Setters Operators and Tenders Metal and Plastic',
  'Fundraisers',
  'Funeral Attendants',
  'Furnace Kiln Oven Drier and Kettle Operators and Tenders',
  'Furniture Finishers',
  'Gambling Cage Workers',
  'Gambling Services Workers',
  'General and Operations Managers',
  'Glaziers',
  'Graders and Sorters Agricultural Products',
  'Grounds Maintenance Workers',
  'Hazardous Materials Removal Workers',
  'Health Information Technologists and Medical Registrars',
  'Health Practitioner Support Technologists and Technicians',
  'Health Teachers Postsecondary',
  'Heating Air Conditioning and Refrigeration Mechanics and Installers',
  'Heavy Vehicle and Mobile Equipment Service Technicians and Mechanics',
  'Helpers Construction Trades',
  'Helpers--Extraction Workers',
  'Highway Maintenance Workers',
  'Hoist and Winch Operators',
  'Home Appliance Repairers',
  'Home Health and Personal Care Aides',
  'Hosts and Hostesses Restaurant Lounge and Coffee Shop',
  'Hotel Motel and Resort Desk Clerks',
  'Human Resources Assistants Except Payroll and Timekeeping',
  'Human Resources Managers',
  'Human Resources Workers',
  'Industrial Engineers Including Health and Safety',
  'Industrial Machinery Installation Repair and Maintenance Workers',
  'Industrial Production Managers',
  'Industrial Truck and Tractor Operators',
  'Inspectors Testers Sorters Samplers and Weighers',
  'Instructional Coordinators',
  'Insulation Workers',
  'Insurance Claims and Policy Processing Clerks',
  'Insurance Sales Agents',
  'Interviewers Except Eligibility and Loan',
  'Jewelers and Precious Stone and Metal Workers',
  'Judges Magistrates and Other Judicial Workers',
  'Laborers and Material Movers',
  'Laundry and Dry-Cleaning Workers',
  'Law Criminal Justice and Social Work Teachers Postsecondary',
  'Lawyers and Judicial Law Clerks',
  'Legislators',
  'Librarians and Media Collections Specialists',
  'Library Assistants Clerical',
  'Library Technicians',
  'Licensed Practical and Licensed Vocational Nurses',
  'Life Sciences Teachers Postsecondary',
  'Line Installers and Repairers',
  'Loan Interviewers and Clerks',
  'Locomotive Engineers and Operators',
  'Lodging Managers',
  'Logging Workers',
  'Logisticians and Project Management Specialists',
  'Machine Tool Cutting Setters Operators and Tenders Metal and Plastic',
  'Machinists',
  'Mail Clerks and Mail Machine Operators Except Postal Service',
  'Maintenance and Repair Workers General',
  'Management Analysts',
  'Marine Engineers and Naval Architects',
  'Market Research Analysts and Marketing Specialists',
  'Marketing and Sales Managers',
  'Massage Therapists',
  'Materials Engineers',
  'Math and Computer Science Teachers Postsecondary',
  'Mathematicians',
  'Mechanical Engineers',
  'Medical and Health Services Managers',
  'Medical Records Specialists',
  'Medical Scientists',
  'Meeting Convention and Event Planners',
  'Metal Furnace Operators Tenders Pourers and Casters',
  'Meter Readers Utilities',
  'Military Enlisted Tactical Operations and Air/Weapons Specialists and Crew Members',
  'Military Officer Special and Tactical Operations Leaders',
  'Mining and Geological Engineers Including Mining Safety Engineers',
  'Miscellaneous Agricultural Workers',
  'Miscellaneous Assemblers and Fabricators',
  'Miscellaneous Business Operations Specialists',
  'Miscellaneous Communications Equipment Operators',
  'Miscellaneous Community and Social Service Specialists',
  'Miscellaneous Computer Occupations',
  'Miscellaneous Construction and Related Workers',
  'Miscellaneous Educational Instruction and Library Workers',
  'Miscellaneous Electrical and Electronic Equipment Mechanics Installers and Repairers',
  'Miscellaneous Engineers',
  'Miscellaneous Entertainers and Performers Sports and Related Workers',
  'Miscellaneous Entertainment Attendants and Related Workers',
  'Miscellaneous Extraction Workers',
  'Miscellaneous Financial Clerks',
  'Miscellaneous Financial Specialists',
  'Miscellaneous First-Line Supervisors Protective Service Workers',
  'Miscellaneous Food Preparation and Serving Related Workers',
  'Miscellaneous Food Processing Workers',
  'Miscellaneous Health Practitioners and Technical Workers',
  'Miscellaneous Health Technologists and Technicians',
  'Miscellaneous Healthcare Diagnosing or Treating Practitioners',
  'Miscellaneous Healthcare Support Occupations',
  'Miscellaneous Information and Record Clerks',
  'Miscellaneous Installation Maintenance and Repair Workers',
  'Miscellaneous Legal Support Workers',
  'Miscellaneous Life Scientists',
  'Miscellaneous Life Physical and Social Science Technicians',
  'Miscellaneous Managers',
  'Miscellaneous Material Moving Workers',
  'Miscellaneous Mathematical Science Occupations',
  'Miscellaneous Media and Communication Equipment Workers',
  'Miscellaneous Media and Communication Workers',
  'Miscellaneous Metal Workers and Plastic Workers',
  'Miscellaneous Motor Vehicle Operators',
  'Miscellaneous Office and Administrative Support Workers',
  'Miscellaneous Personal Appearance Workers',
  'Miscellaneous Personal Care and Service Workers',
  'Miscellaneous Physical Scientists',
  'Miscellaneous Plant and System Operators',
  'Miscellaneous Postsecondary Teachers',
  'Miscellaneous Production Workers',
  'Miscellaneous Protective Service Workers',
  'Miscellaneous Rail Transportation Workers',
  'Miscellaneous Religious Workers',
  'Miscellaneous Sales and Related Workers',
  'Miscellaneous Sales Representatives Services',
  'Miscellaneous Social Scientists and Related Workers',
  'Miscellaneous Teachers and Instructors',
  'Miscellaneous Textile Apparel and Furnishings Workers',
  'Miscellaneous Transportation Workers',
  'Miscellaneous Vehicle and Mobile Equipment Mechanics Installers and Repairers',
  'Miscellaneous Woodworkers',
  'Model Makers and Patternmakers Metal and Plastic',
  'Model Makers and Patternmakers Wood',
  'Models Demonstrators and Product Promoters',
  'Molders and Molding Machine Setters Operators and Tenders Metal and Plastic',
  'Morticians Undertakers and Funeral Arrangers',
  'Motion Picture Projectionists',
  'Multiple Machine Tool Setters Operators and Tenders Metal and Plastic',
  'Musicians Singers and Related Workers',
  'Natural Sciences Managers',
  'New Accounts Clerks',
  'News Analysts Reporters and Journalists',
  'Nuclear Engineers',
  'Nuclear Technicians',
  'Nurse Anesthetists',
  'Nurse Midwives',
  'Nurse Practitioners',
  'Nursing Assistants Orderlies and Psychiatric Aides',
  'Occupational Health and Safety Specialists and Technicians',
  'Occupational Therapy Assistants and Aides',
  'Office Clerks General',
  'Office Machine Operators Except Computer',
  'Operations Research Analysts',
  'Opticians Dispensing',
  'Optometrists',
  'Order Clerks',
  'Packaging and Filling Machine Operators and Tenders',
  'Painters and Paperhangers',
  'Painting Workers',
  'Paralegals and Legal Assistants',
  'Parking Attendants',
  'Parking Enforcement Workers',
  'Passenger Attendants',
  'Passenger Vehicle Drivers',
  'Payroll and Timekeeping Clerks',
  'Personal Service Managers',
  'Pest Control Workers',
  'Petroleum Engineers',
  'Pharmacists',
  'Photographers',
  'Photographic Process Workers and Processing Machine Operators',
  'Physical Sciences Teachers Postsecondary',
  'Physical Therapist Assistants and Aides',
  'Physician Assistants',
  'Physicians',
  'Pipelayers Plumbers Pipefitters and Steamfitters',
  'Plasterers and Stucco Masons',
  'Podiatrists',
  'Police Officers',
  'Postal Service Workers',
  'Postmasters and Mail Superintendents',
  'Power Plant Operators Distributors and Dispatchers',
  'Precision Instrument and Equipment Repairers',
  'Preschool and Kindergarten Teachers',
  'Pressers Textile Garment and Related Materials',
  'Printing Workers',
  'Private Detectives and Investigators',
  'Procurement Clerks',
  'Production Planning and Expediting Clerks',
  'Proofreaders and Copy Markers',
  'Property Appraisers and Assessors',
  'Property Real Estate and Community Association Managers',
  'Psychologists',
  'Public Relations and Fundraising Managers',
  'Public Relations Specialists',
  'Pumping Station Operators',
  'Purchasing Managers',
  'Radio and Telecommunications Equipment Installers and Repairers',
  'Railroad Brake Signal and Switch Operators and Locomotive Firers',
  'Railroad Conductors and Yardmasters',
  'Rail-Track Laying and Maintenance Equipment Operators',
  'Real Estate Brokers and Sales Agents',
  'Receptionists and Information Clerks',
  'Recreation and Fitness Workers',
  'Refuse and Recyclable Material Collectors',
  'Registered Nurses',
  'Reinforcing Iron and Rebar Workers',
  'Reservation and Transportation Ticket Agents and Travel Clerks',
  'Residential Advisors',
  'Retail Salespersons',
  'Rock Splitters Quarry',
  'Roofers',
  'Roustabouts Oil and Gas',
  'Sailors and Marine Oilers',
  'Sales Engineers',
  'Sales Representatives Wholesale and Manufacturing',
  'Secondary School Teachers',
  'Secretaries and Administrative Assistants',
  'Securities Commodities and Financial Services Sales Agents',
  'Security Guards and Gambling Surveillance Officers',
  'Self-Enrichment Teachers',
  'Semiconductor Processing Technicians',
  'Septic Tank Servicers and Sewer Pipe Cleaners',
  'Sewing Machine Operators',
  'Sheet Metal Workers',
  'Ship and Boat Captains and Operators',
  'Ship Engineers',
  'Shipping Receiving and Inventory Clerks',
  'Shoe and Leather Workers',
  'Small Engine Mechanics',
  'Social and Community Service Managers',
  'Social Science Research Assistants',
  'Social Sciences Teachers Postsecondary',
  'Social Workers',
  'Sociologists',
  'Software and Web Developers Programmers and Testers',
  'Solar Photovoltaic Installers',
  'Special Education Teachers',
  'Stationary Engineers and Boiler Operators',
  'Statistical Assistants',
  'Statisticians',
  'Structural Iron and Steel Workers',
  'Structural Metal Fabricators and Fitters',
  'Substitute Teachers Short-Term',
  'Subway and Streetcar Operators',
  'Supervisors of Food Preparation and Serving Workers',
  'Surface Mining Machine Operators and Earth Drillers',
  'Surgeons',
  'Survey Researchers',
  'Surveying and Mapping Technicians',
  'Surveyors Cartographers and Photogrammetrists',
  'Switchboard Operators Including Answering Service',
  'Tailors Dressmakers and Sewers',
  'Tank Car Truck and Ship Loaders',
  'Tax Examiners Collectors and Preparers and Revenue Agents',
  'Teaching Assistants',
  'Telemarketers',
  'Telephone Operators',
  'Television Video and Film Camera Operators and Editors',
  'Tellers',
  'Textile Machine Setters Operators and Tenders',
  'Therapists',
  'Timing Device Assemblers and Adjusters',
  'Tool and Die Makers',
  'Tour and Travel Guides',
  'Traffic Technicians',
  'Training and Development Managers',
  'Training and Development Specialists',
  'Transportation Inspectors',
  'Transportation Service Attendants',
  'Transportation Storage and Distribution Managers',
  'Travel Agents',
  'Tutors',
  'Underground Mining Machine Operators',
  'Urban and Regional Planners',
  'Ushers Lobby Attendants and Ticket Takers',
  'Veterinarians',
  'Waiters and Waitresses',
  'Water and Wastewater Treatment Plant and System Operators',
  'Weighers Measurers Checkers and Samplers Recordkeeping',
  'Welding Soldering and Brazing Workers',
  'Wind Turbine Service Technicians',
  'Woodworking Machine Setters Operators and Tenders',
  'Writers and Editors'
]

const positionValues = [
  'Full-time',
  'Part-time',
  'Temporary',
  'Internship',
  'Apprenticeship',
  'Pre-Apprenticeship',
  'Leader Development Program'
];

const benefitValues = [
  'Health insurance',
  'Dental insurance',
  'Disability insurance',
  'Life insurance',
  'Vacation / Paid time off',
  'Paid sick leave',
  'Retirement / Pension plan',
  'Flexible schedule',
  'Relocation assistance',
  'Gym membership'
];

const companyCultureValues = [
  'Pioneering',
  'Guarding',
  'Driving',
  'Integrating'
]

const supportedProgramValues = [
  'DOD Skillbridge',
  'Registered Apprenticeship Program (RAP)',
  'Industry-Recognized Apprenticeship Program (IRAP)'
];

const formalEducationValues = [
  'High school diploma',
  'Earned GED',
  'Associate',
  'Bachelors',
  'Masters',
  'Doctoral',
  'Certificate - Undergrad',
  'Certificate - Grad',
  'Credential / Other Certification'
];

const identificationValues = [
  'Active Duty',
  'National Guard',
  'Reserve',
  'Veteran',
  'Spouse'
];

const countryValues = [
  'USA',
  'AFGHANISTAN',
  'ALBANIA',
  'ALGERIA',
  'ANDORRA',
  'ANGOLA',
  'ANTIGUA AND BARBUDA',
  'ARGENTINA',
  'ARMENIA',
  'AUSTRALIA',
  'AUSTRIA',
  'AZERBAIJAN',
  'BAHAMAS',
  'BAHRAIN',
  'BANGLADESH',
  'BARBADOS',
  'BELARUS',
  'BELGIUM',
  'BELIZE',
  'BENIN',
  'BHUTAN',
  'BOLIVIA',
  'BOSNIA AND HERZEGOVINA',
  'BOTSWANA',
  'BRAZIL',
  'BRUNEI DARUSSALAM',
  'BULGARIA',
  'BURKINA FASO',
  'BURUNDI',
  'CAMBODIA',
  'CAMEROON',
  'CANADA',
  'CAPE VERDE',
  'CENTRAL AFRICAN REPUBLIC',
  'CHAD',
  'CHILE',
  'CHINA',
  'COLOMBIA',
  'COMOROS',
  'CONGO',
  'COSTA RICA',
  'COTE D\'IVOIRE',
  'CROATIA',
  'CUBA',
  'CYPRUS',
  'CZECH REPUBLIC',
  'DENMARK',
  'DJIBOUTI',
  'DOMINICA',
  'DOMINICAN REPUBLIC',
  'ECUADOR',
  'EGYPT',
  'EL SALVADOR',
  'EQUATORIAL GUINEA',
  'ERITREA',
  'ESTONIA',
  'ETHIOPIA',
  'FEDERATED STATES OF MICRONESIA',
  'FIJI',
  'FINLAND',
  'FRANCE',
  'GABON',
  'GAMBIA',
  'GEORGIA',
  'GERMANY',
  'GHANA',
  'GREECE',
  'GREENLAND',
  'GRENADA',
  'GUATEMALA',
  'GUINEA',
  'GUINEA-BISSAU',
  'GUYANA',
  'HAITI',
  'HONDURAS',
  'HONG KONG',
  'HUNGARY',
  'ICELAND',
  'INDIA',
  'INDONESIA',
  'IRAN',
  'IRAQ',
  'IRELAND',
  'ISRAEL',
  'ITALY',
  'JAMAICA',
  'JAPAN',
  'JORDAN',
  'KAZAKHSTAN',
  'KENYA',
  'KIRIBATI',
  'KUWAIT',
  'KYRGYZSTAN',
  'LAO PEOPLE\'S DEMOCRATIC REPUBLIC',
  'LATVIA',
  'LEBANON',
  'LESOTHO',
  'LIBERIA',
  'LIBYA',
  'LIECHTENSTEIN',
  'LITHUANIA',
  'LUXEMBOURG',
  'MACEDONIA',
  'MADAGASCAR',
  'MALAWI',
  'MALAYSIA',
  'MALDIVES',
  'MALI',
  'MALTA',
  'MARSHALL ISLANDS',
  'MAURITANIA',
  'MAURITIUS',
  'MEXICO',
  'MOLDOVA',
  'MONACO',
  'MONGOLIA',
  'MONTENEGRO',
  'MONTSERRAT',
  'MOROCCO',
  'MOZAMBIQUE',
  'MYANMAR',
  'NAMIBIA',
  'NAURU',
  'NEPAL',
  'NETHERLANDS',
  'NEW ZEALAND',
  'NICARAGUA',
  'NIGER',
  'NIGERIA',
  'NORTH KOREA',
  'NORWAY',
  'OMAN',
  'PAKISTAN',
  'PALAU',
  'PANAMA',
  'PAPUA NEW GUINEA',
  'PARAGUAY',
  'PERU',
  'PHILIPPINES',
  'POLAND',
  'PORTUGAL',
  'QATAR',
  'ROMANIA',
  'RUSSIA',
  'RWANDA',
  'SAINT KITTS AND NEVIS',
  'SAINT LUCIA',
  'SAINT VINCENT AND THE GRENADINES',
  'SAMOA',
  'SAN MARINO',
  'SAUDI ARABIA',
  'SENEGAL',
  'SERBIA',
  'SEYCHELLES',
  'SIERRA LEONE',
  'SINGAPORE',
  'SLOVAKIA',
  'SLOVENIA',
  'SOLOMON ISLANDS',
  'SOMALIA',
  'SOUTH AFRICA',
  'SPAIN',
  'SRI LANKA',
  'SOUTH KOREA',
  'SUDAN',
  'SURINAME',
  'SWAZILAND',
  'SWEDEN',
  'SWITZERLAND',
  'SYRIA',
  'TAIWAN',
  'TAJIKISTAN',
  'TANZANIA',
  'THAILAND',
  'THE DEMOCRATIC REPUBLIC OF CONGO',
  'TIMOR-LESTE',
  'TOGO',
  'TONGA',
  'TRINIDAD AND TOBAGO',
  'TUNISIA',
  'TURKEY',
  'TURKMENISTAN',
  'TUVALU',
  'UGANDA',
  'UKRAINE',
  'UNITED ARAB EMIRATES',
  'UNITED KINGDOM',
  'URUGUAY',
  'UZBEKISTAN',
  'VANUATU',
  'VENEZUELA',
  'VIET NAM',
  'YEMEN',
  'ZAMBIA',
  'ZIMBABWE'
];

const clearanceValues = [
  'Secret',
  'Top Secret'
];

const fieldOfStudyValues = [
  'Accounting and Computer Science',
  'Accounting and Related Services',
  'Advanced/Graduate Dentistry and Oral Sciences',
  'Aerospace, Aeronautical and Astronautical Engineering',
  'African Languages, Literatures, and Linguistics',
  'Agricultural and Domestic Animal Services',
  'Agricultural and Food Products Processing',
  'Agricultural Business and Management',
  'Agricultural Engineering',
  'Agricultural Mechanization',
  'Agricultural Production Operations',
  'Agricultural Public Services',
  'Agriculture, Agriculture Operations, and Related Sciences, Other',
  'Agriculture, General',
  'Air Force ROTC, Air Science and Operations',
  'Air Transportation',
  'Allied Health and Medical Assisting Services',
  'Allied Health Diagnostic, Intervention, and Treatment Professions',
  'Alternative and Complementary Medical Support Services',
  'Alternative and Complementary Medicine and Medical Systems',
  'American Indian/Native American Languages, Literatures, and Linguistics',
  'American Literature (United States and Canadian)',
  'American Sign Language',
  'Animal Sciences',
  'Anthropology',
  'Apparel and Textiles',
  'Applied Horticulture and Horticultural Business Services',
  'Applied Mathematics',
  'Archeology',
  'Architectural Engineering Technologies/Technicians',
  'Architectural Engineering',
  'Architectural History and Criticism',
  'Architectural Sciences and Technology',
  'Architecture and Related Services, Other',
  'Architecture',
  'Area Studies',
  'Area, Ethnic, Cultural, and Gender Studies, Other',
  'Army ROTC, Military Science and Operations',
  'Arts, Entertainment,and Media Management',
  'Astronomy and Astrophysics',
  'Atmospheric Sciences and Meteorology',
  'Audiovisual Communications Technologies/Technicians',
  'Basic Skills and Developmental/Remedial Education',
  'Behavioral Sciences',
  'Bible/Biblical Studies',
  'Bilingual, Multilingual, and Multicultural Education',
  'Biochemical Engineering',
  'Biochemistry, Biophysics and Molecular Biology',
  'Bioethics/Medical Ethics',
  'Biological and Biomedical Sciences, Other',
  'Biological and Physical Sciences',
  'Biological/Biosystems Engineering',
  'Biology Technician/Biotechnology Laboratory Technician',
  'Biology, General',
  'Biomathematics, Bioinformatics, and Computational Biology',
  'Biomedical/Medical Engineering',
  'Biopsychology',
  'Biotechnology',
  'Boilermaking/Boilermaker',
  'Botany/Plant Biology',
  'Building/Construction Finishing, Management, and Inspection',
  'Business Administration, Management and Operations',
  'Business Operations Support and Assistant Services',
  'Business, Management, Marketing, and Related Support Services, Other',
  'Business/Commerce, General',
  'Business/Corporate Communications',
  'Business/Managerial Economics',
  'Carpenters',
  'Cell/Cellular Biology and Anatomical Sciences',
  'Celtic Languages, Literatures, and Linguistics',
  'Ceramic Sciences and Engineering',
  'Chemical Engineering',
  'Chemistry',
  'Chiropractic',
  'Citizenship Activities',
  'City/Urban, Community and Regional Planning',
  'Civil Engineering Technologies/Technicians',
  'Civil Engineering',
  'Classical and Ancient Studies',
  'Classics and Classical Languages, Literatures, and Linguistics',
  'Clinical Child Psychology',
  'Clinical Psychology',
  'Clinical, Counseling and Applied Psychology',
  'Clinical/Medical Laboratory Science/Research and Allied Professions',
  'Cognitive Psychology and Psycholinguistics',
  'Cognitive Science',
  'Communication and Media Studies',
  'Communication Disorders Sciences and Services',
  'Communication, Journalism, and Related Programs, Other',
  'Communications Technologies/Technicians and Support Services, Other',
  'Communications Technology/Technician',
  'Community Organization and Advocacy',
  'Community Psychology',
  'Comparative Psychology',
  'Computational Science',
  'Computer and Information Sciences and Support Services, Other',
  'Computer and Information Sciences, General',
  'Computer Engineering Technologies/Technicians',
  'Computer Engineering',
  'Computer Programming',
  'Computer Science',
  'Computer Software and Media Applications',
  'Computer Systems Analysis',
  'Computer Systems Networking and Telecommunications',
  'Computer/Information Technology Administration and Management',
  'Construction Engineering Technologies',
  'Construction Engineering',
  'Construction Management',
  'Construction Trades, General',
  'Construction Trades, Other',
  'Cosmetology and Related Personal Grooming Services',
  'Counseling Psychology',
  'Crafts/Craft Design, Folk Art and Artisanry',
  'Creative Writing',
  'Criminal Justice and Corrections',
  'Criminology',
  'Culinary Arts and Related Services',
  'Cultural Studies/Critical Theory and Analysis',
  'Curriculum and Instruction',
  'Dance',
  'Data Entry/Microcomputer Applications',
  'Data Processing',
  'Demography and Population Studies',
  'Dental Residency Programs',
  'Dental Support Services and Allied Professions',
  'Dentistry',
  'Design and Applied Arts',
  'Developmental and Child Psychology',
  'Dietetics and Clinical Nutrition Services',
  'Dispute Resolution',
  'Drafting/Design Engineering Technologies/Technicians',
  'Drama/Theatre Arts and Stagecraft',
  'East Asian Languages, Literatures, and Linguistics',
  'Ecology, Evolution, Systematics, and Population Biology',
  'Economics',
  'Education, General',
  'Education, Other',
  'Educational Administration and Supervision',
  'Educational Assessment, Evaluation, and Research',
  'Educational Psychology',
  'Educational/Instructional Media Design',
  'Electrical and Power Transmission Installers',
  'Electrical Engineering Technologies/Technicians',
  'Electrical, Electronics and Communications Engineering',
  'Electrical/Electronics Maintenance and Repair Technology',
  'Electromechanical Engineering',
  'Electromechanical Instrumentation and Maintenance Technologies/Technicians',
  'Energy and Biologically Based Therapies, Other',
  'Energy and Biologically Based Therapies',
  'Engineering Chemistry',
  'Engineering Mechanics',
  'Engineering Physics',
  'Engineering Science',
  'Engineering Technologies/Technicians, Other',
  'Engineering Technology, General',
  'Engineering-Related Fields',
  'Engineering-Related Technologies',
  'Engineering, General',
  'Engineering, Other',
  'English Composition',
  'English Language and Literature, General',
  'English Language and Literature/Letters, Other',
  'English Literature (British and Commonwealth)',
  'Entrepreneurial and Small Business Operations',
  'Environmental Control Technologies/Technicians',
  'Environmental Design',
  'Environmental Psychology',
  'Environmental/Environmental Health Engineering',
  'Ethnic, Cultural Minority, Gender, and Group Studies',
  'Experimental Psychology',
  'Family and Consumer Economics and Related Studies',
  'Family and Consumer Sciences/Human Sciences Business Services',
  'Family and Consumer Sciences/Human Sciences, General',
  'Family and Consumer Sciences/Human Sciences, Other',
  'Family Psychology',
  'Film/Video and Photographic Arts',
  'Finance and Financial Management Services',
  'Fine and Studio Arts',
  'Fire Protection',
  'Fishing and Fisheries Sciences and Management',
  'Food Science and Technology',
  'Foods, Nutrition, and Related Services',
  'Foreign Languages, Literatures, and Linguistics, Other',
  'Forensic Psychology',
  'Forest Engineering',
  'Forestry',
  'Funeral Service and Mortuary Science',
  'General Sales, Merchandising and Related Marketing Operations',
  'Genetics',
  'Geography and Cartography',
  'Geological and Earth Sciences/Geosciences',
  'Geological/Geophysical Engineering',
  'Germanic Languages, Literatures, and Linguistics',
  'Gerontology',
  'Geropsychology',
  'Graphic Communications',
  'Ground Transportation',
  'Health Aides/Attendants/Orderlies',
  'Health and Medical Administrative Services',
  'Health and Physical Education/Fitness',
  'Health Professions and Related Clinical Sciences, Other',
  'Health Psychology',
  'Health Services/Allied Health/Health Sciences, General',
  'Health-Related Knowledge and Skills',
  'Health/Medical Preparatory Programs',
  'Heating, Air Conditioning, Ventilation and Refrigeration Maintenance Technology/Technician (HAC, HACR, HVAC, HVACR)',
  'Heavy/Industrial Equipment Maintenance Technologies',
  'High School/Secondary Certificate Programs',
  'High School/Secondary Diploma Programs',
  'Historic Preservation and Conservation',
  'History',
  'Holocaust and Related Studies',
  'Homeland Security, Law Enforcement, Firefighting and Related Protective Services, Other',
  'Homeland Security',
  'Hospitality Administration/Management',
  'Housing and Human Environments',
  'Human Biology',
  'Human Computer Interaction',
  'Human Development, Family Studies, and Related Services',
  'Human Resources Management and Services',
  'Human Services, General',
  'Industrial and Organizational Psychology',
  'Industrial Engineering',
  'Industrial Production Technologies/Technicians',
  'Information Science/Studies',
  'Insurance',
  'Intelligence, Command Control and Information Operations',
  'Intercultural/Multicultural and Diversity Studies',
  'Interior Architecture',
  'International Agriculture',
  'International and Comparative Education',
  'International Business',
  'International Relations and National Security Studies',
  'International/Global Studies',
  'Interpersonal and Social Skills',
  'Iranian/Persian Languages, Literatures, and Linguistics',
  'Journalism',
  'Landscape Architecture',
  'Law',
  'Leatherworking and Upholstery',
  'Legal Professions and Studies, Other',
  'Legal Research and Advanced Professional Studies',
  'Legal Support Services',
  'Leisure and Recreational Activities',
  'Liberal Arts and Sciences, General Studies and Humanities',
  'Library and Archives Assisting',
  'Library Science and Administration',
  'Library Science, Other',
  'Linguistic, Comparative, and Related Language Studies and Services',
  'Literature',
  'Management Information Systems and Services',
  'Management Sciences and Quantitative Methods',
  'Manufacturing Engineering',
  'Marine Sciences',
  'Marine Transportation',
  'Maritime Studies',
  'Marketing',
  'Mason/Masonry',
  'Materials Engineerin',
  'Materials Science',
  'Materials Sciences',
  'Mathematics and Computer Science',
  'Mathematics and Statistics, Other',
  'Mathematics',
  'Mechanic and Repair Technologies/Technicians, Other',
  'Mechanical Engineering Related Technologies/Technicians',
  'Mechanical Engineering',
  'Mechanics and Repairers, General',
  'Mechatronics, Robotics, and Automation Engineering',
  'Medical Clinical Sciences/Graduate Medical Studies',
  'Medical Illustration and Informatics',
  'Medical Residency Programs - General Certificates',
  'Medical Residency Programs - Subspecialty Certificates',
  'Medical Residency Programs',
  'Medicine',
  'Medieval and Renaissance Studies',
  'Mental and Social Health Services and Allied Professions',
  'Metallurgical Engineering',
  'Microbiological Sciences and Immunology',
  'Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics',
  'Military Applied Sciences',
  'Military Economics and Management',
  'Military Science and Operational Studies',
  'Military Science, Leadership and Operational Art, Other',
  'Military Systems and Maintenance Technology',
  'Military Technologies and Applied Sciences, Other',
  'Military Technologies',
  'Mining and Mineral Engineering',
  'Mining and Petroleum Technologies/Technicians',
  'Missions/Missionary Studies and Missiology',
  'Modern Greek Language and Literature',
  'Molecular Medicine',
  'Movement and Mind-Body Therapies and Education',
  'Multi-/Interdisciplinary Studies, General',
  'Multi/Interdisciplinary Studies, Other',
  'Museology/Museum Studies',
  'Music',
  'Nanotechnology',
  'Natural Resources and Conservation, Other',
  'Natural Resources Conservation and Research',
  'Natural Resources Management and Policy',
  'Natural Sciences',
  'Naval Architecture and Marine Engineering',
  'Navy/Marine ROTC, Naval Science and Operations',
  'Neurobiology and Neurosciences',
  'Neuroscience',
  'Non-Professional General Legal Studies (Undergraduate)',
  'Nuclear and Industrial Radiologic Technologies/Technicians',
  'Nuclear Engineering Technologies/Technicians',
  'Nuclear Engineering',
  'Nursing',
  'Nutrition Sciences',
  'Ocean Engineering',
  'Operations Research',
  'Ophthalmic and Optometric Support Services and Allied Professions',
  'Optometry',
  'Osteopathic Medicine/Osteopathy',
  'Outdoor Education',
  'Paper Science and Engineering',
  'Parks, Recreation and Leisure Facilities Management',
  'Parks, Recreation and Leisure Studies',
  'Parks, Recreation, Leisure, and Fitness Studies, Other',
  'Pastoral Counseling and Specialized Ministries',
  'Peace Studies and Conflict Resolution',
  'Personal and Culinary Services, Other',
  'Personal Awareness and Self-Improvement',
  'Personality Psychology',
  'Petroleum Engineering',
  'Pharmacology and Toxicology',
  'Pharmacy, Pharmaceutical Sciences, and Administration',
  'Philosophy and Religious Studies, General',
  'Philosophy and Religious Studies, Other',
  'Philosophy',
  'Physical Science Technologies/Technicians',
  'Physical Sciences, Other',
  'Physical Sciences',
  'Physics',
  'Physiological Psychology/Psychobiology',
  'Physiology, Pathology and Related Sciences',
  'Plant Sciences',
  'Plumbing and Related Water Supply Services',
  'Podiatric Medicine Residency Programs',
  'Podiatric Medicine/Podiatry',
  'Political Science and Government',
  'Polymer/Plastics Engineering',
  'Practical Nursing, Vocational Nursing and Nursing Assistants',
  'Precision Metal Working',
  'Precision Production Trades, General',
  'Precision Production, Other',
  'Precision Systems Maintenance and Repair Technologies',
  'Psychology, General',
  'Psychology, Other',
  'Psychometrics and Quantitative Psychology',
  'Psychopharmacology',
  'Public Administration and Social Service Professions, Other',
  'Public Administration',
  'Public Health',
  'Public Policy Analysis',
  'Public Relations, Advertising, and Applied Communication',
  'Publishing',
  'Quality Control and Safety Technologies/Technicians',
  'Radio, Television, and Digital Communication',
  'Real Estate Development',
  'Real Estate',
  'Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing',
  'Rehabilitation and Therapeutic Professions',
  'Religion/Religious Studies',
  'Religious Education',
  'Religious/Sacred Music',
  'Research and Experimental Psychology',
  'Rhetoric and Composition/Writing Studies',
  'Romance Languages, Literatures, and Linguistics',
  'Rural Sociology',
  'School Psychology',
  'Science Technologies/Technicians, General',
  'Science Technologies/Technicians, Other',
  'Science, Technology and Society',
  'Security Policy and Strategy',
  'Slavic, Baltic and Albanian Languages, Literatures, and Linguistics',
  'Social and Philosophical Foundations of Education',
  'Social Psychology',
  'Social Sciences, General',
  'Social Sciences, Other',
  'Social Work',
  'Sociology and Anthropology',
  'Sociology',
  'Soil Sciences',
  'Somatic Bodywork and Related Therapeutic Services',
  'South Asian Languages, Literatures, and Linguistics',
  'Southeast Asian and Australasian/Pacific Languages, Literatures, and Linguistics',
  'Special Education and Teaching',
  'Specialized Sales, Merchandising and  Marketing Operations',
  'Speech and Rhetorical Studies',
  'Statistics',
  'Student Counseling and Personnel Services',
  'Surveying Engineering',
  'Sustainability Studies',
  'Systems Engineering',
  'Systems Science and Theory',
  'Taxation',
  'Teacher Education and Professional Development, Specific Levels and Methods',
  'Teacher Education and Professional Development, Specific Subject Areas',
  'Teaching Assistants/Aides',
  'Teaching English or French as a Second or Foreign Language',
  'Technical and Business Writing',
  'Technology Education/Industrial Arts Programs',
  'Telecommunications Management',
  'Textile Sciences and Engineering',
  'Theological and Ministerial Studies',
  'Theology and Religious Vocations, Other',
  'Transportation and Materials Moving, Other',
  'Turkic, Uralic-Altaic, Caucasian, and Central Asian Languages, Literatures, and Linguistics',
  'Urban Studies/Affairs',
  'Vehicle Maintenance and Repair Technologies',
  'Veterinary Biomedical and Clinical Sciences',
  'Veterinary Medicine',
  'Veterinary Residency Programs',
  'Visual and Performing Arts, General',
  'Visual and Performing Arts, Other',
  'Wildlife and Wildlands Science and Management',
  'Woodworking',
  'Work and Family Studies',
  'Zoology/Animal Biology'
]

const ATSValues = [
  'JazzHR',
  'Oracle Taleo',
  'Bullhorn',
  'IBM Kenexa BrassRing',
  'Jobvite',
  'SmartRecruiters',
  'iCIMS',
  'Greenhouse',
  'BambooHR',
  'SAP SuccessFactors',
  'Workable',
  'Workday'
]

export const findLabelByValue = (value, options) => {
  const found = options.find((option) => option.value == value)
  if (found) return found.label;
  return null;
}

export default {

  //interview options
  interviewDifficulty: [
    {value: 1, label: 'Very Easy'},
    {value: 2, label: 'Easy'},
    {value: 3, label: 'Average'},
    {value: 4, label: 'Hard'},
    {value: 5, label: 'Very Hard'}
  ],

  decisionTime: [
    {value: 'OneWeek', label: '1 Week'},
    {value: 'TwoWeeks', label: '2 Weeks'},
    {value: 'OneMonth', label: '1 Month'},
    {value: 'TwoMonths', label: '2 Months'},
    {value: 'LongerThanTwoMonths', label: 'Longer'}
  ],

  decisions: [
    {value: 'NoOffer', label: 'No'},
    {value: 'ReceivedOffer', label: 'Yes, and accepted'},
    {value: 'ReceivedOfferButRefused', label: 'Yes, but declined'}
  ],

  interviewSources: [
    {value: 'Online', label: 'Online'},
    {value: 'InPerson', label: '3rd Party'},
    {value: 'Referral', label: 'Insider'}
  ],

  yearInterviewed: Array(differenceInYears(new Date(), startDate) + 1).fill(0).map((_, i) => {
    const value = ''.concat(getYear(new Date()) - i);
    return {value, label: value}
  }),

  //company options
  salaryTypes: [
    {value: 'Salaried', label: 'Salaried'},
    {value: 'Hourly', label: 'Hourly'}
  ],

  //educator options
  degrees: degreeValue.map((value) => ({value, label: value})),

  instructionRange: instructionValues.map((value) => ({value, label: value})),

  scholarshipRange: scholarshipValues.map((value) => ({value, label: value})),

  fundingRange: fundingValues.map((value) => ({value, label: value})),

  branchRange: branchValues.map((value) => ({value, label: value})),

  paygradeRange: paygradeValues.map((value) => ({value, label: value})),

  outofpocketRange: outofpocketValues.map((value) => ({value, label: value})),

  avgMonthlyPay: avgmonthlypayValues.map((value) => ({value: parseFloat(value.replace(/[^0-9]/g, '')) + (value[0] == '>' ? 1 : 0), label: value})),

  benefits: [
    {value: 'Negative', label: 'Negative'},
    {value: 'NoOpinion', label: 'No opinion'},
    {value: 'Positive', label: 'Positive'}
  ],

  complete: [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},
  ],

  next: [
    {value: 'WasHiredBy', label: 'Was hired by'},
    {value: 'ContinuedToWorkAt', label: 'Continued to work at'}
  ],

  //common options
  experienceOptions: [
    {value: 'Negative', label: 'Negative'},
    {value: 'NoOpinion', label: 'Neutral'},
    {value: 'Positive', label: 'Positive'}
  ],

  experienceRatingOptions: [
    {value: 1, label: 'Very Negative'},
    {value: 2, label: 'Negative'},
    {value: 3, label: 'Neutral'},
    {value: 4, label: 'Positive'},
    {value: 5, label: 'Very Positive'}
  ],

  paygradeOptions: [
    {value: 'E-1', label: 'E-1'},
    {value: 'E-2', label: 'E-2'},
    {value: 'E-3', label: 'E-3'},
    {value: 'E-4', label: 'E-4'},
    {value: 'E-5', label: 'E-5'},
    {value: 'E-6', label: 'E-6'},
    {value: 'E-7', label: 'E-7'},
    {value: 'E-8', label: 'E-8'},
    {value: 'E-9', label: 'E-9'},
    {value: 'O-1', label: 'O-1'},
    {value: 'O-2', label: 'O-2'},
    {value: 'O-3', label: 'O-3'},
    {value: 'O-4', label: 'O-4'},
    {value: 'O-5', label: 'O-5'},
    {value: 'O-6', label: 'O-6'},
    {value: 'O-7', label: 'O-7'},
    {value: 'O-8', label: 'O-8'},
    {value: 'O-9', label: 'O-9'},
    {value: 'O-10', label: 'O-10'},
    {value: 'W-1', label: 'W-1'},
    {value: 'W-2', label: 'W-2'},
    {value: 'W-3', label: 'W-3'},
    {value: 'W-4', label: 'W-4'},
    {value: 'W-5', label: 'W-5'}
  ],

  functions: functionsValues.map(value => ({value, label: value})),
  industries: industriesValues.map(value => ({value, label: value})),
  countries: countryValues.map(value => ({value, label: value})),

  states: [
    {label: 'AL', value: 'AL', name: 'ALABAMA'},
    {label: 'AK', value: 'AK', name: 'ALASKA'},
    {label: 'AZ', value: 'AZ', name: 'ARIZONA'},
    {label: 'AR', value: 'AR', name: 'ARKANSAS'},
    {label: 'CA', value: 'CA', name: 'CALIFORNIA'},
    {label: 'CO', value: 'CO', name: 'COLORADO'},
    {label: 'CT', value: 'CT', name: 'CONNECTICUT'},
    {label: 'DC', value: 'DC', name: 'DISTRICT OF COLUMBIA'},
    {label: 'DE', value: 'DE', name: 'DELAWARE'},
    {label: 'FL', value: 'FL', name: 'FLORIDA'},
    {label: 'GA', value: 'GA', name: 'GEORGIA'},
    {label: 'HI', value: 'HI', name: 'HAWAII'},
    {label: 'ID', value: 'ID', name: 'IDAHO'},
    {label: 'IL', value: 'IL', name: 'ILLINOIS'},
    {label: 'IN', value: 'IN', name: 'INDIANA'},
    {label: 'IA', value: 'IA', name: 'IOWA'},
    {label: 'KS', value: 'KS', name: 'KANSAS'},
    {label: 'KY', value: 'KY', name: 'KENTUCKY'},
    {label: 'LA', value: 'LA', name: 'LOUISIANA'},
    {label: 'ME', value: 'ME', name: 'MAINE'},
    {label: 'MD', value: 'MD', name: 'MARYLAND'},
    {label: 'MA', value: 'MA', name: 'MASSACHUSETTS'},
    {label: 'MI', value: 'MI', name: 'MICHIGAN'},
    {label: 'MN', value: 'MN', name: 'MINNESOTA'},
    {label: 'MS', value: 'MS', name: 'MISSISSIPPI'},
    {label: 'MO', value: 'MO', name: 'MISSOURI'},
    {label: 'MT', value: 'MT', name: 'MONTANA'},
    {label: 'NE', value: 'NE', name: 'NEBRASKA'},
    {label: 'NV', value: 'NV', name: 'NEVADA'},
    {label: 'NH', value: 'NH', name: 'NEW HAMPSHIRE'},
    {label: 'NJ', value: 'NJ', name: 'NEW JERSEY'},
    {label: 'NM', value: 'NM', name: 'NEW MEXICO'},
    {label: 'NY', value: 'NY', name: 'NEW YORK'},
    {label: 'NC', value: 'NC', name: 'NORTH CAROLINA'},
    {label: 'ND', value: 'ND', name: 'NORTH DAKOTA'},
    {label: 'OH', value: 'OH', name: 'OHIO'},
    {label: 'OK', value: 'OK', name: 'OKLAHOMA'},
    {label: 'OR', value: 'OR', name: 'OREGON'},
    {label: 'PA', value: 'PA', name: 'PENNSYLVANIA'},
    {label: 'RI', value: 'RI', name: 'RHODE ISLAND'},
    {label: 'SC', value: 'SC', name: 'SOUTH CAROLINA'},
    {label: 'SD', value: 'SD', name: 'SOUTH DAKOTA'},
    {label: 'TN', value: 'TN', name: 'TENNESSEE'},
    {label: 'TX', value: 'TX', name: 'TEXAS'},
    {label: 'UT', value: 'UT', name: 'UTAH'},
    {label: 'VT', value: 'VT', name: 'VERMONT'},
    {label: 'VA', value: 'VA', name: 'VIRGINIA'},
    {label: 'WA', value: 'WA', name: 'WASHINGTON'},
    {label: 'WV', value: 'WV', name: 'WEST VIRGINIA'},
    {label: 'WI', value: 'WI', name: 'WISCONSIN'},
    {label: 'WY', value: 'WY', name: 'WYOMING'}
  ],

  organizationClassOptions: [
    {value: 'Educator', label: 'Educator'},
    {value: 'Employer', label: 'Employer'}
  ],

  companySortOptions: [
    {value: 'employeeReviewCount', label: 'Review count'},
    {value: 'name', label: 'Name'},
    {value: 'rating', label: 'Rating'}
  ],

  companyTypes: [
    {value: 'Agency — Municipal', label: 'Agency — Municipal'},
    {value: 'Agency — State', label: 'Agency — State'},
    {value: 'Agency — Federal', label: 'Agency — Federal'},
    {value: 'Other', label: 'Other'},
    {value: 'Private — For Profit', label: 'Private — For Profit'},
    {value: 'Private — Not for Profit', label: 'Private — Not for Profit'},
    {value: 'Public — For Profit', label: 'Public — For Profit'},
    {value: 'Public — Not for Profit', label: 'Public — Not for Profit'},
    {value: 'Small Business', label: 'Small Business'},
    {value: 'Startup', label: 'Startup'}
  ],

  orgSizeOptions: [
    {value: '1', label: '1+'},
    {value: '100', label: '100+'},
    {value: '1000', label: '1,000+'},
    {value: '10000', label: '10,000+'},
    {value: '100000', label: '100,000+'},
    {value: '1000000', label: '1,000,000+'}
  ],

  affinityGroupSizeOptions: [
    {value: '1', label: '1+'},
    {value: '10', label: '10+'},
    {value: '50', label: '50+'},
    {value: '100', label: '100+'},
    {value: '500', label: '500+'},
    {value: '1000', label: '1,000+'},
    {value: '5000', label: '5,000+'},
    {value: '10000', label: '10,000+'},
    {value: '50000', label: '50,000+'},
    {value: '100000', label: '100,000+'}
  ],

  educatorSortOptions: [
    {value: 'programReviewCount', label: 'Review count'},
    {value: 'name', label: 'Name'},
    {value: 'rating', label: 'Rating'},
    {value: 'studentsGiBill', label: 'GI Bill students'},
    {value: 'studentsYellowRibbon', label: 'Yellow Ribbon students'},
    {value: 'studentsUndergraduates', label: 'Undergraduate students'},
    {value: 'graduationPercent', label: 'Graduation %'},
    {value: 'retentionPercent', label: 'Retention %'},
    {value: 'repaymentPercent', label: 'Repayment %'},
    {value: 'tuitionInState', label: 'In-state tuition/YR'},
    {value: 'tuitionOutOfState', label: 'Out-of-state tuition/YR'},
    {value: 'maxHousingAllowance', label: 'Max Housing Allowance/MO'},
    {value: 'medianEarnings', label: '10YR median earnings'}
  ],

  selectivityCategories: [
    {value: 'Elite', label: 'Elite'},
    {value: 'High', label: 'High'},
    {value: 'Selective', label: 'Selective'},
    {value: 'Ability to Pay', label: 'Ability to Pay'},
    {value: 'Other', label: 'Other'}
  ],

  accreditationTypes: [
    {value: 'Hybrid', label: 'Hybrid'},
    {value: 'Regional', label: 'Regional'},
    {value: 'National', label: 'National'},
    {value: 'Other', label: 'Other'}
  ],

  reviewClassOptions: [
    {value: 'EmployerJobReview', label: 'Employee'},
    {value: 'EmployerInterviewReview', label: 'Job Applicant'},
    {value: 'EducatorProgramReview', label: 'Student'},
    {value: 'EducatorInterviewReview', label: 'Student Applicant'}
  ],

  reviewSortOptions: [
    {value: 'reviewCreatedAt', label: 'Date'},
    {value: 'reviewViewsCount', label: 'Views'},
    {value: 'reviewUpvotesCount', label: 'Upvotes'},
    {value: 'reviewRating', label: 'Rating'}
  ],

  partnerTypes: [
    {value: 'Apprenticeships', label: 'Apprenticeships'},
    {value: 'Arts & Music', label: 'Arts & Music'},
    {value: 'Benefits Navigation', label: 'Benefits Navigation'},
    {value: 'Children', label: 'Children'},
    {value: 'Coaching', label: 'Coaching'},
    {value: 'Community Engagement', label: 'Community Engagement'},
    {value: 'Crosswalks & Translators', label: 'Crosswalks & Translators'},
    {value: 'DOD', label: 'DOD'},
    {value: 'Disaster Response', label: 'Disaster Response'},
    {value: 'Education', label: 'Education'},
    {value: 'Employment', label: 'Employment'},
    {value: 'Entrepreneurship', label: 'Entrepreneurship'},
    {value: 'Federal', label: 'Federal'},
    {value: 'Financial Fitness', label: 'Financial Fitness'},
    {value: 'Health & Wellness', label: 'Health & Wellness'},
    {value: 'Job Training', label: 'Job Training'},
    {value: 'Mentorship', label: 'Mentorship'},
    {value: 'Military Caregivers', label: 'Military Caregivers'},
    {value: 'Multiple', label: 'Multiple'},
    {value: 'Municipal', label: 'Municipal'},
    {value: 'Networking', label: 'Networking'},
    {value: 'News & Media', label: 'News & Media'},
    {value: 'Other', label: 'Other'},
    {value: 'Public Leadership', label: 'Public Leadership'},
    {value: 'Recreation', label: 'Recreation'},
    {value: 'Rights & Legislation', label: 'Rights & Legislation'},
    {value: 'Spouses', label: 'Spouses'},
    {value: 'State & Local', label: 'State & Local'},
    {value: 'Industry Associations', label: 'Industry Associations'},
    {value: 'VA', label: 'VA'}
  ],

  partnerSortOptions: [
    {value: 'name', label: 'Name'},
    {value: 'founded', label: 'Founded'}
  ],

  geographicScopeOptions: [
    {value: 'National', label: 'National'},
    {value: 'Regional', label: 'Regional'},
    {value: 'Local', label: 'Local'}
  ],

  experiencePeriods: [
    {label: '1 month', value: 1},
    {label: '2 months', value: 2},
    {label: '3 months', value: 3},
    {label: '6 months', value: 6},
    {label: '1 year', value: 12},
    {label: '2 years', value: 24},
    {label: '3 years', value: 36},
    {label: '4 years', value: 48},
    {label: '5 years', value: 60}
  ],

  reviewSignTags: [
    {value: 'Service branch', label: 'Service branch'},
    {value: 'EAS paygrade', label: 'EAS paygrade'},
    {value: 'Military occupation', label: 'Military occupation'},
    {value: 'User category', label: 'Affiliation'}
  ],

  // jobs & onboarding

  positions: positionValues.map(value => ({value, label: value})),
  supportedPrograms: supportedProgramValues.map(value => ({value, label: value})),
  formalEducationOptions: formalEducationValues.map(value => ({value, label: value})),
  identificationOptions: identificationValues.map(value => ({value, label: value})),
  clearanceOptions: clearanceValues.map(value => ({value, label: value})),
  fieldOfStudyOptions: fieldOfStudyValues.map(value => ({value, label: value})),
  companyCultureOptions: companyCultureValues.map(value => ({value, label: value})),
  benefitOptions: benefitValues.map(value => ({value, label: value})),
  ATSOptions: ATSValues.map(value => ({value, label: value})),

  isRequiredOptions: [
    {value: false, label: 'Nice-to-have'},
    {value: true, label: 'Required'}
  ],

  switchOptions: [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
  ]
}
