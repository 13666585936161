import React                  from 'react';
import { reduxForm,
         Field }              from 'redux-form';
import { compose,
         setDisplayName }     from 'recompose';
import cx                     from 'classnames';
import { TextField }          from 'theme/Form';
import { withPasswordToggle } from 'theme/utils/recompose';


const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.confirmPassword ) {
    errors.confirmPassword = 'Required' ;
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Password mismatched';
  }

  return errors;
};

export default compose(
  setDisplayName('PasswordForm'),
  withPasswordToggle(),
)(reduxForm({
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, pristine, submitting, mailToken, togglePassword, checkPasswordVisible}) => {
    return (
      <div className="ResetPassword__form">
        <form
          onSubmit={handleSubmit}
          className={cx({mailToken})}
        >
          <Field
            name="password"
            label={mailToken ? 'New password' : 'Password'}
            type={checkPasswordVisible('password') ? 'text' : 'password'}
            placeholder="Type new password"
            component={TextField}
            isPasswordVisible={checkPasswordVisible('password')}
            togglePassword={togglePassword}
            required
          />
          <Field
            name="confirmPassword"
            label={mailToken ? 'Confirm new password' : 'Confirm password'}
            type={checkPasswordVisible('confirmPassword') ? 'text' : 'password'}
            placeholder="Confirm password"
            component={TextField}
            isPasswordVisible={checkPasswordVisible('confirmPassword')}
            togglePassword={togglePassword}
            required
          />
          <div className={cx("ResetPassword__send", {mailToken})}>
            <button
              type="submit"
              className={cx("btn-primary save", {mailToken})}
              disabled={pristine || submitting}
            >
              {mailToken ? 'Update Password' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    )
  }
))
