import React          from 'react';
import Footer         from 'theme/Footer';
import Header         from 'theme/Header';
import MessageSection from 'theme/MessageSection';

import './NotFound.sass';


export default function NotFound() {
  return(
    <nav className="NotFound">
      <Header />
      <div className="NotFound__container">
        <MessageSection
          title="404"
          text="Oops! The page you're looking for doesn't exist."
        />
      </div>
      <Footer />
    </nav>
  )
}
