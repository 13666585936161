import React                  from 'react';
import { reduxForm,
         Field }              from 'redux-form';
import { compose,
         setDisplayName }     from 'recompose';
import { TextField }          from 'theme/Form';
import { withPasswordToggle } from 'theme/utils/recompose';

import './ChangePasswordForm.sass';

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.confirmPassword ) {
    errors.confirmPassword = 'Required' ;
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Password mismatched';
  }
  return errors;
};

export default compose(
  setDisplayName('ChangePasswordForm'),
  withPasswordToggle()
)(reduxForm({
  validate
})(
  ({handleSubmit, pristine, submitting, invalid, checkPasswordVisible, togglePassword}) => {
    return (
      <div className="ChangePasswordForm__form">
        <h3>Change your password</h3>
        <form onSubmit={handleSubmit}>
          <Field
            name="password"
            label="New password"
            type={checkPasswordVisible('password') ? 'text' : 'password'}
            isPasswordVisible={checkPasswordVisible('password')}
            component={TextField}
            togglePassword={togglePassword}
          />
          <Field
            name="confirmPassword"
            label="Confirm new password"
            type={checkPasswordVisible('confirmPassword') ? 'text' : 'password'}
            isPasswordVisible={checkPasswordVisible('confirmPassword')}
            component={TextField}
            togglePassword={togglePassword}
          />
          <button
            type="submit"
            className="btn-secondary"
            disabled={pristine || submitting || invalid}
          >
            Save
          </button>
        </form>
      </div>
    )
  }
))
