import React          from 'react';
import { formatDate } from 'theme/utils/helpers';

import './WorkExperienceRow.sass';

export default function WorkExperienceRow({work: {position, start, end, company, industry}}) {
  return (
    <div className="WorkExperienceRow job-content-row">
      <h4>{position}</h4>
      <span className="text">{start && formatDate(start)} - {end && formatDate(end)}</span>
      <div className="data">
        <span className="small-label">Company </span>
        <span className="text">{company}</span>
      </div>
      <div className="data">
        <span className="small-label">Industry </span>
        <span className="text">{industry}</span>
      </div>
    </div>
  )
}
