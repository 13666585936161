import React, { useRef }        from 'react';
import { connect }              from 'react-redux';
import { Link }                 from 'react-router-dom';
import { debounce }             from 'underscore';
import cx                       from 'classnames';
import { scrollMobileNavRight } from 'store/actions';

import './HeadingNav.sass';


function HeadingNav({sections, link, activeSection, scrollMobileNavRight}) {
  const innerRef = useRef();

  const onScroll = debounce(() => {
    if (innerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = innerRef.current;

      if (scrollLeft + clientWidth === scrollWidth)
        scrollMobileNavRight(true);
      else scrollMobileNavRight(false);
    }
  }, 200);

  return (
    <div className="HeadingNav" ref={innerRef} onScroll={() => onScroll()}>
      {sections.map(({key, name}) => (
        <Link
          key={key}
          to={link({key, name})}
          className={cx(
            "HeadingNav__item",
            {active: key == activeSection}
          )}
        >
          {name}
        </Link>
      ))}
    </div>
  )
}

export default connect(
  null,
  dispatch => ({ scrollMobileNavRight: (value) => dispatch(scrollMobileNavRight(value)) })
)(HeadingNav);
