// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/security.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".checkbox .check{width:20px;height:20px;display:inline-flex;justify-content:center;align-items:center;border-radius:4px;border:1px solid #BAC2D2;cursor:pointer;position:relative}.checkbox .check:before{display:none}.checkbox .check.checked{background:#44D19F;border:1px solid #44D19F}.checkbox .check.checked .fa-check{color:#fff;font-size:.8rem}@keyframes tick{0%{transform:scale(0)}90%{transform:scale(1.7)}100%{transform:scale(1)}}.Security{background:#fff}.Security__jumbotron{display:flex;justify-content:center;align-items:center;height:46rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%;background-repeat:no-repeat;color:#fff}.Security section{padding:9.25rem 0}.Security section:last-of-type{padding:9.25rem 0 14rem}.Security h2{text-align:center}.Security article{display:flex}.Security article>*{flex:1 1 50%}.Security article ~ article{margin-top:12.5rem}.Security .left{margin-right:5rem}.Security .right{margin-left:5rem}.Security .align-right{text-align:right}.Security__dataSecurity article{margin-top:8rem}.Security__dataSecurityHeading{width:55%;margin:0 auto;text-align:center}.Security__dataSecurity h2{margin:0 0 1.5rem}.Security__dataSecurity .right:last-child{margin-left:7.5rem}.Security__dataSecurity li{margin-bottom:1rem}.Security .grid{display:grid;grid-template-columns:1fr 1fr;grid-template-rows:repeat(6, 1fr)}.Security li{list-style-type:none}.Security__phishing{background:#FBFBFB}.Security__phishing h2{margin:0}.Security__phishing article{flex:1 1 calc(100% / 3);margin-top:5.5rem}.Security__phishing img{height:32.5rem;align-self:center;padding-top:3rem}.Security__phishing .left>div ~ div{margin-top:4.75rem}.Security__title{color:#FF4E00;text-transform:uppercase}.Security__subTitle{font-weight:500;margin:0 0 .25rem}.Security__row{margin-bottom:3rem}\n", ""]);
// Exports
module.exports = exports;
