export const filters = [
  {type: 'availableTypes', value: 'Full-time'},
  {type: 'availableTypes', value: 'Part-time'},
  {type: 'availableTypes', value: 'Temporary'},
  {type: 'availableTypes', value: 'Internship'},
  {type: 'availableTypes', value: 'Apprenticeship'},
  {type: 'availableTypes', value: 'Pre-Apprenticeship'},
  {type: 'supportedPrograms', value: 'DOD Skillbridge'},
  {type: 'supportedPrograms', value: 'DOL Registered Apprenticeship Program'},
  {type: 'supportedPrograms', value: 'DOL Industry-Recognized Apprenticeship Program'},
  {type: 'createdAt', value: 'Date'},
  {type: 'benefits', value: 'Healthcare insurance'},
  {type: 'benefits', value: 'Dental insurance'},
  {type: 'benefits', value: 'Disability insurance'},
  {type: 'benefits', value: 'Life insurance'},
  {type: 'benefits', value: 'Vacation / Paid time off'},
  {type: 'benefits', value: 'Paid sick leave'},
  {type: 'benefits', value: 'Retirement / Pension plan'},
  {type: 'benefits', value: 'Flexible schedule'},
  {type: 'benefits', value: 'Relocation assistance'},
  {type: 'benefits', value: 'Gym membership'},
  {type: 'industry', value: 'Accommodation and Food Services'},
  {type: 'industry', value: 'Administrative and Support and Waste Management and Remediation Services'},
  {type: 'industry', value: 'Agriculture, Forestry, Fishing and Hunting'},
  {type: 'industry', value: 'Arts, Entertainment, and Recreation'},
  {type: 'industry', value: 'Construction'},
  {type: 'industry', value: 'Educational Services'},
  {type: 'industry', value: 'Finance and Insurance'},
  {type: 'industry', value: 'Health Care and Social Assistance'},
  {type: 'industry', value: 'Information'},
  {type: 'industry', value: 'Management of Companies and Enterprises'},
  {type: 'industry', value: 'Manufacturing'},
  {type: 'industry', value: 'Mining'},
  {type: 'industry', value: 'Other Services (except Public Administration)'},
  {type: 'industry', value: 'Professional, Scientific, and Technical Services'},
  {type: 'industry', value: 'Public Administration'},
  {type: 'industry', value: 'Real Estate Rental and Leasing'},
  {type: 'industry', value: 'Retail Trade'},
  {type: 'industry', value: 'Transportation and Warehousing'},
  {type: 'industry', value: 'Utilities'},
  {type: 'industry', value: 'Wholesale Trade'},
  {type: 'companyCultures', value: 'Pioneering'},
  {type: 'companyCultures', value: 'Guarding'},
  {type: 'companyCultures', value: 'Driving'},
  {type: 'companyCultures', value: 'Integrating'}
];
