import React, { useEffect }   from 'react';
import { withRouter }         from 'react-router';
import { connect }            from 'react-redux';
import { Link }               from 'react-router-dom';
import MessageSection         from 'theme/MessageSection';
import { updateUserProfile }  from 'user/actions';

import './UserVerification.sass';


function UserVerification({verify, user, history}) {
  const adminAccepted = location.search.includes('AdminAccepted');
  const userId = location.search.split('userId=').pop();

  useEffect(() => {
    if (!user)
      history.push(`/auth/signin/${location.search}&ProofFile`)
    else verify(userId, {verified: adminAccepted});
  }, []);

  return (
    <div className="UserVerification page">
      <MessageSection
        title="Thank you"
        text={adminAccepted ? "The user has been verified." :  "The user has been rejected."}
      >
        <Link
          className="btn-primary"
          to="/"
        >
          Return
        </Link>
      </MessageSection>
    </div>
  )
}

export default withRouter(connect(
  ({auth}) => ({user: auth.user}),
  dispatch => ({verify: (id, values) => dispatch(updateUserProfile(id, values, false))})
)(UserVerification));
