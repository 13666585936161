import React       from 'react';
import { Link }    from 'react-router-dom';
import { images }  from 'theme/img/images';

import './Return.sass';


export default function Return({text, link, goTo, brightArrowIcon}) {
  const content = (
    <>
      <img src={brightArrowIcon ? images.arrowLeft : images.arrowLeftDark} alt="Return" />
      <span>{text}</span>
    </>
  )

  return (
    <div className="Return">
      {link
        ? <Link to={link}>{content}</Link>
        : <div className="Return__wrapper" onClick={goTo}>{content}</div>
      }
    </div>
  )
}
