import React          from 'react';
import Loader         from 'theme/Loader';
import { constants }  from 'theme/utils/constants';
import Comment        from 'comments/Comment';
import CommentBox     from 'comments/CommentBox';

import './CommentsSection.sass';


export default function CommentsSection({comments, user, reply, rootTopicType, createCommentReportingUrl, replied, setReplied, reactToComment, commentId, loading, count, showMore}) {
  return (
    <div className="CommentsSection">
      {user &&
        <CommentBox
          text="Post your comment"
          reply={reply}
          hasReplied={replied}
          setReplied={setReplied}
          commentId={commentId}
        />
      }
      <div className="CommentsSection__list">
        {comments?.map(c => (
          <Comment
            key={c.id}
            anchorId={c.id}
            post={c}
            authUser={user}
            reactToComment={reactToComment}
            currentCommentsPageSize={comments.length}
            createCommentReportingUrl={createCommentReportingUrl}
            rootTopicType={rootTopicType}
            mediumLvlComment
          />
        ))}
      </div>
      <div className="CommentsSection__load">
        <Loader loading={loading} />
        {(comments.length >= constants.COMMENT.PAGE_SIZE) && (comments.length < count) &&
          <div className="CommentsSection__loadMore" onClick={() => showMore()}>
            <span>Load more comments</span>
          </div>
        }
      </div>
    </div>
  )
}

CommentsSection.displayName = 'CommentsSection';
