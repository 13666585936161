import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { Link }               from 'react-router-dom';
import { Helmet }             from 'react-helmet';
import { ReactSVG }           from 'react-svg';
import { formValueSelector }  from 'redux-form';
import { compose,
         setDisplayName,
         lifecycle,
         withState,
         withProps,
         withHandlers }       from 'recompose';
import { API_CONF }           from 'api';
import Header                 from 'theme/Header';
import Tabs                   from 'theme/Tabs';
import Steps                  from 'theme/Steps';
import SubscriptionInfo       from 'theme/SubscriptionInfo';
import { images }             from 'theme/img/images';
import { withScroll }         from 'theme/utils/recompose';
import { getPartners }        from 'partners/actions';
import { getOrganization,
         updateOrganization } from 'organizations/actions';
import { generateCheckout }   from 'organizationAdmin/actions';
import { onboardUser }        from 'users/actions';

import accountTypes           from '../accountTypes';
import BasicProfileForm       from '../Forms/BasicProfileForm';
import PremiumProfileForm     from '../Forms/PremiumProfileForm';

import './CompanyOnboarding.sass';


const basicProfileFormName = 'BasicProfileForm';
const premiumEmployerFormName = 'PremiumEmployerForm';

const PRICE = 0;

window.Chargebee.init({
  site: API_CONF.CHARGEBEE_SITE,
  publishableKey: API_CONF.CHARGEBEE_KEY
})

const FORMS = [
  {name: 'Basic Information', title: 'Create a basic employer profile'},
  {name: 'Choose Plan', title: 'Showcase your brand as a veteran employer - your values, culture, and commitment.'},
  {name: 'Premium Content', title: 'Create premium employer profile'}
];

const CompanyOnboarding = compose(
  setDisplayName('CompanyOnboarding'),
  withScroll(),
  withState('submitted', 'setSubmitted', false),
  withState('selectedAccountType', 'setAccountType', 'Basic'),
  lifecycle({
    componentDidMount() {
      const { getOrganization, getPartners, user, enablePremium } = this.props;

      enablePremium({arePremiumFeaturesEnabled: true}, user.organizationId);
      getOrganization(user.organizationId);
      getPartners();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.match.params.page != this.props.match.params.page) {
        window.scrollTo(0, 0);
      }
    }
  }),
  withProps(({match}) => ({
    currentPage: Number(match.params.page)
  })),
  withHandlers({
    submit: ({submit, currentPage, history, user}) => values => {
      return submit(values, user.organizationId)
      .then(() => {
        history.push(`/employer-onboarding/${currentPage + 1}`)
      })
    },
    finishOnboarding: ({history, submit, user, selectedAccountType, currentPage, onboardUser}) => values => {
      onboardUser(user.id);

      if (currentPage === 2) {
        if (selectedAccountType == 'Basic')
          history.push('/org-admin/feed');
      } else if (currentPage === 3) {
        return submit(values, user.organizationId, true)
        .then(() => {
          history.push('/org-admin/feed');
        });
      }
    },
    generateCheckout: ({generateCheckout}) => () => {
      return generateCheckout()
      .then(res => window.open(res.hosted_page.url, '_self'));
    }
  })
)(({currentPage,
    organization,
    loading,
    partners,
    selectedPartners,
    selectedAccountType,
    setAccountType,
    submit,
    finishOnboarding,
    generateCheckout
  }) => {
  const initialValues = {
    ...organization,
    partners: organization?.partners?.map(p => p.id)
  };

  const partnerOptions = partners?.map(p => ({label: p.name, value: p.id}));
  const filterOptions = {
    partners: option => selectedPartners.findIndex(id => id === option.value) === -1
  };

  const stepCount = selectedAccountType === 'Premium' || currentPage === 3 ? 3 : 2;

  return (
    <div className="CompanyOnboarding page">
      <Helmet>
        <title>Vetit – Employer Onboarding</title>
      </Helmet>
      <Header isOnboarding />
      <div className="container">
        <div className="card">
          <Steps
            stepCount={stepCount}
            page={currentPage}
          />
          <span className="CompanyOnboarding__step">{`Step ${currentPage}/${stepCount}`}</span>
          <h1>{FORMS[currentPage - 1].title}</h1>
          <Tabs
            loading={loading}
            title={FORMS[currentPage - 1].name.toUpperCase()}
            hideNav={currentPage === 2}
          >
            {currentPage == 1 &&
              <BasicProfileForm
                onSubmit={submit}
                form={basicProfileFormName}
                initialValues={initialValues}
                partnerOptions={partnerOptions}
                filterOptions={filterOptions}
              />
            }
            {currentPage == 2 &&
              <div className="CompanyOnboarding__selectAccountType">
                <h5>Select account type</h5>
                {accountTypes.map((type, i) => (
                  <SubscriptionInfo
                    key={i}
                    type={type}
                    selectedType={selectedAccountType}
                    select={setAccountType}
                  />
                ))}
                <div className="CompanyOnboarding__selectAccountTypeTotal">
                  <span>Total</span>
                  {selectedAccountType === 'Premium' ? `$${PRICE}` : '$0'}
                </div>
                <div className="CompanyOnboarding__selectAccountTypeActions actions">
                  <Link
                    className="CompanyOnboarding__goBack btn-primary-outlined"
                    to='/employer-onboarding/1'
                  >
                    <ReactSVG
                      src={images.arrowLeft}
                      aria-label="Go back"
                    />
                    Back
                  </Link>
                  <button
                    className="btn-primary"
                    onClick={() => selectedAccountType === 'Basic' || currentPage === 3
                      ? finishOnboarding()
                      : generateCheckout()
                    }
                  >
                    {selectedAccountType === 'Basic' ? 'Save & Continue' : 'Checkout'}
                  </button>
                </div>
              </div>
            }
            {currentPage == 3 &&
              <PremiumProfileForm
                onSubmit={finishOnboarding}
                form={premiumEmployerFormName}
                initialValues={initialValues}
              />
            }
          </Tabs>
        </div>
      </div>
    </div>
  )
})

const mapStateToProps = (state) => {
  const { auth, loading, data } = state;
  const id = auth.user.organizationId;
  const selector = formValueSelector(basicProfileFormName);

  return {
    user: auth.user,
    loading: loading[`organization:employer:${id}`],
    organization: (data && data[`organization:employer:${id}`]) || {},
    partners: data.partners?.data || [],
    selectedPartners: selector(state, 'partners') || []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrganization: (organizationId) => new Promise((resolve, reject) => dispatch(getOrganization(organizationId, 'employer', {resolve, reject}))),
    submit: (values, id, isOnboarding) => dispatch(updateOrganization({organizationType: 'employer', id, values, isOnboarding, form: basicProfileFormName})),
    enablePremium: (values, id) => dispatch(updateOrganization({organizationType: 'employer', id, values})),
    getPartners: () => dispatch(getPartners({page: 0, pageSize: 200})),
    generateCheckout: () => dispatch(generateCheckout('premium')),
    onboardUser: id => dispatch(onboardUser(id))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyOnboarding))
