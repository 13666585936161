import React                        from 'react';
import { connect }                  from 'react-redux';
import { withRouter }               from 'react-router';
import { lifecycle,
         withProps,
         withState,
         compose,
         mapProps,
         setDisplayName,
         withHandlers }             from 'recompose';
import PT                           from 'prop-types';
import { pick, debounce }           from 'underscore';
import Footer                       from 'theme/Footer';
import Header                       from 'theme/Header';
import Loader                       from 'theme/Loader';
import Modal                        from 'theme/Modal';
import Pagination                   from 'theme/Pagination';
import ManagerStats                 from 'theme/ManagerStats';
import InterviewsStats              from 'theme/InterviewsStats';
import Profile                      from 'theme/Profile';
import QuickGlance                  from 'theme/QuickGlance';
import StarSection                  from 'theme/StarSection';
import ReviewsSection               from 'theme/ReviewsSection';
import Videos                       from 'theme/Videos';
import SingleReview                 from 'theme/SingleReview';
import DetailsSection               from 'theme/DetailsSection';
import PartnersBox                  from 'theme/PartnersBox';
import CustomCarousel               from 'theme/CustomCarousel';
import { withModal,
         withScroll,
         withSignature }            from 'theme/utils/recompose';
import { constants }                from 'theme/utils/constants';
import { images }                   from 'theme/img/images';
import { env }                      from 'theme/content';
import AskQuestion                  from 'comments/AskQuestion';
import Feed                         from 'feed/Feed';
import JobContainer                 from 'jobs/JobContainer';
import { labels }                   from 'companies/labels';
import { getCompany,
         getCompanyJobs,
         viewJobReview,
         getInterviews,
         viewInterview,
         voteInterview,
         voteJobReview,
         getReview,
         follow,
         unfollow,
         followReview,
         unfollowReview,
         verifyEmployer,
         getJobReviews }            from 'companies/actions';
import { addComment,
         getComments,
         voteComment,
         follow as followPost,
         unfollow as unfollowPost } from 'comments/actions';
import { getJob,
         viewJob,
         saveJob,
         unsaveJob }                from 'jobs/actions';
import { actionSucceeded }          from 'store/actions';

import CompanyJobs                  from '../CompanyJobs';

import './Company.sass';


const headingWithCount = (name, count) => (
  <span>
    {name}
    <span className="count"> ({count})</span>
  </span>
)

const headingSections = (reviewsCount, interviewsCount, jobsCount, loggedIn) => {
  const all = [
    {key: 'overview', name: <span><span className="hidden-xs"></span>Overview</span>},
    {key: 'job-offers', name: headingWithCount('Jobs', jobsCount)},
    {key: 'discussion', name: <span><span className="hidden-xs"></span>Discussion</span>},
    {key: 'reviews', name: headingWithCount('Employees', reviewsCount)},
    {key: 'interviews', name: headingWithCount('Applicants', interviewsCount)}
  ];

  const notAll = [
    {key: 'overview', name: <span><span className="hidden-xs"></span>Overview</span>},
    {key: 'reviews', name: headingWithCount('Employees', reviewsCount)},
    {key: 'interviews', name: headingWithCount('Applicants', interviewsCount)}
  ];

  return loggedIn ? all : notAll;
};

const renderCarousel = (employer, loading) => {
  const Star = () => (
    <StarSection
      orgType={employer.class}
      industry={employer.industry}
      loading={loading}
    />
  );

  const ConnectedPartners = () => (
    <div className="card">
      <Loader loading={loading} />
      <PartnersBox partners={employer?.partners} />
    </div>
  );

  if (employer?.arePremiumFeaturesEnabled) {
    if (employer?.partners?.length > 0) {
      return (
        <CustomCarousel>
          <Star />
          <ConnectedPartners />
        </CustomCarousel>
      )
    } else {
      return (
        <CustomCarousel>
          <Star />
        </CustomCarousel>
      )
    }
  } else {
    if (employer?.partners?.length > 0) {
      return (
        <CustomCarousel>
          <ConnectedPartners />
        </CustomCarousel>
      )
    } else (<></>)
  }
};

const MODAL_NAMES = {
  SHARE: 'SHARE',
  ASK_QUESTION: 'ASK_QUESTION'
};

const Company = compose(
  setDisplayName('Company'),
  withModal(),
  withScroll(),
  withSignature(),
  withState('page', 'setPage', 0),
  withState('jobsPage', 'setJobsPage', 0),
  withProps(({match, data: {employer}}) => ({
    activeSection: match.params.section,
    jobId: match.params.jobId,
    organizationId: match.params.id,
    reviewId: match.params.reviewId,
    premium: employer.premiumFeatures || {},
    adminAccepted: location.search.includes('AdminAccepted'),
    adminRejected: location.search.includes('AdminRejected'),
    userId: location.search.includes('organizationId')
      ? location.search.match('userId=(.*)&organizationId') && location.search.match('userId=(.*)&organizationId')[1]
      : location.search.split('userId=').pop()
  })),
  mapProps(props => ({
    ...props,
    voteInterview: debounce(props.voteInterview, 1000, true),
    voteJobReview: debounce(props.voteJobReview, 1000, true)
  })),
  withProps(() => ({
    refEl: React.createRef(),
    bodyRef: React.createRef()
  })),
  withHandlers({
    scrollToRef: ({refEl}) => () => window.scrollTo(0, refEl.current.offsetTop),
    scrollToReviewsListTop: ({bodyRef}) => () => window.scrollTo(0, bodyRef.current.offsetTop),
    verifyEmployer: ({verifyEmployer, userId, adminRejected, displaySuccessMessage}) => () => {
      const message = adminRejected ? 'The user\'s request has been rejected.' : 'The user\'s request has been accepted.';

      return verifyEmployer(userId, adminRejected ? true : false)
      .then(() => displaySuccessMessage(message));
    }
  }),
  lifecycle({
    componentDidMount() {
      const {getCompany, getCompanyJob, getCompanyJobs, getJobReviews, getInterviews, jobId, user, organizationId, history, adminAccepted, adminRejected, verifyEmployer, getReview, reviewId} = this.props;

      getCompany();
      getJobReviews();
      getInterviews();
      if (user)
        getCompanyJobs({organizationId})

      if (jobId)
        getCompanyJob({jobId})

      if (reviewId)
        getReview();

      if (adminRejected || adminAccepted) {
        if (!user)
          history.push(`/auth/signin/${location.search}&organizationId=${organizationId}`)

        if (user)
          verifyEmployer();
      }
    },
    componentDidUpdate(prevProps) {
      const { getCompanyJob, getJobReviews, getInterviews, activeSection, setPage, jobId, scrollToRef, refEl, reviewId, getReview } = this.props;
      if (activeSection && activeSection != prevProps.activeSection) {
        if (activeSection == 'reviews') {
          getJobReviews();
          setPage(0);
        }
        if (activeSection == 'interviews') {
          getInterviews();
          setPage(0);
        }
      }

      if ((jobId != prevProps.match.params.jobId) && jobId)
        getCompanyJob({jobId});
        refEl.current && scrollToRef();

      if (prevProps.reviewId != reviewId && reviewId)
        getReview();
    }
  }),
  withHandlers({
    follow: ({follow, getCompany, getJobReviews, getInterviews, getReview, reviewId}) => (...args) => {
      return follow(...args)
      .then(() => {
        getCompany({loader: false});
        getJobReviews(0, false);
        getInterviews(0, false);

        if (reviewId)
          getReview({loader: false});
      })
    },
    unfollow: ({unfollow, getCompany, getJobReviews, getInterviews, getReview, reviewId}) => (...args) => {
      return unfollow(...args)
      .then(() => {
        getCompany({loader: false});
        getJobReviews(0, false);
        getInterviews(0, false);

        if (reviewId)
          getReview({loader: false});
      })
    },
    followReview: ({followReview, getJobReviews, getInterviews, organizationId, page}) => type => reviewId => {
      const req = type === 'reviews' ? getJobReviews : getInterviews;

      return followReview(organizationId, reviewId, type)
      .then(() => req(page, false))
    },
    unfollowReview: ({unfollowReview, getJobReviews, getInterviews, organizationId, page}) => type => reviewId => {
      const req = type === 'reviews' ? getJobReviews : getInterviews;

      return unfollowReview(organizationId, reviewId, type)
      .then(() => req(page, false))
    }
  }),
  withHandlers({
    submit: ({submit, organizationId, closeModal, history}) => values => {
      return submit(values, `organization/${organizationId}`)
      .then(response => {
        closeModal();
        history.push(`/community/post/${response.id}`);
      });
    },
    getJobReviews: ({getJobReviews, setPage, scrollToReviewsListTop}) => page => {
      getJobReviews(page)
      .then(() => {
        setPage(page);
        scrollToReviewsListTop();
      });
    },
    getInterviews: ({getInterviews, setPage, scrollToReviewsListTop}) => page => {
      getInterviews(page)
      .then(() => {
        setPage(page);
        scrollToReviewsListTop();
      });
    },
    handleSave: ({saveJob, unsaveJob, jobsPage, jobId, organizationId, getCompanyJob, getCompanyJobs}) => (id, action) => {
      const req = action == 'Save' ? saveJob(id) : unsaveJob(id);

      return req
      .then(() => jobId
        ? getCompanyJob({jobId, loader: false})
        : getCompanyJobs({page: jobsPage, organizationId, loader: false})
      )
    },
    searchJobs: ({getCompanyJobs, setJobsPage, organizationId}) => page => {
      setJobsPage(page);
      return getCompanyJobs({page, organizationId});
    },
    voteJobReview: ({voteJobReview, page}) => (loadReview = false) => (...args) => {
      return voteJobReview(...args, {page, loadReview})
    },
    voteInterview: ({voteInterview, page}) => (loadReview = false) => (...args) => {
      return voteInterview(...args, {page, loadReview})
    },
    redirectToReview: ({history, data: {employer}}) => section => (reviewId, reported = false, returnUrl = false) => {
      const reviewType = section == 'reviews' ? 'jobs' : 'interviews';
      const url = `/companies/${employer.id}/${employer.slug}/${section}/${reviewType}/${reviewId}`;

      if (returnUrl)
        return url;
      else history.push({pathname: reported ? `${url}${env.QUERY.reviewReported}` : url, state: {from: location.pathname}});
    }
  })
)(
  ({data: {employer, review, reviews, interviews, job, jobs, posts},
    count,
    activeSection,
    getComments,
    viewInterview,
    viewJobReview,
    voteInterview,
    voteJobReview,
    loading,
    premium,
    user,
    userSignature,
    follow,
    unfollow,
    followReview,
    unfollowReview,
    followPost,
    unfollowPost,
    page,
    getJobReviews,
    getInterviews,
    organizationId,
    jobId,
    niceToHave,
    voteComment,
    modal,
    openModal,
    closeModal,
    submit,
    viewJob,
    handleSave,
    searchJobs,
    jobsPage,
    reviewId,
    redirectToReview,
    refEl,
    bodyRef,
    history
  }) => {
  const actionsBtnProps = {
    title: 'Add a review',
    links: [
      {name: '+ Applicant', link: `/companies/interview-review/${employer.id}/${employer.slug}`},
      {name: '+ Employee', link: `/companies/review/${employer.id}/${employer.slug}`}
    ]
  };

  const mobileReviewLinks = [
    {text: '+ APPLICANT REVIEW', icon: images.comment, fn: () => history.push(`/companies/interview-review/${employer.id}/${employer.slug}`)},
    {text: '+ EMPLOYEE REVIEW', icon: images.comment, fn: () => history.push(`/companies/review/${employer.id}/${employer.slug}`)}
  ];

  return (
    <div className="Company page">
      <Header />
      <Profile
        manager={employer}
        activeSection={activeSection}
        sections={headingSections(count.reviews, count.interviews, count.jobs, Boolean(user))}
        prefix="companies"
        loading={loading.company}
        user={user}
        premium={premium}
        follow={follow}
        unfollow={unfollow}
        submit={submit}
        userSignature={userSignature}
        fullWidth={activeSection == 'job-offers'}
        openModal={openModal}
        actionsBtnProps={actionsBtnProps}
        mobileReviewLinks={mobileReviewLinks}
      >
        <div key="left" ref={bodyRef}>
          {activeSection == 'overview' &&
            <div>
              <div className="Company__carousel">
                <Loader loading={loading.company} />
                {renderCarousel(employer, loading.company)}
              </div>

              {premium.videos && employer.arePremiumFeaturesEnabled &&
                <Videos videos={premium.videos} />
              }
              <div className="Company__card card">
                <Loader loading={loading.company} />
                <QuickGlance
                  data={employer}
                  labels={labels.quickGlance(employer)}
                  loading={loading.company}
                  hasColumns
                  numberOfRows={4}
                />
                <div className="Company__indicators--mobile">
                  <QuickGlance
                    data={employer}
                    labels={labels.indicators(employer)}
                    loading={loading.company}
                    hasColumns
                    numberOfRows={3}
                    booleanFields={labels.booleanFields}
                  />
                </div>
              </div>
              {premium.videos && employer.arePremiumFeaturesEnabled &&
                <Videos videos={premium.videos} mobile />
              }
              <div className="Company__indicators card">
                <Loader loading={loading.company} />
                <QuickGlance
                  data={employer}
                  labels={labels.indicators(employer)}
                  loading={loading.company}
                  hasColumns
                  numberOfRows={3}
                  booleanFields={labels.booleanFields}
                />
              </div>
              {reviews.length > 0 &&
                <div className="Company__row card">
                  <Loader loading={loading.reviews} />
                  <ReviewsSection
                    manager={employer}
                    reviews={reviews}
                    view={viewJobReview}
                    title="Employee Experiences"
                    reviewName="Employee Reviews"
                    link={`/companies/${employer.id}/${employer.slug}/reviews`}
                    linkAdd="/companies/review"
                    voteReview={voteJobReview()}
                    allReviewsCount={count.reviews}
                    redirectToReview={redirectToReview('reviews')}
                    followReview={followReview('reviews')}
                    unfollowReview={unfollowReview('reviews')}
                  >
                    {employer.statistics?.employerStatistics &&
                      <ManagerStats
                        statistics={employer.statistics.employerStatistics}
                        reviews={reviews}
                        className="Company__statistics"
                        allReviewsCount={count.reviews}
                      />
                    }
                  </ReviewsSection>
                </div>
              }
              {interviews.length > 0 &&
                <div className="Company__row card">
                  <Loader loading={loading.interviews} />
                  <ReviewsSection
                    manager={employer}
                    reviews={interviews}
                    view={viewInterview}
                    title="Applicant Experiences"
                    reviewName="Applicant Reviews"
                    link={`/companies/${employer.id}/${employer.slug}/interviews`}
                    linkAdd="/companies/interview-review"
                    voteReview={voteInterview()}
                    allReviewsCount={count.interviews}
                    redirectToReview={redirectToReview('interviews')}
                    followReview={followReview('interviews')}
                    unfollowReview={unfollowReview('interviews')}
                  >
                    {employer.statistics?.interviewStatistics &&
                      <InterviewsStats
                        statistics={pick(employer.statistics.interviewStatistics, 'interviewDifficulty', 'interviewSourceDistribution', 'averageOverallRating', 'admissionPercent')}
                        className="Company__statistics"
                        organization={employer}
                        reviews={interviews}
                        allReviewsCount={count.interviews}
                      />
                    }
                  </ReviewsSection>
                </div>
              }
            </div>
          }
          {activeSection == 'reviews' && !reviewId &&
            <div>
              <div className="position-relative">
                <Loader loading={loading.reviews} />
                <ReviewsSection
                  key={page}
                  manager={employer}
                  reviews={reviews}
                  view={viewJobReview}
                  title="Employee Experiences"
                  reviewName="Employee Reviews"
                  link={`/companies/${employer.id}/${employer.slug}/reviews`}
                  linkAdd="/companies/review"
                  voteReview={voteJobReview()}
                  redirectToReview={redirectToReview('reviews')}
                  followReview={followReview('reviews')}
                  unfollowReview={unfollowReview('reviews')}
                  all
                />
              </div>
              <Pagination
                className="Company__pagination"
                itemsCount={count.reviews}
                gotoPage={getJobReviews}
                activePage={page}
                disabled={loading.reviews}
              />
            </div>
          }
          {activeSection == 'reviews' && reviewId &&
            <SingleReview
              review={review}
              id={reviewId}
              user={user}
              voteReview={voteJobReview(true)}
              loading={loading.review}
              goBack={() => history.goBack()}
              followReview={followReview('reviews')}
              unfollowReview={unfollowReview('reviews')}
            />
          }
          {activeSection == 'interviews' && !reviewId &&
            <div>
              <div className="position-relative">
                <Loader loading={loading.interviews} />
                <ReviewsSection
                  key={page}
                  manager={employer}
                  reviews={interviews}
                  view={viewInterview}
                  title="Applicant Experiences"
                  reviewName="Applicant Reviews"
                  link={`/companies/${employer.id}/${employer.slug}/interviews`}
                  linkAdd="/companies/interview-review"
                  voteReview={voteInterview()}
                  redirectToReview={redirectToReview('interviews')}
                  followReview={followReview('interviews')}
                  unfollowReview={unfollowReview('interviews')}
                  all
                />
              </div>
              <Pagination
                className="Company__pagination"
                itemsCount={count.interviews}
                gotoPage={getInterviews}
                activePage={page}
                disabled={loading.interviews}
              />
            </div>
          }
          {activeSection == 'interviews' && reviewId &&
            <SingleReview
              review={review}
              user={user}
              voteReview={voteInterview(true)}
              loading={loading.review}
              goBack={() => history.goBack()}
              followReview={followReview('interviews')}
              unfollowReview={unfollowReview('interviews')}
            />
          }
          {activeSection == 'discussion' &&
            <Feed
              data={posts}
              dataCount={count.posts}
              className="Company__discussion"
              getData={getComments}
              loadingFeed={loading.posts}
              user={user}
              history={history}
              userSignature={userSignature}
              follow={followPost}
              unfollow={unfollowPost}
              voteComment={voteComment}
              optionalArgs={{
                topicId: `organization/${organizationId}`
              }}
              emptyText="No more posts!"
              communityFeed
            />
          }
          {activeSection == 'job-offers' && !jobId &&
            <CompanyJobs
              jobs={jobs}
              loading={loading.jobs}
              searchJobs={searchJobs}
              jobsCount={count.jobs}
              activePage={jobsPage}
              employer={employer}
              activeSection={activeSection}
              handleSave={handleSave}
              viewJob={viewJob}
              user={user}
            />
          }
          {activeSection == 'job-offers' && jobId && job.jobOpening &&
            <div ref={refEl} className="Company__row">
              <JobContainer
                niceToHave={niceToHave}
                handleSave={handleSave}
                user={user}
                isCompanyPage
              />
            </div>
          }
        </div>
        <div key="right">
          {activeSection == 'overview' &&
            <div>
              {employer.arePremiumFeaturesEnabled &&
                <StarSection
                  orgType={employer.class}
                  loading={loading.company}
                />
              }
              {employer?.partners?.length > 0 &&
                <div className="card">
                  <Loader loading={loading.company} />
                  <PartnersBox partners={employer?.partners} />
                </div>
              }
              {premium.details?.length > 0 && employer.arePremiumFeaturesEnabled &&
                <div className="card">
                  <Loader loading={loading.company} />
                  <DetailsSection details={premium.details} />
                </div>
              }
            </div>
          }
          {activeSection == 'discussion' &&
            <div className="Company__discussionAsk card">
              <button
                className="btn-primary max-width"
                onClick={() => openModal(MODAL_NAMES.ASK_QUESTION)}
              >
                Ask a question
              </button>
            </div>
          }
        </div>
      </Profile>
      <Footer />

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        className="Company__modal"
        closeBtn
      >
        <AskQuestion
          onSubmit={submit}
          form="QuestionForm"
          initialValues={{
            topicId: {
              value: `organization/${employer.id}`,
              label: employer.name
            }
          }}
          signature={userSignature}
          title="Ask a question"
        />
      </Modal>
    </div>
  )}
)

Company.propTypes = {
  employer: PT.object,
  getCompany: PT.func
};

const mapStateToProps = ({auth, data, loading}, {match: {params: {id, reviewId, jobId}}}) => ({
  user: auth.user,
  userProfile: data.user_profile || {},
  data: {
    employer: data[`company:${id}`] || {},
    review: data[`review:${reviewId}`] || {},
    reviews: data[`company:${id}:reviews`]?.data || [],
    interviews: data[`company:${id}:interviews`]?.data || [],
    job: data[`job:${jobId}`] || {},
    jobs: data.company_jobs || {},
    posts: data.comments?.data || []
  },
  count: {
    reviews: data[`company:${id}:reviews`]?.count || 0,
    interviews: data[`company:${id}:interviews`]?.count || 0,
    jobs: data.company_jobs?.list?.count + data.company_jobs?.matched?.length || 0,
    posts: data.comments?.count || 0
  },
  loading: {
    job: loading[`job:${jobId}`],
    jobs: loading.company_jobs,
    company: loading[`company:${id}`],
    posts: loading.comments,
    reviews: loading[`company:${id}:reviews`],
    interviews: loading[`company:${id}:interviews`],
    review: loading[`review:${reviewId}`]
  }
});

const mapDispatchToProps = (dispatch, {match: {params: {id, reviewId, reviewType}}}) => ({
  getCompany: (args = {}) => new Promise((resolve, reject) => dispatch(getCompany(id, {...args, resolve, reject}))),
  getCompanyJob: (args = {}) => new Promise((resolve, reject) => dispatch(getJob({...args, resolve, reject}))),
  getCompanyJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getCompanyJobs({...args, resolve, reject}))),
  getComments: (args = {}) => new Promise((resolve, reject) => dispatch(getComments({...args, resolve, reject}))),
  getJobReviews: (page, loader) => new Promise((resolve, reject) => dispatch(getJobReviews(id, {page, loader, resolve, reject}))),
  getInterviews: (page, loader) => new Promise((resolve, reject) => dispatch(getInterviews(id, {loader, resolve, reject, page}))),
  getReview: (args = {}) => new Promise((resolve, reject) => dispatch(getReview({...args, companyId: id, reviewType, reviewId, resolve, reject}))),
  viewJobReview: (...args) => dispatch(viewJobReview(id, ...args)),
  viewInterview: (...args) => dispatch(viewInterview(id, ...args)),
  voteJobReview: (...args) => dispatch(voteJobReview(id, ...args)),
  voteInterview: (...args) => dispatch(voteInterview(id, ...args)),
  follow: () => dispatch(follow(id)),
  unfollow: () => dispatch(unfollow(id)),
  followPost: (...args) => dispatch(followPost(...args)),
  unfollowPost: (...args) => dispatch(unfollowPost(...args)),
  followReview: (...args) => dispatch(followReview(...args)),
  unfollowReview: (...args) => dispatch(unfollowReview(...args)),
  submit: (values, topicId) => new Promise((resolve, reject) => dispatch(addComment({values, topicId, resolve, reject}))),
  saveJob: (id) => dispatch(saveJob(id)),
  unsaveJob: (id) => dispatch(unsaveJob(id)),
  displaySuccessMessage: (message) => dispatch(actionSucceeded(message)),
  viewJob: (id) => dispatch(viewJob(id)),
  verifyEmployer: (userId, isRejected) => dispatch(verifyEmployer({userId, isRejected})),
  voteComment: (id, action, {page, pageSize = constants.COMMENT.PAGE_SIZE}) => dispatch(voteComment(id, action, {page, pageSize}))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Company));
