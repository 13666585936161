import React                        from 'react';
import { connect }                  from 'react-redux';
import { withRouter }               from 'react-router';
import { Link }                     from 'react-router-dom';
import { Helmet }                   from 'react-helmet';
import { compose,
         setDisplayName,
         lifecycle,
         withState,
         withHandlers,
         withProps }                from 'recompose';
import cx                           from 'classnames';
import { parse }                    from 'qs';
import { env, REVIEW_TYPES }        from 'theme/content';
import Avatar                       from 'theme/Avatar';
import Follows                      from 'theme/Follows';
import Footer                       from 'theme/Footer';
import Header                       from 'theme/Header';
import Loader                       from 'theme/Loader';
import ManagerLogo                  from 'theme/ManagerLogo';
import Modal                        from 'theme/Modal';
import Return                       from 'theme/Return';
import SingleReview                 from 'theme/SingleReview';
import Pagination                   from 'theme/Pagination';
import ScrollingBar                 from 'theme/ScrollingBar';
import { withModal,
         withSignature }            from 'theme/utils/recompose';
import { constants }                from 'theme/utils/constants';
import { images }                   from 'theme/img/images';
import Share                        from 'share/Share';
import AskQuestion                  from 'comments/AskQuestion';
import Post                         from 'comments/Post';
import Feed                         from 'feed/Feed';
import EditAdminPostForm            from 'admin/AdminPosts/Form';
import JobContainer                 from 'jobs/JobContainer';
import { getAutocomplete as getTopicsAutocomplete,
         getTopics,
         followTopic,
         voteComment,
         addComment }               from 'comments/actions';
import { follow,
         unfollow,
         getCompanies }             from 'companies/actions';
import { getPartners }              from 'partners/actions';
import { getEducators }             from 'educators/actions';
import { saveJob,
         unsaveJob,
         withdraw,
         getJob,
         viewJob }                  from 'jobs/actions';
import { getPremiumOrganizations }  from 'landing/actions';
import { getSearches,
         deleteSearch,
         voteReview,
         updateUserProfile,
         getFollowed,
         getReviewsFeed,
         getUserFeed,
         getAppliedJobs,
         getSavedJobs,
         getMatchedJobs,
         getJobInvitations,
         getOrganizations as getOrganizationsAutocomplete,
         getRecent,
         getReview,
         getJobsFromFollowed }      from 'user/actions';
import { voteAdminPost,
         getAdminPost,
         updateAdminPost }          from 'admin/actions';
import { actionSucceeded }          from 'store/actions';

import JobList                      from './JobList';
import CompleteProfile              from './CompleteProfile';
import SubscribedSearch             from './SubscribedSearch';
import MyFeedForm                   from '../Forms/MyFeedForm';

import './UserDashboard.sass';

const ORG_TYPE_MAP = {
  'Educator': 'educators',
  'Employer': 'companies',
  'Partner': 'partners'
};

const MyFeedFormName = "MyFeedForm";

const SECTIONS = [
  {name: 'feed', label: 'Feed', to: env.HOME_ROUTE.common},
  {name: 'jobs', label: 'My jobs', to: '/dashboard/jobs/searches'},
  {name: 'reviews', label: 'My reviews', to: '/dashboard/reviews/my'},
  // {name: 'education', label: 'My education', to: '/dashboard/educators'},
  // {name: 'support', label: 'My support', to: '/dashboard/support'}
];

const MY_JOBS_SECTIONS = [
  {name: 'searches', label: 'Searches'},
  {name: 'matched', label: 'Matched'},
  {name: 'saved', label: 'Saved'},
  {name: 'applied', label: 'Applied'},
  {name: 'invitations', label: 'Job Invitations'}
];

const InviteFriendsBtn = ({openModal, mobile}) => (
  <button
    className={cx("UserDashboard__inviteFriendsBtn btn-primary-with-icon max-width", {mobile})}
    onClick={openModal}
  >
    <img src={images.personAdd} alt="Invite friends" />
    Invite friends
  </button>
);

const UserDashboard = compose(
  setDisplayName('UserDashboard'),
  withState('jobsPage', 'setJobsPage', 0),
  withState('subscriptionsPage', 'setSubscriptionsPage', 0),
  withState('completeProfileBoxVisible', 'setCompleteProfileBoxVisible', true),
  withModal(),
  withSignature(),
  withProps(({match: {params: {section, activeTab, jobId, reviewId, postId, organizationType}}, userProfile, location, urlParams, subscribed, fromFollowed}) => ({
    section,
    activeTab,
    jobId,
    reviewId,
    postId,
    organizationType,
    profileCompletion: userProfile && Math.ceil(userProfile.profileCompletion * 100),
    refEl: React.createRef(),
    subscribed: subscribed?.map(s => ({...s, type: 'subscription'})),
    fromFollowed: fromFollowed?.map(s => ({...s, type: 'fromFollowed'})),
    afterOnboarding: location.search.includes('onboarded'),
    isReportedContentReview: Boolean(urlParams.commentReported || urlParams.postReported)
  })),
  withHandlers({
    getJobInvitations: ({user, getJobInvitations}) => (...args) => {
      return getJobInvitations({...args, userId: user.id});
    },
    getOrganizationsForFeedCustomization: ({getPartners, getEducators, getCompanies, openModal}) => () => {
      const partnersReq = getPartners({pageSize: constants.ONBOARDING.ORG_SIZE});
      const educatorsReq = getEducators({pageSize: constants.ONBOARDING.ORG_SIZE, sort: 'studentsGiBill:d'});
      const companiesReq = getCompanies({pageSize: constants.ONBOARDING.ORG_SIZE, sort: 'employeeReviewCount:d'});

      Promise.all([partnersReq, educatorsReq, companiesReq])
      .then(() => {
        openModal('AFTER_ONBOARDING');
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        getFollowed,
        getSearches,
        getSavedJobs,
        getAppliedJobs,
        getMatchedJobs,
        getPremiumOrganizations,
        getRecent,
        getJobsFromFollowed,
        getJobInvitations,
        getJob,
        section,
        jobId,
        postId,
        reviewId,
        getReview,
        getAdminPost,
        getOrganizationsForFeedCustomization,
        getTopics,
        afterOnboarding
      } = this.props;

      if (section == 'jobs') {
        getPremiumOrganizations();
        getSearches();
        getSavedJobs();
        getAppliedJobs();
        getMatchedJobs();
        getRecent();
        getJobsFromFollowed();
        getJobInvitations();
      }

      getFollowed();

      if (jobId)
        getJob({jobId});

      if (reviewId)
        getReview();

      if (postId)
        getAdminPost();

      if (afterOnboarding) {
        getOrganizationsForFeedCustomization();
        getTopics();
      }
    },
    componentDidUpdate(prevProps) {
      const {
        getSearches,
        getAppliedJobs,
        getSavedJobs,
        getMatchedJobs,
        getJobsFromFollowed,
        getPremiumOrganizations,
        getJobInvitations,
        section,
        setJobsPage,
        activeTab,
        getJob,
        jobId,
        getRecent,
        getReview,
        getAdminPost,
        reviewId,
        postId,
        isReportedContentReview
      } = this.props;

      if (prevProps.section != section) {
        if (section == 'jobs') {
          getPremiumOrganizations();
          getSearches();
          getSavedJobs();
          getAppliedJobs();
          getMatchedJobs();
          getRecent();
          getJobsFromFollowed();
          getJobInvitations();
        }
      }

      if (prevProps.activeTab != activeTab)
        setJobsPage(0);

      if (prevProps.jobId !== jobId) {
        if (jobId) {
          !isReportedContentReview && window.scrollTo(0, 0);
          getJob({jobId});
        }

        if (activeTab === 'searches') {
          getSearches();
          getJobsFromFollowed();
          getRecent();
        }
        if (activeTab === 'saved')
          getSavedJobs();
        if (activeTab === 'matched')
          getMatchedJobs();
        if (activeTab === 'applied')
          getAppliedJobs();
        if (activeTab === 'invitations')
          getJobInvitations();
      }

      if (prevProps.reviewId != reviewId && reviewId) {
        !isReportedContentReview && window.scrollTo(0, 0);
        getReview();
      }

      if (prevProps.postId != postId && postId) {
        !isReportedContentReview && window.scrollTo(0, 0);
        getAdminPost();
      }
    }
  }),
  withHandlers({
    scrollToRef: ({refEl}) => () => window.scrollTo(0, refEl.current.offsetTop)
  }),
  withHandlers({
    follow: ({follow, getPremiumOrganizations}) => (...args) => {
      return follow(...args)
      .then(() => getPremiumOrganizations({loader: false}))
    },
    unfollow: ({unfollow, getPremiumOrganizations}) => (...args) => {
      return unfollow(...args)
      .then(() => getPremiumOrganizations({loader: false}))
    },
    getTopicsAutocomplete: ({getTopicsAutocomplete}) => value => {
      if (value === '') return;
      return getTopicsAutocomplete({query: value})
      .then(res => res.data.map(r => ({
        label: r.name,
        value: r.id
      })));
    },
    submit: ({submit, closeModal, getUserFeed}) => values => {
      return submit(values)
      .then(() => {
        getUserFeed();
        closeModal();
      });
    },
    updateAdminPost: ({updateAdminPost, closeModal, getAdminPost}) => values => {
      return updateAdminPost(values)
      .then(() => {
        getAdminPost();
        closeModal();
      });
    },
    searchJobs: ({getAppliedJobs, getSavedJobs, getJobsFromFollowed, getMatchedJobs, setJobsPage, scrollToRef, activeTab}) => page => {
      let jobReq;

      switch(activeTab) {
        case 'matched':
          jobReq = getMatchedJobs;
          break;
        case 'saved':
          jobReq = getSavedJobs;
          break;
        case 'applied':
          jobReq = getAppliedJobs;
          break;
        default:
          jobReq = getJobsFromFollowed;
      }

      return jobReq({page, loader: false})
      .then(() => {
        setJobsPage(page);
        if (activeTab != 'searches')
          scrollToRef();
      })
    },
    searchSubscriptions: ({getSearches, setSubscriptionsPage, scrollToRef}) => page => {
      return getSearches({page, loader: false})
      .then(() => {
        setSubscriptionsPage(page);
        scrollToRef();
      })
    },
    handleSave: ({saveJob, unsaveJob, getAppliedJobs, getSavedJobs, getMatchedJobs, getRecent, jobsPage, subscriptionsPage, activeTab, getSearches, getJobsFromFollowed}) => (type = null) => (id, action) => {
      const req = action == 'Save' ? saveJob(id) : unsaveJob(id);

      return req
      .then(() => {
        getSavedJobs({page: jobsPage});
        switch(activeTab) {
          case 'applied':
            return getAppliedJobs({page: jobsPage, loader: false});
          case 'matched':
            return getMatchedJobs({page: jobsPage, loader: false});
          case 'searches':
            if (type === 'subscription')
              return getSearches({page: subscriptionsPage, loader: false});
            else if (type === 'followed')
              return getJobsFromFollowed({page: jobsPage, loader: false});
            else if (type === 'recent')
              return getRecent({loader: false});
        }
      })
    },
    withdraw: ({withdraw, displaySuccessMessage, jobId, getJob}) => applicationId => {
      return withdraw(applicationId)
      .then(() => {
        getJob({jobId});
        displaySuccessMessage('Your job application has been withdrawn.');
      })
    },
    redirectToReview: ({history, section}) => review => (id, reported = false) => {
      const url = `/dashboard/${section}/${REVIEW_TYPES[review?.metadata.class].organizationsAlt}/${review?.organization.id}/${REVIEW_TYPES[review?.metadata.class].type || REVIEW_TYPES[review?.metadata.class].section}/${id}`;
      history.push(reported ? `${url}${env.QUERY.reviewReported}` : url);
    },
    voteReview: ({voteReview, getReview}) => review => (id, action) => {
      return voteReview(review, id, action)
      .then(() => {
        getReview({loader: false});
      })
    },
    voteAdminPost: ({voteAdminPost, getAdminPost}) => (id, action) => {
      return voteAdminPost(id, action)
      .then(() => {
        getAdminPost({loader: false});
      })
    },
    getOrganizationsAutocomplete: ({getOrganizationsAutocomplete}) => value => {
      return getOrganizationsAutocomplete(value)
      .then(data => data.map(item => ({
        value: item.id,
        label: item.name
      })))
    },
    updateProfile: ({user, updateProfile, closeModal, getFollowed}) => values => {
      return updateProfile(user, values)
      .then(() => {
        closeModal();
        getFollowed();
      });
    }
  }),
  withProps(({feedItems, followed, savedTopHit, appliedTopHit, savedJobs, appliedJobs}) => {
    const merge = (topHit, jobs) => Object.keys(topHit).length > 0 ? [topHit, ...jobs] : jobs;

    return {
      savedJobs: merge(savedTopHit, savedJobs),
      appliedJobs: merge(appliedTopHit, appliedJobs),
      feedItems: feedItems.map(i => ({...i,
        itemType: i.adminPostFeedContent ? 'admin-post' : 'comment',
        id: i.adminPostFeedContent ? i.adminPostFeedContent.id : (i.commentFeedContent ? i.commentFeedContent.id : i.reviewFeedContent.id)
      })),
      followedOrganizations: [
        {name: 'companies', label: 'Employers', data: followed.filter(f => f.class == 'Employer')},
        {name: 'educators', label: 'Educators', data: followed.filter(f => f.class == 'Educator')},
        {name: 'support-services', label: 'Support Services', data: followed.filter(f => f.class == 'Partner')}
      ]
    }
  }),
  withProps(({followedOrganizations}) => ({
    allFollowedOrganizations: followedOrganizations
      .map(type => type.data?.map((item) => ({...item, organizationType: type.name})))
      .reduce((a, b) => a.concat(b), [])
  }))
)(({user,
   count,
   feedItems,
   getUserFeed,
   reviews,
   getReviewsFeed,
   section,
   activeTab,
   jobId,
   review,
   reviewId,
   redirectToReview,
   selectReview,
   loading,
   followedOrganizations,
   allFollowedOrganizations,
   userProfile,
   userSignature,
   modal,
   closeModal,
   openModal,
   submit,
   subscribed,
   deleteSearch,
   matchedJobs,
   appliedJobs,
   savedJobs,
   jobInvitations,
   jobsPage,
   handleSave,
   searchJobs,
   premiumOrganizations,
   follow,
   unfollow,
   getTopicsAutocomplete,
   completeProfileBoxVisible,
   setCompleteProfileBoxVisible,
   voteComment,
   voteAdminPost,
   viewJob,
   profileCompletion,
   refEl,
   history,
   withdraw,
   jobsFromFollowed,
   subscriptionsPage,
   searchSubscriptions,
   recent,
   voteReview,
   adminPost,
   postId,
   updateAdminPost,
   companies,
   educators,
   partners,
   getOrganizationsAutocomplete,
   isReportedContentReview,
   updateProfile,
   topics,
   followTopic
  }) => {
  const jobOpeningProps = {
    page: jobsPage,
    searchJobs,
    user,
    viewJob,
    activeTab,
    tab: activeTab == 'saved' ? 'saved' : 'applied'
  };

  const emptyText = section === 'reviews'
    ? "You haven’t submitted any reviews yet. Once you do you’ll be able to view and edit them here. Submit your first one!"
    : "If you're not seeing the review content you hoped for, let us know! You could also reach out to friends and encourage them to share their experiences!";

  return (
    <div className="UserDashboard page">
      <Helmet>
        <title>Vet it — User Dashboard</title>
        <meta name="description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
        <meta name="image" content={images.shareDefaultImage} />
        <meta itemProp="name" content="Vet it" />
        <meta itemProp="description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
        <meta itemProp="image" content={images.shareDefaultImage} />
        <meta name="og:title" content="Vet it" />
        <meta name="og:description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
        <meta name="og:image" content={images.shareDefaultImage} />
        <meta name="og:url" content={`${location.origin}/home`} />
        <meta name="og:site_name" content="Vet it" />
        <meta name="og:type" content="website" />
        <meta name="twitter:title" content='Vet it' />
        <meta name="twitter:description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
        <meta name="twitter:image" content={images.shareDefaultImage} />
      </Helmet>
      <Header />
      <div className="container">
        <div className="UserDashboard__heading card">
          <Loader loading={loading.profile} />
          <div className="UserDashboard__headingWrapper">
            <Avatar />
            <div className="UserDashboard__user">
              <div className="UserDashboard__userName">
                <h4>Hello {userProfile.firstName}!</h4>
                <span>{user.email}</span>
              </div>
              <Link className="btn-primary" to="/profile-settings/general">Edit profile</Link>
            </div>
          </div>
          <div className="UserDashboard__nav">
            {SECTIONS.map((s, i) => (
              <Link
                key={i}
                className={cx({active: section === s.name})}
                to={s.to}
              >
                {s.label}
              </Link>
            ))}
          </div>
          <div className="nav-overlay"></div>
        </div>

        {jobId
          ?
            <>
              <Return
                text="Back"
                goTo={() => history.goBack()}
              />
              <JobContainer
                className="UserDashboard__jobContainer"
                handleSave={handleSave}
                withdraw={withdraw}
                isUserDashboard
              />
            </>
          :
          <div className={cx("UserDashboard__container", {reviewsFeed: section == 'reviews'})}>
            {section == 'feed' &&
              <div className="UserDashboard__content">
                {postId
                  ? <div className="UserDashboard__left">
                      <Loader loading={loading.adminPost} />
                      {!isReportedContentReview &&
                        <Return
                          text="Back"
                          goTo={() => history.goBack()}
                        />
                      }
                      {Object.keys(adminPost).length > 0 &&
                        <Post
                          post={adminPost}
                          postId={postId}
                          vote={voteAdminPost}
                          voteComment={voteComment}
                          authUser={user}
                          history={history}
                          admin
                          topLvlComment
                          editPost={() => openModal('EDIT_ADMIN_POST', adminPost)}
                        />
                      }
                    </div>
                  : <div className="UserDashboard__left">
                      {reviewId
                        ?
                          <SingleReview
                            review={review}
                            id={reviewId}
                            user={user}
                            voteReview={voteReview(review)}
                            loading={loading.review}
                            goBack={() => history.goBack()}
                            isFeed
                          />
                        :
                          <div>
                            {profileCompletion < 100 && completeProfileBoxVisible &&
                              <CompleteProfile
                                percentage={profileCompletion}
                                loading={loading.profile}
                                close={() => setCompleteProfileBoxVisible(false)}
                              />
                            }
                            <div className="UserDashboard__askQuestion card">
                              <div
                                className="UserDashboard__askQuestionInput"
                                onClick={() => openModal('ASK_QUESTION')}
                              >
                                <i className="fa fa-pencil-square-o"></i>
                                Ask a question... what are you professionally curious about?
                              </div>
                            </div>
                            <Feed
                              key={count.feed}
                              data={feedItems}
                              dataCount={count.feed}
                              getData={getUserFeed}
                              loadingFeed={loading.feed}
                              user={user}
                              history={history}
                              redirectToReview={redirectToReview}
                              selectReview={selectReview}
                              emptyText="That's all the content you follow!"
                              userDashboard
                            />
                          </div>
                      }
                    </div>
                }
                {!isReportedContentReview &&
                  <InviteFriendsBtn
                    openModal={() => openModal('SHARE')}
                    mobile
                  />
                }
                <div className="UserDashboard__right UserDashboard__right--reverse">
                  <div className="UserDashboard__oNet card">
                    <h4>Invite friends!</h4>
                    <p>Help your friends make smart career decisions and invite them to the community!</p>
                    <InviteFriendsBtn openModal={() => openModal('SHARE')} />
                  </div>
                  <Follows
                    className="UserDashboard__follows"
                    organizationTypes={followedOrganizations}
                    loading={loading.followed}
                  />
                  {!isReportedContentReview &&
                    <ScrollingBar
                      className="UserDashboard__mobileFollows"
                      loading={loading.followed}
                      title="My follows"
                    >
                      {allFollowedOrganizations.map(manager => (
                        <Link
                          key={manager.id}
                          to={`/${ORG_TYPE_MAP[manager.class]}/${manager.id}/${manager.slug}/overview`}
                        >
                          <ManagerLogo
                            manager={manager}
                            className="Follows__managersLogo"
                          />
                        </Link>
                      ))}
                    </ScrollingBar>
                  }
                </div>
              </div>
            }
            {section == 'jobs' &&
              <div className="UserDashboard__content UserDashboard__jobs">
                <div className="UserDashboard__left" ref={refEl}>
                  <div className="UserDashboard__jobsNav">
                    {MY_JOBS_SECTIONS.map((s, i) => (
                      <Link
                        className={cx("btn-secondary-ghost-transparent", {active: activeTab === s.name})}
                        key={i}
                        to={`/dashboard/jobs/${s.name}`}
                      >
                        {s.label}
                      </Link>
                    ))}
                  </div>
                  {activeTab == 'searches' &&
                    <div className="UserDashboard__searches">
                      <div className="UserDashboard__subscriptions">
                        <Loader loading={loading.searches} />
                        {subscribed.length > 0
                          ?
                            <>
                              {subscribed.map(s => (
                                <SubscribedSearch
                                  search={s}
                                  key={s.subscription.id}
                                  deleteSearch={deleteSearch}
                                  viewJob={viewJob}
                                  user={user}
                                  handleSave={handleSave('subscription')}
                                />
                              ))}
                              <Pagination
                                itemsCount={count.subscribed}
                                gotoPage={searchSubscriptions}
                                disabled={loading.searches}
                                activePage={subscriptionsPage}
                                pageSize={3}
                              />
                            </>
                          :
                            <div className="UserDashboard__empty">
                              <h5>You haven't saved any searches yet! Go to the jobs listing, apply the filters you want, and click the Save Search button!</h5>
                            </div>
                        }
                      </div>
                      <div className="UserDashboard__fromFollowed">
                        <span className="UserDashboard__title">Followed employers</span>
                        <JobList
                          jobs={jobsFromFollowed}
                          count={count.jobsFromFollowed}
                          loading={loading.jobsFromFollowed}
                          handleSave={handleSave('followed')}
                          pageSize={3}
                          {...jobOpeningProps}
                        />
                      </div>
                      <div className="UserDashboard__recent">
                        <span className="UserDashboard__title">Recently viewed</span>
                        <JobList
                          jobs={recent}
                          count={count.recent}
                          loading={loading.recent}
                          handleSave={handleSave('recent')}
                          {...jobOpeningProps}
                        />
                      </div>
                    </div>
                  }
                  {activeTab == 'matched' &&
                    <JobList
                      jobs={matchedJobs}
                      count={count.matchedJobs}
                      loading={loading.matchedJobs}
                      handleSave={handleSave()}
                      {...jobOpeningProps}
                    />
                  }
                  {activeTab == 'saved' &&
                    <JobList
                      jobs={savedJobs}
                      count={count.savedJobs}
                      loading={loading.savedJobs}
                      handleSave={handleSave()}
                      empty="You haven't saved any jobs yet!"
                      {...jobOpeningProps}
                    />
                  }
                  {activeTab == 'applied' &&
                    <JobList
                      jobs={appliedJobs}
                      count={count.appliedJobs}
                      loading={loading.appliedJobs}
                      handleSave={handleSave()}
                      empty="You haven't applied to any jobs yet!"
                      {...jobOpeningProps}
                    />
                  }
                  {activeTab == 'invitations' &&
                    <JobList
                      jobs={jobInvitations}
                      count={count.jobInvitations}
                      loading={loading.jobInvitations}
                      handleSave={handleSave()}
                      empty="You haven't received any invitations to apply yet!"
                      jobInvitation
                      {...jobOpeningProps}
                    />
                  }
                </div>
                <div className="UserDashboard__right">
                  <div className="UserDashboard__oNet card">
                    <h4>What's my next move?</h4>
                    <p>O*Net is a US Department of Labor occupational database that can give you an idea of how you might translate your military experience into a civilian job.</p>
                    <a
                      className="btn-primary-outlined max-width"
                      href="https://www.onetonline.org/"
                      target="_blank"
                    >
                      Check my o*net page
                    </a>
                  </div>
                  {premiumOrganizations.length > 0 &&
                    <div className="UserDashboard__premium card">
                      <Loader loading={loading.premium} />
                      <h4>Premium employers</h4>
                      {premiumOrganizations
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(o => (
                          <div key={o.id} className="UserDashboard__premiumEmployer">
                            <Link to={`/companies/${o.id}/${o.slug}/overview`} key={o.id}>
                              <ManagerLogo
                                manager={o}
                                size="xl"
                              />
                            </Link>
                            <h4>{o.name}</h4>
                            <a
                              className="OrganizationBox__link"
                              href={o.url}
                              target="_blank"
                            >
                              {o.url}
                              <img src={images.externalLink} alt="Go to website" />
                            </a>
                            <span className="UserDashboard__premiumOpen">{o.openPositionCount} open positions</span>
                            <button
                              className={cx("btn-secondary-ghost", {active: o.isFollowed})}
                              onClick={() => o.isFollowed ? unfollow(o.id): follow(o.id)}
                            >
                              {o.isFollowed ? 'Unfollow' : 'Follow'}
                            </button>
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
            }
            {section == 'reviews' && !reviewId &&
              <div className="UserDashboard__left UserDashboard__left--maxWidth">
                <Feed
                  data={reviews}
                  dataCount={count.reviews}
                  getData={getReviewsFeed}
                  loadingFeed={loading.reviews}
                  redirectToReview={redirectToReview}
                  user={user}
                  emptyText={emptyText}
                  userDashboard
                  reviewsFeed
                  isMyReviews
                />
              </div>
            }
            {section == 'reviews' && reviewId &&
              <SingleReview
                review={review}
                user={user}
                voteReview={voteReview(review)}
                loading={loading.review}
                goBack={() => history.goBack()}
                isFeed
              />
            }
          </div>
        }
      </div>
      <Footer />

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        className={cx("UserDashboard__modal", {shareModal: modal.name == 'SHARE'})}
        closeBtn
      >
        {(() => {
          switch(modal.name) {
            case 'ASK_QUESTION':
              return (
                <AskQuestion
                  onSubmit={submit}
                  form="QuestionForm"
                  getAutocomplete={getTopicsAutocomplete}
                  signature={userSignature}
                  title="Ask a question"
                  topicPlaceholder="Select or enter new topic"
                />
              )
            case 'EDIT_ADMIN_POST':
              return (
                <EditAdminPostForm
                  onSubmit={updateAdminPost}
                  form="EditAdminPostForm"
                  initialValues={modal.payload}
                  postType={modal.payload?.type}
                  title="Edit your post"
                  btnText="Save your edit"
                  isEdited
                />
              )
            case 'SHARE':
              return (
                <Share
                  url={`${location.origin}/home`}
                  closeModal={closeModal}
                  linkedinText={`Vetters transition better! "Vet it Forward" by inviting the military peers, veterans, and spouses you know to share their insights with our community! ${location.origin}`}
                />
              )
            case 'AFTER_ONBOARDING':
              return (
                <div className="UserDashboard__customizeFeed">
                  <Loader loading={loading.orgs && loading.topics} />
                  <h4>Customize your feed</h4>
                  <p className="UserDashboard__customizeFeedInfo">We don’t want your feed to be empty. These are recommended profiles that you might find interesting:</p>
                  <h5 className="subheader">Top employers, education profiles and supporting organizations</h5>
                  {(!loading.orgs && !loading.topics) &&
                    <MyFeedForm
                      onSubmit={updateProfile}
                      form={MyFeedFormName}
                      initialValues={{
                        companies,
                        educators,
                        supportServices: partners
                      }}
                      getOrganizationsAutocomplete={getOrganizationsAutocomplete}
                      getTopicsAutocomplete={getTopicsAutocomplete}
                      topics={topics}
                      followTopic={followTopic}
                    />
                  }
                </div>
              )
          }
        })()}
      </Modal>
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}, {match: {params: {reviewId}}}) => ({
  user: auth.user,
  followed: data.followed?.data || [],
  userProfile: data.user_profile || {},
  feedItems: data.user_feed?.data || [],
  reviews: data.reviews_feed?.data || [],
  subscribed: data.searches?.data || [],
  premiumOrganizations: data.premium || [],
  matchedJobs: data.matched_jobs?.data || [],
  recent: data.recent?.data || [],
  review: data[`review:${reviewId}`] || {},
  jobsFromFollowed: data.jobs_from_followed?.data || [],
  savedJobs: data.saved_jobs?.data || [],
  savedTopHit: data.saved_jobs?.topHit || {},
  jobInvitations: data.job_invitations?.data || [],
  appliedJobs: data.applied_jobs?.data || [],
  appliedTopHit: data.applied_jobs?.topHit || {},
  adminPost: data.admin_post || {},
  companies: data.companies?.data || [],
  educators: data.educators?.data || [],
  partners: data.partners?.data || [],
  topics: data.topics?.data || [],
  urlParams: parse(location.search.substr(1)),
  count: {
    reviews: data.reviews_feed?.count || 0,
    feed: data.user_feed?.count || 0,
    subscribed: data.searches?.count || 0,
    savedJobs: data.saved_jobs?.count || 0,
    appliedJobs: data.applied_jobs?.count || 0,
    matchedJobs: data.matched_jobs?.count || 0,
    recent: data.recent?.count || 0,
    jobsFromFollowed: data.jobs_from_followed?.count || 0,
    jobInvitations: data.job_invitations?.count || 0
  },
  loading: {
    followed: loading.followed,
    profile: loading.user_profile,
    searches: loading.searches,
    savedJobs: loading.saved_jobs,
    matchedJobs: loading.matched_jobs,
    appliedJobs: loading.applied_jobs,
    premium: loading.premium,
    feed: loading.user_feed,
    reviews: loading.reviews_feed,
    recent: loading.recent,
    jobsFromFollowed: loading.jobs_from_followed,
    review: loading[`review:${reviewId}`],
    adminPost: loading.admin_post,
    jobInvitations: loading.job_invitations,
    orgs: loading.companies || loading.educators || loading.partners,
    topics: loading.topics
  }
});

const mapDispatchToProps = (dispatch, {match: {params: {organizationType, reviewId, organizationId, reviewType, postId}}}) => ({
  getFollowed: () => new Promise((resolve, reject) => dispatch(getFollowed({resolve, reject}))),
  getSavedJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getSavedJobs({...args, resolve, reject}))),
  getJob: (args = {}) => new Promise((resolve, reject) => dispatch(getJob({...args, resolve, reject}))),
  getAppliedJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getAppliedJobs({...args, resolve, reject}))),
  getMatchedJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getMatchedJobs({...args, resolve, reject}))),
  getJobsFromFollowed: (args = {}) => new Promise((resolve, reject) => dispatch(getJobsFromFollowed({...args, resolve, reject}))),
  getUserFeed: (args = {}) => new Promise((resolve, reject) => dispatch(getUserFeed({...args, resolve, reject}))),
  getReviewsFeed: (args = {}) => new Promise((resolve, reject) => dispatch(getReviewsFeed({...args, resolve, reject}))),
  getRecent: (args = {}) => new Promise((resolve, reject) => dispatch(getRecent({...args, resolve, reject}))),
  getSearches: (args = {}) => new Promise((resolve, reject) => dispatch(getSearches({...args, resolve, reject}))),
  getAdminPost: (args = {}) => new Promise((resolve, reject) => dispatch(getAdminPost({...args, postId, resolve, reject}))),
  getJobInvitations: (args = {}) => new Promise((resolve, reject) => dispatch(getJobInvitations({...args, resolve, reject}))),
  getTopicsAutocomplete: (args) => dispatch(getTopicsAutocomplete(args)),
  getPremiumOrganizations: (args = {}) => new Promise((resolve, reject) => dispatch(getPremiumOrganizations({...args, resolve, reject}))),
  getReview: (args = {}) => new Promise((resolve, reject) => dispatch(getReview({...args, organizationType, organizationId, reviewType, reviewId, resolve, reject}))),
  deleteSearch: (id) => dispatch(deleteSearch(id)),
  submit: (values) => new Promise((resolve, reject) => dispatch(addComment({values, resolve, reject}))),
  saveJob: (id) => dispatch(saveJob(id)),
  unsaveJob: (id) => dispatch(unsaveJob(id)),
  follow: (...args) => dispatch(follow(...args)),
  unfollow: (...args) => dispatch(unfollow(...args)),
  displaySuccessMessage: (message) => dispatch(actionSucceeded(message)),
  viewJob: (id) => dispatch(viewJob(id)),
  withdraw: (applicationId) => dispatch(withdraw(applicationId)),
  voteComment: (id, action, {page, pageSize = constants.COMMENT.PAGE_SIZE}) => dispatch(voteComment(id, action, {page, pageSize})),
  voteAdminPost: (id, action) => dispatch(voteAdminPost(id, action)),
  voteReview: (review, id, action) => dispatch(voteReview(review, id, action)),
  updateAdminPost: (values) => dispatch(updateAdminPost({values})),
  getEducators: (args = {}) => dispatch(getEducators({...args})),
  getCompanies: (args = {}) => dispatch(getCompanies({...args})),
  getPartners: (args = {}) => dispatch(getPartners({...args})),
  getOrganizationsAutocomplete: query => dispatch(getOrganizationsAutocomplete(query)),
  getTopics: () => new Promise((resolve, reject) => dispatch(getTopics({sortByPopularity: true, resolve, reject}))),
  updateProfile: (user, values) => dispatch(updateUserProfile(user.id, values)),
  followTopic: (...args) => dispatch(followTopic(...args))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDashboard));
