import React    from 'react';
import { Link } from 'react-router-dom';
import cx       from 'classnames';
import Loader   from 'theme/Loader';

import './Tabs.sass';


export default function Tabs({links, title, section, children, loading, hideNav, mobileTitle, className}) {
  return (
    <div className={cx("Tabs", className)}>
      <Loader loading={loading} />
      {/* {!hideNav && */}
        <div className={cx("Tabs__nav", {hidden: section == 'delete-account'})}>
          {links
            ? <>
                <h1 className="Tabs__mobileTitle">{mobileTitle}</h1>
                <ul>
                  {links.map((l, i) => (
                    <li key={i} className={cx({active: l.name === section})}><Link to={l.name}>{l.label}</Link></li>
                  ))}
                </ul>
              </>
            :
              <h5 className="Tabs__title">{title}</h5>
          }
        </div>
      {/* } */}
      <div className={cx("Tabs__body", {noPadding: hideNav || section == 'delete-account'})}>
        {!loading &&
          <div>{children}</div>
        }
      </div>
    </div>
  )
}
