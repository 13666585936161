import api, { loadAuth }      from 'api';
import { omit }               from 'underscore';
import { trimValues,
         mapReviewAction }    from 'theme/utils/helpers';

const getFollowed = () => {
  return api({
    method: 'get',
    url: '/followed',
    params: {page: 0, pageSize: 1000}
  });
}

function fetchPartners({page = 0, pageSize = 10, filters = {}, query}) {
  const {sort, ...restFilters} = filters;
  const newFilters = Object.entries(restFilters)
  .filter(([value]) => value !== undefined && value !== null)
  .map(([k, value]) => `${k}:${value}`);

  return api({
    method: 'get',
    url: '/partners',
    params: {
      page,
      pageSize,
      filter: newFilters,
      sort: sort || 'name:a',
      ...query && {query}
    }
  });
}

function fetchPartner({id}) {
  const user = loadAuth();
  const loggedIn = Object.keys(user).length > 0;
  const followedReq = loggedIn && getFollowed();

  const partnerReq = api({
    method: 'get',
    url: `/partners/${id}`
  });

  if(!loggedIn)
    return partnerReq;

  return Promise.all([partnerReq, followedReq])
  .then(([partner, followed]) => {
    const c = followed.data.data.find(f => f.id == partner.data.id);
    return {
      ...partner.data,
      isFollowed: Boolean(c)
    };
  });
}

function postPartner({values}) {
  const {metadata, ...otherValues} = values;
  const cities = otherValues.cities || [];
  const states = otherValues.states || [];

  return api({
    method: 'post',
    url: '/partners',
    data: {metadata, ...trimValues({...otherValues, cities, states})}
  });
}

function putPartner({id, values}) {
  const {metadata, ...otherValues} = values;

  return api({
    method: 'put',
    url: `/partners/${id}`,
    data: {metadata, ...trimValues(otherValues)}
  });
}


export const deletePartner = ({partner}, {page = 0} = {}) => dispatch => {
  return api({
    method: 'delete',
    url: `/partners/${partner.id}`,
    params: page
  })
  .then(() => dispatch(getPartners({page})));
}

export const getAutocomplete = ({prefix, sort = 'name:a', field, nameOnly = false}) => () => {
  return api({
    method: 'get',
    url: nameOnly ? 'partners/autocomplete' : 'partners',
    params: {
      prefix,
      sort: nameOnly ? null : sort,
      field: nameOnly ? field = 'cities' : field
    }
  })
  .then(({data}) => data);
}


export const getPartners = ({query, page, pageSize, filters, loader = true}) => {
  return {type: 'PARTNERS/FETCH', req: fetchPartners, query, page, pageSize, filters, loader}
}

export const getPartner = (id, opts = {loader: true}) => {
  return {type: `PARTNER:${id}/FETCH`, req: fetchPartner, id, ...opts}
}

export const addPartner = ({values, resolve, reject}) => {
  return {type: 'PARTNER/SUBMIT', values, req: postPartner, resolve, reject}
}

export const updatePartner = ({id, values, resolve, reject}) => {
  return {type: `PARTNER:${id}/SUBMIT`, id, values, req: putPartner, resolve, reject}
}

//------------------------------ REVIEWS ---------------------------

function postReview({values}) {
  const headers = values.captcha ? {'Captcha': values.captcha} : {};

  return api({
    method: 'post',
    url: `/partners/${values.partner.id || values.partner}/reviews`,
    headers,
    data: omit(values, 'captcha')
  });
}

function fetchReview({partnerId, reviewId}) {
  return api({
    method: 'get',
    url: `/partners/${partnerId}/reviews/${reviewId}`
  });
}

function fetchReviews({id, page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: `/partners/${id}/reviews`,
    params: {page, pageSize}
  });
}

function reviewAction(partnerId, reviewId, action, isVote) {
  return api(
    mapReviewAction({
      method: 'post',
      action: isVote ? (action === 'revokeVote' ? 'revokeVote' : 'vote') : null,
      url: `/partners/${partnerId}/reviews/${reviewId}`,
      params: isVote
        ? {type: action}
        : {action}
    })
  );
}

export function updateReview(partnerId, reviewId, values) {
  return (dispatch) => {
    return api({
      method: 'put',
      url: `/partners/${partnerId}/reviews/${reviewId}`,
      data: omit(values, 'id', 'partner', 'metadata')
    })
    .then(() => dispatch(getPartnerReview(partnerId, reviewId, {loader: false})))
  }
}


export const addPartnerReview = ({values, form, resolve, reject}) => {
  return {type: 'PARTNER_REVIEW/SUBMIT', values, req: postReview, form, resolve, reject}
}

export const getPartnerReview = (partnerId, reviewId, opts = {}) => {
  return {type: `PARTNER_REVIEW:${reviewId}/FETCH`, req: fetchReview, partnerId, reviewId, ...opts}
}

export const getPartnerReviews = (id, opts = {loader: true}) => {
  return {type: `PARTNER:${id}:REVIEWS/FETCH`, id, req: fetchReviews, ...opts}
}

//------------------------------ VIEWS ---------------------------
export const viewReview = (partnerId, reviewId) => () => {
  return reviewAction(partnerId, reviewId, 'View');
}

//------------------------------ UP- & DOWNVOTING ---------------------------
export const voteReview = (partnerId, reviewId, action, {page, pageSize, loadReview} = {}) => dispatch => {
  return reviewAction(partnerId, reviewId, action, true)
  .then(() => dispatch(loadReview
    ? getPartnerReview(partnerId, reviewId, {loader: false})
    : getPartnerReviews(partnerId, {loader: false, page, pageSize})));
}

//------------------------------ FOLLOWING ---------------------------

export const follow = partnerId => () => {
  return api({
    method: 'post',
    url: `/partners/${partnerId}/follow`
  })
}

export const unfollow = partnerId => () => {
  return api({
    method: 'delete',
    url: `/partners/${partnerId}/follow`
  })
}

//------------------------------ FOLLOW REVIEWS ---------------------------
export const followReview = (partnerId, reviewId) => () => {
  return reviewAction(partnerId, reviewId, 'Follow');
}

export const unfollowReview = (partnerId, reviewId) => () => {
  return reviewAction(partnerId, reviewId, 'Unfollow');
}
