import api from 'api';

function fetchSubscription() {
  return api({
    method: 'get',
    url: '/subscriptions/my'
  })
}

function fetchSubscriptions() {
  return api({
    method: 'get',
    url: '/subscriptions'
  })
}

export const generateCheckout = planId => () => {
  return api({
    method: 'post',
    url: 'subscriptions/my',
    params: {plan: planId}
  })
  .then(({data}) => data)
}

export const editSubscription = () => () => {
  return api({
    method: 'patch',
    url: 'subscriptions/my'
  })
}

export const getSubscription = (opts = {}) => {
  return {type: `SUBSCRIPTION/FETCH`, req: fetchSubscription, ...opts}
}

export const getSubscriptions = (opts = {}) => {
  return {type: `SUBSCRIPTIONS/FETCH`, req: fetchSubscriptions, ...opts}
}