import * as types from 'store/types'

const auth = (state = {}, action) => {
    switch (action.type) {
      case types.LOGGED_FAILED:
        return {
          ...state,
          error: action.error.response,
        };
      case types.LOGGED_SUCCESSFULLY:
        return {
          ...state,
          error: null,
          ...action.auth,
        };
      case types.LOGGED_OUT:
        return {
          ...state,
          token: null,
          user: null,
          accountConfirmed: false
        }
      case 'AUTH/ACCOUNT_CONFIRMED':
        return {
          ...state,
          accountConfirmed: true
        }
      case 'AUTH/CLOSE_CONFIRMATION_MESSAGE':
        return {
          ...state,
          accountConfirmed: false
        }
      default:
        return state;
    }
}

export default auth;
