import React               from 'react';
import { connect }         from 'react-redux';
import { withRouter }      from 'react-router';
import { ReactSVG }        from 'react-svg';
import { compose,
         setDisplayName,
         lifecycle,
         withState,
         withHandlers,
         withProps }       from 'recompose';
import cx                  from 'classnames';
import { parse }           from 'qs';
import Loader              from 'theme/Loader';
import Modal               from 'theme/Modal';
import Confirm             from 'theme/Confirm';
import Signature           from 'theme/Signature';
import CollapseComponent   from 'theme/CollapseComponent';
import { formatDate }      from 'theme/utils/helpers';
import { constants }       from 'theme/utils/constants';
import { withModal }       from 'theme/utils/recompose';
import { images }          from 'theme/img/images';
import LogoLink            from 'comments/LogoLink';
import CommentBox          from 'comments/CommentBox';
import Interactions        from 'comments/Interactions';
import { withComment }     from 'comments/recompose';
import { getPostComments,
         addPostComment,
         deleteComment,
         report,
         voteComment,
         updateComment }   from 'comments/actions';
import { actionSucceeded } from 'store/actions';

import './Comment.sass';

const extractId = id => id.split('/').pop();

const Comment = compose(
  setDisplayName('Comment'),
  withComment(),
  withModal(),
  withState('isEdited', 'setEdited', false),
  withState('textAfterEdition', 'setTextAfterEdition', ''),
  withState('timeout', 'setTime', null),
  withProps(({urlParams, post, authUser}) => ({
    refEl: React.createRef(),
    isReported: authUser?.isAdmin && urlParams?.commentReported && urlParams.commentId === post.id
  })),
  withHandlers({
    changeScrolledCommentColor: ({refEl}) => () => {
      if (refEl.current)
        refEl.current.style.background = 'white';
    }
  }),
  lifecycle({
    componentDidMount() {
      const { urlParams, refEl, post } = this.props;

      if (post && urlParams?.commentId === post.id)
        refEl.current.scrollIntoView({behavior: 'smooth', block: 'center'});
    },
    componentDidUpdate() {
      const { changeScrolledCommentColor, urlParams } = this.props;

      if (urlParams?.commentId) {
        setTimeout(() => {
          changeScrolledCommentColor();
        }, constants.COMMENT.TIMEOUT);
      }
    },
    componentWillUnMount() {
      clearTimeout(this.props.changeScrolledCommentColor);
      this.props.setEdited(false);
    }
  }),
  withProps(({post: { user }, authUser}) => ({
    me: authUser?.id === user.id,
    commentAuthorSignature: {
      'Service branch': user?.serviceBranch,
      'Military occupation': user?.mos,
      'EAS paygrade': user?.etsPaygrade,
      'User category': user?.userCategory
    }
  })),
  withHandlers({
    openModal: ({openModal}) => e => {
      e.stopPropagation();
      openModal();
    },
    reactToSubcomment: ({post, reactToSubcomment, setComments, getPostComments, currentCommentsPageSize}) => id => (e, reactionType) => {
      typeof e.stopPropagation === "function" && e.stopPropagation();

      return reactToSubcomment(id, reactionType)
      .then(() => {
        if (post.id !== id) {
          getPostComments({
            postId: post.id,
            page: 0,
            pageSize: currentCommentsPageSize,
            sort: 'createdAt:d'
          })
          .then(response => setComments(response.data));
        }
      });
    },
    report: ({report, post, createCommentReportingUrl, rootTopicType}) => id => () => {
      const handleParams = `commentId=${id}&threadId=${rootTopicType === 'review' ? extractId(post.rootTopicId) : post.threadId}&parentId=${extractId(post.id)}`;

      return report({
        itemId: id,
        url: createCommentReportingUrl(handleParams)
      });
    },
    updateComment: ({updateComment, getPostComments, setTextAfterEdition, setEdited, post, mediumLvlComment, setComments, comments}) => (values, commentId) => {
      return updateComment(values, commentId)
      .then(res => {
        getPostComments({postId: mediumLvlComment ? post.id : extractId(post.topic.id), page: 0, pageSize: comments.length, sort: 'createdAt:d'})
        .then(({data}) => setComments(data));

        setTextAfterEdition(res.data.content);
        setEdited(false);
      })
    },
    deleteComment: ({deleteComment, post, closeModal, history, displaySuccessMessage}) => () => {
      return deleteComment(post.id)
      .then(() => {
        closeModal();
        displaySuccessMessage('You have successfully deleted the comment.');
        history.push('/home');
      });
    },
    deleteSubcomment: ({deleteComment, closeModal, history, displaySuccessMessage}) => id => () => {
      return deleteComment(id)
      .then(() => {
        closeModal();
        displaySuccessMessage('You have successfully deleted the comment.');
        history.push('/home');
      });
    },
    updateSubcomment: ({updateComment, getPostComments, setEdited, post, mediumLvlComment, setComments, comments}) => (values, commentId) => {
      return updateComment(values, commentId)
      .then(() => {
        getPostComments({postId: mediumLvlComment ? post.id : extractId(post.topic.id), page: 0, pageSize: comments.length, sort: mediumLvlComment ? 'createdAt:d' : 'createdAt:a'})
        .then(({data}) => setComments(data));

        setEdited(false);
      })
    }
  })
)(({
  post: {content, createdAt, id, shortComment, votes, user},
  replied,
  setReplied,
  anchorId,
  toggleSubcomments,
  getPostComments,
  me,
  comments,
  commentsCount,
  reply,
  subcommentsVisible,
  expand,
  expanded,
  subcomment,
  reactToComment,
  reactToSubcomment,
  report,
  authUser,
  showMore,
  loadingComments,
  commentAuthorSignature,
  textAfterEdition,
  isEdited,
  history,
  setEdited,
  updateComment,
  updateSubcomment,
  mediumLvlComment,
  deleteComment,
  deleteSubcomment,
  displaySuccessMessage,
  urlParams,
  refEl,
  modal,
  closeModal,
  openModal,
  isReported,
  createCommentReportingUrl
}) => {
  const commentBoxProps = {
    reply: isEdited ? mediumLvlComment ? updateComment : updateSubcomment : reply,
    commentId: id,
    hasReplied: replied,
    setReplied
  };

  return (
    <div className="Comment" id={anchorId}>
      {user.organization
        ? <LogoLink {...user.organization} />
        : <div className={cx("Comment__avatar", {me})}>
            <img src={me ? images.personXsWhite : images.personBlack} className="userAvatar" alt="Avatar" />
          </div>
      }
      <div className="Comment__container">
        <div
          ref={refEl}
          className={cx("Comment__wrapper card", {me, scrolledTo: urlParams?.commentId === id})}
        >
          <div className={cx("Comment__heading", {orgPost: user.organization})}>
            {user.organization &&
              <span className="Comment__employerName">{user.organization?.name}</span>
            }
            <div className="Comment__headingBtns">
              <div className="Comment__dateMobile">
                <span>{formatDate(createdAt, ...Array(1), true)}</span>
              </div>
              {me &&
                <button
                  className={cx("Comment__startEditing btn-icon", {isEdited})}
                  onClick={() => setEdited(true)}
                >
                  <ReactSVG
                    src={images.editAlt}
                    aria-label="Edit post"
                  />
                </button>
              }
              {isReported &&
                <button
                  className="btn-icon-trash"
                  onClick={e => openModal(e)}
                >
                  <span className="fa fa-trash" />
                </button>
              }
            </div>
          </div>
          <div className="Comment__body">
            {isEdited
              ?
                <CommentBox
                  className="Comment__edit"
                  text="Save"
                  placeholder="Edit your comment..."
                  commentValue={textAfterEdition || content}
                  isCommentEdited
                  {...commentBoxProps}
                />
              :
                <CollapseComponent isOpened>
                  {expanded || !shortComment
                    ? <p className="Comment__text">{textAfterEdition || content}</p>
                    :
                      <div className="Comment__content">
                        {shortComment}
                        <div className="Comment__more" onClick={expand} />
                      </div>
                  }
                </CollapseComponent>
            }
          </div>

          <div className="Comment__footer">
            <div className="Comment__footerWrapper">
              {me
                ? <span className="Comment__signature">me</span>
                : user.organization ? '' : <Signature signature={commentAuthorSignature} />
              }
            </div>
            <div className="Comment__footerWrapper">
              <div className="Comment__vote">
                <Interactions
                  votes={votes}
                  react={subcomment ? reactToSubcomment(id) : reactToComment(id)}
                  report={report(id)}
                  itemType="comment"
                />
                {!subcomment &&
                  <div
                    className="Comment__reply"
                    onClick={toggleSubcomments}
                  >
                    <img src={images.comment} alt="reply" />
                    {commentsCount > 0
                      ? (commentsCount > 1 ? `${commentsCount} replies` : `${commentsCount} reply`)
                      : 'reply'
                    }
                  </div>
                }
              </div>
              <div className="Comment__date">
                <span>{formatDate(createdAt, ...Array(1), true)}</span>
              </div>
            </div>

          </div>
        </div>
        {subcommentsVisible &&
          <div className="Comment__replies">
            {commentsCount > comments.length  &&
              <div className="Comment__loadMore">
                <Loader loading={loadingComments} />
                {(comments.length >= constants.COMMENT.PAGE_SIZE) && (comments.length < commentsCount) &&
                  <div className="load load--comment" onClick={() => showMore()}>
                    <span>Load older replies</span>
                  </div>
                }
              </div>
            }
            <div className="Comment__repliesList">
              {comments?.map(c => (
                <Comment
                  key={c.id}
                  anchorId={c.id}
                  post={c}
                  toggleSubcomments={toggleSubcomments}
                  subcommentsVisible={subcommentsVisible}
                  getPostComments={getPostComments}
                  reactToSubcomment={reactToSubcomment(c.id)}
                  report={report(c.id)}
                  authUser={authUser}
                  history={history}
                  updateComment={updateSubcomment}
                  deleteComment={deleteSubcomment(c.id)}
                  displaySuccessMessage={displaySuccessMessage}
                  createCommentReportingUrl={createCommentReportingUrl}
                  subcomment
                />
              ))}
            </div>
            <CommentBox
              text="Reply"
              isReply
              {...commentBoxProps}
            />
            {subcomment &&
              <CommentBox
                text="Reply"
                isReply
                {...commentBoxProps}
              />
            }
          </div>
        }
      </div>

      <Modal
        isOpen={modal.visible}
        close={closeModal}
        closeBtn
      >
        <Confirm
          question="Are you sure that this comment violates the community rules and, in consequence, you want to remove it from the platform?"
          close={closeModal}
          action={deleteComment}
        />
      </Modal>
    </div>
  )
})

const mapDispatchToProps = dispatch => ({
  getPostComments: (args = {}) => new Promise((resolve, reject) => dispatch(getPostComments({...args, resolve, reject}))),
  reply: (values, postId) => dispatch(addPostComment({values, postId})),
  report: (args = {}) => dispatch(report(args)),
  reactToSubcomment: (id, action) => dispatch(voteComment(id, action)),
  updateComment: (values, commentId) => dispatch(updateComment({values, topicId: commentId})),
  deleteComment: id => dispatch(deleteComment(id)),
  displaySuccessMessage: message => dispatch(actionSucceeded(message))
});

export default withRouter(
  connect(
    () => ({ urlParams: parse(location.search.substring(1)) }),
    mapDispatchToProps
  )(Comment)
);
