import React                from 'react';

import './DetailsSection.sass';


const TextHtml = ({text}) => (
  <p>
    {text.map((t, i) => (
      <span key={i}>{t}</span>
    ))}
  </p>
)

export default function DetailsSection ({details}) {
  return(
    <div className="DetailsSection">
      <h4>Veteran Details</h4>
      {details.map((d, i) => (
        <div key={i}>
          <h6>{d && d.title}</h6>
          <TextHtml text={d && d.text} />
        </div>

      ))}
    </div>
  )
}
