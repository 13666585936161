export const required = value => (value || value === false) ? undefined : 'Required';
export const numeric = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Invalid email address'
  : undefined;

export const milEmail = value =>
  value && !/([a-z]|[A-Z]|[0-9]|\.|http\:\/\/|https\:\/\/|ws\:\/\/|wss\:\/\/)+\.((|mil|)|(|mil|)\.([a-z]|[A-Z])([a-z]|[A-Z]))(?=(\s|$|\/|\[|\]|\{|\}|\(|\)|\,|\;|\'|\"|\||\t|\n|\r))/i.test(value)
  ? 'Please enter a .mil address'
  : undefined;

export const url = value => value
  ? (/((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*(\.[a-zA-Z]{2,3}\/?))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/gm.test(value) ? undefined : 'Invalid url format or empty input')
  : undefined;

export const normalizeNumber = value => {
  const replaced = value.replace(',', '.');
  const commaLast = replaced.search(/\./) == value.length - 1;
  return (isNaN(Number(replaced)) || commaLast) ? replaced : parseFloat(Number(replaced));
}

export const phoneNumber = value => value && !/^\d{7,}$/i.test(value) ? 'Phone number should have at least 7 digits' : undefined;
