import React, { useState }   from 'react';
import { reduxForm,
         Field,
         FieldArray }        from 'redux-form';
import cx                    from 'classnames';
import { TextField,
         ImageField,
         ArrayField,
         VideoField }        from 'theme/Form';
import FunctionBtn           from 'theme/FunctionBtn';
import { url, required }     from 'theme/utils/validate';
import { images }            from 'theme/img/images';


const validate = values => {
  const errors = {};

  if(!values.text) {
    errors.text = 'Required';
  }
  if(!values.images) {
    errors.images = 'Required';
  }
  if(!values.video) {
    errors.video = 'Required';
  }

  return errors;
}

export default reduxForm({
  enableReinitialize: true,
  validate
})(
  ({handleSubmit,
    pristine,
    submitting,
    title,
    btnText,
    submitFailed,
    invalid
  }) => {
  const [postType, setPostType] = useState('Text');

  return (
    <div className="AdminPosts__form">
      <h3>{title}</h3>
      <form onSubmit={handleSubmit}>
        <Field
          name="text"
          className={cx('AdminPosts__formText', {videoDescription: postType === 'Video'})}
          component={TextField}
          componentType="markdown"
          required
        />
        {postType === 'Image' &&
          <FieldArray
            name="images"
            label="Images"
            placeholder="http://some-picture.png"
            component={ArrayField}
          >
            <Field
              name=""
              component={ImageField}
              validate={url || required}
            />
          </FieldArray>
        }
        {postType === 'Video' &&
          <VideoField name="video" className="Form__field AdminPosts__video" />
        }
        <div className="AdminPosts__formBtns">
          {(postType === 'Image' || postType === 'Video') &&
            <FunctionBtn
              img={{src: images.edit, alt: 'Add text post'}}
              text="Add text post"
              action={() => setPostType('Text')}
            />
          }
          {(postType === 'Text' || postType === 'Video') &&
            <FunctionBtn
              img={{src: images.image, alt: 'Add image'}}
              text="Add image"
              action={() => setPostType('Image')}
            />
          }
          {(postType === 'Text' || postType === 'Image') &&
            <FunctionBtn
              img={{src: images.film, alt: 'Add video'}}
              text="Add video"
              action={() => setPostType('Video')}
            />
          }
        </div>
        {submitFailed &&
          <div className="AdminPosts__error">Please check if you filled all required fields.</div>
        }
        <div className="AdminPosts__formSave">
          <button
            type="submit"
            className="btn-primary save"
            disabled={pristine || submitting || invalid}
          >
            {btnText || 'Publish'}
          </button>
        </div>
      </form>
    </div>
  )}
)
