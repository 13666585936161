import React, { useEffect } from 'react';
import { Helmet }           from 'react-helmet';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';

import './Policy.sass';


export default function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Policy page">
      <Helmet>
        <title>Vetit — Privacy & Cookie Policy</title>
      </Helmet>
      <Header />
      <div className="container">
        <div className="vertical-card">
          <h1>Privacy & Cookie Policy</h1>

          <p>
            We, Vet It, LLC, a Texas Limited Liability Company, explain in this Privacy and Cookie Policy how we process information that we receive via our website and mobile applications (collectively "Vet It") that link to this Privacy and Cookie Policy, as amended from time to time.
            This Privacy and Cookie Policy applies only to information we collect through Vet It.
            This Privacy and Cookie Policy does not apply to information that we collect through other channels, such as information that we collect offline, from other websites or from emails you send us.
          </p>

          <h3>Information We Collect and How We Use It</h3>
          <h4>Personal Information that You Provide Us Actively</h4>
          <p>
            When you fill out registration forms, submit your resume or provide us with other information actively, you will know what information we are collecting because you are actively submitting it.
            Because we change our offerings and features from time to time, the options you have to provide us with personal information also may change, but here are some examples of situations in which you may decide to provide personal information to us:
          </p>
          <ul>
            <li>Creating an account;</li>
            <li>Signing up for email alerts;</li>
            <li>Completing a form related to one of our products or services;</li>
            <li>Uploading a resume;</li>
            <li>Contacting us for technical support or customer service.</li>
          </ul>

          <p>
            Depending on how you interact with Vet It, the personal information we collect from you may vary.
            For example, to create an account we may ask only for an email address and password.
            In other circumstances, such as when you complete a form related to a particular service offering, we may ask you to provide other information, which may include your name, phone number, and/or postal address.
            On a voluntary basis, you may also decide to provide us more sensitive information such as your social security number; driver's license or other government-issued identification number, or payment information, including a bank account, credit or debit card number.
            You should not include sensitive data (such as, social security number, driver's license number, credit or debit card numbers, etc.) in any resume you upload to Vet It or submit it in any other scenarios, except where we specifically prompt you to provide such information.
            If you connect to Vet It using Facebook, LinkedIn, or another social networking site (each a "Social Networking Site"), we will receive information that you authorize the Social Networking Site to share with us, including information about your friends and connections on that Social Networking Site.
            Any information that we collect from your Social Networking Site account may depend on the privacy settings you have set with the Social Networking Site, so please consult the Social Networking Site's privacy and data practices.
          </p>

          <h4>Information We Collect by Automated Means</h4>
          <p>
            When you use Vet It, your device is automatically providing information to us so we can respond and customize our response to you.
            The type of information we collect by automated means may vary, but generally includes technical information about your computer, such as your IP address or other device identifier.
            The information we collect also may include usage information and statistics about your interaction with Vet It.
            That information may include the URLs of our web pages that you visited, URLs of referring and exiting pages, page views, time spent on a page, number of clicks, platform type, location data, and other information about how you used Vet It.
          </p>
          <p>Automated means of data collection include the following:</p>
          <ul>
            <li>
              <span>
                <strong>Web Browser - </strong>
                When you visit a Vet It website, your browser automatically sends us your Internet protocol ("IP") address so that the web pages you request can be sent to your computer or device.
                We use your IP address to determine additional information, such as whether the computer or device has ever been used to visit Vet It before, which Vet It features were used the most often, and how much time was spent on a page.
              </span>
            </li>
            <li>
              <span>
                <strong>Web Beacons and Log Files - </strong>
                We use "web beacons" (also known as Internet tags, pixel tags, tracking pixels, and clear GIFs) on our websites.
                These web beacons allow us and third parties to collect information such as the IP address of the device, the URL of the web page, the time the page was viewed, the type of browser used, and any additional information in cookies that may have been set by the third party.
                The information is typically collected in log files, which generally track traffic on our websites.
                We use this information for a variety of purposes, including the management of our online advertising by establishing which ads brought users to our websites.
                The information also is used for analytical purposes and to manage technical issues that may arise.
                We do not attempt to identify individual users through this information.
              </span>
            </li>
            <li>
              <span>
                <strong>Location Data - </strong>
                If you access Vet It using a mobile device, we will collect your physical location if you have enabled this feature.
                Even if you are not using a mobile device, information about your general location may be discernable from your device's IP address or the URLs we receive.
              </span>
            </li>
            <li>
              <span>
                <strong>Cookies - </strong>
                We also collect information using cookies to help us identify you when you visit Vet It and we may share cookies with third-party advertisers or advertising networks to serve you relevant, targeted ads.
                For more information on cookies and similar tracking mechanisms, how we use them, and how you can control them, please see the section entitled Cookie Policy and Ad Choices.
              </span>
            </li>
          </ul>

          <h4>Information Third Parties Collect from Vet It</h4>
          <p>
            Via "web beacons" (also known as Internet tags, pixel tags, tracking pixels, and clear GIFs) on our website, third parties can collect information such as the IP address of the device, the URL of the web page, the time the page was viewed, the type of browser used, and any additional information in cookies that may have been set by the third party.
            If you respond to ads posted by third parties or submit information to third parties via Vet It, such third parties receive information about you subject to their privacy policies.
            If you come to Vet It through social media sites, from other websites or with devices that enable third parties to collect information from or about you, such third parties receive information about you subject to their privacy policies.
          </p>

          <h4>Do Not Track Signals</h4>
          <p>
            We do not currently respond to 'do not track' signals and similar settings or mechanisms.
            When you use Vet It, we try to provide a customized experience.
          </p>

          <h4>How We Use Information</h4>
          <p>We may use the information we collect:</p>
          <ul>
            <li>to provide you with personalized content and services;</li>
            <li>to moderate and display the employer reviews, interview reviews, salary reports and other content you have submitted for the benefit of our other users;</li>
            <li>to customize and improve the features, performance, and support of the site;</li>
            <li>to show you relevant information, if you connect to Vet It through a Social Networking Site, from your Social Networking Site friends and connections, and to allow you to share job and profile information with them;</li>
            <li>to provide relevant advertising, including interest-based advertising from us and third parties, which may mean that we share non-personally identifiable information, such as your job title, to third-party advertisers;</li>
            <li>to provide recruiting services to employers;</li>
            <li>for internal operations, including troubleshooting, data analysis, testing, research, and service improvement;</li>
            <li>to communicate with you regarding our services;</li>
            <li>to analyze use of Vet It and improve Vet It;</li>
            <li>to create aggregate and statistical data that does not identify you individually and that we can commercialize;</li>
            <li>for other purposes that you separately authorize as you interact with Vet It.</li>
          </ul>

          <h4>How We Share Information</h4>
          <p>We do not disclose your individual account or usage data to third parties, except as follows:</p>
          <ul>
            <li>With your consent;</li>
            <li>We use affiliated and unaffiliated service providers all over the world that help us deliver our service and run our business subject to strict confidentiality agreements;</li>
            <li>We share aggregated usage statistics that cannot be used to identify you individually;</li>
            <li>We will disclose data if we believe in good faith that such disclosure is necessary (1) to comply with relevant laws or to respond to subpoenas or warrants or legal process served on us; (2) to enforce our Vet It Terms of Use and Privacy Policy; (3) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity; or (4) as we otherwise deem necessary to protect and defend the rights or property of us, the users of our services, or third parties. Our general procedure with respect to civil subpoenas requesting user data is to require a court order, binding on Vet It, before we release such information;</li>
            <li>In a reorganization or sale of our company or assets, your data may be transferred, subject to the acquirer accepting the commitments made in this Statement and compliance with applicable law;</li>
            <li>If you apply for a particular job through Vet It, we will disclose your personal information to the employer to whom you apply;</li>
            <li>We may invite you to include your resume or other information in more generally available formats. For example, we may introduce services for employers, recruiters and others that rely on accessing our database of user information. Subject to visibility settings that you control, subscribers to those services will be able to view information that you post;</li>
            <li>We may share certain information that does not identify you personally, but which is unique to your use of Vet It, such as job titles that you have entered at the time of registration or on your profile; when we share such information, we do not link job titles with your name when we provide this information to others, such as advertisers, recruiters or employers.</li>
          </ul>

          <h4>Cookie Policy and Ad Choices</h4>
          <p>This Cookie policy describes how Vet It, LLC ("we", "our", "us") uses Cookies and similar technologies. For more information on our general data privacy practices, please see our Privacy Policy. Cookies</p>
          <p>
            Cookies are small pieces of data that are stored on your computer, mobile phone, or other device when you first visit a page.
            We use cookies, web beacons, locally shared objects (sometimes called "flash cookies"), mobile identifiers and similar technologies ("Cookies") to help us recognize you on Vet It, enhance your user experience, understand Vet It usage, and show you relevant advertising.
            By visiting Vet It, you consent to their placement of in your browser in accordance with this Privacy and Cookie Policy.
          </p>

          <h4>When do we use Cookies?</h4>
          <p>
            We may set Cookies when you visit Vet It.
            They may also be set by other websites or services that run content on the page you're visiting.
            After you register on Vet It, we may connect information stored in Cookies with other information received from you.
          </p>

          <h4>What types of Cookies do we use?</h4>
          <p>
            We use two types of Cookies on Vet It: "session cookies" and "persistent cookies."
            Session Cookies are temporary Cookies that remain on your device until you leave Vet It.
            A persistent Cookie remains on your device for much longer until you manually delete it (how long the Cookie remains will depend on the duration or "lifetime" of the specific Cookie and your browser settings).
          </p>

          <h4>What are Cookies used for?</h4>
          <p>
            Cookies may transmit information about you and your use of Vet It, such as your browser type, search preferences, job titles, data relating to advertisements that have been displayed to you or that you have clicked on, and the date and time of your use.
            The data stored in Cookies is anonymous and is not linked to your personally identifiable information without your permission.
            Cookies may link to certain unique, non-personally identifiable information such as the job titles that you entered at time of registration or on your profile, but this is not linked to your name or any personally identifiable information.
          </p>

          <h4>We use Cookies for things like:</h4>
          <p>
            <strong>Authentication: </strong>
            We use Cookies to help us determine whether or not you've signed in to Vet It and to keep you signed in during visits as you access different pages. 
          </p>
          <p>
            <strong>Security: </strong>
            We use Cookies to enable and support security features, prevent fraud, and protect your data from unauthorized access. 
          </p>
          <p>
            <strong>Preferences and Features: </strong>
            We use Cookies to enable features, help us provide you with personalized content such as showing you your recent search activity. 
          </p>
          <p>
            <strong>Advertising: </strong>
            We use Cookies to deliver, evaluate and improve advertising, such as by using information about you to provide relevant advertising both on and off Vet It. Our partners may use a cookie to determine whether you've already been shown an ad or how it performed, or provide us with information about how you interacted with an ad. To learn more about our partners and their use of Cookies, click here. 
          </p>
          <p>
            <strong>Analytics and Performance: </strong>
            We use Cookies to analyze how our visitors use Vet It and to monitor site performance. These Cookies help us to identify and fix errors, understand and improve services, research and test out different features, and monitor how our visitors reach our sites.
          </p>

          <h4>Ad Choices and Managing Cookies</h4>
          <p>Vet It works with several third parties to provide you with personalized, interest-based advertising. We may target ads to you on and off Vet It using:</p>
          <ul>
            <li>Cookies (both on and off Vet It);</li>
            <li>Location information, to the extent you have enabled location tracking on your mobile device;</li>
            <li>Member-provided profile information;</li>
            <li>Your use of Vet It (for example, your Vet It search history);</li>
            <li>Information from third-parties (including advertising partners, publishers, and data aggregators) which we use to help deliver relevant ads to you.</li>
          </ul>
          <p>
            We work with third parties, such as Google Analytics, to provide analytics services that may use the Cookies set on your device to measure the performance of advertising and track traffic to Vet It generally
            In addition, we have implemented Google Analytics Demographics and Interest Reporting, which categorizes cookie information so that we and third-party service providers can better deliver advertisements that are relevant and useful to you on Vet It and various websites across the internet
            You may opt out of tracking by Google Analytics and Google Analytics Demographics and Interest Reporting services by visiting Google Ads Settings or by downloading the Google Analytics Opt-Out Browser Add-on
          </p>
          <p>
            You may be able to opt out of other third-party advertiser and ad network placement of Cookies or targeted advertising by visiting the following online resources: Network Advertising Initiative; Digital Advertising Alliance; European Interactive Digital Advertising Alliance (for users in the EU); The Digital Advertising Alliance of Canada (for users in Canada); or by following opt-out instructions of the advertising networks, analytics providers and other advertising third parties we work with listed here
            You will continue to receive generic ads by companies not listed with these opt-out tools
          </p>
          <p>
            You may also be able to disable placement of some (but not all) Cookies by setting your browser to decline cookies, though this may worsen your user experience
            If you delete your browser cookies, your opt-out cookie will also be deleted
            Additionally, if you change computers or web browsers, you will need to opt out again
            A useful resource for information about deleting and controlling cookies can be found at AboutCookies.org
          </p>
          <p>
            If you enable location data for the mobile version of Vet It (including any version installed as a native application), you are expressly agreeing that we may use your location data (after de-identifying it) to serve you geo-targeted ads and offers for businesses or employers that are local to you
            In such instances, we do not share your location with the advertiser or advertising network, rather, we provide the advertiser or advertising network with a means to push ads through to users located in certain areas or zip codes
            You may disable location services at any time in your "settings".
          </p>
          <p>
            <strong>Please note: </strong>
            if ads or other features on Vet It are provided by third parties, those parties also may set and use their own Cookies that are subject to those third parties' privacy notices and policies
            Vet It does not have access to, or control over, these Cookies.
          </p>

          <h3>Other Important Privacy Information</h3>
    {/*   <h4>Choices Regarding Your Personal Information</h4>
    */}
    {/*
          <p>
            You can manage your account settings in the "Account Settings" page on Vet It.
            We provide you with the opportunity to 'opt-out' of having your personally identifiable information used for certain purposes and to unsubscribe from newsletters and marketing emails.
          </p>
    */}
    {/*
          <p>
            We may send you notifications, promotional communications, or other messages using the contact information (e.g., your e-mail address) you provided to us when you registered or when you requested information from us.
            You may opt-out of continuing to receive optional messages by following the instructions included in each message.
            Also, you can control most of the e-mails you receive from us by editing your e-mail preferences within "My Account" settings on the site.
            You release us from any responsibility for communications you do not receive.
        </p>
    */}
          <p>
          We may send you service-related announcements when we believe it is necessary to do so.
            Generally, you may not opt-out of these announcements, which are not primarily promotional in nature.
            If you do not wish to receive these announcements, you have the option to deactivate your account.
            In some cases, you can stop or limit the information we collect by automated means.
            To learn more about how you may be able to stop or limit our receipt of that information please review the section entitled Cookie Policy and Ad Choices.
          </p>

          <h4>Closing Your Account</h4>
          <p>
            If you'd like to delete your Vet It account entirely, navigate to your account settings and click the "Delete Account" button.  Vet It will remove your profile within 30 days. Data for dormant accounts will be depersonalized after 2 years.
            Upon account deletion you will no longer have full access to salaries, reviews, and interviews.
            Any personal information collected will be deleted from our active databases but may remain in our archives for legal and back-up purposes, as permitted by applicable law.
          </p>

          <h4>Security Safeguards</h4>
          <p>
            <strong>Security. </strong>
            We employ physical, electronic, and managerial measures to safeguard the information we collect online.
            However, no company can fully eliminate security risks, so we cannot make guarantees about any part of our services.
            You are responsible for keeping your username and password secret.
            Once you have registered with us, we will never ask you for your password.
            Please create a unique password for Vet It and do not use it for any other web services or applications.
            Do not share your password with anyone else.
          </p>
          <p>
            If you enter credit card information on Vet It in connection with a purchase, that information is sent directly from your browser to the third-party service provider we use to manage credit card processing and we do not store it on our servers.
            The service provider is not permitted to use the information you provide except for the sole purpose of credit card processing on our behalf.
          </p>
          <p>
            <strong>Tips For A Responsible Job Search. </strong>
            We actively monitor Vet It for job postings that violate our Terms of Use.
            If we detect fraudulent activity, we remove the job posting from Vet It.
            However, fraudulent job postings can sometimes appear and we urge to you exercise caution in your job search.
            Please consider the following tips when conducting your search:
          </p>
          <ul>
            <li>Do not send money to a prospective employer.</li>
            <li>Do not give out personal or financial account information to a new employer via email.</li>
            <li>Do not cash checks or transfer funds for your employer.</li>
            <li>Beware of job postings that ask you to send your resume to a free email address.</li>
            <li>Do not include sensitive information like your social security number in your resume.</li>
            <li>Beware of job listings with spelling errors and bad grammar.</li>
            <li>If something sounds too good to be true, it probably is.</li>
            <li>Do your homework. Be sure to research any job opportunity that interests you.</li>
          </ul>
          <p>
            If you see a suspicious job posting, please contact us here.
            If you think you have been the victim of fraud, immediately report the fraud to your local police and contact Vet It.
            We also recommend that you file an online report with The Internet Crime Complaint Center (IC3).
          </p>
          <p>
            <strong>Privacy of Minors.</strong>
            Vet It is not intended for minors.
            Minors must obtain parental consent to use Vet It.
            Minors under 17 years of age are expressly prohibited from using Vet It or providing any personal information.
            If you become aware that a child has provided us with personal information without parental consent, please contact us here.
            If we become aware that a child under 17 has provided us with personal information without parental consent, we remove such information and terminate the child's account.
          </p>

          <h4>Texas Residents</h4>
          <p>
            Texas Civil Code permits our users who are Texas residents to request and obtain from us a list of what personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year and the names and addresses of those third parties.
            Requests may be made only once a year and are free of charge.
          </p>

          <h4>Data Controller / EU Safe Harbor</h4>
          <p>
            The information about you that we collect through Vet It is controlled by Vet It, LLC, Austin, Texas.
            This information is stored in the United States.
            Vet It complies with the U.S.-E.U.and U.S.-Swiss Safe Harbor Privacy Policy Principles of notice, choice, onward transfer, security, data integrity, access and enforcement as further explained in our General EEA Safe Harbor Notice.
            To learn more about the Safe Harbor Program, and to view our certification, please visit the U.S.Department of Commerce Safe Harbor website.
          </p>

          <h4>Special Event Notifications: Privacy Policy Updates, Data Breaches, and Business Transfers</h4>
          <p>
            Special events - policy updates, data breaches, and business transfers - will be posted here and notifications will be sent to registered emails. Furthermore, in such cases, users will be asked to Agree to Vet It's terms of use and privacy policy again during their next sign in.      
          </p>

          <h4>Contact Us</h4>
          <p>
            If you have any questions or suggestions regarding our Privacy and Cookie Policy please contact us by sending us an email to <a href="mailto:admin@vetitforward.com">admin@vetitforward.com</a>
          </p>

        </div>
      </div>
      <Footer />
    </div>
  );
}
