import React        from 'react';
import { Link }     from 'react-router-dom';
import cx           from 'classnames';
import Loader       from 'theme/Loader';
import Pagination   from 'theme/Pagination';
import JobRow       from 'jobs/JobRow';

import './CompanyJobs.sass';


export default function CompanyJobs({jobs: {matched, list}, employer, user, className, activeSection, handleSave, viewJob, jobsCount, searchJobs, loading, activePage}) {
  const jobProps = j => ({
    job: j.jobOpening,
    employer,
    handleSave,
    viewJob,
    user,
    link: `/companies/${employer.id}/${employer.slug}/${activeSection}/${j.jobOpening.id}`
  });

  return (
    <div className={cx("CompanyJobs", className)}>
      <div className="CompanyJobs__left">
        <Loader loading={loading} />
        <div className="CompanyJobs__list">
          <h4 className="best">Jobs to consider</h4>
          {matched?.map(j => {
            return (
              <JobRow
                key={j.jobOpening.id}
                {...jobProps(j)}
                recommended
              />
            )
          })}
        </div>
        <div className="CompanyJobs__other">
          {list?.length > 0 &&
            <h5 className="CompanyJobs__otherText">You may also like</h5>
          }
          {list?.data.map(j => (
            <JobRow
              key={j.jobOpening.id}
              activeSection={activeSection}
              {...jobProps(j)}
            />
          ))}
          <Pagination
            itemsCount={jobsCount}
            gotoPage={searchJobs}
            disabled={loading}
            activePage={activePage}
          />
        </div>
      </div>
      <div className="CompanyJobs__right flex-start">
        <div className="card">
          <h5>Want to learn more about working at {employer.name}?</h5>
          <Link
            className="btn-primary"
            to={`/companies/${employer.id}/${employer.slug}/discussion`}
          >
            Ask a question
          </Link>
        </div>
      </div>
    </div>
  )
}
