import api               from 'api';
import { formatDate }    from 'theme/utils/helpers';
import { omit }          from 'underscore';

function fetchUsers({query, page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/users',
    params: {query, page, pageSize}
  });
}

function fetchUser({id}) {
  return api({
    method: 'get',
    url: `/users/${id}`
  });
}

export const updateUser = ({id, values}) => dispatch => {
  const formattedDate = values.etsSeparationDate && formatDate(values.etsSeparationDate, 'yyyy-MM-dd');

  return api({
    method: 'patch',
    url: `/users/${id}`,
    data: {
      ...omit(values, 'organization'),
      etsSeparationDate: formattedDate,
      organizationId: values.organizationId?.value || values.organizationId,
      championTopicIds: values.championTopics.reduce((a, b) => a.concat(b.value), [])
    }
  })
  .then(() => dispatch(getUser(id)));
}

export const onboardUser = id => () => {
  return api({
    method: 'patch',
    url: `/users/${id}`,
    data: {
      isOnboarded: true
    }
  });
}

export const deleteUserOrganization = id => (dispatch) => {
  return api({
    method: 'delete',
    url: `/users/${id}/organizationId`
  })
  .then(() => dispatch(getUser(id)));
}

export const verifyUser = userId => () => {
  return api({
    method: 'post',
    url: `/users/${userId}/verify?method=AdminUpdate`,
    data: {
      verify: true
    }
  })
}

export const deleteUser = id => {
  return api({
    method: 'delete',
    url: `/users/${id}`
  })
}

export const getUsers = ({query, page, pageSize}) => {
  return {type: 'USERS/FETCH', req: fetchUsers, query, page, pageSize}
}

export const getUser = (id) => {
  return {type: `USER:${id}/FETCH`, req: fetchUser, id}
}
