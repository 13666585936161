import React                    from 'react';
import cx                       from 'classnames';
import {CSSTransition}          from 'react-transition-group';

import './Loader.sass';


export default function Loader ({loading, className}) {
  return (
    <CSSTransition
      classNames="fade"
      in={loading}
      appear
      unmountOnExit
      timeout={{exit: 500, enter: 0}}
    >
      {() => (
        <div className={cx("Loader", className)}>
          <div className="Loader__container">
            <div className="dot1" />
            <div className="dot2" />
            <div className="dot3" />
          </div>
        </div>
      )}
    </CSSTransition>
  )
}
