import React                from 'react';
import {Link}               from 'react-router-dom';

import './EmptyReviews.sass';


export default function EmptyReviews ({manager, link, header,  reviewName}) {
  return (
    <div className="EmptyReviews">
      <h4>
        {header || `Looks like ${manager.name} has no ${reviewName}.`}
      </h4>
      <p>Be the first to write one!</p>
      <Link className="btn-primary" to={link}>+ ADD A REVIEW</Link>
    </div>
  )
}