import api from 'api';

function fetchPremium({limit = 5}) {
  return api({
    method: "get",
    url: "/premium-exposure",
    params: {limit}
  })
  .then((response) => {
    const {employers, educators} = response.data;
    return [].concat(employers, educators).filter(c =>
      c.premiumFeatures && Object.keys(c.premiumFeatures).length > 0 && c.premiumFeatures.imgUrl
  )});
}

export const getPremiumOrganizations = ({limit, loader, resolve, reject} = {}) => {
  return {type: 'PREMIUM/FETCH', limit, req: fetchPremium, loader, resolve, reject}
}
