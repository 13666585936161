


import React, { useState } from 'react';
import { ReactSVG }        from 'react-svg';
import cx                  from 'classnames';
import { images }          from 'theme/img/images';

import './CustomCarousel.sass';

export default function CustomCarousel({ children }) {
  const [current, setCurrent] = useState(0);
  const length = children.length;

  const nextSlide = () => setCurrent(current === length - 1 ? 0 : current + 1);
  const prevSlide = () => setCurrent(current === 0 ? length - 1 : current - 1);

  if (!Array.isArray(children) || children.length <= 0) {
    return null;
  }

  return (
    <div className="CustomCarousel">
      <button className='CustomCarousel__arrow CustomCarousel__arrow--left' onClick={prevSlide}>
        <ReactSVG src={images.chevronLeftGray} />
      </button>
      <div className="CustomCarousel__container">
        {children.map((item, index) => {
          return (
            <div
              className={cx('CustomCarousel__slide', {active: index === current, })}
              key={index}
            >
              {index === current && (
                item
              )}
            </div>
          )
        })}
      </div>
      <button className='CustomCarousel__arrow CustomCarousel__arrow--right' onClick={nextSlide}>
        <ReactSVG src={images.chevronRightGray} />
      </button>
      <div className="CustomCarousel__dots">
        {Array(length).fill().map((count, i) => (
          <div key={i} className={cx("CustomCarousel__dot", {active: current === i})} />
        ))}
      </div>
    </div>
  );
}