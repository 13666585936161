import React        from 'react';
import ManagerLogo  from 'theme/ManagerLogo';

import './PartnersBox.sass';

export default function PartnersBox({partners}) {
  return (
    <div className="PartnersBox">
      <h5 className="subheader">Military Connected Partners</h5>
      <div className="PartnersBox__container">
        {partners?.map(p => (
          <a
            key={p.id}
            href={p.hrefUrl}
            target="_blank"
          >
            <ManagerLogo manager={p} size='sm' />
          </a>
        ))}
      </div>
    </div>
  )
}
