import { compose,
         withState,
         withHandlers,
         withStateHandlers,
         lifecycle,
         withProps }      from 'recompose';
import debounce           from 'debounce-promise';

const SEARCH_DEBOUNCE = 500;


export default function ({
  queryProp = 'query',
  debouncedName = 'searchDebounced',
  functionName = 'search',
  withLifecycle = false
} = {}) {
  return compose(
    ...[
      withProps(props => ({
          [debouncedName]: debounce(props[functionName], SEARCH_DEBOUNCE)
      })),
      withLifecycle && lifecycle({
        componentDidMount() {
          return this.props[functionName](this.props[queryProp]);
        },
        componentDidUpdate(prevProps) {
          if (prevProps[queryProp] !== this.props[queryProp])
            return this.props[debouncedName](this.props[queryProp]);
        }
      })
    ].filter(Boolean)
  )
}

export function withModal() {
  return compose(
    withStateHandlers(
      {modal: {visible: false}},
      {
        openModal: () => (name, payload) => {
          return {modal: {name, payload, visible: true}}
        },
        closeModal: ({modal}) => () => {
          return {modal: {...modal, visible: false}}
        },
      }
    )
  )
}

export function withScroll() {
  return compose(
    lifecycle({
      componentDidMount() {
        window.scrollTo(0, 0);
      }
    })
  )
}

export function withSignature() {
  return compose(
    withProps(({userProfile, candidateProfile}) => {
      const profile = candidateProfile || userProfile;
      const mostRecentService = profile?.militaryServices?.slice().sort((a, b) => a.createdAt - b.createdAt).pop();

      return {
        userSignature: {
          'Military occupation': mostRecentService?.mos,
          'Service branch': mostRecentService?.serviceBranch,
          'EAS paygrade': mostRecentService?.etsPaygrade,
          'User category': profile.userCategory
        }
      }
    })
  )
}

export function withPasswordToggle() {
  return compose(
    withState('visiblePasswordFields', 'setVisiblePasswordFields', []),
    withHandlers({
      checkPasswordVisible: ({visiblePasswordFields}) => name => visiblePasswordFields.includes(name)
    }),
    withHandlers({
      togglePassword: ({visiblePasswordFields, setVisiblePasswordFields, checkPasswordVisible}) => name => {
        const newFields = checkPasswordVisible(name)
          ? visiblePasswordFields.filter(f => f !== name)
          : visiblePasswordFields.concat(name);

        setVisiblePasswordFields(newFields);
      }
    })
  )
}

export function withGallery() {
  return compose(
    withState('imageIndex', 'setImageIndex', 0),
    withHandlers({
      prev: ({setImageIndex}) => (images, index) => {
        if (index === 0)
          setImageIndex(images.length - 1);
        else setImageIndex(index -= 1);
      },
      next: ({setImageIndex}) => (images, index) => {
        if (index === images.length - 1)
          setImageIndex(0);
        else setImageIndex(index += 1);
      },
      openGallery: ({openModal, setImageIndex}) => post => index => {
        openModal('GALLERY', post);
        setImageIndex(index);
      }
    })
  )
}

export function withStaticFormSections() {
  return compose(
    withState('activeEditedSections', 'setEditedSections', []),
    withHandlers({
      toggleEditedSections: ({activeEditedSections, setEditedSections}) => name => {
        const isSectionEdited = activeEditedSections.includes(name);

        const newEdited = isSectionEdited
          ? activeEditedSections.filter(sectionName => sectionName !== name)
          : activeEditedSections.concat(name);

        setEditedSections(newEdited);
      }
    }),
    withProps(({activeEditedSections}) => ({
      isEdited: name => activeEditedSections.includes(name)
    }))
  )
}

export function withScreenWidthDetection() {
  return compose (
    withState('screenWidth', 'resizeWindow', window.innerWidth),
    withHandlers({
      updateDimensions: ({resizeWindow}) => () => resizeWindow(window.innerWidth)
    }),
    lifecycle({
      componentDidMount() {
        window.addEventListener('resize', this.props.updateDimensions);
      },
      componentWillUnmount() {
        window.removeEventListener('resize', this.props.updateDimensions);
      }
    })
  )
}
