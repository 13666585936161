import React, { useState } from 'react';
import CreatableSelect     from 'react-select/creatable';

import './CreatableInput.sass';


const components = {
  DropdownIndicator: null
};

const createOption = label => ({
  label,
  value: label
});

export default function CreatableInput({validate, placeholder =  "Type something and press enter..."}) {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  const handleChange = value => {
    setValue(value);
    validate(value);
  };

  const handleInputChange = inputValue => setInputValue(inputValue);

  const handleKeyDown = event => {
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
        handleInputChange('');
        handleChange([...value, ...inputValue.split(' ').map(item => createOption(item))])
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      className="CreatableInput"
      classNamePrefix="RSelect"
      components={components}
      inputValue={inputValue}
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
    />
  );
}
