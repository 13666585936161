import React            from 'react';
import { Link }         from 'react-router-dom';
import { ReactSVG }     from 'react-svg';
import { reduxForm,
         Field,
         FieldArray }   from 'redux-form';
import cx               from 'classnames';
import { TextField,
         Select,
         Radio,
         ArrayField,
         ImageField }   from 'theme/Form';
import { options }      from 'theme/content';
import { images }       from 'theme/img/images';
import { url }          from 'theme/utils/validate';

import './BasicProfileForm.sass';


export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit,
    submitting,
    partnerOptions,
    filterOptions,
    organizationProfile
  }) => {
    return (
      <div className="BasicProfileForm">
        <form onSubmit={handleSubmit}>
          <section>
            <Field
              name="name"
              className="medium-field"
              label="Company name"
              component={TextField}
              placeholder="Type company name..."
            />
            <Field
              name="url"
              className="medium-field"
              label="Website"
              placeholder="http://www.example.com"
              component={TextField}
              validate={url}
            />
            <Field
              name="address.city"
              className="medium-field"
              label="City"
              placeholder="Irving"
              component={TextField}
            />
            <Field
              name="address.state"
              className="medium-field"
              label="State"
              placeholder="Choose a state"
              component={Select}
              options={options.states}
            />
            <Field
              name="address.country"
              className="medium-field"
              label="Country"
              placeholder="Choose a country"
              component={Select}
              options={options.countries}
            />
            <Field
              name="logo"
              className="BasicProfileForm__logo"
              label="Logo URL"
              component={ImageField}
              placeholder="http://some-picture.png"
              validate={url}
            />
            <FieldArray
              name="partners"
              className="BasicProfileForm__arrayField"
              label="Support Services"
              component={ArrayField}
            >
              <Field
                component={Select}
                name=""
                placeholder="Select a support service"
                options={partnerOptions}
                filterOptions={filterOptions.partners}
              />
            </FieldArray>
          </section>
          <section>
            <Field
              name="organizationSize"
              className="medium-field"
              label="Organization Size"
              placeholder="Select"
              component={Select}
              options={options.orgSizeOptions}
            />
            <Field
              name="type"
              className="medium-field"
              label="Type"
              placeholder="Select"
              component={Select}
              options={options.companyTypes}
            />
            <Field
              name="industry"
              className="medium-field"
              label="Industry"
              placeholder="Select"
              component={Select}
              options={options.industries}
            />
            <Field
              name="affinityGroupSize"
              className="medium-field"
              label="Affinity Group Size"
              placeholder="Select"
              component={Select}
              options={options.affinityGroupSizeOptions}
            />
            <Field
              name="founded"
              className="short-field"
              label="Founded"
              placeholder="e.g. 1993"
              component={TextField}
            />
          </section>
          <section className="BasicProfileForm__indicators">
            <h5>Indicators</h5>
            <div>
              <Field
                name="indicators.dodSkillbridgeReady"
                label="DOD Skillbridge Ready"
                mode="checkbox"
                component={Radio}
              />
              <Field
                name="indicators.executiveSponsorship"
                label="Executive Sponsorship"
                mode="checkbox"
                component={Radio}
              />
              <Field
                name="indicators.militaryOnboardingProcess"
                label="DOL Apprenticeships"
                mode="checkbox"
                component={Radio}
              />
              <Field
                name="indicators.mentorshipProgram"
                label="Mentorship Program"
                mode="checkbox"
                component={Radio}
              />
              <Field
                name="indicators.militaryLeavePolicy"
                label="ESGR Support Signatory"
                mode="checkbox"
                component={Radio}
              />
              <Field
                name="indicators.militarySpousePCSPolicy"
                label="MSEP Support Signatory"
                mode="checkbox"
                component={Radio}
              />
            </div>
          </section>
          <section>
            <Field
              name="ats"
              className="medium-field"
              label="What ATS do you use?"
              placeholder="Select or type"
              component={Select}
              options={options.ATSOptions}
              creatable
            />
          </section>
          <div className={cx('actions', {organizationProfile})}>
            {!organizationProfile &&
              <Link
                className="CompanyOnboarding__goBack btn-primary-outlined"
                to='/intro'
              >
                <ReactSVG
                  src={images.arrowLeft}
                  aria-label="Go back"
                />
                Back
              </Link>
            }
            <button
              type="submit"
              className="btn-primary"
              disabled={submitting}
            >
              {organizationProfile ? 'Save' : 'Continue'}
            </button>
          </div>
        </form>
      </div>
    )
  }
)
