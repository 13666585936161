
import React from 'react';
import cx    from 'classnames';

import './CustomRadio.sass';

export default function CustomRadio({ value, onClick, label, isEnabled, data, action }) {
  const handleClick = () => {
    const newOption = value === true ? null : true;
    onClick(newOption);
  }
  const valueIsTruthy = data && data.find(tv => tv === value);

  return (
    <div className="CustomRadio">
      <div
        className={cx("check", data ?
          {checked: valueIsTruthy, option1: value === data[0], option2: value === data[1], option3: value === data[2]} :
          {checked: (Boolean(value) == true || isEnabled)})
        }
        onClick={action || handleClick || null}
      />
      {label &&
        <span className="CustomRadio__label">{label}</span>
      }
    </div>
  )
}
