import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { Helmet }             from 'react-helmet';
import { Link }               from 'react-router-dom';
import { compose,
         withState,
         setDisplayName,
         withProps,
         withHandlers,
         lifecycle }          from 'recompose';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Loader                 from 'theme/Loader';
import Confirm                from 'theme/Confirm';
import Modal                  from 'theme/Modal';
import MessageSection         from 'theme/MessageSection';
import { withScroll,
         withModal }          from 'theme/utils/recompose';
import { getJob,
         addJob,
         getSocCodes,
         getFieldsOfStudy,
         deleteJob,
         closeJob,
         updateJob }          from 'organizationAdmin/actions';

import Form                   from './Form';

import './JobOpening.sass';


const formName = "JobOpeningForm";

const JobOpening = compose(
  setDisplayName('JobOpening'),
  withScroll(),
  withModal(),
  withState('submitted', 'setSubmitted', false),
  withState('deleted', 'setDeleted', false),
  withProps(({match}) => ({
    cloned: match.url.includes('clone'),
    jobId: match.params.jobId
  })),
  withProps(({jobId, cloned}) => ({
    actionType: jobId ? (cloned ? 'Clone' : 'Edit') : 'Add'
  })),
  withHandlers({
    submit: ({submit, setSubmitted, jobId}) => (values) => {
      return submit(values, jobId)
      .then(setSubmitted(true))
    },
    deleteJob: ({deleteJob, setDeleted}) => id => {
      return deleteJob(id)
      .then(setDeleted(true))
    },
    getSocCodes: ({getSocCodes}) => (value, field) => {
      if (value === '') return;
      return getSocCodes(value)
      .then(({data}) => data.map(r => ({
        label: field === 'code' ? r.code : r.description,
        value: field === 'code' ? r.code : r.description,
        otherFieldValue: field === 'code' ? r.description : r.code
      })));
    },
    closeJob: ({closeJob, history, closeModal}) => (id, values) => {
      return closeJob(id, values)
      .then(() => {
        closeModal();
        history.push('/org-admin/jobs');
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      const { jobId, getJob } = this.props;

      if (jobId)
        getJob(jobId);
    },
    componentDidUpdate(prevProps) {
      if ((prevProps.submitted !== this.props.submitted) || (prevProps.deleted !== this.props.deleted))
        window.scrollTo(0, 0);
    }
  })
)(({job,
    submitted,
    submit,
    profile,
    loadingJob,
    match,
    deleteJob,
    deleted,
    actionType,
    getSocCodes,
    getFieldsOfStudy,
    closeJob,
    jobId,
    cloned,
    modal,
    openModal,
    closeModal,
    history
  }) => {
    const message = {
      title: `Thanks for paying it forward! ${deleted ? 'Your job has been deleted.' : (actionType === 'Edit' ? 'Your job opening has been edited.' : 'Your job opening has been posted.')}`
    };

    const initialValues = jobId
      ?
        {
          ...job,
          soc: {
            code: {
              value: job.socCode,
              label: job.socCode
            },
            associatedName: {
              value: job.associatedName,
              label: job.associatedName
            }
          },
          industryExperience: job.industryExperience &&
            [{
              ...job.industryExperience,
              period: job.industryExperience.period
            }],
          leadershipExperience: job.leadershipExperience &&
            [{
              ...job.leadershipExperience,
              period: job.leadershipExperience.period
            }],
          education: job.education?.map(item => ({
            ...item,
            degree: item.degree,
            fieldOfStudy: item.fieldOfStudy
          })),
          remoteAvailable: false,
          hasExpirationDate: job.expirationDate ? true : false
        }
      :
      {
        education: []
      }
    ;

    return (
      <div className="JobOpening page">
        <Helmet>
          <title>Vetit — Add Job Opening</title>
        </Helmet>
        <Header />
        {submitted || deleted
        ?
          <div className="container">
            <MessageSection {...message}>
              <Link
                className="btn-primary"
                to='/org-admin/jobs'
              >
                Return
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <div className="JobOpening__card">
              <Loader loading={loadingJob} />
              {!loadingJob &&
                <Form
                  onSubmit={submit}
                  form={formName}
                  initialValues={initialValues}
                  actionType={actionType}
                  jobId={jobId}
                  cloned={cloned}
                  history={history}
                  openModal={() => openModal('CLOSE_RECRUITMENT')}
                  organizationId={match.params.id}
                  profile={profile}
                  getSocCodes={getSocCodes}
                  getFieldsOfStudy={getFieldsOfStudy}
                  deleteJob={() => deleteJob(jobId)}
                />
              }
            </div>
          </div>
        }
        <Footer />

        <Modal
          isOpen={modal.visible}
          close={closeModal}
          closeBtn
        >
          <Confirm
            question="Are you sure you want to close this recruitment?"
            close={closeModal}
            action={() => closeJob(jobId, initialValues)}
          />
        </Modal>
      </div>
    )
  }
)

const mapStateToProps = ({data, loading}, {match}) => {
  const id = match.params.jobId;
  return {
    job: data[`job:${id}`]?.jobOpening || {},
    loadingJob: loading[`job:${id}`]
  }
}

const mapDispatchToProps = (dispatch, {match}) => {
  const jobId = match.params.jobId;
  const clone = match.url.includes('clone');
  const submitReq = (req, values, id) => new Promise((resolve, reject) => dispatch(req({values, id, resolve, reject, form: formName})));

  return {
    getJob: (id) => new Promise((resolve, reject) => dispatch(getJob(id, {resolve, reject}))),
    getSocCodes: (query) => dispatch(getSocCodes(query)),
    getFieldsOfStudy: (name) => dispatch(getFieldsOfStudy({name})),
    deleteJob: (id) => dispatch(deleteJob({id})),
    closeJob: (id, values) => dispatch(closeJob({id, values})),
    submit: (values, id) => jobId
      ? (clone ? submitReq(addJob, values, id) : submitReq(updateJob, values, id))
      : submitReq(addJob, values, id)
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JobOpening))
