import React               from 'react';
import { compose,
         withState,
         withProps,
         withHandlers,
         setDisplayName }  from 'recompose'
import Tooltip             from 'theme/Tooltip';
import Modal               from 'theme/Modal';
import { images }          from 'theme/img/images';
import { withScreenWidthDetection,
         withModal }       from 'theme/utils/recompose';

import './MoreInfo.sass';


const MoreInfo = compose(
  setDisplayName('MoreInfo'),
  withModal(),
  withScreenWidthDetection(),
  withState('tooltipVisible', 'setVisible', false),
  withProps(({screenWidth}) => ({
    mobile: screenWidth < 769
  })),
  withHandlers({
    handleClick: ({mobile, openModal}) => () => {
      if (mobile)
        openModal('MORE_INFO');
    }
  }),
)(({tooltipVisible, setVisible, mobile, modal, closeModal, text, handleClick}) => {
  return (
    <div className="MoreInfo">
      <span
        onMouseEnter={() => !mobile && setVisible(true)}
        onMouseLeave={() => !mobile && setVisible(false)}
        onClick={() => handleClick()}
      >
        <img src={images.questionMarkCircle} alt="More info" />
      </span>
      <Tooltip open={tooltipVisible}>{text}</Tooltip>
      <Modal
        className="MoreInfo__modal"
        isOpen={modal.visible}
        close={closeModal}
      >
        {text}
      </Modal>
    </div>
  )
})

export default MoreInfo;
