import api, { API_CONF }      from 'api';
import axios                  from 'axios';
import { omit }               from 'underscore';
import { copy, REVIEW_TYPES } from 'theme/content';
import { formatDate,
         mapReviewAction }    from 'theme/utils/helpers';

function fetchReviewsFeed({page = 0, pageSize = 3}) {
  return api({
    method: 'get',
    url: '/user-reviews',
    params: {
      sort: 'reviewCreatedAt:d',
      page,
      pageSize
    }
  })
}

function fetchProfile({id}) {
  return api({
    method: 'get',
    url: `/users/${id}`
  });
}

function fetchUserProfile({id}) {
  return api({
    method: 'get',
    url: `/users/${id}/profile`
  });
}

function fetchFollowed({page = 0, pageSize = 1000}) {
  return api({
    method: 'get',
    url: '/followed',
    params: {page, pageSize}
  });
}

function fetchSavedJobs({page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/job-openings/saved',
    params: {
      page,
      pageSize,
      sort: 'createdAt:d'
    }
  })
}

function fetchAppliedJobs({page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/job-applications/applied',
    params: {
      page,
      pageSize
    }
  })
}

function fetchMatchedJobs({page = 0, pageSize = 10}) {
  return api({
    method: 'get',
    url: '/job-openings/matched',
    params: {
      page,
      pageSize
    }
  })
}

function fetchJobInvitations({page = 0, pageSize = 10, userId}) {
  return api({
    method: 'get',
    url: '/job-invitations',
    params: {
      page,
      pageSize,
      userId
    }
  })
}

function fetchJobsFromFollowed({page = 0, pageSize = 3}) {
  return api({
    method: 'get',
    url: '/job-openings/followed',
    params: {
      page,
      pageSize,
      sort: 'createdAt:d'
    }
  })
}

function fetchSearches({page = 0, pageSize = 3}) {
  return api({
    method: 'get',
    url: '/job-openings/searches',
    params: {
      page,
      pageSize
    }
  });
}

function fetchRecent() {
  return api({
    method: 'get',
    url: '/job-openings/recent'
  });
}

function fetchReview({organizationType, organizationId, reviewType, reviewId}) {
  const defineUrl = () => {
    switch(organizationType) {
      case 'companies':
        return `/employers/${organizationId}/reviews/${reviewType}/${reviewId}`;
      case 'educators':
        return `/educators/${organizationId}/reviews/${reviewType}/${reviewId}`;
      case 'support-services':
        return `/partners/${organizationId}/${reviewType}/${reviewId}`;
    }
  };

  return api({
    method: 'get',
    url: defineUrl()
  });
}

export const deleteSearch = id => dispatch => {
  return api({
    method: 'delete',
    url: `/job-openings/subscriptions/${id}`
  })
  .then(() => dispatch(getSearches()));
}

export const getLocations = query => () => {
  return api({
    method: 'get',
    url: '/locations',
    params: {
      query
    }
  })
  .then(({data}) => data.data.map(r => {
    const value = r.country === copy.usaLabel
      ? `${r.city}, ${r.state}, ${r.country}`
      : `${r.city}, ${r.country}`

    return {
      value,
      label: value
    }
  }))
}

export const getSkills = query => () => {
  return api({
    method: 'get',
    url: '/skills',
    params: {
      query
    }
  })
  .then(({data}) => data);
}

export const fetchUserFeed = ({page, pageSize}) => {
  return api({
    method: 'get',
    url: '/user-feed',
    params: {
      page,
      pageSize
    }
  })
}

export const inviteFriends = (recipients, personalizedMessage) => () => {
  return api({
    method: 'POST',
    url: `/mails/invite`,
    data: {
      redirectUrl: `${location.origin}/home`,
      emails: recipients,
      ...(personalizedMessage && {personalizedMessage})
    }
  })
}

export const getReviewsFeed = ({page, pageSize, resolve, reject} = {}) => {
  return {type: 'REVIEWS_FEED/FETCH', req: fetchReviewsFeed, page, pageSize, resolve, reject}
}

export const getFollowed = (opts = {}) => {
  return {type: 'FOLLOWED/FETCH', req: fetchFollowed, ...opts}
}

export const getUserFeed = ({page, pageSize, resolve, reject} = {}) => {
  return {type: 'USER_FEED/FETCH', req: fetchUserFeed, page, pageSize, resolve, reject}
}

export const getSavedJobs = ({page, pageSize, loader, resolve, reject} = {}) => {
  return {type: 'SAVED_JOBS/FETCH', req: fetchSavedJobs, page, pageSize, loader, resolve, reject}
}

export const getAppliedJobs = ({page, pageSize, loader, resolve, reject} = {}) => {
  return {type: 'APPLIED_JOBS/FETCH', req: fetchAppliedJobs, page, pageSize, loader, resolve, reject}
}

export const getMatchedJobs = ({page, pageSize, loader, resolve, reject} = {}) => {
  return {type: 'MATCHED_JOBS/FETCH', req: fetchMatchedJobs, page, pageSize, loader, resolve, reject}
}

export const getJobInvitations = ({page, pageSize, userId, loader, resolve, reject} = {}) => {
  return {type: 'JOB_INVITATIONS/FETCH', req: fetchJobInvitations, page, pageSize, userId, loader, resolve, reject}
}

export const getJobsFromFollowed = ({page, pageSize, loader, resolve, reject} = {}) => {
  return {type: 'JOBS_FROM_FOLLOWED/FETCH', req: fetchJobsFromFollowed, page, pageSize, loader, resolve, reject}
}

export const getSearches = (opts = {}) => {
  return {type: 'SEARCHES/FETCH', req: fetchSearches, ...opts}
}

export const getRecent = ({loader, resolve, reject} = {}) => {
  return {type: 'RECENT/FETCH', req: fetchRecent, loader, resolve, reject}
}

export const getProfile = (id, {resolve, reject, loader} = {}) => {
  return {type: 'PROFILE/FETCH', req: fetchProfile, id, resolve, reject, loader}
}

export const getUserProfile = ({id, resolve, reject, loader} = {}) => {
  return {type: 'USER_PROFILE/FETCH', req: fetchUserProfile, id, resolve, reject, loader}
}

export const getCandidateProfile = ({id, resolve, reject, loader} = {}) => {
  return {type: 'CANDIDATE_PROFILE/FETCH', req: fetchUserProfile, id, resolve, reject, loader}
}

export const getReview = ({organizationType, organizationId, reviewType, reviewId, loader}) => {
  return {type: `REVIEW:${reviewId}/FETCH`, req: fetchReview, organizationType, organizationId, reviewType, reviewId, loader}
}

//---------------------UPDATING---------------------------

const DATE_FORMAT = 'yyyy-MM-dd';

export const updateProfile = (id, values, reloadProfile = true) => {
  return (dispatch) => {
    return api({
      method: 'patch',
      url: `/users/${id}`,
      data: {
        ...values,
        etsSeparationDate: values.etsSeparationDate && formatDate(values.etsSeparationDate, DATE_FORMAT)
      }
    })
    .then(() => {
      if (reloadProfile)
        dispatch(getProfile(id))
    })
  }
}

export const updateUserProfile = (id, values, onboarding = false, isProfileSettings = false) => {
  const skills = values.skills || [];
  const searchedOrganizations = values.searchedOrganizations ? values.searchedOrganizations.reduce((a, b) => [...a, b.value], []) : [];
  const followedOrganizations = (values.topEmployers && values.topEducators && values.topSupportOrganizations) && [...values.topEmployers, ...values.topEducators, ...values.topSupportOrganizations];

  const stringCheck = items => items && items.every(item => typeof item === 'string') ? items :  items && items.reduce((a, b) => [...a, b.value], []);

  return (dispatch) => {
    return api({
      method: "patch",
      url: onboarding ? `/users/${id}/profile?onboarding` : `/users/${id}/profile`,
      data: {
        ...omit(values, 'suggestedSkills', 'companies', 'educators', 'supportServices', 'topEmployers', 'topEducators', 'topSupportOrganizations', 'searchedOrganizations'),
        eas: values.eas && formatDate(values.eas, DATE_FORMAT),
        earliestAvailability: values.earliestAvailability && formatDate(values.earliestAvailability, DATE_FORMAT),
        securityClearance: {
          clearance: values.securityClearance?.clearance,
          clearanceExpiration: values.securityClearance?.clearanceExpiration && formatDate(values.securityClearance.clearanceExpiration, DATE_FORMAT)
        },
        locations: values.locations ? stringCheck(values.locations): [],
        education: values.education?.map(e => ({
          ...e,
          graduationYear: parseInt(e.graduationYear)
        })),
        militaryServices: values.militaryServices?.some(ms => !ms) ? [] : values.militaryServices,
        certifications: values.certifications?.map(c => ({
          ...c,
          issueDate: c.issueDate && formatDate(c.issueDate, DATE_FORMAT),
          expirationDate: c.expirationDate && formatDate(c.expirationDate, DATE_FORMAT)
        })),
        employments: values.employments?.map(e => ({
          ...e,
          start: e.start && formatDate(e.start, DATE_FORMAT),
          end: e.end && formatDate(e.end, DATE_FORMAT)
        })),
        followedOrganizations: followedOrganizations && followedOrganizations.concat(searchedOrganizations),
        skills: isProfileSettings ? skills : (skills && values.suggestedSkills) && [...skills, ...values.suggestedSkills]
      }
    })
    .then(() => dispatch(getUserProfile({id})))
  }
}

export const uploadFile = (values, userId, updateProgress, source, forVerification = false) => dispatch => {
  return new Promise((resolve, reject) => {
    api.post(
      forVerification ? `/users/${userId}/verify/file` : `/users/${userId}/profile/resumes`,
      {meta: []},
      {cancelToken: source.token})
    .then(({data}) => {
      const file = new FormData();
      file.append('file', values[0], values[0].name);

      return axios({
        method: 'put',
        url: data.url,
        data: file,
        headers: {
          'x-amz-meta-name': encodeURI(values[0].name),
          'x-amz-meta-size': values[0].size
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            updateProgress && updateProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
        cancelToken: source.token
      })
      .then(() => {
        if (forVerification)
          return api({
            method: 'post',
            url: `/users/${userId}/verify/file/uploaded`,
            data: {
              fileName: values[0].name,
              key: data.key
            }
          })
      })
      .then(resolve, reject)
      .then(() => dispatch(getUserProfile({id: userId, loader: false})))
    })
  })
}

export const downloadFile = userId => () => {
  return new Promise((resolve, reject) => {
    api.get(
      `/users/${userId}/profile/resumes`,
    )
    .then(({data}) => {
      data.map(file => {
        return axios({
          method: 'get',
          url: file.url,
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', decodeURI(file.headers[0].value));
          document.body.appendChild(link);
          link.click();
        });
      })
    })
    .then(resolve, reject)
  })
}

export const deleteFile = (userId, key) => {
  return api({
    method: 'delete',
    url: `/users/${userId}/profile/resumes/${key}`
  })
}

export const deleteAccount = userId => () => {
  return api({
    method: 'delete',
    url: `/users/${userId}`
  })
}

function reviewAction(review, reviewId, action, isVote) {
  const type = REVIEW_TYPES[review.metadata.class];
  const url = type.organization === 'partner'
    ? `/${type.organization}s/${review.organizationId || review?.organization?.id}/reviews/${reviewId}`
    : `/${type.organization}s/${review.organizationId || review?.organization?.id}/reviews/${type.review}s/${reviewId}`;

  return api(
    mapReviewAction({
      method: 'post',
      action: isVote ? (action === 'revokeVote' ? 'revokeVote' : 'vote') : null,
      url,
      params: isVote
        ? {type: action}
        : {action}
    })
  );
}

export const voteReview = (review, reviewId, action) => () => {
  return reviewAction(review, reviewId, action, true);
}

export const viewReview = (review, reviewId) => () => {
  return reviewAction(review, reviewId, 'View');
}

export const sendVerificationEmail = (userId, values) => () => {
  return api({
    method: 'post',
    url: `/users/${userId}/verify/send-mail`,
    data: {
      email: values.milEmail,
      redirectUri: API_CONF.MIL_REDIRECT_URI
    }
  })
}

export const getOrganizations = (prefix) => () => {
  return api({
    method: 'get',
    url: 'organizations/autocomplete',
    params: {
      prefix,
      field: 'name'
    }
  })
  .then(({data}) => data);
}
