import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import cx                   from 'classnames';
import Loader               from 'theme/Loader';
import { env }              from 'theme/content';
import { images }           from 'theme/img/images';

export default class Dropdown extends Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };

    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
  }

  hideDropdown() {
    this.setState({visible: false});
  }

  showDropdown() {
    this.setState({visible: true})
  }

  render() {
    const { visible } = this.state;
    const { showDropdown, hideDropdown } = this;
    const { logout, user, userProfile, loadingProfile, isMobile, links } = this.props;

    return (
      <div className="Header__dropdown">
        {visible &&
          <div
            className="Header__overlay"
            onClick={hideDropdown}
          />
        }
        <div
          className={cx("Header__dropdownUser", {mobile: isMobile})}
          onClick={showDropdown}
        >
          {user && !isMobile &&
            <>
              <div className="Header__dropdownUserName">
                <Loader loading={loadingProfile} />
                <span>{`${userProfile?.firstName} ${userProfile?.lastName}`}</span>
                <Link
                  to={user.organizationId ? env.HOME_ROUTE.organizationAdmin : env.HOME_ROUTE.common}
                  onClick={(e) => e.stopPropagation()}
                >
                  Dashboard
                </Link>
              </div>
              <div className="Header__avatar" />
            </>
          }
          <img src={images.expand} alt="dropdown" />
        </div>

        <div className={cx("Header__dropdownContent", {visible})}>
          {links.map((link, i) => {
            return (
              <Link key={i} to={link.to} className="Header__dropdownLink">
                {link.name}
              </Link>
            )
          })}
          <div className="Header__logout">
            <button
              className="btn-secondary-outlined"
              onClick={logout}
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    )
  }
}
