import React                 from 'react';
import { connect }           from 'react-redux';
import { reduxForm,
         Field,
         formValueSelector } from 'redux-form';
import { TextField,
         Select,
         RadioLabels,
         Rating,
         Radio,
         Captcha }           from 'theme/Form';
import { required,
         email }             from 'theme/utils/validate';
import { options,
         agreements }        from 'theme/content';
import { getMosOptions }     from 'theme/content/mosOptions';

import './Form.sass';


export default connect(
  (state, props) => (
    {invited: formValueSelector(props.form)(state, 'invited')},
    {serviceBranchValue: formValueSelector(props.form)(state, 'reviewerDetails.serviceBranch')}
  )
)(reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, searchEducators, educators, loadingEducators, invited, submitFailed, invalid, openModal, profile, editDisabled, getAutocomplete, serviceBranchValue}) => {
    const isSpouse = serviceBranchValue === 'Spouse';
    return (
      <div className="InterviewReview__form">
        <h4>Applicant Experience</h4>
        <form onSubmit={handleSubmit}>
          <h6>Program Details</h6>
          <Field
            name="educator"
            label="School Name"
            required
            placeholder="Start typing..."
            component={Select}
            search={searchEducators}
            loading={loadingEducators}
            options={educators}
            valueKey='id'
            labelKey='name'
            validate={required}
            creatable
            onNewOptionClick={openModal}
            disabled={editDisabled}
            async
            getAutocomplete={getAutocomplete}
          />
          <Field
            name="programName"
            label="Program Name"
            required
            placeholder="e.g. Business, Criminal Justice.."
            component={TextField}
            validate={required}
          />
          <Field
            name="acquiredDegree"
            label="Degree Level"
            required
            className="block"
            component={RadioLabels}
            options={options.degrees}
            validate={required}
            disabled={editDisabled}
          />
          <Field
            name="instructionDetails"
            label="Instruction Delivery"
            className="block"
            required
            component={RadioLabels}
            options={options.instructionRange}
            validate={required}
            disabled={editDisabled}
          />
          <h6>Experience Overview</h6>
          <Field
            name="review.reviewTitle"
            label="Review Title"
            placeholder="e.g. After an incredible interview process, I received an acceptance letter!"
            className="block"
            required
            component={TextField}
            validate={required}
          />
          <Field
            name="review.reviewComments"
            label="Review Comments"
            className="block"
            placeholder="You filled out application forms, wrote essays, paid fees, secured letters of recommendation, and worked through the DoD TA or VA GI Bill processes. How was your experience? What did you think this specific school, program, degree, certificate, or training credential might do for your career? What did you think of the application and/or interview processes? How many interviews did you have and how did you prepare for them?"
            required
            componentType="textarea"
            component={TextField}
            validate={required}
          />
          <Field
            name="rating"
            label="Rate your overall experience"
            required
            validate={required}
            component={Rating}
            disabled={editDisabled}
          />
          <h6>Application & Interview Details</h6>
          <Field
            name="interviewDetails.yearInterviewed"
            label="Year Applied"
            component={Select}
            options={options.yearInterviewed}
            disabled={editDisabled}
          />
          <Field
            className="InterviewReview__formRadio"
            name="invited"
            label="I was invited to interview"
            component={Radio}
            mode="checkbox"
            disabled={editDisabled}
          />
          <Field
            name="interviewDetails.interviewDifficulty"
            label="The interview itself was"
            className="block"
            component={RadioLabels}
            options={options.interviewDifficulty}
            disabled={editDisabled || !invited}
          />
          <h6>Decision Details</h6>
          <Field
            name="interviewDetails.processLength"
            label="The time from submitting my application to being notified of a decision was"
            className="block"
            component={RadioLabels}
            options={options.decisionTime}
            disabled={editDisabled}
          />
          <Field
            name="interviewDetails.interviewOutcome"
            label="Were you admitted into the program?"
            className="block"
            component={RadioLabels}
            options={options.decisions}
            disabled={editDisabled}
          />
          <h6>Reviewer Information</h6>
          <Field
            name="reviewerDetails.serviceBranch"
            label="Service Branch"
            required
            className="block"
            component={RadioLabels}
            options={options.branchRange}
            validate={required}
            disabled={editDisabled}
          />
          {!isSpouse &&
            <Field
              label="Military Occupation"
              name="reviewerDetails.mos"
              placeholder="Select"
              component={Select}
              options={getMosOptions(serviceBranchValue)}
              disabled={editDisabled}
              {...(!isSpouse && {validate: required, required: true})}
            />
          }
          {!isSpouse &&
            <Field
              name="reviewerDetails.etsPaygrade"
              label="Highest Paygrade Attained"
              placeholder="Select"
              component={Select}
              options={options.paygradeOptions}
              disabled={editDisabled}
              {...(!isSpouse && {validate: required, required: true})}
            />
          }
          <Field
            name="reviewerDetails.email"
            label="Your E-mail"
            required
            placeholder="e.g. sgt.hooah@gmail.com or iam.t.standard4.mil@mail.mil"
            component={TextField}
            validate={[required, email]}
            disabled={editDisabled}
          />
          <h5 className="InterviewReview__agreement">{agreements.certifiedVet}</h5>
          {Object.keys(profile).length == 0 &&
            <Field
              name="captcha"
              component={Captcha}
              validate={required}
            />
          }
          {submitFailed && invalid &&
            <div className="ProgramReview__error">Please check if you filled all required fields.</div>
          }
          <button
            type="submit"
            className="btn-primary"
            disabled={pristine || submitting}
          >
            VET IT FORWARD!
          </button>
        </form>
      </div>
    )
  }
))
