import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { compose,
         withState,
         lifecycle,
         withProps }          from 'recompose';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Loader                 from 'theme/Loader';
import AdminNav               from 'theme/AdminNav';
import { employerFields,
         educatorFields,
         partnerFields }      from 'theme/content';
import { getFields,
         updateFields }       from 'organizations/actions';

import Form                   from './Form';
import './OrganizationSettings.sass';


const formName = 'OrganizationSettings';

const OrganizationSettings = compose(
  withState('error', 'setError', null),
  lifecycle({
    componentDidMount() {
      this.props.getFields();
    }
  }),
  withProps(({match}) => ({ type: match.params.type })),
  withProps(({type}) => ({
    fieldList: type == 'educator' ? educatorFields : (type == 'employer' ? employerFields : partnerFields)
  }))
)(({type, fields, fieldList, updateFields, loading}) => {
  const orgType = type === 'support-service' ? 'support service' : type;
  return (
    <div className="OrganizationSettings page">
      <Header />
      <AdminNav />
      <div className="container">
        <Loader className="OrganizationSettings__loader" loading={loading} />
        <h4>Manage {orgType} fields</h4>
        <span>Click checkboxes to either enable or disable the selected form field for {orgType}s. A checked checkbox stands for a disabled field — unchecked — for an active one.</span>

        <div className="OrganizationSettings__fields">
          <Form
            initialValues={fields}
            fieldList={fieldList}
            form={formName}
            onSubmit={updateFields}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
})

const mapStateToProps = ({data, loading}) => {
  return {
    fields: data && data.fields || {},
    loading: loading.fields
  }
}

const mapDispatchToProps = (dispatch, {match: {params}}) => {
  const organizationType = params.type === 'support-service' ? 'Partner' : (params.type === 'educator' ? 'Educator' : 'Employer');
  return {
    getFields: () => new Promise((resolve, reject) => dispatch(getFields({organizationType, resolve, reject}))),
    updateFields: values => dispatch(updateFields({values, organizationType}))
  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationSettings))
