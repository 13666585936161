import React        from 'react';
import { Link }     from 'react-router-dom';
import Loader       from 'theme/Loader';
import ProgressBar  from 'theme/ProgressBar';
import { images }   from 'theme/img/images';

import './CompleteProfile.sass';


export default function CompleteProfile({percentage, loading, close}) {
  return (
    <div className="CompleteProfile">
      <Loader loading={loading} />
      <div className="CompleteProfile__info">
        <div className="CompleteProfile__infoInner">
          <h3>Complete your profile</h3>
          <p>A complete profile is more likely to be matched to employers, learning opportunities, and support services. It is the best way to help us provide you with a great user experience.</p>
          <button
            className="btn-close"
            onClick={close}
          >
            <img src={images.closeBlack} alt="close" />
          </button>
        </div>
        <div className="CompleteProfile__progress">
          <div className="CompleteProfile__progressHeading">
            <h5 className="subheader">Getting started</h5>
            <span className="CompleteProfile__progressPercentage">{percentage}%</span>
          </div>
          <ProgressBar value={percentage} />
        </div>
      </div>
      <div className="CompleteProfile__continue">
        <Link className="btn-primary" to="/profile-settings/general">Continue</Link>
      </div>
    </div>
  )
}
