import React from 'react';

import './Confirm.sass';

export default function Confirm({question, close, action}) {
  return (
    <div className="Confirm">
      <p>{question}</p>
      <button
        className="btn-primary"
        onClick={action}
      >
        Yes
      </button>
      <button
        className="btn-primary-outlined"
        onClick={close}
      >
        No
      </button>
    </div>
  )
}
