import React, { useEffect } from 'react';
import { Link }             from 'react-router-dom';
import cx                   from 'classnames';
import CollapseComponent    from 'theme/CollapseComponent';
import Loader               from 'theme/Loader';
import FunctionBtn          from 'theme/FunctionBtn';
import Pie                  from 'theme/Pie';
import ListItemLabel        from 'theme/ListItemLabel';
import { formatDate }       from 'theme/utils/helpers';
import { images }           from 'theme/img/images';

import './Candidates.sass';


const Row = ({
  candidate: {id, earliestAvailability, idName, invited, jobApplication, matchingInfo: {matchingRatio}},
  shortlist,
  downloadResume,
  viewApplication,
  jobOpeningClosed,
  isMatched,
  openModal
}) => {
  const candidateId = jobApplication?.id || id;
  const availability = jobApplication?.user.earliestAvailability || earliestAvailability;
  const IDName = idName?.substring(1, (idName.length));

  useEffect(() => {
    const textElements = document.querySelectorAll('text');
    Array.from(textElements).forEach(el => {
      el.setAttribute('text-anchor', 'middle');
      el.setAttribute('dominant-baseline', 'middle');
      el.style.fontWeight = '600';
    })
  }, []);

  return (
    <div className="Candidates__row" key={candidateId}>
      <div className="Candidates__avatar" />
      <div className="Candidates__name">
        <div>
          <p>{isMatched ? `ID name: #${IDName}` : `${jobApplication?.user.firstName} ${jobApplication?.user.lastName}`}</p>
          {(!isMatched && !jobApplication?.viewed) &&
            <ListItemLabel text="New" color="orange" />
          }
        </div>
        <span className="Candidates__availability">
          Earliest availability:
          <span>{availability && formatDate(availability)}</span>
        </span>
      </div>
      <div className="Candidates__matched">
        <Pie value={matchingRatio} small />
        <span>Matched</span>
      </div>
      <div className={cx("Candidates__btns", {hidden: isMatched && jobOpeningClosed})}>
        {isMatched
          ?
            <button
              className={cx("Candidates__inviteBtn btn btn-secondary", {invited})}
              onClick={invited ? null : openModal}
            >
              {invited ? '✔ Invited' : 'Invite to apply'}
            </button>
          :
            <>
              <FunctionBtn
                className="Candidates__resume btn"
                img={{src: images.download, alt: 'resume'}}
                text="Resume"
                disabled={jobApplication?.user.resumes.length === 0}
                action={() => downloadResume(jobApplication?.user.id)}
              />
              <Link
                className="btn btn-secondary"
                to={`/org-admin/candidate/${candidateId}`}
                onClick={() => !jobApplication?.viewed && viewApplication(candidateId)}
              >
                View profile
              </Link>
              <button
                className={cx("Candidate__shortlist btn btn-secondary-ghost", {active: jobApplication?.shortlisted})}
                onClick={() => shortlist(candidateId, jobApplication?.shortlisted ? false : true)}
              >
                {jobApplication?.shortlisted ? 'Shortlisted' : 'Shortlist'}
              </button>
            </>
          }
      </div>
    </div>
  )
}

export default function List({data: {candidates, label, count}, toggleList, viewApplication, jobOpeningClosed, loading, index, children, shortlist, openModal, isListExpanded, downloadResume, isMatched}) {
  return (
      <div className="Candidates__list">
        <Loader loading={loading} />
        <div className="Candidates__heading">
          <h5 className="subheader">
            {label} {count > 0 && `(${count})`}
          </h5>
          <button
            onClick={() => toggleList(index)}
            className={cx("Candidates__expand")}
          >
            {candidates?.length > 0 &&
              <img src={images.hide} alt="toggle" className={cx({listHidden: !isListExpanded})} />
            }
          </button>
        </div>
        <CollapseComponent isOpened={isListExpanded}>
          {candidates?.map(c => (
            <Row
              candidate={c}
              key={c.id || c.jobApplication.id}
              shortlist={shortlist}
              downloadResume={downloadResume}
              viewApplication={viewApplication}
              jobOpeningClosed={jobOpeningClosed}
              isMatched={isMatched}
              openModal={() => openModal('INVITE_TO_APPLY', c)}
            />
          ))}
          {children}
        </CollapseComponent>
      </div>
  )
}
