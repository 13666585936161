import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { compose,
         withState,
         withStateHandlers,
         withHandlers,
         lifecycle }        from 'recompose';
import { pick }             from 'underscore';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Loader               from 'theme/Loader';
import MessageSection       from 'theme/MessageSection';
import { withScroll }       from 'theme/utils/recompose';
import { getProfile }       from 'auth/actions';
import { getPartner,
         getPartners,
         getPartnerReview,
         addPartnerReview,
         updateReview }     from 'partners/actions';

import Form                 from './Form';
import './PartnerReview.sass';


const formName = 'PartnerReview';

const PartnerReview = compose(
  withScroll(),
  withState('submitted', 'setSubmitted', false),
  withStateHandlers(
  {
    modalOpened: false,
    orgName: ''
  },
  {
    openModal: () => orgName => ({
      modalOpened: true,
      orgName
    }),
    closeModal: () => () => ({modalOpened: false})
  }),
  withHandlers({
    nextPartnerReview: props => () => props.setSubmitted(false),
    getPartners: ({getPartners, query}) => () => {
      return getPartners()(query);
    },
    submit: props => values => {
      return props.submit(values)
      .then(() =>
        props.setSubmitted(values.partner.id || values.partner)
      )
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getPartners();

      if (this.props.user && Object.keys(this.props.user).length)
        this.props.getProfile(this.props.user);
      if (this.props.match.params.partnerId)
        this.props.getPartner();
      if(this.props.match.params.reviewId)
        this.props.getPartnerReview();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.submitted != this.props.submitted)
        window.scrollTo(0, 0);
    }
  })
)(({partner, partners, review, profile, submit, loadingPartner, submitted, nextPartnerReview, match}) => {
  const reviewerDetails = pick(profile, 'mos', 'etsPaygrade', 'serviceBranch', 'email');
  const partnerOptions = partners.map(p => ({id: p.id, name: p.name}));

  const initialValues = match.params.reviewId
  ? { ...review, partner: {id: partner.id, name: partner.name} }
  : { reviewerDetails, partner: {id: partner.id, name: partner.name}};

  const message = {
    title: `Thanks for paying it forward! ${match.params.reviewId ? 'Your review has been updated.' : 'Your review has been added to this organization\'s profile.'}`
  };

  const redirectLink = match.params.reviewId
    ? history.state.state.prevPath
    : `/support-services/${submitted}/${partner.slug}/overview`;

  return (
    <div className="PartnerReview page">
      <Header />
      {submitted ?
        <div className="container">
          <MessageSection {...message}>
            <Link
              className="btn-primary"
              onClick={nextPartnerReview}
              to={redirectLink}
            >
              Return
            </Link>
          </MessageSection>
        </div>
      :
        <div className="container">
          <Loader loading={loadingPartner} />
          {!loadingPartner &&
            <Form
              onSubmit={submit}
              initialValues={initialValues}
              form={formName}
              profile={profile}
              partnerOptions={partnerOptions}
              editDisabled={Boolean(match.params.reviewId)}
            />
          }
          <div className="PartnerReview__sidebar">
            <div className="flag first"></div>
            <p className="secondary-text">Career Services and Programs</p>
            <p className="content-text">This review form allows you to share details about experiences you've had with support services and programs. </p>
            <div className="flag second"></div>
            <p className="secondary-text">Be Constructive!</p>
            <p className="content-text">We value candid insights much more than rose-colored ones, but be professional! Profanity will be removed. </p>
            <p className="content-text">Respond as though a close military friend is reaching out to learn more about this specific experience.</p>
            <div className="flag third"></div>
            <p className="secondary-text">Your Review Will Remain Discreet!</p>
            <p className="content-text">Because our community can learn the most from open, honest insights like yours, we want you to feel safe sharing them.</p>
          </div>
        </div>
      }
      <Footer />
    </div>
  )
});

const mapStateToProps = ({auth, data, loading}, props) => {
  const id = props.match.params.partnerId;
  const reviewId = props.match.params.reviewId;

  return {
    user: auth.user,
    profile: data.profile || {},
    partner: data[`partner:${id}`] || {},
    partners: (data.partners && data.partners.data) || [],
    loadingPartner: loading[`partner:${id}`],
    review: (data && data[`partner_review:${reviewId}`]) || {metadata: {}, reviewerDetails: {}}
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const id = props.match.params.partnerId;
  const reviewId = props.match.params.reviewId;

  return {
    getProfile: (user) => dispatch(getProfile(user.id)),
    getPartner: () => new Promise((resolve, reject) => dispatch(getPartner(id, {resolve, reject}))),
    getPartners: (page) => (query) => new Promise((resolve, reject) => dispatch(getPartners({page, pageSize: 300, query, resolve, reject, dispatch}))),
    submit: (values) => reviewId ?
      dispatch(updateReview(id, reviewId, values))
      : new Promise((resolve, reject) => dispatch(addPartnerReview({values, resolve, reject, form: formName}))),
    getPartnerReview: () => new Promise((resolve, reject) => dispatch(getPartnerReview(id, reviewId, {resolve, reject})))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerReview))
