import { valueToPercent, toCommas } from 'theme/utils/helpers';

export const labels = {
  quickGlance: item => ([
    {label: 'city', value: item.address && item.address.city},
    {label: 'state', value: item.address && item.address.state},
    {label: 'country', value: item.address && item.address.country},
    {label: 'type', value: item.type},
    {label: 'accreditation', value: item.quickGlance && item.quickGlance.accreditation},
    {label: 'selectivity', value: item.selectivity}
  ]),
  studentsData: item => ([
    {label: 'gi bill students', value: toCommas(item.quickGlance && item.quickGlance.studentsGiBill)},
    {label: 'yellow ribbon', value: toCommas(item.quickGlance && item.quickGlance.studentsYellowRibbon)},
    {label: 'undergrad students', value: toCommas(item.quickGlance && item.quickGlance.studentsUndergraduates)},
    {label: 'retention %', value: valueToPercent(item.quickGlance && item.quickGlance.retentionPercent)},
    {label: 'graduation %', value: valueToPercent(item.quickGlance && item.quickGlance.graduationPercent)},
    {label: 'repayment %', value: valueToPercent(item.quickGlance && item.quickGlance.repaymentPercent)}
  ]),
  dollarAndSense: item => ([
    {label: 'in state tuition/yr', value: toCommas(item.dollarsSense && item.dollarsSense.tuitionInState)},
    {label: 'out of state tuition/yr', value: toCommas(item.dollarsSense && item.dollarsSense.tuitionOutOfState)},
    {label: 'max housing allowance/mo', value: toCommas(item.dollarsSense && item.dollarsSense.maxHousingAllowance)},
    {label: '10yr median earnings', value: toCommas(item.dollarsSense && item.dollarsSense.medianEarnings)}
  ]),
  indicators: item => ([
    {label: 'mostly online', value: item.quickGlance && item.quickGlance.isMostlyOnline},
    {label: '8 key commitment', value: item.indicators && item.indicators.is8kc},
    {label: 'principles of excellence', value: item.indicators && item.indicators.isPoe},
    {label: 'dod mou / ta agreement', value: item.indicators && item.indicators.isDodmou},
    {label: 'student vet group', value: item.indicators && item.indicators.isSvg}
  ]),
  booleanFields: ['mostly online', '8 key commitment', 'principles of excellence',  'dod mou / ta agreement',  'student vet group'],
  percentFields: ['retention %', 'graduation %', 'repayment %']
}
