import React, { useEffect } from 'react';
import { Link }             from 'react-router-dom';
import cx                   from 'classnames';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import copy                 from 'about/copy';

import './About.sass';


export default function About() {
  const {
    dueDiligence,
    paths
  } = copy;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="About page">
      <Header />
      <div className="About__jumbotron">
        <h1>Discover the right next step<br /> to develop your career.</h1>
        <p>Enduring career matches are rational and intentional.<br /> Make time to invest in your self and industry awareness today!</p>
      </div>
      <div className="container">
        <section className="About__dueDiligence">
          <h2 className="About__title">{dueDiligence.title}</h2>
          <div>
            {dueDiligence.items.map((item, i) => (
              <div key={i} className="About__dueDiligenceItem">
                <img src={item.icon} />
                <h4>{item.title}</h4>
                <span className="subheader">{item.subtitle}</span>
                <p className="secondary-text">{item.text}</p>
                <Link className="btn-secondary" to={item.link}>{item.btnText}</Link>
              </div>
            ))}
          </div>
        </section>
        <section className="About__paths">
          <h2 className="About__title">{paths.title}</h2>
          <p className="secondary-text About__subtitle">{paths.subtitle}</p>
          <div>
            {paths.items.map((item, i) => (
              <div key={i} className={cx("About__pathsItem", {reverse: i % 2 !== 0})}>
                <img src={item.image} alt="path" />
                <div>
                  <h3>{item.title}</h3>
                  <p className="secondary-text">{item.text}</p>
                  <Link className="btn-secondary" to={item.link}>{item.btnText}</Link>
                </div>
              </div>
            ))}
          </div>
        </section>
    </div>
    <Footer />
  </div>
  )
}
