import React, { useRef, useEffect } from 'react';
import { connect }                  from 'react-redux';
import { withRouter }               from 'react-router';
import cx                           from 'classnames';
import { parse }                    from 'qs';
import Loader                       from 'theme/Loader';
import Review                       from 'theme/Review';
import Return                       from 'theme/Return';

import './SingleReview.sass';

function SingleReview({className, location, user, review, id, view, voteReview, followReview, unfollowReview, goBack, loading, isFeed, urlParams}) {
  const ref = useRef();

  useEffect(() => {
    ref.current.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, []);

  const isReportedPage = Boolean(urlParams.commentReported || urlParams.reviewReported);

  return (
    <div className={cx("SingleReview", className)}>
      <Loader loading={loading} />
      {(!location.state && (user && !isReportedPage)) &&
        <Return
          text="Go back"
          goTo={goBack}
        />
      }
      <div ref={ref}>
        <Review
          review={review}
          reviewId={id}
          view={view}
          voteReview={voteReview}
          organization={review.organization}
          isFeed={isFeed}
          follow={followReview}
          unfollow={unfollowReview}
          reviewItem
          card
        />
      </div>
    </div>
  )
}


export default withRouter(connect(
  () => ({ urlParams: parse(location.search.substring(1)) }),
  null
)(SingleReview));
