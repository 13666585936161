import React        from 'react';
import { Link }     from 'react-router-dom';

import suitcase     from './img/suitcase.svg';
import hat          from './img/hat.svg';
import person       from './img/person.svg';
import path1        from './img/path-1.svg';
import path2        from './img/path-2.svg';
import path3        from './img/path-3.svg';
import path4        from './img/path-4.svg';
import path5        from './img/path-5.svg';
import step1        from './img/step-1.png';
import step2        from './img/step-2.png';
import step3        from './img/step-3.png';


export default {
  dueDiligence: {
    title: 'Post-Military Career Due Diligence',
    items: [
      {
        title: 'Determine What You Want',
        subtitle: 'Assess Your Interests',
        link: '/',
        icon: step1,
        text: 'You likely rank high on patriotism, selflessness, honor, adventure, and commitment. Courage and grit too. And we love you for it! But, focusing on your future, what professional interests do you have, and what might they tell us about what type of work you\'ll find uniquely meaningful? In addition to engaging with Vet it\'s community around career topics and reviews, be sure to assess your interests through a more formal instrument. You may just learn a little more about yourself!',
        btnText: 'Assess My Interests'
      },
      {
        title: 'Get Qualified',
        subtitle: 'Your credentials matter',
        link: '/educators',
        icon: step2,
        text: 'Okay. You\'ve assessed your interests. You\'ve conducted your interviews. You\'ve read all the honest reviews and engaged your curiosity by asking lots of questions on Vet it. You know exactly what jobs and industries are most meaningful to you and you recognize that you may need more than just your soft skills alone. Based on your aptitude, time, energy, and money, which credentials and schools will produce the outcomes that your future self will thank you for?',
        btnText: 'Explore Education'
      },
      {
        title: 'Find Cultural Alignment',
        subtitle: 'Your value set is unique',
        link: '/',
        icon: step3,
        text: 'And so are the values and culture of the company and team you go to work with. In fact, your alignment with them will be strongly correlated to your productivity, job satisfaction, and organizational commitment. Have friends that aren\'t satisfied with their job? There\'s a good chance they didn\'t take these things seriously. While we sometimes have to take a job just to make ends meet, we owe it to ourselves and families to be better informed. Let us help.',
        btnText: 'How I Work'
      }
    ]
  },
  paths: {
    title: 'The Paths Frequently Travelled...',
    subtitle: 'Are somehow still not well understood. Your best future self needs you engaged right now. Read beyond the headline.',
    items: [
      {
        title: 'Higher Education?',
        text: 'The GI Bill is one of the greatest benefits you\'ve earned for your service. But, don\'t treat it like it\'s free simply because you want the housing allowance! Be intentional and diligent in identifying both the degree and school that\'s right for you! See what advice our VA GI Bill Community is sharing.',
        image: path1,
        btnText: 'Ask others',
        link: '/community/general/Higher-Education'
      },
      {
        title: 'Apprenticeships?!',
        text: 'Did you know that many apprenticeships offer both a job and a housing allowance similar to the GI Bill? Also, because many veterans start but don\'t complete higher ed degrees, struggle to pay off student loans, and attend programs with poor earning potential, an apprenticeship might just be the right next step for you! And, while many are suited for veterans interested in more traditional trades, more and more are being created for trades such as cybersecurity. Many of them, not knowing about Apprenticeships, will actually use the GI Bill just to get the housing allowance from it for a few years, only to not do well in their college courses and end up having to pay money back because of GI Bill standards.',
        image: path2,
        btnText: 'Learn more',
        link: '/community/general/Apprenticeships'
      },
      {
        title: 'Skillbridge.. \n what\'s a skillbridge?',
        text: 'Wait, what?! It\'s true! The DOD has a program that allows a service member\'s chain of command to approve a nearby civilian internship or pre-apprenticeship starting up to 180 days before the "end of active service" date. While there are hurdles to get this approval, it could be a great way to jumpstart your career transition.',
        image: path3,
        btnText: 'Learn more',
        link: '/community/general/dod-skillbridge'
      },
      {
        title: 'Upskilling',
        text: 'Not interested in a degree, an apprenticeship, or a bridge program? There are many certifications that are hot in the current labor market and that employers often value above all else. Perhaps you already have a degree in something that you haven\'t used for a long time, or you simply want to try a new job function in a new industry? This could be the path for you!',
        image: path4,
        btnText: 'Learn more',
        link: '/community/general/Upskilling'
      },
      {
        title: 'Self Employment \n & Entrepreneurship',
        text: 'Reportedly as many as 25% of veterans would like to work for themselves or start a company. 5% or less do. The reason? It\'s hard ya\'ll! But, if any group generally has the right aptitude for it, it\'s former military. Check out organizations that can help you assess your individual readiness!',
        image: path5,
        btnText: 'Discuss this topic',
        link: '/community/general/Entrepreneurship'
      }
    ]
  },
  faq: {
    title: 'FAQ',
    subtitle: 'Can we just hide FAQ for now? I mean, we can have Norbert do it, but with the request that he hide it from showing in production',
    items: [
      {
        title: 'What is Vet it\'s \'Why\'?',
        features: []
      },
      {
        title: 'How does Vet it do it?',
        features: []
      },
      {
        title: 'What does a Vet it survey look like?',
        features: [
          {
            title: 'Quick. Easy. Wide reaching.',
            text: <p>Each review survey can be completed in as little as 1 minute, depending on how many details and insights are shared.<br />Members with complete user profiles can complete them even faster.</p>,
            icon: suitcase
          },
          {
            title: 'Applicant and Student Experiences for Educators.',
            text: <p>View a school applicant experience review survey by <Link to="/educators/interview-review">clicking here.</Link><br/>View a student experience review survey by <Link to="/educators/review">clicking here.</Link></p>,
            icon: hat
          },
          {
            title: 'Applicant and Employee Experiences for Employers.',
            text: <p>View an applicant experience review survey by <Link to="/companies/interview-review">clicking here.</Link><br/>View an employee experience review survey by <Link to="/companies/review">clicking here.</Link></p>,
            icon: person
          }
        ]
      },
      {
        title: 'How are Companies and Schools listed?',
        features: []
      },
      {
        title: 'What do Company and School profiles look like?',
        features: []
      }
    ]
  }
}