
import React from 'react';
import cx    from 'classnames';

import './Toggle.sass';

export default function Toggle({ checked, onClick, disabled }) {
  return (
    <div
      className={cx("Toggle", {checked, disabled})}
      onClick={onClick}
    >
      <div className={cx("Toggle__thumb", {checked})}>
        {checked &&
          <i className="fa fa-check" aria-hidden="true" />
        }
      </div>
    </div>
  )
}
