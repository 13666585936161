import React                        from 'react';
import { Route, Switch, Redirect }  from 'react-router-dom';
import Authentication               from 'auth/Authentication';
import ResetPassword                from 'auth/ResetPassword';
import LoginCallback                from 'auth/Callback';
import Verification                 from 'auth/Verification';
import Landing                      from 'landing/Landing';
import Educator                     from 'educators/Educator';
import Educators                    from 'educators/Educators';
import NewEducator                  from 'educators/NewEducator';
import ProgramReview                from 'educators/ProgramReview';
import EducatorInterviewReview      from 'educators/InterviewReview';
import Company                      from 'companies/Company';
import Companies                    from 'companies/Companies';
import NewCompany                   from 'companies/NewCompany';
import CompanyInterviewReview       from 'companies/InterviewReview';
import CompanyReview                from 'companies/CompanyReview';
import Community                    from 'community/Community';
import Topics                       from 'community/Topics';
import Styleguide                   from 'theme/Styleguide';
import NotFound                     from 'theme/NotFound';
import UserDashboard                from 'user/UserDashboard/UserDashboard';
import AccountSettings              from 'user/AccountSettings';
import ProfileSettings              from 'user/ProfileSettings';
import Onboarding                   from 'user/Onboarding';
import UserVerification             from 'user/UserVerification';
import User                         from 'users/User';
import Users                        from 'users/Users';
import Partner                      from 'partners/Partner';
import Partners                     from 'partners/Partners';
import PartnerReview                from 'partners/PartnerReview';
import NewPartner                   from 'partners/NewPartner';
import JobBrowsing                  from 'jobBrowsing/JobBrowsing';
import Organization                 from 'organizations/Organization';
import Organizations                from 'organizations/Organizations';
import OrganizationSettings         from 'organizations/OrganizationSettings';
import Candidate                    from 'organizationAdmin/MyCompany/Candidate';
import MyCompany                    from 'organizationAdmin/MyCompany';
import JobPreview                   from 'organizationAdmin/MyCompany/JobPreview';
import JobOpening                   from 'organizationAdmin/MyCompany/JobOpening';
import CompanyOnboarding            from 'organizationAdmin/CompanyOnboarding';
import ManageProfile                from 'organizationAdmin/ManageProfile';
import AdminReviews                 from 'admin/AdminReviews';
import AdminPosts                   from 'admin/AdminPosts';
import Subscription                 from 'subscriptions/Subscription';
import Subscriptions                from 'subscriptions/Subscriptions';
import CompanyLanding               from 'companyLanding/CompanyLanding';
import About                        from 'about/About';
import Policy                       from 'policy/Policy';
import Terms                        from 'terms/Terms';
import Security                     from 'security/Security';
import PostTeaser                   from 'teaser/PostTeaser';
import ReviewTeaser                 from 'teaser/ReviewTeaser';


/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default function routes (store) {
  const LoginRoute = ({component, ...rest}) => {
    const { auth } = store.getState();
    if (!auth.token) {
      return (
        <Route component={component} {...rest} />
      )
    }
    return (
      <Route {...rest}>
        <Redirect to="/" />
      </Route>
    )
  }

  const AuthenticatedRoute = ({...props}) => {
    const { auth } = store.getState();
    if (auth.token) {
      return (<Route {...props} />)
    }
    return (
      <Redirect to="/auth/signin" {...props} />
    )
  }

  const AdminRoute = ({...props}) => {
    const { auth } = store.getState();
    if (auth.token && auth.user.isAdmin) {
      return (<Route {...props} />)
    }
    return (
      <Redirect to="/home" {...props} />
    )
  }

  return (
    <div>
      <Switch>
        <Redirect exact path="/" to="/home" />
        <Route exact path="/home" component={Landing} />
        <Route exact path="/style" component={Styleguide} />
        <LoginRoute exact path="/auth/:section" component={Authentication} />
        <LoginRoute exact path="/reset-password" component={ResetPassword} />
        <LoginRoute exact path="/reset-password/:mailToken" component={ResetPassword} />
        <Route exact path="/auth/callback/:verificationMethod" component={Verification} />
        <Route exact path="/auth/callback/:social/:type" component={LoginCallback} />
        <Route exact path="/companies" component={Companies} />
        <Route exact path="/companies/new" component={NewCompany} />
        <Route exact path="/companies/interview-review" component={CompanyInterviewReview} />
        <Route exact path="/companies/interview-review/:companyId" component={CompanyInterviewReview} />
        <Route exact path="/companies/interview-review/:companyId/edit/:reviewId" component={CompanyInterviewReview} />
        <Route exact path="/companies/interview-review/:companyId/:slug" component={CompanyInterviewReview} />
        <Route exact path="/companies/review" component={CompanyReview} />
        <Route exact path="/companies/review/:companyId/edit/:reviewId" component={CompanyReview} />
        <Route exact path="/companies/review/:companyId/:slug" component={CompanyReview} />
        <Route exact path="/companies/:filter" component={props => <Companies filter {...props} />} />
        <Route exact path="/companies/:id/:slug/:section" component={Company} />
        <Route exact path="/companies/:id/:slug/:section/:reviewType/:reviewId" component={Company} />
        <AuthenticatedRoute exact path="/companies/:id/:slug/:section/:jobId" component={Company} />
        <AuthenticatedRoute exact path="/companies/:id/:slug/discussion" component={Company} />
        <AuthenticatedRoute exact path="/companies/:id/:slug/job-offers" component={Company} />
        <Route exact path="/companies/:id" component={Company} />
        <Route exact path="/companies/:id/:slug/overview" component={Company} />
        <Route exact path="/educators" component={Educators} />
        <Route exact path="/educators/new" component={NewEducator} />
        <Route exact path="/educators/review" component={ProgramReview} />
        <Route exact path="/educators/review/:educatorId" component={ProgramReview} />
        <Route exact path="/educators/review/:educatorId/edit/:reviewId" component={ProgramReview} />
        <Route exact path="/educators/review/:educatorId/:slug" component={ProgramReview} />
        <Route exact path="/educators/interview-review" component={EducatorInterviewReview} />
        <Route exact path="/educators/interview-review/:educatorId" component={EducatorInterviewReview} />
        <Route exact path="/educators/interview-review/:educatorId/edit/:reviewId" component={EducatorInterviewReview} />
        <Route exact path="/educators/interview-review/:educatorId/:slug" component={EducatorInterviewReview} />
        <Route exact path="/educators/:filter" component={props => <Educators filter {...props} />} />
        <Route exact path="/educators/:id/:slug/:section" component={Educator} />
        <Route exact path="/educators/:id/:slug/:section/:reviewId" component={Educator} />
        <Route exact path="/educators/:id/:slug/overview" component={Educator} />
        <Route exact path="/educators/:id" component={Educator} />
        <Route exact path="/support-services" component={Partners} />
        <Route exact path="/support-services/new" component={NewPartner} />
        <Route exact path="/support-services/review" component={PartnerReview} />
        <Route exact path="/support-services/review/:partnerId/edit/:reviewId" component={PartnerReview} />
        <Route exact path="/support-services/review/:partnerId/:slug" component={PartnerReview} />
        <Route exact path="/support-services/:filter" component={props => <Partners filter {...props} />} />
        <Route exact path="/support-services/:id" component={Partner} />
        <Route exact path="/support-services/:id/:slug/:section/:reviewId" component={Partner} />
        <Route exact path="/support-services/:id/:slug/:section" component={Partner} />
        <Route exact path="/support-services/:id/:slug/overview" component={Partner} />
        <Route exact path="/jobs" component={JobBrowsing} />
        <Route exact path="/jobs/:subscription" component={props => <JobBrowsing isSubscription {...props} />} />
        <Route exact path="/jobs/:jobId/jazzhr" component={JobBrowsing} />
        <Route exact path="/jobs/:jobId/organizations/:organizationId" component={JobBrowsing} />
        <Route exact path="/jobs/search/:filter" component={props => <JobBrowsing filter {...props} />} />
        <Route exact path="/about" component={About} />
        <Route exact path="/terms-of-use" component={Terms} />
        <Route exact path="/privacy-and-cookie-policy" component={Policy} />
        <Route exact path="/security" component={Security} />
        <Route exact path="/employer" component={CompanyLanding} />
        <Route exact path="/handle-user-verification" component={UserVerification} />
        <Route exact path="/teaser/review/:organizationType/:organizationId/:organizationSlug/:section/:reviewType/:reviewId" component={ReviewTeaser} />
        <Route exact path="/teaser/review/:organizationType/:organizationId/:organizationSlug/:reviewType/:reviewId" component={ReviewTeaser} />
        <Route exact path="/teaser/:postType/:postId" component={PostTeaser} />
        <Route exact path="/community" component={Community} />
        <AuthenticatedRoute exact path="/onboarding" component={Onboarding} />
        <AuthenticatedRoute exact path="/community/post/:postId" component={Community} />
        <AuthenticatedRoute exact path="/community/:topicId+" component={Community} />
        <AuthenticatedRoute exact path="/topics" component={Topics} />
        <AuthenticatedRoute exact path="/dashboard/:section" component={UserDashboard} />
        <AuthenticatedRoute exact path="/dashboard/:section/post/:postId" component={UserDashboard} />
        <AuthenticatedRoute exact path="/dashboard/:section/:organizationType/:organizationId/:reviewType/:reviewId" component={UserDashboard} />
        <AuthenticatedRoute exact path="/dashboard/:section/:activeTab" component={UserDashboard} />
        <AuthenticatedRoute exact path="/dashboard/:section/:activeTab/:jobId" component={UserDashboard} />
        <AuthenticatedRoute exact path="/account-settings/:section" component={AccountSettings} />
        <AuthenticatedRoute exact path="/profile-settings/:section" component={ProfileSettings} />
        <AuthenticatedRoute exact path="/org-admin/candidate/:candidateId" component={props => <Candidate isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/manage-profile/:activeTab" component={ManageProfile} />
        <AuthenticatedRoute exact path="/org-admin/jobs/new" component={props => <JobOpening isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/jobs/:jobId/edit" component={props => <JobOpening isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/jobs/:jobId/clone" component={props => <JobOpening isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/jobs/:jobId" component={props => <JobPreview isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/organizations/:type/:id" component={props => <Organization isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/:section" component={props => <MyCompany isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/:section/post/:postId" component={props => <MyCompany isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/:section/:reviewId" component={props => <MyCompany isOrgAdminPage {...props} />} />
        {/* <AuthenticatedRoute exact path="/org-admin/:section" component={props => <MyEducator isOrgAdminPage {...props} />} /> */}
        {/* <AuthenticatedRoute exact path="/org-admin/:section" component={props => <MySupportService isOrgAdminPage {...props} />} /> */}
        <AuthenticatedRoute exact path="/org-admin/posts" component={props => <AdminPosts isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/org-admin/subscription" component={props => <Subscription isOrgAdminPage {...props} />} />
        <AuthenticatedRoute exact path="/employer-onboarding/:page" component={CompanyOnboarding} />
        <AdminRoute path="/admin">
          <Switch>
            <Redirect from="/admin" exact to="/admin/users" />
            <Route exact path="/admin/users" component={Users} />
            <Route exact path="/admin/users/:id" component={User} />
            <Route exact path="/admin/employers" render={props => (
              <Organizations section="employers" {...props} />
            )} />
            <Route exact path="/admin/educators" render={props => (
              <Organizations section="educators" {...props} />
            )} />
            <Route exact path="/admin/support-services" render={props => (
              <Organizations section="support-services" {...props} />
            )} />
            <Route exact path="/admin/support-services/new" component={props => <NewPartner isAdminRoute {...props} />} />
            <Route exact path="/admin/organizations/:type/settings" component={OrganizationSettings} />
            <Route exact path="/admin/organizations/:type/:id" component={Organization} />
            <Route exact path="/admin/reviews" component={AdminReviews} />
            <Route exact path="/admin/posts" component={AdminPosts} />
            <Route exact path="/admin/subscriptions" component={Subscriptions} />
            <Route component={NotFound} />
          </Switch>
        </AdminRoute>
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}