import React      from 'react';
import cx         from 'classnames';

import './MessageSection.sass'


export default function MessageSection ({title, text, children, className, multiLine}) {
  return(
    <div className={cx("MessageSection", className)}>
      <div className="MessageSection__container card">
        {title &&
          <h3>{multiLine ? title.split('\n').map((item, i) => <span key={i}>{item}</span> ) : title}</h3>
        }
        {text &&
          <div className="secondary-text">
            {text}
          </div>
        }
        {children &&
          <div className="MessageSection__content">
            {children}
          </div>
        }
      </div>
    </div>
  )
}
