import React                   from 'react';
import { compose,
         withProps,
         withHandlers,
         setDisplayName }      from 'recompose';
import Loader                  from 'theme/Loader';

import OrganizationFiltersItem from './OrganizationFiltersItem';

import './OrganizationFilters.sass';

export default compose(
  setDisplayName('OrganizationFilters'),
  withProps(({selectedFilters, filterItems}) => ({
    activeFilters: filterItems.filter(item => Object.keys(selectedFilters).find(key => key === item.name))
  })),
  withHandlers({
    clearFilters: () => filters => filters
      .filter(f => f.name !== 'sort')
      .map(f => f.onChange(null))
  })
)(({
  filter,
  filterItems,
  stateSelectDisabled,
  getAutocomplete,
  getPartnerAutocomplete,
  clearFilters,
  activeFilters,
  loading,
  section,
  mobile
}) => (
  <div className="OrganizationFilters">
    <div className="OrganizationFilters__container">
      <Loader loading={loading} />
      <div className="OrganizationFilters__filters">
        {filterItems?.map((f, index) => (
          <OrganizationFiltersItem
            {...f}
            key={index}
            onClick={f.onChange}
            partnerSelect={f.name === 'partnerId'}
            stateSelectDisabled={stateSelectDisabled}
            getAutocomplete={getAutocomplete}
            getPartnerAutocomplete={getPartnerAutocomplete}
            checkbox={f.component === 'checkbox'}
            section={section}
            mobile={mobile}
          />
        ))}
      </div>
      <div className="OrganizationFilters__actions">
        <button
          className="OrganizationFilters__clearAll"
          onClick={() => clearFilters(activeFilters)}
        >
          <i className="fa fa-times" />
          Clear all filters
        </button>
        <button
          className="OrganizationFilters__filterBtn btn-secondary"
          onClick={filter}
        >
          Filter
        </button>
      </div>
    </div>
  </div>
))
