import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { Helmet }             from 'react-helmet';
import { compose,
         setDisplayName,
         lifecycle,
         withHandlers,
         withState,
         withProps }          from 'recompose';
import cx                     from 'classnames';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import Loader                 from 'theme/Loader';
import Return                 from 'theme/Return';
import Signature              from 'theme/Signature';
import ListItemLabel          from 'theme/ListItemLabel';
import Pie                    from 'theme/Pie';
import MoreInfo               from 'theme/MoreInfo';
import { copy }               from 'theme/content';
import { images }             from 'theme/img/images';
import { withScroll,
         withSignature }      from 'theme/utils/recompose';
import { formatDate }         from 'theme/utils/helpers';
import SchoolRow              from 'jobs/SchoolRow';
import WorkExperienceRow      from 'jobs/WorkExperienceRow';
import CertificationRow       from 'jobs/CertificationRow';
import { getJobApplication,
         shortlist,
         editNotes }          from 'organizationAdmin/actions';
import { downloadFile,
         getUserProfile,
         getCandidateProfile } from 'user/actions';

import './Candidate.sass';


export const Candidate = compose(
  setDisplayName('Candidate'),
  withScroll(),
  withSignature(),
  withState('formVisible', 'setFormVisible', false),
  withState('textareaValue', 'setTextareaValue', ''),
  withProps(({match}) => ({
    candidateId: match.params.candidateId
  })),
  lifecycle({
    componentDidMount() {
      const { getJobApplication, candidateId, getCandidateProfile } = this.props;

      getJobApplication(candidateId)
      .then(response => {
        getCandidateProfile(response.jobApplication.user.id)
      });
    }
  }),
  withHandlers({
    editNotes: ({setFormVisible, setTextareaValue}) => (id, values) => {
      return editNotes(id, values)
      .then(res => {
        setTextareaValue(res.data.employerNotes);
        setFormVisible(false);
      });
    },
    shortlist: ({shortlist, getJobApplication, candidateId}) => (id, value) => {
      return shortlist(id, value)
      .then(() => {
        getJobApplication(candidateId)
      });
    }
  })
)(({
  loading,
  formVisible,
  setFormVisible,
  textareaValue,
  setTextareaValue,
  jobApplication: {id, user, shortlisted, employerNotes, viewed},
  matchingInfo: {matchingRatio},
  candidateProfile: {userCategory, email, locations, earliestAvailability, employments, education, certifications, skills, securityClearance, resumes},
  editNotes,
  downloadResume,
  shortlist,
  userSignature
}) => {
  return (
    <div className="Candidate page">
      <Helmet>
        <title>Vetit — Candidate Profile</title>
      </Helmet>
      <Header />
      <div className="container">
        <Return
          text="Back to job listing"
          link='/org-admin/jobs'
        />
        <div className="Candidate__heading card">
          <Loader loading={loading} />
          {!loading &&
            <Pie
              className="Candidate__circularBar"
              value={matchingRatio}
            />
          }
          <div className="Candidate__headingWrapper">
            <Loader loading={loading} />
            <div className="Candidate__data">
              <div className="Candidate__dataHeader">
                <h3>{user?.firstName} {user?.lastName}</h3>
                {!viewed &&
                  <ListItemLabel text="New" color="orange" />
                }
              </div>
                <p className="Candidate__dataAvailability">
                  Earliest availability:
                  <span> {earliestAvailability ? formatDate(earliestAvailability) : 'not specified'}</span>
                </p>
                <p className="Candidate__dataEmail">{email}</p>
              <div className="Candidate__dataOther">
                <p>
                  {locations?.length > 0
                    ? locations.join('; ')
                    : 'Location not specified'
                  }
                </p>
                {user &&
                  <Signature signature={userSignature} />
                }
              </div>
            </div>
            <div className="Candidate__headingBtns">
              <button
                className="btn-primary"
                onClick={() => downloadResume(user.id)}
                disabled={resumes?.length === 0}
              >
                <img src={images.download} alt="Download a resume" />
                RESUME
              </button>
              <button
                className={cx("btn-primary-ghost", {active: shortlisted})}
                onClick={() => shortlist(id, shortlisted ? false : true)}
              >
                {shortlisted ? 'SHORTLISTED' : 'SHORTLIST'}
              </button>
            </div>
          </div>
        </div>
        <div className="Candidate__container">
          <div className="Candidate__content">
            {(userCategory !== 'Spouse' && userSignature['EAS paygrade']) &&
              <div className={cx("Candidate__militaryBackground card", {notVisible: !userSignature['EAS paygrade']})}>
                <Loader loading={loading} />
                <h5 className="subheader">Military background</h5>
                <div>
                  <p className="Candidate__militaryBackgroundTitle">
                    What might this person’s military background say about them as a future employee?
                    {' '}
                    <MoreInfo text={copy.candidate.militaryBackgroundTip} />
                  </p>
                  <p className="Candidate__militaryBackgroundBody">{copy.candidate.etsPaygrade[userSignature['EAS paygrade']]}</p>
                </div>
              </div>
            }
            <div className="Candidate__description card">
              <Loader loading={loading} />
              <div className="Candidate__descriptionSection">
                <h5 className="subheader">Work experience</h5>
                <div>
                  {employments?.map((w, i) => (
                    <WorkExperienceRow work={w} key={i} />
                  ))}
                </div>
              </div>
              <div className="Candidate__descriptionSection Candidate__skills">
                <h5 className="subheader">Skills</h5>
                <div>
                  {skills?.map((s, i) => (
                    <div className="Candidate__skill taglabel" key={i}>{s}</div>
                  ))}
                </div>
              </div>
              <div className="Candidate__descriptionSection Candidate__education">
                <h5 className="subheader">Education</h5>
                <div>
                  {education?.map((item, i) => (
                    <SchoolRow school={item} key={i} />
                  ))}
                </div>
              </div>
              <div className="Candidate__descriptionSection Candidate__certifications">
                <h5 className="subheader">Certification and courses</h5>
                <div>
                  {certifications?.map((c, i) => (
                    <CertificationRow certification={c} key={i} />
                  ))}
                </div>
              </div>
              <div className="Candidate__descriptionSection Candidate__clearance">
                <h5 className="subheader">Security Clearance</h5>
                {securityClearance && Object.keys(securityClearance).length > 0 &&
                  <div>
                    <span className="label">{securityClearance?.clearance}</span>
                    <span className="text">({securityClearance?.clearanceExpiration && formatDate(securityClearance.clearanceExpiration)})</span>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="Candidate__actions">
            <div className="Candidate__notes card">
              <Loader loading={loading} />
              <div className="Candidate__notesHeading">
                <h4>Your private notes</h4>
                <button
                  onClick={() => formVisible ? editNotes(id, textareaValue) : setFormVisible(true)}
                  className="btn-secondary-outlined"
                >
                  {formVisible ? 'Save' : 'Edit'}
                </button>
              </div>
              <div className="Candidate__notesText">
                {formVisible
                  ?
                    <textarea
                      className="Candidate__textarea"
                      placeholder="Type your note about the candidate..."
                      onChange={e => setTextareaValue(e.target.value)}
                      onKeyPress={e => e.key == 'Enter' && editNotes(id, textareaValue)}
                    />
                  :
                    <div>{textareaValue || employerNotes}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
});

const mapStateToProps = ({data, loading}) => ({
  jobApplication: data.job_application?.jobApplication || {},
  matchingInfo: data.job_application?.matchingInfo || {},
  userProfile: data?.user_profile || {},
  candidateProfile: data?.candidate_profile || {},
  loading: loading.candidate_profile || loading.job_application
});

const mapDispatchToProps = dispatch => ({
  getJobApplication: (id) => new Promise((resolve, reject) => dispatch(getJobApplication(id, {resolve, reject}))),
  getUserProfile: (id) => new Promise((resolve, reject) => dispatch(getUserProfile({id, resolve, reject}))),
  getCandidateProfile: (id) => new Promise((resolve, reject) => dispatch(getCandidateProfile({id, resolve, reject}))),
  editNotes: (id, values) => new Promise((resolve, reject) => dispatch(editNotes(id, values, {resolve, reject}))),
  downloadResume: (userId) => dispatch(downloadFile(userId)),
  shortlist: (id, value) => dispatch(shortlist(id, value))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Candidate));
