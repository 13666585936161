export const labels = {
  quickGlance: item => ([
    {label: 'city', value: item.address && item.address.city},
    {label: 'state', value: item.address && item.address.state},
    {label: 'country', value: item.address && item.address.country},
    {label: 'founded', value: item.founded},
    {label: 'org size', value: item.organizationSize},
    {label: 'type', value: item.type},
    {label: 'industry', value: item.industry},
    {label: 'affinity group size', value: item.affinityGroupSize}
  ]),
  indicators: item => ([
    {label: 'dod skillbridge ready', value: item.indicators && item.indicators.dodSkillbridgeReady},
    {label: 'executive sponsorship', value: item.indicators && item.indicators.executiveSponsorship},
    {label: 'DOL Apprenticeships', value: item.indicators && item.indicators.militaryOnboardingProcess},
    {label: 'mentorship program', value: item.indicators && item.indicators.mentorshipProgram},
    {label: 'ESGR Support Signatory', value: item.indicators && item.indicators.militaryLeavePolicy},
    {label: 'MSEP Support Signatory', value: item.indicators && item.indicators.militarySpousePCSPolicy}
  ]),
  booleanFields: [
    'dod skillbridge ready',
    'executive sponsorship',
    'DOL Apprenticeships',
    'mentorship program',
    'ESGR Support Signatory',
    'MSEP Support Signatory'
  ]
};
