import React                from 'react';
import cx                   from 'classnames';
import PT                   from 'prop-types';
import QuickGlanceItem      from './QuickGlanceItem';

import './QuickGlance.sass';


const chunkify = (arr, size) => arr.reduce((chunks, el, i) => (i % size
  ? chunks[chunks.length - 1].push(el)
  : chunks.push([el])) && chunks, []);

export default function QuickGlance({
  data,
  className,
  labels,
  toString,
  isInDollars,
  booleanFields,
  percentFields,
  hasColumns,
  numberOfRows,
  caution
}) {
  const isCaution = data && data.quickGlance && data.quickGlance.isCaution,
        getValue = value => value != undefined && (isInDollars ? `$${value || toString(value)}` : value || toString(value)),
        checkIfBoolean = label => booleanFields && booleanFields.find(f => f === label),
        checkIfPercent = label => percentFields && percentFields.find(f => f === label),
        columns = chunkify(labels, numberOfRows),
        itemProps = {checkIfBoolean, checkIfPercent, getValue, toString};

  return (
    <div className={cx("QuickGlance", className)}>
      {data.class == 'Educator' && caution &&
        <span className="QuickGlance__caution">{isCaution && 'Note'}</span>
      }
      <div className="QuickGlance__row">
        {hasColumns
          ? columns.map((columnArray, index) => (
            <div key={index} className="QuickGlance__column">
              {columnArray.map(({label, value}, index) => (
                <QuickGlanceItem
                  key={index}
                  label={label}
                  value={value}
                  {...itemProps}
                />
              ))}
            </div>
          ))
          :
          labels.map(({label, value}, index) => (
            <QuickGlanceItem
              key={index}
              label={label}
              value={value}
              {...itemProps}
              studentsDataItem
            />
          ))
        }
      </div>
    </div>
  )
}

QuickGlance.propTypes = {
  data: PT.object.isRequired,
  className: PT.string,
  labels: PT.array,
  isInDollars: PT.bool,
  booleanFields: PT.array,
  percentFields: PT.array,
  hasColumns: PT.bool,
  numberOfRows: PT.number,
  caution: PT.bool
}
