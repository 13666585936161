import React                      from 'react';
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router';
import { Link }                   from 'react-router-dom';
import { Helmet }                 from 'react-helmet';
import { compose,
         withProps,
         withState,
         withHandlers,
         setDisplayName }         from 'recompose';
import { CancelToken }            from 'axios';
import Footer                     from 'theme/Footer';
import Header                     from 'theme/Header';
import MessageSection             from 'theme/MessageSection';
import Tabs                       from 'theme/Tabs';
import { skillExamples }          from 'theme/content';
import { getUserProfile,
         updateUserProfile,
         getLocations,
         getSkills,
         uploadFile,
         deleteFile }             from 'user/actions';

import PersonalInformationForm    from '../Forms/PersonalInformationForm';
import EducationForm              from '../Forms/EducationForm';
import EmploymentForm             from '../Forms/EmploymentForm';

import './ProfileSettings.sass';


const links = [
  {name: 'general', label: 'Personal Information'},
  {name: 'education', label: 'Education'},
  {name: 'employment', label: 'Employment'}
]

const PersonalInformationFormName = "PersonalInfoForm";
const EducationFormName = "EducationForm";
const EmploymentFormName = "EmploymentForm";

const axiosSource = CancelToken.source();

const ProfileSettings = compose(
  setDisplayName('ProfileSettings'),
  withState('progress', 'updateProgress', 0),
  withState('uploadCancelled', 'setUploadCancelled', false),
  withState('submitted', 'setSubmitted', false),
  withState('message', 'setMessage', ''),
  withHandlers({
    submit: ({submit, user}) => values => {
      return submit(user, values)
    },
    uploadFile: ({uploadFile, user, updateProgress, setSubmitted, setMessage}) => forVerification => values => {
      return uploadFile(values, user.id, updateProgress, axiosSource, forVerification)
      .then(() => {
        if(forVerification) {
          setSubmitted(true);
          setMessage('Your file has been sent for verification.');
        }
      })
    },
    cancelUpload: ({setUploadCancelled}) => () => {
      const message = 'Operation cancelled by the user.';
      axiosSource.cancel(message);
      setUploadCancelled(message);
    },
    deleteFile: ({user, getUserProfile}) => key => {
      return deleteFile(user.id, key)
      .then(() => getUserProfile({id: user.id, loader: false}))
    },
    getSkills: ({getSkills}) => value => {
      return getSkills(value)
      .then(({data}) => data.map(item => ({
        value: item,
        label: item
      })))
    }
  }),
  withProps(({match}) => ({
    section: match.params.section
  }))
)(({section,
    submit,
    message,
    userProfile,
    loadingProfile,
    getLocations,
    getSkills,
    uploadFile,
    deleteFile,
    progress,
    cancelUpload,
    uploadCancelled,
    submitted,
    setSubmitted,
    user,
    setMessage
  }) => {
  const uploadProps = {deleteFile, progress, files: userProfile.resumes, cancelUpload, uploadCancelled};

  return (
    <div className="ProfileSettings page">
      <Helmet>
        <title>Vetit – Profile Settings</title>
      </Helmet>
      <Header />
      {submitted
        ?
          <div className="container">
            <MessageSection
              title="Thanks!"
              text={message}
            >
              <Link
                className="btn-primary"
                onClick={() => setSubmitted(false)}
                to="/profile-settings/general"
              >
                Go back to profile settings
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container card">
            <h1 className="ProfileSettings__title">Profile Info</h1>
            <Tabs
              links={links}
              section={section}
              loading={loadingProfile}
              mobileTitle="Profile info"
            >
              {section === 'general' &&
                <PersonalInformationForm
                  onSubmit={submit}
                  className="Tabs__section"
                  form={PersonalInformationFormName}
                  initialValues={userProfile}
                  getLocations={getLocations}
                  user={user}
                  setSubmitted={setSubmitted}
                  setMessage={setMessage}
                  uploadProps={{
                    uploadFile: uploadFile(true),
                    ...uploadProps
                  }}
                  isSettings
                />
              }
              {section === 'education' &&
                <EducationForm
                  onSubmit={submit}
                  className="Tabs__section"
                  form={EducationFormName}
                  initialValues={userProfile}
                  isSettings
                />
              }
              {section === 'employment' &&
                <EmploymentForm
                  onSubmit={submit}
                  className="Tabs__section"
                  form={EmploymentFormName}
                  initialValues={userProfile}
                  uploadProps={{
                    uploadFile: uploadFile(),
                    ...uploadProps
                  }}
                  getSkills={getSkills}
                  skillExamples={skillExamples}
                  isSettings
                />
              }
            </Tabs>
          </div>
      }
      <Footer />
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}) => {
  return {
    user: auth.user,
    userProfile: data?.user_profile || {},
    loadingProfile: loading.user_profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (args = {}) => new Promise((resolve, reject) => dispatch(getUserProfile({...args, resolve, reject}))),
    uploadFile: (values, userId, updateProgress, source, forVerification) => dispatch(uploadFile(values, userId, updateProgress, source, forVerification)),
    deleteFile: (userId, key) => dispatch(deleteFile(userId, key)),
    getLocations: query => dispatch(getLocations(query)),
    getSkills: query => dispatch(getSkills(query)),
    submit: (user, values) => dispatch(updateUserProfile(user.id, values, false, true))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSettings))
