import React from 'react';
import cx    from 'classnames';

import './Avatar.sass';


const Tag = ({className}) => (
  <div className={cx("tag", className)}>
    <div className="hole">
      <div />
    </div>
    <div className="stripe" />
    <div className="stripe short" />
    <div className="stripe" />
  </div>
)

const PATH = "M301,350Q236,328,229,269Q237,213,279,206Q332,201,357,216C391,238,398,262,433,263Q485,304,434,346";

const Chain = () => (
  <svg viewBox="0,0,500,500">
    <path id="curve" d={PATH} />
    <path id="curve" className="dots" d={PATH} />
  </svg>
)

export default function Avatar() {
  return (
    <div className="Avatar card">
      <div className="Avatar__bgr" />
      <div className="Avatar__icon">
        <Tag className="first" />
        <Tag className="second" />
        <Chain />
      </div>
    </div>
  )
}
