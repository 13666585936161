import React                from 'react';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import { Helmet }           from 'react-helmet';
import { lifecycle,
         compose,
         setDisplayName,
         withProps,
         withState,
         withHandlers }     from 'recompose';
import PT                   from 'prop-types';
import { env }              from 'theme/content';
import Follows              from 'theme/Follows';
import Footer               from 'theme/Footer';
import Header               from 'theme/Header';
import Loader               from 'theme/Loader';
import Modal                from 'theme/Modal';
import Return               from 'theme/Return';
import Pagination           from 'theme/Pagination';
import PageHeading          from 'theme/PageHeading';
import SingleReview         from 'theme/SingleReview';
import ReviewsSection       from 'theme/ReviewsSection';
import ManagerLogo          from 'theme/ManagerLogo';
import ScrollingBar         from 'theme/ScrollingBar';
import { withScroll,
         withModal,
         withSignature }    from 'theme/utils/recompose';
import Feed                 from 'feed/Feed';
import Post                 from 'comments/Post';
import AddPostForm          from 'admin/AdminPosts/Form';
import { addAdminPost,
         voteAdminPost,
         updateAdminPost,
         getAdminPost }     from 'admin/actions';
import { voteComment,
         follow,
         unfollow }         from 'comments/actions';
import { getCompany,
         getReview,
         getInterviews,
         voteInterview,
         voteJobReview,
         getJobReviews }    from 'companies/actions';
import { getJobs,
         getOrganizationFeed,
         viewApplication }  from 'organizationAdmin/actions';
import { getFollowed }      from 'user/actions';

import MyCompanyJobs        from './Jobs';
import FirstTime            from '../FirstTime';

import './MyCompany.sass';


const ORG_TYPE_MAP = {
  'Educator': 'educators',
  'Employer': 'employers',
  'Partner': 'partners'
};

const headingWithCount = (name, count) => {
  if (!count || count == 0)
    return <span>{name}</span>;
  return (
    <span>
      {name}
      <span className="count"> ({count})</span>
    </span>
  )
};

const headingSections = (reviewsCount, interviewsCount, jobsCount) => [
  {key: 'feed', name: <span><span className="hidden-xs"></span>Feed</span>},
  {key: 'jobs', name: headingWithCount('Jobs', jobsCount)},
  {key: 'reviews', name: headingWithCount('Employees', reviewsCount)},
  {key: 'interviews', name: headingWithCount('Applicants', interviewsCount)},
  // {key: 'stats', name: <span><span className="hidden-xs"></span>Stats</span>}
];

const formName = 'OrganizationPostForm';
const MODAL_NAMES = {
  NEW_POST: 'NEW_POST',
  EDIT_POST: 'EDIT_POST',
  FIRST_TIME: 'FIRST_TIME'
};

const MyCompany = compose(
  setDisplayName('MyCompany'),
  withScroll(),
  withModal(),
  withSignature(),
  withState('page', 'setPage', 0),
  withProps(({match, followed}) => ({
    activeSection: match.params.section,
    postId: match.params.postId,
    reviewId: match.params.reviewId,
    followedOrganizations: [
      {name: 'companies', label: 'Employers', data: followed.filter(f => f.class == 'Employer')},
      {name: 'educators', label: 'Educators', data: followed.filter(f => f.class == 'Educator')},
      {name: 'support-services', label: 'Support Services', data: followed.filter(f => f.class == 'Partner')}
    ]
  })),
  withProps(({followedOrganizations}) => ({
    allFollowedOrganizations: followedOrganizations
      .map(type => type.data?.map((item) => ({...item, organizationType: type.name})))
      .reduce((a, b) => a.concat(b), [])
  })),
  withHandlers({
    getReview: ({getReview, user, reviewId, activeSection}) => (args = {}) => {
      return getReview({...args, companyId: user.organizationId, reviewId, reviewType: activeSection == 'reviews' ? 'jobs' : 'interviews'})
    }
  }),
  lifecycle({
    componentDidMount() {
      const { getCompany, getJobReviews, user, getInterviews, getJobs, getFollowed, getReview, reviewId, getAdminPost, postId, openModal } = this.props;

      getCompany(user.organizationId);
      getFollowed();
      getJobReviews({id: user.organizationId});
      getInterviews({id: user.organizationId});
      getJobs();

      if (reviewId)
        getReview();

      if (postId)
        getAdminPost({postId});

      if (location.search.includes('first-time'))
        openModal(MODAL_NAMES.FIRST_TIME);
    },
    componentDidUpdate(prevProps) {
      const { getJobReviews, getInterviews, user, activeSection, setPage, getReview, reviewId, getAdminPost, postId } = this.props;
      if (activeSection && activeSection != prevProps.activeSection) {
        if (activeSection == 'reviews') {
          getJobReviews({id: user.organizationId});
          setPage(0);

          if (reviewId)
            getReview();
        }

        if (activeSection == 'interviews') {
          getInterviews({id: user.organizationId});
          setPage(0);

          if (reviewId)
            getReview();
        }

        if (postId) {
          window.scrollTo(0, 0);
          getAdminPost({postId});
        }
      }
    }
  }),
  withHandlers({
    getJobReviews: ({getJobReviews, user, setPage}) => page => {
      getJobReviews({page, id: user.organizationId})
      .then(() => setPage(page));
    },
    getInterviews: ({getInterviews, user, setPage}) => page => {
      getInterviews({page, id: user.organizationId})
      .then(() => setPage(page));
    },
    submit: ({addAdminPost, closeModal, user, getOrganizationFeed, page}) => values => {
      return addAdminPost(values, user.organizationId, {page})
      .then(() => {
        getOrganizationFeed({page});
        closeModal();
      });
    },
    redirectToReview: ({history, activeSection}) => (reviewId, reported = false) => {
      const url = `/org-admin/${activeSection}/${reviewId}`;
      history.push(reported ? `${url}${env.QUERY.reviewReported}` : url);
    },
    voteJobReview: ({voteJobReview, page, user})  => (...args) => {
      return voteJobReview(user.organizationId, ...args, {page})
    },
    voteInterview: ({voteInterview, page, user}) => (...args) => {
      return voteInterview(user.organizationId, ...args, {page})
    },
    voteSingleJobReview: ({voteJobReview, user, getReview}) => (...args) => {
      return voteJobReview(user.organizationId, ...args)
      .then(() => getReview({loader: false}));
    },
    voteSingleInterview: ({voteInterview, user, getReview}) => (...args) => {
      return voteInterview(user.organizationId, ...args)
      .then(() => getReview({loader: false}));
    },
    voteAdminPost: ({voteAdminPost, getAdminPost, postId}) => (id, action) => {
      return voteAdminPost(id, action)
      .then(() => {
        getAdminPost({postId, loader: false});
      })
    },
    updateAdminPost: ({updateAdminPost, closeModal, getAdminPost, postId}) => values => {
      return updateAdminPost(values)
      .then(() => {
        getAdminPost({postId});
        closeModal();
      });
    }
  })
)(
  ({employer,
    feed,
    count,
    adminPost,
    getOrganizationFeed,
    followedOrganizations,
    allFollowedOrganizations,
    companyReviews,
    activeSection,
    interviews,
    loading,
    user,
    userSignature,
    page,
    getJobReviews,
    getInterviews,
    isOrgAdminPage,
    jobs,
    viewApplication,
    modal,
    closeModal,
    openModal,
    submit,
    voteComment,
    history,
    postId,
    reviewId,
    review,
    redirectToReview,
    voteJobReview,
    voteInterview,
    voteSingleJobReview,
    voteSingleInterview,
    voteAdminPost,
    updateAdminPost
  }) => {
  const postProps = {
    vote: voteAdminPost,
    authUser: user,
    history,
    editPost: () => openModal(MODAL_NAMES.EDIT_POST, adminPost)
  };

  const actionsBtnProps = {
    title: 'Manage profile',
    links: [
      {name: 'Edit', link: '/org-admin/manage-profile/general'},
      {name: 'Preview', link: `/companies/${employer.id}/${employer.slug}/overview`}
    ]
  };

  return (
    <div className="MyCompany page">
        <Helmet>
          <title>{`Vetit – ${employer.name}`}</title>
        </Helmet>
        <Header />
        <div className="container">
          <PageHeading
            manager={employer}
            activeSection={activeSection}
            sections={headingSections(count.reviews, count.interviews, count.jobs)}
            prefix="companies"
            loading={loading.company}
            user={user}
            isOrgAdminPage={isOrgAdminPage}
            actionsBtnProps={actionsBtnProps}
          />
          {(activeSection == 'feed' && !postId) &&
            <div className="MyCompany__container">
              <div className="MyCompany__feedContainer">
                <div className="MyCompany__writePost card">
                  <Loader loading={loading.feed} />
                  <div
                    className="MyCompany__ask"
                    onClick={() => openModal(MODAL_NAMES.NEW_POST)}
                  >
                    <i className="fa fa-pencil-square-o" />
                    Write new post...
                  </div>
                </div>
                <Feed
                  data={feed}
                  key={count.feed}
                  dataCount={count.feed}
                  getData={getOrganizationFeed}
                  loadingFeed={loading.feed}
                  user={user}
                  userSignature={userSignature}
                  history={history}
                  follow={follow}
                  unfollow={unfollow}
                  voteComment={voteComment}
                  emptyText="No more posts!"
                />
              </div>
              <Follows
                className="MyCompany__follows"
                organizationTypes={followedOrganizations}
                loading={loading.followed}
              />
              <ScrollingBar
                className="MyCompany__mobileFollows"
                loading={loading.followed}
                title="My follows"
              >
                {allFollowedOrganizations.map(manager => (
                  <Link
                    key={manager.id}
                    to={`/${ORG_TYPE_MAP[manager.class]}/${manager.id}/${manager.slug}/overview`}
                  >
                    <ManagerLogo
                      manager={manager}
                      className="Follows__managersLogo"
                    />
                  </Link>
                ))}
              </ScrollingBar>
            </div>
          }
          {activeSection == 'feed' && postId &&
            <div className="MyCompany__row">
              <Loader loading={loading.adminPost} />
              <Return
                text="Go back"
                goTo={() => history.goBack()}
              />
              {(adminPost && Object.keys(adminPost).length > 0) &&
                <Post
                  {...postProps}
                  post={adminPost}
                  postId={postId}
                  topLvlComment
                  admin
                />
              }
            </div>
          }
          {activeSection == 'jobs' &&
            <MyCompanyJobs
              jobs={jobs}
              jobsCount={count.jobs}
              viewApplication={viewApplication}
            />
          }
          {activeSection == 'reviews' && !reviewId &&
            <div className="MyCompany__row">
              <div className="position-relative">
                <Loader loading={loading.reviews} />
                <ReviewsSection
                  manager={employer}
                  reviews={companyReviews}
                  title="Employee Experiences"
                  reviewName="Employee Reviews"
                  link={`/companies/${employer.id}/${employer.slug}/reviews`}
                  linkAdd="/companies/review"
                  isOrgAdminPage={isOrgAdminPage}
                  redirectToReview={redirectToReview}
                  voteReview={voteJobReview}
                  all
                />
              </div>
              <Pagination
                itemsCount={count.reviews}
                gotoPage={getJobReviews}
                activePage={page}
                disabled={loading.reviews}
              />
            </div>
          }
          {activeSection == 'reviews' && reviewId &&
            <SingleReview
              className="MyCompany__row"
              review={review}
              id={reviewId}
              user={user}
              voteReview={voteSingleJobReview}
              loading={loading.review}
              goBack={() => history.goBack()}
            />
          }
          {activeSection == 'interviews' && !reviewId &&
            <div className="MyCompany__row">
              <div className="position-relative">
                <Loader loading={loading.interviews} />
                <ReviewsSection
                  manager={employer}
                  reviews={interviews}
                  title="Applicant Experiences"
                  reviewName="Applicant Reviews"
                  link={`/companies/${employer.id}/${employer.slug}/interviews`}
                  linkAdd="/companies/interview-review"
                  isOrgAdminPage={isOrgAdminPage}
                  redirectToReview={redirectToReview}
                  voteReview={voteInterview}
                  all
                />
              </div>
              <Pagination
                itemsCount={count.interviews}
                gotoPage={getInterviews}
                activePage={page}
                disabled={loading.interviews}
              />
            </div>
          }
          {activeSection == 'interviews' && reviewId &&
            <SingleReview
              className="MyCompany__row"
              review={review}
              id={reviewId}
              user={user}
              voteReview={voteSingleInterview}
              loading={loading.review}
              goBack={() => history.goBack()}
            />
          }
        </div>
        <Footer />

        <Modal
          isOpen={modal.visible}
          close={closeModal}
          className="MyCompany__modal"
          closeBtn
        >
          {(() => {
            switch(modal.name) {
              case MODAL_NAMES.NEW_POST:
                return (
                  <AddPostForm
                    onSubmit={submit}
                    form={formName}
                    title="Add new post"
                  />
                )
              case MODAL_NAMES.EDIT_POST:
                return (
                  <AddPostForm
                    initialValues={adminPost}
                    onSubmit={updateAdminPost}
                    form={formName}
                    title="Edit post"
                    btnText="Save your edit"
                  />
                )
              case MODAL_NAMES.FIRST_TIME:
                return (
                  <FirstTime />
                )
            }
          })()}
        </Modal>
      </div>
    )
  }
);

MyCompany.propTypes = {
  employer: PT.object,
  getCompany: PT.func
};

const mapStateToProps = ({auth, data, loading}, {match: {params: {reviewId}}}) => {
  const id = auth.user?.organizationId;

  return {
    user: auth.user,
    userProfile: data.user_profile || {},
    employer: data[`company:${id}`] || {},
    followed: data.followed?.data || [],
    feed: data.organization_feed?.data || [],
    jobs: data.jobs?.data || [],
    adminPost: data.admin_post || {},
    review: data[`review:${reviewId}`] || {},
    companyReviews: data[`company:${id}:reviews`]?.data || [],
    interviews: data[`company:${id}:interviews`]?.data || [],
    count: {
      feed: data.organization_feed?.count || 0,
      jobs: data.jobs?.count || 0,
      reviews: data[`company:${id}:reviews`]?.count || 0,
      interviews: data[`company:${id}:interviews`]?.count || 0
    },
    loading: {
      feed: loading.organization_feed,
      followed: loading.followed,
      company: loading[`company:${id}`],
      reviews: loading[`company:${id}:reviews`],
      interviews: loading[`company:${id}:interviews`],
      review: loading[`review:${reviewId}`],
      adminPost: loading.admin_post
    }
  }
};

const mapDispatchToProps = dispatch => ({
  getCompany: id => new Promise((resolve, reject) => dispatch(getCompany(id, {resolve, reject}))),
  getAdminPost: (args = {}) => new Promise((resolve, reject) => dispatch(getAdminPost({...args, resolve, reject}))),
  getReview: (args = {}) => new Promise((resolve, reject) => dispatch(getReview({...args, resolve, reject}))),
  getOrganizationFeed: (args = {}) => new Promise((resolve, reject) => dispatch(getOrganizationFeed({...args, resolve, reject}))),
  getJobReviews: ({page, id}) => new Promise((resolve, reject) => dispatch(getJobReviews(id, {page, resolve, reject}))),
  getInterviews: ({page, id}) => new Promise((resolve, reject) => dispatch(getInterviews(id, {page, resolve, reject}))),
  getJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getJobs({...args, resolve, reject}))),
  getFollowed: () => new Promise((resolve, reject) => dispatch(getFollowed({resolve, reject}))),
  addAdminPost: (values, organizationId, {page = {}}) => dispatch(addAdminPost({values, page, organizationId, form: formName})),
  viewApplication: (id) => dispatch(viewApplication(id)),
  voteComment: (id, action, {page, pageSize}) => dispatch(voteComment(id, action, {page, pageSize})),
  voteJobReview: (...args) => dispatch(voteJobReview(...args)),
  voteInterview: (...args) => dispatch(voteInterview(...args)),
  voteAdminPost: (id, action) => dispatch(voteAdminPost(id, action)),
  updateAdminPost: (values) => dispatch(updateAdminPost({values}))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCompany));
