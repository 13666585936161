import React          from 'react';
import { components } from 'react-select';
import { images }     from 'theme/img/images';


export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.searchIcon
        ? <img src={images.search} alt="search" />
        : <img src={images.down} alt="dropdown" />
      }
    </components.DropdownIndicator>
  );
};

export const MultiValueContainer = props => {
  return (
    <components.MultiValueContainer {...props} />
  );
};

const suggestedStyle = {
  display: 'block',
  margin: '10px 0',
  paddingLeft: '17px',
  color: '#828DA1',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: 0
};


export const Menu = props => {
  const { options, children, text } = props;

  return (
    <components.Menu {...props}>
      {options.length > 0 &&
        <span style={suggestedStyle}>{text}</span>
      }
      {children}
    </components.Menu>
  );
};
