import React            from 'react';

import './ImageBrowser.sass';

export default function ImageBrowser({images, defaultSize = 2, openGallery}) {
  return (
    <div className="ImageBrowser">
      {images.slice(0, defaultSize).map((i, index) => (
        <div
          key={index}
          style={{backgroundImage: `url(${i})`}}
          className="ImageBrowser__image"
          onClick={() => openGallery(index)}
        >
          {(images.length > defaultSize) && (index === defaultSize - 1) &&
            <div className="ImageBrowser__imageOverlay">
              <span>+ {images.length - defaultSize}</span>
            </div>
          }
        </div>
      ))}
    </div>
  )
}
