import React                      from 'react';
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router';
import { Helmet }                 from 'react-helmet';
import { compose,
         lifecycle,
         withProps,
         withHandlers,
         withStateHandlers,
         withState }              from 'recompose';
import Footer                     from 'theme/Footer';
import Header                     from 'theme/Header';
import withDebounce               from 'theme/utils/recompose';
import { getEducators,
         getFilters,
         getAutocomplete,
         follow,
         unfollow }               from 'educators/actions';
import { getAutocomplete as
         getPartnerAutocomplete } from 'partners/actions';
import Search                     from 'searchResults/SearchResults';

import './Educators.sass';


const Educators = compose(
  withState('activePage', 'setPage', 0),
  withStateHandlers({
    selectedFilters: {
      sort: {
        value: 'programReviewCount',
        dir: 'down'
      }
    }
  },
  {
    setFilters: ({selectedFilters}) => (filterName, value) => {
      const f = value === null
      ? Object.keys(selectedFilters).reduce((obj, key) => {
        if (key != filterName) {
          obj[key] = selectedFilters[key];
        }
        return obj;
      }, {})
      : {...selectedFilters, [filterName]: value};

      return {selectedFilters: f};
    }
  }),
  withProps(({selectedFilters, match}) => ({
    sort: selectedFilters.sort && `${selectedFilters.sort.value}:${selectedFilters.sort.dir == 'down' ? 'd' : 'a'}`,
    urlQuery: match.params.filter?.split('=').pop()
  })),
  withHandlers({
    search: ({getEducators, page = 0, query, urlQuery}) => () => getEducators(page)(query || urlQuery),
    searchEducators: ({getEducators, page = 0}) => queryValue => {
      return getEducators({page})(queryValue)
    },
    getEducators: ({getEducators, activePage, query, setPage}) => page => {
      setPage(page);
      getEducators({page})(query)
      .catch(() => setPage(activePage));
    },
    filterEducators: ({getEducators, activePage}) => (args = {}) => {
      return getEducators({page: activePage, ...args})(args.query);
    },
    follow: ({follow, getEducators, selectedFilters, sort, query, urlQuery}) => (activePage, queryValue) => (...args) => {
      return follow(...args)
      .then(() => getEducators({page: activePage, loader: false, filters: {...selectedFilters, sort}})(queryValue || query || urlQuery))
    },
    unfollow: ({unfollow, getEducators, selectedFilters, sort, query, urlQuery}) => (activePage, queryValue) => (...args) => {
      return unfollow(...args)
      .then(() => getEducators({page: activePage, loader: false, filters: {...selectedFilters, sort}})(queryValue || query || urlQuery))
    }
  }),
  withDebounce({withLifecycle: true}),
  lifecycle({
    componentDidMount() {
      this.props.getFilters();
    }
  })
)(({
  educators,
  query,
  filterValues,
  loading,
  user,
  follow,
  unfollow,
  partners,
  educatorsCount,
  setFilters,
  selectedFilters,
  filterEducators,
  sort,
  searchEducators,
  getAutocomplete,
  getPartnerAutocomplete
  }) => (
  <div className="Educators page">
    <Helmet>
      <title>Vetit – Educators</title>
    </Helmet>
    <Header />
    <div className="container">
      <Search
        results={educators}
        name="school"
        loading={loading}
        user={user}
        itemsCount={educatorsCount}
        follow={follow}
        unfollow={unfollow}
        headerQuery={query}
        partners={partners}
        filter={filterEducators}
        filterValues={filterValues}
        setFilters={setFilters}
        selectedFilters={selectedFilters}
        sort={sort}
        search={searchEducators}
        getAutocomplete={getAutocomplete}
        getPartnerAutocomplete={getPartnerAutocomplete}
      />
    </div>
    <Footer />
  </div>
))

const mapStateToProps = ({auth, data: {educators, filter}, loading, header}) => ({
  user: auth.user,
  educators: (educators?.topHit ? [educators?.topHit, ...educators?.data] : educators?.data) || [],
  educatorsCount: educators?.count || 0,
  companiesCount: educators?.companiesCount || 0,
  loading: loading.educators,
  query: header.query,
  partners: educators?.partners || [],
  partnersCount: educators?.partnersCount || 0,
  filterValues: filter || []
})


const mapDispatchToProps = (dispatch) => ({
  getEducators: (args = {}) => (query) => new Promise((resolve, reject) => dispatch(getEducators({...args, query, resolve, reject, dispatch}))),
  getAutocomplete: (args) => dispatch(getAutocomplete(args)),
  getPartnerAutocomplete: (args) => dispatch(getPartnerAutocomplete(args)),
  getFilters: (args = {}) => new Promise((resolve, reject) => dispatch(getFilters({...args, resolve, reject}))),
  follow: (...args) => dispatch(follow(...args)),
  unfollow: (...args) => dispatch(unfollow(...args))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Educators))
