import React                      from 'react';
import { reduxForm, Field }       from 'redux-form';
// import { API_CONF }               from 'api';
import { TextField }              from 'theme/Form';
import FileUpload                 from 'theme/FileUpload';
import MoreInfo                   from 'theme/MoreInfo';
import { copy }                   from 'theme/content';
import { milEmail }               from 'theme/utils/validate';

import './VerifyForm.sass';


export default reduxForm({
  enableReinitialize: true
})(
  ({handleSubmit, invalid, submitting, pristine, uploadProps}) => {
    return (
      <div className="VerifyForm">
        <h3>Verify your identity</h3>
        <span className="VerifyForm__info">Verify via your .mil email address</span>
        <div className="VerifyForm__section">
          <form onSubmit={handleSubmit} className="VerifyForm__form">
            <Field
              name="milEmail"
              label=".mil email"
              validate={milEmail}
              placeholder="enter your .mil email address"
              component={TextField}
              size="medium"
            />
            <button
              type="submit"
              className="btn-primary"
              disabled={pristine || submitting || invalid}
            >
              Send verification message
            </button>
          </form>
        </div>
        {/* <div className="VerifyForm__section">
          <a
            className="btn-primary"
            href={`https://sandbox-api.va.gov/oauth2/authorization?client_id=${API_CONF.VA_CLIENT_ID}&redirect_uri=${API_CONF.VA_REDIRECT_URI}&response_type=code&state=${generateSecret()}&scope=openid+veteran_status.read`}
          >
            Use VA Lighthouse
          </a>
        </div> */}
        <div className="VerifyForm__section VerifyForm__upload">
          <span className="form-label">
            Upload proof
            <MoreInfo text={copy.fileProof} />
          </span>
          <FileUpload {...uploadProps} />
        </div>
      </div>
    )
  }
)
