import React                       from 'react';
import { connect }                 from 'react-redux';
import { withRouter }              from 'react-router';
import { Link }                    from 'react-router-dom';
import { Helmet }                  from 'react-helmet';
import { ReactSVG }                from 'react-svg';
import { TransitionGroup,
         CSSTransition }           from 'react-transition-group';
import { lifecycle,
         compose,
         setDisplayName,
         withProps,
         withState,
         withHandlers }            from 'recompose';
import qs                          from 'qs';
import cx                          from 'classnames';
import Footer                      from 'theme/Footer';
import Header                      from 'theme/Header';
import Rating                      from 'theme/Rating';
import Carousel                    from 'theme/Carousel';
import ManagerLogo                 from 'theme/ManagerLogo';
import CustomRadio                 from 'theme/CustomRadio';
import { images }                  from 'theme/img/images';
import { withScroll,
         withScreenWidthDetection }from 'theme/utils/recompose';
import { constants }               from 'theme/utils/constants';
import { getPremiumOrganizations } from 'landing/actions';
import { getAutocomplete }         from 'jobs/actions';
import JobSearch                   from 'jobs/JobSearch';

import milestones                  from './img/milestones.png';

import './Landing.sass';

const FEATURES = [
  {image: images.feature1, title: 'Become Self Aware'},
  {image: images.feature2, title: 'Identify Skill Gaps'},
  {image: images.feature3, title: 'Research Jobs'},
  {image: images.feature4, title: 'Match & Apply'},
  {image: images.feature5, title: 'Engage Privately'},
  {image: images.feature6, title: 'Learn Industries'}
];

const SEARCHES = [
  {name: 'jobs', label: 'Jobs'},
  {type: 'companies', name: 'companies', label: 'Companies'},
  {type: 'schools', name: 'educators', label: 'Education'},
  {type: 'support services', name: 'support-services', label: 'Support'}
];

const TRENDING_SEARCHES = [
  {name: 'apprenticeships', topic: 'general/Apprenticeships'},
  {name: 'skillbridge', topic: 'general/dod-skillbridge'},
  {name: 'pmp', topic: 'general/Project-Management'},
  {name: 'gi bill', topic: 'general/va-gi-bill'},
  {name: 'mba', topic: 'general/MBA'},
  {name: 'service orgs', topic: 'general/Service-Orgs'},
  {name: 'government jobs', topic: 'general/Federal-Jobs'},
  {name: 'entrepreneurship', topic: 'general/Entrepreneurship'},
  {name: 'security clearances', topic: 'general/Security-Clearances'},
  {name: 'benefits', topic: 'general/benefits'},
  {name: 'student loans', topic: 'general/Student-Loans'},
  {name: 'franchises', topic: 'general/Franchises'},
  {name: 'spouse employment', topic: 'general/Spouse-Employment'}
];

const Landing = compose(
  setDisplayName('Landing'),
  withScroll(),
  withScreenWidthDetection(),
  withState('organizationIndex', 'changeCompany', 0),
  withState('activeSearch', 'setSearch', SEARCHES[0]),
  withState('query', 'setQuery', ''),
  withState('selectedLocation', 'selectLocation', ''),
  withState('interval', 'setInter', null),
  withHandlers({
    organizationsInterval: ({organizations, changeCompany, organizationIndex}) => () => {
      const newI = (organizationIndex + 1) % organizations.length;
      changeCompany(newI);
    },
    change: ({setQuery}) => e => {
      const value = e.target.value;
      setQuery(value);
    },
    getAutocomplete: ({getAutocomplete}) => value => {
      return getAutocomplete({prefix: value})
      .then(res => res.map(r => ({
        value: r,
        label: r
      })))
    },
    onKeyPress: ({history, activeSearch, query}) => e => {
      if (e.key === 'Enter')
        history.push(`/${activeSearch.name}/name=${query}`);
    }
  }),
  withProps(({organizationIndex, organizations}) => ({
    organization: organizations && organizations[organizationIndex]
  })),
  withProps(({query, selectedLocation}) => {
    const filterString = {...(query ? {query} : {}), ...(selectedLocation ? {location: selectedLocation} : {})};

    return {
      filterParam: qs.stringify(filterString, {arrayFormat: 'repeat'})
    }
  }),
  lifecycle({
    componentDidMount() {
      const { getPremiumOrganizations, user, setInter, organizationsInterval } = this.props;

      if (user) {
        getPremiumOrganizations()
        .then(organizations => organizations.length && setInter(setInterval(organizationsInterval, constants.LANDING.INTERVAL)))
      }
    },
    componentWillUnmount() {
      clearInterval(this.props.interval);
    }
  })
)(
  ({organization, filterParam, screenWidth, organizations, organizationIndex, user, setSearch, activeSearch, query, setQuery, change, selectLocation, getAutocomplete, history, onKeyPress}) => {
    return (
      <div className="Landing page">
        <Helmet>
          <title>Vet it</title>
          <meta name="description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
          <meta name="image" content={images.shareDefaultImage} />
          <meta itemProp="name" content="Vet it" />
          <meta itemProp="description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
          <meta itemProp="image" content={images.shareDefaultImage} />
          <meta name="og:title" content="Vet it" />
          <meta name="og:description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
          <meta name="og:image" content={images.shareDefaultImage} />
          <meta name="og:url" content={location.href} />
          <meta name="og:site_name" content="Vet it" />
          <meta name="og:type" content="website" />
          <meta name="twitter:title" content='Vet it' />
          <meta name="twitter:description" content='Vet it. A professional community for veterans and spouses with free inside looks at post-military career paths. Vetters transition better!' />
          <meta name="twitter:image" content={images.shareDefaultImage} />
        </Helmet>
        <Header />
        <div className="Landing__container">
          <div className="Landing__main">
            <div className="overlay" />
            <div className="container">
              <h1>The best career paths are being marked. <br />Vet yours.</h1>
              <div className="Landing__search">
                <div className="Landing__searchControls">
                  {SEARCHES.map((s, i) => (
                    <div
                      className={cx("Landing__searchControl", {active: s.name === activeSearch.name})}
                      key={i}
                    >
                      <CustomRadio
                        onClick={() => setSearch(s)}
                        value={activeSearch.name === s.name}
                      />
                      <label htmlFor={s.name}>{s.label}</label>
                    </div>
                  ))}
                </div>
                <div className="Landing__searchContainer card">
                  {activeSearch.name === 'jobs'
                    ?
                      <JobSearch
                        className="Landing__jobSearch"
                        query={query}
                        setQuery={setQuery}
                        loadOptions={getAutocomplete}
                        onSelectOption={selectLocation}
                        search={() => history.push(`/${activeSearch.name}/search/filter?${filterParam}`)}
                        redirectLink={`/${activeSearch.name}/search/filter?${filterParam}`}
                      />
                    :
                      <div className="Landing__searchOrganizations">
                        <input
                          className="Landing__searchInput"
                          placeholder="Search by name and read reviews by veterans"
                          onChange={change}
                          onKeyPress={e => onKeyPress(e)}
                        />
                        <Link
                          className="btn-primary"
                          to={`/${activeSearch.name}/name=${query}`}
                        >
                          Find {activeSearch.type}
                        </Link>
                      </div>
                  }
                </div>
                <div className="Landing__trendingSearches">
                  <span>Trending in the community: </span>
                  <div>
                    {TRENDING_SEARCHES.map(s => (
                      <Link
                        key={s.name}
                        to={user ? `/community/${s.topic}` : `/auth/signin?trendingSearch=${s.topic}`}
                        className="Landing__trendingSearch"
                      >
                        {s.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <span className="Landing__hiring">
                Are You Hiring?&nbsp;
                <Link to="/employer">Create employer account</Link>
              </span>
            </div>
          </div>
          <div className="Landing__features">
            <div className="container">
              <h2>Connect with the Best Military<br />Transition Resources</h2>
              <Carousel
                className="Landing__carousel"
                items={FEATURES}
                screenWidth={screenWidth}
              />
            </div>
          </div>
          <div className="Landing__career">
            <div className="container">
              <div className="Landing__careerHeading">
                <h2>Vetters transition better</h2>
                <p>Our professional community helps military veterans and employers create enduring professional matches. <br />Engage with confidence today! <span>#vetitforward</span></p>
              </div>
              <div className="Landing__careerActions">
                <div className="Landing__careerAction">
                  <div className="Landing__careerIcon">
                    <img src={images.compass} alt="Assess & Plot" />
                  </div>
                  <h4>Assess & Plot</h4>
                  <p>Career paths are journeys that require constant balance between self and industry awareness. Identify the skills, education, and training you’ll need to own your future.</p>
                </div>
                <div className="Landing__careerAction">
                  <div className="Landing__careerIcon">
                    <img src={images.binoculars} alt="Navigate & Discover" />
                  </div>
                  <h4>Navigate & Discover</h4>
                  <p>Discover more than simply what jobs you can do. Our matching recommendations  include honest insights from your peers, helping you decide where you’ll be happiest.</p>
                </div>
                <div className="Landing__careerAction">
                  <div className="Landing__careerIcon">
                    <img src={images.roadsign} alt="Engage & Retain" />
                  </div>
                  <h4>Engage & Retain</h4>
                  <p>Engaging smartly, sincerely, and in the same language helps veterans and employers create realistic expectations that lead to enduring professional matches.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="Landing__mission">
            <div className="container">
              <div>
                <h5 className="subheader">Our mission</h5>
                <h2 className="content">Get real. Get smart. Vet it!</h2>
                <Link to="/jobs" className="btn-primary">Start now</Link>
              </div>
              <div className="Landing__missionImg">
                <img src={milestones} alt="milestones" />
              </div>
            </div>
          </div>
          <div className="Landing__hero">
          {organization &&
            <>
              <TransitionGroup className="Landing__heroWrapper">
                <CSSTransition
                  key={organization ? organization.id : -1}
                  timeout={500}
                  classNames="fade"
                >
                  <div className="Landing__heroImg" style={{backgroundImage: organization && `url(${organization.premiumFeatures.imgUrl})`}}>
                    {organization && organization.premiumFeatures.overlayOnLanding &&
                      <div className="Landing__heroOverlay" style={{background: organization && organization.premiumFeatures.overlayColor}} />
                    }
                    <div className="Landing__dots">
                      {organizations.map((o, i) => (
                        <div className={cx("Landing__dot", {active: i == organizationIndex})} key={i} />
                      ))}
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
              {organization && organization.premiumFeatures.review &&
                <div className="Landing__heroReview">
                  <h3>Authentic career insights<br />Shared by veterans</h3>
                  <div className="Landing__heroReviewWrapper">
                    <ManagerLogo manager={organization} className="Landing__heroReviewLogo" />
                    <Rating rating={organization.premiumFeatures.review.rating} size="semi-md" />
                  </div>
                  <div className="Landing__heroReviewInfo">
                    <h4>"{organization.premiumFeatures.review.title}"</h4>
                    <span>{organization.premiumFeatures.review.reviewer}</span>
                  </div>
                  <Link
                    to={`/${organization.class == 'Educator' ? 'educators' : 'companies'}/${organization.id}/${organization.slug}/overview`}
                    className="seeMore"
                  >
                    See more {organization.name}
                    <ReactSVG
                      src={images.arrowRight}
                      aria-label="See more"
                    />
                  </Link>
                </div>
              }
            </>
          }
          </div>
        </div>
        <Footer />
      </div>
    )
  }
)

const mapStateToProps = ({auth, data: {premium}, loading}) => ({
  user: auth.user,
  organizations: premium || [],
  loading: loading.premium
})

const mapDispatchToProps = dispatch => ({
  getAutocomplete: prefix => dispatch(getAutocomplete(prefix)),
  getPremiumOrganizations: limit => new Promise((resolve, reject) => dispatch(getPremiumOrganizations({limit, resolve, reject})))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing));
