import React                    from 'react';
import { Link }                 from 'react-router-dom';
import { connect }              from 'react-redux';
import { withRouter }           from 'react-router';
import RSelect                  from 'react-select';
import { withState,
         withStateHandlers,
         compose,
         lifecycle,
         withProps,
         withHandlers }         from 'recompose';
import Search                   from 'theme/Search';
import Loader                   from 'theme/Loader';
import Pagination               from 'theme/Pagination';
import Sorting                  from 'theme/Sorting';
import withDebounce             from 'theme/utils/recompose';
import { constants }            from 'theme/utils/constants';
import { getJobs }              from 'organizationAdmin/actions';
import { downloadFile }         from 'user/actions';

import Row                      from './Row';
import './Jobs.sass';


const JOB_STATUSES = [
  {value: '', label: 'All'},
  {value: 'Closed', label: 'Closed'},
  {value: 'Open', label: 'Open'}
];

const COLUMNS = [
  {name: 'referenceNumber', label: 'Ref. Number'},
  {name: 'jobTitle', label: 'Title'},
  {name: 'createdAt', label: 'Posted Date'},
  {name: 'expirationDate', label: 'Expiration Date'}
]

const MyCompanyJobs = compose(
  withState('query', 'setQuery', ''),
  withState('activePage', 'setPage', 0),
  withState('option', 'setOption', ''),
  withState('companyJobs', 'setCompanyJobs', []),
  withState('expandedJobs', 'setExpandedJobs', []),
  withStateHandlers({
    sortColumn: 'createdAt',
    sortDirection: 'd'
  },
  {
    sortBy: () => (column, direction) => {
      return {
        sortColumn: column,
        sortDirection: direction
      }
    }
  }),
  withHandlers({
    getSortedJobs: ({sortBy, getJobs, sortDirection, sortColumn, setPage, query}) => (page, column = 'createdAt', direction, filter) => {
      const dir = direction ||
        (column == sortColumn
        ? sortDirection == 'a' ? 'd' : 'a'
        : 'd');

      sortBy(column || sortColumn, dir);
      setPage(page);
      getJobs({page, sort: `${column}:${dir}`, filter, query});
    }
  }),
  withHandlers({
    search: ({getJobs, page = 0, query}) => () => getJobs({page, query})
  }),
  lifecycle({
    componentDidMount() {
      const { setCompanyJobs, jobs} = this.props;

      setCompanyJobs(jobs);
    },
    componentDidUpdate(prevProps) {
      if (prevProps.activePage !== this.props.activePage)
        window.scrollTo(0, 0);
    }
  }),
  withDebounce(),
  withHandlers({
    change: ({setQuery, searchDebounced}) => e => {
      const value = e.target.value;
      setQuery(value);
      searchDebounced(value);
    },
    toggleCandidatesList: ({expandedJobs, setExpandedJobs}) => jobId => {
      const isJobExpanded = expandedJobs.includes(jobId);

      const newExpanded = isJobExpanded
        ? expandedJobs.filter(id => id !== jobId)
        : expandedJobs.concat(jobId);

      setExpandedJobs(newExpanded);
    },
    filterByStatus: ({getSortedJobs, setOption, sortColumn, sortDirection}) => value => {
      setOption(value);
      getSortedJobs(0, sortColumn, sortDirection, value);
    },
    searchJobs: ({getSortedJobs, sortColumn, sortDirection, option}) => page => {
      getSortedJobs(page, sortColumn, sortDirection, option);
    }
  }),
  withProps(({match, jobs, topHit}) => ({
    employerId: match.params.id,
    jobs: Object.keys(topHit).length > 0 ? [topHit, ...jobs] : jobs
  }))
)(
  ({query,
    change,
    jobs,
    jobsCount,
    searchJobs,
    loading,
    getSortedJobs,
    downloadResume,
    activePage,
    toggleCandidatesList,
    expandedJobs,
    sortColumn,
    sortDirection,
    filterByStatus,
    employerId,
    viewApplication
  }) => {
    return (
      <div className="MyCompanyJobs">
        <div className="MyCompanyJobs__header">
          <h1>Job listings</h1>
          <div className="MyCompanyJobs__headerFilters">
            <div className="MyCompanyJobs__select">
              <span>Job status</span>
              <RSelect
                options={JOB_STATUSES}
                defaultValue={JOB_STATUSES[0]}
                onChange={e => filterByStatus(e.value)}
              />
            </div>
            <Search onChange={change} value={query} />
          </div>
          <div className="MyCompanyJobs__headerNew">
            <Link to='/org-admin/jobs/new' className="btn-primary">Create new job</Link>
          </div>
        </div>
        <div className="MyCompanyJobs__container">
          <Loader loading={loading} />
          {jobs.length > 0
            ?
              <>
                <Sorting
                  columns={COLUMNS}
                  activePage={activePage}
                  sortDirection={sortDirection}
                  sortColumn={sortColumn}
                  sort={getSortedJobs}
                />
                {jobs.map(j => {
                  const isExpanded = expandedJobs.includes(j.jobOpening.id);
                  return (
                    <Row
                      key={j.jobOpening.id}
                      job={j.jobOpening}
                      isExpanded={isExpanded}
                      employerId={employerId}
                      toggleCandidatesList={toggleCandidatesList}
                      downloadResume={downloadResume}
                      viewApplication={viewApplication}
                    />
                  )
                })}
                <div className="JobBrowsing__pagination">
                  {jobsCount > constants.PAGINATION.SIZE &&
                    <span>Page {activePage + 1} of {Math.ceil(jobsCount / constants.PAGINATION.SIZE)}</span>
                  }
                  <Pagination
                    itemsCount={jobsCount}
                    gotoPage={searchJobs}
                    disabled={loading}
                    activePage={activePage}
                  />
                </div>
              </>
            :
              <div className="MyCompanyJobs__empty">
                <h5>You haven't added any jobs yet!</h5>
                <Link to='/org-admin/jobs/new' className="btn-primary">Create new job</Link>
              </div>
          }
        </div>
      </div>
    )
  }
)


const mapStateToProps = ({data, loading}) => ({
  topHit: data.jobs?.topHit || {},
  loading: loading.jobs
})

const mapDispatchToProps = dispatch => ({
  getJobs: (args = {}) => new Promise((resolve, reject) => dispatch(getJobs({...args, resolve, reject}))),
  downloadResume: userId => dispatch(downloadFile(userId))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCompanyJobs))
