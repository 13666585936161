import React from 'react';
import './Plan.sass';

export default function Plan({plan, employerLanding, altText, premiumImg, changePlan}) {
  return (
    <div className="Plan">
      <img src={premiumImg || plan.img} alt="plan" />
      <p className="Plan__title">{plan.label}</p>
      <span className="Plan__desc">{altText || plan.desc}</span>
      {employerLanding &&
        <button
          className="btn-primary"
          onClick={() => window.scrollTo(0, 0)}
        >
          {plan.btnText}
        </button>
      }
      {!employerLanding &&
        <button
          className="contact btn-primary"
          onClick={() => changePlan()}
        >
          Upgrade
        </button>
      }
    </div>
  )
}
