import React from 'react';

import './ProgressBar.sass';

export default function ProgressBar({value}) {
  return (
    <div className="ProgressBar">
      <div className="ProgressBar__active" style={{width: `${value}%`}} />
    </div>
  )
}
