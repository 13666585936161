import React    from 'react';
import { Link } from 'react-router-dom';

import './Agreement.sass';


export default function Agreement() {
  return (
    <div className="Agreement">
      By creating an account, I agree to Vet it's 
      {' '}
      <Link to="/terms-of-use">Terms of Use</Link> and
      {' '}
      <Link to="/privacy-and-cookie-policy">Privacy Policy</Link>.
    </div>
  )
}
