export const employerFields = [
  {label: 'Name', name: 'name'},
  {label: 'Partners', name: 'partners'},
  {label: 'City', name: 'address.city'},
  {label: 'State', name: 'address.state'},
  {label: 'Country', name: 'address.country'},
  {label: 'Website Url', name: 'url'},
  {label: 'Type', name: 'type'},
  {label: 'Founded', name: 'founded'},
  {label: 'Industry', name: 'industry'},
  {label: 'Organization Size', name: 'organizationSize'},
  {label: 'Affinity Group Size', name: 'affinityGroupSize'},
  {label: 'DOD Skillbridge Ready', name: 'indicators.dodSkillbridgeReady'},
  {label: 'Executive Sponsorship', name: 'indicators.executiveSponsorship'},
  {label: 'DOL Apprenticeships', name: 'indicators.militaryOnboardingProcess'},
  {label: 'Mentorship Program', name: 'indicators.mentorshipProgram'},
  {label: 'ESGR Support Signatory', name: 'indicators.militaryLeavePolicy'},
  {label: 'MSEP Support Signatory', name: 'indicators.militarySpousePCSPolicy'},
  {label: 'Cover Photo Url', name: 'premiumFeatures.imgUrl'},
  {label: 'Cover Overlay Color', name: 'premiumFeatures.overlayColor'},
  {label: 'Enable Overlay on Organization Page', name: 'premiumFeatures.overlayOnCover'},
  {label: 'Enable Overlay on Landing Page', name: 'premiumFeatures.overlayOnLanding'},
  {label: 'Cover Title', name: 'premiumFeatures.title'},
  {label: 'Blue Box Title', name: 'premiumFeatures.info.title'},
  {label: 'Blue Box Text', name: 'premiumFeatures.info.text'},
  {label: 'Blue Box \'Learn More\' Link', name: 'premiumFeatures.info.url'},
  {label: 'Star Title', name: 'premiumFeatures.star.title'},
  {label: 'Star Paragraphs', name: 'premiumFeatures.star.content'},
  {label: 'Videos', name: 'premiumFeatures.videos'},
  {label: 'Details', name: 'premiumFeatures.details'},
  {label: 'Review Title', name: 'premiumFeatures.review.title'},
  {label: 'Reviewer Details', name: 'premiumFeatures.review.reviewer'},
  {label: 'Review Rating', name: 'premiumFeatures.review.rating'}
];

export const educatorFields = [
  {label: 'Name', name: 'name'},
  {label: 'Partners', name: 'partners'},
  {label: 'Caution', name: 'quickGlance.isCaution'},
  {label: 'Accreditation', name: 'quickGlance.accreditation'},
  {label: 'Selectivity', name: 'selectivity'},
  {label: 'City', name: 'address.city'},
  {label: 'State', name: 'address.state'},
  {label: 'Country', name: 'address.country'},
  {label: 'Website Url', name: 'url'},
  {label: 'Type', name: 'type'},
  {label: 'GI Bill Students', name: 'quickGlance.studentsGiBill'},
  {label: 'Undergraduate Students', name: 'quickGlance.studentsUndergraduates'},
  {label: 'Yellow Ribbon Students', name: 'quickGlance.studentsYellowRibbon'},
  {label: 'Retention Percent (%)', name: 'quickGlance.retentionPercent'},
  {label: 'Graduation Percent (%)', name: 'quickGlance.graduationPercent'},
  {label: 'Repayment Percent (%)', name: 'quickGlance.repaymentPercent'},
  {label: 'In State Tuition / YR', name: 'dollarsSense.tuitionInState'},
  {label: 'Out Of State Tuition / YR', name: 'dollarsSense.tuitionOutOfState'},
  {label: 'Max Housing Allowance / MO', name: 'dollarsSense.maxHousingAllowance'},
  {label: 'Median Earnings / YR', name: 'dollarsSense.medianEarnings'},
  {label: 'Mostly Online', name: 'quickGlance.isMostlyOnline'},
  {label: '8 Key Commitment', name: 'indicators.is8kc'},
  {label: 'Principles of Excellence', name: 'indicators.isPoe'},
  {label: 'DOD MOU / TA Agreement', name: 'indicators.isDodmou'},
  {label: 'Student Vet Group', name: 'indicators.isSvg'},
  {label: 'Cover Photo Url', name: 'premiumFeatures.imgUrl'},
  {label: 'Cover Overlay Color', name: 'premiumFeatures.overlayColor'},
  {label: 'Enable Overlay on Organization Page', name: 'premiumFeatures.overlayOnCover'},
  {label: 'Enable Overlay on Landing Page', name: 'premiumFeatures.overlayOnLanding'},
  {label: 'Cover Title', name: 'premiumFeatures.title'},
  {label: 'Blue Box Title', name: 'premiumFeatures.info.title'},
  {label: 'Blue Box Text', name: 'premiumFeatures.info.text'},
  {label: 'Blue Box \'Learn More\' Link', name: 'premiumFeatures.info.url'},
  {label: 'Star Title', name: 'premiumFeatures.star.title'},
  {label: 'Star Paragraphs', name: 'premiumFeatures.star.content'},
  {label: 'Videos', name: 'premiumFeatures.videos'},
  {label: 'Details', name: 'premiumFeatures.details'},
  {label: 'Review Title', name: 'premiumFeatures.review.title'},
  {label: 'Reviewer Details', name: 'premiumFeatures.review.reviewer'},
  {label: 'Review Rating', name: 'premiumFeatures.review.rating'}
];

export const partnerFields = [
  {label: 'Name', name: 'name'},
  {label: 'Logo Url', name: 'logoUrl'},
  {label: 'Website Url', name: 'hrefUrl'},
  {label: 'Founded', name: 'founded'},
  {label: 'Cities', name: 'cities'},
  {label: 'States', name: 'states'},
  {label: 'Type', name: 'type'},
  {label: 'Geo Scope', name: 'geographicScope'}
];

export const skillExamples = [
  'Leadership Skills',
  'Active Listening',
  'Critical Thinking',
  'Attention to Detail',
  'Stress Tolerance'
];
