import React                  from 'react';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { Helmet }             from 'react-helmet';
import { Link }               from 'react-router-dom';
import { formValueSelector }  from 'redux-form';
import { lifecycle,
         compose,
         withState,
         withHandlers }       from 'recompose';
import Loader                 from 'theme/Loader';
import Footer                 from 'theme/Footer';
import Header                 from 'theme/Header';
import AdminNav               from 'theme/AdminNav';
import MessageSection         from 'theme/MessageSection';
import { createSlug }         from 'theme/utils/helpers';
import { addPartner,
         getPartner,
         updatePartner }      from 'partners/actions';

import Form                   from './Form';
import './NewPartner.sass';


const formName = 'Partner';

const NewPartner = compose(
  withState('submitted', 'setSubmitted', false),
  withHandlers({
    submit: ({submit, setSubmitted}) => values => {
      return submit(values || values.id)
      .then(response => setSubmitted(response))
    }
  }),
  lifecycle({
    componentDidMount() {
      if(this.props.match.params.id)
        this.props.getPartner();
    }
  })
)(({partner, loadingPartner, submit, user, isAdminRoute, selectedStates, submitted}) => {
  const filterOptions = option => selectedStates.findIndex(state => state === option.value) === -1;
  return (
    <div className="NewPartner page">
      <Helmet>
        <title>Vetit Admin – Partner</title>
      </Helmet>
      <Header />
      {isAdminRoute &&
        <AdminNav />
      }
      {submitted
        ?
        <div className="container">
          <MessageSection title="Thank you for adding a new Support Service!">
            <Link
              to={`/support-services/${submitted.id}/${createSlug(submitted.name.toLowerCase())}/overview`}
              className="btn-primary"
            >
              Show the organization
            </Link>
          </MessageSection>
        </div>
        :
        <div className="container">
          <Loader className="NewPartner__loader" loading={loadingPartner} />
          <Form
            form={formName}
            onSubmit={submit}
            initialValues={partner}
            user={user}
            filterOptions={filterOptions}
          />
        </div>
      }
      <Footer />
    </div>
  )
});

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;
  const selector = formValueSelector(formName);

  return {
    user: state.auth.user,
    partner: (state.data && state.data[`partner:${id}`]),
    loadingPartner: state.loading[`partner:${id}`],
    selectedStates: selector(state, 'states') || []
  }
};

const mapDispatchToProps = (dispatch, {match}) => {
  const id = match.params.id;

  return {
    getPartner: () => new Promise((resolve, reject) => dispatch(getPartner(id, {resolve, reject}))),
    submit: (values) => id
      ? new Promise((resolve, reject) => dispatch(updatePartner({id, values, resolve, reject})))
      : new Promise((resolve, reject) => dispatch(addPartner({values, resolve, reject})))
  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPartner));
