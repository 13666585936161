import React                    from 'react';
import { connect }              from 'react-redux';
import { withRouter }           from 'react-router';
import { Link }                 from 'react-router-dom';
import { Helmet }               from 'react-helmet';
import { compose,
         setDisplayName,
         lifecycle,
         withState,
         withHandlers }         from 'recompose';
import { CancelToken }          from 'axios';
import Header                   from 'theme/Header';
import Tabs                     from 'theme/Tabs';
import MessageSection           from 'theme/MessageSection';
import { withScroll }           from 'theme/utils/recompose';
import { skillExamples, env }   from 'theme/content';
import { getUserProfile,
         updateUserProfile,
         getLocations,
         uploadFile,
         deleteFile }           from 'user/actions';

import PersonalInformationForm  from '../Forms/PersonalInformationForm';

import './Onboarding.sass';


const PersonalInformationFormName = "PersonalInformationForm";
const axiosSource = CancelToken.source();

const Onboarding = compose(
  setDisplayName('Onboarding'),
  withScroll(),
  withState('progress', 'updateProgress', 0),
  withState('uploadCancelled', 'setUploadCancelled', false),
  withState('submitted', 'setSubmitted', false),
  withState('message', 'setMessage', ''),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.match.params.page != this.props.match.params.page)
        window.scrollTo(0, 0);
    }
  }),
  withHandlers({
    uploadFile: ({uploadFile, user, updateProgress, setSubmitted, setMessage}) => forVerification => values => {
      return uploadFile(values, user.id, updateProgress, axiosSource, forVerification)
      .then(() => {
        if(forVerification) {
          setSubmitted(true);
          setMessage('Your file has been sent for verification.');
        }
      })
    },
    cancelUpload: ({setUploadCancelled}) => () => {
      const message = 'Operation cancelled by the user.';
      axiosSource.cancel(message);
      setUploadCancelled(message);
    },
    deleteFile: ({user, getUserProfile}) => key => {
      return deleteFile(user.id, key)
      .then(() => getUserProfile({id: user.id, loader: false}))
    },
    submit: ({submit, user, history}) => values => {
      return submit(user, values, true)
      .then(() =>
        history.push(env.HOME_ROUTE.commonOnboarded)
      )
    }
  })
)(({
    submit,
    userProfile,
    loading,
    uploadFile,
    deleteFile,
    getLocations,
    progress,
    cancelUpload,
    uploadCancelled,
    submitted,
    setSubmitted,
    message,
    setMessage,
    user
  }) => {
  const initialValues = {
    ...userProfile,
    skills: userProfile.skills?.filter(skill => !skillExamples.find(example => example === skill)),
    suggestedSkills: userProfile.skills?.filter(skill => skillExamples.find(example => example === skill))
  };

  const uploadProps = {deleteFile, progress, files: userProfile.resumes, cancelUpload, uploadCancelled};

  return (
    <div className="Onboarding page">
      <Helmet>
        <title>Vetit – Onboarding</title>
      </Helmet>
      <Header isOnboarding />
      {submitted
        ?
          <div className="container">
            <MessageSection
              title="Thanks!"
              text={message}
            >
              <Link
                className="Onboarding__goBack btn-primary"
                onClick={() => setSubmitted(false)}
                to="/onboarding"
              >
                Go back to onboarding
              </Link>
            </MessageSection>
          </div>
        :
          <div className="container">
            <div className="card">
              <span className="Onboarding__welcome">Welcome to the Vet it community!</span>
              <h1>Complete your profile with basic information</h1>
              <Tabs
                loading={loading}
                title="Personal information"
              >
                {!loading &&
                  <PersonalInformationForm
                    onSubmit={submit}
                    initialValues={initialValues}
                    form={PersonalInformationFormName}
                    getLocations={getLocations}
                    user={user}
                    setSubmitted={setSubmitted}
                    setMessage={setMessage}
                    uploadProps={{
                      uploadFile: uploadFile(true),
                      ...uploadProps
                    }}
                    isOnboarding
                  />
                }
              </Tabs>
            </div>
          </div>
      }
    </div>
  )
})

const mapStateToProps = ({auth, data, loading}) => ({
  user: auth.user,
  userProfile: data?.user_profile || {},
  loading: loading.user_profile
});

const mapDispatchToProps = dispatch => ({
  getUserProfile: (args = {}) => new Promise((resolve, reject) => dispatch(getUserProfile({...args, resolve, reject}))),
  uploadFile: (values, userId, updateProgress, source, forVerification) => dispatch(uploadFile(values, userId, updateProgress, source, forVerification)),
  deleteFile: (userId, key) => dispatch(deleteFile(userId, key)),
  getLocations: (query) => dispatch(getLocations(query)),
  submit: (user, values, onboarding) => dispatch(updateUserProfile(user.id, values, onboarding))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding));
